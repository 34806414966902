export enum Role {
  All = 'All',
  Admin = 'Admin',
  Teacher = 'Teacher',
  Student = 'Student',
  Parent = 'Parent',
  children = 'children',
  Anonymous = 'Anonymous',
}


// export enum Role {

//   Teacher = 'Teacher' //1,
//   Student = 'Student' //2,
//   Parent = 'Parent'//3,

// }
