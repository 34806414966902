import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ICity, ICountry, IGenders, IGovernorate } from 'src/app/shared/Model/Country/country';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ParentEditProfileService } from 'src/app/shared/Apis/Parent/Profile/parent-edit-profile.service';
import { Router } from '@angular/router';
import { IGetProfile } from 'src/app/shared/Model/teacher/edit-profile';
import { NgbRatingConfig, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { environment as env, environment} from 'src/environments/environment';
import { IGetParentProfile } from 'src/app/shared/Model/Parent/parent-edit-profile';
import { ChooseStudentComponent } from '../../choose-student/choose-student.component';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-parent-profile',
  templateUrl: './parent-profile.component.html',
  standalone: true,
  imports: [CommonModule,NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslateModule,NgbRatingModule,
    ChooseStudentComponent ,DisableWeritingArabicDirective,NgSelectModule
  ],
  styleUrls: ['./parent-profile.component.scss']
})
export class ParentProfileComponent extends BaseComponent implements OnInit{
  private logoForm!: FormData;
  protected selectedFile!: File;
  protected ProfileData!: IGetParentProfile;
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected submit: boolean = true;
  protected subscription = new Subscription();
  imageSrc!: string;
  max = 5;
  rate = 3;
  AcademicYearEducationLevel: any;
  EducationType: any
  EducationLevel: any
  AcademicYear: any
  EducationtypeId:any;
  StudentId: any;
constructor(  private ParentEditProfileService:ParentEditProfileService,  private _fb: FormBuilder,
    private _transition: TranslateService,
    private _Lookup: LookupService,private _AuthService:AuthService,
    private seoService: SeoService,
    private layoutService: LayoutService,config: NgbRatingConfig) {

    super();

    config.max = 5;
		config.readonly = true
}
ngOnInit(): void {
  this.seo();
  this.initiate();
  this.GetCountry();
  this.GetProfile();

}

private initiate(Profile? :IGetParentProfile) {
  this.insertForm = this._fb.group(
    {
      Name: [Profile?.name ||'', Validators.required],
      Birthdate: [Profile?.birthdate?.slice(0,10) || '', Validators.required],
      Email: [Profile?.email || '', [Validators.required, Validators.email]],
      CityId: [Profile?.cityId ||'', Validators.required],
      GenderId: [Profile?.genderId ||'', Validators.required],
      MobileX: [{ value: Profile?.mobile || '', disabled: true }],
      GovernorateId: [Profile?.governoratedId ||'', Validators.required],
      CountryId: [Profile?.countryId ||'', Validators.required],
      Code:[Profile?.code ||'', Validators.required],
      // remmove
    },

  );
}
protected get fc() {
  return this.insertForm.controls;
}

GetProfile() {
  this.ParentEditProfileService
    .GetProfileParent()
    .subscribe((_profileData:any) => {
      this.ProfileData=_profileData;
      if(this.ProfileData.image){
        this.imageSrc=environment.serverFirstHalfOfImageUrl+'/'+this.ProfileData.image
      }
      else{
      this.imageSrc= 'assets/images/Logo/logo.svg'
      }
      this.initiate(_profileData)
      this.GetGovernorate();
      this.SelectedGovernorate();



    });
}
GetCountry() {
  this._Lookup
    .GetCountry()
    .subscribe((country) => (this.countries = country));
}
protected ChangCountry() {

  this.fc['GovernorateId'].setValue('');
  this.fc['CityId'].setValue('');
  this.Governorates = [];
  this.cityList = [];
  this.GetGovernorate();
}
GetGovernorate() {
  if (  this.fc['CountryId'].value !== null &&
  this.fc['CountryId'].value !== '')
    this._Lookup
      .GetGovernorate(this.fc['CountryId'].value)
      .subscribe((_Governorates) => (this.Governorates = _Governorates));
  else {
    this.Governorates = [];
    this.cityList = [];
  }
}

protected ChangGovernorate() {
  this.fc['CityId'].setValue('');
  this.SelectedGovernorate();
}
// get from city
private SelectedGovernorate() {
  if (  this.fc['GovernorateId'].value !== null &&
  this.fc['GovernorateId'].value !== '') {
    this.subscription.add(
      this._Lookup
        .GetCity(this.fc['GovernorateId'].value)
        .subscribe((cities) => (this.cityList = cities))
    );
  } else this.cityList = [];
}

protected onSubmit() {
  if (this.insertForm.valid ) {
    // Perform the submit logic here
    this.UpdateData();
  } else {
    this.insertForm.markAllAsTouched();
  }
}

private UpdateData() {
  this.loopform();
  this.submit = false;
  this.ParentEditProfileService.UpdateProfileParent(this.logoForm).subscribe(
    (res:any) => {
      if (res.success == true) {
        Swal.fire({
          icon: 'success',
          title: this._transition.instant('Profile updated successfully'),
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem('customer_name', res.data['name']);
        localStorage.setItem('logo', res.data['image']);
        this._AuthService.ReloadHeader(true);

      }
      this.submit = true;
    },
    (err) => {
      this.submit = true;
    }
  );
}

private loopform() {
  this.logoForm = new FormData();
  Object.keys(this.insertForm.value).forEach((key) => {
    if (this.insertForm.value[key] == null) {
      //this.insertForm.removeControl(key);
    } else {
      if (
        typeof this.insertForm.value[key] !== 'object' &&
        key !== 'image' &&
        key !== 'truckImage'
      )
        this.logoForm.append(key, this.insertForm.value[key]);
      else if (typeof this.insertForm.value[key] == 'object')
        Object.keys(this.insertForm.value[key]).forEach((subkey) => {
          this.logoForm.append(key, this.insertForm.value[key][subkey]);
        });
    }
  });
  if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
  this.logoForm.append('ParentImage',this.selectedFile)
}

protected onSelectFile(event: any) {
  this.selectedFile = event.target.files[0];
  // this.IsFile = true;
  // this.FileSize= true;
  if (this.selectedFile) {

  const reader = new FileReader();
    const fileSizeInMB = this.selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
    reader.readAsDataURL(this.selectedFile);

    if (fileSizeInMB > 2) {
      // File size exceeds 5MB
      // this.FileSize=false
      Swal.fire({
        icon: 'warning',
        title: this._transition.instant('warning'),
        text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
      });
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

    }else
    reader.onload = () => {
      this.imageSrc = reader.result as string;

    };

  }

}

protected  preview(files: any) :void{
  const file = files.target.files[0];
  if (file.length === 0) return;
  var mimeType = file.type;
  const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
  // this.selectedFile = file;
  if (before_ == 'image') {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeInMB > 2) {
      // File size exceeds 5MB
      // this.FileSize=false
      Swal.fire({
        icon: 'warning',
        title: this._transition.instant('warning'),
        text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
      });
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
      this.imageSrc=environment.serverFirstHalfOfImageUrl + this._AuthService.GetByName("logo")
    } else {
      this.selectedFile = file;
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  } else {
    this.imageSrc=environment.serverFirstHalfOfImageUrl + this._AuthService.GetByName("logo")
    this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
    Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });

    return;
  }
}

private seo(){
  this.seoService.setMetaImage(this.imageSrc ? this.imageSrc : 'assets/images/Profile/Pic.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ParentProfile.header.Title);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}

}
