
// // In your component or service, you can call changeEnv to update the language.

// export const api ="https://localhost:44374" //devlopemnt
// // export const api ="https://mrscoolapidemo.azurewebsites.net" // demo
// export  const api ="https://alnada-devmrsapi.azurewebsites.net" //Test
export const api ="https://api.mrscool.app" //live




export const environment = {
  production: false,
  language: 'en', // Default language
  langJson: 'https://translate-wecancity-production.up.railway.app/api/json',
  // langJson: 'http://localhost:3000/api/json',
  defaultImage: 'assets/images/statics/personAvatar.png',
  Server_URL: `${api}/api/en/`, // Default language path
  serverFirstHalfOfImageUrl: `${api}/`
};

export function changeEnv(lan: string) {
  environment.language = lan;
  environment.Server_URL = `${api}/api/${lan}/`;
}

export function changeEnvProd(lan: string) {
  environment.language = lan;

  environment.language = lan;
  environment.Server_URL = `${api}/api/${lan}/`;

}
