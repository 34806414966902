import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map, of } from 'rxjs';

import { environment as env } from 'src/environments/environment';
 import { IDocumentType } from '../../Model/teacher/documents-information';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
import { IBank, ICity, ICountry, IGovernorate, IStatus } from '../../Model/Country/country';
import { SubjectInfo } from '../../Model/teacher/subject-info';
import { AuthService } from '../AuthService/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpClient,private auth:AuthService, private router:Router) {}
  //drop list
  GetDocumentType():Observable<IDocumentType[]>{
    return this.http.get<IGenericResponse<IDocumentType>>(`${env.Server_URL}DocumentType/Get`).pipe( map((res) => res.data ))}


    GetCountry():Observable<ICountry[]>{
      return this.http.get<IGenericResponse<ICountry>>(`${env.Server_URL}Country/Get`).pipe( map((res) => res.data ))
    }
    GetGovernorate(id:number):Observable<IGovernorate[]>{
      return this.http.get<IGenericResponse<IGovernorate>>(`${env.Server_URL}Governorate/Get?countryId=${id}`).pipe( map((res) => res.data ))
    }
    GetCity(id:number):Observable<ICity[]>{
      return this.http.get<IGenericResponse<ICity>>(`${env.Server_URL}City/Get?GovernorateId=${id}`).pipe( map((res) => res.data ))
    }

    GetBank():Observable<IBank[]>{
      return this.http.get<IGenericResponse<IBank>>(`${env.Server_URL}Bank/GetBankForList`).pipe( map((res) => res.data ))
    }
    GetEducationType():Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}EducationType/Get`).pipe( map((res) => res.data ))
    }
    GetEducationLevelByEducationType(Type:number):Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}EducationLevel/GetByEducationType?educationTypeId=${Type}`).pipe( map((res) => res.data ))
    }
    GetAcademicYearByeducationLevelId(LevelId:number):Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}AcademicYear/GetByEducationLevel?educationLevelId=${LevelId}`).pipe( map((res) => res.data ))
    }

// new api
GetAllForListByEducationLevelId(educationLevelId:number):Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}Subject/GetAllForListByEducationLevelId?educationLevelId=${educationLevelId}`).pipe( map((res) => res.data ))
    }

    GetAllSubjectBySubjectIdAndEducationLevelId(data:any):Observable<SubjectInfo[]>{
      return this.http.post<IGenericResponse<SubjectInfo>>(`${env.Server_URL}SubjectSemesterYear/GetAllSubjectBySubjectIdAndEducationLevelId`,data).pipe( map((res) => res.data ))
    }


    GetAcademicYearEducationLevel():Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}AcademicYear/GetAcademicYearEducationLevel`).pipe( map((res) => res.data ))
    }
    GetSubjectSemesterYearByAcademicYear(LevelId:number, all:boolean =false):Observable<SubjectInfo[]>{
      return this.http.get<IGenericResponse<SubjectInfo>>(`${env.Server_URL}SubjectSemesterYear/GetAllSubjectByAcademicEducationLevelId?academicEducationLevelId=${LevelId}&GetAll=${all}`).pipe( map((res) => res.data ))
    }
    GetAcademicSemester():Observable<any[]>{
      return this.http.get<IGenericResponse<any>>(`${env.Server_URL}AcademicSemester/Get`).pipe( map((res) => res.data ))
    }

    GetStatus():Observable<IStatus[]>{
      return this.http.get<IGenericResponse<IStatus>>(`${env.Server_URL}Lookups/GetStatus`).pipe( map((res) => res.data ))
    }
    GetDays():Observable<IStatus[]>{
      return this.http.get<IGenericResponse<IStatus>>(`${env.Server_URL}Lookups/GetDay`).pipe( map((res) => res.data ))
    }

    public GetFinance(controller:string ): Observable<IGenericResponseObject<any>> {
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}${controller}/FinanceView?studentId=${this.auth.getCurrentChildrenId()??""}`).pipe(
          map((response: any) => {
              return response;
          })
      );
    }
    public PagedFinanceSubjects(controller: string, pager: any): Observable<IGenericResponseObject<any>> {
      if (this.auth.getRoleId() == '3') {
        if (!this.auth.getCurrentChildrenId()) {
          this.router.navigate([`/main/parent/ParentStudents`]);
          return of({} as IGenericResponseObject<any>);
        }
        else
        pager['studentId']=this.auth.getCurrentChildrenId()
      }
      return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}${controller}/PagedFinanceSubjects`, pager).pipe(
        map((response: IGenericResponseObject<any>) => {
          return response;
        })
      );
    }
    public PagedFinanceLessons(controller:string ,pager:any): Observable<IGenericResponseObject<any>> {

      if (this.auth.getRoleId() == '3') {
        if (!this.auth.getCurrentChildrenId()) {
          this.router.navigate([`/main/parent/ParentStudents`]);
          return of({} as IGenericResponseObject<any>);
        }
        else
        pager['studentId']=this.auth.getCurrentChildrenId()
      }
      return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}${controller}/PagedFinanceLessons`,pager).pipe(
          map((response: any) => {
              return response;
          })
      );
    }

    public GetFinanceForTeacher( ): Observable<IGenericResponseObject<any>> {
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Teacher/TeacherFinance`).pipe(
          map((response: any) => {
              return response;
          })
      );
    }
  }
