
<div class="global-card  mb-3 m-auto w-fullbg-white rounded-lg shadow  bg-white sha dow p-2 my-2">

    <div class="px-8 pt-3 addressIconSpace" [ngSwitch]="type">
      <h2
        *ngSwitchCase="'MostRatedTeacher'"
        class="Lama-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-TeacherWithClick)]"
      >
        {{'MostBookedTeacher.body.Top-Rated-Teachers' | translate}}
      </h2>
      <h2
        *ngSwitchCase="'GetTeachersMostBooked'"
        class="Lama-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-TeacherWithClick)]"
      >
        {{'MostBookedTeacher.body.Most-Booked-Teachers' | translate}}
      </h2>
      <h2 *ngSwitchDefault class="Lama-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-TeacherWithClick)]">
        {{'MostBookedTeacher.body.Most-Viewed-Teachers' | translate}}
      </h2>

      <hr>
    </div>


<div class="grid gap-2 md:mb-2 lg:grid-cols-4  xl:grid-cols-5 md:grid-cols-3 grid-cols-1 mt-3" *ngIf="TeachersMostBooked && TeachersMostBooked[0] !=null">
  <div
    (click)="goToTeacherProfile(item.teacherId)"
    *ngFor="let item of TeachersMostBooked"
    class="cursor-pointer border-0 card md:mb-3 ml-2 md:p-2 hover-effect d-flex flex-column just align-content-center  justify-content-center"
  >
    <div class="d-flex flex-column align-items-center justify-content-center" data-aos="flip-left"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="2000" >
      <img
        *ngIf="item?.teacherImage"
        [src]="ImgUrlServer + item?.teacherImage"
        class="  w-full borderRaduis50 text-center"

      />
      <img

        *ngIf="!item?.teacherImage"
        src="assets/images/MrS-Cool/subjectDetails.png"
        class="borderRaduis50 text-center"
      />
    </div>

    <div class="text-center mt-3">
      <div class="text-start">
        <h4 class="text-[12px]  text-center md:text-start md:text-[16px] Lama-bold  text-[var(--theme-deafult-TeacherWithClick)]">{{ item.teacherName }}</h4>
      </div>
      <div class="text-lg  text-center md:text-start">
        <span class="Lama-Reguler  text-[12px] md:text-[14px]  text-[var(--theme-deafult-studentWithClick)]">{{ item.teacherRate }} </span>
        <ngb-rating
          class="Rate"
          [readonly]="true"
          [rate]="item.teacherRate"
          [max]="5"
        >
          <ng-template let-fill="fill">
            <span class="mr-1" *ngIf="fill === 100">&#9733;</span>
            <span class="mr-1" *ngIf="fill !== 100">&#9734;</span>
          </ng-template>
        </ngb-rating>
        <span>({{ item.teacherReview }})</span>
      </div>
      <div class="text-start" *ngIf="showPriceAndDuration">
        <div class="info mb-2">
          <div class="flex mt-2" >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              class="mt-2"
            >
              <path
                d="M15.9775 8.90839C15.9775 8.90831 15.9775 8.90827 15.9775 8.90819L12.9566 1.32306C12.8925 1.16189 12.7098 1.08321 12.5486 1.14733C12.5485 1.14736 12.5484 1.1474 12.5483 1.14748L0.293041 6.02741C0.243062 6.049 0.199718 6.08347 0.167407 6.12729C0.0654866 6.18025 0.00109929 6.28508 0 6.39991V14.5661C0 14.7396 0.140631 14.8802 0.314084 14.8802H13.5056C13.6791 14.8802 13.8197 14.7396 13.8197 14.5661V10.1061L15.8022 9.3165C15.9633 9.25223 16.0418 9.06951 15.9775 8.90839ZM13.1915 14.252H0.628169V6.714H13.1915V14.252ZM5.2405 6.0874L10.4995 3.99183C10.9689 4.48518 11.6192 4.76558 12.3002 4.76825L12.8247 6.0874H5.2405ZM13.8197 9.43145V6.39991C13.8197 6.22646 13.6791 6.08583 13.5056 6.08583H13.5006L12.8351 4.41427C12.8277 4.40034 12.8191 4.38707 12.8093 4.3747C12.7756 4.22343 12.6355 4.12021 12.4811 4.13285C11.8475 4.19061 11.2277 3.92371 10.8343 3.42365C10.7365 3.30285 10.5644 3.27281 10.4314 3.3533C10.4164 3.35506 10.4016 3.35781 10.3871 3.36146L3.96657 5.91811C3.88887 5.94857 3.82672 6.009 3.79414 6.08583H1.8443L12.4892 1.84726L15.278 8.84977L13.8197 9.43145Z"
                fill="#FFAA00"
              />
              <path
                d="M1.4806 12.1332C2.0904 12.3135 2.56757 12.79 2.74856 13.3996C2.78825 13.5327 2.91063 13.6238 3.04945 13.6239C3.06575 13.6228 3.08192 13.6204 3.09782 13.6166C3.11192 13.6206 3.12633 13.6236 3.14085 13.6254H10.6789C10.6918 13.6237 10.7046 13.6211 10.7172 13.6176C10.8741 13.6557 11.0323 13.5595 11.0705 13.4026C11.0706 13.402 11.0707 13.4015 11.0709 13.4009C11.2514 12.7907 11.7286 12.3134 12.3388 12.1329C12.4868 12.0877 12.5787 11.9402 12.5543 11.7874C12.5585 11.7722 12.5615 11.7566 12.5634 11.7409V9.22826C12.5615 9.21251 12.5584 9.19696 12.554 9.18177C12.5786 9.02893 12.4866 8.88139 12.3385 8.83628C11.7283 8.6558 11.2512 8.17835 11.0712 7.56801C11.0257 7.41901 10.8768 7.32683 10.7232 7.35254C10.7086 7.3485 10.6938 7.34556 10.6789 7.34375H3.14085C3.12503 7.34563 3.10937 7.34877 3.09405 7.35317C2.94129 7.32883 2.79391 7.42074 2.74856 7.56863C2.56792 8.1787 2.09071 8.6558 1.4806 8.83628C1.33263 8.88151 1.24068 9.02897 1.26514 9.18177C1.26106 9.19704 1.25811 9.21255 1.25635 9.22826V11.7409C1.25815 11.7557 1.2611 11.7703 1.26514 11.7846C1.23939 11.9384 1.33153 12.0875 1.4806 12.1332ZM1.88452 9.35577C2.5152 9.10246 3.01506 8.6026 3.26837 7.97192H10.551C10.8045 8.6026 11.3045 9.10246 11.9352 9.35577V11.6134C11.3047 11.867 10.8049 12.3668 10.5514 12.9973H3.26837C3.01479 12.3668 2.515 11.867 1.88452 11.6134V9.35577Z"
                fill="#FFAA00"
              />
              <path
                d="M6.9099 12.3706C7.95069 12.3706 8.7944 11.5269 8.7944 10.4861C8.7944 9.44527 7.95069 8.60156 6.9099 8.60156C5.8691 8.60156 5.02539 9.44527 5.02539 10.4861C5.02641 11.5264 5.86953 12.3696 6.9099 12.3706ZM6.9099 9.22973C7.60375 9.22973 8.16623 9.79222 8.16623 10.4861C8.16623 11.1799 7.60375 11.7424 6.9099 11.7424C6.21604 11.7424 5.65356 11.1799 5.65356 10.4861C5.65356 9.79222 6.21604 9.22973 6.9099 9.22973Z"
                fill="#FFAA00"
              />
              <path
                d="M3.45501 10.9579C3.71519 10.9579 3.92614 10.7469 3.92614 10.4868C3.92614 10.2266 3.71519 10.0156 3.45501 10.0156C3.19483 10.0156 2.98389 10.2266 2.98389 10.4868C2.98389 10.747 3.19483 10.9579 3.45501 10.9579ZM3.45501 10.3297C3.54174 10.3297 3.61206 10.4 3.61206 10.4868C3.61206 10.5735 3.54174 10.6438 3.45501 10.6438C3.36829 10.6438 3.29797 10.5735 3.29797 10.4868C3.29797 10.4 3.36829 10.3297 3.45501 10.3297Z"
                fill="#FFAA00"
              />
              <path
                d="M10.3647 10.9579C10.6249 10.9579 10.8358 10.7469 10.8358 10.4868C10.8358 10.2266 10.6249 10.0156 10.3647 10.0156C10.1045 10.0156 9.89355 10.2266 9.89355 10.4868C9.89355 10.747 10.1045 10.9579 10.3647 10.9579ZM10.3647 10.3297C10.4514 10.3297 10.5217 10.4 10.5217 10.4868C10.5217 10.5735 10.4514 10.6438 10.3647 10.6438C10.278 10.6438 10.2076 10.5735 10.2076 10.4868C10.2076 10.4 10.278 10.3297 10.3647 10.3297Z"
                fill="#FFAA00"
              />
            </svg>


            <p class="mt-2 mx-2 Lama-Regular text-[13px]  text-[var(--theme-deafult-TeacherWithClick)]" >        {{ item?.minPrice }}  <span > - {{ item?.maxPrice }}</span>
              {{ "TeacherLessonGroupDetail.form.EGP" | translate }}</p>
          </div>
          <div class="flex mt-2" *ngIf="item.duration !=null">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              class="mt-2"

            >
              <g clip-path="url(#clip0_2799_13070)">
                <path
                  d="M10.4033 4.6153L3.31577 0.246428C2.80301 -0.0692515 2.18306 -0.0823765 1.65738 0.211319C1.1317 0.504967 0.817871 1.03983 0.817871 1.64197V10.3399C0.817871 11.2504 1.55147 11.995 2.45311 12C2.45557 12 2.45803 12 2.46044 12C2.74221 12 3.03584 11.9116 3.31008 11.7443C3.5307 11.6097 3.60042 11.3217 3.46582 11.1012C3.33122 10.8805 3.04322 10.8108 2.82267 10.9454C2.6944 11.0236 2.56912 11.0641 2.45815 11.0641C2.11795 11.0622 1.75371 10.7708 1.75371 10.3399V1.64199C1.75371 1.38369 1.88833 1.1543 2.1138 1.02833C2.3393 0.90235 2.6052 0.907975 2.82488 1.04323L9.91241 5.4121C10.1254 5.54326 10.2473 5.76195 10.2468 6.01212C10.2463 6.26227 10.1235 6.48048 9.90932 6.61119L4.785 9.74872C4.5646 9.88367 4.49532 10.1717 4.63027 10.3921C4.7652 10.6125 5.05327 10.6818 5.27368 10.5469L10.3974 7.4097C10.888 7.11043 11.1815 6.58871 11.1827 6.014C11.1839 5.43936 10.8924 4.91642 10.4033 4.6153Z"
                  fill="#FFAA00"
                />
              </g>
              <defs>
                <clipPath id="clip0_2799_13070">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p    class="mt-2 ml-2 Lama-Regular text-[13px]  text-[var(--theme-deafult-TeacherWithClick)]">
              {{ convertDurationToHours(item?.duration) }}
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="text-center" *ngIf="!TeachersMostBooked || !TeachersMostBooked.length">

  <div class="my-5 px-8  flex justify-center" [ngSwitch]="type">

    <div class="text-center"   *ngSwitchCase="'MostRatedTeacher'" >
      <img
        src="assets/images/OurMostPopularLessons/empty/MostRatedTeacher.svg"
        alt="calendar"
        class="m-auto"
      />
      <p class="text-[#868B9F] mt-3">
        {{"Showing.NoAvailable"  |translate}} Top  <br />
         Rated Teachers  yet
        <span class="vector-bg"
          >

        </span>
      </p>
    </div>
    <div class="text-center"   *ngSwitchCase="'GetTeachersMostBooked'" >

      <svg width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="106" cy="106" r="106" fill="#F1F3F7"/>
        <path d="M150.17 99.1346C149.697 99.1346 149.224 98.9344 148.878 98.6068C148.551 98.261 148.35 97.7879 148.35 97.3147C148.35 96.8415 148.551 96.3684 148.878 96.0226C149.57 95.3492 150.789 95.3492 151.462 96.0226C151.79 96.3684 151.99 96.8415 151.99 97.3147C151.99 97.8061 151.79 98.261 151.462 98.6068C151.117 98.9344 150.643 99.1346 150.17 99.1346Z" fill="#868B9F"/>
        <path d="M88.2986 131.886C87.8254 131.886 87.3522 131.685 87.0064 131.358C86.6789 131.012 86.4787 130.539 86.4787 130.066C86.4787 129.592 86.6789 129.119 87.0064 128.774C87.698 128.1 88.8991 128.1 89.5907 128.774C89.9182 129.119 90.1184 129.592 90.1184 130.066C90.1184 130.539 89.9182 131.012 89.5907 131.358C89.2449 131.685 88.7717 131.886 88.2986 131.886Z" fill="#868B9F"/>
        <path d="M150.17 113.699C149.166 113.699 148.35 112.884 148.35 111.88V104.6C148.35 103.595 149.166 102.78 150.17 102.78C151.175 102.78 151.99 103.595 151.99 104.6V111.88C151.99 112.884 151.175 113.699 150.17 113.699Z" fill="#868B9F"/>
        <path d="M150.17 91.8488C149.166 91.8488 148.35 91.0334 148.35 90.0289V69.1002C148.35 68.0957 149.166 67.2804 150.17 67.2804C151.175 67.2804 151.99 68.0957 151.99 69.1002V90.0289C151.99 91.0334 151.175 91.8488 150.17 91.8488Z" fill="#868B9F"/>
        <path d="M88.2986 124.606C87.294 124.606 86.4787 123.79 86.4787 122.786V101.297C86.4787 100.292 87.294 99.4766 88.2986 99.4766C89.3031 99.4766 90.1184 100.292 90.1184 101.297V122.786C90.1184 123.79 89.3031 124.606 88.2986 124.606Z" fill="#868B9F"/>
        <path d="M88.2986 146.442C87.294 146.442 86.4787 145.626 86.4787 144.622V137.342C86.4787 136.338 87.294 135.522 88.2986 135.522C89.3031 135.522 90.1184 136.338 90.1184 137.342V144.622C90.1184 145.626 89.3031 146.442 88.2986 146.442Z" fill="#868B9F"/>
        <path d="M112.192 145.542C111.187 145.542 110.372 144.726 110.372 143.722V115.499C110.372 114.494 111.187 113.679 112.192 113.679C113.197 113.679 114.012 114.494 114.012 115.499V143.722C114.012 144.726 113.198 145.542 112.192 145.542Z" fill="#868B9F"/>
        <path d="M146.534 117.323C145.53 117.323 144.714 116.508 144.714 115.503C144.714 114.499 145.53 113.684 146.534 113.684C147.537 113.684 148.354 112.866 148.354 111.864C148.354 110.859 149.169 110.044 150.174 110.044C151.179 110.044 151.994 110.859 151.994 111.864C151.994 114.874 149.544 117.323 146.534 117.323Z" fill="#868B9F"/>
        <path d="M146.535 117.319H88.2986C87.294 117.319 86.4787 116.503 86.4787 115.499C86.4787 114.494 87.294 113.679 88.2986 113.679H146.535C147.539 113.679 148.355 114.494 148.355 115.499C148.355 116.503 147.539 117.319 146.535 117.319Z" fill="#868B9F"/>
        <path d="M139.253 85.4819H112.617C111.613 85.4819 110.798 84.6666 110.798 83.662C110.798 82.6574 111.613 81.8421 112.617 81.8421H139.253C140.258 81.8421 141.073 82.6574 141.073 83.662C141.073 84.6666 140.258 85.4819 139.253 85.4819Z" fill="#868B9F"/>
        <path d="M139.255 92.7633H114.659C113.655 92.7633 112.839 91.948 112.839 90.9434C112.839 89.9389 113.655 89.1236 114.659 89.1236H139.255C140.259 89.1236 141.075 89.9389 141.075 90.9434C141.075 91.948 140.259 92.7633 139.255 92.7633Z" fill="#868B9F"/>
        <path d="M139.252 100.035H111.034C110.03 100.035 109.215 99.2195 109.215 98.2149C109.215 97.2103 110.03 96.395 111.034 96.395H139.252C140.256 96.395 141.072 97.2103 141.072 98.2149C141.072 99.2195 140.256 100.035 139.252 100.035Z" fill="#868B9F"/>
        <path d="M131.973 78.2104H97.3951C96.3905 78.2104 95.5752 77.3951 95.5752 76.3905C95.5752 75.386 96.3905 74.5707 97.3951 74.5707H131.973C132.977 74.5707 133.793 75.386 133.793 76.3905C133.793 77.3951 132.977 78.2104 131.973 78.2104Z" fill="#868B9F"/>
        <path d="M114.686 67.2795C113.681 67.2795 112.866 66.4642 112.866 65.4596V61.8199C112.866 60.8153 113.681 60 114.686 60C115.69 60 116.506 60.8153 116.506 61.8199V65.4596C116.506 66.4642 115.69 67.2795 114.686 67.2795Z" fill="#868B9F"/>
        <path d="M79.1976 78.1924C78.193 78.1924 77.3777 77.3771 77.3777 76.3725V69.1002C77.3777 68.0957 78.193 67.2804 79.1976 67.2804C80.2021 67.2804 81.0175 68.0957 81.0175 69.1002V76.3725C81.0175 77.3771 80.2021 78.1924 79.1976 78.1924Z" fill="#868B9F"/>
        <path d="M79.1976 70.9247C78.193 70.9247 77.3777 70.1094 77.3777 69.1048C77.3777 66.0947 79.8273 63.6451 82.8373 63.6451C83.8419 63.6451 84.6572 64.4605 84.6572 65.465C84.6572 66.4696 83.8419 67.2849 82.8373 67.2849C81.8346 67.2849 81.0175 68.102 81.0175 69.1048C81.0175 70.1094 80.2021 70.9247 79.1976 70.9247Z" fill="#868B9F"/>
        <path d="M146.534 67.2849H82.8378C81.8332 67.2849 81.0179 66.4696 81.0179 65.465C81.0179 64.4605 81.8332 63.6451 82.8378 63.6451H146.534C147.538 63.6451 148.353 64.4605 148.353 65.465C148.353 66.4696 147.538 67.2849 146.534 67.2849Z" fill="#868B9F"/>
        <path d="M150.174 70.9247C149.169 70.9247 148.354 70.1094 148.354 69.1048C148.354 68.102 147.537 67.2849 146.534 67.2849C145.53 67.2849 144.714 66.4696 144.714 65.465C144.714 64.4605 145.53 63.6451 146.534 63.6451C149.544 63.6451 151.994 66.0947 151.994 69.1048C151.994 70.1094 151.179 70.9247 150.174 70.9247Z" fill="#868B9F"/>
        <path d="M67.9865 97.498C67.2167 97.498 66.4997 97.0048 66.254 96.2295C65.9483 95.2723 66.4779 94.2477 67.4351 93.9438C73.3042 92.0711 79.6028 91.7017 85.6521 92.8773C86.6385 93.0684 87.2827 94.0238 87.0935 95.0102C86.9024 95.9966 85.9506 96.6336 84.9606 96.4516C79.5064 95.3906 73.8302 95.7254 68.5416 97.4125C68.356 97.4689 68.1703 97.498 67.9865 97.498Z" fill="#868B9F"/>
        <path d="M85.3087 96.4677C84.6245 96.4677 83.9675 96.0801 83.6581 95.4158C83.2341 94.5059 83.6272 93.423 84.5389 92.9972L101.467 85.1043C102.374 84.6785 103.462 85.0716 103.886 85.9852C104.31 86.8951 103.917 87.9779 103.005 88.4038L86.0767 96.2966C85.8292 96.4149 85.5672 96.4677 85.3087 96.4677Z" fill="#868B9F"/>
        <path d="M105.314 95.1916C104.63 95.1916 103.973 94.804 103.663 94.1416C103.239 93.2316 103.632 92.147 104.542 91.723C106.009 91.0387 105.443 89.6301 105.314 89.3553C105.187 89.0805 104.479 87.7447 103.003 88.4253C102.095 88.8512 101.008 88.4581 100.584 87.5445C100.16 86.6346 100.553 85.5517 101.465 85.1259C104.639 83.6427 107.465 85.357 108.612 87.8175C109.758 90.2762 109.256 93.541 106.08 95.0224C105.834 95.1371 105.572 95.1916 105.314 95.1916Z" fill="#868B9F"/>
        <path d="M88.3 103.117C87.6157 103.117 86.9587 102.73 86.6494 102.067C86.2253 101.156 86.6184 100.073 87.5284 99.6488L104.544 91.7141C105.454 91.2864 106.539 91.6831 106.963 92.5931C107.387 93.5048 106.994 94.5877 106.084 95.0117L89.068 102.946C88.8205 103.063 88.5584 103.117 88.3 103.117Z" fill="#868B9F"/>
        <path d="M95.1563 151.001C94.6485 151.001 94.1426 150.79 93.7841 150.376C93.1235 149.618 93.2035 148.469 93.9606 147.809L110.997 132.969C111.755 132.309 112.904 132.389 113.564 133.146C114.225 133.905 114.145 135.053 113.388 135.714L96.3519 150.553C96.0062 150.853 95.5803 151.001 95.1563 151.001Z" fill="#868B9F"/>
        <path d="M129.225 151.001C128.801 151.001 128.375 150.853 128.031 150.553L110.995 135.714C110.236 135.053 110.158 133.905 110.818 133.146C111.477 132.389 112.627 132.309 113.386 132.969L130.422 147.809C131.181 148.469 131.259 149.618 130.599 150.376C130.238 150.79 129.734 151.001 129.225 151.001Z" fill="#868B9F"/>
        <path d="M62.8199 123.233C61.8153 123.233 61 122.417 61 121.413V102.639C61 101.634 61.8153 100.819 62.8199 100.819C63.8245 100.819 64.6398 101.634 64.6398 102.639V121.413C64.6398 122.417 63.8245 123.233 62.8199 123.233Z" fill="#868B9F"/>
        <path d="M62.8199 104.46C61.8153 104.46 61 103.645 61 102.641C61 98.6022 63.597 95.1026 67.4624 93.9306C68.4233 93.6376 69.4406 94.1836 69.7318 95.1444C70.023 96.1072 69.4788 97.1227 68.5179 97.4138C66.1976 98.1181 64.6398 100.218 64.6398 102.641C64.6398 103.645 63.8245 104.46 62.8199 104.46Z" fill="#868B9F"/>
        <path d="M70.0986 146.44C69.094 146.44 68.2787 145.625 68.2787 144.62V104.61C68.2787 103.605 69.094 102.79 70.0986 102.79C71.1031 102.79 71.9184 103.605 71.9184 104.61V144.62C71.9184 145.625 71.1031 146.44 70.0986 146.44Z" fill="#868B9F"/>
        <path d="M79.1993 95.8257C73.4321 95.8257 68.5585 90.9521 68.5585 85.1849C68.5585 79.4177 73.4321 74.544 79.1993 74.544C84.9665 74.544 89.8402 79.4158 89.8402 85.1849C89.8402 90.9539 84.9665 95.8257 79.1993 95.8257ZM79.1993 78.1856C75.4049 78.1856 72.1983 81.3922 72.1983 85.1867C72.1983 88.9811 75.4049 92.1878 79.1993 92.1878C82.9938 92.1878 86.2004 88.9811 86.2004 85.1867C86.2004 81.3904 82.9938 78.1856 79.1993 78.1856Z" fill="#868B9F"/>
        <path d="M79.1976 146.45C78.193 146.45 77.3777 145.635 77.3777 144.63V121.419C77.3777 120.415 78.193 119.599 79.1976 119.599C80.2021 119.599 81.0175 120.415 81.0175 121.419V144.63C81.0175 145.635 80.2021 146.45 79.1976 146.45Z" fill="#868B9F"/>
        <path d="M66.4596 126.761C63.7462 126.761 61 124.926 61 121.419C61 120.415 61.8153 119.599 62.8199 119.599C63.8245 119.599 64.6398 120.415 64.6398 121.419C64.6398 123.039 66.1557 123.121 66.4596 123.121C66.7636 123.121 68.2795 123.039 68.2795 121.419C68.2795 120.415 69.0948 119.599 70.0994 119.599C71.104 119.599 71.9193 120.415 71.9193 121.419C71.9193 124.926 69.1731 126.761 66.4596 126.761Z" fill="#868B9F"/>
        <path d="M83.7473 150.853C82.1203 150.853 80.588 150.307 79.4323 149.313C78.1075 148.174 77.3777 146.511 77.3777 144.632C77.3777 143.628 78.193 142.813 79.1976 142.813C80.2021 142.813 81.0175 143.628 81.0175 144.632C81.0175 147.14 83.4688 147.213 83.7473 147.213C84.0257 147.213 86.4771 147.14 86.4771 144.632C86.4771 143.628 87.2924 142.813 88.297 142.813C89.3016 142.813 90.1169 143.628 90.1169 144.632C90.1169 148.716 86.912 150.853 83.7473 150.853Z" fill="#868B9F"/>
        <path d="M74.6483 150.853C73.0213 150.853 71.4889 150.307 70.3333 149.313C69.0084 148.174 68.2787 146.511 68.2787 144.632C68.2787 143.628 69.094 142.813 70.0986 142.813C71.1031 142.813 71.9184 143.628 71.9184 144.632C71.9184 147.14 74.3698 147.213 74.6483 147.213C74.9267 147.213 77.3781 147.14 77.3781 144.632C77.3781 143.628 78.1934 142.813 79.198 142.813C80.2025 142.813 81.0178 143.628 81.0178 144.632C81.0178 148.716 77.813 150.853 74.6483 150.853Z" fill="#868B9F"/>
        </svg>

      <p class="text-[#868B9F] mt-3">
        {{"Showing.NoAvailable"  |translate}} most  <br />
        booked teachers yet
        <span class="vector-bg"
          >

        </span>
      </p>
    </div>

    <div class="text-center"   *ngSwitchDefault  >
      <div>
        <svg width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="106" cy="106" r="106" fill="#F1F3F7"/>
          <path d="M150.17 99.1346C149.697 99.1346 149.224 98.9344 148.878 98.6068C148.551 98.261 148.35 97.7879 148.35 97.3147C148.35 96.8415 148.551 96.3684 148.878 96.0226C149.57 95.3492 150.789 95.3492 151.462 96.0226C151.79 96.3684 151.99 96.8415 151.99 97.3147C151.99 97.8061 151.79 98.261 151.462 98.6068C151.117 98.9344 150.643 99.1346 150.17 99.1346Z" fill="#868B9F"/>
          <path d="M88.2986 131.886C87.8254 131.886 87.3522 131.685 87.0064 131.358C86.6789 131.012 86.4787 130.539 86.4787 130.066C86.4787 129.592 86.6789 129.119 87.0064 128.774C87.698 128.1 88.8991 128.1 89.5907 128.774C89.9182 129.119 90.1184 129.592 90.1184 130.066C90.1184 130.539 89.9182 131.012 89.5907 131.358C89.2449 131.685 88.7717 131.886 88.2986 131.886Z" fill="#868B9F"/>
          <path d="M150.17 113.699C149.166 113.699 148.35 112.884 148.35 111.88V104.6C148.35 103.595 149.166 102.78 150.17 102.78C151.175 102.78 151.99 103.595 151.99 104.6V111.88C151.99 112.884 151.175 113.699 150.17 113.699Z" fill="#868B9F"/>
          <path d="M150.17 91.8488C149.166 91.8488 148.35 91.0334 148.35 90.0289V69.1002C148.35 68.0957 149.166 67.2804 150.17 67.2804C151.175 67.2804 151.99 68.0957 151.99 69.1002V90.0289C151.99 91.0334 151.175 91.8488 150.17 91.8488Z" fill="#868B9F"/>
          <path d="M88.2986 124.606C87.294 124.606 86.4787 123.79 86.4787 122.786V101.297C86.4787 100.292 87.294 99.4766 88.2986 99.4766C89.3031 99.4766 90.1184 100.292 90.1184 101.297V122.786C90.1184 123.79 89.3031 124.606 88.2986 124.606Z" fill="#868B9F"/>
          <path d="M88.2986 146.442C87.294 146.442 86.4787 145.626 86.4787 144.622V137.342C86.4787 136.338 87.294 135.522 88.2986 135.522C89.3031 135.522 90.1184 136.338 90.1184 137.342V144.622C90.1184 145.626 89.3031 146.442 88.2986 146.442Z" fill="#868B9F"/>
          <path d="M112.192 145.542C111.187 145.542 110.372 144.726 110.372 143.722V115.499C110.372 114.494 111.187 113.679 112.192 113.679C113.197 113.679 114.012 114.494 114.012 115.499V143.722C114.012 144.726 113.198 145.542 112.192 145.542Z" fill="#868B9F"/>
          <path d="M146.534 117.323C145.53 117.323 144.714 116.508 144.714 115.503C144.714 114.499 145.53 113.684 146.534 113.684C147.537 113.684 148.354 112.866 148.354 111.864C148.354 110.859 149.169 110.044 150.174 110.044C151.179 110.044 151.994 110.859 151.994 111.864C151.994 114.874 149.544 117.323 146.534 117.323Z" fill="#868B9F"/>
          <path d="M146.535 117.319H88.2986C87.294 117.319 86.4787 116.503 86.4787 115.499C86.4787 114.494 87.294 113.679 88.2986 113.679H146.535C147.539 113.679 148.355 114.494 148.355 115.499C148.355 116.503 147.539 117.319 146.535 117.319Z" fill="#868B9F"/>
          <path d="M139.253 85.4819H112.617C111.613 85.4819 110.798 84.6666 110.798 83.662C110.798 82.6574 111.613 81.8421 112.617 81.8421H139.253C140.258 81.8421 141.073 82.6574 141.073 83.662C141.073 84.6666 140.258 85.4819 139.253 85.4819Z" fill="#868B9F"/>
          <path d="M139.255 92.7633H114.659C113.655 92.7633 112.839 91.948 112.839 90.9434C112.839 89.9389 113.655 89.1236 114.659 89.1236H139.255C140.259 89.1236 141.075 89.9389 141.075 90.9434C141.075 91.948 140.259 92.7633 139.255 92.7633Z" fill="#868B9F"/>
          <path d="M139.252 100.035H111.034C110.03 100.035 109.215 99.2195 109.215 98.2149C109.215 97.2103 110.03 96.395 111.034 96.395H139.252C140.256 96.395 141.072 97.2103 141.072 98.2149C141.072 99.2195 140.256 100.035 139.252 100.035Z" fill="#868B9F"/>
          <path d="M131.973 78.2104H97.3951C96.3905 78.2104 95.5752 77.3951 95.5752 76.3905C95.5752 75.386 96.3905 74.5707 97.3951 74.5707H131.973C132.977 74.5707 133.793 75.386 133.793 76.3905C133.793 77.3951 132.977 78.2104 131.973 78.2104Z" fill="#868B9F"/>
          <path d="M114.686 67.2795C113.681 67.2795 112.866 66.4642 112.866 65.4596V61.8199C112.866 60.8153 113.681 60 114.686 60C115.69 60 116.506 60.8153 116.506 61.8199V65.4596C116.506 66.4642 115.69 67.2795 114.686 67.2795Z" fill="#868B9F"/>
          <path d="M79.1976 78.1924C78.193 78.1924 77.3777 77.3771 77.3777 76.3725V69.1002C77.3777 68.0957 78.193 67.2804 79.1976 67.2804C80.2021 67.2804 81.0175 68.0957 81.0175 69.1002V76.3725C81.0175 77.3771 80.2021 78.1924 79.1976 78.1924Z" fill="#868B9F"/>
          <path d="M79.1976 70.9247C78.193 70.9247 77.3777 70.1094 77.3777 69.1048C77.3777 66.0947 79.8273 63.6451 82.8373 63.6451C83.8419 63.6451 84.6572 64.4605 84.6572 65.465C84.6572 66.4696 83.8419 67.2849 82.8373 67.2849C81.8346 67.2849 81.0175 68.102 81.0175 69.1048C81.0175 70.1094 80.2021 70.9247 79.1976 70.9247Z" fill="#868B9F"/>
          <path d="M146.534 67.2849H82.8378C81.8332 67.2849 81.0179 66.4696 81.0179 65.465C81.0179 64.4605 81.8332 63.6451 82.8378 63.6451H146.534C147.538 63.6451 148.353 64.4605 148.353 65.465C148.353 66.4696 147.538 67.2849 146.534 67.2849Z" fill="#868B9F"/>
          <path d="M150.174 70.9247C149.169 70.9247 148.354 70.1094 148.354 69.1048C148.354 68.102 147.537 67.2849 146.534 67.2849C145.53 67.2849 144.714 66.4696 144.714 65.465C144.714 64.4605 145.53 63.6451 146.534 63.6451C149.544 63.6451 151.994 66.0947 151.994 69.1048C151.994 70.1094 151.179 70.9247 150.174 70.9247Z" fill="#868B9F"/>
          <path d="M67.9865 97.498C67.2167 97.498 66.4997 97.0048 66.254 96.2295C65.9483 95.2723 66.4779 94.2477 67.4351 93.9438C73.3042 92.0711 79.6028 91.7017 85.6521 92.8773C86.6385 93.0684 87.2827 94.0238 87.0935 95.0102C86.9024 95.9966 85.9506 96.6336 84.9606 96.4516C79.5064 95.3906 73.8302 95.7254 68.5416 97.4125C68.356 97.4689 68.1703 97.498 67.9865 97.498Z" fill="#868B9F"/>
          <path d="M85.3087 96.4677C84.6245 96.4677 83.9675 96.0801 83.6581 95.4158C83.2341 94.5059 83.6272 93.423 84.5389 92.9972L101.467 85.1043C102.374 84.6785 103.462 85.0716 103.886 85.9852C104.31 86.8951 103.917 87.9779 103.005 88.4038L86.0767 96.2966C85.8292 96.4149 85.5672 96.4677 85.3087 96.4677Z" fill="#868B9F"/>
          <path d="M105.314 95.1916C104.63 95.1916 103.973 94.804 103.663 94.1416C103.239 93.2316 103.632 92.147 104.542 91.723C106.009 91.0387 105.443 89.6301 105.314 89.3553C105.187 89.0805 104.479 87.7447 103.003 88.4253C102.095 88.8512 101.008 88.4581 100.584 87.5445C100.16 86.6346 100.553 85.5517 101.465 85.1259C104.639 83.6427 107.465 85.357 108.612 87.8175C109.758 90.2762 109.256 93.541 106.08 95.0224C105.834 95.1371 105.572 95.1916 105.314 95.1916Z" fill="#868B9F"/>
          <path d="M88.3 103.117C87.6157 103.117 86.9587 102.73 86.6494 102.067C86.2253 101.156 86.6184 100.073 87.5284 99.6488L104.544 91.7141C105.454 91.2864 106.539 91.6831 106.963 92.5931C107.387 93.5048 106.994 94.5877 106.084 95.0117L89.068 102.946C88.8205 103.063 88.5584 103.117 88.3 103.117Z" fill="#868B9F"/>
          <path d="M95.1563 151.001C94.6485 151.001 94.1426 150.79 93.7841 150.376C93.1235 149.618 93.2035 148.469 93.9606 147.809L110.997 132.969C111.755 132.309 112.904 132.389 113.564 133.146C114.225 133.905 114.145 135.053 113.388 135.714L96.3519 150.553C96.0062 150.853 95.5803 151.001 95.1563 151.001Z" fill="#868B9F"/>
          <path d="M129.225 151.001C128.801 151.001 128.375 150.853 128.031 150.553L110.995 135.714C110.236 135.053 110.158 133.905 110.818 133.146C111.477 132.389 112.627 132.309 113.386 132.969L130.422 147.809C131.181 148.469 131.259 149.618 130.599 150.376C130.238 150.79 129.734 151.001 129.225 151.001Z" fill="#868B9F"/>
          <path d="M62.8199 123.233C61.8153 123.233 61 122.417 61 121.413V102.639C61 101.634 61.8153 100.819 62.8199 100.819C63.8245 100.819 64.6398 101.634 64.6398 102.639V121.413C64.6398 122.417 63.8245 123.233 62.8199 123.233Z" fill="#868B9F"/>
          <path d="M62.8199 104.46C61.8153 104.46 61 103.645 61 102.641C61 98.6022 63.597 95.1026 67.4624 93.9306C68.4233 93.6376 69.4406 94.1836 69.7318 95.1444C70.023 96.1072 69.4788 97.1227 68.5179 97.4138C66.1976 98.1181 64.6398 100.218 64.6398 102.641C64.6398 103.645 63.8245 104.46 62.8199 104.46Z" fill="#868B9F"/>
          <path d="M70.0986 146.44C69.094 146.44 68.2787 145.625 68.2787 144.62V104.61C68.2787 103.605 69.094 102.79 70.0986 102.79C71.1031 102.79 71.9184 103.605 71.9184 104.61V144.62C71.9184 145.625 71.1031 146.44 70.0986 146.44Z" fill="#868B9F"/>
          <path d="M79.1993 95.8257C73.4321 95.8257 68.5585 90.9521 68.5585 85.1849C68.5585 79.4177 73.4321 74.544 79.1993 74.544C84.9665 74.544 89.8402 79.4158 89.8402 85.1849C89.8402 90.9539 84.9665 95.8257 79.1993 95.8257ZM79.1993 78.1856C75.4049 78.1856 72.1983 81.3922 72.1983 85.1867C72.1983 88.9811 75.4049 92.1878 79.1993 92.1878C82.9938 92.1878 86.2004 88.9811 86.2004 85.1867C86.2004 81.3904 82.9938 78.1856 79.1993 78.1856Z" fill="#868B9F"/>
          <path d="M79.1976 146.45C78.193 146.45 77.3777 145.635 77.3777 144.63V121.419C77.3777 120.415 78.193 119.599 79.1976 119.599C80.2021 119.599 81.0175 120.415 81.0175 121.419V144.63C81.0175 145.635 80.2021 146.45 79.1976 146.45Z" fill="#868B9F"/>
          <path d="M66.4596 126.761C63.7462 126.761 61 124.926 61 121.419C61 120.415 61.8153 119.599 62.8199 119.599C63.8245 119.599 64.6398 120.415 64.6398 121.419C64.6398 123.039 66.1557 123.121 66.4596 123.121C66.7636 123.121 68.2795 123.039 68.2795 121.419C68.2795 120.415 69.0948 119.599 70.0994 119.599C71.104 119.599 71.9193 120.415 71.9193 121.419C71.9193 124.926 69.1731 126.761 66.4596 126.761Z" fill="#868B9F"/>
          <path d="M83.7473 150.853C82.1203 150.853 80.588 150.307 79.4323 149.313C78.1075 148.174 77.3777 146.511 77.3777 144.632C77.3777 143.628 78.193 142.813 79.1976 142.813C80.2021 142.813 81.0175 143.628 81.0175 144.632C81.0175 147.14 83.4688 147.213 83.7473 147.213C84.0257 147.213 86.4771 147.14 86.4771 144.632C86.4771 143.628 87.2924 142.813 88.297 142.813C89.3016 142.813 90.1169 143.628 90.1169 144.632C90.1169 148.716 86.912 150.853 83.7473 150.853Z" fill="#868B9F"/>
          <path d="M74.6483 150.853C73.0213 150.853 71.4889 150.307 70.3333 149.313C69.0084 148.174 68.2787 146.511 68.2787 144.632C68.2787 143.628 69.094 142.813 70.0986 142.813C71.1031 142.813 71.9184 143.628 71.9184 144.632C71.9184 147.14 74.3698 147.213 74.6483 147.213C74.9267 147.213 77.3781 147.14 77.3781 144.632C77.3781 143.628 78.1934 142.813 79.198 142.813C80.2025 142.813 81.0178 143.628 81.0178 144.632C81.0178 148.716 77.813 150.853 74.6483 150.853Z" fill="#868B9F"/>
          </svg>

      </div>
      <p class="text-[#868B9F] mt-3">
        {{"Showing.NoAvailable"  |translate}} most  <br />
       viewed teachers yet
        <span class="vector-bg"
          >

        </span>
      </p>
    </div>

  </div>

</div>
</div>
