import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'customPipeForImages',
  standalone: true

})
export class CustomPipeForImagesPipe implements PipeTransform {

  transform(contactImage: string): string {
    return environment.serverFirstHalfOfImageUrl+contactImage ;
  }

}
