import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import {  IGetMyDocuments } from 'src/app/shared/Model/teacher/documents-information';
import { IManageLessonsMatrials, IModelSearchMaterial } from 'src/app/shared/Model/teacher/manage-lessons-matrials';



@Injectable({
  providedIn: 'root'
})
export class ManageLessonsMatrialsService {


  constructor(private http: HttpClient) {}

  GetMyLessonMaterials( ModelSearchMaterial: IModelSearchMaterial):Observable<IManageLessonsMatrials>{

    return this.http.post<IGenericResponseObject<IManageLessonsMatrials>>(`${env.Server_URL}TeacherLessonMaterial/GetMyLessonMaterials`,ModelSearchMaterial).pipe( map((res) => res.data ))
  }
  GetMaterialType():Observable<any>{

    return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}MaterialType/Get`).pipe( map((res) => res.data ))
  }

  AddTeacherLessonMaterial(data:any):Observable<IGenericResponseObject<IModelSearchMaterial>>{
    return this.http.post<IGenericResponseObject<IModelSearchMaterial>>(`${env.Server_URL}TeacherLessonMaterial/Create`,data)
  }
  UpdateTeacherLessonMaterial(data:any):Observable<IGenericResponseObject<IModelSearchMaterial>>{
    return this.http.post<IGenericResponseObject<IModelSearchMaterial>>(`${env.Server_URL}TeacherLessonMaterial/Update`,data)
  }


    DeleteTeacherLessonMaterial(id:number):Observable<IGenericResponse<any[]>>{
      return this.http.get<IGenericResponse<any>>(`${env.Server_URL}TeacherLessonMaterial/Delete?id=${id}`).pipe( map((res) => res ))
    }
}


