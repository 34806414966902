<div
*ngIf=" (ParentService.MyChildren$ | async)!.length > 0"
    dir="ltr"
    class="col-12"
  [ngClass]="{
    'col-md-8': (ParentService.MyChildren$ | async)!.length <= 4,
    'col-md-12': (ParentService.MyChildren$ | async)!.length > 4
  }"
>
  <owl-carousel-o
    [options]="
      (ParentService.MyChildren$ | async)!.length <= 4
        ? InstaSliderConfig
        : ParentChildernliderConfig
    "
    dir="ltr"
  >
    <ng-container
      *ngFor="let item of ParentService.MyChildren$ | async; let i = index"
    >
      <ng-template carouselSlide [id]="item.id.toString()">
        <div
          class="slide cursor-pointer border-0 m-4 mb-3 ml-2 StudentsCardList hover-effect text-center"
        >
          <div
            class="row align-items-center"
            [ngClass]="{
              selected: Childrenindex === i || childrenIdFromService == item.id,
              notSelected:
                Childrenindex !== i && childrenIdFromService != item.id
            }"
            (click)="ChangeStudent(item.id); this.selectStudent.emit(item.id)"
          >
            <div class="col-3">
              <img
                *ngIf="item?.image"
                [src]="ImgUrlServer + item?.image"
                class="fixed-size-img mr-2"
              />
              <img
                *ngIf="!item?.image"
                src="assets/images/MrS-Cool/subjectDetails.png"
                class="fixed-size-img mr-2"
              />
            </div>
            <div class="col-9 text-start">
              <h3 class="mb-0 sora-SemiBold text-[16px] dark:text-white">
                {{ item.name }}
              </h3>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
