<!-- <div class="w-full sm:w-1/1 md:w-1/1  xl:w-1/1 pt-5 px-3 md:ps-12  "> -->
  <div
  class="  mt20 bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)]  md:px -14"
>

<div
class="sm:p-5 pt-5 md:p-10"
>
  <form
    [formGroup]="insertForm"
    (ngSubmit)="onSubmit()"
  >
  <div class="text-center">
    <p class="text-black text-center text-[16px] Lama-Regular">
      <a
        href="https://www.youtube.com/watch?v=op5L2L0ROvg"
        target="_blank"
        class="text-black"
      >
              {{ "TeacherSignUp.header.Explanation" | translate }} *
      </a>
    </p>
  </div>
    <div class="grid gap-0 grid-cols-3 ">
      <h2
        class="text-[16px] col-span-2 text-[var(--theme-deafult-TeacherWithClick)] lg:text-[30px] text-left Lama-bold dark:text-white"
      >
        {{ "SubjectInfo.header.Title" |translate}}
      </h2>
      <p class="  text-right text-[14px] text-[var(--theme-deafult-TeacherWithClick)] Lama-Regular dark:text-white">
        (2/3)
      </p>

      <!-- <p class="mb-2 text-left text-[13px]  text-[var(--theme-deafult-TeacherWithClick)] Lama-Regular">
        {{  "SubjectInfo.header.Enter-subtitle-here" |translate }}
      </p> -->

    </div>
    <p class="text-left text-[14px] Lama-Regular">
      <span class=" text-[#FF0000]  text-[12px] md:text-[16px] Lama-SemiBold "> * </span> <span class="text-[var(--theme-deafult-TeacherWithClick)] ">{{ "SubjectInfo.form.Percentage-Mr-S-Cool" | translate }}</span>
    </p>
    <p class="text-left text-[14px] Lama-Regular m-0">
      <span class=" text-[#FF0000]  text-[12px] md:text-[16px] Lama-SemiBold "> * </span> <span class="text-[var(--theme-deafult-TeacherWithClick)] ">{{ "SubjectInfo.form.Percentage-Teacher" | translate }}</span>
    </p>
    <div class="grid gap-0 mb-6 md:grid-cols-1 mt-1">

      <div>
        <label
          for="Education_"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "SubjectInfo.form.Education-Type" |translate  | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >
        <ng-select
          id="Education_"
          #EducationType
          [selectOnTab]="true"
          formControlName="EducationTypeId"
          class="form-control p-0  Lama-Ligh fixed-width-ng-select"
          (change)="ChangEducationType()"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['EducationTypeId'].errors?.['required'] ?  ('SubjectInfo.form.required' | translate) : null"
          [isDisabled]="
            !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
          "
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['EducationTypeId'].valid &&
            (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
          "
          [class.is-invalid]="
            fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
          "
          [ngClass]="{
      'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
      'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
    }"
        >
          <ng-option value="" class="Lama-Ligh">
            {{ "SubjectInfo.form.Choose-your-EducationType" | translate }}
          </ng-option>
          <ng-option class="Lama-Ligh" *ngFor="let item of EducationTypes" [value]="item.id"
            >{{ item.name }}
          </ng-option>
        </ng-select>
      </div>
      <div>
        <label
          for="SubjectSemesterYearId_"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "SubjectInfo.form.Education-Level" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >

        <ng-select
          id="EducationLevel_"
          #EducationType
          [selectOnTab]="true"
          formControlName="EducationLevelId"
          class="Lama-Ligh form-control p-0 fixed-width-ng-select"
          (change)="ChangEducationLevel()"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['EducationLevelId'].errors?.['required'] ?  ('SubjectInfo.form.required' | translate) : null"
          [isDisabled]="
            !fc['EducationLevelId'].errors && fc['EducationLevelId'].touched
          "
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['EducationLevelId'].valid &&
            (fc['EducationLevelId'].touched || fc['EducationLevelId'].value)
          "
          [class.is-invalid]="
            fc['EducationLevelId'].invalid && fc['EducationLevelId'].touched
          "
          [ngClass]="{
      'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
      'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
    }"
        >
          <ng-option class="Lama-Ligh" value="">
            {{ "SubjectInfo.form.Choose-your-EducationLevel" | translate }}
          </ng-option>
          <ng-option class="Lama-Ligh" *ngFor="let item of EducationLevel" [value]="item.id"
            >{{ item.name }}
          </ng-option>
        </ng-select>
      </div>
      <div>
        <label
          for="countries"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "SubjectInfo.form.Subjects" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >

        <ng-select
          id="SubjectSemester_"
          #SubjectSemesterRev
          [selectOnTab]="true"
          formControlName="SubjectSemesterId"
          class="Lama-Ligh form-control p-0 fixed-width-ng-select"
          (change)="ChangAcademicYear()"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['SubjectSemesterId'].errors?.['required'] ?  ('SubjectInfo.form.required' | translate) : null"
          [isDisabled]="
            !fc['SubjectSemesterId'].errors && fc['SubjectSemesterId'].touched
          "
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['SubjectSemesterId'].valid &&
            (fc['SubjectSemesterId'].touched || fc['SubjectSemesterId'].value)
          "
          [class.is-invalid]="
            fc['SubjectSemesterId'].invalid && fc['SubjectSemesterId'].touched
          "
          [ngClass]="{
            'class-1': fc['SubjectSemesterId'].invalid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
            'class-2': fc['SubjectSemesterId'].valid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
          }"
        >
          <ng-option class="Lama-Ligh" value="">
            {{ "SubjectInfo.form.Choose-Subjects" | translate }}
          </ng-option>
          <ng-option class="Lama-Ligh" *ngFor="let item of SubjectsList" [value]="item.id"
            >{{ item.name | translate}}
          </ng-option>
        </ng-select>


      </div>
        <div>
        <label
          for="countries"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "SubjectInfo.form.Study-materials-for-the-stage" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >
        <!-- <ng-multiselect-dropdown
        class="Lama-Ligh w-100 ngSelectMin-heigh"
  formControlName="SubjectSemesterYearIds"
  id="validationCustom02=3"
  [settings]="dropdownSettings"
  [data]="SubjectSemester"
  mult="false"
  [tooltip]="fc['SubjectSemesterYearIds'].errors?.['required'] ? ('SubjectInfo.form.required' | translate) : null"
  [isDisabled]="!fc['SubjectSemesterYearIds'].errors && fc['SubjectSemesterYearIds'].touched"
   [ngClass]="{
    'class-1': fc['SubjectSemesterYearIds'].invalid && (fc['SubjectSemesterYearIds'].dirty || fc['SubjectSemesterYearIds'].touched),
    'class-2': fc['SubjectSemesterYearIds'].valid && (fc['SubjectSemesterYearIds'].dirty || fc['SubjectSemesterYearIds'].touched)
  }"

  [class.is-valid]="
  fc['SubjectSemesterYearIds'].valid &&
  (fc['SubjectSemesterYearIds'].touched || fc['SubjectSemesterYearIds'].value)
"
[class.is-invalid]="
  fc['SubjectSemesterYearIds'].invalid && fc['SubjectSemesterYearIds'].touched
"


>
  <ng-option class="Lama-Ligh" value="{{ item.id }}" *ngFor="let item of SubjectSemester">
    {{ item.name }}
  </ng-option>
</ng-multiselect-dropdown> -->



         <ng-select
          id="subjectSemesterYearId_"
          #EducationType
          [selectOnTab]="true"
          formControlName="subjectSemesterYearId"
          class="form-control p-0 fixed-width-ng-select"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['subjectSemesterYearId'].errors?.['required'] ?  ('SubjectInfo.form.required' | translate) : null"
          [isDisabled]="
            !fc['subjectSemesterYearId'].errors &&
            fc['subjectSemesterYearId'].touched
          "
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['subjectSemesterYearId'].valid &&
            (fc['subjectSemesterYearId'].touched ||
              fc['subjectSemesterYearId'].value)
          "
          [class.is-invalid]="
            fc['subjectSemesterYearId'].invalid &&
            fc['subjectSemesterYearId'].touched
          "
          [ngClass]="{
      'class-1': fc['subjectSemesterYearId'].invalid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
      'class-2': fc['subjectSemesterYearId'].valid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
    }"
        >
          <ng-option value="">
            {{ "SubjectInfo.form.Choose-Subjects" | translate }}
          </ng-option>

          <ng-option  class="Lama-Ligh"  *ngFor="let item of SubjectSemester" [value]="item.id"
            >{{ item.name }}
          </ng-option>
        </ng-select>
      </div>

      <div class="max-md:col-span-4">
        <label
          for="IndividuaGroupCostlCost_"
          class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "SubjectInfo.form.groupSessionCost" | translate }}
          <span
            class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
            >*</span
          >
        </label>
        <input
          type="text"
          appOnlyNumbersWithDouble
          formControlName="groupSessionCost"
          [tooltip]="fc['groupSessionCost'].hasError('pattern') ? ('PriceShouldNotStartWithZero' | translate) : (fc['groupSessionCost'].errors?.['required'] ? ('GroupPriceFromRequired' | translate) : null)"
          [ngClass]="{
            'class-1': fc['groupSessionCost'].invalid && (fc['groupSessionCost'].dirty || fc['groupSessionCost'].touched),
            'class-2': fc['groupSessionCost'].valid && (fc['groupSessionCost'].dirty || fc['groupSessionCost'].touched),
          }"
          id="groupSessionCost"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#1a202c] dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter your Price"
          [placeholder]="'Enter_your_Pricer' | translate"
          required
        />

      </div>

    </div>

    <div class="text-right">
      <button
        type="submit"  [disabled]="!submit"
        [ngClass]="{'valid-form': insertForm.valid, 'invalid-form': insertForm.invalid}"
        class="text-[14px] Lama-Regular  text-[#868B9F] bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] font-medium rounded-lg text-sm px-5 py-3 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        {{ "SubjectInfo.form.Save" | translate }}
      </button>
      <button
        type="button"
        class="text-[var(--theme-deafult-TeacherWithClick)] text-[14px] Lama-Regular  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-3 mr-2 mb-2 dark:bg-[#1a202c] dark:text-white dark:border-gray-600 dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:hover:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)]"
        (click)=" this.resetForm();        this.resetArray();   "
      >
        {{ "SubjectInfo.form.Clear" | translate }}
      </button>
    </div>
  </form>

  <p class="text-left text-[14px] Lama-Regular">
    <span class=" text-[#FF0000]  text-[12px] md:text-[16px] Lama-SemiBold "> * </span> <span class="text-[var(--theme-deafult-TeacherWithClick)] ">{{ "SubjectInfo.form.Note-Must-be-enter-one-item-at-least" | translate }}</span>
  </p>
  <div class="relative overflow-x-auto w-full "   *ngIf="TeacherSubjectAcademic && TeacherSubjectAcademic.length >0">
    <!-- *ngIf=" TeacherSubjectAcademic&&TeacherSubjectAcademic.length >0" -->
    <table class="m-auto w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400 bg-[var(--theme-deafult-TeacherWithClick)] rounded-lg  ">
      <thead
        class="Lama-Medium w-full  text-white    first-letter: dark:bg-[#1a202c] rounded-lg dark:text-gray-400"
      >
        <tr>
          <th scope="col" class="px-6 py-3">
            {{ "SubjectInfo.form.Education-Type" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "SubjectInfo.form.Education-Level" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "SubjectInfo.form.Academic-Year" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "SubjectInfo.form.Subjects" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "SubjectInfo.form.groupSessionCost" | translate }}
          </th>
          <th scope="col" class="px-6 py-3 text-end">{{ "SubjectInfo.form.Action" | translate }}</th>
        </tr>
      </thead>
      <tbody class="border border-1 rounded-lg">
        <tr
          class="bg-white border-b dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] rounded-lg  border border-1"
          *ngFor="let item of TeacherSubjectAcademic; let y = index"
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
          >
            {{ item.educationTypeName }}
          </th>
          <td class="px-6 py-4">
            {{ item.educationLevelName }}
          </td>
          <td class="px-6 py-4">
            {{ item.academicYearName }}
          </td>
          <td class="px-6 py-4">
            {{ item.subjectSemesterYearName }}
          </td>
          <td class="px-6 py-4">
            {{ item.groupSessionCost }}
          </td>
          <td class="px-6 py-4">
            <a
              class="font-medium text-red-600 red:text-blue-500 hover:underline"
              (click)="TableDeleteTeacherSubject=item.id ;openModal(template)"
              ><img src="assets/images/Icons/delete.png" class=" " alt=""></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-3 flex justify-between align-center">
    <div class="pr-2">
      <button
        [ngClass]="{
    'bg-[var(--theme-deafult-TeacherWithClick)]': TeacherSubjectAcademic&&(TeacherSubjectAcademic.length >0),
    'bg-[#DDDFE5]':  !TeacherSubjectAcademic||(TeacherSubjectAcademic.length ==0),
    'text-[#868B9F]':  !TeacherSubjectAcademic||(TeacherSubjectAcademic.length ==0),
  }"
        type="submit"
        class="text-white   my-3 bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]  text-[12px] lg:text-[14px] Lama-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-black"
        >
        {{ "SubjectInfo.form.Previous" | translate }}
      </button>
    </div>
    <div class="pl-2 mt-4">
      <i class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5]"></i>
      <i class="fa-solid fa-circle mx-2 text-[8px] text-[var(--theme-deafult-TeacherWithClick)]"></i>
      <i class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5] "
      (click)="TeacherSubjectAcademic?navigate():null"
      ></i>
    </div>
    <div class="pl-2">
      <button
        [ngClass]="{
    'bg-[var(--theme-deafult-TeacherWithClick)]': TeacherSubjectAcademic&&(TeacherSubjectAcademic.length >0),
    'bg-[#DDDFE5]':  !TeacherSubjectAcademic||(TeacherSubjectAcademic.length ==0),
    'text-[#868B9F]':  !TeacherSubjectAcademic||(TeacherSubjectAcademic.length ==0),
  }"
        type="submit"


        class="text-white   my-3 bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]  text-[12px] lg:text-[14px] Lama-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-black"
        (click)="navigate()"
        [disabled]="!TeacherSubjectAcademic"
      >
        {{ "SubjectInfo.form.Next" | translate }}
      </button>
    </div>
  </div>
</div>

</div>


<ng-template #template>

  <div class=""style=" position: relative;top:50%;">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div >

            <div class="p-6 text-center deleteModalW">
              <div class="text-center"><img src="assets/images/MrS-Cool/delete-Icon.png"  class="m-auto mt-3 p-3 w-14" alt=""></div>
                <h6 class="mb-3 mt-3 text-lg text-[var(--theme-deafult-TeacherWithClick)]  font-normal  dark:text-gray-400 ">  {{ "SubjectInfo.form.Are-you-sure-you-want-to-delete" | translate }}</h6>

                <button
                (click)="this.submit=false;DeleteTeacherSubjectAcademic(TableDeleteTeacherSubject);modalRef?.hide() ;"
                [disabled]="!submit"   type="button" class="mt-4 btnMargin text-white bg-[var(--theme-deafult-TeacherWithClick)]  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-3 text-center mr-2">
                    {{ "SubjectInfo.form.Yes" | translate }}
                </button>
                <button (click)="modalRef?.hide()"type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-3 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">   {{ "SubjectInfo.form.No" | translate }}</button>
            </div>
        </div>
</div>
  </div>
</ng-template>
