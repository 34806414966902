import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule,TranslateModule,RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
protected date =  Date.now()
constructor(private http: HttpClient,  public layoutService: LayoutService) {}
/**
 *
 */
  openTermsAndConditions() {
    this.fetchAndOpenUrl(`${env.Server_URL}Settings/TermsAndConditions`);
  }

  openPrivacyAndPolicy() {
    this.fetchAndOpenUrl(`${env.Server_URL}Settings/PrivacyAndPolicy`);
  }

  private fetchAndOpenUrl(apiUrl: string) {
    this.http.get(apiUrl, { responseType: 'text' }).subscribe({
      next: (data: string) => {
        let url = data.replace(/\\/g, '/');
        window.open(url, '_blank');
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }
}
