import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild ,Renderer2} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  standalone: true,
  imports: [CommonModule, NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslateModule, NgbRatingModule],
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent {
  messages: any[] = [
    { text: 'Hi there!', from: 'me' },
    { text: 'Hello!', from: 'contact' },
    // Add more messages
  ];
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  isHovered!:boolean
  contacts: any[] = [
    { name: 'Contact 1',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }] },
    { name: 'Contact 2',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }]  },
    { name: 'Contact 1',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }]  },
    { name: 'Contact 2' ,items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }] },
    { name: 'Contact 1' },
    { name: 'Contact 2' ,items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }] },
    { name: 'Contact 1',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }]  },
    { name: 'Contact 2',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }]  },
    { name: 'Contact 1',items:[{
      name:1
    },
    {
      name:2
    },
    {
      name:3
    },
    {
      name:4
    },
    {
      name:5
    }]  },
   
    // Add more contacts
  ];
  newMessage: string = '';
  ViewList: boolean = false;
  activeIndex: number | null = null; // Initialize the active index as null
  activeIndexSubject: number | null = null;
constructor(private renderer: Renderer2){

}
  loadMessages(index: any) {
    
    if (this.activeIndexSubject === index) {
      this.activeIndexSubject = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndexSubject = index; // Set the clicked item as the active one
    }
  }
  toggleActive(index: number) {
    if (this.activeIndex === index) {
      this.activeIndex = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndex = index; // Set the clicked item as the active one
    }
  }
  isActive(index: number): boolean {
    return this.activeIndex === index;
  }


 
  isActiveSubject(index: number): boolean {
    return this.activeIndexSubject === index;
  }
  sendMessage() {
    if (this.newMessage) {
      // Send the new message and add it to the 'messages' array
      const msg = {
        text: this.newMessage,
        from: 'me'
      }
      this.messages.push(msg);
      this.newMessage = '';
    }
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }
  scrollToBottom() {
    try {
      this.renderer.setProperty(
        this.chatContainer.nativeElement,
        'scrollTop',
        this.chatContainer.nativeElement.scrollHeight
      );
    } catch (err) {}
  }
  
  
}
