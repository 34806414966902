

<div class="grid gap-6  md:grid-cols-2 grid-cols-1 mt-3  m-auto align-middle h-[80vh]">

  <div class="align-middle m-auto">
    <img src="assets/images/Confirm/Payment.svg" alt="Confirm Payment">
  </div>
  <div class="align-middle w-full m-auto sm:px-5 sm:p-2 pt-10 md:px-3 md:ps-12">


  <div class="m-auto p-16   bg-white rounded-lg   dark:border  dark:bg-[var(--theme-deafult-studentWithClick)] dark:border-gray-700 "
  >
         <p class=" mt-3 diagonal-fractions ... text-right  text-[12px] md-text-[14px] sora-Regular">

    </p>
    <img *ngIf="responseCode=='000'"  [src]="'assets/images/Confirm/success.svg'"  class="w-28 m-auto py-3">
    <img  *ngIf="responseCode!='000'" [src]="'assets/images/Confirm/Failed.svg'"  class="w-28 m-auto py-3">


    <h3 *ngIf="responseCode=='000'" class="text-[18px]  text-center lg:text-[30px] sora-bold dark:text-white text-[var(--theme-deafult-studentWithClick)]">{{ "ConfirmBooking.body.YourPaymentApproveSuccessfully"  |translate }}</h3>
    <h3 *ngIf="responseCode!='000'" class="text-[18px]  text-center lg:text-[30px] sora-bold dark:text-white text-[var(--theme-deafult-ParentWithClick)]">{{ "ConfirmBooking.body.YourPaymentFailed"  |translate }}</h3>



    <p class="text-[12px] text-center md-text-[14px]  text-[#868B9F]  sora-Regular mb-5">
      {{ "ConfirmBooking.body.Your-payment-had-been-done-successfully" |translate}}
      <br>
      {{ "ConfirmBooking.body.Please-back-to-your-account-and-enjoy"  |translate}}

    </p>
    <!-- <p class="text-[12px] md-text-[14px]  text-[#868B9F] sora-Regular mb-5">
      {{ "Please back to your account and enjoy" }}
    </p> -->
    <div class=" mt-3 ">
      <!-- <button type="submit" class="w-full   text-white bg-gray-200 focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
      (click)="navigate(true)"
      (keyup.enter)="navigate(true)">
        {{ "Next" | translate }}
      </button> -->

      <button
      type="submit"
      routerLink="/main/home"
      class="w-full text-[#fff] bg-[var(--theme-deafult-studentWithClick)] rounded-lg  hover:bg-[var(--theme-deafult-studentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-studentWithClick)] text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 h-[3.75rem] mr-2 mb-2 dark:bg-[var(--theme-deafult-studentWithClick)] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
    >
    {{ "ConfirmBooking.body.Go-To-Home" | translate }}

    </button>
    <!-- <i class="ti ti-unlink"></i> -->
    </div>

  </div>
</div>

</div>
