import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ISubjectGroup } from 'src/app/shared/Model/teacher/subject-group';
import { SubjectGroupService } from 'src/app/shared/Apis/Teacher/Geoup/subject-group.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-group-schedule',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipModule],
  templateUrl: './group-schedule.component.html',
  styleUrls: ['./group-schedule.component.scss'],
})
export class GroupScheduleComponent {
  @Input() TeacherSubjectGroup!: ISubjectGroup;
  @Input() _InjectSubjectGroup!: SubjectGroupService;
  @Output() IsReview: EventEmitter<any> = new EventEmitter<any>();
  @Output() IsAdded: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  protected submit: boolean = true;

  constructor(
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}

  navigate(isReview: boolean,isInvalid: boolean) {
    if(this.TeacherSubjectGroup )
    this.seo();

    this.IsReview.emit({isInvalid: isInvalid, isReview: isReview});
    // this.IsReview.emit(isReview);
  }

  protected CreateSubjectTeacherScheduleGroup() {
    this.submit = false;

    this._InjectSubjectGroup
      .CreateSubjectTeacherScheduleGroup(this.TeacherSubjectGroup)
      .subscribe((teacherSubject) => {

        if (teacherSubject.success) {
          this.navigate(false,true);
        }
        this.submit = true;

      },
      (err) => {
        this.submit = true;
      });
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(this.TeacherSubjectGroup.teacherSubjectAcademicSemesterYearName);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}

