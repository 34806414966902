<div [dir]="'ltr'" class="flex _ flex-wrap justify-ce nter pt-5 py-14">
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6">
    <!-- <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
      <input type="file" formControlName="TeacherImage" accept="image/jpeg,image/png,image/gif" />
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('required')">Please select an image</div>
      <div *ngIf="insertForm?.get('imaTeacherImagege')?.hasError('invalidFileType')">Invalid file type. Allowed types: JPEG, PNG, GIF</div>
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('invalidFileSize')">File size exceeds the maximum limit (5MB)</div>
      <button type="submit">Submit</button>
    </form> -->
    <img src="assets/images/MrS-Cool/ConnectStudent.png" class="w-full mt-5" />
  </div>

  <div
    class="w-full sm:w-1/1 md:w-1/2 xl:w-2/3 pt-5 px-3 md:ps-12"
    *ngIf="ConnectParentStudentSteps.Vefiry"
  >
    <form
      class="p-12 mb-5 m-auto pb-3 dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <h3 class="text-3xl Lama-bold dark:text-white text-left rtl:text-end">
        {{ "ConnectParentStudent.header.Title" | translate }}
      </h3>
      <p class="mb-3 mt-1 Lama-Medium text-white text-left rtl:text-end">
        {{ "ConnectParentStudent.header.Enter-subtitle-here" | translate }}
      </p>

      <div>
        <label
          for="phone"
          class="block mb-2 text-left rtl:text-end text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "ConnectParentStudent.form.Mobile" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
            >*</span
          ></label
        >
        <input
          type="tel"
          id="phone"
          formControlName="mobile"
          onlyNumbers maxlength="11" minlength="11"
          class="bg-white  text-left rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          [placeholder]="('ConnectParentStudent.form.Enter-your-number' | translate)"
          [tooltip]="
            fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched)
              ? ('this field is required' | translate)
              : null
          "
          [ngClass]="{
            'class-1': fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched),
            'class-2': fc['mobile'].valid && (fc['mobile'].dirty || fc['mobile'].touched),
          }"
        />
      </div>

      <div class="text-center Lama-Medium pt-3">
        <p>
          {{
            "ConnectParentStudent.form.We-will-send-you-otp-to-your-mobile-number"
              | translate
          }}
          <br />
          <!-- {{
            "ConnectParentStudent.form.to-reset-a-new-password" | translate
          }} -->
        </p>
      </div>
      <button
        type="submit"
        class="w-full text-white btn btn-danger font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        {{ "ConnectParentStudent.form.Verify" | translate }}
      </button>
    </form>
  </div>

  <div
    class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5"
    *ngIf="ConnectParentStudentSteps.vefiryOtp && DataVefiryOtp"
  >
    <app-verify-otpstudent-connect
      [DataVefiryOtp]="DataVefiryOtp"
      [phoneNumber]="fc['mobile'].value"
      (StepVerifyOTP)="handleEvent($event)"
    ></app-verify-otpstudent-connect>
  </div>
  <!-- <div class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5" *ngIf="ForgetPasswordSteps.ResetPassword">
    <app-reset-password [from]="insertForm.value"></app-reset-password>
  </div> -->
</div>
