<!--footer section start-->
<!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class-->
<footer class="footer-1 primary-bg ptb-60 footer-with-newsletter">
  <!--subscribe newsletter start-->
  <div class="container">
    <div class="row newsletter-wrap gradient-bg rounded shadow-lg p-5">
      <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
        <div class="newsletter-content text-white">
          <h3 class="mb-0 text-white">{{ 'subscribe_newsletter' | translate }}</h3>
          <p class="mb-0 text-white">{{ 'subscribe_newsletter_description' | translate }}</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="newsletter-form position-relative">
          <input type="email" class="input-newsletter form-control" placeholder="{{ 'enter_email' | translate }}"
            id="subscription" required>
          <button class="disabled" id="btnSubscription"><i class="fas fa-paper-plane"></i></button>
        </div>
      </div>
    </div>
  </div>
  <!--subscribe newsletter end-->

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0 d-block justify-content-center text-center">
        <a href="#" class="navbar-brand mb-2 text-center">
          <img src="assets/images/Logo/Logo_footer.svg" class="h-14 me-3" alt="Mr-Scool Logo" />
        </a>
        <br>
        <p class="text-white">{{ 'platform_description' | translate }}</p>
        <p class="text-white">{{ 'platform_features' | translate }}</p>
        <p class="text-white">{{ '01023036779'}}</p>

        <div class="list-inline social-list-default background-color social-hover-2 mt-2">
          <li class="list-inline-item"><a class="instagram" href="https://www.instagram.com/mrscool.eg/" target="_blank"><i
                class="fab fa-instagram"></i></a></li>
          <li class="list-inline-item"><a class="facebook" href="https://www.facebook.com/profile.php?id=61559643763340"
              target="_blank"><i class="fab fa-facebook"></i></a></li>
          <li class="list-inline-item"><a class="instagram" href="https://www.tiktok.com/@mrscool.eg" target="_blank"><i
                class="fab fa-tiktok"></i></a></li>
          <li class="list-inline-item"><a class="youtube" href="https://www.youtube.com/@Mrscool-ly6sd" target="_blank"><i
                class="fab fa-youtube"></i></a></li>
        </div>
      </div>
    </div>
  </div>
  <!--end of container-->
</footer>
<!--footer bottom copyright start-->
<div class="footer-bottom py-3 gray-light-bg">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-6 col-lg-7">
        <div class="copyright-wrap small-text sora-Medium" id="copyright">
          <p class="mb-0 text-center sora-Medium" style="direction:ltr">&copy; {{ date | date : "yyy" }} {{ 'all_rights_reserved' | translate }} <span
              class="strong">{{ 'mr_scool' | translate }}</span>
            <br>
            <a href="https://wecancity.com/" target="_blank" class="sora-bold">{{ 'wecancity' | translate }}</a>
            {{ 'developed_by' | translate }}
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="terms-policy-wrap text-lg-right text-md-right text-center">
          <ul class="list-inline text-center">
            <li class="list-inline-item">
              <a class="sora-Medium" style="cursor: pointer; color: #2d2db2;" (click)="openTermsAndConditions()">
                {{ 'terms_and_conditions' | translate }}
              </a>
            </li>

            <li class="list-inline-item">
              <a class="sora-Medium mouse" style="cursor: pointer !important; color: #2d2db2;" id="privacy" (click)="openPrivacyAndPolicy()">
                {{ 'privacy_policy' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!--footer bottom copyright end-->
