import {  Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object ,
    private _transition: TranslateService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        // eslint-disable-next-line no-

        if (error && error.message == 'Internet is required' ) {
          //typeof window != 'undefined' && !window.navigator.onLine
          // Show friendly error
          // this.toastr.error('Internet connection required.');

         if (isPlatformBrowser(this.platformId))
          Swal.fire({
            icon: 'error',
            title: this._transition.instant('Internet connection required.'),
            text: error.error.message,
          });
        } else if (error) {
          switch (error.status) {
            case 400:
              // this.toastr.error(
              //   this._transition.instant('error'),
              //   error.error.message
              // );
              Swal.fire({
                icon: 'error',
                title: this._transition.instant('error'),
                text: error.error?.message,
              });

              break;
            case 401:
              // this.toastr.error(
              //   this._transition.instant('Data modification error'),
              //   error.error.message
              // );

              Swal.fire({
                icon: 'error',
                title: this._transition.instant('Data modification error'),
                text: error.error?.message,
              });

              break;
            case 500:
              // const navigationExtras: NavigationExtras = {
              //   state: { error: error.error },
              // };
              break;
            default:
              //webpack:///src/app/home/widgets/package/services-home-page-blog/services-home-page-blog.component.ts            // this.toastr.error(this._transition.instant('error'), this._transition.instant('something unexpected happened'));

              Swal.fire({
                icon: 'error',
                title: this._transition.instant('error'),
                text: error.error?.message,
              });

              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
