import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbPagination,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import { MostViewedLessonsService } from 'src/app/shared/Apis/most-viewed-lessons.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-most-booked-teachers',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    NgbProgressbarModule,
    TranslateModule,
    NgbRatingModule,
    NgbPagination,
    NgxPaginationModule,
  ],
  templateUrl: './most-booked-teachers.component.html',
  styleUrls: ['./most-booked-teachers.component.scss'],
})
export class MostBookedTeachersComponent {
  ImgUrlServer = environment.serverFirstHalfOfImageUrl;
  data: {
    subjectId: string | null;
    lessonId: string | null;
    exceptTeacherId: string | null;
  } = {
    subjectId: null,
    lessonId: null,
    exceptTeacherId: null,
  };
  subjectId: any;
  TeachersMostBooked: any[]=[];
  @Input() type?: string;
  @Input() showPriceAndDuration:boolean=false;
  @Input() academicleveid?: number;
  @Input() teacherData: any;

  constructor(
    private router: Router,
    private SubjectInfoService: SubjectInfoService,
    private _MostViewedLessonsService: MostViewedLessonsService, private layoutService:LayoutService
  ) {}
  ngOnInit(): void {
    if (this.type != null && this.teacherData ==null) this.GetatedTeacherrTopViewedSubjects(this.academicleveid);
    else this.GetTeachersMostBooked();
  }
  GetTeachersMostBooked() {
    if (this.teacherData.type === 'Subject') {
      this.data.subjectId = this.teacherData.id;
    } else if (this.teacherData.type === 'Lesson') {
      this.data.lessonId = this.teacherData.id;
    }
    if (this.teacherData.teacherId) {
      this.data.exceptTeacherId = this.teacherData.teacherId;
    }
    const data = this.filteredData(this.data);
    this.SubjectInfoService.GetTeachersMostBooked(data).subscribe(
      (res: any) => {
        this.TeachersMostBooked = res;
        console.log(res)
      }
    );
  }

  convertDurationToHours(duration: number): string {
    const lang = this.layoutService.config.langu; // en | ar
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    if (lang == 'ar') {
      // تنسيق الوقت للعربية
      return `${formattedMinutes} : ${formattedHours} ساعات`;
    } else {
      // تنسيق الوقت للإنجليزية أو اللغات الأخرى
      return `${formattedHours} : ${formattedMinutes} hrs`;
    }
  }

  filteredData(data: any) {
    const filteredData = Object.keys(data)
      .filter((key) => data[key as keyof typeof data] !== null) // Type assertion here
      .reduce((obj, key) => {
        obj[key] = data[key as keyof typeof data];
        return obj;
      }, {} as Record<string, string | null>);
    return filteredData;
  }

  GetatedTeacherrTopViewedSubjects(_academiclevelid?:number) {
    if (this.type) {
      if (this.type != 'GetTeachersMostBooked')
        this._MostViewedLessonsService
          .GetatedTeacherrTopViewedSubjects(this.type, _academiclevelid)
          .subscribe((res) => {
            this.TeachersMostBooked = res;
          });
      else
        this.SubjectInfoService.GetTeachersMostBooked({
          academiclevelid: _academiclevelid,
        }).subscribe((res: any) => {
          this.TeachersMostBooked = res;
        });
    }
  }

  goToTeacherProfile(id: any) {
    this.router.navigate([`/main/Teacher/Profile/${id}`]);
  }
}
