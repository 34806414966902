<div
class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700  startPagePadding"
>
<form class="max-md:px-1 " [formGroup]="SearchForm">
  <div class="grid gap-x-5   grid-cols-1  max-md:mb-3">
    <div class="lg:grid-cols-4 grid  gap-4  max-md:mb-3">
      <div class=" lg:col-span-2 mx-2 max-md:mb-2">
        <label
        for="teacherSubjectId"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "SubjectGroup.form.Subject-Name" | translate }}
      </label>
        <ng-select id="teacherSubjectId"  #teacherSubjectAcademicSemesterYearSearch [selectOnTab]="true" formControlName="teacherSubjectId" class="form-control p-0"
        dropdownPosition="auto" labelForId="validation02"
        [searchable]="true"
        (change)="ChangTeacherSubjectAcademicSemesterYearForList()"

        >
        <ng-option value=""> {{'SubjectGroup.form.ChooseSubject' |translate}}
        </ng-option>
        <ng-option   *ngFor="let type of SubjectAcademicSemesterYearForList ; let i =index" [value]="type.id">
          {{ type.subjectDisplayName }}
        </ng-option>
      </ng-select>
      </div>

      <div class="mx-2 max-md:mb-2">
        <label
        for="groupName"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "GroupForLesson.form.Group" | translate }}
      </label>
        <input
          type="text"
          formControlName="groupName"
          id="SearchInp"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          [placeholder]="('Enter_groupName'|translate)"
        />
      </div>
      <div class="mx-2 max-md:mb-2">
        <label
        for="teacherLessonId"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "GroupForLesson.form.Teacher-Lesson" | translate }}
      </label>
        <ng-select id="teacherLessonId"  #teacherSubjectAcademicSemesterYearSearch [selectOnTab]="true"  formControlName="teacherLessonId" class="form-control p-0"
        dropdownPosition="auto" labelForId="validation02"
        [searchable]="true"
        (change)="ChangeTeacherLessonForList()"

        >
        <ng-option value="">    {{ "GroupForLesson.form.ChooseLesson" | translate }} </ng-option>
        <ng-option     *ngFor="let lesson of TeacherLessonForList ; let i =index" [value]="lesson.id">
          {{ lesson.lessonName }}
        </ng-option>
      </ng-select>
      </div>



      <div class=" lg:col-span-2 mx-2 max-md:mb-2">
        <label
        for="startDate"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "GroupForLesson.form.SDate" | translate }}
      </label>
        <input
          type="date" (keypress)="$event.preventDefault()"
          formControlName="lessonDate"
          id="lessonDate"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter start Date"
        />
      </div>

    <div  class="text-right align-items-end d-flex lg:col-span-2 justify-end mx-2 max-md:mb-2">
      <button
        type="submit"
        (click)="SearchFormPag()"
        class="text-white btnFilter bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
      {{ "GroupForLesson.form.Filter" | translate }}

      </button>
    </div>
  </div>

  </div>
</form>
</div>
<div
class="  bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 "
>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg dark:bg-[#1a202c] bg-[#ffffff] ">
  <div
    class="d-flex align-items-center border-b-2   bg-[#ffffff] mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6  "
  >
    <div class="pb-3">
      <img src="assets/images/MrS-Cool/setting.png" alt="" />
    </div>
    <div class="addressIconSpace">
      <h3  class="text-[30px] text-left sora-bold dark:text-white underTitle">

        {{ "StudentCompletedLesson.Tabble.header.Title" | translate }}

      </h3>
      <p class="text-sm/[11px] text-left rtl:text-right dark:text-white">
        {{ "StudentCompletedLesson.Tabble.header.Title" | translate }}
      </p>
    </div>
  </div>
  <div
    class="d-flex align-items-center   bg-[#ffffff] startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6  mb-3"
  >
    <table
      class="w-full text-sm text-left dark:bg-[#1a202c] bg-[#ffffff] rtl:text-right text-white-500 dark:text-white-400"
    >
      <thead
        class="sora-Medium text-white dark:bg-[#1a202c]  bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
      >
        <tr>
          <th scope="col" class="px-6 py-3 w-72">
            {{ "GroupForLesson.Tabble.thead.Subject" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "GroupForLesson.Tabble.thead.Group" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "GroupForLesson.Tabble.thead.SDate" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "GroupForLesson.Tabble.thead.Lesson" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "GroupForLesson.Tabble.thead.STime" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "GroupForLesson.Tabble.thead.ETime" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">

            {{ "GroupForLesson.Tabble.thead.Action" | translate }}

          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"

          *ngFor="
          let item of MyLessonSchedualGroup
            | paginate
              : {
                  id: 'pagin',
                  itemsPerPage: pager.maxResultCount,
                  currentPage: pageNumber,
                  totalItems: totalRecords
                };
          let y = index
        "
        >
          <th
            scope="row"
            class="px-6  py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
          >
            {{ item.subjectName }}
          </th>
          <td class="px-6 py-4">
            {{ item.groupName }}
          </td>
          <td class="px-6 py-4">
            {{ item.date|date}}
          </td>
          <td class="px-6 py-4">
            {{ item.lessonName}}
          </td>
          <td class="px-6 py-4">
            {{ item.timeFrom }}
          </td>
          <td class="px-6 py-4">
            {{ item.timeTo}}
          </td>
          <td class="px-6 py-4 text-center">

            <a
              class="font-mediu hover:underline"
              (click)=" navigate(item.teacherLessonSessionSchedualSlotId)"
              >
              <img
              src="assets/images/Icons/view.svg"
              class=" p-3"
              alt=""
            />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center mt-3" *ngIf="MyLessonSchedualGroup.length!=0">
    <pagination-controls

      id="pagin"
      previousLabel=""
      nextLabel=""
      style="display: flex; justify-content: center"
      (pageChange)="pageChanged((page = $event))"
    ></pagination-controls>
  </div>
</div>
<!-- </div> -->


</div>
<!-- (click)="TableDeleteGroupID = item.id; openModal(template)" -->
