<div class="container-fluid h-100">
  <h4 class="dark:text-white"><strong>{{ "FinanceTeacher.header.Finance" | translate }}</strong></h4>

  <div class="grid gap-2 mb-12 lg:grid-cols-3 mt-5">
    <div *ngIf="TotalFinance"
      class="card mt-4 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt text-[#1B2651] dark:text-white">
        <strong>
          {{ "FinanceTeacher.balanceCard.CurrentBalance" | translate }}
        </strong></label
      >
      <div class="mt-5 flex h-full justify-center align-items-center">
        <div class="text-center pa50">
          <h1 class="BalancNumb text-[#1B2651] dark:text-white">
            {{TotalFinance?.totalDue}} {{ "FinanceTeacher.balanceCard.LE" | translate }}
          </h1>
        </div>
      </div>
    </div>
    <!-- <div
      class="card mt-4 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt text-[#1B2651] dark:text-white">
        <strong>
          {{ "FinanceTeacher.balanceCard.NextPayment" | translate }}
        </strong></label
      >
      <div class="mt-5 ">
    <div class="text-center pa50">
          <h1 class="BalancNumb text-[#39B002] dark:text-white"> {{TotalFinance?.nextCycleDue}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>

      </div>
    </div> -->
    <div
      class="card mt-4 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt text-[#1B2651] dark:text-white"
        ><strong>
          {{ "FinanceTeacher.balanceCard.YouEarned" | translate }}
        </strong></label
      >
      <div class="mt-5 flex h-full justify-center align-items-center">
    <div class="text-center pa50">
          <h1  class="BalancNumb text-[#39B002] dark:text-white"> {{TotalFinance?.totalIncome}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>
      </div>
    </div>
  </div>

  <app-teacher-purchased-lessons></app-teacher-purchased-lessons>
  <br>
  <app-teacher-purchased-subjects></app-teacher-purchased-subjects>


</div>
