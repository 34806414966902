import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { content,contentAuth} from './shared/core/routes/routes';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },

  {
    path: 'authentication',
    component: AuthLayoutComponent,
   // canActivate: [AuthGuard],
    children:contentAuth
  },
  {
    path: 'main',
    component: MainLayoutComponent,
   // canActivate: [AuthGuard],
    children:content
  },
 { path: '**',
 component: AuthLayoutComponent,
}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
