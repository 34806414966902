import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  NgbPagination,
  NgbRatingModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChooseStudentComponent } from 'src/app/Component/Parent/choose-student/choose-student.component';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import { OnlyNumbersWithDoubleDirective } from 'src/app/shared/Directive/only-numbers-with-double.directive';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { decimalNotToStarZero } from 'src/app/shared/core/appSetting';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { validateNotZero } from 'src/app/shared/validators/validate-not-zero';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {MatExpansionModule} from '@angular/material/expansion';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';

@Component({
  selector: 'app-teacher-for-subjects',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgbTooltipModule,
    TranslateModule,
    NgbRatingModule,
    NgbPagination,
    NgxPaginationModule,
    ChooseStudentComponent,
    CustomPipeForImagesPipe,
    OnlyNumbersWithDoubleDirective,
    MatExpansionModule
  ],
  templateUrl: './teacher-for-subjects.component.html',
  styleUrls: ['./teacher-for-subjects.component.scss'],
})
export class TeacherForSubjectsComponent
  extends BaseComponent
  implements OnInit
{
  public panelOpenState  = false;
  SubjectDetails: any;
  ImgUrlServer = environment.serverFirstHalfOfImageUrl;
  data = {};
  SubjectId: any;
  SubjectOrLessonTeachers: any;
  rating = 0;
  page!: number;
  @ViewChildren('embeddedImage') embeddedImages!: QueryList<ElementRef>;
  LessonId: any;
  type: any;
  id: any;
  isPrice: boolean=true;
  protected SearchForm!: FormGroup;

  constructor(
    private router: Router,
    private SubjectInfoService: SubjectInfoService,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.initiateSearch();
    if (
      this.route.snapshot.paramMap.get('type') &&
      this.route.snapshot.paramMap.get('id')
    ) {
      this.type = this.route.snapshot.paramMap.get('type');
      const id = this.route.snapshot.paramMap.get('id');
      if (this.type === 'Subject') {
        this.SubjectId = id;
      } else if (this.type === 'Lesson') {
        this.LessonId = id;
      }
      // this.GetSubjectOrLessonTeachers(this.pager);
      this.onSortChange('Booked')
    }
  }

  get fc() {
    return this.SearchForm.controls;
  }
  private initiateSearch() {
    this.SearchForm = this._fb.group({
      teacherName: '',
      sortColumn: 'Booked',
      rate: '',
      genderId: '',
      priceFrom: ['', Validators.pattern(decimalNotToStarZero)],
      priceTo: ['', Validators.pattern(decimalNotToStarZero)],
    });
  }
  GetSubjectOrLessonTeachers(pagerFilter: any) {
    if (this.SubjectId) {
      pagerFilter.subjectId = this.SubjectId;
    } else if (this.LessonId) {
      pagerFilter.LessonId = this.LessonId;
    }

    this.SubjectInfoService.SubjectOrLessonTeachers(pagerFilter).subscribe(
      (res: any) => {
        this.SubjectOrLessonTeachers = res.items;
        this.totalRecords = res.totalCount;
        this.SubjectDetails = res.items[0].getSubjectOrLessonDto;
        this.seoService.setMetaImage(this.SubjectDetails?.image);
        this.seoService.setTitle(this.SubjectDetails?.headerName??"");
        this.seoService.setHostUrlIndex();
        this.seoService.setMetaDescription(this.SubjectDetails?.systemBrief??"")
        this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.SubjectDetails?.systemBrie??""))
      }
    );
  }
  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetSubjectOrLessonTeachers(this.pager);
    this.pageNumber;
  }

  SearchFormPag(pagerFilter: any) {
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.pager = { ...this.pager, ...pagerFilter };
    for (let prop in this.pager) {
      if (prop == 'rate') {
        if (this.pager[prop] == 0) {
          delete this.pager[prop];
        }
      }
      if (this.pager.hasOwnProperty(prop) && this.pager[prop] === '') {
        delete this.pager[prop];
      }
    }
    this.GetSubjectOrLessonTeachers(this.pager);
    this.pageNumber;
  }

  convertDurationToHours(duration: number): string {
    const lang = this.layoutService.config.langu; // en | ar
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    if (lang == 'ar') {
      // تنسيق الوقت للعربية
      return `${formattedMinutes} : ${formattedHours} ساعات`;
    } else {
      // تنسيق الوقت للإنجليزية أو اللغات الأخرى
      return `${formattedHours} : ${formattedMinutes} hrs`;
    }
  }

  Search() {
    this.isPrice=true

    if (this.fc['priceFrom'].value != null && this.fc['priceTo'].value != null &&this.fc['priceFrom'].value != "" && this.fc['priceTo'].value != "")
   {
    if (
      JSON.parse(this.fc['priceFrom'].value) >
      JSON.parse(this.fc['priceTo'].value)
    ) {
      // Swal.fire({
      //   icon: 'warning',
      //   title: 'warning',
      //   text: 'The price should be from the largest of the price to',
      // });
      this.isPrice=false
    } else this.SearchFormPag(this.SearchForm.getRawValue());
   }
    else
    this.SearchFormPag(this.SearchForm.getRawValue());
  }

  onLeave(rate: number) {
    if (this.pager.rate === 1) {
      this.pager.rate = 0;
    }
  }
  onSortChange(value: string) {
    this.fc['sortColumn'].setValue(value);
    this.pager.sortColumn = value;
    this.SearchFormPag(this.pager);
  }
  GoToTeacherObjectInfo(item: any, teacherId: any) {
    var id;
    if (this.SubjectId) {
      this.id = this.SubjectId;
    } else if (this.LessonId) {
      this.id = this.LessonId;
    }
    const type = {
      type: this.type,
      id: this.id,
      teacherId: teacherId,
    };
    const navigationExtras: NavigationExtras = {
      state: {
        data: type,
      },
    };
    if (type.type == 'Subject') {
      id = item.teacherSubjectId;

      this.router.navigate(
        [`/main/student/TeacherSubjectInfo/${id}`],
        navigationExtras
      );
    } else if (type.type == 'Lesson') {
      id = item.teacherLessonId;

      this.router.navigate(
        [
          `/main/student/TeacherLessonGroupDetailComponent/${item.teacherLessonId}`,
        ],
        navigationExtras
      );
    }
  }

}
