import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IOtp, IVefiryUserPost, IVefiryUserResponse } from '../../Model/teacher/teacher';
import { IGenericResponseObject } from '../../Model/Generic/generic-response';
import { IPostResetPassword, PostCahngePassword, ResponseChangePassword } from '../../Model/Auth/login';
@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  constructor(private http: HttpClient) {}
  public ResetPassword(controller:string ,resetPasswordModel: IPostResetPassword): Observable<IGenericResponseObject<any>> {
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}${controller}/ResetPassword`, resetPasswordModel).pipe(
        map((response: any) => {
            return response;
        })

    );
}
public ChangePassword(controller:string ,PasswordModel: PostCahngePassword): Observable<IGenericResponseObject<ResponseChangePassword>> {
  return this.http.post<IGenericResponseObject<ResponseChangePassword>>(`${env.Server_URL}${controller}/ChangePassword`, PasswordModel).pipe(
      map((response: any) => {
          return response;
      })

  );

}
}

