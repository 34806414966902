import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MustMatch } from 'src/app/shared/validators/MustMatch';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
 import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { PasswordService } from 'src/app/shared/Apis/Password/password.service';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    TranslateModule,
    RouterLink,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected Controller!: string;
  protected submit: boolean = true;

  protected changetype: boolean = true;
  protected confirmtype: boolean = true;
  protected doneReset: boolean = false;
  constructor(
    private seoService: SeoService,
    private layoutService: LayoutService,
    private _fb: FormBuilder,
    private _router: Router,
    private _SSRService: SSRService,
    private _Password: PasswordService
  ) {
    this.Controller = this._SSRService.Location(2);
  }
  ngOnInit(): void {
    this.seo()
    this.initiate();
  }
  private initiate() {


    this.insertForm = this._fb.group(
      {
        currentPassword: ["" ,[Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        confirmPassword: ['', Validators.required],

      },
      {
        validators: [MustMatch('newPassword', 'confirmPassword')],
      }
    );
  }

  navigate() {
    this._router.navigate([`/main/home`]);

  }

  protected get fc() {
    return this.insertForm.controls;
  }
  protected onSubmit() {
    if (this.insertForm.valid) {
      this.ChangePassword();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private ChangePassword() {
    this.submit = false;
    this._Password
      .ChangePassword(this.Controller, this.insertForm.getRawValue())
      .subscribe((res) => {
        if (res.success) this.doneReset = true;
      this.submit = true;
    },
    (err) => {
      this.insertForm.get('currentPassword')?.setErrors({'incorrect': true})
      this.submit = true;
    }
  );

}

  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/changePass.png');
     const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {

      if(this.Controller== 'teacher')
        this.seoService.setTitle(translations.ChangePassword.header.Text + ' - ' + translations.Tabs.body.Teacher);
        else if(this.Controller == 'parent')
        this.seoService.setTitle(translations.ChangePassword.header.Text + ' - ' + translations.Tabs.body.Parent);
        else
        this.seoService.setTitle(translations.ChangePassword.header.Text + ' - ' + translations.Tabs.body.Student);+
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
