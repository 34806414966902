


<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li ngbNavItem>
    <a ngbNavLink (click)="GetEgyptDateTime()">{{ "MENUITEMS.StudentsSessions.LIST.ScheduleList" | translate }}</a>
		<ng-template ngbNavContent>
      <div
      class="bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 startPagePadding"
    >
      <form class="max-md:px-1" [formGroup]="SearchForm">
        <div class="grid gap-x-z grid-cols-1 max-md:mb-3">
          <div class="lg:grid-cols-5 grid gap-4 max-md:mb-3">
            <div class="mx-2 max-md:mb-2">
              <label
                for="startDate"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.SDate" | translate }}
              </label>
              <input
                type="date"
                (keypress)="$event.preventDefault()"
                formControlName="dateFrom"
                id="dateFrom"
                class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter start Date"
              />
            </div>

            <div class="mx-2 max-md:mb-2">
              <label
                for="dateTo"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.EDate" | translate }}
              </label>
              <input
                type="date"
                (keypress)="$event.preventDefault()"
                formControlName="dateTo"
                id="dateTo"
                class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter start Date"
              />
            </div>

            <div class="mx-2 max-md:mb-2 flex items-end">
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  formControlName="isCancel"
                  class="sr-only peer"
                />
                <span
                  class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >{{ "Cancel" | translate }}</span
                >
                <div
                  class="relative mt-2 mx-4 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
            </div>

            <div class="mx-2 max-md:mb-2 flex items-end">
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  formControlName="isAttend"
                  class="sr-only peer"
                />
                <span
                  class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {{ "StudentCompletedLesson.Tabble.thead.Attendance" | translate }}
                </span>
                <div
                  class="relative mt-2 mx-4 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
            </div>

            <div
              class="text-right align-items-end d-flex justify-end mx-2 max-md:mb-2"
            >
              <button
                type="submit"
                (click)="onSubmit()"
                class="text-white btnFilter bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                {{ "GroupForLesson.form.Filter" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card h-100 mt-4 p-3 dark:bg-[#1a202c]">
      <div class="card-header bg-[#ffffff] dark:bg-[#1a202c]">
        <div class="row">
          <div class="col p-2 rtl:text-right bg-[#ffffff] dark:bg-[#1a202c]">
            <h3
              class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >
              {{ "TeacherCalenderSchedualList.Calendar" | translate }}
            </h3>
            <!-- <app-get-egypt-date-time [class]="'red'"
            (setegyptDateTime)="handleEventDateTane($event)"></app-get-egypt-date-time> -->
            <p class="mb-0 text-left rtl:text-end" style="color: red">
              {{ "Notice" | translate }} : {{ "lesson_schedule_time" | translate }}

              <span class="mx-2"> {{ serverTime?.slice(0, 16) }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="card-body dark:bg-[#1a202c] h-100 overflow-auto">
        <table
          class="w-full text-sm text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400"
        >
          <thead
            class="Lama-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">
                <!-- {{ "TeacherCalenderSchedualList.cancel_option" | translate }} -->
              </th>
              <th scope="col" class="px-6 py-3"></th>
              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.groupName" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.subject_name" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.session_name" | translate }}
              </th>
              <!-- <th scope="col" class="px-6 py-3">
                {{ "FinanceTeacher.Date" | translate }}
              </th> -->

              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.students" | translate }}
              </th>
              <!-- <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.session_date" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.session_start_time" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.session_end_time" | translate }}
              </th> -->

              <th scope="col" class="px-6 py-3">
                {{ "TeacherCalenderSchedualList.cancel_option" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
              *ngFor="
                let item of appointmentsData
                  | paginate
                    : {
                        id: 'pagin',
                        itemsPerPage: pager.maxResultCount,
                        currentPage: pageNumber,
                        totalItems: totalRecords
                      };
                let y = index
              "
            >
              <td class="px-6 py-4">
                <a
                  class="font-medium text-red-600 red:text-blue-500 hover:underline"
                >
                  <ng-container>
                    <a
                      *ngIf="item.teamMeetingLink"
                      (click)="TeacherAttendance(item['id'])"
                      [href]="item.teamMeetingLink"
                      class="text-[12px] mt-4 mb-4 m-lg:text-[14px] flex items-center Lama-Medium w-100 text-[#fff] bg-transparent border-[green] focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#6264a7]"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        border: 1px green solid;
                        background-color: green !important;
                        color: white !important;
                      "
                    >
                      {{ "join" | translate }}
                      <!-- teams microsoft
                  class=" text-[12px] mt-4 mb-4 m-lg:text-[14px]  Lama-Medium w-100 text-white bg-[var(--theme-deafult-studentWithClick)] focus:bg-[var(--theme-deafult-studentWithClick)] my-3 hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-[var(--theme-deafult-studentWithClick)]" -->
                      <img
                        src="assets/images/Icons/Microsoft-Teams-Logo.png"
                        class="w-10 mx-1"
                        alt="Icons delete"
                        srcset=""
                      />
                    </a>
                  </ng-container>
                  <ng-template #notMeetingTime> </ng-template>
                </a>
              </td>

              <td class="px-6 py-4">
                <div
                  [ngClass]="{
                    'bg-red-700': item.isCancel == true,
                    'bg-green-600': item.isCancel == false
                  }"
                  class="inline-block w-4 h-4 mr-2 rounded-full"
                ></div>
              </td>
              <th
                scope="row"
                class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
              >
                {{ item.text }}
                <br />

                <a *ngIf="item.isAlternate" style="color: red">
                   {{ "alternate_lesson" | translate }}
                </a>
              </th>

              <td class="px-6 py-4">
                {{ item.subjectName }}
              </td>
              <td class="px-6 py-4">
                {{ item.sessionName }}
                <br />

                <a style="color: red">
                  {{ item.startDate | date }} {{ "From" | translate }}
                  {{ item.timeFrom.slice(0, 5) }} {{ "To" | translate }}
                  {{ item.timeTo.slice(0, 5) }}
                </a>
              </td>
              <td class="px-6 py-4">
                {{ item.students }}
              </td>

              <td class="px-6 py-4">
          <div class="d-flex justify-between">

                      <!-- <a
                        *ngIf="item.actions"
                        (click)="
                        ( item.teachersubjectAcademicSemesterYearId && item.isAlternate ==false)
                            ? AddAndUpdateSessions(item)
                            : ForCalenderAddAndUpdateSessionsAlternate(item)
                        "
                        class="font-medium text-red-600 red:text-blue-500 hover:underline CancelAndEdit"
                      >
                        <img
                        src="assets/images/Icons/edit.png"
                          class="w-4"
                          alt="Icons delete"
                          srcset=""
                        />

                      </a> -->


                      <a
                      *ngIf="item.actions && item.teachersubjectAcademicSemesterYearId"
                      [tooltip]="   ('change_schedule' | translate)"
                      (click)="
                        item.isAlternate ==false
                        ? AddAndUpdateSessions(item)
                        : ForCalenderAddAndUpdateSessionsAlternate(item)
                    "
                        class=" mr-1 font-medium text-red-600 red:text-blue-500 hover:underline"
                      >
                        <img src="assets/images/Icons/edit.png"
                        class="w-4"
                        alt="Icons Edit"
                         srcset=""
                      /></a>
                      <a
                      *ngIf="item.actions &&(item.isAlternate ||!item.teachersubjectAcademicSemesterYearId)"

                        (click)="

                            confirmCancelBookLessonSession(item)
                        "
                        class="font-medium text-red-600 red:text-blue-500 hover:underline CancelAndEdit"
                      >
                        <!-- <span> {{ "CancelAndEdit" | translate }}</span> -->
                        <img
                      [tooltip]="   ('CancelAndEdit' | translate)"

                          src="assets/images/Icons/delete.png"
                          class="w-4"
                          alt="Icons delete"
                          srcset=""
                        />
                      </a>
          </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="d-flex justify-content-center mt-3"
        *ngIf="appointmentsData && appointmentsData?.length != 0"
      >
        <pagination-controls
          id="pagin"
          previousLabel=""
          nextLabel=""
          style="display: flex; justify-content: center"
          (pageChange)="pageChanged((page = $event))"
        ></pagination-controls>
      </div>
    </div>
		</ng-template>
	</li>
	<li ngbNavItem (click)="GetEgyptDateTimeFroTab()">
		<a style="margin: 0 5px;"  ngbNavLink>{{ "alternate_lesson" | translate }}</a>
		<ng-template ngbNavContent>

      <div class="card h-100 mt-4 p-3 dark:bg-[#1a202c]">
        <div class="card-header bg-[#ffffff] dark:bg-[#1a202c]">
          <div class="row">
            <div class="col p-2 rtl:text-right bg-[#ffffff] dark:bg-[#1a202c]">
              <h3
                class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "alternate_lessons" | translate }}
              </h3>
              <!-- <app-get-egypt-date-time [class]="'red'"
              (setegyptDateTime)="handleEventDateTane($event)"></app-get-egypt-date-time> -->
              <p class="mb-0 text-left rtl:text-end" style="color: red">
                {{ "Notice" | translate }} : {{ "lesson_schedule_time" | translate }}

                <span class="mx-2"> {{ serverTime?.slice(0, 16) }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="card-body dark:bg-[#1a202c] h-100 overflow-auto">
          <table
            class="w-full text-sm text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400"
          >
            <thead
              class="Lama-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
            >

              <tr>
                <th scope="col" class="px-6 py-3">
                  {{ "TeacherCalenderSchedualList.groupName" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "TeacherCalenderSchedualList.subject_name" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "StudentMatrial.body.Lesson" | translate }}
                </th>

                <th scope="col" class="px-6 py-3">
                  {{ "TeacherCalenderSchedualList.students" | translate }}
                </th>

                <th scope="col" class="px-6 py-3">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of MissedSessionList; let y = index"
              >
                <th
                  scope="col"
                  class="px-6 py-4  font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  {{ item.groupName }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-4  font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  {{ item.subjectName }}
                </th>

                <td class="px-6 py-4">
                  {{ item.lessonName }}
                </td>
                <td class="px-6 py-4">
                  {{ item.absentStudentCount }}
                </td>

                <!-- <td class="px-6 py-4">
                  {{ item.startDate|date }}

                </td>
                <td class="px-6 py-4">
                  {{ item.timeFrom }}
                </td>
                <td class="px-6 py-4">
                  {{ item.timeTo }}
                </td> -->
                <td class="px-6 py-4">
                  <a
                    (click)="AddAndUpdateSessionsAlternate(item)"
                    class="font-medium text-red-600 red:text-blue-500 hover:underline CancelAndEdit"
                  >
                    <span> {{ "create_alternate_lesson" | translate }}</span>
                    <!-- <img
                src="assets/images/Icons/delete.png"
                class="w-4"
                  alt="Icons delete"
                  srcset=""
              /> -->
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
          </ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

