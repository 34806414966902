import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChildrenIdService {
  private currentChildrenId!: number;

  public childrenIdSubject = new BehaviorSubject<any>(null);
  public academicleveid = new BehaviorSubject<any>(null);
  public backOnStudent = new BehaviorSubject<any>(null);

  ChildrenId$: Observable<any> = this.childrenIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  setChildrenId(id: number) {

    if (this.childrenIdSubject.value !== id) {
      this.childrenIdSubject.next(null);
      this.childrenIdSubject.next(id);
      this.currentChildrenId=id
      localStorage.setItem('childrenId', String(id));

    }
  }
  setAcademicleveId(academicleve: any) {
    // set academicleveid
    if ( this.academicleveid.value != academicleve) {
    this.academicleveid.next(academicleve);
    localStorage.setItem('academicYearId', String(academicleve));
  }
  }

  getCurrentChildrenId(): number {
    if (!this.currentChildrenId && isPlatformBrowser(this.platformId)) {
      const storedChildrenId = localStorage.getItem('childrenId');
      if (storedChildrenId && storedChildrenId !== 'undefined') {
        this.currentChildrenId = parseInt(storedChildrenId, 10);
      }
    }
    return this.currentChildrenId;
  }

  ReloadBackOnStudent(isRleliad: null | number) {
    //  this.backOnStudent.next(null)
    // this.backOnStudent.
    this.backOnStudent.next(isRleliad);
  }
  // set Child Name

  setChildrenName(name: string) {
    localStorage.setItem('ChildName', name);
  }

  constructor(private auth:AuthService,    @Inject(PLATFORM_ID) private platformId: Object) {}

  setNull() {

      this.childrenIdSubject.next(null);
      this.backOnStudent.next(null);
      localStorage.removeItem('childrenId');
  }
}
