// Home Slider
export let HomeSlider: any = {
  loop: false,
  nav: true,
  dots: false,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  navContainerClass: 'owl-nav',
  navClass: ['owl-prev', 'owl-next'],
  navText: [
    '<i class="fa-chevron-left"></i>',
    '<i class="fa-chevron-right></i>"',
  ],
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    740: {
      items: 1,
    },
    940: {
      items: 1,
    },
  },
};

// Blog Slider
export let BlogSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    740: {
      items: 2,
    },
    940: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

// Insta Slider
export let InstaSlider: any = {
  loop: false,
  nav: true,
  dots: false,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  navContainerClass: 'owl-nav',
  navClass: ['owl-prev', 'owl-next'],
  navText: [
    '<i class="fa-solid fa-arrow-left-long"></i>',
    '<i class="fa-solid fa-arrow-right-long"></i>',

  ],

  navSpeed: 3000,
  responsive: {
    991: {
      items: 3,
    },
    767: {
      items: 2,
    },
    586: {
      items: 1,
      autoplay: false,

    },
    0: {
      autoplay: false,
      items: 1,

    },
  },
};

// Logo Slider
export let LogoSlider: any = {
  loop: false,
  nav: true,
  dots: true,
  autoplay: false,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  navContainerClass: 'owl-nav',
  navClass: [ 'owl-prev','owl-next'],
  navText: [
    '<i class=""></i>',//fa-solid  fa-arrow-right-long
    '<i class=""></i>', //fa-solid fa-arrow-left-long
  ],
  responsive: {
    1200: {
      items: 4,
    },
    1024: {
      items: 3,
    },
    767: {
      items: 2,
    },
    576: {
      items: 2,
    },
    480: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Collection Slider
export let CollectionSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    586: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Category Slider
export let CategorySlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    1024: {
      items: 6,
    },
    767: {
      items: 5,
    },
    576: {
      items: 5,
    },
    480: {
      items: 3,
    },
    0: {
      items: 2,
    },
  },
};

// Testimonial Slider
export let TestimonialSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    991: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Team Slider
export let TeamSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    586: {
      items: 2,
    },
    0: {
      items: 2,
    },
  },
};

// Compare Slider
export let CompareSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    586: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Product Slider
export let ProductSlider: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  autoHeight: true,
  responsive: {
    991: {
      items: 4,
    },
    767: {
      items: 3,
    },
    420: {
      items: 2,
    },
    0: {
      items: 1,
    },
  },
};

// Product Slider
export let ProductOneSlider: any = {
  items: 1,
  loop: false,
  dots: false,
  navSpeed: 300,
};

// New Product Slider
export let NewProductSlider: any = {
  items: 1,
  loop: false,
  nav: true,
  dots: false,
  navContainerClass: 'owl-nav',
  navClass: ['owl-prev', 'owl-next'],
  navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
};

// Product Details Main Slider
export let ProductDetailsMainSlider: any = {
  items: 1,
  nav: false,
  dots: false,
  autoplay: false,
  slideSpeed: 300,
  loop: false,
};
export let ProductDetailsMainSlider$: any = {
  items: 1,
  nav: false,
  dots: false,
  autoplay: false,
  slideSpeed: 300,
  loop: false,
};

// Product Details Thumb Slider
export let ProductDetailsThumbSlider: any = {
  items: 3,
  loop: false,
  margin: 10,
  dots: false,
};

export let ParentChildern: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  margin: 0,
  responsive: {
    740: {
      items: 6,
    },
    940: {
      items: 6,
    },
    1200: {
      items: 6,
    },
  },
};


export let ParentChildern_: any = {
  loop: false,
  dots: false,
  navSpeed: 300,
  margin: 0,
  responsive: {
    991: {
      items: 34,
    },
    767: {
      items: 4,
    },
    586: {
      items: 4,
    },
    0: {
      items: 1,
    },
  },
};
