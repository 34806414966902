import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { MostBookedTeachersComponent } from '../Teacher/MostBookedTeachers/most-booked-teachers/most-booked-teachers.component';
import { OurMostPopularSubjectsComponent } from 'src/app/shared/components/our-most-popular-subjects/our-most-popular-subjects.component';
import { OurMostPopularLessonsComponent } from 'src/app/shared/components/our-most-popular-lessons/our-most-popular-lessons.component';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  SubjectInfo,
  TeacherSubjectAcademicSemeste,
} from 'src/app/shared/Model/teacher/subject-info';
import { Subscription } from 'rxjs';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    OurMostPopularLessonsComponent,
    OurMostPopularSubjectsComponent,
    MostBookedTeachersComponent,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    NgSelectModule,
    TooltipModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected EducationTypes!: SubjectInfo[];
  protected EducationLevel!: SubjectInfo[];
  protected AcademicYear!: SubjectInfo[];
  protected SubjectSemester!: SubjectInfo[];
  protected TeacherSubjectAcademic!: TeacherSubjectAcademicSemeste[];
  protected subscription = new Subscription();
  submit: boolean = true;
  language: string = "en";

  constructor(
    private seoService: SeoService,
    private layoutService: LayoutService,
    private _Lookup: LookupService,
    private _fb: FormBuilder,
    private router: Router,
    private _AuthService: AuthService
  ) {}
  ngOnInit(): void {
    this._AuthService.ReloadHeader(true);
    this.language=this._AuthService.GetByName('language')
    this.initiate();
    this.GetEducationType();
    this.GetAcademicSemester();
    this.seo()
  }
  protected get fc() {
    return this.insertForm.controls;
  }

  private initiate() {
    this.insertForm = this._fb.group({
      EducationTypeId: ['', Validators.required],
      EducationLevelId: ['', Validators.required],
      SubjectSemesterId: ['', Validators.required],
      subjectAcademicYearId: [''],
    });
  }

  GetEducationType() {
    this._Lookup
      .GetEducationType()
      .subscribe((_EducationTypes) => (this.EducationTypes = _EducationTypes));
  }
  protected ChangEducationType() {
    this.fc['EducationLevelId'].setValue('');
    this.fc['SubjectSemesterId'].setValue('');

    this.GetEducationLevel();
  }
  GetEducationLevel() {
    if (
      this.fc['EducationTypeId'].value != null &&
      this.fc['EducationTypeId'].value != ''
    )
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    else {
      this.EducationLevel = [];
      this.AcademicYear = [];
    }
  }

  protected ChangEducationLevel() {
    this.fc['SubjectSemesterId'].setValue('');
    this.SelectedEducationLevel();
  }
  // get from AcademicYear
  private SelectedEducationLevel() {
    if (
      this.fc['EducationLevelId'].value != null &&
      this.fc['EducationLevelId'].value != ''
    ) {
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
          .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
      );
    } else this.AcademicYear = [];
  }

  protected ChangAcademicYear() {
    // this.fc['subjectAcademicYearId'].setValue(0);
    // this.SelectedAcademicYear();
  }
  // get from AcademicYear
  private GetAcademicSemester() {
    this._Lookup
      .GetAcademicSemester()
      .subscribe(
        (_SubjectSemester) => (this.SubjectSemester = _SubjectSemester)
      );
  }

  protected onSubmit() {
    this.submit = false;
    if (this.insertForm.valid) {
      // Perform the submit logic here
      this.navigateToRoute();
    } else {
      this.insertForm.markAllAsTouched();
      this.submit = true;
    }
  }

  // Function to set query parameters and navigate to an absolute route

  private navigateToRoute() {
    if (this.fc['SubjectSemesterId'].value != null) {
      let queryParams = {
        academicleveid: this.fc['SubjectSemesterId'].value,
        semesterId: this.fc['subjectAcademicYearId'].value,
      };
      if (this.fc['subjectAcademicYearId'].value == null)
        delete queryParams['semesterId'];
      this.router.navigate(['main/ShowingResults'], {
        queryParams: queryParams,
        queryParamsHandling: 'merge', // or 'preserve' or merge or ''
      });
    }
  }

  private seo(){
    this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.Home.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
