import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-tow',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
  ],
  templateUrl: './footer-tow.component.html',
  styleUrls: ['./footer-tow.component.scss']
})
export class FooterTowComponent {
  protected date =  Date.now()

}
