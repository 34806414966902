<div class="container h-100">
  <h2 class="dark:text-white Lama-bold">{{"Finance.header.Finance" | translate}}</h2>
  <label class="dark:text-white">{{ "Finance.header.FinanceSubtitle" | translate }}</label>
   <div class=" bg-[#ffffff] rounded-lg cardsSpace   p-4 shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-3 BalancCard" >
   <h3 class="currentBalanceTxt text-[#1B2651] dark:text-white">{{"Finance.balanceCard.CurrentBalance" | translate}}</h3>
   <div class="grid gap-2 mb-12 lg:grid-cols-2 mt-5">

     <div class="ml-20 par40">
       <h1 class="BalancNumb text-[#1B2651] dark:text-white">{{Finance?.currentBalance}} {{"Finance.balanceCard.LE" | translate}}</h1>
       <label calss="dark:text-white">{{CurrentDate}}</label>
     </div>
   </div>
 </div>

 <div
  class=" bg-[#ffffff] rounded-lg cardsSpace   shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-3"
>
  <div class="relative overflow-x-auto dark:bg-[#1a202c]">
    <div
      class="d-flex align-items-center    border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <div >
        <h3 class="text-2xl text-left font-bold dark:text-white underTitle">
          {{ "Finance.tableCard.PurchasedSubjects" | translate }}

        </h3>
        <p class="text-sm/[11px] text-left rtl:text-right dark:text-white">
          {{ "Finance.tableCard.PurchasedLessonsSubtitle" | translate }}
        </p>
      </div>
    </div>
    <div
      class="d-flex align-items-center startPagePadding  dark:bg-[#1a202c] rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <table

        class="w-full tableBorder text-sm text-left rtl:text-right  dark:bg-[#1a202c] text-white-500 dark:text-white-400"
      >
        <thead
          class="text-xs text-white bg-[#FA0]  dark:bg-[#1a202c] dark:bg-blend-darken dark:text-white-400"
        >
        <tr>
          <th scope="col" class="px-6 py-3">
             {{'Finance.body.LessonName' |translate}}
          </th>
          <th scope="col" class="px-6 py-3">
            {{'Finance.body.TeacherName' |translate}}

          </th>
          <th scope="col" class="px-6 py-3">
            {{'Finance.body.Amount' |translate}}

          </th>
          <th scope="col" class="px-6 py-3 text-center">
            {{'Finance.body.Date' |translate}}
          </th>

      </tr>
        </thead>
        <tbody>
          <tr class="bg-white  border-b-2  dark:bg-[#1a202c] dark:border-gray-700"
          *ngFor="let item of FinanceSubjects | paginate
          : {
              id: 'pagin',
              itemsPerPage: pager.maxResultCount,
              currentPage: pageNumber,
              totalItems: totalRecords
            }; let y = index"
          >
              <th scope="row" class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white">
                {{item.subjectName}}
              </th>
              <td class="px-6 py-4">
                {{item.teacherName}}
              </td>
              <td class="px-6 py-4">
                {{item.amount}}
              </td>
              <td class="px-6 py-4 text-center">
                {{ getFormattedDate(item.date) }}

              </td>

              <!-- <td class="px-6 py-4"    >
                  <a class="font-medium text-red-600 red:text-blue-500 hover:underline"  >   <img src="assets/images/Icons/delete.png" alt="Icons delete" srcset=""
        /></a>
              </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <pagination-controls
        id="pagin"
        previousLabel=""
        nextLabel=""
        style="display: flex; justify-content: center"
        (pageChange)="pageChanged((page = $event))"
      ></pagination-controls>
    </div>
  </div>
  <!-- </div> -->
</div>
<div
  class=" bg-[#ffffff] rounded-lg cardsSpace dark:bg-[#1a202c] shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-3"
>
  <div class="relative overflow-x-auto dark:bg-[#1a202c]">
    <div
      class="d-flex align-items-center  dark:bg-[#1a202c] border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >

      <div >
        <h3 class="text-2xl text-left font-bold dark:text-white underTitle">
          {{ "Finance.tableCard.PurchasedLessons" | translate }}

        </h3>
        <p class="text-sm/[11px] text-left rtl:text-right dark:text-white">
          {{ "Finance.tableCard.PurchasedLessonsSubtitle" | translate }}
        </p>
      </div>
    </div>
    <div
      class="d-flex align-items-center startPagePadding  dark:bg-[#1a202c] rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <table
        class="w-full tableBorder text-sm text-left rtl:text-right  dark:bg-[#1a202c] text-white-500 dark:text-white-400"
      >
        <thead
          class="text-xs text-white bg-[#FA0]  dark:bg-[#1a202c] dark:bg-blend-darken dark:text-white-400"
        >
        <tr>
          <th scope="col" class="px-6 py-3">
             {{'Finance.body.Subject' |translate}}
          </th>
          <th scope="col" class="px-6 py-3">
            {{'Finance.body.TeacherName' |translate}}

          </th>
          <th scope="col" class="px-6 py-3">
            {{'Finance.body.Amount' |translate}}

          </th>
          <th scope="col" class="px-6 py-3 text-center">
            {{'Finance.body.Date' |translate}}
          </th>

      </tr>
        </thead>
        <tbody>
          <tr class="bg-white  border-b-2  dark:bg-[#1a202c] dark:border-gray-700"
          *ngFor="let item of FinanceLessons  | paginate
          : {
              id: 'pagin2',
              itemsPerPage: pagerPagedFinanceLessons.maxResultCount,
              currentPage: pageNumberPagedFinanceLessons,
              totalItems: totalRecordsPagedFinanceLessons
            }; let y = index"
          >
              <th scope="row" class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white">
                {{item.lessonName}}
              </th>
              <td class="px-6 py-4">
                {{item.teacherName}}
              </td>
              <td class="px-6 py-4">
                {{item.amount}}
              </td>
              <td class="px-6 py-4 text-center">
                {{ getFormattedDate(item.date) }}
              </td>

              <!-- <td class="px-6 py-4"    >
                  <a class="font-medium text-red-600 red:text-blue-500 hover:underline"  >   <img src="assets/images/Icons/delete.png" alt="Icons delete" srcset=""
        /></a>
              </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <pagination-controls
        id="pagin2"
        previousLabel=""
        nextLabel=""
        style="display: flex; justify-content: center"
        (pageChange)="pageChangedPagedFinanceLessons((page = $event))"
      ></pagination-controls>
    </div>
  </div>
  <!-- </div> -->
</div>
 </div>
