import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
   FormBuilder,
   FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import {
  ITteacherCompletedLessonStudentList,
} from 'src/app/shared/Model/teacher/subject-group';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
 import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-student-completed-lesson',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
    NgxPaginationModule,
    NgbRatingModule,
  ],
  templateUrl: './student-completed-lesson.component.html',
  styleUrls: ['./student-completed-lesson.component.scss'],
})
export class StudentCompletedLessonComponent
  extends BaseComponent
  implements OnInit
{
  protected StudentCompletedLesson: any[] = [];
  protected SearchForm!: FormGroup;
  protected SubjectAcademicSemesterYearForList!: any;
  protected StudentLessonForList: any;
  protected StudentLessonObject: any;
  page!: number;
  modalRef?: BsModalRef;
  TeacherLessonId: any;
  StudentRate: number = 0;
  ratingSelected: boolean = false;
  StudentCompletedLessonIndex: any;
  BookTeacherLessonseSsionDetailId: any;
  StudentId: any;

  constructor(
    private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,    private _transition: TranslateService,
    protected auth: AuthService,
    protected StudentService: StudentService,
    protected _GroupForLessonService: GroupForLessonService,
    private router: Router,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    // if (typeof this._activatedRoute.snapshot.params['id'] !== 'undefined') {
    //   this.teacherLessonId = this._activatedRoute.snapshot.params['id'];
    //   this.GetMyLessonMaterials({ teacherLessonId: this.teacherLessonId });
    // }
    this.initiateSearch();
    this.GetStudentCompletedLesson();
    this.GetStudentSubjectAcademicSemesterYearForList();
    this.seo();
  }

  private initiateSearch(row?: any): void {
    this.SearchForm = this._fb.group({
      subjectId: [null, [Validators.required]],
      groupName: [null],
      lessonDate: null,
      lessonId: null,
    });
  }

  get fc() {
    return this.SearchForm.controls;
  }

  removeNullfromPager(pager: any): void {
    for (const prop in pager) {
      if (
        (pager.hasOwnProperty(prop) && pager[prop] === null) ||
        pager[prop] == ''
      ) {
        delete pager[prop];
      }
    }
  }
  GetStudentCompletedLesson() {
    this.removeNullfromPager(this.pager);
    //  if parent  chacke from select Student
    if (this.auth.getRoleId() == '3') {
      {
        if (!this.auth.getCurrentChildrenId()) {
          this.router.navigate([`/main/parent/ParentStudents`]);
          return;
        }
        this.pager.studentId = this.auth.getCurrentChildrenId();
      }
    }


    this.StudentService.StudentCompletedLesson(this.pager).subscribe(
      (res: any) => {
        // Handle the data here
        this.StudentCompletedLesson = res.data.items;
        this.totalRecords = res.data.totalCount;
      },
      (error: any) => {
        // Handle errors here
      }
    );
  }

  protected ChangeTeacherLessonForList() {
    const foundObject = this.StudentLessonForList.find(
      (item: any) => item.id == this.fc['lessonId'].value
    );
    if (foundObject != null) {
      this.StudentLessonObject = foundObject;
      // this.fc['teacherSubjectAcademicSemesterYearName'].setValue(
      //   foundObject
      // );
    }
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetStudentCompletedLesson();
    this.pageNumber;
  }
  SearchFormPag() {
    this.pager.subjectId = this.SearchForm.get('subjectId')?.value;
    this.pager.lessonId = this.SearchForm.get('lessonId')?.value;
    this.pager.groupName = this.SearchForm.get('groupName')?.value;
    this.pager.lessonDate = this.SearchForm.get('lessonDate')?.value;
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.GetStudentCompletedLesson();
    this.pageNumber;
  }

  /**
   *
   *@GetTeacherSubjectAcademicSemesterYearForList
   * @private
   * @memberof CompletedlessonsComponent
   */

  private GetStudentSubjectAcademicSemesterYearForList(id?: number): void {
    this.StudentService.GetBookedStudentSubjects(id ?? null).subscribe(
      (res) => {
        this.SubjectAcademicSemesterYearForList = res.data;
      }
    );
  }

  protected ChangStudentSubjectAcademicSemesterYearForList() {
    this.fc['lessonId'].setValue(null);
    this.StudentLessonForList = [];
    if (this.fc['subjectId'].value != '' && this.fc['subjectId'].value != null)
      this.GetStudentLessonForList();
  }

  /**
   *
   * @GetTeacherLessonForList
   * @private
   * @memberof GroupForLessonComponent
   */
  private GetStudentLessonForList() {
    this.StudentService.GetBookedStudentLessons(
      this.fc['subjectId'].value
    ).subscribe((res) => {
      this.StudentLessonForList = res;
    });
  }

  navigateToMatrial(id: number) {
    this.router.navigate([`/main/StudentMatrial/${id}`]);
  }

  // Example in a component method
  navigateWithQueryParams(
    TteacherCompletedLessonStudentList: ITteacherCompletedLessonStudentList
  ): void {
    const queryParams = {
      bookTeacherLessonSessionDetailId:
        TteacherCompletedLessonStudentList.bookSessionDetailId,
      studentId: TteacherCompletedLessonStudentList.studentId,
    };

    this.router.navigate([
      `/main/student/Chat/${queryParams.studentId}/${queryParams.bookTeacherLessonSessionDetailId}`,
    ]); //{ queryParams }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  OpenToRate(item: any, template: any, index: any) {
    if (!item.isRated) {
      this.StudentCompletedLessonIndex = index;
      this.TeacherLessonId = item.teacherLessonId;
      this.BookTeacherLessonseSsionDetailId=item.bookSessionDetailId
      this.StudentId=item.studentId
      this.openModal(template);
    }
  }
  onRateChange(rate: number) {
    // Handle rate change event
    this.StudentRate = rate;
  }

  AddRate() {
    const data = {
      studentId:this.StudentId,
      teacherLessonId: this.TeacherLessonId,
      bookTeacherLessonSessionDetailId:this.BookTeacherLessonseSsionDetailId,
      rate: this.StudentRate,
    };
    this.StudentService.AddStudentRate(data).subscribe((res) => {
      this.StudentRate = 0;
      this.StudentCompletedLesson[this.StudentCompletedLessonIndex].isRated =
        true;
      Swal.fire({
        icon: 'success',
        title: this._transition.instant('RateSuccessfully'),
        showConfirmButton: false,
        timer: 1500,
      });
    });
  }

  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.StudentCompletedLesson.Tabble.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
