import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from 'src/app/shared/Model/Generic/generic-response';
;
import { IGetStudentProfile } from 'src/app/shared/Model/Student/student-edit-profile';
import { AuthService } from '../../AuthService/auth.service';
import { Router } from '@angular/router';
import { ChildrenIdService } from 'src/app/Component/Parent/choose-student/children-id.service';
@Injectable({
  providedIn: 'root'
})
export class StudentEditProfileService {

  constructor(private http: HttpClient,private auth:AuthService ,
    private child:ChildrenIdService,
    private router:Router) {}
  UpdateProfileStudent(data:FormData):Observable<IGenericResponseObject<IGetStudentProfile>>{

    if(  this.auth.getRoleId() == '3')
      {

        const CurrentChildrenId = this.child.getCurrentChildrenId();
        if (!CurrentChildrenId) {
            this.router.navigate([`/main/parent/ParentStudents`]);
        } else {
            data.append('studentId', CurrentChildrenId.toString());
        }

    }
    return this.http.post<IGenericResponseObject<IGetStudentProfile>>(`${env.Server_URL}Student/UpdateProfile`,data)
  }
  GetProfileStudent():Observable<IGetStudentProfile>{
    return this.http.get<IGenericResponseObject<IGetStudentProfile>>(`${env.Server_URL}Student/Profile`).pipe( map((res) => res.data ))
  }
}
