import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbRatingModule, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChildrenIdService } from 'src/app/Component/Parent/choose-student/children-id.service';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-student-matrial',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
  NgSelectModule,
  NgbRatingModule,
  NgbPagination
  ],
  templateUrl: './student-matrial.component.html',
  styleUrls: ['./student-matrial.component.scss']
})
export class StudentMatrialComponent {
Teacherlessonid: any;
  StudentLessonMaterials: any;
constructor(
  private seoService: SeoService,
  private layoutService: LayoutService,
  protected auth: AuthService,

  private StudentService:StudentService,private router:Router,private route :ActivatedRoute){

}
ngOnInit(): void {
  if (this.auth.getRoleId() == '3') {
    {
      if (!this.auth.getCurrentChildrenId()) {
        this.router.navigate([`/main/parent/ParentStudents`]);
        return;
      }

    }
  }
  if (this.route.snapshot.paramMap.get("id")) {
    this.Teacherlessonid=this.route.snapshot.paramMap.get("id")
    this.GetStudentLessonMaterials(this.Teacherlessonid);
  }

}
downloadFiles(materialUrl:any){
  window.open(materialUrl, '_blank');
}
GetStudentLessonMaterials(Teacherlessonid:any){
this.StudentService.GetStudentLessonMaterials(Teacherlessonid).subscribe(res=>{
  this.StudentLessonMaterials=res.data;

  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.StudentMatrial.header.Title1 + '-' +  this.StudentLessonMaterials?.subjectName +'-' +  this.StudentLessonMaterials?.lessonName );
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(this.StudentLessonMaterials.subjectBrief)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.StudentLessonMaterials.subjectBrief +"-" + this.StudentLessonMaterials?.lessonBrief ))


  });

})
}


}
