import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import AOS from "aos";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SSRService } from './shared/Apis/appsettings/ssr.service';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { SeoService } from './shared/Apis/appsettings/seo.service';
import { EnvironmentService } from './shared/core/layout.service/environment.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from './shared/core/layout.service/layout.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'web-app';

  constructor(
    private layoutService:LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object,
    translate: TranslateService,
    private _router: Router,
    private SeoService: SeoService,
    private metaTagService: Meta,
    private environmentService: EnvironmentService
  ){
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('ar');
      translate.addLangs(['en', 'ar']);
    }

    this.events();
    this.addMetaTags();


  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof document !== 'undefined') {
        import('aos').then((module) => {
          // Initialize AOS here
          AOS.init({
            duration: 800, // Animation duration in milliseconds
            offset: 200, // Offset (in px) from the original trigger point
            easing: 'ease-in-out', // Easing function for the animation
          });
        });
      }
      if (typeof document !== 'undefined') {
        import('flowbite').then((flowbite) => {
          // Initialize flowbite components here
          initFlowbite();
        });
      }

    }
  }
  private events() {
    this._router.events.subscribe((event) => {
      // console.log("frist",event)
      if (event instanceof NavigationStart) {
        this.SeoService.setHostUrlIndex(event);
      }
    });
  }

  private addMetaTags() {
    const lang = this.layoutService.config.langu;
    const oldTitle = lang === 'ar' ? 'مستر اس كول' : 'Mr. S Cool';
    this.metaTagService.addTags([
      // <!-- Search Engine Optimization -->
      { name: 'viewport', content: "width=device-width,initial-scale=1.0" },
      {
        name: 'description',
        content: `مستر إس كول هي منصة تعليمية إلكترونية رائدة في مصر..  يجلب لك تطبيق مستر اس كول من مستر اس كول الحل في متناول يديك!`,
      },
      {
        name: 'author',
        property: 'article:author',
        content: oldTitle,
      },
      {
        name: 'keywords',
        property: 'schema:keywords',
        content: `مستر إس كول هي منصة تعليمية إلكترونية رائدة في مصر..  يجلب لك تطبيق مستر اس كول من مستر اس كول الحل في متناول يديك!`,
      },
      {
        name: 'robots',
        content: "index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
      },
      {
        name: 'original-source',
        content: "https://platform.mrscool.app"
      },
      {
        name: 'copyright',
        content: "Copyright 2024 wecancity.com, All rights reserved.",
      },
      // <!-- ekrameg.org Facebook Card -->
      { property: 'og:locale', content: 'ar_AR' },
      {
        name: 'title',
        property: 'og:title',
        content: oldTitle,
      },
      {
        name: "mobile-web-app-capable",
        content: 'yes',
      },
      {
        name: 'apple-mobile-web-app-capable',
        content: 'yes',
      },
      {
        charset: "utf-8",
      },
      { name: "csrf-token", content: "" },
      { property: 'og:type', content: 'wecancity.com' },
      { property: 'og:url', content: 'https://platform.mrscool.app/' },
      { property: 'og:image', content: 'Image' },
      { property: 'og:image:secure_url', content: 'Image' },
      { property: 'og:image:type', content: 'image/png' },
      { property: 'og:image:width', content: '1080' },
      { property: 'og:image:height', content: '1080' },
      { property: 'og:image:alt', content: oldTitle },
      { property: 'og:site_name', content: oldTitle },
      { property: 'og:description', content: oldTitle },

      // Additional Meta Tags
      { name: 'application-name', content: oldTitle },
      { name: 'msapplication-config', content: 'none' },
      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-square70x70logo', content: 'https://platform.mrscool.app/assets/images/ms-scoolLogo/ms-scool Logo_70x70.png' },
      { name: 'msapplication-square150x150logo', content: 'https://platform.mrscool.app/assets/images/ms-scoolLogo/ms-scool Logo_150x150.png' },
      { name: 'msapplication-wide310x150logo', content: 'https://platform.mrscool.app/assets/images/ms-scoolLogo/ms-scool Logo_310X150.png' },
      { name: 'msapplication-square310x310logo', content: 'https://platform.mrscool.app/assets/images/ms-scoolLogo/ms-scool Logo_310X310.png' }
    ]);
  }

}
