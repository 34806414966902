import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { IStudentChat } from '../../../Model/Student/student-chat';
import { AuthService } from '../../AuthService/auth.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class StudentLessonSessionCommentService {
  constructor(private http: HttpClient,private auth:AuthService, private router:Router) {}

  GetAllTeachersChatWithStudent(studentId?:string):Observable<any>{
    let url = `${env.Server_URL}StudentLessonSessionComment/GetAllTeachersChatWithStudent`;
        // Use HttpParams to build query parameters
        let params = new HttpParams();

        if(  this.auth.getRoleId() == '3')
          {
              {
                  if( !this.auth.getCurrentChildrenId())
                      this.router.navigate([`/main/parent/ParentStudents`]);
                  params = params.set('studentId', this.auth.getCurrentChildrenId());
              }

        }

    return this.http.get<IGenericResponse<any>>(url, { params })
  }

  GetSubjectSchedualGroupDetailsById(id:number):Observable<any>{
    return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}StudentLessonSessionComment/GetAllLessonChatByStudent?bookTeacherLessonSessionDetailId=${id}`).pipe( map((res) => res.data ))
  }
  SendMessage(data:any):Observable<IGenericResponseObject<any>>{

    if(  this.auth.getRoleId() == '3')
      {
          {
              if( !this.auth.getCurrentChildrenId())
                  this.router.navigate([`/main/parent/ParentStudents`]);
                  data.studentId=this.auth.getCurrentChildrenId();
          }

    }
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}StudentLessonSessionComment/CreateComment`,data)
  }
}
