import {
  CommonModule,
  DatePipe,
  formatDate,
  isPlatformBrowser,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import {
  NgbPagination,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { environment } from 'src/environments/environment';
import { MostBookedTeachersComponent } from '../../MostBookedTeachers/most-booked-teachers/most-booked-teachers.component';
import { ChooseStudentComponent } from 'src/app/Component/Parent/choose-student/choose-student.component';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { InstaSlider } from 'src/app/shared/core/data/slider';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import Swal from 'sweetalert2';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';

registerLocaleData(localeAr);
@Component({
  selector: 'app-teacher-subject-info',
  standalone: true,
  imports: [
    CommonModule,
    CustomPipeForImagesPipe,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    NgbProgressbarModule,
    MostBookedTeachersComponent,
    ChooseStudentComponent,
    TranslateModule,
    NgbRatingModule,
    NgbPagination,
    NgxPaginationModule,
    RouterLink,
  ],

  providers: [DatePipe,    { provide: LOCALE_ID, useValue: 'ar' }],
  templateUrl: './teacher-subject-info.component.html',
  styleUrls: ['./teacher-subject-info.component.scss'],
})
export class TeacherSubjectInfoComponent
  extends BaseComponent
  implements OnInit
{
  checkRoll(item: any): boolean {
    if (
      (this.auth.getToken() && this.auth.getRoleId() == '1') ||
      (this.auth.getToken() &&
        this.auth.getRoleId() == '3' &&
        !this.auth.getCurrentChildrenId()) ||
      this.auth.getCurrentChildrenId() == 'undefined'
    ) {
      if (this.auth.getRoleId() == '3') {
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('StudentEmpty'),
        });
      } else
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('Serviceavailable'),
        });
      return false;
    } else {
      this.localStorage(item);
      this.router.navigate([`/main/Booking/` + item.teacherLessonSessionId]);
      return true;
    }
  }
  SubjectDetails: any;
  ImgUrlServer = environment.serverFirstHalfOfImageUrl;
  data = {};
  TeacherSubjectId: any;
  TeacherSubjectGroupDetail: any;
  rating = 0;
  page!: number;
  public InstaSliderConfig: any = InstaSlider;

  @ViewChildren('embeddedImage') embeddedImages!: QueryList<ElementRef>;
  DetailsForMostBooked: any;
  type: any;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private SubjectInfoService: SubjectInfoService,
    private route: ActivatedRoute,
    protected auth: AuthService,
    private _transition: TranslateService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.subscribe((params) => {
        this.TeacherSubjectId = params.get('id');
        if (isPlatformBrowser(this.platformId)) {
          this.type = history.state.data;
          this.GetTeacherSubjectGroupDetail();
        }
      });
      // if (this.type.type == 'Subject') {
      //   this.GetTeacherSubjectGroupDetail();
      // } else if (this.type.type == 'Lesson') {
      // }
    }
  }
  GetTeacherSubjectGroupDetail() {
    this.SubjectInfoService.GetTeacherSubjectGroupDetail(
      this.TeacherSubjectId
    ).subscribe((res: any) => {
      this.TeacherSubjectGroupDetail = res;
      this.SubjectDetails = res.getSubjectOrLessonDto;
      this.DetailsForMostBooked = {
        exceptTeacherId: this.TeacherSubjectGroupDetail.teacherId,
      };
      this.seoService.setMetaImage(
        this.SubjectDetails?.image
          ? this.SubjectDetails?.image
          : 'assets/images/MrS-Cool/subjectDetails.png'
      );
      this.seoService.setTitle(
        this.TeacherSubjectGroupDetail?.teacherName +
          ' - ' +
          this.SubjectDetails?.headerName ?? ''
      );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        this.SubjectDetails?.systemBrief ?? ''
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(this.SubjectDetails?.systemBrie ?? '')
      );
    });
  }
  localStorage(BookTeacher: any) {
    const BookTeacher$ = {
      teacherLessonSessionId: BookTeacher.teacherLessonSessionId,
    };
    localStorage.setItem('BookTeacher', JSON.stringify(BookTeacher$));
  }
  // convertTime(inputTime: string): string | null {
  //   const time = new Date(`2000-01-01T${inputTime}`);
  //   return this.datePipe.transform(time, 'h:mm a') || null;
  // }

  convertTime(inputTime: string): string | null {
    const time = new Date(`2000-01-01T${inputTime}`);

    const lang = this.layoutService.config.langu;
    const formattedTime = this.datePipe.transform(time, 'h:mm a', undefined, lang);

    // إزالة المسافة إذا كانت اللغة "ar" ليظهر "3:00م" مثلاً
    return formattedTime ? formattedTime.replace(' ', '') : null;
  }


  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }
  }
  convertDurationToHours(duration: number): string {
    const lang = this.layoutService.config.langu; // en | ar
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    if (lang == 'ar') {
      // تنسيق الوقت للعربية
      return `${formattedMinutes} : ${formattedHours} ساعات`;
    } else {
      // تنسيق الوقت للإنجليزية أو اللغات الأخرى
      return `${formattedHours} : ${formattedMinutes} hrs`;
    }
  }

  onLeave(rate: number) {
    if (this.pager.rate === 1) {
      // If the current rate is 1 and the user leaves the rating component, reset it to 0
      this.pager.rate = 0;
    }
  }

  onStudentSelect(selectedItem: any) {
    this.router.navigate([`/main/parent/StudentSubjects/${selectedItem}`]);
  }

  private seo() {
    this.seoService.setMetaImage(
      this.SubjectDetails?.image
        ? this.SubjectDetails?.image
        : 'assets/images/MrS-Cool/subjectDetails.png'
    );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(
        translations.TeacherSignUp.header.PesrsonalInformatio
      );
      this.seoService.setHostUrlIndex();
      // this.seoService.setMetaDescription(translations.Home.header.meta_description)
      // this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });
  }

  getFormattedDate(date: Date): string {

    const lang = this.layoutService.config.langu;
    const locale =lang === 'ar' ? 'ar-EG' : 'en-US';

    return formatDate(date, 'd MMM yyyy', locale);
  }
}
