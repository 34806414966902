<div  class="flex flex-wrap">
  <div class="flex justify-content-center sm:w-1/1 xl:w-2/6  max-xl:hidden">
    <!-- <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
      <input type="file" formControlName="TeacherImage" accept="image/jpeg,image/png,image/gif" />
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('required')">Please select an image</div>
      <div *ngIf="insertForm?.get('imaTeacherImagege')?.hasError('invalidFileType')">Invalid file type. Allowed types: JPEG, PNG, GIF</div>
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('invalidFileSize')">File size exceeds the maximum limit (5MB)</div>
      <button type="submit">Submit</button>
    </form> -->
    <img
      src="assets/images/MrS-Cool/left_student.png"
      class="w-full imgPosition"
    />
  </div>

  <div
    class="flexFormDivAuth   w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="StudentSteps.signUp == true"
  >
    <app-nav-tabs class="w-full mb-4"></app-nav-tabs>
    <form
    class="w-full   max-md:p-[50px] bg-white md:w-10/12 ProfilePadding rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
    [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div>
        <h2

          class="text-[16px] rtl:text-right  col-span-2 lg:text-[30px] text-left sora-bold dark:text-white"
        >
          {{ "StudentSignUp.header.PesrsonalInformation" | translate }}
        </h2>

        <p
          class="text-left  rtl:text-right text-[13px] sora-Regular dark:text-white col-span-2"
        >
          {{ "StudentSignUp.header.Enter-subtitle-here" | translate }}
        </p>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2 mt-3">
        <div >
          <label
            for="first_name"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Student-Name" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            >
          </label>
          <input
            type="text"
            formControlName="Name"
            [tooltip]="
              fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
                ? ('StudentSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
                  'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
                }"
            id="first_name"
            class="bg-white   border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="('StudentSignUp.body.required' | translate)"
            required
          />
        </div>
        <div>
          <label
            for="phone"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Mobile-Number" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            type="tel"
            id="phone"
            formControlName="Mobile"
            onlyNumbers
            maxlength="11"
            minlength="11"
            class="bg-white rtl:text-end  border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="('StudentSignUp.body.Enter-your-number' | translate)"
            [tooltip]="
              fc['Mobile'].invalid &&
              (fc['Mobile'].dirty || fc['Mobile'].touched)
                ? ('this field is required' | translate)
                : null
            "
            [ngClass]="{
              'class-1': fc['Mobile'].invalid && (fc['Mobile'].dirty || fc['Mobile'].touched),
              'class-2': fc['Mobile'].valid && (fc['Mobile'].dirty || fc['Mobile'].touched),
            }"
          />
          <!-- <label for="success" class="block mb-2  text-green-700 dark:text-green-500">Your name</label>
        <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
        <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
        </div>
        <div>
          <label
            for="countries"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Gender" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Gender"
            #Gender
            [selectOnTab]="true"
            formControlName="GenderId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GenderId'].errors?.['required'] ? ('StudentSignUp.body.required' | translate) : null"
            [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GenderId'].valid &&
              (fc['GenderId'].touched || fc['GenderId'].value)
            "
            [class.is-invalid]="
              fc['GenderId'].invalid && fc['GenderId'].touched
            "
            [ngClass]="{
      'class-1': fc['GenderId'].invalid && (fc['GenderId'].dirty || fc['GenderId'].touched),
      'class-2': fc['GenderId'].valid && (fc['GenderId'].dirty || fc['GenderId'].touched),
    }"
          >
            <ng-option selected value="" >
              {{ "StudentSignUp.body.Male/Female" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of genders" [value]="item.id"
              >{{ item.title |translate }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label
            for="Birthdate"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Birthdate" | translate }}</label
          >
          <input
            type="date"
            id="Birthdate"
            (keypress)="$event.preventDefault()"
            [max]="expirationDateNow"
            formControlName="Birthdate"
            class="bg-white  rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            required
            [placeholder]="('StudentSignUp.body.Enter-your-Birthdate' | translate)"
            [tooltip]="
              fc['Birthdate'].invalid &&
              (fc['Birthdate'].dirty || fc['Birthdate'].touched)
                ? ('StudentSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
          'class-1': fc['Birthdate'].invalid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
          'class-2': fc['Birthdate'].valid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
        }"
          />

        </div>

        <div>
          <label
            for="educationtype"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Education-Type" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Education_"
            #EducationType
            [selectOnTab]="true"
            formControlName="EducationTypeId"
            class="form-control p-0  "
            (change)="ChangEducationType()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['EducationTypeId'].errors?.['required'] ? ('StudentSignUp.body.required' | translate): null"
            [isDisabled]="
              !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['EducationTypeId'].valid &&
              (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
            "
            [class.is-invalid]="
              fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
            "
            [ngClass]="{
      'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
      'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
    }"
          >
            <ng-option value="">
              {{ "StudentSignUp.body.Choose-Education-Type" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of EducationTypes" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="educationlevel"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Education-Level" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="EducationLevel_"
            #EducationType
            [selectOnTab]="true"
            formControlName="EducationLevelId"
            class="form-control p-0"
            (change)="ChangEducationLevel()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['EducationLevelId'].errors?.['required'] ? ('StudentSignUp.body.required' | translate) : null"
            [isDisabled]="
              !fc['EducationLevelId'].errors && fc['EducationLevelId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['EducationLevelId'].valid &&
              (fc['EducationLevelId'].touched || fc['EducationLevelId'].value)
            "
            [class.is-invalid]="
              fc['EducationLevelId'].invalid && fc['EducationLevelId'].touched
            "
            [ngClass]="{
      'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
      'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
    }"
          >
            <ng-option value=""  >
              {{ "StudentSignUp.body.Choose-Education-Level" | translate }}
            </ng-option>
            <ng-option  *ngFor="let item of EducationLevel" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div >
          <label
            for="academicyear"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Academic-Year" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="AcademicYearEducationLevelId"
            #EducationType
            [selectOnTab]="true"
            formControlName="AcademicYearEducationLevelId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['AcademicYearEducationLevelId'].errors?.['required'] ? ('StudentSignUp.body.required' | translate): null"
            [isDisabled]="
              !fc['AcademicYearEducationLevelId'].errors &&
              fc['AcademicYearEducationLevelId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['AcademicYearEducationLevelId'].valid &&
              (fc['AcademicYearEducationLevelId'].touched ||
                fc['AcademicYearEducationLevelId'].value)
            "
            [class.is-invalid]="
              fc['AcademicYearEducationLevelId'].invalid &&
              fc['AcademicYearEducationLevelId'].touched
            "
            [ngClass]="{
      'class-1': fc['AcademicYearEducationLevelId'].invalid && (fc['AcademicYearEducationLevelId'].dirty || fc['AcademicYearEducationLevelId'].touched),
      'class-2': fc['AcademicYearEducationLevelId'].valid && (fc['AcademicYearEducationLevelId'].dirty || fc['AcademicYearEducationLevelId'].touched),
    }"
          >
            <ng-option class=" " value="">
              {{ "StudentSignUp.body.Choose-Academic-Year" | translate }}
            </ng-option>
            <ng-option class=" " *ngFor="let item of AcademicYear" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label
            for="password"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Password" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            id="password"
            formControlName="PasswordHash"
            class="bg-white   border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'StudentSignUp.body.Enter-your-password' | translate"
            required
            [tooltip]="
            fc['PasswordHash'].errors?.['required']
              ? ('StudentSignUp.body.required' | translate)
              : fc['PasswordHash'].errors?.['minlength']
                ? ('StudentSignUp.body.password-should-be-more-than-6-characters' | translate)
                : null"
            [ngClass]="{
                  'class-1': fc['PasswordHash'].invalid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                  'class-2': fc['PasswordHash'].valid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                }"
          />
        </div>
        <div>
          <label
            for="confirm_password"
            class="block   mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentSignUp.body.Confirmpassword" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            formControlName="confirmPassword"
            id="confirm_password"
            class="bg-white   border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'StudentSignUp.body.Enter-your-password' | translate"
            required
            [tooltip]="
            fc['confirmPassword'].errors?.['required']
              ? ('StudentSignUp.body.required' | translate)
              :  fc['confirmPassword'].errors?.['mustMatch']
                  ? ('StudentSignUp.body.Passwords-must-match' | translate)
                  : null"
            [ngClass]="{
                  'class-1': fc['confirmPassword'].invalid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                  'class-2': fc['confirmPassword'].valid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                }"
          />
        </div>
      </div>

      <div class="flex items-start mb-6">
        <div class="flex items-center h-5">
          <input
            id="remember"
            type="checkbox"
            formControlName="Accept"
            style="border: 3px solid var(--theme-deafult-studentWithClick)"
            class="w-4 h-4   bg-white focus:ring-3 focus:ring-[var(--theme-deafult-studentWithClick)] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:ring-offset-gray-800"
            [tooltip]="
              fc['Accept'].value == false &&
              (fc['Accept'].dirty || fc['Accept'].touched)
                ? ('TeacherSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
            'class-checkbox-err': fc['Accept'].value ==false && (fc['Accept'].dirty || fc['Accept'].touched),
            'class-radio': fc['Accept'].value ==true && (fc['Accept'].dirty || fc['Accept'].touched),
          }"
          />
        </div>
        <p
          for="remember"
          class="ml-2 underline   text-[12px] lg:text-[14px] sora-Regular text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
        >
          {{
            "StudentSignUp.body.Accept-the-Terms-and-Privacy-Policy" | translate
          }}
        </p>
      </div>

      <!-- <button type="submit"  class="text-white bg-blue-700 mb-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{{'Save & Next'|translate}}</button> -->
      <div class="mt-3 flex justify-between">
      <button
        type="submit"
        [ngClass]="{
 'bg-[var(--theme-deafult-studentWithClick)]':insertForm.valid && fc['Accept'].value ==true,
'bg-[#FFF2D9] text-[#CAAF79]':  insertForm.invalid,

}"
        class="text-[14px] btnH sora-Regular w-full mb-5 hover:bg-[var(--theme-deafult-studentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-studentWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        {{ "StudentSignUp.body.Submit" | translate }}
        <ng-container *ngIf="!submit">
          <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
        </ng-container>
      </button>
</div>

    </form>
  </div>

  <div
    class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5"
    *ngIf="StudentSteps.vefiryUser"
  >
    <app-vefiry-student
      [DataVefiryUser]="DataVefiryUser"
      [phoneNumber]="fc['Mobile'].value"
      (Step3)="handleEvent($event)"
    ></app-vefiry-student>
  </div>
</div>
