
<div class="global-card   m-auto w-full  rounded-lg   bg-white shadow p-2 my-2">

  <div class="px-8 py-3 addressIconSpace" >
    <h2 class="text-[18px] lg:text-[30px] sora-bold text-[var(--theme-deafult-TeacherWithClick)]">

      {{ type == "MostBookedLessons" ? ("our-most-popular-lessons.MostBookedLessons" | translate) : ("our-most-popular-lessons.MostViewedLessons" | translate) }}

    </h2>

    <hr>
  </div>
  <div class="w-full pb-2" *ngIf="data && data[0] !=null ">

    <owl-carousel-o dir="ltr" [options]="InstaSliderConfig" class="no-arrow">
      <ng-container *ngFor="let item of data">
        <ng-template carouselSlide>
          <div class="md:w-10/12  m-auto mb-1" data-aos="zoom-in-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000">
            <div
              class="logo-block cardH rounded-lg ... p-2"
              style="border: 1px solid var(--theme-deafult-studentWithClick)"
              (click)="GoToSubjectOrLessonTeachers(item.lessonId)"
            >
            <div class="contentCard">
              <img
              src="assets/images/OurMostPopularLessons/Book.svg"
              alt="Boolk loessons"
              class="m-auto w-28 book-lesson"
            />
            <p
              class="text-[14px]  mt-2 md:text-[18px] sora-SemiBold  text-center  "
            >
              {{ item.lessonName | truncate: 30}}
            </p>
            <p
              class="subjectName text-[12px] md:text-[16px]  text-center sora-bold mt-2   text-[var(--theme-deafult-studentWithClick)]"
            >
              {{ formatString(item.subjectName)[0] }}
            </p>
            <p class="text-[10px]   md:text-[12px] sora-bold   text-center   mt-2">
              {{ formatString(item.subjectName)[1] }} ,
              {{ formatString(item.subjectName)[2] }}
            </p>
            </div>

              <hr />

              <div class="grid NumColor gap-2 md:grid-cols-2">
                <p class="col-span-2 text-[10px] sora-Regular flex">
                  <img
                    src="assets/images/OurMostPopularLessons/person_black1.svg"
                    alt="Boolk loessons"
                    class="w-9 person_black inline-block mr-2"
                  />
                  <span class="inline-block">
                    <span class="sora-bold text-[12px] ">
                      {{ item.availableTeacher }} </span
                    >    {{"Showing.Available-Teachers"  |translate}} </span
                  >
                </p>
                <p
                  class="col-span-1 text-[10px] sora-Regular flex justify-items-center items-center"
                >
                  <img
                    src="assets/images/OurMostPopularLessons/mony.svg"
                    alt="Min Price"
                    class="w-9 person_black inline-block mr-2"
                  />
                  <span class="inline-block ">
                    {{ "Showing.MinPrice" | translate }}   :
                    <span class="sora-bold text-[12px]">
                      {{ item.minPrice }} </span
                    >{{ "Showing.EGP" | translate }} </span
                  >
                </p>
                <p
                  class="col-span-1 text-[10px] sora-Regular flex justify-items-center items-center"
                >
                  <img
                    src="assets/images/OurMostPopularLessons/mony.svg"
                    alt="Max Price"
                    class="w-9 person_black inline-block mr-2"
                  />
                  <span class="inline-block ">

                    {{ "Showing.MaxPrice" | translate }}    :
                    <span class="sora-bold text-[12px]">
                      {{ item.maxPrice }} </span
                      >{{ "Showing.EGP" | translate }} </span

                  >
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>


  <div class="text-center" *ngIf="!data || !data.length">
    <div class="my-5 px-8  flex justify-center" [ngSwitch]="type">

      <div class="text-center"   *ngSwitchCase="'MostBookedLessons'" >
        <img
          src="assets/images/OurMostPopularLessons/empty/MostBookedLessons.svg"
          alt="calendar"
          class="m-auto"
        />
        <p class="text-[#868B9F] mt-3">
          {{"Showing.NoAvailable"  |translate}}  <br />
          {{"Showing.most-booked-lessons-Yet"  |translate}}


          <span class="vector-bg"
            >

          </span>
        </p>
      </div>


      <div class="text-center"   *ngSwitchDefault  >
        <img
          src="assets/images/OurMostPopularLessons/empty/MostBookedLessons.svg"
          alt="calendar"
          class="m-auto"
        />
        <p class="text-[#868B9F] mt-3">

          {{"Showing.NoAvailable"  |translate}}  <br />
          {{"Showing.most-Viewed-lessons-Yet"  |translate}}
          <span class="vector-bg"
            >

          </span>
        </p>
      </div>

    </div>

  </div>
</div>
