import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IGetMyCompletedLessonDetail, ITteacherCompletedLessonStudentList } from 'src/app/shared/Model/teacher/subject-group';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-get-my-completed-lesson-detail',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,CustomPipeForImagesPipe
  ],
  templateUrl: './get-my-completed-lesson-detail.component.html',
  styleUrls: ['./get-my-completed-lesson-detail.component.scss']
})
export class GetMyCompletedLessonDetailComponent   implements OnInit  {
  protected MyCompletedLessonDetails!: IGetMyCompletedLessonDetail;
  constructor(
      private _activatedRoute: ActivatedRoute,
      private seoService: SeoService,
      private layoutService: LayoutService,private _router: Router,
    protected _CompletedLessonDetail: GroupForLessonService,

  )
  {}

  ngOnInit(): void {
    // this.seo();
    this.getQueryParam();
  }

  private getQueryParam() {
    this._activatedRoute.params.subscribe((params) => {
      // Do something with the params
      const id = JSON.parse(params['id']);
      if (id)
        this.GetMyCompletedLessonDetail(id);
    });
  }

  private GetMyCompletedLessonDetail(id :number) {
    this._CompletedLessonDetail
      .GetMyCompletedLessonDetail(id)
      .subscribe((res) => {
        this.MyCompletedLessonDetails = res;

          this.seoService.setTitle( this.MyCompletedLessonDetails?.lessonName  + this.MyCompletedLessonDetails?.subjectName??"");
          this.seoService.setHostUrlIndex();
          this.seoService.setMetaDescription(this.MyCompletedLessonDetails?.subjectBrief??"")
          this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.MyCompletedLessonDetails?.subjectBrief??"" ))


      });
  }

  navigate(teacherLessonId: number) {
    this._router.navigate([`/main/ManageMySubject/Lessons/Matrials/${teacherLessonId}`]);
  }

// Example in a component method
navigateWithQueryParams(TteacherCompletedLessonStudentList:ITteacherCompletedLessonStudentList): void {
  const queryParams = { bookTeacherLessonSessionDetailId:TteacherCompletedLessonStudentList.bookSessionDetailId,
    studentId:TteacherCompletedLessonStudentList. studentId};

  this._router.navigate(['/main/teacher/Chat'], { queryParams });
}

  //  seo ssr
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.CompletedLessonDetail.header.Add);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}

