import { Routes } from '@angular/router';
import { CalenderComponent } from '../../components/calender/calender.component';
import { SignUpComponent } from 'src/app/Authentication/Teacher/sign-up/sign-up.component';
import { LoginComponent } from 'src/app/Authentication/Login/login/login.component';
import { HomeComponent } from 'src/app/Component/home/home.component';
import { ForgetPasswordComponent } from 'src/app/Authentication/Forget/forget-password/forget-password.component';
import { ChangePasswordComponent } from 'src/app/Authentication/change-password/change-password.component';
import { ManageMyDocumentsComponent } from 'src/app/Component/Teacher/manage-my-documents/manage-my-documents.component';
import { EditProfileComponent } from 'src/app/Component/Teacher/Profile/edit-profile/edit-profile.component';
import { StudentEditProfileComponent } from 'src/app/Component/Student/Profile/student-edit-profile/student-edit-profile.component';
import { ChatComponent } from 'src/app/Component/Chat/chat.component';
import { isNotLoginGuard } from '../../guard/is-not-login.guard';
import { isLoginGuard  } from '../../guard/is-login.guard';
import { ManageMySubjectsComponent } from 'src/app/Component/Teacher/manage-my-subjects/manage-my-subjects.component';
import { ManageMySubjectLessonsComponent } from 'src/app/Component/Teacher/manage-my-subject-lessons/manage-my-subject-lessons.component';
import { ManageLessonsMatrialsComponent } from 'src/app/Component/Teacher/Matrials/manage-lessons-matrials/manage-lessons-matrials.component';
import { StudentSignUpComponent } from 'src/app/Authentication/Student/student-sign-up/student-sign-up.component';
import { ManageMyScheduleComponent } from 'src/app/Component/Teacher/manage-my-schedule/manage-my-schedule.component';
import { Role } from '../../Enums/role';
import { SubjectGroupComponent } from 'src/app/Component/Teacher/subject-group/subject-group.component';
 import { TicketDetailsComponent } from 'src/app/Component/Tickets/ticket-details/ticket-details.component';
import { GroupForLessonComponent } from 'src/app/Component/Teacher/subject-group/Manage/group-for-lesson/group-for-lesson.component';
import { CompletedlessonsComponent } from 'src/app/Component/Teacher/Completed/completedlessons/completedlessons.component';
import { GetMyCompletedLessonDetailComponent } from 'src/app/Component/Teacher/Completed/get-my-completed-lesson-detail/get-my-completed-lesson-detail.component';
 import { ParentSignUpComponent } from 'src/app/Authentication/Parent/parent-sign-up/parent-sign-up.component';
import { ParentStudentsComponent } from 'src/app/Component/Parent/ParentStudents/parent-students/parent-students.component';
import { AddParentStudentComponent } from 'src/app/Component/Parent/add-parent-student/add-parent-student.component';
import { ConnectParentStudentComponent } from 'src/app/Component/Parent/connect-parent-student/connect-parent-student.component';
import { StudentSubjectsComponent } from 'src/app/Component/Student/student-subjects/student-subjects.component';
import { SubjectDetailsComponent } from 'src/app/Component/Student/subject-details/subject-details.component';
import { TeacherChatComponent } from 'src/app/Component/Teacher/Chats/teacher-chat/teacher-chat.component';
import { TeacherCalenderSchedualComponent } from 'src/app/Component/Teacher/teacher-calender-schedual/teacher-calender-schedual.component';
import { TestCalenderComponent } from 'src/app/Component/Teacher/teacher-calender-schedual/test-calender/test-calender.component';
import { BookingComponent } from 'src/app/Component/booking/booking.component';
import { TeacherForSubjectsComponent } from 'src/app/Component/Teacher/TeacherForSubjects/teacher-for-subjects/teacher-for-subjects.component';
import { TeacherSubjectInfoComponent } from 'src/app/Component/Teacher/TeacherSubjectInfo/teacher-subject-info/teacher-subject-info.component';
import { BookingcalendarComponent } from 'src/app/Component/Teacher/Booking-calendar/Booking-calendar.component';
import { TeacherLessonGroupDetailComponent } from 'src/app/Component/Teacher/TeacherSubjectInfo/teacher-lesson-group-detail/teacher-lesson-group-detail.component';
import { OurMostPopularLessonsComponent } from '../../components/our-most-popular-lessons/our-most-popular-lessons.component';
import { ParentProfileComponent } from 'src/app/Component/Parent/Profile/parent-profile/parent-profile.component';
import { ShowingResultsForHomeComponent } from 'src/app/Component/home/showing-results-for-home/showing-results-for-home.component';
import { TeacherProfileComponent } from 'src/app/Component/Teacher/Profile/teacher-profile/teacher-profile.component';
import { StudentCompletedLessonComponent } from 'src/app/Component/Student/Completed/student-completed-lesson/student-completed-lesson.component';
import { TeacherRatesReviewsComponent } from 'src/app/Component/Teacher/teacher-rates-reviews/teacher-rates-reviews.component';
import { StudentMatrialComponent } from 'src/app/Component/Student/Completed/StudentMatrial/student-matrial/student-matrial.component';
import { isLoginParentStudentGuard } from '../../guard/is-login-parent-student.guard';
import { ConfirmPaymentComponent } from 'src/app/Component/booking/confirm-payment/confirm-payment.component';
import { StudentCalenderScheduleComponent } from 'src/app/Component/Student/student-calender-schedule/student-calender-schedule.component';
import { StudentFinanceComponent } from 'src/app/Component/Student/StudentFinance/StudentFinance.component';
import { pageForParentsOnlyGuard } from '../../guard/page-for-parents-only.guard';
import { VefiryUserComponent } from 'src/app/Authentication/Teacher/sign-up/vefiry-user/vefiry-user.component';
import { EditableTableComponent } from '../editable-table/editable-table.component';
import { scrollGuard } from '../../guard/scroll.guard';
import { FinanceComponent } from 'src/app/Authentication/Parent/parent-sign-up/Finance/Finance.component';
import { TeacherFinanceComponent } from 'src/app/Component/Finance/Finance.component';
import { TeacherCalenderSchedualListComponent } from 'src/app/Component/Teacher/teacher-calender-schedual/teacher-calender-schedual-list/teacher-calender-schedual-list.component';
import { StudentCalenderScheduleListComponent } from 'src/app/Component/Student/student-calender-schedule/student-calender-schedule-list/student-calender-schedule-list.component';
export const content: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  // {
  //   path: 'EditableTable',
  //   canActivate: [isLoginGuard ,scrollGuard],

  //   data: {
  //     role: Role.Anonymous,
  //   },
  //   component: EditableTableComponent,
  // },
  {
    path: 'Teacher/ReCalendar',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher,
    },
    component: TeacherCalenderSchedualComponent,
  },
  {
    path: 'Teacher/Homepage',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher,
    },
    component: TeacherCalenderSchedualListComponent,
  },
  {
    path: 'OurMostPopularLessons',
        canActivate: [ scrollGuard],

    data: {
      role: Role.All,
    },
    component: OurMostPopularLessonsComponent,
  },
  {
    path: 'Bookingcalendar',
        canActivate: [ scrollGuard],

    data: {
      role: Role.All,
    },
    component: BookingcalendarComponent,
  },
  {
    path: 'home',
        canActivate: [ scrollGuard],

    data: {
      role: Role.All,
    },
    component: HomeComponent,
  },
  {
    path: 'ShowingResults',
        canActivate: [ scrollGuard],

    data: {
      role: Role.All,
    },
    component: ShowingResultsForHomeComponent,
  },
  {
    path: 'Booking/:teacherLessonSessionId',
    canActivate: [isLoginParentStudentGuard,scrollGuard],
    data: {
      role: Role.All,
    },
    component: BookingComponent,
  },
  {
    path: 'Confirm',
    canActivate: [isLoginParentStudentGuard,scrollGuard],
    data: {
      role: Role.All,
    },
    component: ConfirmPaymentComponent,
  },
  {
    path: 'ManageMyDocuments',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher,
    },
    component: ManageMyDocumentsComponent,
  },
  {
    path: 'ManageMySubjects',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: ManageMySubjectsComponent,
  },
  {
    path: 'ManageMySubjectLessons/:id/:subjectAcademicYearId',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: ManageMySubjectLessonsComponent,
  },
  {
    path: 'ManageMySubject/Lessons/Matrials/:id',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: ManageLessonsMatrialsComponent,
  },

  {
    path: 'ManageMySchedule',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: ManageMyScheduleComponent,
  },
  {
    path: 'SubjectGroup',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: SubjectGroupComponent,
  },
  {
    path: 'GroupForLesson',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: GroupForLessonComponent,
  },
  {
    path: 'Completedlessons',
   canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: CompletedlessonsComponent,
  },

  {
    path: 'CompletedLessonDetail/:id',
    canActivate: [isLoginGuard ,scrollGuard],

    data: {
      role: Role.Teacher,
    },
    component: GetMyCompletedLessonDetailComponent,
  },
  {
    path: 'StudentCompletedlessons',
    canActivate: [isLoginParentStudentGuard,scrollGuard],

    data: {
      role: Role.Student,
    },
    component: StudentCompletedLessonComponent,
  },
  {
    path: 'StudentMatrial/:id',
    canActivate: [isLoginParentStudentGuard,scrollGuard],

    data: {
      role: Role.Student,
    },
    component: StudentMatrialComponent,
  },

  // {
  //   path: 'Teacher/Ticket',
  //   canActivate: [isLoginGuard ,scrollGuard],
  //   data: {
  //     role: Role.Teacher, // Add data if needed
  //   },
  //   component: TicketsForTeacherComponent, // Reference to your TicketsForTeacherComponent
  // },
  {
    path: 'Teacher/Calender',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher, // Add data if needed
    },
    component: TeacherCalenderSchedualComponent, // Reference to your TicketsForTeacherComponent
  },
  {
    path: 'teacher/Chat',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher, // Add data if needed
    },
    component: TeacherChatComponent, // Reference to your TicketsForTeacherComponent
  },
  {
    path: 'Finance',
    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: Role.Teacher, // Add data if needed
    },
    component: TeacherFinanceComponent, // Reference to your TicketsForTeacherComponent
  },
  {
    path: 'Teacher/Profile/:id',
    canActivate: [ scrollGuard],

    // data: {
    //   role: Role.Teacher, // Add data if needed
    // },
    component: TeacherProfileComponent, // Reference to your TicketsForTeacherComponent
  },

  {
    path: 'Teacher/RatesReviews',
   canActivate: [isLoginGuard ,scrollGuard],
    // data: {
    //   role: Role.Teacher, // Add data if needed
    // },
    component: TeacherRatesReviewsComponent, // Reference to your TicketsForTeacherComponent
  },

  {
    path: 'EditProfile',

    canActivate: [isLoginGuard ,scrollGuard],
    data: {
      role: [Role.Teacher],
    },
    component: EditProfileComponent, // Reference to your EditProfileComponent
  },
  {
    path: 'StudentEditProfile',
    canActivate: [isLoginGuard ,scrollGuard], // Add your guard here if needed
    data: {
      role: Role.Student, // Add data if needed
    },
    component: StudentEditProfileComponent, // Reference to your StudentEditProfileComponent
  },
  {
    path: 'student/Chat/:id/:bookTeacherLessonSessionDetailId',
    canActivate: [isLoginParentStudentGuard,scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: ChatComponent,
  },
  {
    path: 'student/Chat',
    canActivate: [isLoginParentStudentGuard,scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: ChatComponent,
  },
  {
    path: 'Student/ReCalendar',
    canActivate: [isLoginParentStudentGuard,scrollGuard], // Add your guard here if needed

    data: {
      role: Role.Student,
    },
    component: StudentCalenderScheduleComponent,
  },
  {
    path: 'Student/ScheduleList',
    canActivate: [isLoginParentStudentGuard,scrollGuard], // Add your guard here if needed

    data: {
      role: Role.Student,
    },
    component: StudentCalenderScheduleListComponent,
  },
  // {
  //   path: 'student/Tickets',
  //   canActivate: [isLoginParentStudentGuard,scrollGuard], // Add your guard here if needed
  //   data: {
  //     role: Role.Student, // Add data if needed
  //   },
  //   component: TicketsComponent,
  // },
  {
    path: 'student/Ticket-Details',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: TicketDetailsComponent,
  },
  {
    path: 'student/Finance',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: StudentFinanceComponent,
  },
  // {
  //   path: 'parent/Chat',
  //   canActivate: [isLoginParentStudentGuard,scrollGuard],
  //   data: {
  //     role: Role.Parent, // Add data if needed
  //   },
  //   component: ChatComponent,
  // },
  // {
  //   path: 'parent/Tickets',
  //   canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
  //   data: {
  //     role: Role.Parent, // Add data if needed
  //   },
  //   component: TicketsComponent,
  // },
  {
    path: 'parent/Ticket-Details',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Parent, // Add data if needed
    },
    component: TicketDetailsComponent,
  },
  {
    path: 'parent/Finance',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Parent, // Add data if needed
    },
    component: FinanceComponent,
  },
  {
    path: 'parent/ParentStudents',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Parent, // Add data if needed
    },
    component: ParentStudentsComponent,

  },
  {
    path: 'parent/AddStudent',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Parent, // Add data if needed
    },
    component: AddParentStudentComponent,
  },
  {
    path: 'parent/ProfileStudent/:id',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed

    data: {
      role: Role.Parent, // Add data if needed
    },
    component: AddParentStudentComponent,
  },
  {
    path: 'parent/ProfileStudent',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed

    data: {
      role: Role.Parent, // Add data if needed
    },
    component: AddParentStudentComponent,
  },
  {
    path: 'parent/ConnectStudent',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed

    data: {
      role: Role.Parent, // Add data if needed
    },
    component: ConnectParentStudentComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },

  {
    path: 'admin',
    canActivate: [isLoginGuard ,scrollGuard], // Add your guard here if needed
    data: {
      role: Role.Teacher, // Add data if needed
    },
    component: CalenderComponent,
  },

  //ChangePassword for parent ,teacher ,student
  {
    path: 'parent/ChangePassword',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Parent, // Add data if needed
    },
    component: ChangePasswordComponent,
  },
  {
    path: 'teacher/ChangePassword',
    canActivate: [isLoginGuard ,scrollGuard], // Add your guard here if needed
    data: {
      role: Role.Teacher, // Add data if needed
    },
    component: ChangePasswordComponent,
  },
  {
    path: 'student/ChangePassword',
    canActivate: [isLoginParentStudentGuard,scrollGuard], // Add your guard here if needed
    data: {
      role: Role.Student, // Add data if needed
    },
    component: ChangePasswordComponent,
  },
  {
    path: 'teacher/EditProfile',
    canActivate: [isLoginGuard ,scrollGuard], // Add your guard here if needed
    component: EditProfileComponent, // Reference to your EditProfileComponent
  },
  {
    path: 'student/EditProfile',
    canActivate: [isLoginParentStudentGuard,scrollGuard], // Add your guard here if needed
    component: StudentEditProfileComponent,
  },
  {
    path: 'parent/EditProfile',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    component: ParentProfileComponent,
  },
  {
    path: 'student/StudentSubjects',
    canActivate: [isLoginParentStudentGuard,scrollGuard],
    data: {
      role: Role.Student, // Add data if needed
    },
    component: StudentSubjectsComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },

  {
    path: 'parent/StudentSubjects/:id',
    canActivate: [pageForParentsOnlyGuard,isLoginParentStudentGuard], // Add your guard here if needed
    data: {
      role: Role.Student, // Add data if needed
    },
    component: StudentSubjectsComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },
  {
    path: 'student/SubjectDetails/:id',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: SubjectDetailsComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },
  {
    path: 'student/TeacherForSubjects/:type/:id',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: TeacherForSubjectsComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },

  {
    path: 'student/TeacherSubjectInfo/:id',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: TeacherSubjectInfoComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },
  {
    path: 'student/TeacherLessonGroupDetailComponent/:id',
        canActivate: [ scrollGuard],

    data: {
      role: Role.Student, // Add data if needed
    },
    component: TeacherLessonGroupDetailComponent,
    //canActivate:[isLoginGuard ,scrollGuard]
  },
];

export const contentAuth: Routes = [
  { path: '', redirectTo: 'teacher/signup', pathMatch: 'full' },
  {
    path: 'teacher/signup',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Teacher,
    },
    component: SignUpComponent,
  },
  {
    path: 'teacher/signup/:mobile',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Teacher,
    },
    component: SignUpComponent,
  },
  {
    path: 'teacher/signup/otp/:otp',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Teacher,
    },
    component: VefiryUserComponent,
  },
  {
    path: 'student/signup',
    canActivate: [isNotLoginGuard],

    data: {
      role: Role.Student,
    },
    component: StudentSignUpComponent,
  },
  {
    path: 'parent/signup',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Parent,
    },
    component: ParentSignUpComponent,
  },

  {
    path: 'teacher/login',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Teacher,
    },

    component: LoginComponent,
  },
  {
    path: 'parent/login',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Parent,
    },
    component: LoginComponent,
  },
  {
    path: 'student/login',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Parent,
    },
    component: LoginComponent,
  },
  //  ForgetPassword for parent ,teacher ,student
  {
    path: 'parent/ForgetPassword',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Parent,
    },
    component: ForgetPasswordComponent,
  },
  {
    path: 'teacher/ForgetPassword',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Teacher,
    },
    component: ForgetPasswordComponent,
  },
  {
    path: 'student/ForgetPassword',
    canActivate: [isNotLoginGuard],
    data: {
      role: Role.Student,
    },
    component: ForgetPasswordComponent,
  },
];
