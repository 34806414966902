import { Injectable } from '@angular/core';
import { environment, changeEnv } from 'src/environments/environment';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private layoutService: LayoutService) {
    this.updateEnvironmentLanguage();
  }

  updateEnvironmentLanguage(): void {
    const language = this.layoutService.config.langu;
    console.log(language)
    changeEnv(language);
  }
}
