import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { IOtp, IVefiryUserPost } from 'src/app/shared/Model/teacher/teacher';
import { VerificationService } from 'src/app/shared/Apis/Verification/verification.service';
import { NgOtpInputConfig, NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-verify-otpstudent-connect',
  standalone: true,
  imports: [CommonModule, NgOtpInputModule, TranslateModule],
  templateUrl: './verify-otpstudent-connect.component.html',
  styleUrls: ['./verify-otpstudent-connect.component.scss'],
})
export class VerifyOTPStudentConnectComponent {
  @ViewChild('ngOtpInputRef') ngOtpInput: any;

  config: NgOtpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    isPasswordInput: false,
    placeholder: '',
  };
  public subscription = new Subscription();
  @Input() DataVefiryOtp!: IOtp;
  @Input() phoneNumber!: number;
  @Input() endPoint: boolean = false;
  @Output() StepVerifyOTP: EventEmitter<[boolean, number]> = new EventEmitter< [boolean, number]>();

  private interval: any;
  protected showOtp: boolean = false;
  protected showSendOtp: boolean = false;
  protected DoneVefiryOtp: boolean = true;
  protected otp!: any;
  protected isInvalid: boolean = false;
  protected Controller!: string;
  protected submit: boolean = true;
  protected isOtpInvalid: boolean = false;
  otpValues: string[] = [];

  protected DoneVefiryUser: boolean = true;
  constructor(
    private router: Router,
    protected _Verification: VerificationService,    private seoService: SeoService,
    private layoutService: LayoutService,
    private _SSRService: SSRService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.Controller = this._SSRService.Location(2);
  }

  ngOnInit(): void {
    //When entering the screen for the first time, the time is calculated based on the time entered by Sign-Up
    this.setInterval();
    this.seo();
  }
  ngAfterViewInit(): void {
    // تهيئة قيمة OTP بعد أن يصبح ngOtpInput جاهزًا
    // if (this.ngOtpInput && this.DataVefiryOtp.otp) {
    //   this.ngOtpInput.setValue(this.DataVefiryOtp.otp);
    // }
  }
  //When there is a change in input Otp
  protected onOtpChange(otp: any) {
    this.otp = otp;
  }
  // Send verification of mobile number and code to register
  SendVerify() {
    this.submit = false;

    if (this.otp && this.otp?.length == 6) {
      const VerifyOTP: IVefiryUserPost = {
        Mobile: this.phoneNumber,
        Otp: this.otp,
      };
      if (!this.endPoint)
        this.subscription.add(
          this._Verification.VerifyOTP('Student', VerifyOTP).subscribe(
            (response) => {
              if (response.success) {
                this.DoneVefiryOtp = false;
                this.isInvalid = true;
              } else {
                this.otpValues = [];
                this.isInvalid = false;
                this.submit = true;
                this.isOtpInvalid = true;
              }
              this.ngOtpInput?.setValue('');
              this.submit = true;
            },
            (err) => {
              this.ngOtpInput?.setValue('');
              this.submit = true;
              this.isOtpInvalid = true;
            }
          )
        );
      // Send VefiryUser verification of mobile number and code to register
      else
        this.subscription.add(
          this._Verification.VefiryUser('Student', VerifyOTP).subscribe(
            (response) => {
              if (response.success) {
                this.DoneVefiryOtp = false;
                this.isInvalid = true;
              } else {
                this.otpValues = [];
                this.isInvalid = false;
                this.submit = true;
                this.isOtpInvalid = true;
              }
              this.ngOtpInput?.setValue('');
              this.submit = true;
            },
            (err) => {
              this.ngOtpInput?.setValue('');
              this.submit = true;
              this.isOtpInvalid = true;
            }
          )
        );
      this.submit = true;
      return;
    } else {
      this.submit = true;
      Swal.fire({
        icon: 'warning',
        title: 'warning',
        text: 'The verification number must be 6 digits',
      });
    }
  }
  navigate(isInvalid: boolean) {
    this.StepVerifyOTP.emit([isInvalid, this.otp]);
    if (isInvalid && isPlatformBrowser(this.platformId)) {
      localStorage.setItem('addchildren', 'false');
      this.router.navigate([`/main/parent/ParentStudents`]);
    }
  }
  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {
    this.subscription.add(
      this._Verification
        .SendOTP('Student', this.phoneNumber)
        .subscribe((res) => {
          this.showSendOtp = false;
          if (res.data['otp']) {
            this.showOtp = true;
            // this.InitFormVerify(res)
            this.DataVefiryOtp = res.data;
            // if (this.ngOtpInput && this.DataVefiryOtp.otp) {
            //   this.ngOtpInput.setValue(this.DataVefiryOtp.otp);
            // }
            this.setInterval();
          }
        })
    );
  }

  //To calculate the Code time from 90 Seconds to 0 Tansley
  private setInterval() {
    this.interval = setInterval(() => {
      if (this.DataVefiryOtp.secondsCount > 0) {
        this.DataVefiryOtp.secondsCount--;
      } else {
        this.showSendOtp = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private seo(){
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.VerifyOtpStudent.header.Text);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
