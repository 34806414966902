import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
import { IOtp, IVefiryUserPost, IVefiryUserResponse } from '../../Model/Student/student';
import { AuthService } from '../AuthService/auth.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class StudentService {
  constructor(private http: HttpClient, protected auth: AuthService, private router:Router) {}
AddStudent(data:any):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/Register`,data)
  }
  AddStudentRate(data:any):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}TeacherLesson/StudentAddRate`,data)
  }
  GetStudentSubjects(id?:any){
    if(id){
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Student/GetStudentSubjects?id=${id}`)
    }
    else{
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Student/GetStudentSubjects`)
    }
  }

  GetBookedStudentLessons(SubjectId:any,id?:any){
    if(  this.auth.getRoleId() == '3')
      {
      if( !this.auth.getCurrentChildrenId())
        {
          this.router.navigate([`/main/parent/ParentStudents`]);
       return of([]);
        }
      }
    return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Student/GetBookedStudentLessons?StudentId=${this.auth.getCurrentChildrenId()??""}&SubjectId=${SubjectId}`).pipe( map((res) =>{
      return  res.data
    } ));
  }


  GetStudentLessonMaterials(id?:any){
    if(id){
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}StudentCompletedLesson/GetStudentLessonMaterials?teacherlessonid=${id}`)
    }
    else{
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}StudentCompletedLesson/GetStudentLessonMaterials`)
    }
  }
  GetBookedStudentSubjects(id?:any){
    if(  this.auth.getRoleId() == '3')
      {
      if( !this.auth.getCurrentChildrenId())
        this.router.navigate([`/main/parent/ParentStudents`]);

      }
      // return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Student/GetBookedStudentSubjects`)
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Student/GetBookedStudentSubjects?StudentId=${this.auth.getCurrentChildrenId()??""}`)

  }
  GetStudentProfile(id:any){
    return this.http.get<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/GetStudentProfile?id=${id}`)
  }
  StudentCompletedLesson(data:any):Observable<IGenericResponseObject<any>>{


    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}StudentCompletedLesson/GetStudentCompletedLessons`,data)
  }

  DeleteStudent(id?:any){
    if(id){
      return this.http.get<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/Delete?id=${id}`)
    }
    else{
      return this.http.get<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/Delete`)
    }
  }
CreateByParent(data:any):Observable<IGenericResponseObject<IOtp>>{
  return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/CreateByParent`,data)
}
  VefiryUser(data:IVefiryUserPost):Observable<IGenericResponseObject<IVefiryUserResponse>>{
    return this.http.post<IGenericResponseObject<IVefiryUserResponse>>(`${env.Server_URL}Student/VefiryUser`,data)
  }
  SendOTP(mobile:number):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Student/SendOTP`,{mobile:mobile})
  }

}
