<div
class="sm:px-5 sm:p-2 pt-10 md:px-3 md:ps-12 pt-12 text-center">
<app-nav-tabs class="w-full mb-4"></app-nav-tabs>

  <div class="sm:w-full ml-6 px-20 md:w-10/12 pt-12 mb-5 ProfilePadding max-md:px-1 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700" *ngIf="DoneVefiryUser">

    <div class="text-center">
      <p class="text-black text-center text-[16px] Lama-Regular">
        <a
          href="https://www.youtube.com/watch?v=op5L2L0ROvg"
          target="_blank"
          class="text-black"
        >
                {{ "TeacherSignUp.header.Explanation" | translate }} *
        </a>
      </p>
    </div>
    <img src="assets/images/MrS-Cool/VefiryUser.png"  class="w-1/6 m-auto py-3">
    <h3 class="text-[18px]  lg:text-[30px] Lama-bold dark:text-white">
      {{ "VerifyTeacher.header.Text" |translate}}
    </h3>
    <ng-otp-input
    dir="ltr"
    class="text-[var(--theme-deafult-TeacherWithClick)]"
    (keyup.enter)="SendVerify()"
       #ngOtpInputRef
       (onInputChange)="onOtpChange($event)"
    [class.invalid-border]="isOtpInvalid"
    [config]="config"></ng-otp-input>
    <br>
    <!-- <pre>{{DataVefiryUser.otp}}</pre> -->

    <div class=" m-3">

      <!-- <button type="submit"
      (click)="SendVerify()"
      (keyup.enter)="SendVerify()"
      class="w-full text-white bg-gray-200 focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black">{{ "Verify" | translate }}</button> -->

      <button

      type="submit"
      [disabled]="!submit"
      (click)="SendVerify()"
      (keyup.enter)="SendVerify()"
      class="w-full text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]  hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 h-14 rounded  mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
    >
      {{ "VerifyTeacher.form.Verify" | translate }}
    </button>
    </div>
    <div class="text-center mt-4 mb-4" >

      <p class="  text-Regular text-[11px] md:text-[14px] text-[#1b2651] dark:text-gray-950" *ngIf="!showSendOtp">
        {{"VerifyTeacher.form.OTP-Not-Received" | translate }} <a    class="text-Regular text-[11px] md:text-[14px] text-[#FF0000] hover:underline dark:text-primary-500"> {{ DataVefiryUser.secondsCount + " " }}{{ " " }} {{ "VerifyTeacher.form.secondly" | translate }}</a>
    </p>
      <p class="  text-Regular text-[11px] md:text-[14px] text-[#1b2651] dark:text-gray-950" *ngIf="showSendOtp">
        {{"VerifyTeacher.form.OTP-Not-Received" | translate }} <span  (click)="SendOTP()" class="text-Regular text-[11px] md:text-[14px]text-primary-600 hover:underline dark:text-[#FF0000]" style="color: red !important"> {{ "VerifyTeacher.form.Resend-Code" | translate }}</span>
    </p>
    <p class="  text-Regular text-[11px] md:text-[14px] text-[#1b2651]   dark:text-gray-950" (click)="navigate(false)" >
      {{"VerifyTeacher.form.Mobile-number-not-correct" | translate }} <a (click)="navigate(false)"   class="text-Regular text-[11px] md:text-[14px]text-[#FF0000] hover:underline dark:text-[#FF0000]">  {{ "VerifyTeacher.form.Modify-Now" | translate }}</a>
  </p>
    </div>
  </div>

  <div class="m-auto sm:w-full md:w-10/12 pb-3 mt-12 px-20   bg-white rounded-lg shadow dark:border  dark:bg-[#1a202c] dark:border-gray-700 " *ngIf="!DoneVefiryUser">
    <div class="text-center">
      <p class="text-black text-center text-[16px] Lama-Regular">
        <a
          href="https://www.youtube.com/watch?v=op5L2L0ROvg"
          target="_blank"
          class="text-black"
        >
                {{ "TeacherSignUp.header.Explanation" | translate }} *
        </a>
      </p>
    </div>
    <p class=" mt-3 diagonal-fractions ... text-right text-[14px] Lama-Regular">

    </p>
    <img src="assets/images/MrS-Cool/VefiryUserSucc.png"  class="w-1/6 m-auto py-3">
    <h3 class="text-[18px]  lg:text-[30px] Lama-bold dark:text-white text-[var(--theme-deafult-TeacherWithClick)]">{{"Mobile Number Verified"}}</h3>


    <p class="text-[14px] Lama-Regular text-[var(--theme-deafult-TeacherWithClick)]">
{{"VerifyTeacher.form.Your-account-had-beed-created-successfully" | translate }}
    </p>
    <p class="text-[14px] Lama-Regular text-[var(--theme-deafult-TeacherWithClick)] mb-5">
      {{ "VerifyTeacher.form.Please-sign-in-to-use-your-account-and-enjoy" | translate }}
    </p>
    <div class=" my-3 mx-5">
      <!-- <button type="submit" class="w-full   text-white bg-gray-200 focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
      (click)="navigate(true)"
      (keyup.enter)="navigate(true)">
        {{ "Next" | translate }}
      </button> -->

      <button
      type="submit"
      class="w-full text-white bg-[var(--theme-deafult-TeacherWithClick)]   hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] text-Regular text-[11px] md:text-[14px]rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      (click)="navigate(true)"
      (keyup.enter)="navigate(true)"
    >
    {{ "VerifyTeacher.form.Next" | translate }}

    </button>
    </div>

  </div>
</div>
