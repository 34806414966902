import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IOtp, IVefiryUserPost, IVefiryUserResponse } from '../../Model/teacher/teacher';
import { IGenericResponseObject } from '../../Model/Generic/generic-response';


@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  constructor(private http: HttpClient) {}


  VefiryUser(controller:string ,data:IVefiryUserPost):Observable<IGenericResponseObject<IVefiryUserResponse>>{
    return this.http.post<IGenericResponseObject<IVefiryUserResponse>>(`${env.Server_URL}${controller}/VefiryUser`,data)
  }

  VerifyOTP(controller:string ,data:IVefiryUserPost):Observable<IGenericResponseObject<IVefiryUserResponse>>{
    return this.http.post<IGenericResponseObject<IVefiryUserResponse>>(`${env.Server_URL}${controller}/VerifyOTP`,data)
  }

  SendOTP(controller:string ,mobile:number):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}${controller}/SendOTP`,{mobile:mobile})
  }



}
