import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import {
  IGenericResponse,
} from 'src/app/shared/Model/Generic/generic-response';

@Injectable({
  providedIn: 'root',
})
@Injectable({
  providedIn: 'root',
})
export class MostViewedLessonsService {
  constructor(private http: HttpClient) {}

  public GetMostViewedLessonsOrMostBookedLessons(
    type: string,
    academicleveid?: number
  ): Observable<any> {

    let url = `${env.Server_URL}Lesson/${type}`;

    // Use HttpParams to build query parameters
    let params = new HttpParams();
    if (academicleveid != null && academicleveid > 0) {
      params = params.set('academicleveid', academicleveid.toString());
    }

    return this.http
      .get<IGenericResponse<any>>(url, { params })
      .pipe(map((res) => res.data));
  }

  public GetTopBookedSubjectsOrTopViewedSubjects(
    type: string,
    academicleveid?: number
  ): Observable<any> {

    let url = `${env.Server_URL}SubjectSemesterYear/${type}`;
    // Use HttpParams to build query parameters
    let params = new HttpParams();
    if (academicleveid != null && academicleveid > 0) {
      params = params.set('academicLevelId', academicleveid.toString());
    }
    return this.http
      .get<IGenericResponse<any>>(url, { params })
      .pipe(map((res) => res.data));
  }

  public GetatedTeacherrTopViewedSubjects(
    type: string,
    academicleveid?: number
  ): Observable<any> {

    let url = `${env.Server_URL}Teacher/${type}`;
    // Use HttpParams to build query parameters
    let params = new HttpParams();
    if (academicleveid != null && academicleveid > 0) {
      params = params.set('academicLevelId', academicleveid.toString());
    }
    return this.http
      .get<IGenericResponse<any>>(url, { params })
      .pipe(map((res) => res.data));
  }

  public GetAllSubjects(type: any): Observable<any> {

    let url = `${env.Server_URL}SubjectOrLessonTeachers/GetAllSubjects`;
    return this.http
      .post<IGenericResponse<any>>(url, type)
      .pipe(map((res) => res.data));
  }
}
