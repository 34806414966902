<!-- <p>student-edit-profile works!</p>
<div [dir]="'ltr'" class="flex _ flex-wrap justify-center align-items-center">
    <div class="w-full pt-5 ">
      <div class="card">
        <div class="card-header bg-white">
            <h1>Message</h1>

        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-4">
                    list
                                    </div>
                <div class="col-8">
chats
                </div>


            </div>
        </div>
      </div>
    </div>
  </div>
   -->
<div class="container h-100">
    <div class="border-0 card chat-card h-100 p-4 shadow">
        <div class="card-header bg-white">
            <div class="row">
                <div class="col text-start p-0  ">
                    <h3 class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)] ">{{'TicketDetails.header.Title' |translate}} </h3>
                </div>
            </div>
        </div>
        <div class="card-body chat-container h-100">
            <div class="row h-100">
                <div class="col-4 h-100">
                    <div class=" h-100 card border-0 ">
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Status</h5>
                            <!-- <label class="col-12  text-start">

                                    <span  class="inline-block">Assigned</span>
                                    <div class="inline-block" id="rectangle"></div>

                            </label> -->
                            <label class="col-12 text-start" style="display: flex; align-items: center;">
                                <span style="margin-right: 2%;">Assigned</span>
                                <div class="rectangle" ></div>
                            </label>

                        </div>
                        <div class="row  mb-4">
                            <h5 class="col-12 text-start headerFont">ID</h5>
                            <label class="col-12 text-start">4161845154841</label>

                        </div>
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Created By</h5>
                            <label class="col-12 text-start">Belal Younis</label>

                        </div>
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Date</h5>
                            <label class="col-12 text-start">15 Jun 2023</label>

                        </div>
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Contact Info</h5>
                            <label class="col-12 text-start">01024152155</label>

                        </div>
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Department</h5>
                            <label class="col-12 text-start">Department 3</label>

                        </div>
                        <div class="row mb-4">
                            <h5 class="col-12 text-start headerFont">Descreption</h5>
                            <label class="col-12 text-start">How likely are you to recommend our company to your friends
                                and family How likely are you to recommend our company to your friends and familyHow
                                likely are you to recommend our company to your friends and family How likely are you to
                                recommend our company to your friends and family How likely are you to recommend our
                                company to your friends and family How likely are you to recommend our company to your
                                friends and family How likely are you to recommend our company to your friends
                            </label>

                        </div>


                    </div>
                </div>
                <div class="col-8 h-100">
                    <div class="chat h-100">
                        <div class="chat-messages card chatCard ">
                            <div class="card-header bg-white headerfooterBorder ">
                                <div>
                                    <div class="mr-2 timeText">
                                        <!-- <span>35 mins</span>
                                      </div>
                                      <div class="mr-2 senderText">
                                          <span>ff</span>
                                      </div> -->
                                        <div class="row">
                                            <div class="col-1">
                                                <img src="assets/images/MrS-Cool/ChatSender.png" class=""
                                                    style="border-radius: 50%;" />
                                            </div>
                                            <div class="col text-start p-0 mt-1 "><span
                                                    class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)]">Belal Younis</span>
                                                <br>
                                                <span>Lesson 1, Egyptian, Primary 1, Grade 1, English</span>
                                            </div>



                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="card-body overflow-auto scrollable-content " #chatContainer>
                                <ng-container *ngIf="messages">
                                    <div *ngFor="let message of messages"
                                        [ngClass]="{'my-message': message.from === 'me', 'contact-message': message.from !== 'me'}">
                                        <div class=" mt-3 ml-auto"
                                            [ngClass]="{'my-message': message.from === 'me', 'contact-message': message.from !== 'me'}">
                                            <!-- <div  [ngClass]="{'mymessageName': message.from === 'me', 'contantmessageName': message.from !== 'me'}">
                                        <div class="mr-2">
                                            <img
                                            src="assets/images/MrS-Cool/ChatSender.png"
                                            class=""
                                            style="border-radius: 50%;"
                                          />
                                        </div>

                                    </div> -->


                                        </div>
                                        <div class="mt-3"
                                            [ngClass]="{'myMessageDiv': message.from === 'me', 'contantMessageDiv': message.from !== 'me'}"
                                            [class.ml-auto]="message.from === 'me'"
                                            [class.mr-auto]="message.from !== 'me'">
                                            <span>
                                                {{ message.text }}
                                            </span>
                                            <br>
                                            <span class="createTxt mt-4">Created 23 Sep 2023 by Belal Younis</span>

                                        </div>
                                    </div>

                                </ng-container>
                                <div class="d-flex justify-center" *ngIf="!messages">
                                    <img src="assets/images/MrS-Cool/Vector.png" class="" />

                                </div>
                                <div class="d-flex justify-center noMessageTxt mt-3" *ngIf="!messages">

                                    <label>{{'TicketDetails.form.To-read-a-message-click-on-conversation' | translate}}.</label>
                                </div>
                            </div>
                            <div class="card-footer bg-white headerfooterBorder" style="    height: 115px;">
                                <div class="chat-input row">
                                    <div class="col-8">
                                        <input [(ngModel)]="newMessage" style="    height: 100px;"
                                            (keyup.enter)="sendMessage()" class="form-control border-0"
                                            placeholder="Type a message" />

                                    </div>
                                    <div class="col-4 d-flex flex-column justify-content-center">
                                        <button class="btn btn-primary btnSend" (click)="sendMessage()">{{'TicketDetails.form.Send-Message' |translate}}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
