import { Title, Meta } from '@angular/platform-browser';
// import { keywordsNames } from './../Models/project';
import { Injectable } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
 import { environment } from 'src/environments/environment';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from '../../core/layout.service/layout.service';
declare const addthis: any;

export interface keywordsNames {
  id: number;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private http: HttpClient,
    private _Title: Title,
    private _Meta: Meta,
    private location: Location,
    private layoutService:LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public setHostUrlIndex(event?: any): string {
    if (isPlatformBrowser(this.platformId)) {
      // This code will only run in the browser
      let host = window.location.href;

      this._Meta.updateTag({
        name: 'original-source',
        content: host,
      });
      this._Meta.updateTag({
        property: 'og:url',
        content: host,
      });
      if (typeof addthis !== 'undefined') {
        addthis.update('share', 'url', host);
        addthis.url = host;
        addthis.toolbox('.addthis_toolbox');
      }
      return host;
    }
    return '';
  }
  //  set Main address  for index
  public setTitle(title: string) {
    const lang = this.layoutService.config.langu;
    const oldTitle = lang === 'ar' ? 'مستر اس كول' : 'Mr. S Cool';
    console.log(title);
    this._Meta.updateTag({
      name: 'title',
      property: 'og:title',
      content: title + ' - ' + oldTitle,
    });
    this._Title.setTitle(title + ' - ' + oldTitle);
  }

  public setMetaImage(imge: string) {
    // const url = "https://platform.mrscool.app"+imge
    const url = "https://mrscoolweb.azurewebsites.net"+imge
    this._Meta.updateTag({
      property: 'og:image',
      content: url,
    });
    this._Meta.updateTag({
      property: 'og:image:secure_url',
      content: url,
    });
  }
  public setMetaDescription(description: string) {
console.log("description",description)
    this._Meta.updateTag({ property: 'og:description', content: description });
    this._Meta.updateTag({ name: 'description', content: description });
  }
  public setMetaKeywords(keywords: keywordsNames[]) {
    let metakeywords = '';
    if (!keywords || !Array.isArray(keywords)) {
      return; // Add a guard clause to handle undefined or non-array inputs
    }

    keywords.forEach((element) => {
      metakeywords += ',' + element.title;
    });
    this._Meta.updateTag({
      name: 'keywords',
      property: 'schema:keywords',
      content: metakeywords,
    });
  }

  setMetaTags(translations: any) {
    this._Meta.updateTag({ name: 'description', content: translations.meta_description });
    this._Meta.updateTag({ name: 'keywords', content: translations.meta_keywords });
    this._Meta.updateTag({ property: 'og:description', content: translations.meta_description });
    this._Meta.updateTag({ property: 'og:title', content: translations.home_title });
  }
  getPath() {
    return this.location.path();
  }
  // getHost() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const fullURL = window.location.href;
  //     console.log(decodeURIComponent(fullURL));
  //     let hostname = new URL(fullURL).host;
  //     return decodeURIComponent(hostname);
  //   }
  // }

  getPathDecodeURIComponent() {
    const path = this.location.path().split('/')[1];
    return decodeURIComponent(path);
  }
  private baseUrl = environment.langJson;  // مسار API على الخادم

  loadTranslations(lang: string): Observable<any> {
    console.log(lang)
    const url = `${this.baseUrl}/${lang}`;
    return this.http.get(url).pipe(
      map((response: any) => response)
    );
  }
  generateKeywords(text: string): keywordsNames[] {
    const keywords: keywordsNames[] = [];
    const words = text.split(/\s+/);

    for (let i = 0; i < words.length; i++) {
      const keyword: keywordsNames = {
        id: i + 1,
        title: words[i]
      };
      keywords.push(keyword);
    }

    return keywords;
  }

}
