import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule, isPlatformBrowser, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { NavTabsComponent } from '../../tabs/nav-tabs/nav-tabs.component';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    RouterLink,
    NavTabsComponent,
    TranslateModule,
    OnlyNumbersDirective,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected changetype: boolean = true;
  public subscription = new Subscription();
  protected Controller!: string;
  protected PahtImge: string = 'left_';
  protected submit: boolean = true;


  constructor(
    private _fb: FormBuilder,
    private _AuthService: AuthService,
    private router: Router,
    private _SSRService: SSRService,    private seoService: SeoService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object

  ) {
    if (isPlatformBrowser(this.platformId)) {

    this.PahtImge = 'left_' + localStorage.getItem('Controller') + '.svg' ?? '';
    }
    this.Controller = this._SSRService.Location(2);
  }
  ngOnInit(): void {
    this.initiate();
    this.router.navigate([this.router.url]);
    this.PahtImge = 'left_' + this.Controller + '.svg' ?? '';
    this.seo()

  }
  private initiate() {
    this.insertForm = this._fb.group({
      mobile: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(40),
        ],
      ],
    });
  }
  protected get fc() {
    return this.insertForm.controls;
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      // Perform the submit logic here
      this.Teacher_login();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
  private Teacher_login():void {
    this.submit = false;

    this.subscription.add(
      this._AuthService
        ._Login(this.Controller, this.insertForm.value)
        .subscribe(
          (response) => {
            if (response) {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('customerAuthorization', response['token']);
          this.GetSetting()

                localStorage.setItem('customer_login', JSON.stringify(response));
                localStorage.setItem('customer_name', response['name']);
                localStorage.setItem('logo', response['imagePath']);
                localStorage.setItem('role', response['role']);
                localStorage.setItem(
                  'roleId',
                  JSON.stringify(response['roleId'])
                );
                localStorage.setItem(
                  'profileStatusId',
                  JSON.stringify(response['profileStatusId'])
                );

                // this.toastr.success(response.name, this._transition.instant('Welcome'), this._transition.instant('Register Successfully'));

                if (response['role'] == 'Teacher') {
                  this._AuthService.ReloadHeader(true);

                  response['profileStatusId'] == 3
                  ? this.router
                  .navigate([`/main/Teacher/Homepage`])
                  // .then(() => window.location.reload())
                  : this.router.navigate([`/authentication/teacher/signup`]);

                return;
              }

              if (response['role'] == 'Student' && response['roleId'] == 2) {
                this._AuthService.ReloadHeader(true);

                localStorage.setItem('academicYearId', JSON.stringify(response['academicYearId']));

                // this.router.navigate([`/main/student/StudentSubjects`]);
                this.router.navigate([`/main/Student/ScheduleList`]);
                return;
              }
              if (response['role'] == 'Parent' && response['roleId'] == 3) {
            this._AuthService.ReloadHeader(true);
            localStorage.setItem('isUpdate', JSON.stringify(true));

                this.router.navigate([`/main/parent/ParentStudents`]);
                return;
              }
              this.router.navigate([`/authentication/teacher/signup`]);
              this._AuthService.ReloadHeader(true);

            }
          }

            this.insertForm.reset();
            this.submit = true;
          },
          (err) => {
            this.submit = true;
          }
        )
    );
  }



  private GetSetting():void {
    this.submit = false;

    this.subscription.add(
      this._AuthService
        .GetSetting()
        .subscribe(
          (response) => {
            if (response) {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('hoursPlusUtc', response.hoursPlusUtc.toString());
          }
          }


          },
          (err) => {

          }
        )
    );
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/Auth/SiginIn.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      if(this.Controller== 'teacher')
      this.seoService.setTitle(translations.Login.header.Text + ' - ' + translations.Tabs.body.Teacher);
      else if(this.Controller == 'parent')
      this.seoService.setTitle(translations.Login.header.Text + ' - ' + translations.Tabs.body.Parent);
      else
      this.seoService.setTitle(translations.Login.header.Text + ' - ' + translations.Tabs.body.Student);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
