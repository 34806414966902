import {  AfterViewInit, ChangeDetectorRef, Component, Inject, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],

})
export class MainLayoutComponent implements AfterViewInit {
  showFiller = true;
  direction!: Direction;
  @ViewChild('drawer') drawer: any;

  protected actionPart!: string;
  protected _SSRService = inject(SSRService);
  constructor(protected AuthService: AuthService,private router: Router,private cdr: ChangeDetectorRef,  @Inject(PLATFORM_ID) private platformId: Object) {
    this.actionPart = this._SSRService.Location(3);
    this._SSRService
      .events(3)
      .subscribe((eventValue: string | [string, string]) => {
        if (typeof eventValue === 'string') {
          // Handle the case when eventValue is a string
          // this.Controller = eventValue == 'home' ? 'teacher' : eventValue;
          this.actionPart = eventValue;
          // Your logic for handling a single string value
        }
      });

    //For use in other places you need role l example for use in path or src
    // localStorage.setItem('Controller', this.Controller);
// this.drawer.toggle()
  }

 protected onStudentSelect(selectedItem:any){

    this.router.navigate([`/main/parent/StudentSubjects/${selectedItem}`]);
  }

  handleEvent(event :any){
// this.drawer.toggle()
  }


  ngAfterViewInit(): void {
    // تحقق مما إذا كنت على المتصفح
    if (isPlatformBrowser(this.platformId)) {
      // منطق حجم الشاشة فقط يعمل على المتصفح
      if (this.AuthService.getToken()) {
        if (window.innerWidth > 768) {
          this.drawer.open();
        }
        this.cdr.detectChanges();
      }

      // إضافة Event listener لتغيير الحجم في حالة تغييره بعد فتح الصفحة
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 768) {
          this.drawer.close();
        } else if (this.AuthService.getToken()) {
          this.drawer.open();
        }
        this.cdr.detectChanges();
      });
    }
  }
}
