<div class="modal-content">
  <div class="modal-header">
    <!-- <h3 class="modal-title fs-5">
      {{"Service.header.CreateNewService" | translate}}</h3> -->
    <button class="btn-close" type="button" (click)="close()"></button>
  </div>
  <div class="modal-body custom-input">

    <div class="card h-100 mt-4 p-3 dark:bg-[#1a202c]">

      <div class="card-body dark:bg-[#1a202c] h-100 overflow-auto">
        <table
          class="w-full text-sm text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400"
        >
          <thead
            class="Lama-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
          >
            <tr>

              <th scope="col" class="px-6 py-3">
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.groupName" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.subject_name" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.session_name" | translate }}
              </th>
              <!-- <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.Date" | translate }}
              </th> -->

              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.Teacher_Name" | translate }}
              </th>
              <!-- <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.session_date" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.session_start_time" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.session_end_time" | translate }}
              </th> -->
              <!-- <th scope="col" class="px-6 py-3">
                {{ "studentCalenderSchedualList.cancel_option" | translate }}
              </th> -->

            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
              *ngFor="
              let item of TeacherLessonForList    ; let y = index

            "
            >


            <td class="px-6 py-4">
              <div
              [ngClass]="{
                'bg-red-700':  item.isCancel ==true,
                'bg-green-600':  item.isCancel ==false
              }"
                class="inline-block w-4 h-4 mr-2  rounded-full"
              ></div>
            </td>
              <th
                scope="row"
                class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
              >
                {{ item.groupName }}
                <br />

                <a *ngIf="item.isAlternate" style="color: red">
                   {{ "alternate_lesson" | translate }}
                </a>
              </th>


              <td class="px-6 py-4">
                {{ item.subjectName }}

              </td>

              <td class="px-6 py-4">
                {{ item.lessonName }}

                <br>

                <a style="color: red;">
                  {{ item.date|date }} {{'From' |translate}}    {{ item.timeFrom.slice(0,5) }}  {{'To' |translate}}     {{ item.timeTo.slice(0,5) }}
                </a>
              </td>
              <td class="px-6 py-4">
                {{ item.teacherName }}

              </td>



            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer">
        <a   class="d-block">
          <button class="btn btn-square btn-danger m-l-20  pull-right"  (click)="close()" type="button">{{"Cancel"|translate}}</button>
        </a>
        <!-- <button class="btn btn-square btn-success btnForm  pull-right"  [disabled]="!submit"  type="submit">{{"Save"|translate}}</button> -->

      </div>
    </div>

  </div>

</div>





