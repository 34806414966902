import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherCalenderSchedualService } from 'src/app/shared/Apis/Teacher/Calender/teacher-calender-schedual.service';
import { addHours, addMinutes } from 'date-fns';
import { EventColor } from 'calendar-utils';
import { CalenderSchedual } from 'src/app/shared/Model/teacher/calender-schedual';
import { DxSchedulerModule, DxSchedulerComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import Query from 'devextreme/data/query';
import { Appointment, Resource, Service } from './test-calender/app.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSessionModleComponent } from '../subject-group/add-session-modle/add-session-modle.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-teacher-calender-schedual',
  templateUrl: './teacher-calender-schedual.component.html',
  styleUrls: ['./teacher-calender-schedual.component.scss'],
  providers: [Service],
})
export class TeacherCalenderSchedualComponent implements OnInit {
  @ViewChild(DxSchedulerComponent, { static: false }) scheduler:
    | DxSchedulerComponent
    | undefined;

  events!: Appointment[];
  appointmentsData: Appointment[];
  targetedAppointmentData!: Appointment;
  currentDate: Date = new Date();
  resourcesData: Resource[];
  modalRef?: BsModalRef;
  dateNow: Date = new Date();
  dateNow$: Date = new Date();
  serverTime: Date = new Date();

  constructor(
    service: Service,
    private seoService: SeoService,
    private _TeacherCalenderSchedual: TeacherCalenderSchedualService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,private translate: TranslateService,
    public layoutService: LayoutService,    public modal: NgbModal,
  ) {
    this.appointmentsData = service.getAppointments();
    this.resourcesData = service.getResources();

    this.GetMyCalenderSchedual();
  }
  ngOnInit(): void {
    this.seo();
  }
  onAppointmentDblClick(e: any) {
    e.cancel = true;
  }
  onAppointmentDeleting(e: any) {
    // Handler of the "appointmentDeleting" event
  }
  ngAfterViewInit(): void {
    // Trigger change detection and layout recalculation
    this.cdr.detectChanges();
    if (this.scheduler && this.scheduler.instance) {
      this.scheduler.instance.repaint();
    }
  }

  allowAdding: boolean = false;
  allowDeleting: boolean = false;
  allowUpdating: boolean = false;
  allowResizing: boolean = true;
  allowDragging: boolean = true;

  isDeletedAppointment(data: any): boolean {
    // Implement your logic to determine whether the appointment should be considered as deleted
    // For example, compare the appointment's date with the current date
    const appointmentDate = new Date(data.startDate);
    const currentDate = new Date();
    return appointmentDate < currentDate;
  }

  private GetMyCalenderSchedual() {
    this._TeacherCalenderSchedual
      .GetMyCalenderSchedual()
      .subscribe((calendarItems) => {
        const transformedCalendar = calendarItems.map(
          (calendarItem: CalenderSchedual) => {
            // Perform some transformation on each item
            // let Start = new Date(calendarItem.date); // تعيين تاريخ مخصص
            // let minute = this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0;

            let event = {
              teacherlessonsessionId: calendarItem.teacherlessonsessionId ,
              teacherlessonId: calendarItem.teacherlessonId ,
              teachersubjectAcademicSemesterYearId: calendarItem.teacherSubjectAcademicSemesterYearId ,
              text: calendarItem.groupName ?? 'individual',
              startDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
              ),
              endDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeTo) ?? 0
              ),
              timeFrom: calendarItem.timeFrom,
              timeTo: calendarItem.timeTo,
              isCancel: calendarItem.isCancel,
              teamMeetingLink: calendarItem.teamMeetingLink,
              id: calendarItem.teacherLessonSessionSchedualSlotId,
              bookTeacherlessonsessionDetailId:
                calendarItem.bookTeacherlessonsessionDetailId,
              roomId: calendarItem.isCancel ? 1 : 2,
              actions: this.isCancelAndCheckOnDate(
                calendarItem.isCancel,
                addMinutes(
                  new Date(calendarItem.date),
                  this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
                )
              ),
            };
            return event;
          }
        );
        this.events = [];
        this.events = [...transformedCalendar];
        this.appointmentsData = [...transformedCalendar];
      });
  }

  private CancelBookLessonSession(id: any) {
    this._TeacherCalenderSchedual
      .CancelBookLessonSession(id)
      .subscribe((calendarItems) => {
        Swal.fire(
          this.translate.instant('Cancelled'),
          this.translate.instant('Your appointment has been cancelled.'),
          'success'
        );
        const transformedCalendar = calendarItems.map(
          (calendarItem: CalenderSchedual) => {

            // Perform some transformation on each item
            let Start = new Date(calendarItem.date); // تعيين تاريخ مخصص
            let minute = this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0;
            // إضافة 15 دقيقة إلى التاريخ المخصص
            Start.setMinutes(Start.getMinutes() + minute);

            let event = {
              text: calendarItem.groupName ?? 'individual',
              teacherlessonsessionId: calendarItem.teacherlessonsessionId ,
              teacherlessonId: calendarItem.teacherlessonId ,
              teachersubjectAcademicSemesterYearId: calendarItem.teacherSubjectAcademicSemesterYearId ,
              startDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
              ),
              endDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeTo) ?? 0
              ),
              timeFrom: calendarItem.timeFrom,
              timeTo: calendarItem.timeTo,
              isCancel: calendarItem.isCancel,
              id: calendarItem.teacherLessonSessionSchedualSlotId,
              roomId: calendarItem.isCancel ? 1 : 3,
              actions: this.isCancelAndCheckOnDate(
                calendarItem.isCancel,
                addMinutes(
                  new Date(calendarItem.date),
                  this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
                )
              ),
            };

            return event;
          }
        );
        this.events = [];
        this.events = [...transformedCalendar];
        this.appointmentsData = [...transformedCalendar];
      });
  }

  private convertTimeToMinutes(timeString: string): number | null {
    // Regular expression to match the "HH:mm:ss" format
    const timeRegex = /^(\d{2}):(\d{2}):(\d{2})$/;

    // Check if the input time string matches the expected format
    const match = timeString.match(timeRegex);

    if (match) {
      // Extract the hour, minute, and second components
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const seconds = parseInt(match[3], 10);

      // Calculate the total minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      return totalMinutes;
    } else {
      // Return null if the input format is not valid
      console.error('Invalid time format. Expected "HH:mm:ss"');
      return null;
    }
  }

  isCancelAndCheckOnDate(isCancel: boolean, start: Date) {
    if (!isCancel && start > new Date()) {
      return true;
    } else {
      return false;
    }
  }

  onAppointmentDeleted(e: any) {
    // Handler of the "appointmentDeleted" event
    /* {
      startDate: new Date(2016, 6, 18, 8),
      endDate: new Date(2016, 6, 18, 9),
      ownerId: [1, 2],
      recurrenceRule: "FREQ=DAILY"
  } */
  }

  deleteEvent(eventToDelete: number) {
    if (eventToDelete) {
      this.CancelBookLessonSession(eventToDelete);
      this.GetMyCalenderSchedual();
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  protected TeacherAttendance(
    teacherlessonsessionScheduleSlotId: number
  ): void {
    this._TeacherCalenderSchedual
      .TeacherAttendance(teacherlessonsessionScheduleSlotId)
      .subscribe((res) => {});
  }

  private seo() {
    // this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    // );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(
        translations.MENUITEMS.StudentsSessions.LIST.Calendar
      );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        translations.Home.header.meta_description
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(
          translations.Home.header.meta_description
        )
      );

      // this.seoService.setMetaTags(translations);
    });
  }

  AddAndUpdateSessions(SubjectGroup:Appointment) {
    const modalRef = this.modal.open(AddSessionModleComponent, { size: "lg" });
    if(SubjectGroup)
    modalRef.componentInstance.teacherLessonSessionSchedualSlotId = SubjectGroup.id; // Pass the teacherLessonSessionSchedualSlotId to the modal component
    modalRef.componentInstance.teacherlessonId = SubjectGroup.teacherlessonId; // Pass the teacherlessonId to the modal component
    modalRef.componentInstance.id = SubjectGroup.teacherlessonsessionId; // Pass the teacherlessonsessionId to the modal component
    modalRef.componentInstance.teacherSubjectAcademicSemesterYearId = SubjectGroup.teachersubjectAcademicSemesterYearId; // Pass the ID to the modal component
    modalRef.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;

      },
      (reason) => {
         this.GetMyCalenderSchedual();

      },
    );
  }
}
