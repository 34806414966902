<div class="modal-content">
  <div class="modal-header">
    <!-- <h3 class="modal-title fs-5">
      {{"Service.header.CreateNewService" | translate}}</h3> -->
    <button class="btn-close" type="button" (click)="close()"></button>
  </div>
  <div class="modal-body custom-input">
      <form class="row g-3 needs-validation custom-input"
      [formGroup]="InsertForm" (ngSubmit)="onSubmit()">


        <div class="col-md-12 position-relative">
          <label class="form-label  " >   {{'GroupForLesson.form.Teacher-Lesson' |translate}} </label>

         <ng-select
         #main *ngIf="!teacherlessonId"
         [selectOnTab]="true"
         formControlName="teacherLessonId"
         class="p-0 custom-select"
         dropdownPosition="auto"
         labelForId="validation02"
         id="validationTooltip03"
         [style.border]="(fc['teacherLessonId']?.touched || validate) ? (fc['teacherLessonId']?.invalid ? '1px solid #dc3545 !important'
         : (fc['teacherLessonId'].valid && fc['teacherLessonId']?.touched ? '1px solid #28a745' : 'none')) : 'none'"
       >

         <ng-option  *ngFor="let item of TeacherLessonForList;" [value]="item.id">{{ item.lessonName }} </ng-option>

       </ng-select>

       <ng-select *ngIf="teacherlessonId"
       #mainUpdate
       [selectOnTab]="true"
       formControlName="teacherLessonUpdateId"
       class="p-0 custom-select"
       dropdownPosition="auto"
       labelForId="validation02"
       id="validationTooltip03"
       [style.border]="(fc['teacherLessonUpdateId']?.touched || validate) ? (fc['teacherLessonUpdateId']?.invalid ? '1px solid #dc3545 !important'
       : (fc['teacherLessonUpdateId'].valid && fc['teacherLessonUpdateId']?.touched ? '1px solid #28a745' : 'none')) : 'none'"
     >

       <ng-option  *ngFor="let item of TeacherLessonForList;" [value]="item.id">{{ item.lessonName }} </ng-option>

     </ng-select>
           <div class="invalid-tooltip">{{"Service.form.invalidMainService" | translate}}</div>

        </div>
        <div class="col-md-6 col-12 position-relative">

          <label
          for="countries"
          class="block mb-2  text-gray-900 dark:text-white"
          >       {{ "GroupForLesson.form.Date" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
        >
        <input
          type="date" (keypress)="$event.preventDefault()"
          formControlName="date"
          [min]="expirationDateNow"
          [tooltip]="
            fc['date'].invalid &&
            (fc['date'].dirty || fc['date'].touched)
              ? ('GroupForLesson.form.required' | translate)
              : null
          "
          [ngClass]="{
              'class-1': fc['date'].invalid && (fc['date'].dirty || fc['date'].touched),
              'class-2': fc['date'].valid && (fc['date'].dirty || fc['date'].touched),
            }"
          id="date"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"

          required
        />


          </div>

          <div class="col-md-6 col-12 position-relative">

            <label
              for="timeFrom"
              class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >
              {{ "SubjectGroup.form.STime" | translate
              }}<span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              >
            </label>
            <input
              type="time"
              formControlName="timeFrom"
              [tooltip]="
             InsertForm.get('timeFrom')?.invalid &&
                (InsertForm.get('timeFrom')?.dirty ||
                  InsertForm.get('timeFrom')?.touched)
                  ? ('SubjectGroup.form.required' | translate)
                  : null
              "
              [ngClass]="{
            'class-1': InsertForm.get('timeFrom')?.invalid && (InsertForm.get('timeFrom')?.dirty || InsertForm.get('timeFrom')?.touched),
            'class-2': InsertForm.get('timeFrom')?.valid && (InsertForm.get('timeFrom')?.dirty || InsertForm.get('timeFrom')?.touched),
          }"
              id="timeFrom"
              class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your from Time"
              required
            />
          </div>






        <div class="modal-footer">
        <a   class="d-block">
          <button class="btn btn-square btn-danger m-l-20  pull-right"  (click)="close()" type="button">{{"Cancel"|translate}}</button>
        </a>
        <button class="btn btn-square btn-success btnForm  pull-right"  [disabled]="!submit"  type="submit">{{"Save"|translate}}</button>

      </div>
    </form>
  </div>

</div>





