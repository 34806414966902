

<!-- <div class="row  ">
  <img
    class="mb-4 ImgCover"
    src="../../../../../assets/images/MrS-Cool/parentstudent.png"
  />
</div> -->

<div class="  md:mt-10 py-3  md:pr-12 ">
<div class="   rounded-lg bg-white shadow">
  <div class="addressIconSpace ps-4">
    <!-- <h2 class="ps-4 text-[18px]  lg:text-[30px] sora-bold underTitle">{{'StudentSubject.header.Title' |translate}}</h2>-->
      <h2 class=" text-[18px]   pt-3 mb-0 lg:text-[30px] sora-bold underTitle">
        <span class=" ps-4 text-[18px]  lg:text-[30px] sora-bold underTitle text-[var(--theme-deafult-TeacherWithClick)]"> {{'StudentSubject.header.Title' |translate}} </span>
        <span  class=" ml-2sora-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-ParentWithClick)]" *ngIf="academicLevelName">
          {{academicLevelName }}
          <!-- {{ formatString(AllSubjects[0].name)[1] }}
            {{ formatString(AllSubjects[0].name)[2] }} -->
          </span>
        </h2>
        <label class="ps-4">{{'StudentSubject.header.Subtitle' |translate}}</label>
        <hr>
  </div>
  <div *ngIf="StudentSubjects &&StudentSubjects[0] !=null" class="w-100">
  <owl-carousel-o  [dir]="'ltr'" [options]="customOptions"  >
    <ng-container *ngFor="let item of StudentSubjects">
      <ng-template carouselSlide >
        <div
          (click)="GoToDetails(item.id)"
          class="slide cursor-pointer card m-4 mb-3 ml-2 StudentsCardList hover-effect text-center"
        >
          <div class="SvgStudentImg mt-4">
            <svg
              *ngIf="!item.image"
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="100"
              viewBox="0 0 182 182"
              fill="none"
            >
              <path
                d="M91 182C141.121 182 182 141.121 182 91C182 40.8789 141.121 0 91 0C40.8789 0 0 40.8789 0 91C0 141.121 40.8789 182 91 182Z"
                fill="#FFF5F5"
              />
              <mask
                id="mask0_2339_7986"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="182"
                height="182"
              >
                <path
                  d="M91 182C141.121 182 182 141.121 182 91C182 40.8789 141.121 0 91 0C40.8789 0 0 40.8789 0 91C0 141.121 40.8789 182 91 182Z"
                  fill="#FAAC04"
                />
              </mask>
              <g mask="url(#mask0_2339_7986)">
                <path
                  d="M26.5713 178.576V182.692C26.5657 184.168 27.7577 185.37 29.2339 185.376H152.203C153.68 185.37 154.872 184.168 154.866 182.692V178.576C154.866 156.632 138.414 138.628 117.438 137.33C117.379 137.328 117.32 137.328 117.261 137.33H64.1816C64.1224 137.328 64.0632 137.328 64.0041 137.33C43.0284 138.628 26.5713 156.632 26.5713 178.576Z"
                  fill="#FFAA00"
                />
                <path
                  d="M77.3635 119.883C75.8852 119.874 74.6807 121.067 74.6748 122.546V147.81C74.6807 149.288 75.8852 150.481 77.3635 150.473H104.078C105.554 150.478 106.756 149.286 106.762 147.81C106.762 139.925 106.762 131.907 106.762 122.546C106.756 121.069 105.554 119.877 104.078 119.883H77.3635Z"
                  fill="#FFCCAA"
                />
                <path
                  d="M77.3653 119.883C77.0139 119.881 76.6656 119.948 76.3402 120.081C76.0148 120.214 75.7188 120.409 75.4691 120.657C75.2194 120.904 75.0209 121.198 74.8849 121.522C74.749 121.846 74.6782 122.194 74.6768 122.545V130.266C79.1844 133.538 84.7176 135.491 90.7195 135.491C96.7213 135.491 102.253 133.538 106.762 130.266V122.545C106.759 121.836 106.475 121.158 105.972 120.658C105.468 120.159 104.788 119.88 104.079 119.883H77.3653Z"
                  fill="#FFB889"
                />
                <path
                  d="M64.0058 137.33C43.0302 138.628 26.5694 156.635 26.5694 178.579V182.693C26.564 184.17 27.7558 185.371 29.2318 185.377H34.5777C33.1017 185.371 31.9098 184.17 31.9152 182.693V178.579C31.9152 156.635 48.376 138.628 69.3516 137.33H64.1833C64.1241 137.328 64.065 137.328 64.0058 137.33Z"
                  fill="#FFAA00"
                />
                <path
                  d="M70.1175 130.031L62.0879 138.04C61.0514 139.083 61.0514 140.767 62.0879 141.81L80.8148 160.517C81.8575 161.553 83.5415 161.553 84.5842 160.517L90.7185 154.398L96.8581 160.517C97.9008 161.553 99.5848 161.553 100.628 160.517L119.333 141.81C120.37 140.767 120.37 139.083 119.333 138.04L111.32 130.031C110.809 129.517 110.112 129.233 109.388 129.242C108.689 129.252 108.022 129.535 107.529 130.031L90.7185 146.843L73.8868 130.031C73.3814 129.522 72.6929 129.238 71.976 129.242C71.2773 129.252 70.6102 129.535 70.1175 130.031Z"
                  fill="#CD2028"
                />
                <path
                  d="M89.3764 25.3326L19.883 62.3199C18.0469 63.2978 18.1243 65.7563 20.0187 66.6352L60.3699 85.1337L61.5655 82.928L61.5759 82.9329H119.85L121.045 85.1338L161.417 66.6353C163.313 65.7584 163.393 63.2997 161.558 62.32L92.0598 25.3328C91.6737 25.1269 91.2799 24.9899 90.7195 25.0006C90.1591 25.0112 89.7258 25.146 89.3764 25.3326Z"
                  fill="#FFAA00"
                />
                <path
                  d="M64.3795 91.25C58.8031 91.25 54.4131 96.0807 54.4131 101.75C54.4131 107.419 58.8031 112.265 64.3795 112.265C65.1561 112.265 65.92 112.134 66.6714 111.941C66.671 111.921 66.661 111.904 66.661 111.884V91.5372C65.9142 91.3518 65.1486 91.25 64.3795 91.25Z"
                  fill="#FFB889"
                />
                <path
                  d="M127.035 101.75C127.035 107.419 122.645 112.265 117.068 112.265C116.292 112.265 115.528 112.134 114.776 111.941C114.777 111.921 114.786 111.904 114.786 111.884V91.5372C115.533 91.3518 116.299 91.25 117.068 91.25C122.645 91.25 127.035 96.0807 127.035 101.75Z"
                  fill="#FFCCAA"
                />
                <path
                  d="M90.7175 64.7344C82.4091 64.7344 74.9113 65.4804 69.2915 66.8071C66.4817 67.4705 64.1434 68.2486 62.2853 69.3133C60.4482 70.366 58.7123 71.9796 58.6725 74.3882C58.5168 75.1709 56.6785 84.8781 64.1595 92.3435L64.9479 93.1319H116.487L117.26 92.3435C124.741 84.8781 122.918 75.1709 122.762 74.3882C122.723 71.9796 120.992 70.366 119.155 69.3133C117.297 68.2486 114.959 67.4705 112.149 66.8071C106.529 65.4804 99.0259 64.7344 90.7175 64.7344Z"
                  fill="#1B2651"
                />
                <path
                  d="M84.1085 73.3517C83.7571 73.3476 83.4082 73.4129 83.082 73.5438C82.7558 73.6746 82.4586 73.8686 82.2074 74.1144C81.9562 74.3603 81.756 74.6533 81.6182 74.9766C81.4803 75.3 81.4076 75.6473 81.4042 75.9988C81.3408 81.9964 76.5221 86.7909 70.5346 86.7908H66.0552C65.3499 86.7937 64.6743 87.0751 64.1755 87.5739C63.6768 88.0726 63.3954 88.7483 63.3926 89.4536V102.872C63.3926 117.943 75.6243 130.147 90.718 130.147C105.812 130.147 118.049 117.943 118.049 102.872V89.4536C118.046 88.7483 117.764 88.0726 117.266 87.5739C116.767 87.0751 116.091 86.7937 115.386 86.7908H99.6768C93.173 86.7908 87.6978 82.0434 86.7137 75.6229C86.6185 74.9972 86.3045 74.4256 85.8274 74.0097C85.3504 73.5938 84.7413 73.3607 84.1085 73.3517Z"
                  fill="#FFCCAA"
                />
                <path
                  d="M39.9304 51.6562L34.5791 54.5044V91.8252H39.9304V51.6562Z"
                  fill="white"
                />
                <path
                  d="M34.5995 86.4688C33.1233 86.4631 31.9219 87.6552 31.916 89.1315V113.267C31.9104 114.751 33.1151 115.956 34.5995 115.95H39.9348C41.411 115.944 42.603 114.743 42.5974 113.267V89.1315C42.5915 87.6634 41.4028 86.4746 39.9348 86.4688H34.5995Z"
                  fill="#CD2028"
                />
                <path
                  d="M90.719 49.0703C82.4106 49.0703 74.9122 49.8275 69.3087 51.1065C66.507 51.7461 64.1879 52.4943 62.3443 53.4926C61.4225 53.9917 60.6082 54.555 59.9166 55.3304C59.225 56.1058 58.6375 57.2178 58.6375 58.4683V74.1159C58.6397 74.202 58.6699 74.2734 58.6794 74.3561C58.679 74.3692 58.6743 74.3796 58.674 74.3926C58.6018 74.7555 58.1698 77.0707 58.4704 80.1567C60.4969 78.1724 61.4963 77.1111 63.2473 75.8911C64.717 74.8671 66.8881 73.4722 71.6005 72.2259C74.838 71.3696 78.9498 70.6714 83.7388 70.3045C85.9817 70.1764 88.2953 70.0904 90.7189 70.0904C93.1426 70.0904 95.4561 70.1764 97.6991 70.3045C102.488 70.6714 106.6 71.3696 109.837 72.2259C114.55 73.4722 116.721 74.8671 118.191 75.8911C119.942 77.1111 120.941 78.1723 122.968 80.1567C123.268 77.0707 122.836 74.7554 122.764 74.3926C122.764 74.3795 122.759 74.3691 122.759 74.356C122.768 74.2733 122.798 74.202 122.8 74.1159V58.4683C122.8 57.2178 122.213 56.1058 121.521 55.3304C120.83 54.555 120.015 53.9917 119.094 53.4926C117.25 52.4943 114.931 51.7461 112.129 51.1065C106.526 49.8275 99.0275 49.0703 90.719 49.0703Z"
                  fill="#FFAA00"
                />
                <path
                  d="M34.5994 86.4688C33.1232 86.4631 31.9219 87.6547 31.916 89.1311V113.269C31.9104 114.754 33.115 115.958 34.5994 115.952H39.9348C38.4553 115.952 37.2562 114.75 37.2619 113.269V89.1311C37.2678 87.6583 38.4635 86.4689 39.9348 86.4688H34.5994Z"
                  fill="#CD2028"
                />
                <path
                  d="M62.3454 53.4762C61.4236 53.9753 60.6042 54.5383 59.9127 55.3137C59.2211 56.089 58.6336 57.2057 58.6336 58.4562V74.1011C58.6358 74.1872 58.6659 74.2585 58.6754 74.3412C58.6751 74.3544 58.6704 74.3648 58.6701 74.3778C58.5979 74.7406 58.1659 77.0548 58.4665 80.1409C60.493 78.1565 61.4923 77.096 63.2433 75.876C63.4274 75.7477 63.6653 75.5972 63.875 75.4584C63.9298 75.0536 63.995 74.483 64.0159 74.3778C64.0162 74.3647 64.0207 74.3542 64.0213 74.3412C64.0116 74.2585 63.9817 74.1872 63.9794 74.1011V58.4562C63.9794 57.2057 64.5669 56.089 65.2585 55.3137C65.9501 54.5383 66.7694 53.9753 67.6912 53.4762C69.5349 52.4779 71.8537 51.7301 74.6554 51.0905C79.6416 49.9524 86.1692 49.2283 93.3919 49.0824C92.4971 49.0642 91.635 49.0547 90.719 49.0547C82.4106 49.0547 74.9131 49.8115 69.3096 51.0905C66.5079 51.7301 64.189 52.4779 62.3454 53.4762Z"
                  fill="#FFAA00"
                />
                <path
                  d="M66.058 86.8047C64.5901 86.8106 63.4016 87.999 63.3955 89.467V102.883C63.3955 114.87 71.1413 125.019 81.9023 128.681C72.8207 123.69 66.6636 114.053 66.6636 102.966V88.5326C66.6658 87.9084 66.8711 87.3019 67.2483 86.8047H66.058Z"
                  fill="#FFB889"
                />
                <path
                  d="M53.4194 169.339C51.8075 169.267 50.4999 170.628 50.6368 172.236V185.338H55.9618V172.236C56.0906 170.723 54.9358 169.407 53.4194 169.339Z"
                  fill="#FFAA00"
                />
                <path
                  d="M128.261 169.339C126.649 169.267 125.342 170.628 125.479 172.236V185.338H130.804V172.236C130.932 170.723 129.778 169.407 128.261 169.339Z"
                  fill="#FFAA00"
                />
              </g>
            </svg>
            <img
              *ngIf="item.image"
              class="mb-4 StudentImg"
              [src]="envImageUrl + item.image"
            />
          </div>

          <div class="text-center">
            <!-- <div class="formatted-text"  [innerHTML]="formatString(item.name)"></div> -->
            <div class="formatted-text">
              <span class="first-part">{{ formatString(item.name)[0] }}</span
              >
              <!-- <br />
              <span class="second-part">{{ formatString(item.name)[1] }}</span
              > -->
              <!-- <br />
              <span class="third-part">{{ formatString(item.name)[2] }}</span> -->
            </div>
            <!-- <label>{{item.name}}</label> -->
            <br />
          </div>
        </div>
      </ng-template>
    </ng-container>

  </owl-carousel-o>
  </div>

  <div class="text-center" *ngIf="!StudentSubjects || !StudentSubjects.length">

    <div class="my-5 px-8  flex justify-center" >




      <div class="text-center"  >
        <img
          src="assets/images/OurMostPopularLessons/empty/TopViewedSubjects.svg"
          alt="calendar"
          class="m-auto"
        />
        <p class="text-[#868B9F] mt-3">

          {{"Showing.NoAvailable"  |translate}} <br />  {{"Showing.SubjectsYet"  |translate}}
          <span class="vector-bg"
            >

          </span>
        </p>
      </div>

    </div>

  </div>
</div>
</div>
<!-- <div class="row py-3 cardsSpace">
  <img
    class="mb-4 ImgCover"
    src="../../../../../assets/images/MrS-Cool/Ad.png"
  />
</div> -->
<app-our-most-popular-lessons #MostViewedLessons *ngIf="academicleveid" [academicleveid]="academicleveid" class="cardsSpace"  [type]="'MostViewedLessons'" ></app-our-most-popular-lessons>

<br>
<app-our-most-popular-lessons #MostBookedLessons *ngIf="academicleveid" [academicleveid]="academicleveid"  class="cardsSpace"  [type]="'MostBookedLessons'"></app-our-most-popular-lessons>
<br>

<app-our-most-popular-subjects #TopViewedSubjects class="cardsSpace"  *ngIf="academicleveid" [academicleveid]="academicleveid"   [type]="'TopViewedSubjects'"></app-our-most-popular-subjects>

<br>

<app-our-most-popular-subjects #TopBookedSubjects  *ngIf="academicleveid" [academicleveid]="academicleveid"   [type]="'TopBookedSubjects'"></app-our-most-popular-subjects>
<br>

<app-most-booked-teachers #MostRatedTeacher class="cardsSpace" *ngIf="academicleveid" [academicleveid]="academicleveid"    [type]="'MostRatedTeacher'"></app-most-booked-teachers>
<br>

<app-most-booked-teachers  #MostViewedTeacher class="cardsSpace"  *ngIf="academicleveid" [academicleveid]="academicleveid"   [type]="'MostViewedTeacher'"></app-most-booked-teachers>
