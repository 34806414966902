import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
 import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MustMatch } from 'src/app/shared/validators/MustMatch';
import { Router, RouterLink } from '@angular/router';
import { PasswordService } from 'src/app/shared/Apis/Password/password.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    TranslateModule,
    RouterLink,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected Controller!: string;
  @Input() from: any;
  protected changetype: boolean = true;
  protected confirmtype: boolean = true;
  protected doneReset: boolean = false;
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _SSRService: SSRService,
    private _Password: PasswordService,  private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    this.Controller = this._SSRService.Location(2);
  }
  ngOnInit(): void {
    this.seo();
    this.initiate();
  }
  private initiate() {
    this.insertForm = this._fb.group(
      {
        mobile: [this.from.mobile, [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        confirmPassword: ['', Validators.required],
        otp: [this.from.otp, Validators.required],
      },
      {
        validators: [MustMatch('newPassword', 'confirmPassword')],
      }
    );
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      this.ResetPassword();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private ResetPassword() {
    this._Password
      .ResetPassword(this.Controller, this.insertForm.getRawValue())
      .subscribe((res) => {
        if (res.success) this.doneReset = true;
      });
  }
  navigate() {
    this._router.navigate([`/authentication/${this.Controller}/login`]);
  }

  protected get fc() {
    return this.insertForm.controls;
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/left.svg');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      if(this.Controller== 'teacher')
      this.seoService.setTitle(translations.ResetPassword.header.Text + ' - ' + translations.Tabs.body.Teacher);
      else if(this.Controller == 'parent')
      this.seoService.setTitle(translations.ResetPassword.header.Text + ' - ' + translations.Tabs.body.Parent);
      else
      this.seoService.setTitle(translations.ResetPassword.header.Text + ' - ' + translations.Tabs.body.Student);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }
}
