import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../AuthService/auth.service';
import { ChildrenIdService } from 'src/app/Component/Parent/choose-student/children-id.service';
@Injectable({
  providedIn: 'root',
})
export class SSRService {
  constructor(
    private _router: Router,
    private location: Location,
    private auth: AuthService,
    private childrenIdService: ChildrenIdService
  ) {}
  Location(count: number) {
    return this.location.path().split('/')[count];
  }

  event(action: number) {

    this._router.events.subscribe((event: any) => {
      if (
        event instanceof NavigationEnd &&
        this.Location(3) == 'StudentSubjects'
      ) {
        const StudentId = parseInt(this.Location(action));

        const childrenIdValue = this.auth.GetByName('childrenId');
        if (childrenIdValue !== '') {
          try {
            const childrenIdJson = JSON.parse(childrenIdValue);
            // القيام بالمزيد من العمل بعد التحويل بنجاح
            if (
              StudentId &&
              StudentId !== JSON.parse(this.auth.GetByName('childrenId'))
            )
              this.eventPrams(StudentId);
          } catch (error) {
            console.error('Invalid JSON string:', childrenIdValue);
          }
        } else {
          this.eventPrams(StudentId);
        }
        return StudentId;
      }
      return true;
    });
  }

  eventPrams(StudentId: any) {
    // this.childrenIdService.setChildrenId(StudentId);
    // this.childrenIdService.ReloadBackOnStudent(StudentId);

    const childrenIdValue = this.auth.GetByName('childrenId');
    if (childrenIdValue !== '') {
      try {
        const childrenIdJson = JSON.parse(childrenIdValue);
        // القيام بالمزيد من العمل بعد التحويل بنجاح
        if (
          StudentId &&
          (JSON.parse(this.auth.GetByName('isUpdate')) ||
            parseInt(StudentId) !== childrenIdJson)
        ) {
          this.childrenIdService.setChildrenId(StudentId);
          this.childrenIdService.ReloadBackOnStudent(StudentId);
        }
      } catch (error) {
        console.error('Invalid JSON string:', childrenIdValue);
      }
    } else {
      this.childrenIdService.setChildrenId(parseInt(StudentId));
      this.childrenIdService.ReloadBackOnStudent(parseInt(StudentId));
    }
  }

  public events(
    action: number,
    controller?: number
  ): Observable<[string, string] | string> {
    return this._router.events.pipe(
      // Filter for NavigationEnd events
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const actionPart = this.location.path().split('/')[action] || '';

        if (controller) {
          const controllerPart =
            this.location.path().split('/')[controller] || '';
          return [actionPart, controllerPart];
        } else {
          return actionPart;
        }
      })
    );
  }
}
