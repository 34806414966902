<!-- Faq Question section Start -->
<section class="faq-contain" dir="ltr">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="bold">Terms & Conditions</h2>

        <h2>1. Introduction</h2>
        <p>
          Please read these Terms & Conditions carefully before using the
          “M-r-Scool” mobile application (referred to as the "App") and the
          platform. These Terms & Conditions govern your use of the App, the
          platform and by accessing or using the App and the Platform, you agree
          to be bound by these Terms & Conditions.
        </p>

        <h2>2. App and Platform Purpose:</h2>
        <p>
          The App and Platform "M-r-Scool” is designed for teachers to connect
          with students and parents. They enable teachers to teach students
          anywhere at any time. Parents can stay updated with their child’s
          progress and pay for the lessons safely.
        </p>

        <h2>3. User Responsibilities:</h2>
        <p>
          You acknowledge and agree that your use of the App is at your own risk
          and that you are solely responsible for any consequences arising from
          your use of the App and the Platform. As a
        </p>

        <h2>4. teacher, I agree to:
        </h2>
        <ul>

          <li>  − Create my own user account by myself.
          </li>
          <li> − Communicate with students and actively monitor their academic performance.
          </li>
          <li> − Not use curse or swear words.
          </li>
          <li> − Dress appropriately.
          </li>
          <li> − Never take photos of my students.
          </li>
          <li> − Never record any conversations.
          </li>
          <li> − Never ask students for personal information.
          </li>
          <li> − Always provide and use authentic materials and flawless content.
          </li>
          <li> − Show professionalism to students.
          </li>
          <li> − Be punctual; stick to the timetable and stay consistent with it.
          </li>
          <li> − Never cancel any class. Any cancellation must be informed at least ……… in advance.
          </li>
          <li>  − Hold the classes in accordance with the educational stages shown on the platform.
          </li>
          <li> − Utilize the electronic media of the platform.
          </li>
          <li> − Use educational assessment tools when wrapping up every lesson.
          </li>
          <li> − Never share any links, codes, or teaching/ learning materials sent to me personally.
          </li>
          <li>Conduct a fair and objective assessment of every student’s practice and academic
            learning during the lesson and give a post-assessment as well.
          </li>
          <li> − Always chat and communicate with others through the platform only.
          </li>
          <li> − Not request or make any agreements or appointments outside of the Platform.
          </li>
          <li>  − Not share any personal information with others.
          </li>
          <li>− Have my account suspended for a week in case of a first-time violation of the agreedupon terms and conditions for, and two weeks for the second time.
          </li>
          <li>Have my account terminated in case of multiple violations.

            </li>


        </ul>

        <h2>5.   As a parent/ caregiver, I agree to:</h2>
        <ul>

          <li> − Always chat and communicate with others through the platform only.
          </li>
          <li> − Not request or make any agreements or appointments outside of the Platform.
          </li>
          <li> − Never share any personal information with others.
          </li>
          <li> − Conduct objective and fair assessments of teachers’ performance.
          </li>
          <li> − Have my account suspended for a week in case of a first-time violation of the agreedupon terms and conditions for, and two weeks for the second time.
          </li>
          <li> − Have my account terminated in case of multiple violations.
          </li>

        </ul>


        <h2>6.   As a student, I agree to:        </h2>
        <ul>

          <li> − Develop self-discipline and respect my teachers and classmates/ partners as well.
          </li>
          <li>− Follow my teacher’s instructions.
          </li>
          <li> − Not use curse or swear words. </li>
          <li>− Dress appropriately.
          </li>
          <li> − Never take photos of my teacher nor students.
          </li>
          <li> − Never record any conversations.
          </li>
          <li> − Always chat and communicate with others through the platform only.
          </li>
          <li> − Not request or make any agreements or appointments outside of the Platform.
          </li>
          <li>− Not share any personal information with others.
          </li>
          <li> − Conduct a fair and objective assessment of a teacher’s performance.
          </li>
          <li>   Attend scheduled online classes at set times and follow the regulations and guidelines of
            the platform</li>
          <li> Not claim back the money paid as it will be transferred to my financial wallet for later
            use on the application and platform. </li>

        </ul>

        <h2>6. Third-Party Services:        </h2>

        <ul>
          <li>
            The App and Platform "M-r-Scool” may include links or references to third-party websites,
            services, or resources. These third-party services are not under our control, and we are not
            responsible for their availability, content, or accuracy. Your use of any third-party services is
            subject to their respective terms and conditions and privacy policies.

          </li>

        </ul>


        <h2>7. Privacy</h2>
        <ul>
          <li>
            We are committed to protecting your privacy and handling your personal information in
            accordance with applicable data protection laws. Please review our Privacy Policy to understand
            how we collect, use, and disclose your information when you use the App & the Platform.
          </li>
          <br />

        </ul>

        <h2>8.  Intellectual Property:
        </h2>
        <h3>8.1. User-Initiated Termination:</h3>
        <p>
          All intellectual property rights in the App and its content, including but not limited to text,
          graphics, logos, images, and software, are owned by us or our licensors. You may not reproduce,
          modify, distribute, or display any part of the App without our prior written consent.

        </p>

        <p>
          The platform reserves the right to suspend or terminate accounts for
          terms violations or suspected misuse.
        </p>

        <h2>9. Limitation of Liability:
        </h2>
        <p>
          To the fullest extent permitted by law, we disclaim any liability for any direct, indirect,
          incidental, consequential, or special damages arising out of or in connection with your use of
          The App and Platform "M-r-Scool” including but not limited to any errors or omissions in the
          content, or any loss or damage of any kind incurred as a result of the use of any content or
          services made available through the App and the Platform.
        </p>

        <h2>10. Modifications</h2>
     <p>We reserve the right to modify or terminate The App and Platform "M-r-Scool” or change these
      Terms & Conditions at any time without prior notice. It is your responsibility to review the Terms
      & Conditions periodically for any changes. Your continued use of the App after any
      modifications indicates your acceptance of the updated Terms & Conditions.</p>

      <h2>Payment:
      </h2>
      <p>We reserve the right to modify the discount percentage that is deducted for the application and
        platform without referring to you and without informing you personally. Continued use of the
        Application and platform after any modifications indicates your acceptance of the modification.
        If the session is not executed, the money will be transferred to your financial wallet for later use
        on the application and platform, and you have no right to claim it in cash or transfer it to any
        other account.
        </p>
        <h2>11.  Governing Law:        </h2>
        <p>These Terms & Conditions are governed by and construed in accordance with the laws of the
          Arab Republic of Egypt.</p>

        <h2>12. Contact Us</h2>
        <ul>
          <li>Email: {{'info@saudia.services'}}</li>
          <br />
          <li>Phone: 01023036779</li>
        </ul>
        <br />
        <p>
          If you have any questions or concerns regarding these Terms & Conditions or the App and
          Platform, please contact us at
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Faq Question section End -->
