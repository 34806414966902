import { CommonModule, isPlatformBrowser, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ParentService } from 'src/app/shared/Apis/Parent/parent.service';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ChildrenIdService } from '../../choose-student/children-id.service';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-parent-students',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    CustomPipeForImagesPipe,
  ],
  templateUrl: './parent-students.component.html',
  styleUrls: ['./parent-students.component.scss'],
})
export class ParentStudentsComponent {
  MyChildren: any[] = [];
  showModal = false;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('delete') delete!: ElementRef;
  submit: boolean = true;
  envImageUrl = environment.serverFirstHalfOfImageUrl + '/';
  StudentId: any;
  isModalOpen!: boolean;
  constructor(
    private _AuthService: AuthService,
    private childrenIdService: ChildrenIdService,
    private StudentService: StudentService,
    private router: Router,    private seoService: SeoService,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private ParentService: ParentService,
    public modalService: NgbModal, @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.GetMyChildren();
    if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem('ChildName');
    localStorage.removeItem('childrenId');
    this.reloadPage('Parent');
    this._AuthService.ReloadHeader(true);
    this.childrenIdService.setNull();
    }
   this.seo()
  }
  openModal() {
    this.isModalOpen = true;
    this.modalService
      .open(this.content, {
        centered: true, // Centers the modal vertically and horizontally
        ariaLabelledBy: 'modal-basic-title',
        // modalDialogClass:'modal-md'
      })
      .result.then(
        (result) => {
          // Handle close/dismiss result if needed
        },
        (reason) => {
          this.isModalOpen = false;
          // Handle dismiss/cancel reason if needed
        }
      );
  }
  openModalDelete(StudentId: number) {
    this.isModalOpen = true;
    this.StudentId = StudentId;
    this.modalService
      .open(this.delete, {
        centered: true, // Centers the modal vertically and horizontally
        ariaLabelledBy: 'modal-basic-title',
        // modalDialogClass:'modal-md'
      })
      .result.then(
        (result) => {
          // Handle close/dismiss result if needed
        },
        (reason) => {
          this.isModalOpen = false;

          // Handle dismiss/cancel reason if needed
        }
      );
  }

  GetMyChildren() {
    this.ParentService.GetMyChildren().subscribe((res) => {
      this.MyChildren = res.data;
    });
  }
  DeleteStudent() {
    this.submit = false;
    this.StudentService.DeleteStudent(this.StudentId).subscribe(
      (res) => {
        this.GetMyChildren();
        this.isModalOpen = false;
        this.modalService.dismissAll();
        this.submit = true;
               Swal.fire({
            icon: "success",
            title: this.translate.instant("Student deleted successfully"),
            showConfirmButton: false,
            timer: 1500,
          });
      },
      (err) => {
        this.modalService.dismissAll();
        this.submit = true;
      }
    );
  }

  GoToAddStudent() {
    this.modalService.dismissAll();
    this.isModalOpen = false;
    this.router.navigate([`/main/parent/AddStudent`]);
  }
  GoToStudentSubjects(item: any) {
    localStorage.setItem(
      'academicYearId',
      JSON.stringify(item['academicYearEducationLevelId'])
    );

    this.childrenIdService.setChildrenId(item.id);
    this.childrenIdService.setChildrenName(item.name);
    this.childrenIdService.ReloadBackOnStudent(item.id);

    this.router.navigate([`/main/parent/StudentSubjects`, item.id]);
    this.reloadPage('children');
  }
  GoToConnectStudent() {
    this.modalService.dismissAll();
    this.isModalOpen = false;
    this.router.navigate([`/main/parent/ConnectStudent`]);
  }
  reloadPage(roleName: string) {
    localStorage.setItem('role', roleName);
    localStorage.setItem('addchildren', 'false');
    this._AuthService.ReloadSidebar(true);
    // window.location.reload(); // reload the page
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/parentstudent.png'
    );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.ParentStudent.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
