import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import {  IGetMyDocuments } from 'src/app/shared/Model/teacher/documents-information';
import { IManageLessonsMatrials, IModelSearchMaterial } from 'src/app/shared/Model/teacher/manage-lessons-matrials';
import { IGetMyCompletedLessonDetail, IGetTeacherLessonForList, IModeMyLessonSchedualGroup, IModelSchedualGroup, IMyLessonSchedualGroup, ISubjectGroup, ITeacherSubjectAcademicSemesterYearForList } from 'src/app/shared/Model/teacher/subject-group';
import { ITPagination } from 'src/app/shared/Model/Pagination/pagination';



@Injectable({
  providedIn: 'root'
})
export class GroupForLessonService {


  constructor(private http: HttpClient) {}


  GetTeacherLessonForList(id:number):Observable<IGetTeacherLessonForList[]>{

    return this.http.get<IGenericResponse<IGetTeacherLessonForList>>(`${env.Server_URL}TeacherLesson/GetTeacherLessonForList?teacherSubjectAcademicSemesterYearId=${id}`).pipe( map((res) => res.data ))
  }
  GetAllTeacherLessonForList(id:number):Observable<IGetTeacherLessonForList[]>{

    return this.http.get<IGenericResponse<IGetTeacherLessonForList>>(`${env.Server_URL}TeacherLesson/GetAllTeacherLessonForList?teacherSubjectAcademicSemesterYearId=${id}`).pipe( map((res) => res.data ))
  }


  GetTeacherSubjectAcademicSemesterYearForList():Observable<ITeacherSubjectAcademicSemesterYearForList[]>{

    return this.http.get<IGenericResponse<ITeacherSubjectAcademicSemesterYearForList>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/GetTeacherSubjectAcademicSemesterYearForList`).pipe( map((res) => res.data ))
  }

  GetMyLessonSchedualGroup( ModeMyLessonSchedualGroup: IModeMyLessonSchedualGroup):Observable<IMyLessonSchedualGroup[]>{

    return this.http.post<IGenericResponse<IMyLessonSchedualGroup>>(`${env.Server_URL}TeacherSchedule/GetMyLessonSchedualGroup`,ModeMyLessonSchedualGroup).pipe( map((res) => res.data ))
  }

  CreateLessonTeacherScheduleGroup(data:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSchedule/CreateLessonTeacherScheduleGroup`,data)
  }
  DeleteLessonTeacherScheduleGroup(id:number):Observable<IGenericResponseObject<ISubjectGroup>>{
    return this.http.get<IGenericResponseObject<ISubjectGroup>>(`${env.Server_URL}TeacherSchedule/DeleteLessonTeacherScheduleGroup?id=${id}`).pipe( map((res) => res ))
  }

  ///  Completedlessons
  GetMyCompletedLesson( ModeMyLessonSchedualGroup: IModeMyLessonSchedualGroup):Observable<IGenericResponseObject<ITPagination<IMyLessonSchedualGroup>>>
{
    return this.http.post<IGenericResponseObject<ITPagination<IMyLessonSchedualGroup>>>(`${env.Server_URL}TeacherCompletedLesson/GetMyCompletedLesson`,ModeMyLessonSchedualGroup??{}).pipe( map((res) => res))
  }

  /**
   *
   *
   * @param {number} id
   * @return {*}  {Observable<IGenericResponseObject<ITPagination<IMyLessonSchedualGroup>>>}
   * @memberof GroupForLessonService
   */
  GetMyCompletedLessonDetail( id: number):Observable<IGetMyCompletedLessonDetail>
{
    return this.http.get<IGenericResponseObject<IGetMyCompletedLessonDetail>>(`${env.Server_URL}TeacherCompletedLesson/GetMyCompletedLessonDetail?teacherLessonSessionSchedualSlotId=${id}`).pipe( map((res) => res.data))
  }


  CreateExtraSession( CreateExtraSession: any):Observable<IGenericResponse<any>>
  {
      return this.http.post<IGenericResponse<any>>(`${env.Server_URL}TeacherSchedule/CreateExtraSession`,CreateExtraSession).pipe( map((res) => res))
    }
}
