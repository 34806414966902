import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NgOtpInputConfig, NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { IOtp, IVefiryUserPost } from 'src/app/shared/Model/teacher/teacher';
import { ParentService } from 'src/app/shared/Apis/Parent/parent.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-vefiry-parent',
  standalone: true,
  imports: [CommonModule, NgOtpInputModule, TranslateModule,NavTabsComponent],
  templateUrl: './vefiry-parent.component.html',
  styleUrls: ['./vefiry-parent.component.scss']
})
export class VefiryParentComponent  implements OnInit {
  @ViewChild('ngOtpInputRef') ngOtpInputRef: any;
  config: NgOtpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    isPasswordInput: false,
    placeholder: ''};
  public subscription = new Subscription();
  @Input() DataVefiryUser!: IOtp;
  @Input() phoneNumber!: number;
  @Output() Step3: EventEmitter<boolean> = new EventEmitter<boolean>();
  private interval: any;
  protected showOtp: boolean = false;
  protected showSendOtp: boolean = false;
  protected DoneVefiryUser: boolean = true;
  protected otp !:any
  protected isInvalid: boolean = false;
  protected submit: boolean = true;
  protected isOtpInvalid: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
    ,  protected ParentService: ParentService ,private router:Router  ,    private seoService: SeoService,
    private layoutService: LayoutService,  private _AuthService: AuthService  ) {

  }
  ngOnInit(): void {
    //When entering the screen for the first time, the time is calculated based on the time entered by Sign-Up
    this.setInterval()
    this.seo()
  }
  ngAfterViewInit(): void {
    // تهيئة قيمة OTP بعد أن يصبح ngOtpInputRef جاهزًا
    // if (this.ngOtpInputRef && this.DataVefiryUser.otp) {
    //   this.ngOtpInputRef.setValue(this.DataVefiryUser.otp);
    // }
  }
  //When there is a change in input Otp
  protected onOtpChange(otp: any) {
    this.otp=otp
  }

// Send verification of mobile number and code to register
  SendVerify() {
    this.submit = false;
    if(this.otp&&this.otp?.length ==6){
    const Data:IVefiryUserPost={ Mobile: this.phoneNumber ,Otp:this.otp }
    this.subscription.add(this.ParentService.VefiryUser(Data).subscribe((response) => {
      if(response.success) {
      this.DoneVefiryUser=false;
      if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('customerAuthorization', response.data["token"])
      localStorage.setItem('customer_login', JSON.stringify(response.data))
      localStorage.setItem('customer_name', response.data['name'])
      localStorage.setItem("logo", response.data['imagePath']);
      localStorage.setItem("role", response.data['role']);
      localStorage.setItem("roleId", JSON.stringify(response.data['roleId']));
      localStorage.setItem("profileStatusId", JSON.stringify(response.data['profileStatusId']));
      this.router.navigate([`/main/parent/ParentStudents`])
      this._AuthService.ReloadHeader(true);
      }

      this.isInvalid = true;
    } else {
      this.isInvalid = false;
    }
    this.submit = true;
    this.isOtpInvalid =true;
  },err =>
  { this.isInvalid = false;
    this.submit = true;

    this.isOtpInvalid =true;
   this.ngOtpInputRef?.setValue('')}))
     this.submit = true;

   return
  }
else
{
  this.isInvalid = false;
  this.submit = true;
  this.isOtpInvalid =true;
Swal.fire({
  icon: 'warning',
  title: 'warning',
  text: 'The verification number must be 6 digits',
});

}
  }
  navigate(isInvalid:boolean){
    this.Step3.emit(isInvalid)
  }

  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {
    this.subscription.add(this.ParentService.SendOTP(this.phoneNumber).subscribe((res) => {
      this.showSendOtp = false
      if (res.data['otp']) {
        this.showOtp = true;
        // this.InitFormVerify(res)
        this.DataVefiryUser=res.data;
        // if (this.ngOtpInputRef && this.DataVefiryUser.otp) {
        //   this.ngOtpInputRef.setValue(this.DataVefiryUser.otp);
        // }
        this.setInterval();
      }
    }))
  }

  //To calculate the Code time from 90 Seconds to 0 Tansley
  private setInterval(){
    this.interval = setInterval(() => {
      if (this.DataVefiryUser.secondsCount > 0) {
        this.DataVefiryUser.secondsCount--;
      } else {
        this.showSendOtp = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/VefiryUser.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
       this.seoService.setTitle(translations.aVerifyParent.header.Text + ' - ' + translations.Tabs.body.Parent);

      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
