<div  class="flex _ flex-wrap justify-center align-items-center">
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6">
    <div class="d-flex flex-column align-items-center" for="dropzone-file">
      <div class="position-relative text-center 1" for="dropzone-file">
        <img
          onclick="document.getElementById('dropzone-file').click()"
          [src]="imageSrc ? imageSrc : 'assets/images/Profile/Pic.png'"
          class="m-auto rounded-circle Profile-Pic"
        />
        <span
          class="edit"
          onclick="document.getElementById('dropzone-file').click()"
        >
          <img
            src="assets/images/Profile/edit.png"
            class="w-50 rounded-circle"
          />

        </span>
      </div>

      <div class="d-flex flex-column align-items-center" *ngIf="ProfileData">
        <p class="pt-3 text-[16px] sora-bold">{{ fc["Name"].value }}</p>
        <p class="text-[16px] sora-bold">{{ fc["Code"].value }}</p>
        <!-- <ngb-rating [(rate)]="ProfileData.rate " (hover)="hovered=$event" (leave)="hovered=0" max="5"
        readonly="readonly"></ngb-rating> -->
        <div class="flex items-center space-x-1 mb-2">
          <span class="text-yellow-300">{{ ProfileData.rate }}</span>
          <ngb-rating
            [rate]="ProfileData.rate"
            [class.custom-rating]="true"
          ></ngb-rating>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <img src="assets/images/Profile/Info_fill.png" class="" />
            <span class="text-[14px]">{{ ProfileData.statusName }}</span>
          </div>
          <div
            class="col-6 d-flex align-items-center pt-1 pb-1"
            style="border-radius: 4px; background: var(--Border, #ddd)"
          >
            <svg
              *ngIf="ProfileData.statusId == 1"
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
            >
              <circle
                cx="3.5"
                cy="3.5"
                r="3.5"
                transform="rotate(-90 3.5 3.5)"
                fill="#39B002"
              />
            </svg>
            <svg
              *ngIf="ProfileData.statusId != 1"
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
            >
              <circle
                cx="3.5"
                cy="3.5"
                r="3.5"
                transform="rotate(-90 3.5 3.5)"
                fill="#FF0000"
              />
            </svg>
            <span
              class="text-sm ml-2"
              [ngClass]="
                ProfileData.statusId == 1 ? 'text-success' : 'text-danger'
              "
              >{{ ProfileData.statusName }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="w-full sm:px-5 sm:w-1/1 md:w-1/2 xl:w-2/3  md:px-3 md:ps-12"
    *ngIf="ProfileData"
  >
    <form
      class="mb-5 dark:bg-[#1a202c] bg-[#ffffff] max-md:px-1 startPagePadding  rounded-lg shadow dark:border  dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="border-b-2  dark:border-0 dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
      >
        <h3  class="text-[30px] text-left rtl:text-right sora-bold dark:text-white">
          {{ "TeacherEditProfile.header.Title" |translate}}
        </h3>
      </div>
      <div>
        <label
          for="first_name"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "TeacherEditProfile.form.Teacher-Name" | translate }} <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span>
        </label>
        <input
          type="text"
          formControlName="Name"
          [tooltip]="
            fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
              ? ('TeacherEditProfile.form.required' | translate)
              : null
          "
          [ngClass]="{
            'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
            'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
          }"
          id="first_name"
          class="bg-white rtl:rtl-input border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter your name"
          required
        />
      </div>

      <div class="grid gap-6 mb-6 lg:grid-cols-2 md:grid-cols-1 mt-3">
        <div>
          <label
            for="phone"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Mobile-Number" | translate }}
            <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >
          <input
            type="tel"
            id="phone"
            formControlName="MobileX"
            maxlength="11"
            minlength="11"
            class="bg-slate-300 rtl:rtl-input border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your Number"
            [tooltip]="
              fc['MobileX'].invalid &&
              (fc['MobileX'].dirty || fc['MobileX'].touched)
                ? ('TeacherEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
              'class-1': fc['MobileX'].invalid && (fc['MobileX'].dirty || fc['MobileX'].touched),
              'class-2': fc['MobileX'].valid && (fc['MobileX'].dirty || fc['MobileX'].touched),
            }"
          />
          <!-- <label for="success" class="block mb-2  text-green-700 dark:text-green-500">Your name</label>
        <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
        <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
        </div>

        <div>
          <label
            for="email"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{'TeacherEditProfile.form.Email-address' |translate}}</label
          >
          <input
            type="email"
            id="email"
            formControlName="Email" appDisableWritingArabic
            class="bg-white border rtl:rtl-input border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="john.doe@company.com"
            required
            [tooltip]="
            fc['Email'].invalid &&
            (fc['Email'].dirty || fc['Email'].touched)
              ? ('TeacherEditProfile.form.required' | translate)
              : null
          "
[tooltip]="fc['Email'].hasError('email') ? ('The Email is invalid' | translate) : (fc['Email'].errors?.['required'] ? ('This field is required' | translate) : null)"

          [ngClass]="{
              'class-1': fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched),
              'class-2': fc['Email'].valid && (fc['Email'].dirty || fc['Email'].touched),
            }"
          />

        </div>
      </div>
      <div class="grid gap-6 mb-6 lg:grid-cols-3 md:grid-cols-1 mt-3">
        <div>
          <label
            for="Birthdate"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Birthdate" | translate }}</label
          >
          <input
            type="date"
            id="Birthdate"
            [max]="dateConverterPlus18()"
            (keypress)="$event.preventDefault()"
            formControlName="Birthdate"
            class="bg-white border rtl:rtl-input border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="yyyy-MM-dd"
            required
            [tooltip]="
            fc['Birthdate'].invalid &&
            (fc['Birthdate'].dirty || fc['Birthdate'].touched)
              ? ('TeacherEditProfile.form.required' | translate)
              : null
          "
          [ngClass]="{
              'class-1': fc['Birthdate'].invalid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
              'class-2': fc['Birthdate'].valid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
            }"
          />
        </div>

        <div>
          <label
            for="countries"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Gender" | translate }}
            <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >

          <ng-select
          #GenderId
          [selectOnTab]="true"
          formControlName="GenderId"
          class="form-control p-0 rtl:rtl-input"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['GenderId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
          [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['GenderId'].valid &&
            (fc['GenderId'].touched || fc['GenderId'].value)
          "
          [class.is-invalid]="
            fc['GenderId'].invalid && fc['GenderId'].touched
          "
        >
        <ng-option  selected value=""  >{{ "TeacherEditProfile.form.Male/Female" | translate}} </ng-option>
          <ng-option *ngFor="let gender of genders" [value]="gender.id"
            >{{ gender.title  | translate }}
          </ng-option>
        </ng-select>
        </div>

        <div>
          <label
            class="block k mb-2 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Are-you-teacher" | translate }}
            <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >
          <div class="flex mt-4">
            <div class="flex items-center mr-4">
              <input
                id="inline-radio"
                type="radio"
                formControlName="IsTeacher"
                [value]="true"
                name="IsTeacher"
                class="w-4 h-4 rtl:rtl-input text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                [tooltip]="
                  fc['IsTeacher'].invalid &&
                  (!fc['IsTeacher'].dirty || !fc['IsTeacher'].touched)
                    ? ('TeacherEditProfile.form.required' | translate)
                    : null
                "
                [tooltip]="
                  fc['IsTeacher'].invalid &&
                  (fc['IsTeacher'].dirty || fc['IsTeacher'].touched)
                    ? ('TeacherEditProfile.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-checkbox-err': fc['IsTeacher'].invalid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                  'class-radio': fc['IsTeacher'].valid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                }"
              />
              <label
                for="inline-radio"
                class="ml-2 p-0 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
                >{{ "TeacherEditProfile.form.Yes" | translate }}</label
              >
            </div>
            <div class="flex items-center mr-4">
              <input
                id="inline-2-radio"
                type="radio"
                formControlName="IsTeacher"
                [value]="false"
                name="IsTeacher"
                class="w-4 h-4 rtl:rtl-input text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                [tooltip]="
                  fc['IsTeacher'].invalid &&
                  (fc['IsTeacher'].dirty || fc['IsTeacher'].touched)
                    ? ('TeacherEditProfile.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-checkbox-err': fc['IsTeacher'].invalid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                  'class-radio': fc['IsTeacher'].valid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                }"
              />
              <label
                for="inline-2-radio"
                class="ml-2 p-0 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
                >{{ "TeacherEditProfile.form.No" | translate }}</label
              >
            </div>
          </div>
        </div>

        <div>
          <label
            for="countries"
            class="block mb-2 text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Country" | translate }}
            <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >
          <ng-select
            id="countries"
            #countriesId
            [selectOnTab]="true"
            formControlName="CountryId"
            class="form-control p-0"
            (change)="ChangCountry()"
            class="form-control p-0 rtl:rtl-input"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CountryId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
            [isDisabled]="!fc['CountryId'].errors && fc['CountryId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CountryId'].valid &&
              (fc['CountryId'].touched || fc['CountryId'].value)
            "
            [class.is-invalid]="
              fc['CountryId'].invalid && fc['CountryId'].touched
            "
            [ngClass]="{
          'class-1': fc['CountryId'].invalid && (fc['CountryId'].dirty || fc['CountryId'].touched),
          'class-2': fc['CountryId'].valid && (fc['CountryId'].dirty || fc['CountryId'].touched),
        }"
          >
            <ng-option [value]="0">
              {{ "TeacherEditProfile.form.Choose-your-country" | translate }}
            </ng-option>
            <ng-option *ngFor="let country of countries" [value]="country.id"
              >{{ country.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="GovernorateId"
            class="block mb-2 text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Governorate" | translate }}
            <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >
          <ng-select
            id="GovernorateId"
            #GovernorateId
            [selectOnTab]="true"
            formControlName="GovernorateId"
            class="form-control p-0 rtl:rtl-input"
            (change)="ChangGovernorate()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GovernorateId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
            [isDisabled]="
              !fc['GovernorateId'].errors && fc['GovernorateId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GovernorateId'].valid &&
              (fc['GovernorateId'].touched || fc['GovernorateId'].value)
            "
            [class.is-invalid]="
              fc['GovernorateId'].invalid && fc['GovernorateId'].touched
            "
            [ngClass]="{
              'class-1': fc['GovernorateId'].invalid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
              'class-2': fc['GovernorateId'].valid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
            }"
          >
            <ng-option [value]="0">
              {{ "TeacherEditProfile.form.Choose-your-governorate" | translate }}
            </ng-option>
            <ng-option
              *ngFor="let governorate of Governorates"
              [value]="governorate.id"
              >{{ governorate.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-6">
          <label
            for="CityId"
            class="block mb-2 text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.City" | translate }} <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
          >
          <ng-select
            id="CityId"
            #CityId
            [selectOnTab]="true"
            formControlName="CityId"
            class="form-control rtl:rtl-input p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CityId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
            [isDisabled]="!fc['CityId'].errors && fc['CityId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CityId'].valid && (fc['CityId'].touched || fc['CityId'].value)
            "
            [class.is-invalid]="fc['CityId'].invalid && fc['CityId'].touched"
            [ngClass]="{
              'class-1': fc['CityId'].invalid && (fc['CityId'].dirty || fc['CityId'].touched),
              'class-2': fc['CityId'].valid && (fc['CityId'].dirty || fc['CityId'].touched),
            }"
          >
            <ng-option value="">
              {{ "TeacherEditProfile.form.Choose-your-city" | translate }}
            </ng-option>
            <ng-option *ngFor="let city of cityList" [value]="city.id"
              >{{ city.name }}
            </ng-option>
          </ng-select>
        </div>
       
        
      </div>

      <div class="grid gap-6 mb-6 lg:grid-cols-2 md:grid-cols-1 mt-3">
        <div>
          <label
            for="BankId"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherEditProfile.form.Bank" | translate }}</label
          >
          <ng-select
          id="BankId"
          #BankId
          [selectOnTab]="true"
          formControlName="BankId"
          class="form-control rtl:rtl-input p-0"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['BankId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
          [isDisabled]="!fc['BankId'].errors && fc['BankId'].touched"
          containerClass=""
          [searchable]="true"
        >
          <ng-option value="">
            {{ "TeacherEditProfile.form.Choose-your-bank" | translate }}
          </ng-option>
          <ng-option *ngFor="let bank of Banks" [value]="bank.id"
            >{{ bank.name }}
          </ng-option>
        </ng-select>
          <!-- <label for="success" class="block mb-2  text-green-700 dark:text-green-500">Your name</label>
        <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
        <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
        </div>

        <div>
          <label
            for="iban"
            class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{'TeacherEditProfile.form.Iban' |translate}}</label
          >
          <input
          type="text"
          formControlName="Iban"
          id="first_name"
          class="bg-white rtl:rtl-input border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter your bank Iban"
          
        />

        </div> 
      </div>
      <div class="mb-6">
        <label
          for="message"
          class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "TeacherEditProfile.form.Teacher-BIO" | translate }}</label
        >
        <textarea
          id="message"
          formControlName="TeacherBIO"
          rows="4"
          class="block p-2.5 rtl:rtl-input w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          [placeholder]="('TeacherSignUp.form.Tell-us-about-yourself'|translate)"
        ></textarea>
      </div>
      <input
        id="dropzone-file"
        type="file"
        class="hidden rtl:rtl-input"
        (change)="preview($event)"
        formControlName="Image"
      />

      <!-- <button type="submit"  class="text-white bg-blue-700 mb-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{{'Save & Next'|translate}}</button> -->
      <div class="mt-3 flex justify-center">
        <button
          type="submit"
          class="w-75 ProfileBtn text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-3 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          {{ "TeacherEditProfile.form.Update-Profile" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
