import { Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { CommonModule, DatePipe, formatDate, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BookTeacherLessonSessionService } from 'src/app/shared/Apis/Teacher/SubjectInfo/BookTeacherLesson/book-teacher-lesson-session.service';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { IGetCheckOutBookTeacherSession } from 'src/app/shared/Model/teacher/get-check-out-book-teacher-session';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
@Component({
  selector: 'app-booking',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    TranslateModule,
    RouterLink,
    RouterLinkActive
  ],
  providers: [DatePipe,    { provide: LOCALE_ID, useValue: 'ar' },   { provide: LOCALE_ID, useValue: 'en' }],
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent {
  /**
   *
   */


  protected loading: boolean = true;
  protected submit: boolean = true;
  // protected result: number = 0;
  protected TeacherLessonGroupDetail!: IGetCheckOutBookTeacherSession;
  private teacherLessonSessionId!: any;
  private teacherLesson!: any;
  constructor(
    private BookTeacherLessonSessionService: BookTeacherLessonSessionService,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private layoutService: LayoutService,
    private router:Router,private loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId: Object,

  ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (isPlatformBrowser(this.platformId)) {
    this.teacherLesson = localStorage.getItem('BookTeacher');}
    if (this.route.snapshot.paramMap.get('teacherLessonSessionId')) {
      this.route.paramMap.subscribe((params) => {
        this.teacherLessonSessionId = params.get('teacherLessonSessionId');
        this.GetTeacherLessonGroupDetail();
      });
    }

  }

  GetTeacherLessonGroupDetail() {
    let text = '';
    if (this.teacherLesson !== null) {
        this.BookTeacherLessonSessionService.GetCheckOutBookTeacherSession(
            JSON.parse(this.teacherLesson)
        ).subscribe((res: any) => {
            this.TeacherLessonGroupDetail = res;
            const lang = this.layoutService.config.langu;
            this.seoService.loadTranslations(lang).subscribe(translations => {
                this.seoService.setHostUrlIndex();
                this.seoService.setMetaDescription(translations.Home.header.meta_description);
                this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description));

                if (this.TeacherLessonGroupDetail.bookSchedules && this.TeacherLessonGroupDetail.bookSchedules.length) {
                    text = 'Booking.header.titleFull';
                    this.seoService.setTitle(translations.Booking.header.titleFull + '-' + this.TeacherLessonGroupDetail.headerName +'-' + this.TeacherLessonGroupDetail.academicYearName);
                  } else if (this.TeacherLessonGroupDetail.bookSchedules ==null||this.TeacherLessonGroupDetail.bookSchedules.length==0 ||  !this.TeacherLessonGroupDetail.bookSchedules ) {
                    if (this.TeacherLessonGroupDetail.bookType == "Group") {
                        text = 'Booking.header.titleGroup';

                        this.seoService.setTitle(translations.Booking.header.titleGroup +  '-' + this.TeacherLessonGroupDetail.headerName +'-' + this.TeacherLessonGroupDetail.subjectSemesterName +'-' + this.TeacherLessonGroupDetail.academicYearName);

                    } else {
                        text = 'Booking.header.title';
                        this.seoService.setTitle(translations.Booking.header.title +  '-' + this.TeacherLessonGroupDetail.headerName +'-' + this.TeacherLessonGroupDetail.subjectSemesterName +'-' + this.TeacherLessonGroupDetail.academicYearName);

                    }
                }
            });

            // this.calculateResult(res);
        });
    } else {
        // التعامل مع حالة كون teacherLesson null
        console.error('teacherLesson is null');
    }
}


  // calculateResult(lessonGroupDetail: IGetCheckOutBookTeacherSession): number {
  //   if (lessonGroupDetail.price > lessonGroupDetail.currentBalance) {
  //     this.result = lessonGroupDetail.price - lessonGroupDetail.currentBalance;
  //   } else if (lessonGroupDetail.price <= lessonGroupDetail.currentBalance) {
  //     this.result = 0;
  //   }
  //   return this.result;
  // }

  ConfirmPayment() {

    this.submit = false;
    if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem('BookTeacher');}
    this.loaderService.isLoading.next(true);

    var date: any = {};
    if (this.teacherLesson) {
      date = JSON.parse(this.teacherLesson);
    } else if (this.teacherLessonSessionId) {
      date = JSON.parse(this.teacherLessonSessionId);
    }
    this.BookTeacherLessonSessionService.CreateBookTeacherLessonSession(
      date
    ).subscribe(
      (res: any) => {

        if (res.success)
          // this.router.navigate([`/main/Confirm`], {
          //   queryParams: { status: 'success' },
          //   queryParamsHandling: 'merge',
          // });
          this.loaderService.isLoading.next(false);

        if (res?.data?.paymentUrl) {
          window.location.href = res.data.paymentUrl;
        }
        else
      {
        // http://localhost:4200/main/Confirm?responseMessage=Failed
            this.router.navigate([`/main/Confirm`], {
            queryParams: { responseCode: '000' },
            queryParamsHandling: 'merge',
          });

      }

      },
      (err) =>{
        this.router.navigate([`/main/Confirm`], {
          queryParams: { responseMessage: 'Failed' },
          queryParamsHandling: 'merge',
        })
        this.loaderService.isLoading.next(false);}

    );
  }

  convertDurationToHours(duration: number): string {
    const lang = this.layoutService.config.langu; // en | ar
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    if (lang == 'ar') {
      // تنسيق الوقت للعربية
      return `${formattedMinutes} : ${formattedHours} ساعات`;
    } else {
      // تنسيق الوقت للإنجليزية أو اللغات الأخرى
      return `${formattedHours} : ${formattedMinutes} hrs`;
    }
  }
  getFormattedDate(date: string): string {

    const lang = this.layoutService.config.langu;
    const locale =lang === 'ar' ? 'ar-EG' : 'en-US';

    return formatDate(date, 'EEEE dd, MMMM yyyy', locale);

  }

}
