import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { CalenderListSchedual, CalenderSchedual, TeacherLessonSession } from 'src/app/shared/Model/teacher/calender-schedual';
import { ITPagination } from 'src/app/shared/Model/Pagination/pagination';
@Injectable({
  providedIn: 'root'
})
export class TeacherCalenderSchedualService {
  constructor(private http: HttpClient) {}
  GetMyCalenderSchedual():Observable<CalenderSchedual[]>{
    return this.http.get<IGenericResponse<CalenderSchedual>>(`${env.Server_URL}TeacherCalenderSchedual/GetMyCalenderSchedual`).pipe( map((res) => res.data ))

  }


  public GetMyCalenderSchedualPaged(data:any): Observable<ITPagination<CalenderListSchedual>> {
    return this.http.post<ITPagination<CalenderListSchedual>>(`${env.Server_URL}TeacherCalenderSchedual/GetMyCalenderSchedualPaged`,data).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }
  CancelBookLessonSession(id:number):Observable<any>{
    return this.http.get<any>(`${env.Server_URL}TeacherCalenderSchedual/CancelBookLessonSession?TeacherLessonSessionSchedualSlotId=${id}`).pipe( map((res) => res.data ))

  }

  TeacherAttendance(id:number):Observable<any>{
    return this.http.get<any>(`${env.Server_URL}TeacherCalenderSchedual/TeacherAttendance?teacherlessonsessionScheduleSlotId=${id}`).pipe( map((res) => res.data ))

  }

  GetMissedSessionList():Observable<TeacherLessonSession[]>{
    return this.http.get<IGenericResponse<TeacherLessonSession>>(`${env.Server_URL}TeacherSchedule/GetMissedSessionList`).pipe( map((res) => res.data ))

  }


}
