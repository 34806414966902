import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import {  IGetMyDocuments } from 'src/app/shared/Model/teacher/documents-information';
import { IManageLessonsMatrials, IModelSearchMaterial } from 'src/app/shared/Model/teacher/manage-lessons-matrials';
import { IModelSchedualGroup, ISubjectGroup, ITeacherSubjectAcademicSemesterYearForList } from 'src/app/shared/Model/teacher/subject-group';


@Injectable({
  providedIn: 'root'
})
export class SubjectGroupService {


  constructor(private http: HttpClient) {}

  GetMySubjectSchedualGroup( ModelSchedualGroup?: ISubjectGroup):Observable<ISubjectGroup[]>{

    return this.http.post<IGenericResponse<ISubjectGroup>>(`${env.Server_URL}TeacherSchedule/GetMySubjectSchedualGroup`,ModelSchedualGroup??{}).pipe( map((res) => res.data ))
  }
  GetTeacherSubjectAcademicSemesterYearForList():Observable<ITeacherSubjectAcademicSemesterYearForList[]>{

    return this.http.get<IGenericResponse<ITeacherSubjectAcademicSemesterYearForList>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/GetTeacherSubjectAcademicSemesterYearForList`).pipe( map((res) => res.data ))
  }
  GetSubjectSchedualGroupDetailsById(id:number):Observable<ISubjectGroup>{

    return this.http.get<IGenericResponseObject<ISubjectGroup>>(`${env.Server_URL}TeacherSchedule/GetSubjectSchedualGroupDetails?id=${id}`).pipe( map((res) => res.data ))
  }
  GetReviewSubjectSchedualGroup( ModelSchedualGroup: ISubjectGroup):Observable<ISubjectGroup>{

    return this.http.post<IGenericResponseObject<ISubjectGroup>>(`${env.Server_URL}TeacherSchedule/ReviewSubjectSchedualGroup`,ModelSchedualGroup).pipe( map((res) => res.data ))
  }

  CreateSubjectTeacherScheduleGroup(data:ISubjectGroup):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSchedule/CreateSubjectTeacherScheduleGroup`,data)
  }
  DeleteSubjectTeacherScheduleGroup(id:number):Observable<IGenericResponseObject<ISubjectGroup>>{
    return this.http.get<IGenericResponseObject<ISubjectGroup>>(`${env.Server_URL}TeacherSchedule/DeleteSubjectTeacherScheduleGroup?id=${id}`).pipe( map((res) => res ))
  }
}
