import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../Apis/AuthService/auth.service';
import { isPlatformBrowser } from '@angular/common';
export const isLoginParentStudentGuard: CanActivateFn = (route, state) => {
  const loginService = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  if (
    loginService.getToken() ==
    null /* && loginService.getProfileStatusId() == null */
  ) {
    router.navigate([`/authentication/student/login`]);
    if (isPlatformBrowser(platformId))

    localStorage.setItem('role', 'Anonymous');
    return false;
  } else return true;
};
