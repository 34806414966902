import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOtp, IVefiryUserPost } from 'src/app/shared/Model/teacher/teacher';
import { VerificationService } from 'src/app/shared/Apis/Verification/verification.service';
import { NgOtpInputConfig, NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import Swal from 'sweetalert2';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-verify-otp',
  standalone: true,
  imports: [CommonModule, NgOtpInputModule, TranslateModule],
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOTPComponent implements OnInit {
  @ViewChild('ngOtpInputRef') xgOtpInputRef: any;
  config: NgOtpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    isPasswordInput: false,
    placeholder: '',
  };
  public subscription = new Subscription();
  @Input() DataVefiryOtp!: IOtp;
  @Input() phoneNumber!: number;
  @Output() StepVerifyOTP: EventEmitter<[boolean, number]> = new EventEmitter<
    [boolean, number]
  >();
  ngAfterViewInit() {
    // this.xgOtpInputRef?.setValue('123456');
  }
  private interval: any;
  protected showOtp: boolean = false;
  protected showSendOtp: boolean = false;
  protected DoneVefiryOtp: boolean = true;
  protected otp!: any;
  protected isInvalid: boolean = false;
  protected Controller!: string;

  constructor(
    protected _Verification: VerificationService,
    private _SSRService: SSRService,  private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    this.Controller = this._SSRService.Location(2);
  }

  ngOnInit(): void {
    //When entering the screen for the first time, the time is calculated based on the time entered by Sign-Up
    this.setInterval();
    this.seo();

  }

  //When there is a change in input Otp
  protected onOtpChange(otp: any) {
    this.otp = otp;
  }
  // Send verification of mobile number and code to register
  SendVerify() {
    if(this.otp&&this.otp?.length ==6){

    const VerifyOTP: IVefiryUserPost = {
      Mobile: this.phoneNumber,
      Otp: this.otp,
    };
    this.subscription.add(
      this._Verification
        .VerifyOTP(this.Controller, VerifyOTP)
        .subscribe((response) => {
          if (response.success) {
            this.DoneVefiryOtp = false;
            this.isInvalid = true;
            this.navigate(true);
          } else {
            this.isInvalid = false;
            this.xgOtpInputRef?.setValue('');
          }
        }, (err)=>{
        if(err.status)
        this.isInvalid = true;
        this.xgOtpInputRef?.setValue('');

        })
    );
    return
  }
else
{
  Swal.fire({
    icon: 'warning',
    title: 'warning',
    text: 'The verification number must be 6 digits',
  })
}

  }
  navigate(isInvalid: boolean) {
    this.StepVerifyOTP.emit([isInvalid, this.otp]);
  }
  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {
    this.subscription.add(
      this._Verification
        .SendOTP(this.Controller, this.phoneNumber)
        .subscribe((res) => {
          this.showSendOtp = false;
          if (res.data['otp']) {
            this.showOtp = true;
            // this.InitFormVerify(res)
            this.DataVefiryOtp = res.data;
            this.setInterval();
          }
        })
    );
  }

  //To calculate the Code time from 90 Seconds to 0 Tansley
  private setInterval() {
    this.interval = setInterval(() => {
      if (this.DataVefiryOtp.secondsCount > 0) {
        this.DataVefiryOtp.secondsCount--;
      } else {
        this.showSendOtp = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/VefiryUser.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      if(this.Controller== 'teacher')
      this.seoService.setTitle(translations.VerifyOtp.header.Text + ' - ' + translations.Tabs.body.Teacher);
      else if(this.Controller == 'parent')
      this.seoService.setTitle(translations.VerifyOtp.header.Text + ' - ' + translations.Tabs.body.Parent);
      else
      this.seoService.setTitle(translations.VerifyOtp.header.Text + ' - ' + translations.Tabs.body.Student);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }
}
