import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { RegistrationService } from 'src/app/shared/Apis/Teacher/registration.service';
import {
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { VefiryUserComponent } from './vefiry-user/vefiry-user.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MustMatch } from 'src/app/shared/validators/MustMatch';
import { TeacherRegisterSteps } from 'src/app/shared/Enums/teacher-register-steps';
import { IOtp } from 'src/app/shared/Model/teacher/teacher';
import { SubjectInfoComponent } from './subject-info/subject-info.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { DocumentsInformationComponent } from './documents-information/documents-information.component';
import { NavTabsComponent } from '../../tabs/nav-tabs/nav-tabs.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { validateNotZero } from 'src/app/shared/validators/validate-not-zero';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment.prod';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BaseComponent,
    VefiryUserComponent,
    TooltipModule,
    SubjectInfoComponent,
    DocumentsInformationComponent,
    NavTabsComponent,
    NgSelectModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    OnlyNumbersDirective,
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent extends BaseComponent implements OnInit {
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected TeacherSteps = new TeacherRegisterSteps();
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  mobileNumber!: string;
  protected DataVefiryUser!: IOtp;
  protected submit: boolean = true;
  protected changetype: boolean = true;
  protected confirmtype: boolean = true;
  private logoForm!: FormData;
  imageSrc!: string;
  protected selectedFile!: File;
  protected Controller: string = 'left_';
  protected subscription = new Subscription();
  constructor(
    private _Registration: RegistrationService,
    private _fb: FormBuilder,
    private _Lookup: LookupService,
    private _AuthService: AuthService,
    private _router: Router,
    protected _route: ActivatedRoute,
    private _transition: TranslateService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();

    if (isPlatformBrowser(this.platformId)) {
      //For use in other places you need role l example for use in path or src
      this.Controller =
        'left_' + localStorage.getItem('Controller') + '.svg' ?? '';

    this.mobileNumber = this._route.snapshot.paramMap.get('mobile') ?? '';
    if (this.mobileNumber) {
      this.SendOTP();
    }
  }

  }
  ngOnInit(): void {
    this.initiate();
    this.GetCountry();
    this.checkProfileStatus();
    this.seo();
  }

  private checkProfileStatus() {
    if (
      this._AuthService.getToken() != null &&
      this._AuthService.getProfileStatusId() != null
    ) {
      let profileStatusId = JSON.parse(this._AuthService.getProfileStatusId());
      if (profileStatusId == 1) {
        this.TeacherSteps = {
          signUp: false,
          vefiryUser: false,
          subjectInfo: true,
          DocumentsInformation: false,
        };
      } else if (profileStatusId == 2) {
        this.TeacherSteps = {
          signUp: false,
          vefiryUser: false,
          subjectInfo: false,
          DocumentsInformation: true,
        };
      } else if (profileStatusId == 3) this._router.navigate(['/main/home']);
    }
  }

  private initiate() {
    this.insertForm = this._fb.group(
      {
        Name: ['', Validators.required],
        // Birthdate: ['', Validators.required],
        Image: ['', Validators.required],
        CityId: ['', [Validators.required, validateNotZero]],
        GenderId: ['', Validators.required],
        IsTeacher: [true, Validators.required],
        Mobile: [this.mobileNumber, [Validators.required]],
        PasswordHash: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        TeacherImage: [],
        TeacherBIO: [''],
        // remmove
        CountryId: ['', [Validators.required, validateNotZero]],
        GovernorateId: ['', [Validators.required, validateNotZero]],
        Accept: [false, [Validators.required]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [MustMatch('PasswordHash', 'confirmPassword')],
      }
    );
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  GetCountry() {
    this._Lookup
      .GetCountry()
      .subscribe((country) => (this.countries = country));
  }
  protected ChangCountry() {
    this.fc['GovernorateId'].setValue('');
    this.GetGovernorate();
  }
  GetGovernorate() {
    if (this.fc['CountryId'].value != null)
      this._Lookup
        .GetGovernorate(this.fc['CountryId'].value)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    else this.Governorates = [];
  }

  protected ChangGovernorate() {
    this.fc['CityId'].setValue('');
    this.SelectedGovernorate();
  }
  // get from city
  private SelectedGovernorate() {
    // this.fc.fromCityId.setValue(null)
    if (this.fc['GovernorateId'].value !== null) {
      this.subscription.add(
        this._Lookup
          .GetCity(this.fc['GovernorateId'].value)
          .subscribe((cities) => (this.cityList = cities))
      );
    } else this.cityList = [];
  }

  protected onSubmit() {
    this.insertForm.markAllAsTouched();
    if (this.selectedFile && this.selectedFile.name != 'dummy.txt') {
      if (this.insertForm.valid && this.fc['Accept'].value == true) {
        // Perform the submit logic here
        this.insertData();
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this._transition.instant('warning'),
        text: this._transition.instant('The image must not be empty : ('),
      });
      return;
    }
  }

  private insertData() {
    this.loopform();
    this.submit = false;

    this._Registration.AddTeacher(this.logoForm).subscribe(
      (res) => {
        if (res.success == true) {
          this.TeacherSteps = {
            signUp: false,
            vefiryUser: true,
            subjectInfo: false,
            DocumentsInformation: false,
          };
          this.DataVefiryUser = res.data;
          // Swal.fire({
          //   icon: "success",
          //   title: this.translate.instant("Inserted Success"),
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  public handleEvent(event: boolean, name: string) {
    if (!event) {
      this.TeacherSteps = {
        signUp: true,
        vefiryUser: false,
        subjectInfo: false,
        DocumentsInformation: false,
      };
    } else if (name == 'subject' && event == true) {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: true,
        DocumentsInformation: false,
      };
    } else if (event == true && name == 'Documents') {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: false,
        DocumentsInformation: true,
      };
    } else if (event == true && name == 'Previous') {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: true,
        DocumentsInformation: false,
      };
    }
    //this.serviceId = event
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        // delete   this.insertForm.value[key] ;
      } else {
        if (typeof this.insertForm.value[key] !== 'object')
          this.logoForm.append(key, this.insertForm.value[key]);
        else
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });

    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
      this.logoForm.append('TeacherImage', this.selectedFile);
  }

  /**
   *
   *
   * @param {FormControl} control
   * @return {*}
   * @memberof SignUpComponent
   */
  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {
    this.DataVefiryUser = {
      secondsCount: 180,
      otp: 0,
    };
    this.TeacherSteps = {
      signUp: false,
      vefiryUser: true,
      subjectInfo: false,
      DocumentsInformation: false,
    };
    // this.subscription.add(
    //   this._Registration.SendOTP(this.mobileNumber).subscribe((res) => {
    //     if (res.data['otp']) {
    //       this.TeacherSteps = {
    //         signUp: false,
    //         vefiryUser: true,
    //         subjectInfo: false,
    //         DocumentsInformation: false,
    //       };
    //       this.DataVefiryUser = res.data;

    //     }
    //   })
    // );
  }

  preview(files: any) {
    const file = files.target.files[0];
    if (file.length === 0) return;
    var mimeType = file.type;
    const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
    // this.selectedFile = file;
    if (before_ == 'image') {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > 2) {
        // File size exceeds 5MB
        // this.FileSize=false
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
        });
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
        this.imageSrc =
          environment.serverFirstHalfOfImageUrl +
          this._AuthService.GetByName('logo');
      } else {
        this.selectedFile = file;
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    } else {
      this.imageSrc =
        environment.serverFirstHalfOfImageUrl +
        this._AuthService.GetByName('logo');
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

      Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });
      return;
    }
  }
  private seo() {
    this.seoService.setMetaImage('assets/images/MrS-Cool/left_teacher.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(
        translations.auth.SignUp + ' - ' + translations.Tabs.body.Teacher
      );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        translations.Home.header.meta_description
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(
          translations.Home.header.meta_description
        )
      );
      // this.seoService.setMetaTags(translations);
    });
  }
}
