import { HeaderInterceptor } from './header.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { LoaderInterceptor } from './loader.interceptor';

export const httpInterceptorProvider = [
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: LoaderInterceptor,
  //   multi: true,
  // },

 { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
 { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }];
