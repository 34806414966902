<div class="relative overflow-x-auto shadow-md sm:rounded-lg card border-0 bgW dark:bg-[#1a202c] bg-[white]">
  <div
    class="card-header dark:bg-[#1a202c] bg-[white] mainAddressPadding bgW w-full flex align-items-center justify-between border-b-2 rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700"
  >

    <div class="addressIc onSpace flex">
      <div class="pb-3 mx-1">
        <img src="assets/images/Icons/comment.svg" alt="" />
      </div>
      <h3 class="text-2xl text-left font-bold Lama-bold dark:text-white underTitle">
        {{ "TeacherRatesReviews.header.Title" | translate }}
      </h3>

    </div>
    <!-- *ngIf="RatingReviews List?.length as reviews; else loading"> -->
    <div class="pb-3    " *ngIf=" RatingReviews?.length ">
    <span class="Lama-bold text-[24px] ">{{RatingReviews[0].teacherRate}}  {{'of' |translate}} 5 </span> <span class="Lama-bold text-[18px] ">/ {{totalRecords}}</span>  <span class="Lama-Regular text-[13px]  ml-2">{{'Rate' |translate}}</span>
    </div>
  </div>
  <div class="card-body p-0">
    <div
      class="rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
      *ngFor="
        let item of RatingReviews
          | paginate
            : {
                id: 'pagin',
                itemsPerPage: pager.maxResultCount,
                currentPage: pageNumber,
                totalItems: totalRecords
              }
      "
    >
      <div class="border-b-2 startPagePadding">
        <div>
          <ngb-rating
            class="Rate"
            [readonly]="true"
            [rate]="item.teacherLessonRate"
            [max]="5"
          >
          </ngb-rating>
          {{ item.creationDate | date : "dd MMM yyyy" }}
        </div>
        <h5 class="mt-3">{{ item.teacherLessonName }}</h5>
        <p class="mt-3">{{ item.teacherLessonComment }}</p>
      </div>
    </div>
    <div
      *ngIf="RatingReviews.length != 0"
      class="d-flex justify-content-center mt-3"
    >

      <pagination-controls
        id="pagin"
        previousLabel=""
        nextLabel=""
        style="display: flex; justify-content: center"
        (pageChange)="pageChanged((page = $event))"
      ></pagination-controls>

    </div>
  </div>
</div>
