import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { Modal } from 'flowbite';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DocumentsInformationService } from 'src/app/shared/Apis/Teacher/Documents/documents-information.service';
import {
  IDocumentType,
  IGetMyDocuments,
  ITeacherDocumentCreate,
} from 'src/app/shared/Model/teacher/documents-information';
import { Subscription } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { validateNotZero } from 'src/app/shared/validators/validate-not-zero';
import { OnlyNumbersNotToStarZeroDirective } from 'src/app/shared/Directive/only-numbers-not-to-star-zero.directive';
import { NumbersNotToStarZero } from 'src/app/shared/core/appSetting';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-manage-my-documents',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
    OnlyNumbersNotToStarZeroDirective,MatExpansionModule
  ],
  templateUrl: './manage-my-documents.component.html',
  styleUrls: ['./manage-my-documents.component.scss'],
})
export class ManageMyDocumentsComponent implements OnInit {
  public panelOpenState  = false;
  protected insertForm!: FormGroup;
  protected DocumentType!: IDocumentType[];
  protected MyDocuments!: IGetMyDocuments[];
  protected selectedFile: File = new File([], 'dummy.txt', {
    type: 'text/plain',
  }); // Reset the selected file
  private logoForm!: FormData;
  protected subscription = new Subscription();
  protected Url = env.serverFirstHalfOfImageUrl;
  SearchInp: FormControl = new FormControl('', Validators.required);
  TableDeletDocID!: number;
  submit: boolean = true;
  IsFile: boolean = false;
  FileSize: boolean = true;
  modalRef?: BsModalRef;
  @Output() Previous: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private document: DocumentsInformationService,
    private _Lookup: LookupService,
    private _fb: FormBuilder,
    private _transition: TranslateService,
    private _router: Router,
    private modalService: BsModalService,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.seo();
    this.initiate();
    this.GetDocumentType();
    this.GetMyDocuments();
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  private initiate(row?: ITeacherDocumentCreate) {
    this.insertForm = this._fb.group({
      DocumentTypeId: [
        row?.DocumentTypeId || '',
        [Validators.required, validateNotZero],
      ],
      DocumentP: [row?.Document || null],
      Title: [row?.Title || null, Validators.required],
      Order: [row?.order || 1, [Validators.required,Validators.pattern(NumbersNotToStarZero)]],
    });
  }

  protected onSubmit() {
    if (this.MyDocuments && this.MyDocuments.length != 0)
      {
        this.fc['Order'].setValue(this.MyDocuments.length+1)
      }else
      this.fc['Order'].setValue(1)
    if (this.insertForm.valid) {
      // Perform the submit logic here
      if (this.selectedFile && this.selectedFile.name != 'dummy.txt') {
        this.submit = false;
        this.insertData();
      } else {
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Document must not be empty.',
        });
        return;
      }
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
  private GetDocumentType() {
    this._Lookup.GetDocumentType().subscribe((_DocumentType) => {
      this.DocumentType = _DocumentType;
    });
  }

  private GetMyDocuments(SearchInp?: number | null) {
    this.subscription.add(
      this.document
        .GetMyDocuments(SearchInp)
        .subscribe((_GetMyDocuments) => (this.MyDocuments = _GetMyDocuments.data))
    );
  }
  protected onSelectFile(event: any) {

    const file = event.target.files[0];
    this.FileSize = true;
    this.IsFile = true;

    if (file) {
      var mimeType = file.type;
      const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
      if (file.type!="application/x-msdownload" && before_ != 'video') {
        this.selectedFile = file;
        const fileSizeInMB = this.selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
        if (fileSizeInMB > 2) {
          // File size exceeds 5MB
          this.FileSize = false;
          Swal.fire({
            icon: 'warning',
            title: this._transition.instant('warning'),
            text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
          });
          this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
          this.IsFile = false;
        }
      } else {
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
        this.fc['DocumentP'].reset();
        Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });

        this.IsFile = false;
      }
    }
  }

  private insertData() {
    this.submit = false;
    this.loopform();

    this.document.AddTeacherDocument(this.logoForm).subscribe(
      (res) => {
        if (res) {
          this.GetMyDocuments();
          // Display a success message
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Add Succsessfully',
          });
        }
        this.Clear();
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        //this.insertForm.removeControl(key);
      } else {
        if (typeof this.insertForm.value[key] !== 'object' && key !== 'image')
          this.logoForm.append(key, this.insertForm.value[key]);
        else if (typeof this.insertForm.value[key] == 'object')
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });
    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
      this.logoForm.append('Document', this.selectedFile);
  }

  protected DeleteTeacherDocument(id: number) {
    this.submit = false

    this.subscription.add(
      this.document.DeleteTeacherDocument(id).subscribe((res) => {
        if (res.success) {
          this.modalRef?.hide();
          this.SearchInp.reset()
          this.GetMyDocuments();
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Deleted successfully',
          });
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    ));
  }

  protected Clear() {
    this.insertForm.reset();
    this.ClearFile();
  }
  protected ClearFile() {
    this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
    this.fc['DocumentP'].reset();
    this.IsFile = false;
    this.FileSize = true;
  }

  protected navigate() {
    if (this.MyDocuments.length > 0) {
      this._router.navigate([`/main/home`]);
    } else
      Swal.fire({
        icon: 'warning',
        title: 'warning',
        text: ' Must be enter one item at least',
      });
  }

  ChangeSearchInp() {

    if (this.SearchInp.value != null && this.SearchInp.value != '')
      this.GetMyDocuments(this.SearchInp.value);
    else this.GetMyDocuments();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  //  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ManageMyDocuments.header.Title2);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}
}
