import { CommonModule, NgFor } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { IGetTeacherLessonForList } from 'src/app/shared/Model/teacher/subject-group';
  import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { StudentCalenderScheduleService } from 'src/app/shared/Apis/Student/Calender/student-calender-schedule.service';
import { AppointmentForListForStudents, TeacherLessonSessionDetailForStudent } from 'src/app/shared/Model/teacher/calender-schedual';
 @Component({
  selector: 'app-student-calender-details-modle',
  standalone: true,
  imports: [
    CommonModule,
     TranslateModule,
    TooltipModule,
    NgSelectModule,NgFor
  ],
  templateUrl: './student-calender-details-modle.component.html',
  styleUrls: ['./student-calender-details-modle.component.scss']
})
export class StudentCalenderDetailsModleComponent  extends BaseComponent{
  selectteacherlessonsession(arg: IGetTeacherLessonForList) {}

  id!: number;

  protected TeacherLessonForList!: TeacherLessonSessionDetailForStudent[];
  validate: boolean = false;
  update: boolean = false;
  protected submit: boolean = true;

  constructor(
    public modal: NgbModal,

  private _StudentCalenderScheduleService: StudentCalenderScheduleService  ) {
    super()

  }

  ngOnInit(): void {

    if (this.id) {
      this.GetMyCalenderDetail();
      this.update = true;
    } else {
      this.update = false;
    }
  }


  private GetMyCalenderDetail() {
    this._StudentCalenderScheduleService
      .GetMyCalenderDetail(this.id)
      .subscribe((res) => {
        console.log(res)
       this.TeacherLessonForList = res;
      });
  }
  close() {
    this.modal.dismissAll();
  }




}
