import { Component } from '@angular/core';

@Component({
  selector: 'app-open-terms-and-conditions-en',
  templateUrl: './open-terms-and-conditions-en.component.html',
  styleUrls: ['./open-terms-and-conditions-en.component.scss']
})
export class OpenTermsAndConditionsEnComponent {



}
