<div
class=" bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700  startPagePadding"
>
<form class="max-md:px-1 " [formGroup]="SearchForm">
  <div class="grid gap-x-5   grid-cols-1  max-md:mb-3">
    <div class="lg:grid-cols-4 grid  gap-4  max-md:mb-3">




      <div class="mx-2 max-md:mb-2 ">
        <label
        for="startDate"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "GroupForLesson.form.SDate" | translate }}
      </label>
        <input
          type="date" (keypress)="$event.preventDefault()"
          formControlName="dateFrom"
          id="dateFrom"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter start Date"
        />
      </div>

      <div class="mx-2 max-md:mb-2 ">
        <label
        for="startDate"
        class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ "GroupForLesson.form.EDate" | translate }}
      </label>
        <input
          type="date" (keypress)="$event.preventDefault()"
          formControlName="dateTo"
          id="dateTo"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter start Date"
        />
      </div>


    <div  class="text-right align-items-end d-flex lg:col-span-2 justify-end mx-2 max-md:mb-2">
      <button
        type="submit"
        (click)="onSubmit()"
        class="text-white btnFilter bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
      {{ "GroupForLesson.form.Filter" | translate }}

      </button>
    </div>
  </div>

  </div>
</form>
</div>



<div class="card h-100 mt-4 p-3 dark:bg-[#1a202c]">
  <div class="card-header bg-[#ffffff] dark:bg-[#1a202c]">
    <div class="row">
      <div class="col p-0 rtl:text-right bg-[#ffffff] dark:bg-[#1a202c]">
        <h3
          class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >
          {{ "FinanceTeacher.tableCard.PurchasedLessons" | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div class="card-body dark:bg-[#1a202c] h-100 overflow-auto">
    <table
      class="w-full text-sm text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400"
    >
      <thead
        class="Lama-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
      >
        <tr>
          <th scope="col" class="px-6 py-3">
            {{ "FinanceTeacher.body.LessonName" | translate }}
          </th>
          <!-- <th scope="col" class="px-6 py-3">
            {{ "FinanceTeacher.body.Date" | translate }}
          </th> -->
          <th scope="col" class="px-6 py-3">
            {{ "FinanceTeacher.body.Amount" | translate }}
          </th>
          <th scope="col" class="px-6 py-3">
            {{ "FinanceTeacher.body.count" | translate }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
          *ngFor="
          let item of TeacherPurchasedLessons
            | paginate
              : {
                  id: 'pagin',
                  itemsPerPage: pager.maxResultCount,
                  currentPage: pageNumber,
                  totalItems: totalRecords
                };
          let y = index
        "
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
          >
            {{ item.lessonName }}
          </th>
          <td class="px-6 py-4">
            {{ item.profit }}

          </td>
          <td class="px-6 py-4">
            {{ item.count }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center mt-3" *ngIf="TeacherPurchasedLessons&&TeacherPurchasedLessons?.length!=0">
    <pagination-controls

      id="pagin"
      previousLabel=""
      nextLabel=""
      style="display: flex; justify-content: center"
      (pageChange)="pageChanged((page = $event))"
    ></pagination-controls>
  </div>
</div>
