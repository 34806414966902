<div class=" md-pr-12 content_  ">
  <div class="row  bannerStyl justify-content-center align-items-center ">
    <div class="col-lg-2 col-4">

      <div >
        <img
          [src]="
            SubjectDetails?.image
              ? (SubjectDetails?.image | customPipeForImages)
              : 'assets/images/MrS-Cool/subjectDetails.png'
          "
   class="rounded-full w-full ImgSize"
        />
      </div>
    </div>
    <div class="col-8 col-lg-6   p-0">
      <h2
        class="text-[var(--theme-deafult-TeacherWithClick)] text-[14px] lg:text-[30px] Lama-bold"
      [appTextAlign]="SubjectDetails?.name"

      >
        {{ formatString(SubjectDetails?.name)[0] }}
      </h2>
      <h3
      [appTextAlign]="SubjectDetails?.name"

        class="text-[var(--theme-deafult-TeacherWithClick)] Lama-SemiBold text-[12px] md:text-[16px]"
      >
        <!-- <div class="formatted-text"> -->
        {{ formatString(SubjectDetails?.name)[1] }} ,
        <span class="Lama-Regular">
          {{ formatString(SubjectDetails?.name)[2] }}</span
        >
        <span class="Lama-Regular">
          {{ formatString(SubjectDetails?.name)[3] }}</span
        >
        <!-- </div> -->
      </h3>
      <p
      class="m-0 text-[11px] md:text-[14px] Lama-Regular text-[var(--theme-deafult-TeacherWithClick)] h-[65px] max-md:pr-4 overflow-x-auto"
      [appTextAlign]="SubjectDetails?.systemBrief"
    >
      {{ SubjectDetails?.systemBrief }}
    </p>

    </div>
    <div class="col-4 max-md:hidden flex flex-row-reverse">
      <img class="" src="assets/images/MrS-Cool/ObjectsSubjectDetails.png" />
    </div>
  </div>

  <div class="row mb-3 mt-4">
    <div class="addressIconSpace mt-2 mb-2">
      <h5 class="m-0 Lama-bold text-[16px] lg:text-[30px] underTitle">{{'StudentSubjectDetails.header.Title' |translate}}</h5>
      <p *ngIf="SubjectDetails"
        class="m-0 pl-2 text-[10px] lg:text-[14px] Lama-Regular text-[var(--theme-deafult-TeacherWithClick)]"
      >
        {{SubjectDetails?.unitCount}} {{'StudentSubjectDetails.header.Units' |translate}}  • {{SubjectDetails?.lessonsCount}} {{'StudentSubjectDetails.header.Lessons' |translate}}
      </p>
    </div>
  </div>
  <div class="grid gap-4 mb-6 lg:grid-cols-4 md:grid-cols-1 grid-cols-1 cardsSpace">
    <div class="md:col-span-3">
      <div class="card border-0">
        <div class="card-header CardHeader border-0 p-3">
          <h5 class="m-0 text-[14px] lg:text-[30px] Lama-bold">{{'StudentSubjectDetails.header.Lessons' |translate}}</h5>
          <label class="m-0 Lama-Regular text-[10px] lg:text-[14px]"
            >{{ SubjectDetails?.teacherCount }} {{'StudentSubjectDetails.header.available-teachers' |translate}}</label
          >
        </div>
        <div class="card-body CardBody">
          <ng-container *ngIf="SubjectDetails?.getSubjectLessonsDetailsDtoList">
            <div
              class="relative overflow-x-auto sm:rounded-lg"
              ngbAccordion
              *ngFor="
                let item of SubjectDetails.getSubjectLessonsDetailsDtoList;
                let i = index
              "
            >
              <div
                ngbAccordionItem
                class="mt-3 mb-3"
                [collapsed]="accordionStates[i]"
                (click)="toggleAccordion(i)"
                class="relative overflow-x-auto shadow-md sm:rounded-lg"
              >
                <h2 ngbAccordionHeader class="w-full">
                  <button
                    ngbAccordionButton
                    class="custom-accordion-button DirRtl"
                  >
                    <div class="right-content">
                      <label class="DrLtr">
                        {{ item.lessonsCount }} {{'StudentSubjectDetails.header.Lessons' |translate}}</label
                      >
                    </div>
                    <div
                      class="left-content ml-2 text-[12px] md:text-[16px] Lama-SemiBold"
                    >
                      <label>{{ item.unitName }}</label>
                    </div>
                  </button>
                </h2>
                <div
                  ngbAccordionCollapse
                  [ngClass]="{ showBody: !accordionStates[i] }"
                >
                  <div ngbAccordionBody>
                    <ng-container
                      *ngIf="
                        SubjectDetails.getSubjectLessonsDetailsDtoList
                          .unitLessonDtoList
                      "
                    >
                      <table
                        class="w-full text-sm text-left rtl:text-right text-white-500 dark:text-white-400"
                      >
                        <thead
                          class="Lama-Medium text-white   text-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
                        >
                          <tr class="TabelTextColor">
                            <th scope="col " class="  "></th>
                            <th
                              scope="col"
                              class=" Lama-SemiBold "
                            >
                              {{ "StudentSubjectDetails.header.available-teachers" | translate }}
                            </th>
                            <th
                              scope="col"
                              class="  Lama-SemiBold "
                            >
                              {{ "StudentSubjectDetails.header.Min-Price" | translate }}
                            </th>
                            <th
                              scope="col"
                              class="  Lama-SemiBold "
                            >
                              {{ "StudentSubjectDetails.header.Max-Price" | translate }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let SubjectLessonsDetails of item?.unitLessonDtoList
                            "
                            (click)="
                            SubjectLessonsDetails?.availableTeacherCount >0 ?  GoToSubjectOrLessonTeachers(
                                'Lesson',
                                SubjectLessonsDetails.lessonId
                              ) :null
                            "
                            class="bg-whiteLessonhover cursor-pointer dark:bg-[#1a202c] dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              class="px-6 pb-2 min-md:w-1/2  font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                            >
                              <div style="display: flex; align-items: center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <circle
                                    opacity="0.07"
                                    cx="12.5"
                                    cy="12.5"
                                    r="12.5"
                                    fill="#FFAA00"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8 16.5C8 17.3284 8.67157 18 9.5 18H15.5C16.3284 18 17 17.3284 17 16.5V10.5V10.4875C16.9971 10.3873 16.9642 10.2903 16.9055 10.209L16.902 10.2045C16.887 10.1829 16.8703 10.1625 16.852 10.1435L13.852 7.1435C13.833 7.12523 13.8126 7.10851 13.791 7.0935L13.787 7.0905C13.7029 7.03158 13.6027 6.99998 13.5 7H9.5C8.67157 7 8 7.67157 8 8.5V16.5ZM8.99979 16.5006V8.50056C8.99979 8.22442 9.22365 8.00056 9.49979 8.00056H12.9998V10.5006C12.9998 10.7767 13.2236 11.0006 13.4998 11.0006H15.9998V16.5006C15.9998 16.7767 15.7759 17.0006 15.4998 17.0006H9.49979C9.22365 17.0006 8.99979 16.7767 8.99979 16.5006ZM14.0003 9.99947H15.2938L14.0003 8.70647V9.99947ZM10.4999 14.0006C10.4999 14.2767 10.7237 14.5006 10.9999 14.5006H13.9999C14.276 14.5006 14.4999 14.2767 14.4999 14.0006C14.4999 13.7244 14.276 13.5006 13.9999 13.5006H10.9999C10.7237 13.5006 10.4999 13.7244 10.4999 14.0006Z"
                                    fill="#FFAA00"
                                  />
                                  <mask
                                    id="mask0_2203_7957"
                                    style="mask-type: luminance"
                                    maskUnits="userSpaceOnUse"
                                    x="8"
                                    y="7"
                                    width="9"
                                    height="11"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M9.5 18C8.67157 18 8 17.3284 8 16.5V8.5C8 7.67157 8.67157 7 9.5 7H13.5C13.6027 6.99998 13.7029 7.03158 13.787 7.0905L13.791 7.0935C13.8126 7.10851 13.833 7.12523 13.852 7.1435L16.852 10.1435C16.8703 10.1625 16.887 10.1829 16.902 10.2045L16.9055 10.209C16.9642 10.2903 16.9971 10.3873 17 10.4875C17 10.4918 17 10.496 17 10.5V16.5C17 17.3284 16.3284 18 15.5 18H9.5ZM8.99979 8.50056V16.5006C8.99979 16.7767 9.22365 17.0006 9.49979 17.0006H15.4998C15.7759 17.0006 15.9998 16.7767 15.9998 16.5006V11.0006H13.4998C13.2236 11.0006 12.9998 10.7767 12.9998 10.5006V8.00056H9.49979C9.22365 8.00056 8.99979 8.22442 8.99979 8.50056ZM14.0003 9.99947H15.2938L14.0003 8.70647V9.99947ZM10.9999 14.5006C10.7237 14.5006 10.4999 14.2767 10.4999 14.0006C10.4999 13.7244 10.7237 13.5006 10.9999 13.5006H13.9999C14.276 13.5006 14.4999 13.7244 14.4999 14.0006C14.4999 14.2767 14.276 14.5006 13.9999 14.5006H10.9999Z"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2203_7957)">
                                    <rect
                                      x="6.49902"
                                      y="6.50781"
                                      width="12"
                                      height="12"
                                      fill="#FFAA00"
                                    />
                                  </g>
                                </svg>
                                <span
                                  class="ml-2 lg:text-[13px] text-[10px] Lama-Regular"
                                  >{{ SubjectLessonsDetails?.lessonName }}</span
                                >
                              </div>
                            </th>

                            <td
                              class=" pb-2  text-decoration-underline text-[13px] Lama-Regular"
                            >
                              {{ SubjectLessonsDetails?.availableTeacherCount }}
                              Teacher
                            </td>
                            <td
                              class=" pb-2  text-[13px] Lama-Regular"
                            >
                              {{ SubjectLessonsDetails?.minPrice }} {{'StudentSubjectDetails.header.EGP' |translate}}
                            </td>
                            <td
                              class=" pb-2  text-[13px] Lama-Regular"
                            >
                              {{ SubjectLessonsDetails?.maxPrice }} {{'StudentSubjectDetails.header.EGP' |translate}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div>
      <div class="card border-0">
        <div class="card-header CardHeader border-0 p-3">
          <h5 class="m-0 text-[14px] lg:text-[30px] Lama-bold">{{'StudentSubjectDetails.header.Full-Subject' | translate}}</h5>
          <label class="m-0 Lama-Regular text-[10px] lg:text-[14px]"
            >{{ SubjectDetails?.availableTeacherCount }} {{'StudentSubjectDetails.header.available-teachers' |translate}}</label
          >
        </div>
        <div class="card-body bg-white rounded-lg shadow">
          <!-- <p class="m-0 text-[10px] lg:text-[14px] Lama-Regular">
            {{'StudentSubjectDetails.header.Standard-listing-submission-active-for-30-days' |translate}}
          </p> -->
          <div>
            <ul class="p-0">
              <li class="mt-4">
                <div style="display: flex; align-items: center">
                  <img
                    src="assets/images/Icons/Full-Lessons.svg"
                    class=""
                    alt=""
                    srcset=""
                  />

                  <span class="mx-2 text-[10px] lg:text-[14px] Lama-Regular"
                    >{{ SubjectDetails?.lessonsCount }} {{'StudentSubjectDetails.header.Lessons' |translate}}
                  </span>
                </div>
              </li>
              <li class="mt-4">
                <div style="display: flex; align-items: center">
                  <img
                    src="assets/images/Icons/Full-money1.svg"
                    class=""
                    alt=""
                    srcset=""
                  />

                  <span class="mx-2 text-[10px] lg:text-[14px] Lama-Regular"
                    >{{'StudentSubjectDetails.header.Highest-Price' |translate}} {{ SubjectDetails?.maxPrice }}</span
                  >
                </div>
              </li>

              <li class="mt-4">
                <div style="display: flex; align-items: center">
                  <img
                    src="assets/images/Icons/Full-money1.svg"
                    class=""
                    alt=""
                    srcset=""
                  />

                  <span class="mx-2 text-[10px] lg:text-[14px] Lama-Regular"
                    >{{'StudentSubjectDetails.header.Lowest-Price' |translate}} {{ SubjectDetails?.minPrice }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="text-center mt-4 m-auto">
            <button
              class="w-full rounded-lg text-white px-5 py-2.5 bg-[var(--theme-deafult-studentWithClick)] text-[10px] lg:text-[14px] Lama-Regular"
              (click)="   SubjectDetails?.availableTeacherCount >0 ?  GoToSubjectOrLessonTeachers('Subject', SubjectId) :null"
            >
              {{'StudentSubjectDetails.header.View-Details'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
