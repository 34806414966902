<!-- <ng-container>
  <div class="overlay-layer d-flex justify-content-center p-5 h-100">
    <div class="spinner-border text-main-color" role="status"></div>
  </div>
</ng-container> -->
<ng-container>
  <!-- <div class="w-full sm:w-1/1 md:w-8/12 lg:w-9/12 xl:w-2/3"> -->
  <div class="w-full">
    <div id="available-doctor-appointments">
      <div class="global-card">
        <div class="p-3">

        <!--
          <h5 class="text-main-color dectorated-title">
            {{ "doctorDetails.bookSeesionNow" | translate }}:
          </h5> -->
          <div class="text-center mt-3">

            <div class="position-absolute">
              <img
                src="assets/images/Booking/money.svg"
                alt="calendar"
                class="m-auto inline-block"
              />
              <h3
                class="text-[var(--theme-deafult-studentWithClick)] sora-bold inline-block mx-2"
              >
               <span class="text-[var(--theme-deafult-studentWithClick)]">{{ individualCost }} {{'BookingCalender.header.EGP' |translate}}</span>
              </h3>
            </div>
            <img
              src="assets/images/Booking/calendar.svg"
              alt="calendar"
              class="m-auto"
            />
          </div>
        </div>
        <h5
          class="mb-0 text-center py-3 px-3 text-grey-700 d-flex justify-content-center align-items-center gap-3"
        >
          <!-- <button
            class="bg-transparent border-0 text-grey-700 next-previous-appointment"
            (click)="previous()"
            [disabled]="preDisable"
          >
            <i
              class="fas"
              [ngClass]="
                currentLanguage === 'en' ? 'fa-angle-left' : 'fa-angle-right'
              "
            ></i>
          </button> -->
          <span class="vector-bg"
            >{{ selectedDay | date : "EEEE dd, MMMM yyyy" }}
          </span>
          <!-- <button
            class="bg-transparent border-0 text-grey-700 next-previous-appointment"
            (click)="next()"
          >
            <i
              class="fas"
              [ngClass]="
                currentLanguage === 'en' ? 'fa-angle-right' : 'fa-angle-left'
              "
            ></i>
          </button> -->
        </h5>
        <div>
          <div class="grid gap-6 mb-6 md:grid-cols-7 grid-cols-7 mt-3">

            <!-- <ng-container *ngFor="let day of dates; let i = index"> -->
            <div

              [ngClass]="{
                  'col-span-2':i == 0 || i==4,

              }"
              *ngFor="let day of dates; let i = index"
            >
              <div class="d-flex justify-center align-center">
                <button
                  *ngIf="i == 0"

                  class="bg-transparent border-0 text-grey-700 next-previous-appointment md:mr-5"
                  (click)="previous()"
                  [disabled]="preDisable"
                >
                  <i
                    class="fas"
                    [ngClass]="
                      currentLanguage === 'en'
                        ? 'fa-angle-left'
                        : 'fa-angle-right'
                    "
                  ></i>
                </button>
                <div
                  class="min-width-small d-flex flex-column justify-content-center align-items-center border-bottom"
                  (click)="changeBackground($event, i, day)"
                  routerLinkActive="active"
                  [ngClass]="{
                    'select-card': selectedTitleIndex == i,
                    card: selectedTitleIndex != i
                  }"
                >
                  <span class="text-main-color fw-bold fs-6">
                    {{ day | date : "EE" }}
                  </span>
                  <span>
                    {{ day | date : "d" }}
                  </span>
                </div>

                <button
                  *ngIf="i == 4"
                  class="bg-transparent border-0 text-grey-700 next-previous-appointment md:ms-5"
                  (click)="next()"
                >
                  <i
                    class="fas"
                    [ngClass]="
                      currentLanguage === 'en'
                        ? 'fa-angle-right'
                        : 'fa-angle-left'
                    "
                  ></i>
                </button>
              </div>

              <div
                class="d-flex flex-column justify-content-center align-items-center d-none"
              >
                <ng-container *ngFor="let item of appointmentDay">
                  <div
                    class="rounded mb-3 py-2 position-relative w-100"
                    role="button"
                    [ngClass]="
                      item?.available == 0 && item?.selected == true
                        ? 'border-active-appointment'
                        : item?.available == 0
                        ? 'border'
                        : 'border-booked-appointment'
                    "
                  >
                    <div class="d-flex justify-content-center">
                      <div
                        class="px-1 d-flex flex-column justify-content-center align-items-center"
                      >
                        <span class="d-flex flex-column">
                          <i class="pi pi-clock small text-main-color"></i>
                        </span>
                        <ng-container
                          *ngIf="item?.available == 0; else elseTemplate"
                        >
                          <span
                            class="position-absolute top-0 translate-middle badge rounded-pill check-status"
                            [ngClass]="
                              item?.available == 0 && item?.selected == true
                                ? 'bg-main-color'
                                : ''
                            "
                          >
                            <ng-container
                              *ngIf="
                                item?.available == 0 && item?.selected == true
                              "
                            >
                              <i class="fas fa-check small"></i>
                            </ng-container>
                          </span>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <span
                            class="position-absolute top-0 translate-middle badge rounded-pill check-status lock-status"
                          >
                            <i class="fas fa-lock small text-danger"></i>
                          </span>
                        </ng-template>
                      </div>
                      <div class="d-flex">
                        <span class="small">
                          {{ item?.start_end }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center small">
                      <span class="text-main-color">
                        {{ item?.duration }}
                        {{ "general.minute" | translate }}
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
              <ng-template #noAvaliable>
                <div class="text-center">
                  <i class="fas fa-calendar-times fs-3 my-3 text-grey-700"></i>
                </div>
              </ng-template>
            </div>
            <!-- </ng-container> -->
          </div>


        </div>
        <!-- <ng-container>
          <hr class="my-1 mx-3" />
          <div
            class="d-flex align-items-center justify-content-center clearfix m-auto my-3"
          >
            <button
              type="button"
              class="btn btn-solid-main px-5"
              (click)="bookNow()"
            >
              {{ "general.bookNow" | translate }}
            </button>
          </div>
        </ng-container> -->
      </div>

      <div class="global-card py-5 mb-5 m-auto w-full">
        <div class="text-center" *ngIf="data.length == 0">
          <img
            src="assets/images/Booking/clockwise.svg"
            alt="calendar"
            class="m-auto"
          />
          <p class="text-[#868B9F] mt-3">
            {{'BookingCalender.header.No-Available-Times'|translate}} <br />
            {{'BookingCalender.header.On'|translate}}
            <span class="vector-bg"
              >{{ selectedDay | date : "EEEE dd, MMMM yyyy" }}
            </span>
          </p>
        </div>
        <div *ngIf="data.length != 0">
          <div class="text-center">
            <img
              src="assets/images/Booking/clockwiseAvailablesvg.svg"
              alt="calendar"
              class="m-auto"
            />
            <p class="text-[#000] sora-Bold mt-3">
              {{'BookingCalender.header.Available-Times-on'|translate}}
              <span class="vector-bg"
                >{{ selectedDay | date : "EEEE dd, MMMM yyyy" }}
              </span>
            </p>
          </div>
          <owl-carousel-o [options]="InstaSliderConfig" class="no-arrow" dir="ltr">
            <ng-container *ngFor="let item of data">
            <ng-template style="width: 277px !important;" carouselSlide>
                <div class="w-75 m-auto">
                  <div class="logo-block bg-white">
                    <p class="text-[12px] sora-Regular flex justify-between">
                      {{'BookingCalender.header.Start-Time' |translate}} <span> {{ item.fromTime }} </span>
                    </p>
                    <p class="text-[12px] sora-Regular flex justify-between">
                     {{'BookingCalender.header.End-Time' |translate}}<span> {{ item.toTime }}</span>
                    </p>
                    <button
                      type="submit"


                    (click)="checkRoll(item)"
                      class="w-100 text-white bg-[var(--theme-deafult-studentWithClick)] focus:bg-[var(--theme-deafult-studentWithClick)] my-3  hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-[var(--theme-deafult-studentWithClick)]"
                    >
                      {{ "BookingCalender.header.Book-Now" |translate }}
                    </button>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</ng-container>
