import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,Subject,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { SubjectInfo, TeacherSubjectAcademicSemeste } from 'src/app/shared/Model/teacher/subject-info';
import { ILesson, ITeacherBrief, IUnitLessos, ModelPostData } from 'src/app/shared/Model/teacher/lesson';

@Injectable({
  providedIn: 'root'
})
export class TeacherLessonService {
  private flagSubject = new Subject<boolean>();
  flag$: Observable<boolean> = this.flagSubject.asObservable();


  constructor(private http: HttpClient) {}
  setFlagValue(flag: boolean): void {
    this.flagSubject.next(flag);
  }

  CreateOrUpdateTeacherLesson(data?:any):Observable<IGenericResponseObject<ILesson>>{
    return this.http.post<IGenericResponseObject<ILesson>>(`${env.Server_URL}TeacherLesson/CreateOrUpdate`,data)
  }

  GetTeacherLesson(data:ModelPostData ):Observable<IUnitLessos[]>{
    return this.http.post<IGenericResponse<IUnitLessos>>(`${env.Server_URL}TeacherLesson/GetMyLessons`, data).pipe(map((res) => res.data));

  }

  GetTeacherBerifById(id:number):Observable<ITeacherBrief>{
    return this.http.get<IGenericResponseObject<ITeacherBrief>>(`${env.Server_URL}TeacherLesson/GetTeacherBerifById?Id=${id}`).pipe( map((res) => res.data ))
  }

  UpdateTeacherLessonBerif(data:ITeacherBrief):Observable<IGenericResponseObject<ITeacherBrief>>{
    return this.http.post<IGenericResponseObject<ITeacherBrief>>(`${env.Server_URL}TeacherLesson/UpdateTeacherLessonBerif`,data)
  }
  }
