import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../Apis/AuthService/auth.service';

export const pageForParentsOnlyGuard: CanActivateFn = (route, state) => {
  const loginService = inject(AuthService);
  const router = inject(Router);

  if (
    loginService.getToken() !=
    null  && loginService.getRoleId() == '3'
  ) {

    return true;
  } else{
    router.navigate([`/main/home`]);
  return true; }
};
