<div   class="flex _ flex-wrap justify-center align-items-center">
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6">
    <div class="d-flex flex-column align-items-center" for="dropzone-file">
      <div class="position-relative text-center 1" for="dropzone-file">
        <img
        *ngIf="imageSrc? imageSrc :'assets/images/Profile/Pic.png'"

          onclick="document.getElementById('dropzone-file').click()"
          [src]="imageSrc"
          class="m-auto rounded-circle fixed-size-img"
        />
        <span
          class="edit"
          onclick="document.getElementById('dropzone-file').click()"
        >
          <img
            src="assets/images/Profile/edit.png"
            class="w-50 rounded-circle"
          />
        </span>
      </div>

      <div class="text-center">
        <p class="pt-3 text-[16px] Lama-bold text-[#CD2028]">
          {{ fc["Name"].value }}
        </p>
        <p class="text-[16px] Lama-bold text-[#CD2028]">
          {{ fc["Code"].value }}
        </p>
      </div>
    </div>
  </div>

  <div class="w-full sm:px-5 sm:w-1/1 md:w-1/2 xl:w-2/3 md:px-3 md:ps-12">
    <form
      class="p-4 mb-5 max-md:px-1 dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="border-b-2 dark:border dark:bg-[#1a202c] dark:border-gray-700 pt-2 "
      >
        <h3 class="text-[30px] text-left rtl:text-right Lama-bold dark:text-white">
          {{ "ParentProfile.header.Title" | translate }}
        </h3>
        <p class="text-sm/[10px] rtl:text-right text-left dark:text-white">
          {{ "ParentProfile.header.Enter-subtitle-here" | translate }}
        </p>
      </div>
      <div>
        <label
          for="first_name"
          class="block mb-2 text-left rtl:text-right   text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "ParentProfile.form.Parent-Name" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
            >*</span
          >
        </label>
        <input
          type="text"
          formControlName="Name"
          [tooltip]="
            fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
              ? ('ParentProfile.form.required' | translate)
              : null
          "
          [ngClass]="{
              'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
              'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
            }"
          id="first_name"
          class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
        [placeholder]="'EnterYourName' | translate"
          required
        />
      </div>

      <div class="grid gap-6 mt-3 lg:grid-cols-2 md:grid-cols-1 ">
        <div>
          <label
            for="email"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Mobile" | translate }}</label
          >
          <input
            type="tel"
            id="mobile"
            formControlName="MobileX"
            class="bg-[#FAE9EA] border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label
            for="email"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Email-address" | translate }}</label
          >

          <input
            type="email"
            id="email"
            formControlName="Email"
            appDisableWritingArabic
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="john.doe@company.com"
            required
            [tooltip]="
              fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched)
                ? fc['Email'].hasError('email')
                  ? ('The Email is invalid' | translate)
                  : ('TeacherEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
    'class-1': fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched),
    'class-2': fc['Email'].valid && (fc['Email'].dirty || fc['Email'].touched),
  }"
          />
        </div>
      </div>
      <div class="grid gap-6 mt-3 lg:grid-cols-2 md:grid-cols-1 ">
        <div>
          <label
            for="gender"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Gender" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            #GenderId
            [selectOnTab]="true"
            formControlName="GenderId"
            class="form-control p-0"
            dropdownPosition="auto"
            class="form-control p-0 rtl:rtl-input"
            labelForId="validation02"
            [tooltip]="fc['GenderId'].errors?.['required'] ?  ('TeacherEditProfile.form.required' | translate) : null"
            [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GenderId'].valid &&
              (fc['GenderId'].touched || fc['GenderId'].value)
            "
            [class.is-invalid]="
              fc['GenderId'].invalid && fc['GenderId'].touched
            "
          >
            <ng-option selected value=""
              >{{ "TeacherEditProfile.form.Male/Female" | translate }}
            </ng-option>
            <ng-option *ngFor="let gender of genders" [value]="gender.id"
              >{{ gender.title  | translate }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="Birthdate"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Birthdate" | translate }}</label
          >
          <input
            type="date"
            id="Birthdate"
            [max]="dateConverterPlus18()"
            formControlName="Birthdate"
            (keypress)="$event.preventDefault()"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="yyyy-MM-dd"
            required
          />
        </div>
      </div>
      <div class="grid gap-6 mt-3 lg:grid-cols-3 md:grid-cols-1 ">
        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Country" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="countries"
            #countriesId
            [selectOnTab]="true"
            formControlName="CountryId"
    class="form-control p-0 rtl:rtl-input"
            (change)="ChangCountry()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CountryId'].errors?.['required'] ?  ('ParentProfile.form.required' | translate) : null"
            [isDisabled]="!fc['CountryId'].errors && fc['CountryId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CountryId'].valid &&
              (fc['CountryId'].touched || fc['CountryId'].value)
            "
            [class.is-invalid]="
              fc['CountryId'].invalid && fc['CountryId'].touched
            "
            [ngClass]="{
          'class-1': fc['CountryId'].invalid && (fc['CountryId'].dirty || fc['CountryId'].touched),
          'class-2': fc['CountryId'].valid && (fc['CountryId'].dirty || fc['CountryId'].touched),
        }"
          >
            <ng-option value="">
              {{ "ParentProfile.form.Choose-your-Country" | translate }}
            </ng-option>
            <ng-option *ngFor="let country of countries" [value]="country.id"
              >{{ country.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="GovernorateId"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.Governorate" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="GovernorateId"
            #GovernorateId
            [selectOnTab]="true"
            formControlName="GovernorateId"
    class="form-control p-0 rtl:rtl-input"
            (change)="ChangGovernorate()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GovernorateId'].errors?.['required'] ?  ('ParentProfile.form.required' | translate) : null"
            [isDisabled]="
              !fc['GovernorateId'].errors && fc['GovernorateId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GovernorateId'].valid &&
              (fc['GovernorateId'].touched || fc['GovernorateId'].value)
            "
            [class.is-invalid]="
              fc['GovernorateId'].invalid && fc['GovernorateId'].touched
            "
            [ngClass]="{
              'class-1': fc['GovernorateId'].invalid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
              'class-2': fc['GovernorateId'].valid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
            }"
          >
            <ng-option value="">
              {{ "ParentProfile.form.Choose-your-Governorate" | translate }}
            </ng-option>
            <ng-option
              *ngFor="let governorate of Governorates"
              [value]="governorate.id"
              >{{ governorate.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="CityId"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white text-left rtl:text-right "
            >{{ "ParentProfile.form.City" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="CityId"
            #CityId
            [selectOnTab]="true"
            formControlName="CityId"
    class="form-control p-0 rtl:rtl-input"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CityId'].errors?.['required'] ?  ('ParentProfile.form.required' | translate) : null"
            [isDisabled]="!fc['CityId'].errors && fc['CityId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CityId'].valid && (fc['CityId'].touched || fc['CityId'].value)
            "
            [class.is-invalid]="fc['CityId'].invalid && fc['CityId'].touched"
            [ngClass]="{
              'class-1': fc['CityId'].invalid && (fc['CityId'].dirty || fc['CityId'].touched),
              'class-2': fc['CityId'].valid && (fc['CityId'].dirty || fc['CityId'].touched),
            }"
          >
            <ng-option value="">
              {{ "ParentProfile.form.Choose-your-City" | translate }}
            </ng-option>
            <ng-option *ngFor="let city of cityList" [value]="city.id"
              >{{ city.name }}
            </ng-option>
          </ng-select>
        </div>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          (change)="preview($event)"
        />
      </div>
      <!-- <button type="submit"  class="text-white bg-blue-700 mb-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{{'Save & Next'|translate}}</button> -->
      <div class="mt-3 flex justify-center">
        <button
          type="submit"
          [disabled]="!submit"
          [ngClass]="{
            'valid-form': insertForm.valid,
            'invalid-form': insertForm.invalid
          }"
          class="text-[14px] btnEdit Lama-Regular ProfileBtn mt-3  w-75  mb-5 text-white  bg-[var(--theme-deafult-ParentWithClick)] hover:bg-[var(--theme-deafult-ParentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-ParentWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          {{ "ParentProfile.form.Update-Profile" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
