import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { IGetTeacherLessonForList } from 'src/app/shared/Model/teacher/subject-group';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { IStatus } from 'src/app/shared/Model/Country/country';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-alternate-modle',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    NgSelectModule,
  ],
  templateUrl: './add-alternate-modle.component.html',
  styleUrls: ['./add-alternate-modle.component.scss']
})
export class AddAlternateModleComponent extends BaseComponent{
  selectteacherlessonsession(arg: IGetTeacherLessonForList) {}
  public InsertForm!: FormGroup;
  id!: number;
  teacherSubjectAcademicSemesterYearId!: number;
  teacherLessonSessionSchedualSlotId!: number;
  isCancel!: number;
  teacherlessonId!: number;
  protected daysOfWeek!: IStatus[]; // IStatus ==id , name => dayes of week;
  protected TeacherLessonForList!: IGetTeacherLessonForList[];
  validate: boolean = false;
  update: boolean = false;
  protected submit: boolean = true;

  constructor(
    public modal: NgbModal,
    private translate: TranslateService,
    private _Lookup: LookupService,
    private _GroupForLessonService: GroupForLessonService,
    private _formBuilder: FormBuilder
  ) {
    super()
    this.InitForm();
  }

  ngOnInit(): void {
    this.InitForm();
    this.GetDays();
    if (this.teacherSubjectAcademicSemesterYearId) {
      this.GetAllTeacherLessonForList();
      this.update = true;
    } else {
      this.update = false;
    }
  }

  InitForm() {
    this.InsertForm = this._formBuilder.group({
      // dayId: ['', Validators.required],
      timeFrom: ['', Validators.required],
      date: ['', Validators.required],
      duration: [''],
      teacherlessonsessionId: [this.id, Validators.required],
      teacherLessonId: [this.teacherlessonId||'', Validators.required],

      // for reschedule from teacher
      teacherLessonSessionScheduleSlotId: [this.teacherLessonSessionSchedualSlotId||null],
      isCancel : [this.isCancel?true:false||false, Validators.required],
      teacherLessonUpdateId: [
        { value: this.teacherlessonId || '', disabled: true },
      ],

    });
  }
  get fc() {
    return this.InsertForm.controls;
  }
  private GetAllTeacherLessonForList() {
    this._GroupForLessonService
      .GetAllTeacherLessonForList(this.teacherSubjectAcademicSemesterYearId)
      .subscribe((res) => {
        this.TeacherLessonForList = res;
      });
  }
  close() {
    this.modal.dismissAll();
  }

  protected onSubmit() {
    debugger
    if (this.InsertForm.valid) {
      this.fc['duration'].setValue(
        this.TeacherLessonForList.find(
          (x) => x.id == this.fc['teacherLessonId'].value
        )?.groupDuration
      ); // This will return the object with id '45'


      this.CreateAlternateSession(
      );
    } else {
      this.InsertForm.markAllAsTouched();
    }
  }

  private GetDays() {
    this._Lookup.GetDays().subscribe((days) => {
      this.daysOfWeek = days;
    });
  }

  private CreateAlternateSession() {

    this.submit = false;

    this._GroupForLessonService
      .CreateAlternateSession(this.InsertForm.getRawValue())
      .subscribe((res) => {
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: 'Add Succsessfully',
        });
        this.close()
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      })
  }


}
