import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NavTabsComponent } from '../../tabs/nav-tabs/nav-tabs.component';
import { Subscription } from 'rxjs';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { VerificationService } from 'src/app/shared/Apis/Verification/verification.service';
import { IOtp } from 'src/app/shared/Model/teacher/teacher';
import { ForgetPasswordSteps } from 'src/app/shared/Enums/teacher-register-steps';
import { VerifyOTPComponent } from '../verify-otp/verify-otp.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { RouterLink } from '@angular/router';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NavTabsComponent,RouterLink,
    TranslateModule,
    VerifyOTPComponent,ResetPasswordComponent,OnlyNumbersDirective
  ],
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected changetype: boolean = true;
  public subscription = new Subscription();
  protected Controller!: string;
  protected DataVefiryOtp!: IOtp;
  protected ForgetPasswordSteps = new ForgetPasswordSteps();
  protected otp!: number;

  constructor(
    private _fb: FormBuilder,
    private _SSRService: SSRService,
    private _Verification: VerificationService,  private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    this.Controller = this._SSRService.Location(2);
  }
  ngOnInit(): void {
    this.initiate();
    this.seo();
  }
  private initiate() {
    this.insertForm = this._fb.group({
      mobile: ['', [Validators.required]],
      otp:[this.otp]
    });
  }
  protected get fc() {
    return this.insertForm.controls;
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      this.SendOTP();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {
    this.subscription.add(
      this._Verification
        .SendOTP(this.Controller, this.fc['mobile'].value)
        .subscribe((res) => {
          if (res.data['otp']) {
            // this.InitFormVerify(res)
            this.ForgetPasswordSteps = {
              Forget: false,
              vefiryOtp: true,
              ResetPassword: false,
            };
            this.DataVefiryOtp = res.data;
          }
        })
    );
  }

  public handleEvent(event: [boolean, number]) {

    if (event[0] == true) {
      this.ForgetPasswordSteps = {
        Forget: false,
        vefiryOtp: false,
        ResetPassword: true,
      };
      this.fc['otp'].setValue( event[1]);
    }else
    this.ForgetPasswordSteps = {
      Forget: true,
      vefiryOtp: false,
      ResetPassword: false,
    };
    //this.serviceId = event
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/left.svg"');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      if(this.Controller== 'teacher')
      this.seoService.setTitle(translations.ForgetPassword.header.Text + ' - ' + translations.Tabs.body.Teacher);
      else if(this.Controller == 'parent')
      this.seoService.setTitle(translations.ForgetPassword.header.Text + ' - ' + translations.Tabs.body.Parent);
      else
      this.seoService.setTitle(translations.ForgetPassword.header.Text + ' - ' + translations.Tabs.body.Student);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }
}
