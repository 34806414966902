import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ParentService } from 'src/app/shared/Apis/Parent/parent.service';
import {
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import {
  imageFormatValidator,
  validateImageSize,
} from 'src/app/shared/components/CustomValidator/imageFormatValidator';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
// import { VefiryUserComponent } from './vefiry-user/vefiry-user.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MustMatch } from 'src/app/shared/validators/MustMatch';
import { TeacherRegisterSteps } from 'src/app/shared/Enums/teacher-register-steps';
import { IOtp } from 'src/app/shared/Model/teacher/teacher';
// import { SubjectInfoComponent } from './subject-info/subject-info.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
// import { DocumentsInformationComponent } from './documents-information/documents-information.component';
import { NavTabsComponent } from '../../tabs/nav-tabs/nav-tabs.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Router } from '@angular/router';
import { VefiryParentComponent } from './VefiryParent/vefiry-parent/vefiry-parent.component';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-parent-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BaseComponent,
    VefiryParentComponent,
    TooltipModule,
    NavTabsComponent,OnlyNumbersDirective,DisableWeritingArabicDirective, NgSelectModule
  ],
  templateUrl: './parent-sign-up.component.html',
  styleUrls: ['./parent-sign-up.component.scss']
})
export class ParentSignUpComponent extends BaseComponent implements OnInit  {
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected TeacherSteps = new TeacherRegisterSteps();
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected DataVefiryUser!: IOtp;
  protected submit: boolean = true;
  protected changetype: boolean = true;
  protected confirmtype: boolean = true;

  private logoForm!: FormData;

  protected subscription = new Subscription();

  constructor(
    private ParentService: ParentService,
    private _fb: FormBuilder,
    private _Lookup: LookupService,
    private _AuthService: AuthService,
    private _router: Router,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.initiate();
    this.GetCountry();


    this.checkProfileStatus();
    this.seo();
  }



  private checkProfileStatus() {

    if (
      this._AuthService.getToken() != null &&
      this._AuthService.getProfileStatusId() != null
    ) {
      let profileStatusId = JSON.parse(this._AuthService.getProfileStatusId());
      if (profileStatusId == 1) {
        this.TeacherSteps = {
          signUp: false,
          vefiryUser: false,
          subjectInfo: true,
          DocumentsInformation: false,
        };
      } else if (profileStatusId == 2) {
        this.TeacherSteps = {
          signUp: false,
          vefiryUser: false,
          subjectInfo: false,
          DocumentsInformation: true,
        };
      } else if (profileStatusId == 3) this._router.navigate(['/main/home']);
    }
  }

  private initiate() {
    this.insertForm = this._fb.group(
      {
        Name: ['', Validators.required],
        Birthdate: ['', Validators.required],
        Email: ['',[Validators.required, Validators.email]],
        CityId: ['', Validators.required],
        GenderId: ['', Validators.required],
        Mobile: ['', [Validators.required]],
        PasswordHash: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        Accept: [false, [Validators.required]],
        // remmove
        GovernorateId: ['', Validators.required],
        CountryId: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [MustMatch('PasswordHash', 'confirmPassword')],
      }
    );
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  GetCountry() {

    this._Lookup
      .GetCountry()
      .subscribe((country) => (this.countries = country));
  }
  protected ChangCountry() {

    this.fc['GovernorateId'].setValue('');
    this.fc['CityId'].setValue('');
    this.GetGovernorate();
  }
  GetGovernorate() {
    if (this.fc['CountryId'].value !== null && this.fc['CountryId'].value != '')
      this._Lookup
        .GetGovernorate(this.fc['CountryId'].value)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    else
    {
      this.Governorates = [];
      this.cityList = [];
    }
  }

  protected ChangGovernorate() {
    this.fc['CityId'].setValue('');
    this.SelectedGovernorate();
  }
  // get from city
  private SelectedGovernorate() {
    // this.fc.fromCityId.setValue(null)
    if (   this.fc['GovernorateId'].value !== null &&
    this.fc['GovernorateId'].value !== '') {
      this.subscription.add(
        this._Lookup
          .GetCity(this.fc['GovernorateId'].value)
          .subscribe((cities) => (this.cityList = cities))
      );
    } else this.cityList = [];
  }

  protected onSubmit() {
    if (this.insertForm.valid && this.fc['Accept'].value == true) {
      // Perform the submit logic here
      this.insertData();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private insertData() {
    this.loopform();
    this.submit = false;
    const data ={
      "name": this.insertForm.get('Name')?.value,
      "email": this.insertForm.get('Email')?.value,
      "birthdate": this.insertForm.get('Birthdate')?.value,
      "genderId": this.insertForm.get('GenderId')?.value,
      "cityId": this.insertForm.get('CityId')?.value,
      "mobile":  this.insertForm.get('Mobile')?.value,
      "passwordHash": this.insertForm.get('PasswordHash')?.value,
    }
    this.ParentService.AddParent(data).subscribe(
      (res) => {
        if (res.success == true) {
          this.TeacherSteps = {
            signUp: false,
            vefiryUser: true,
            subjectInfo: false,
            DocumentsInformation: false,
          };
          this.DataVefiryUser = res.data;
          // Swal.fire({
          //   icon: "success",
          //   title: this.translate.instant("Inserted Success"),
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  public handleEvent(event: boolean, name: string) {
    if (!event) {
      this.TeacherSteps = {
        signUp: true,
        vefiryUser: false,
        subjectInfo: false,
        DocumentsInformation: false,
      };
    } else if (name == 'subject' && event == true) {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: true,
        DocumentsInformation: false,
      };
    } else if (event == true && name == 'Documents') {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: false,
        DocumentsInformation: true,
      };
    } else if (event == true && name == 'Previous') {
      this.TeacherSteps = {
        signUp: false,
        vefiryUser: false,
        subjectInfo: true,
        DocumentsInformation: false,
      };
    }
    //this.serviceId = event
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        // delete   this.insertForm.value[key] ;
      } else {
        if (typeof this.insertForm.value[key] !== 'object')
          this.logoForm.append(key, this.insertForm.value[key]);
        else
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });

    // if (this.logo != null) {
    //   this.logoForm.append("logoUrl", this.logo);
    // }
  }

  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/left_parent.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.auth.SignUp + ' - ' + translations.Tabs.body.Parent);

      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
