<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 py-2"
>
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
    <div class="mr-2">
      <img src="assets/images/MrS-Cool/info_.png" alt="" />
    </div>
    <div>
      <h3 class="text-[30px] text-left Lama-bold dark:text-white">
        {{ "ManageLessonMatrials.header.Title" | translate }}
      </h3>

    </div>
  </div>
  <div class="grid gap-8 mb-6 lg:grid-cols-5 md:grid-cols-1 px-6 mt-3">
    <div>
      <label
        for="countries"
        class="block mb-2 text-[16px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageLessonMatrials.form.Education-Type" | translate }}
       </label
      >
      <p
        class="Lama-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ TeacherLessonMaterialBasicData?.educationTypeName }}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 text-[16px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageLessonMatrials.form.Education-Level" | translate }}
        </label
      >
      <p
        class="Lama-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ TeacherLessonMaterialBasicData?.educationLevelName }}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 text-[16px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageLessonMatrials.form.Academic-Year" | translate }}
        </label
      >
      <p
        class="Lama-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ TeacherLessonMaterialBasicData?.academicYearName }}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 text-[16px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageLessonMatrials.form.Subject" | translate }}
        </label
      >
      <p
        class="Lama-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ TeacherLessonMaterialBasicData?.subjectSemesterYearName }}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 text-[16px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageLessonMatrials.form.LessonName" | translate }}
        </label
      >
      <p
        class="Lama-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
      >
        {{ TeacherLessonMaterialBasicData?.lessonName }}
      </p>
    </div>
  </div>
</div>

<!-- <div class="w-full sm:w-1/1 md:w-1/1  xl:w-1/1 pt-5 px-3 md:ps-12  "> -->





<!-- filter  -->

<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="  bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 py-10 p-6"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
        <mat-panel-description>

          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left Lama-bold dark:text-white underTitle ">
                  {{ "ManageLessonMatrials.header.filter-LessonMatrials" |translate}}
            </h2>
          </div>
        </div>

        </mat-panel-description>
      </mat-expansion-panel-header>


      <div
    >
      <form class="max-md:px-1">
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
          <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
            <div class="mb-2 w-full">
              <input
                type="text"
                [formControl]="SearchInp"
                id="SearchInp"
                [tooltip]="'Enter material title' | translate"
                class="bg-white border w-full border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter material title "
              />
            </div>
            <div class="w-full">
              <ng-select
                id="SearchInpMaterialType_"
                #EducationType
                [selectOnTab]="true"
                [formControl]="SearchInpMaterialType"
                class="form-control p-0"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="
                  'ManageLessonMatrials.form.Search-In-Material-Type' | translate
                "
                containerClass=""
              >
                <ng-option value="">
                  {{
                    "ManageLessonMatrials.form.Choose-your-material-type"
                      | translate
                  }}
                </ng-option>
                <ng-option *ngFor="let item of MaterialType" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="text-right">
            <button
              type="submit"
              (click)="ChangeSearchInp()"
              class="ml-auto text-white bg-[var(--theme-deafult-TeacherWithClick)] focus:outline-none focus:ring-0 focus:ring-gray-300 rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
            >
              {{ "ManageLessonMatrials.form.Filter" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>




    </mat-expansion-panel>
  </mat-accordion>

<!-- filter  -->




<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff]  cardsSpace p-0 dark:border  dark:border-[var(--theme-deafult-TeacherWithClick)] mt-3 p-6"
    style="border-radius:15px; "
    (opened)="panelOpenState = true"
    [expanded]="true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >

        <mat-panel-description>
          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3  class=" text-left Lama-bold dark:text-white underTitle">
              {{ "ManageLessonMatrials.header.Title3" |translate }}
            </h3>
          </div>
        </div>


        </mat-panel-description>
      </mat-expansion-panel-header>


      <div
    >
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
        *ngIf="TeacherLessonMaterialDtos"
      >

        <div
          class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
        >
          <table
            class="w-full text-sm text-left rtl:text-right text-white-500 dark:text-white-400"
          >
            <thead
              class="Lama-Medium text-white bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageLessonMatrials.form.Type" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageLessonMatrials.form.Title-Arabic" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageLessonMatrials.form.Title-English" | translate }}
                </th>

                <th scope="col" class="px-6 py-3">
                  {{ "ManageLessonMatrials.form.Document/URL" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageLessonMatrials.form.Action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of TeacherLessonMaterialDtos; let y = index"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  {{ item.materialTypeName }}
                </th>
                <td class="px-6 py-4">
                  {{ item.name }}
                </td>
                <td class="px-6 py-4">
                  {{ item.nameEn }}
                </td>

                <td class="px-6 py-4">
                  <a
                    [href]="item.materialUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-[var(--theme-deafult-TeacherWithClick)]"
                  >
                    <i class="fa-solid fa-paperclip mr-2"></i>
                    {{ "ManageLessonMatrials.form.Click-for-Prev" | translate }}</a
                  >
                </td>
                <td class="px-6 py-4 d-flex justify-between">
                  <a
                  (click)="Edit(item); form.expanded=true"
                    class="font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img src="assets/images/Icons/edit.png" alt="" srcset=""
                  /></a>
                  <a
                    (click)="TableDeletMaterialID = item.id; openModal(template)"
                    class="font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/delete.png"
                      alt="Icons delete"
                      srcset=""
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div> -->

      <ng-template #template>
        <div class="" style="position: relative; top: 50%">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div>
              <div class="p-6 text-center deleteModalW">
                <div class="text-center ">
                  <img
                    src="assets/images/MrS-Cool/delete-Icon.png"
                    class="m-auto mt-3 p-3 w-14"
                    alt=""
                  />
                </div>
                <h6
                class="mb-3 mt-3 text-lg text-[var(--theme-deafult-TeacherWithClick)]  font-normal  dark:text-gray-400 "
                >
                  {{
                    "ManageLessonMatrials.form.Are-you-sure-you-want-to-delete"
                      | translate
                  }}
                </h6>
                <button
                  (click)="
                    modalRef?.hide(); DeleteTeacherMaterial(TableDeletMaterialID)
                  "
                  type="button"
                  class="mt-4 btnMargin text-white bg-[var(--theme-deafult-TeacherWithClick)]  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-3 text-center mr-2"
                >
                  {{ "ManageLessonMatrials.form.Yes" | translate }}
                </button>
                <button
                  (click)="modalRef?.hide()"
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm Lama-Regular px-5 py-3 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  {{ "ManageLessonMatrials.form.No" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>


    </mat-expansion-panel>
  </mat-accordion>



<!-- filter  -->

<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="  bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 py-2 mt-5"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
    #form
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
        <mat-panel-description>
          <div
          class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="mr-3">
            <img src="assets/images/Icons/add-file-button_.png" alt="" />
          </div>
          <div>
            <h3 class="text-[30px] text-left Lama-bold dark:text-white">
              {{ "ManageLessonMatrials.header.Title2" | translate }}
            </h3>

          </div>
        </div>
        </mat-panel-description>
      </mat-expansion-panel-header>


      <div
    >
      <form
        class="mb-5 max-md:px-1"
        [formGroup]="insertForm"
        (ngSubmit)="onSubmit()"
      >

        <div class="grid lg:grid-cols-4 grid-cols-1 gap-4 px-6">
          <div class="col-span-3">
            <div class="grid lg:grid-cols-3 grid-cols-1 gap-3 px-6">
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                  >{{ "ManageLessonMatrials.form.Title-Arabic" | translate }}

                </label>
                <input
                  type="text"
                  appDisableWritingEnglish
                  formControlName="name"
                  [tooltip]="
                    fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)
                      ? ('ManageLessonMatrials.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                      'class-1': fc['name'].invalid && (fc['name'].dirty || fc['name'].touched),
                      'class-2': fc['name'].valid && (fc['name'].dirty || fc['name'].touched),
                    }"
                  id="first_name"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your material title"
                  required
                />
              </div>
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                  >{{ "ManageLessonMatrials.form.Title-English" | translate }}

                </label>
                <input
                  type="text"
                  appDisableWritingArabic
                  formControlName="nameEn"
                  [tooltip]="
                    fc['nameEn'].invalid &&
                    (fc['nameEn'].dirty || fc['nameEn'].touched)
                      ? ('ManageLessonMatrials.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                    'class-1': fc['nameEn'].invalid && (fc['nameEn'].dirty || fc['nameEn'].touched),
                    'class-2': fc['nameEn'].valid && (fc['nameEn'].dirty || fc['nameEn'].touched),
                  }"
                  id="first_name"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your material title"
                  required
                />
              </div>
              <div>
                <label
                  for="countries"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white pt-3"
                  >{{ "ManageLessonMatrials.form.Type" | translate }}
                 </label
                >
                <ng-select
                  id="Education_"
                  #EducationType
                  [selectOnTab]="true"
                  [formControlName]="'materialTypeId'"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fc['materialTypeId'].errors?.['required'] ?  ('ManageLessonMatrials.form.required' | translate) : null"
                  [isDisabled]="
                    !fc['materialTypeId'].errors && fc['materialTypeId'].touched
                  "
                  containerClass=""
                  [class.is-valid]="
                    fc['materialTypeId'].valid &&
                    (fc['materialTypeId'].touched || fc['materialTypeId'].value)
                  "
                  [class.is-invalid]="
                    fc['materialTypeId'].invalid && fc['materialTypeId'].touched
                  "
                  [ngClass]="{
            'class-1': fc['materialTypeId'].invalid && (fc['materialTypeId'].dirty || fc['materialTypeId'].touched),
            'class-2': fc['materialTypeId'].valid && (fc['materialTypeId'].dirty || fc['materialTypeId'].touched),
          }"
                >
                  <ng-option value="">
                    {{
                      "ManageLessonMatrials.form.Choose-your-material-type"
                        | translate
                    }}
                  </ng-option>
                  <ng-option *ngFor="let item of MaterialType" [value]="item.id">
                    {{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-span-3">
              <div class="grid lg:grid-cols-3 grid-cols-1 gap-3 px-6">
                <!-- <div class="d-flex flex-wrap align-items-end justify-between"> -->
                <div class="col-span-2">
                  <label
                    for="materialUrl"
                    class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                    >{{ "ManageLessonMatrials.form.Url" | translate }}
                  </label>
                  <div class="d-flex align-items-center justify-between">
                    <input
                      type="text"
                      formControlName="materialUrl"
                      [tooltip]="
                        fc['materialUrl'].invalid &&
                        (fc['materialUrl'].dirty || fc['materialUrl'].touched)
                          ? ('ManageLessonMatrials.form.required' | translate)
                          : null
                      "
                      [tooltip]="
                        fc['materialUrl'].hasError('pattern')
                          ? ('Enter link valid' | translate)
                          : null
                      "
                      [ngClass]="{
                        'pe-none': IsFile && FileSize,
                        'class-1': fc['materialUrl'].invalid && (fc['materialUrl'].dirty || fc['materialUrl'].touched),
                                      'class-2': fc['materialUrl'].valid && (fc['materialUrl'].dirty || fc['materialUrl'].touched),
                                    }"
                      id="materialUrl"
                      class="border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter your material URL"
                    />
                    <a
                      *ngIf="fc['materialUrl'].value"
                      [href]="fc['materialUrl'].value"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="fa-solid fa-expand text-3xl pl-3"></i
                    ></a>
                  </div>
                </div>
                <div
                  class="text-right col-span-1 d-flex align-items-end justify-between"
                >
                  <button
                    type="submit"
                    [disabled]="!submit"
                    class="text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  >
                    {{
                      fc["id"].value !== null
                        ? ("ManageLessonMatrials.form.Update" | translate)
                        : ("ManageLessonMatrials.form.Save" | translate)
                    }}
                  </button>
                  <button
                    type="button"
                    class="text-[var(--theme-deafult-TeacherWithClick)] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    (click)="Clear()"
                  >
                    {{ "ManageLessonMatrials.form.Clear" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

        <div class=" mt-4">
            <div class="flex items-center justify-center w-full relative"
            [ngClass]="{
              'pe-none': !IsFile && fc['materialUrl'].value
              }">
              <div class="bottom-1 border-slate-950  absolute top-0 right-0"       *ngIf="IsFile && FileSize" >

                <img
                src="assets/images/MrS-Cool/delete-Icon.png"
                class="m-auto p-4 w-16 pt-5"
                alt="delete-Icon"
                (click)="ClearFile()"
              />
                </div>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full    rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-center w-full ">
                  <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full  border-[#DDDDDD] border-[2px]   rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div
                      *ngIf="!IsFile && FileSize"
                      class="flex flex-col items-center justify-center"
                    >

                      <img
                      src="assets/images/MrS-Cool/currentDocument.png"
                      alt=""
                    />
                      <p
                        class=" mt-2 Lama-Regular text-[10px] md:text-[12px] text-[var(--theme-deafult-TeacherWithClick)]  text-center"
                      >
                        {{'DocumentsInformation.form.Files-supported' | translate}}: PDF, JPG, PNG, TIFF, GIF,<br />
                        Pdf, docx, doc, WORD<br />
                        {{'DocumentsInformation.form.Maximum-size-is' | translate}} : 2MB
                      </p>
                      <p class=" Lama-Regular  Lama-Regular text-[11px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] ">
                        {{'DocumentsInformation.form.Drag&Drop-Files' | translate}}
                      <p
                        class="py-3 px-5 mr-2   text-[7px] md:text-[11px] Lama-Regular text-white focus:outline-none bg-[var(--theme-deafult-TeacherWithClick)] rounded-full border  dark:hover:bg-gray-700"
                      >
                        {{'DocumentsInformation.form.Choose-Files' | translate}}
                      </p>
                    </div>

                    <div
                      *ngIf="IsFile && FileSize"
                      class="flex flex-col items-center justify-center pt-5 pb-6 "
                    >


                      <p class="bottom-1 border-slate-950 inFile">
                        <img
                          src="assets/images/MrS-Cool/documents-1.png"
                          alt=""
                        />
                      </p>
                      <p
                        class="Lama-Medium text-gray-500 dark:text-[#DDDDDD] text-center"
                      >
                         {{'DocumentsInformation.form.Your-file-uploaded' | translate}} <br />
                        {{'DocumentsInformation.form.successfully' | translate}}
                      </p>
                    </div>
                    <div
                      *ngIf="!FileSize"
                      class="flex flex-col items-center justify-center pt-5 pb-6"
                    >
                      <p class="bottom-1 border-slate-950 inFile-fill">
                        <img
                          src="assets/images/MrS-Cool/documents-folder.png"
                          alt="documents"
                        />
                      </p>
                      <p
                        class="Lama-Medium text-[10px] md:text-[14px] text-red-500 dark:text-red-400 text-center"
                      >
                      {{'DocumentsInformation.form.Your-file-cant-be-uploaded' | translate}}, <br />
                        {{'DocumentsInformation.form.Maximum-size-is' | translate}} : 2MB
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      class="hidden"
                      (change)="onSelectFile($event)"
                      formControlName="materialFi"
                    />
                  </label>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  (change)="onSelectFile($event)"
                  formControlName="materialFi"
                />
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>




    </mat-expansion-panel>
  </mat-accordion>

<!-- filter  -->
