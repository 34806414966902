

<ul
class="w-full sm:px-0 md:pr-12   xl:startPagePadding sm:w-1/1 md:w-1/1 xl:w-1/1   grid grid-cols-3 ... -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
>
<li class="max-md:w-1/3"  >
  <a
    [routerLink]=" !loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='2'?'/authentication/student/' + actionPart:null"
    routerLinkActive="active"
    [ngClass]="{ 'active-student': Controller == 'student'}"
    class="inline-block  items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
  >
    <div class="">
      <img
        [src]="
          Controller == 'student'
            ? 'assets/images/Auth/Student-active.png'
            : 'assets/images/Auth/Student-deactive.png'
        "
        class="mb-6 mx-auto imgSize"
        alt="student icon"
        srcset=""
      />
    </div>
    <span

      class="btnStyle 2xl:w-[209px]  xl:w-[150px] sm:w-[100px] rounded-lg border student-border border-[#caaf79] text[15px] sora-Medium hover:border-transparent text-[#CAAF79] bg-white max-md:px-2 max-md:py-1 px-16 py-2"
      >{{ "Tabs.body.Student" | translate }}</span
    >
  </a>
</li>
<li class="max-md:w-1/3"
  [routerLink]="!loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='3'?'/authentication/parent/' + actionPart:null"
>
  <a
  [routerLink]="!loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='3'?'/authentication/parent/' + actionPart:null"

    routerLinkActive="active"
    [ngClass]="{ 'active-Parent': Controller == 'parent' }"
    class="inline-block  items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
    aria-current="page"
  >
    <img
      [src]="
        Controller == 'parent'
          ? 'assets/images/Auth/Parent-active.png'
          : 'assets/images/Auth/Parent-deactive.png'
      "
      alt="Parent icon"
      class="mb-6 mx-auto imgSize"
      srcset=""
    />
    <span

  [routerLink]="!loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='3'?'/authentication/parent/' + actionPart:null"
  class="btnStyle 2xl:w-[209px]  xl:w-[150px] sm:w-[100px] rounded-lg border  parent-border border-[#CB8B8E] text[15px] sora-Medium hover:border-transparent  text-[#CB8B8E] bg-white max-md:px-2 max-md:py-1 px-16 py-2"
    >
      {{ "Tabs.body.Parent" | translate }}</span
    >
  </a>
</li>
<li class="md:mr-5 md:pr-4 max-md:w-1/3" >
  <a
    [routerLink]="!loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='1'?'/authentication/teacher/' + actionPart:null"
    routerLinkActive="active"
    [ngClass]="{ 'active-Teacher': Controller == 'teacher' }"
    class="inline-block  items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
  >
    <img
      [src]="
        Controller == 'teacher'
          ? 'assets/images/Auth/Teacher-active.png'
          : 'assets/images/Auth/Teacher-deactive.png'
      "
      alt="Teacher icon"
      class="mb-6 mx-auto imgSize"
      srcset=""
    />
    <span
    [routerLink]="!loginService.getToken() || loginService.getToken() && loginService.getRoleId() =='1'?'/authentication/teacher/' + actionPart:null"

        class="btnStyle 2xl:w-[209px]  xl:w-[150px] sm:w-[100px] rounded-lg border teacher-border text[15px] sora-Medium border-[#868b9f] hover:border-transparent text-[#868b9f] bg-white max-md:px-2 max-md:py-1 px-16 py-2"
    >
      {{ "Tabs.body.Teacher" | translate }}</span
    >
  </a>
</li>
</ul>
