
<div class="ps-20 mb-5 max-md:px-1 ">
    <div class="m-auto pb-3 px-20  bg-white rounded-lg shadow dark:border  dark:bg-[#1a202c] dark:border-gray-700 " *ngIf="DoneVefiryOtp">

      <img src="assets/images/MrS-Cool/VefiryUser.png"  class="w-1/6 m-auto py-3">
      <h3 class="text-3xl sora-bold dark:text-white">{{"VerifyOtpStudent.header.Text" |translate}}</h3>
      <p>{{"VerifyOtpStudent.header.Subtitle" |translate}} <br>{{"VerifyOtpStudent.header.for-verification" |translate}}</p>
      <ng-otp-input
      dir="ltr"
      class="text-[var(--theme-deafult-TeacherWithClick)]"
      (keyup.enter)="SendVerify()"
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event)"
      [class.invalid-border]="isInvalid"
      [config]="config"></ng-otp-input>
      <!-- <pre class="mt-3">{{DataVefiryOtp.otp}}</pre> -->

      <div class=" my-3">
        <button
        [ngClass]="{
          'bg-[var(--theme-deafult-TeacherWithClick)]':ngOtpInputRef.otpForm.status== 'VALID' && ngOtpInputRef.currentVal !=null,
          'bg-[#DDDFE5]': ngOtpInputRef.currentVal !=null,
          'text-[#868B9F]': ngOtpInputRef.currentVal !=null,
        }"
        type="submit" [disabled]="!submit"
        (click)="SendVerify()"
        (keyup.enter)="SendVerify()"
        class="w-full   text-white btn btn-danger  font-medium rounded-lg text-sm px-5 py-2.5 text-center ">{{ "VerifyOtpStudent.form.Verify" | translate }}</button>
      </div>
      <div class="text-center mt-4 mb-4" >

        <p class="text-sm font-light text-gray-500 dark:text-gray-950" *ngIf="!showSendOtp">
          {{ "VerifyOtpStudent.form.OTP-Not-Received" | translate }} <a    class="font-medium text-red-600 hover:underline dark:text-red-500"> {{ DataVefiryOtp.secondsCount + " " }}{{ " " }} {{ "VerifyOtpStudent.form.secondly" | translate }}</a>
      </p>
        <p class="text-sm font-light text-gray-500 dark:text-gray-950" *ngIf="showSendOtp">
          {{ "VerifyOtpStudent.form.OTP-Not-Received" | translate }} <a  (click)="SendOTP()" class="font-medium text-red-600 hover:underline dark:text-red-600">{{ "VerifyOtpStudent.form.Resend-Code" | translate }}</a>
      </p>
        <p class="text-sm font-light text-gray-500 dark:text-gray-950" (click)="navigate(false)" >
          {{"VerifyOtpStudent.form.Mobile-number-not-correct" | translate }} <a (click)="navigate(false)"   class="font-medium text-red-600 hover:underline dark:text-red-600">  {{ "VerifyOtpStudent.form.Modify-Now" | translate }}</a>
      </p>
      </div>
    </div>

    <div class="m-auto pb-3   bg-white rounded-lg shadow dark:border  dark:bg-[#1a202c] dark:border-gray-700 " *ngIf="!DoneVefiryOtp">
      <img src="assets/images/MrS-Cool/VefiryUserSucc.png"  class="w-1/6 m-auto py-3">
      <h3 class="text-3xl sora-bold dark:text-white">{{"VerifyOtpStudent.form.Your-Accounts-Added" | translate }}</h3>

      <div class=" my-3 mx-5">
        <button type="submit"  class="w-full   text-white btn btn-danger  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
        (click)="navigate(true)"
        (keyup.enter)="navigate(true)" >
          {{ "VerifyOtpStudent.form.Go-To-Home" | translate }}
        </button>
      </div>

    </div>
  </div>
