<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 py-2"
>
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
    <div class="mr-2">
      <img src="assets/images/MrS-Cool/info_.png" alt="" />
    </div>
    <div>
      <h3  class="text-[30px] text-left Lama-bold dark:text-white">
        {{ "CompletedLessonDetail.header.Add" | translate }}
      </h3>
    </div>
  </div>
  <div class="grid gap-2 mb-6 lg:grid-cols-2 md:grid-cols-1 px-6 mt-3">
    <div   class="d-flex justify-end col-span-2">
      <a (click)="navigate(MyCompletedLessonDetails.teacherLessonId)" class="d-flex justify-between text-decoration-none  px-3 py-2 bg-[var(--theme-deafult-TeacherWithClick)] rounded " >
        <p class="my-1  text-sm pr-2 dark:text-white text-white ">
        {{ "CompletedLessonDetail.header.ManageLessonMaterial" | translate }}
        </p>
        <img class="rounded-t-lg" src="assets/images/Icons/arrow.svg" alt="" />

    </a>
      </div>
  <div class="grid gap-8 mb-6 lg:grid-cols-1 md:grid-cols-1 px-6 mt-3">


    <div>
      <label
        for="countries"
        class="block mb-2 Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">
        {{ "CompletedLessonDetail.thead.Subject" | translate }}
               </label>
      <p  *ngIf="MyCompletedLessonDetails?.subjectName !==null"class="text-xl  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{MyCompletedLessonDetails.subjectName}}</p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2  Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >       {{ "CompletedLessonDetail.thead.SubjectBrief" | translate }}</label
      >
      <p class="Lama-Medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{MyCompletedLessonDetails.subjectBrief}}</p>

    </div>
  </div>
  <div class="grid gap-8 mb-6 lg:grid-cols-1 md:grid-cols-1 px-6 mt-3">
    <div>
      <label
        for="countries"
        class="block mb-2 Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >
        {{ "CompletedLessonDetail.thead.Lesson" | translate }}
        </label
      >
      <p class="Lama-Medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{MyCompletedLessonDetails.lessonName}}</p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 Lama-bold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >
        {{ "CompletedLessonDetail.thead.LessonBrief" | translate }}

        </label
      >
      <p class="Lama-Medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{MyCompletedLessonDetails.lessonBrief}}</p>

    </div>

  </div>
</div>
</div>


<div
class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 p-6"
>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
    <div class="mr-2">
      <img
      src="assets/images/MrS-Cool/setting.png" alt="" />
    </div>
    <div>
      <h3  class="text-[30px] text-left Lama-bold dark:text-white">

        {{ "Student / Parent Info" | translate }}

      </h3>

    </div>
  </div>
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
  <div class="grid gap-6 mb-6 lg:grid-cols-3 md:grid-cols-1 px-6 mt-3">

    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-[#1a202c] dark:border-gray-700" *ngFor="let item of MyCompletedLessonDetails?.teacherCompletedLessonStudentList">
      <a href="#">
          <!-- <img class="rounded-t-lg" src="assets/images/image-1.jpg" alt="" /> -->
          <img class="rounded-t-lg w-full h-72"

      [src]="
      item?.studentImageUrl
        ? (item.studentImageUrl
          | customPipeForImages)
        : 'assets/images/MrS-Cool/lesson.svg'
    "

          alt="student Image" />
      </a>
      <div class="p-3">
      <div class="d-flex justify-between">
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 ">        {{ "CompletedLessonDetail.List.Card.Attendance" | translate }}</p>
          <div
          [ngClass]="{
            'bg-red-700':  item.studentAttended ==false,
            'bg-green-600': item.studentAttended ==true
          }"
            class="inline-block w-4 h-4 mr-2"
          >
        </div>
        </div>
          <!-- 95 -->

          <a  class="d-flex justify-between">
              <h5 class="mb-2 text-lg Lama-bold tracking-tight text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"> {{ item.studentName }}   </h5>
              <img (click)="navigateWithQueryParams(item)" class="rounded-t-lg" src="assets/images/Icons/message.svg" alt="" />

          </a>
          <p class="border-2 border-[var(--theme-Border)] ... "></p>

          <a   class="d-flex justify-between" *ngIf="item.parentName">
            <h5 class="mb-2 text-lg Lama-bold tracking-tight text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"> <span class="Lama-Regular mx-1">{{ "CompletedLessonDetail.List.Card.Parent" | translate }}</span> <span class="Lama-bold">{{ item.parentName }}</span>     </h5>
            <img class="rounded-t-lg" src="assets/images/Icons/message.svg" alt="" />

        </a>

      </div>
  </div>
  <div>


  </div>
  </div>
</div>
<!-- </div> -->


</div>
