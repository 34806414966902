

<div class="trxt-center youtube ">

  <iframe class="lg:w-[500px]" height="315" src="https://www.youtube.com/embed/ztN3ktcomS8?si=RLYsJ9Zyynu2rrPn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  <p class="text-black text-center text-[16px] Lama-Regular  my-2">

      {{ "ManageMySchedule.header.Explanation" | translate }}

  </p>
</div>
<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
    style="border-radius: 15px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >
      <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left Lama-bold dark:text-white underTitle">
              {{ "ManageMySchedule.header.filter-MySchedul" | translate }}
            </h2>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div
      class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
    >
      <form class="max-md:px-1 startPagePadding" [formGroup]="SearchForm">
        <!-- <div class="md:flex flex-wrap align-items-end justify-between"> -->
        <div class="grid lg:grid-cols-4 grid-cols-1 gap-4 px-6 max-md:mb-3">
          <div class="mx-2">
            <label
              for="first_name"
              class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >{{ "ManageMySchedule.form.Day" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold dark:text-white"
                >*</span
              >
            </label>

            <ng-select
              id="dayId"
              [selectOnTab]="true"
              formControlName="dayId"
              class="form-control p-0"
              dropdownPosition="auto"
              labelForId="validation02"
              [searchable]="true"
            >
              <ng-option value="">
                {{ "ManageMySchedule.form.Choose-day" | translate }}</ng-option
              >
              <ng-option *ngFor="let item of daysOfWeek" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="mx-2">
            <label
              for="first_name"
              class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >{{ "ManageMySchedule.form.Start-Date" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                >*</span
              >
            </label>
            <input
              type="date"
              (keypress)="$event.preventDefault()"
              formControlName="fromStartDate"
              id="first_name"
              class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your material title"
            />
          </div>

          <div>
            <label
              for="countries"
              class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white pt-3"
              >{{ "ManageMySchedule.form.End-Date" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                >*</span
              ></label
            >
            <input
              type="date"
              (keypress)="$event.preventDefault()"
              id="first_name"
              formControlName="toEndDate"
              class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div class="text-right mx-2 flex align-items-end">
            <button
              type="submit"
              (click)="ChangeSearchInp()"
              class="ml-auto text-white bg-[var(--theme-deafult-TeacherWithClick)] btnFilter focus:outline-none focus:ring-0 focus:ring-gray-300 rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
            >
              {{ "ManageMySchedule.form.Filter" | translate }}
            </button>
          </div>
        </div>

        <!-- </div> -->
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
    style="border-radius: 15px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="true"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >
      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3 class="text-left Lama-bold dark:text-white underTitle">
              {{ "ManageMySchedule.header.Title2" | translate }}
            </h3>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div>
      <div
        class="relative overflow-x-auto dark:bg-[#1a202c]"
        *ngIf="manageSchedule"
      >
        <div
          class="d-flex align-items-center startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6"
        >
          <table
            class="w-full text-sm text-left dark:bg-[#1a202c] rtl:text-right text-white-500 dark:text-white-400"
          >
            <thead
              class="Lama-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.Day" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.Start-Date" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.End-Date" | translate }}
                </th>

                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.Start-Time" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.End-Time" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMySchedule.form.Action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of manageSchedule; let y = index"
              >
                <th
                  scope="row"
                  class="px-6 py-4 whitespace-nowrap dark:text-white"
                >
                  {{ item.dayName }}
                </th>
                <td class="px-6 py-4">
                  {{ item.fromStartDate | date }}
                </td>
                <td class="px-6 py-4">
                  {{ item.toEndDate | date }}
                </td>
                <td class="px-6 py-4">
                  {{ item.fromTime | slice : 0 : 5 }}
                </td>
                <td class="px-6 py-4">
                  {{ item.toTime | slice : 0 : 5 }}
                </td>

                <td class="px-6 py-4">
                  <a
                    (click)="
                      TableDeletMyScheduleID = item.id; openModal(template)
                    "
                    class="font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/delete.png"
                      class="w-4"
                      alt="Icons delete"
                      srcset=""
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div> -->

      <ng-template #template>
        <div class="" style="position: relative; top: 50%">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div>
              <div class="pt-5 pb-2 px-12 text-center">
                <div class="text-center pb-6">
                  <img
                    src="assets/images/MrS-Cool/delete-Icon.png"
                    class="m-auto p-3"
                    alt=""
                  />
                </div>
                <h3
                  class="mb-3 text-[12px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400"
                >
                  {{
                    "ManageMySchedule.form.Are-you-sure-you-want-to-delete"
                      | translate
                  }}
                </h3>
                <button
                  (click)="
                    modalRef?.hide();
                    DeleteTeacherSchedule(TableDeletMyScheduleID)
                  "
                  [disabled]="!submit"
                  type="button"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:-[var(--theme-deafult-TeacherWithClick)] focus:ring-0 focus:outline-none focus:ring-red-300 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] rounded-lg Lama-Medium text-[12px] md:text-[14px] inline-flex items-center px-5 h-[3.8em] text-center mr-2"
                >
                  {{ "ManageMySchedule.form.Yes" | translate }}
                </button>
                <button
                  (click)="modalRef?.hide()"
                  type="button"
                  class="bg-white ml-2 hover:bg-gray-100 focus:ring-0 focus:outline-none focus:ring-transparent rounded-lg Lama-Medium text-[12px] md:text-[14px] px-5 h-[3.8em] hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  {{ "ManageMySchedule.form.No" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
    style="border-radius: 15px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >
      <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/Icons/add-file-button_.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3 class="text-left Lama-bold dark:text-white underTitle">
              {{ "ManageMySchedule.header.Title" | translate }}
            </h3>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div>
      <form
        class="mb-5 max-md:px-1"
        [formGroup]="insertForm"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="grid lg:grid-cols-4 grid-cols-1 gap-4 px-6 startPagePadding"
        >
          <div class="col-span-5">
            <div class="grid lg:grid-cols-3 grid-cols-1 gap-3 px-6">
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                  >{{ "ManageMySchedule.form.Day" | translate }}
                  <span
                    class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                    >*</span
                  >
                </label>

                <ng-select
                  id="dayId"
                  #teacherSubjectAcademicSemesterYearSearch
                  [selectOnTab]="true"
                  formControlName="dayId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fc['dayId'].errors?.['required'] ?  ('ManageMySchedule.form.required' | translate) : null"
                  [isDisabled]="!fc['dayId'].errors && fc['dayId'].touched"
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fc['dayId'].valid &&
                    (fc['dayId'].touched || fc['dayId'].value)
                  "
                  [class.is-invalid]="
                    fc['dayId'].invalid && fc['dayId'].touched
                  "
                  [ngClass]="{
              'class-1': fc['dayId'].invalid && (fc['dayId'].dirty || fc['dayId'].touched),
              'class-2': fc['dayId'].valid && (fc['dayId'].dirty || fc['dayId'].touched),
            }"
                >
                  <ng-option value="">
                    {{
                      "ManageMySchedule.form.Choose-day" | translate
                    }}</ng-option
                  >
                  <ng-option *ngFor="let item of daysOfWeek" [value]="item.id">
                    {{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                  >{{ "ManageMySchedule.form.Start-Date" | translate }}
                  <span
                    class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                    >*</span
                  >
                </label>
                <input
                  type="date"
                  (keypress)="$event.preventDefault()"
                  formControlName="fromStartDate"
                  [min]="expirationDateNow"
                  [tooltip]="
                    fc['fromStartDate'].invalid &&
                    (fc['fromStartDate'].dirty || fc['fromStartDate'].touched)
                      ? ('ManageMySchedule.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                'class-1': fc['fromStartDate'].invalid && (fc['fromStartDate'].dirty || fc['fromStartDate'].touched),
                'class-2': fc['fromStartDate'].valid && (fc['fromStartDate'].dirty || fc['fromStartDate'].touched),
              }"
                  id="first_name"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your material title"
                  required
                />
              </div>
              <div>
                <label
                  for="countries"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white pt-3"
                  >{{ "ManageMySchedule.form.End-Date" | translate }}
                  <span
                    class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                    >*</span
                  ></label
                >
                <input
                  type="date"
                  (keypress)="$event.preventDefault()"
                  [min]="fc['fromStartDate'].value"
                  formControlName="toEndDate"
                  [tooltip]="
                    fc['toEndDate'].invalid &&
                    (fc['toEndDate'].dirty || fc['toEndDate'].touched)
                      ? ('ManageMySchedule.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                  'class-1': fc['toEndDate'].invalid && (fc['toEndDate'].dirty || fc['toEndDate'].touched),
                  'class-2': fc['toEndDate'].valid && (fc['toEndDate'].dirty || fc['toEndDate'].touched),
                }"
                  id="first_name"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your material title"
                  required
                />
              </div>
            </div>
            <div class="col-span-3">
              <div class="grid lg:grid-cols-3 grid-cols-1 gap-3 px-6">
                <!-- <div class="d-flex flex-wrap align-items-end justify-between"> -->
                <div class="col-span-1">
                  <label
                    for="first_name"
                    class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                    >{{ "ManageMySchedule.form.Start-Time" | translate }}
                    <span
                      class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                      >*</span
                    >
                  </label>
                  <input
                    type="time"
                    formControlName="fromTime"
                    [tooltip]="
                      fc['fromTime'].invalid &&
                      (fc['fromTime'].dirty || fc['fromTime'].touched)
                        ? ('ManageMySchedule.form.required' | translate)
                        : null
                    "
                    [ngClass]="{
                'class-1': fc['fromTime'].invalid && (fc['fromTime'].dirty || fc['fromTime'].touched),
                'class-2': fc['fromTime'].valid && (fc['fromTime'].dirty || fc['fromTime'].touched),
              }"
                    id="first_name"
                    class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your material title"
                    required
                  />
                </div>
                <div class="col-span-1">
                  <label
                    for="first_name"
                    class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                    >{{ "ManageMySchedule.form.End-Time" | translate }}
                    <span
                      class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                      >*</span
                    >
                  </label>
                  <input
                    type="time"
                    formControlName="toTime"
                    [tooltip]="
                      fc['toTime'].invalid &&
                      (fc['toTime'].dirty || fc['toTime'].touched)
                        ? ('ManageMySchedule.form.required' | translate)
                        : null
                    "
                    [ngClass]="{
                'class-1': fc['toTime'].invalid && (fc['toTime'].dirty || fc['toTime'].touched),
                'class-2': fc['toTime'].valid && (fc['toTime'].dirty || fc['toTime'].touched),
              }"
                    id="first_name"
                    class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your material title"
                    required
                  />
                </div>
                <div
                  class="text-right col-span-1 d-flex align-items-end justify-end"
                >
                  <button
                    type="submit"
                    [disabled]="!submit"
                    [ngClass]="{
                      'valid-form': insertForm.valid,
                      'invalid-form': insertForm.invalid
                    }"
                    class="text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 btnWithForm focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  >
                    {{ "ManageMySchedule.form.Save" | translate }}
                  </button>
                  <button
                    type="button"
                    class="text-[var(--theme-deafult-TeacherWithClick)] bg-white border btnWithForm border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    (click)="Clear()"
                  >
                    {{ "ManageMySchedule.form.Clear" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
