<div class="container h-100">
 <h4 class="dark:text-white">{{"Finance.header.Finance" | translate}}</h4>
 <label class="dark:text-white">{{ "Finance.header.FinanceSubtitle" | translate }}</label>
  <div class="card   mt-4 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard" >
  <label class="currentBalanceTxt text-[#1B2651] dark:text-white">{{"Finance.balanceCard.CurrentBalance" | translate}}</label>
  <div class="grid gap-2 mb-12 lg:grid-cols-2 mt-5">

    <div class="text-center par40">
      <h1 class="BalancNumb text-[#1B2651] dark:text-white">5500 LE</h1>
      <label calss="dark:text-white">Till 16 Oct 2023</label>
    </div>
  </div>
</div>

  <div class="card  h-100 mt-4 p-3 dark:bg-[#1a202c] ">
      <div class="card-header bg-[#ffffff] dark:bg-[#1a202c]">
          <div class="row">
              <div class="col  p-0 rtl:text-right bg-[#ffffff] dark:bg-[#1a202c]">
                  <h3 class="sora-bold text-[var(--theme-deafult-TeacherWithClick)]  dark:text-white">{{ "Finance.tableCard.PurchasedLessons" | translate }}</h3>
              </div>



          </div>
      </div>
      <div class="card-body dark:bg-[#1a202c] h-100">
          <table class="w-full text-sm text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400">
              <thead class="sora-Medium text-white dark:bg-[#1a202c]   bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                         {{'Finance.body.Title' |translate}}
                      </th>
                      <th scope="col" class="px-6 py-3">
                        {{'Finance.body.Type' |translate}}

                      </th>
                      <th scope="col" class="px-6 py-3">
                        {{'Finance.body.Order' |translate}}

                      </th>
                      <th scope="col" class="px-6 py-3">
                        {{'Finance.body.file' |translate}}


                      </th>
                      <th scope="col" class="px-6 py-3">
                        {{'Finance.body.Action' |translate}}

                      </th>
                  </tr>
              </thead>
              <tbody>

                  <tr class="bg-white  border-b-2  dark:bg-[#1a202c] dark:border-gray-700"
                  *ngFor="let item of MyTickets; let y = index"
                  >
                      <th scope="row" class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white">
                        {{item.title}}
                      </th>
                      <td class="px-6 py-4">
                        {{item.documentTypeName}}

                      </td>
                      <td class="px-6 py-4">
                        {{item.order}}
                      </td>
                      <td class="px-6 py-4">
                        <!-- <a [href]="Url+item.documentPath" target="_blank" rel="noopener noreferrer" class="text-[var(--theme-deafult-TeacherWithClick)]"> <i class="fa-solid fa-paperclip"></i> Click for Prev</a> -->
                      </td>
                      <td class="px-6 py-4"    >
                          <a class="font-medium text-red-600 red:text-blue-500 hover:underline"  >   <img src="assets/images/Icons/delete.png" alt="Icons delete" srcset=""
              /></a>
                      </td>
                  </tr>

              </tbody>
          </table>

      </div>
  </div>
  <div class="card  h-100 mt-4 p-3">
    <div class="card-header bg-white">
        <div class="row">
            <div class="col text-start p-0  ">
                <h3 class="sora-bold text-[var(--theme-deafult-TeacherWithClick)] ">{{ "Finance.tableCard.PurchasedSubjects" | translate }}</h3>
            </div>



        </div>
    </div>
    <div class="card-body  h-100">
        <table class="w-full text-sm text-left text-white-500 dark:text-white-400">
            <thead class="sora-Medium text-white   bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                       {{'Finance.body.Title' |translate}}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{'Finance.body.Type' |translate}}

                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{'Finance.body.Order' |translate}}

                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{'Finance.body.file' |translate}}


                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{'Finance.body.Action' |translate}}

                    </th>
                </tr>
            </thead>
            <tbody>

                <tr class="bg-white  border-b-2  dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of MyTickets; let y = index"
                >
                    <th scope="row" class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white">
                      {{item.title}}
                    </th>
                    <td class="px-6 py-4">
                      {{item.documentTypeName}}

                    </td>
                    <td class="px-6 py-4">
                      {{item.order}}
                    </td>
                    <td class="px-6 py-4">
                      <!-- <a [href]="Url+item.documentPath" target="_blank" rel="noopener noreferrer" class="text-[var(--theme-deafult-TeacherWithClick)]"> <i class="fa-solid fa-paperclip"></i> Click for Prev</a> -->
                    </td>
                    <td class="px-6 py-4"    >
                        <a class="font-medium text-red-600 red:text-blue-500 hover:underline"  >   <img src="assets/images/Icons/delete.png" alt="Icons delete" srcset=""
              /></a>
                    </td>
                </tr>

            </tbody>
        </table>

    </div>
</div>
</div>
