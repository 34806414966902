import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageLessonsMatrialsService } from 'src/app/shared/Apis/Teacher/Matrials/manage-lessons-matrials.service';
import {
  IModelSearchMaterial,
  ITeacherLessonMaterialBasicData,
  ITeacherLessonMaterialDtos,
} from 'src/app/shared/Model/teacher/manage-lessons-matrials';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { OnlyNumbersWithDoubleDirective } from 'src/app/shared/Directive/only-numbers-with-double.directive';
import { NgSelectModule } from '@ng-select/ng-select';
 import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { DisableWritingEnglishDirective } from 'src/app/shared/Directive/disable-weriting-english.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-manage-lessons-matrials',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,OnlyNumbersWithDoubleDirective,NgSelectModule,  DisableWeritingArabicDirective,
    DisableWritingEnglishDirective,MatExpansionModule
  ],
  templateUrl: './manage-lessons-matrials.component.html',
  styleUrls: ['./manage-lessons-matrials.component.scss'],
})
export class ManageLessonsMatrialsComponent implements OnInit {
  TeacherLessonMaterialBasicData?: ITeacherLessonMaterialBasicData;
  TeacherLessonMaterialDtos!: ITeacherLessonMaterialDtos[];
  protected MaterialType!: any;
  private teacherLessonId!: number;
  protected insertForm!: FormGroup;

  protected selectedFile:File = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
  private logoForm!: FormData;
  protected subscription = new Subscription();
  protected Url = env.serverFirstHalfOfImageUrl;
  SearchInp: FormControl = new FormControl(null);
  SearchInpMaterialType: FormControl = new FormControl('');
  TableDeletMaterialID!: number;
  protected submit: boolean = true;
  public panelOpenState  = false;

  IsFile: boolean = false;
  FileSize: boolean = true;
  modalRef?: BsModalRef;
  constructor(
    private _ManageLessonsMatrials: ManageLessonsMatrialsService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _transition: TranslateService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    private modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.seo();
    this.GetMaterialType();
    this.initiate();
    if (typeof this._activatedRoute.snapshot.params['id'] !== 'undefined') {
      this.teacherLessonId = this._activatedRoute.snapshot.params['id'];
      this.GetMyLessonMaterials({ teacherLessonId: this.teacherLessonId });
      this.initiate();
    }
  }

  private initiate(row?: ITeacherLessonMaterialDtos) {
    this.insertForm = this._fb.group({
      id: [row?.id || null],
      name: [row?.name || null, Validators.required],
      teacherLessonId: [row?.teacherLessonId || this.teacherLessonId],
      materialTypeId: [row?.materialTypeId || '', Validators.required],
      nameEn: [row?.nameEn || null, Validators.required],
      materialFi: [null],
      materialUrl: [row?.materialUrl || null, [Validators.pattern('^(https?://).*$')]]

    });
  }

  protected get fc() {
    return this.insertForm.controls;
  }
  Edit(item: ITeacherLessonMaterialDtos) {
    this.Clear()
        this.initiate(item);
this.ScrollBottom();

  }
  private GetMaterialType() {
    this._ManageLessonsMatrials.GetMaterialType().subscribe((_materialType) => {
      this.MaterialType = _materialType;
    });
  }
  private GetMyLessonMaterials(ModelSearchMaterial: IModelSearchMaterial) {
    const filter = { ...ModelSearchMaterial }; // Create a copy of the object
    Object.keys(filter).forEach(
      (key) =>
        (filter[key] === null && delete filter[key]) ||
        (filter[key] === '' && delete filter[key])
    );
    this._ManageLessonsMatrials
      .GetMyLessonMaterials(filter)
      .subscribe((_manageLessonsMatrials) => {
        this.TeacherLessonMaterialDtos =
          _manageLessonsMatrials.teacherLessonMaterialDtos;
        this.TeacherLessonMaterialBasicData =
          _manageLessonsMatrials.teacherLessonMaterialBasicData;
      });
  }

  protected onSelectFile(event: any) {

    const file = event.target.files[0];
    this.FileSize = true;
    this.IsFile = true;

    if (file) {
      var mimeType = file.type;
      const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
      if (file.type!="application/x-msdownload" && before_ != 'video') {
        this.selectedFile = file;
        const fileSizeInMB = this.selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
        if (fileSizeInMB > 2) {
          // File size exceeds 5MB
          this.FileSize = false;
          Swal.fire({
            icon: 'warning',
            title: this._transition.instant('warning'),
            text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
          });
          this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
          this.IsFile = false;
        }
      } else {
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
        this.fc['materialFi'].reset();
        Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });

        this.IsFile = false;
      }
    }
  }

  protected onSubmit() {


    if (this.insertForm.valid) {
      // Perform the submit logic here
      if (this.selectedFile && this.selectedFile.name == 'dummy.txt' && !this.fc['materialUrl'].value) {
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'The document or URL must not be empty.',
        });
        return;

      } else {

        this.submit = false;
        this.fc['id'].value == null
        ? this.insertData()
        : this.UpdateTeacherLessonMaterial()
      }
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
  private insertData() {
    this.submit = false;
    this.loopform();
    this._ManageLessonsMatrials
      .AddTeacherLessonMaterial(this.logoForm)
      .subscribe(
        (res) => {
          if (res.success == true) {
            this.GetMyLessonMaterials({
              teacherLessonId: this.teacherLessonId,
            });
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Add Succsessfully',
            });
            this.Clear();
            this.ScrollTop();

          }
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }

  private UpdateTeacherLessonMaterial() {
    this.submit = false;
    this.loopform();
    this._ManageLessonsMatrials
      .UpdateTeacherLessonMaterial(this.logoForm)
      .subscribe(
        (res) => {
          if (res.success == true) {
            this.GetMyLessonMaterials({
              teacherLessonId: this.teacherLessonId,
            });
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Update successfully',
            });
            this.Clear();
            this.ScrollTop();

          }
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }
  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        //this.insertForm.removeControl(key);
      } else {
        if (typeof this.insertForm.value[key] !== 'object')
          this.logoForm.append(key, this.insertForm.value[key]);
        else if (typeof this.insertForm.value[key] == 'object')
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });
    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
    this.logoForm.append('materialFile', this.selectedFile);
  }

  protected DeleteTeacherMaterial(id: number) {
    this.subscription.add(
      this._ManageLessonsMatrials
        .DeleteTeacherLessonMaterial(id)
        .subscribe((res) => {
          if (res.success) {
            this.SearchInp.setValue(null);
            this.modalRef?.hide();
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Deleted successfully',
            });
            this.GetMyLessonMaterials({
              teacherLessonId: this.teacherLessonId,
            });
            this.Clear()
          }
        })
    );
  }

  protected Clear() {
    this.insertForm.reset();
    this.fc['teacherLessonId'].setValue(this.teacherLessonId);
    this.ClearFile();
  }
  protected ClearFile() {
    this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
    this.fc['materialFi'].reset();
    this.IsFile = false;
    this.FileSize = true;
  }
  ChangeSearchInp() {
    // if (
    //   this.SearchInpMaterialType.value != '' &&
    //   this.SearchInp.value != ''
    // )
      this.GetMyLessonMaterials({
        teacherLessonId: this.teacherLessonId,
        materialName: this.SearchInp.value ,
        materialTypeId: this.SearchInpMaterialType.value ,
      });
    // else this.GetMyLessonMaterials({ teacherLessonId: this.teacherLessonId });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ScrollBottom(){
    // Scroll the window to the bottom
  if (typeof window !== 'undefined') {
    window.scrollTo(0, document.body.scrollHeight);
  }
  }

    ScrollTop(){
    // Scroll the window to the top
    if (typeof window !== 'undefined') {
      window.scroll(0, 0);
    }
  }
//  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ManageMySubjects.header.Title3);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}}
