
import { FormControl } from '@angular/forms';

// custom validator to check that Not Zero
export function validateNotZero(control: FormControl) {
  const value = control.value;
  if (value !== 0) {
    return null; // Validation passed
  } else {
    return { notZero: true }; // Validation failed
  }
}
