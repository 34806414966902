import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,

} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NgOtpInputConfig, NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { IOtp, IVefiryUserPost } from 'src/app/shared/Model/teacher/teacher';
import { RegistrationService } from 'src/app/shared/Apis/Teacher/registration.service';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-vefiry-user',
  standalone: true,
  imports: [CommonModule, NgOtpInputModule, TranslateModule, FormsModule,NavTabsComponent],
  templateUrl: './vefiry-user.component.html',
  styleUrls: ['./vefiry-user.component.scss'],
})
export class VefiryUserComponent implements OnInit {
  @ViewChild('ngOtpInputRef') ngOtpInput: any;

  config: NgOtpInputConfig = {
    length: 6,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    isPasswordInput: false,
    placeholder: '',
  };
  otpValues: string[] = [];
  public subscription = new Subscription();
  @Input() DataVefiryUser!: IOtp
  @Input() phoneNumber!: number;
  @Output() Step3: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected submit: boolean = true;
  protected isOtpInvalid: boolean = false;

  private interval: any;
  protected showOtp: boolean = false;
  protected showSendOtp: boolean = false;
  protected DoneVefiryUser: boolean = true;
  protected otp!: any;
  protected isInvalid: boolean = false;

  constructor(protected _Verification: RegistrationService
    ,  private _AuthService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,    private seoService: SeoService,
    private layoutService: LayoutService,
    ) {}
  ngOnInit(): void {
    //When entering the screen for the first time, the time is calculated based on the time entered by Sign-Up
    this.setInterval();
this.seo();

  }
  ngAfterViewInit(): void {
    // تهيئة قيمة OTP بعد أن يصبح ngOtpInput جاهزًا
    // if (this.ngOtpInput && this.DataVefiryUser.otp) {
    //   this.ngOtpInput.setValue(this.DataVefiryUser.otp);
    // }
  }
  //When there is a change in input Otp
  protected onOtpChange(otp: any) {
    this.otp = otp;
  }
  // Send verification of mobile number and code to register
  SendVerify() {
    debugger
    this.submit = false;

    if(this.otp&&this.otp?.length ==6){
    const Data: IVefiryUserPost = { Mobile: this.phoneNumber, Otp: this.otp };
    this.subscription.add(
      this._Verification.VefiryUser(Data).subscribe(
        (response) => {
          this.ngOtpInput = '';

          if (response.success&& isPlatformBrowser(this.platformId)) {
            this.DoneVefiryUser = false;

            localStorage.setItem(
              'customerAuthorization',
              response.data['token']
            );
            localStorage.setItem(
              'customer_login',
              JSON.stringify(response.data)
            );
            localStorage.setItem('customer_name', response.data['name']);
            localStorage.setItem('logo', response.data['imagePath']);
            localStorage.setItem('role', response.data['role']);
            localStorage.setItem(
              'roleId',
              JSON.stringify(response.data['roleId'])
            );
            localStorage.setItem(
              'profileStatusId',
              JSON.stringify(response.data['profileStatusId'])
            );
            this._AuthService.ReloadHeader(true);

            this.isInvalid = true;
            this.submit = true;
          } else {
            this.otpValues = [];
            this.isInvalid = false;
            this.submit = true;
            this.isOtpInvalid =true;
          }
          this.ngOtpInput?.setValue('')
          this.submit = true;
        },
        (err) => {
          this.ngOtpInput?.setValue('')
          this.submit = true;
          this.isOtpInvalid =true;

        }
      ),

    );
  this.submit = true;

    return
  }
else
{
  this.submit = true;
  Swal.fire({
    icon: 'warning',
    title: 'warning',
    text: 'The verification number must be 6 digits',
  })
}
  }
  navigate(isInvalid: boolean) {
    this.Step3.emit(isInvalid);
  }

  // To request a new code after 90 Seconds have passed on the old code
  public SendOTP() {

    this.subscription.add(
      this._Verification.SendOTP(this.phoneNumber).subscribe((res) => {
        this.showSendOtp = false;
        if (res.data['otp']) {
          this.showOtp = true;
          // this.InitFormVerify(res)
          this.DataVefiryUser = res.data;
          // if (this.ngOtpInput && this.DataVefiryUser.otp) {
          //   this.ngOtpInput.setValue(this.DataVefiryUser.otp);
          // }
         this.otp=""
          this.setInterval();
        }
      })
    );

  }
  //To calculate the Code time from 90 Seconds to 0 Tansley
  private setInterval() {
    this.interval = setInterval(() => {
      if (this.DataVefiryUser&&this.DataVefiryUser.secondsCount > 0) {
        this.DataVefiryUser.secondsCount--;
      } else {
        this.showSendOtp = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/VefiryUser.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
       this.seoService.setTitle(translations.VerifyTeacher.header.Text + ' - ' + translations.Tabs.body.Teacher);

      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
