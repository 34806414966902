import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { StudentLessonSessionCommentService } from 'src/app/shared/Apis/Student/Chat/StudentLessonSessionComment.service';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { ChildrenIdService } from '../Parent/choose-student/children-id.service';

import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslateModule,
    NgbRatingModule,
    CustomPipeForImagesPipe,
  ],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  messages: any[] = [
    { text: 'Hi there!', from: 'me' },
    { text: 'Hello!', from: 'contact' },
    // Add more messages
  ];
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  isHovered!: boolean;
  Contacts!: any;
  newMessage: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\s*\S.*$/),
  ]);
  ViewList: boolean = false;
  isCheck: boolean = false;
  activeIndex: number | null = null; // Initialize the active index as null
  activeIndexSubject: number | null = null;
  messageDate: any;
  bookTeacherLessonSessionDetailId: any;
  Controller: any;
  studentId: any;
  isMobile: boolean=false;

  protected submit: boolean = true;

  constructor(
    private _ChildrenIdService: ChildrenIdService,
    private _SSRService: SSRService,
    private renderer: Renderer2,
    private _route: ActivatedRoute,    @Inject(PLATFORM_ID) private platformId: Object,
    private StudentLessonSessionCommentService: StudentLessonSessionCommentService,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    // this.Controller = this._SSRService.Location(2);
    // if (this.Controller == 'student') {
this.getQueryParam()
if (isPlatformBrowser(this.platformId)) {

    if (localStorage.getItem('childrenId') != null) {
      this.studentId = JSON.parse(localStorage.getItem('childrenId') ?? 'null');
      this.studentId = JSON.parse(this.studentId);
      this.GetstudentContacts(this.studentId);
      this._ChildrenIdService.setChildrenId(this.studentId);
    }
    else
    this.GetstudentContacts();

  }
  this.seo();
  }
  GetstudentContacts(studentId?: any) {
    this.StudentLessonSessionCommentService.GetAllTeachersChatWithStudent(
      studentId
    ).subscribe((contacts) => {
      this.Contacts = contacts.data;
      this.filteredContacts =     this.Contacts;
      this.filterContacts()
    });
  }


  loadMessages(index: any, bookTeacherLessonSessionDetailId: any) {
    this.bookTeacherLessonSessionDetailId = bookTeacherLessonSessionDetailId;
    if (this.activeIndexSubject === index) {
      this.activeIndexSubject = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndexSubject = index; // Set the clicked item as the active one
    }
    this.OpenMessage(bookTeacherLessonSessionDetailId);
    this.isMobile=true;

  }
  OpenMessage(bookTeacherLessonSessionDetailId: any) {
    this.StudentLessonSessionCommentService.GetSubjectSchedualGroupDetailsById(
      bookTeacherLessonSessionDetailId
    ).subscribe((res) => {
      this.messages = res.teacherLessonSessionCommentDetailsDtos;
      this.messageDate = res;
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
    });
  }
  toggleActive(index: number) {
    this.isCheck = true;
    if (this.activeIndex === index) {
      this.activeIndex = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndex = index; // Set the clicked item as the active one
    }
  }
  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  isActiveSubject(index: number): boolean {
    return this.activeIndexSubject === index;
  }
  sendMessage() {
    this.submit = false;

    if (this.newMessage.valid) {
      const data = {
        bookTeacherLessonSessionDetailId: this.bookTeacherLessonSessionDetailId,
        comment: this.newMessage.value,
        studentId: this.studentId,
      };
      this.StudentLessonSessionCommentService.SendMessage(data).subscribe(
        (res) => {
          this.messages = res.data.teacherLessonSessionCommentDetailsDtos;
          this.newMessage.reset();

          setTimeout(() => {
            this.scrollToBottom();
          }, 0);
          this.submit = true;
        }
      ),
        (err: any) => {
          this.submit = true;
        };
    } else this.submit = true;
  }
  scrollToBottom() {
    try {
      this.renderer.setProperty(
        this.chatContainer.nativeElement,
        'scrollTop',
        this.chatContainer.nativeElement.scrollHeight
      );
    } catch (err) {}
  }

  private getQueryParam() {
    const num = 1;
    this._route.params.subscribe((params) => {
      // Do something with the params
      // this.parentId = JSON.parse(params.identify)
      // this.title = params.name
      if (params['id'] != null) {
        this._SSRService.eventPrams(params['id']);
        this.studentId = params['id'];

        const bookTeacherLessonSessionDetailId_ =
        params['bookTeacherLessonSessionDetailId'];


      if (
        typeof bookTeacherLessonSessionDetailId_ != 'undefined' &&
        bookTeacherLessonSessionDetailId_ !== null
      ) {
        // Use the values as needed

        // this._SSRService.eventPrams(parseInt(this.studentId))
        this.OpenMessage(bookTeacherLessonSessionDetailId_);
        this.bookTeacherLessonSessionDetailId =
          bookTeacherLessonSessionDetailId_;
          this.GetstudentContacts(this.studentId);


      }

      }
    });
  }

  private seo(){

    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.TeacherChat.header.Message);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }

  searchText: string = '';
  filteredContacts = this.Contacts; // Default to all contacts
  filterContacts(): void {
    const searchTextLower = this.searchText.toLowerCase();
    this.filteredContacts = [];

    for (let i = 0; i < this.Contacts.length; i++) {
      const contactName = this.Contacts[i].teacherName.toLowerCase();
      if (contactName.includes(searchTextLower)) {
        this.filteredContacts.push(this.Contacts[i]);
      }
    }
  }
}
