import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import {  IGetMyDocuments } from 'src/app/shared/Model/teacher/documents-information';



@Injectable({
  providedIn: 'root'
})
export class DocumentsInformationService {

  constructor(private http: HttpClient) {}

  AddTeacherDocument(data:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherDocument/Create`,data)
  }

    GetMyDocuments( documentTypeId?: number |null):Observable<IGenericResponse<IGetMyDocuments>>{
      let search ={};
      if(documentTypeId !=null)
     search= { documentTypeId:documentTypeId }

      return this.http.post<IGenericResponse<IGetMyDocuments>>(`${env.Server_URL}TeacherDocument/GetMyDocuments`,search)
      // .pipe( map((res) => res.data ))
    }

    DeleteTeacherDocument(id:number):Observable<IGenericResponse<any[]>>{
      return this.http.get<IGenericResponse<any>>(`${env.Server_URL}TeacherDocument/Delete?id=${id}`).pipe( map((res) => res ))
    }
}
