import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader{
   private endpoint = `${environment.Server_URL}Translations/GetTranslationFiles/webmicrolesson`;

  constructor(private http: HttpClient,private translate: TranslateService) {

  }



  public translations!: { [key: string]: any }
  getTranslation(lang: string): Observable<any> {
    // Get the current language from TranslateService if not provided
    if (!lang) {
      lang = this.translate.currentLang || this.translate.defaultLang;
    }

    return this.http.get(`${this.endpoint}`).pipe(
      map((response: any) => {
        this.translations = response;
        console.log(this.translations, "Translations"); // Log translations after they are set
        return this.translations[lang] || {}; // Return translations for the requested language
      }),
      catchError((error) => {
        console.error(`Error fetching translations for ${lang}:`, error);
        return of({}); // Return an empty object on error
      })
    );
  }


}
