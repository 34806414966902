import { CommonModule, DatePipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbPagination, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { RatesReviewsService } from 'src/app/shared/Apis/Teacher/RatesReviews/rates-reviews.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';

@Component({
  selector: 'app-teacher-rates-reviews',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
  NgSelectModule,
  NgbRatingModule,
  NgbPagination,
  NgxPaginationModule,
  DatePipe
  ],
  templateUrl: './teacher-rates-reviews.component.html',
  styleUrls: ['./teacher-rates-reviews.component.scss']
})
export class TeacherRatesReviewsComponent extends BaseComponent {
  RatingReviews:any[]=[]
  page!: number;
  constructor(private RatesReviewsService:RatesReviewsService,    private seoService: SeoService,
    private layoutService: LayoutService){
    super()
    this.TeacherLessonRate();
    this.seo();
  }
  TeacherLessonRate(){
    this.RatesReviewsService.TeacherLessonRate(this.pager).subscribe(res=>{
      this.RatingReviews=res.data.items
      this.totalRecords=res.data.totalCount
    })
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.TeacherLessonRate();
    this.pageNumber;
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/Icons/comment.svg'
    );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.TeacherRatesReviews.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))

      // this.seoService.setMetaTags(translations);
    });

  }

}
