import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordionModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {SubjectSemesterYearService} from 'src/app/shared/Apis/Subjects/subject-semester-year.service'
import { environment } from 'src/environments/environment';
import { ChooseStudentComponent } from '../../Parent/choose-student/choose-student.component';
import { ChildrenIdService } from '../../Parent/choose-student/children-id.service';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { TextAlignDirective } from 'src/app/shared/Directive/text-align.directive';
@Component({
  selector: 'app-subject-details',
  standalone: true,
  imports: [CommonModule, NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslateModule, NgbRatingModule,NgbAccordionModule,CustomPipeForImagesPipe,TextAlignDirective,
    ChooseStudentComponent],
  templateUrl: './subject-details.component.html',
  styleUrls: ['./subject-details.component.scss']
})
export class SubjectDetailsComponent implements OnInit{
SubjectId: any;
  SubjectDetails: any;
  isCollapsed: boolean = false;
  ImgUrlServer=environment.serverFirstHalfOfImageUrl;
  accordionStates: boolean[] = [];
  LessonId: any;
  constructor(
    private seoService: SeoService,
    private layoutService: LayoutService,private router:Router,private SubjectSemesterYearService:SubjectSemesterYearService,private route: ActivatedRoute){


}

GetSubjecDetails() {
  this.SubjectSemesterYearService.GetSubjectLessons(this.SubjectId).subscribe(res => {
    this.SubjectDetails = res.data;
    // Check for SubjectDetails before calling initializeAccordionStates
    if (this.SubjectDetails) {
      this.initializeAccordionStates();
    }
  });
}
ngOnInit(): void {
  if (this.route.snapshot.paramMap.get("id")) {
    this.SubjectId=this.route.snapshot.paramMap.get("id")
    this.GetSubjecDetails();
  }

}
initializeAccordionStates(): void {
  if (this.SubjectDetails && this.SubjectDetails.getSubjectLessonsDetailsDtoList) {
    this.accordionStates = new Array(this.SubjectDetails.getSubjectLessonsDetailsDtoList.length).fill(true);
    this.seoService.setMetaImage( this.SubjectDetails.image?? 'assets/images/MrS-Cool/subjectDetails.png' );
    this.seoService.setTitle(  this.SubjectDetails?.name??"");
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(this.SubjectDetails?.subjectBrief??"")
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.SubjectDetails?.subjectBrief??"" ))


  }
}
toggleAccordion(index: number): void {
  this.accordionStates[index] = !this.accordionStates[index];
}
formatString(inputString: string) {
  if(inputString){
    const parts = inputString.split(',');
    return parts;
  }
  else{
    return ''
  }

}
GoToSubjectOrLessonTeachers(type:string,id?:any){
  this.router.navigate([`/main/student/TeacherForSubjects/${type}/${id}`]);
}

onStudentSelect(selectedItem:any){
  this.router.navigate([`/main/parent/StudentSubjects/${selectedItem}`]);
}
}
