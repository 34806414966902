import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../Apis/AuthService/auth.service';
import { isPlatformBrowser } from '@angular/common';

export const isLoginGuard: CanActivateFn = (route, state) => {
  const loginService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);

  if (
    loginService.getToken() ==
    null /* && loginService.getProfileStatusId() == null */
  ) {
    router.navigate([`/authentication/teacher/login`]);
    if (isPlatformBrowser(platformId))

    localStorage.setItem('role', 'Anonymous');
    return false;
  } else {
      // Scroll the window to the top

    if (
      typeof loginService.getProfileStatusId() !== 'undefined' &&
      JSON.parse(loginService.getProfileStatusId()) != 3
    ) {
      router.navigate([
        `/authentication/${loginService.getRoleName().toLowerCase()}/signup`,
      ]);
      return false;
    }

    return true;
  }
};
