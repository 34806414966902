import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { CalenderListSchedual, CalenderListSchedualForStudents, CalenderSchedual } from 'src/app/shared/Model/teacher/calender-schedual';
import { AuthService } from '../../AuthService/auth.service';
import { Router } from '@angular/router';
import { ITPagination } from 'src/app/shared/Model/Pagination/pagination';
@Injectable({
  providedIn: 'root'
})
export class StudentCalenderScheduleService {
  constructor(private http: HttpClient,private auth:AuthService, private router:Router) {}

  GetMyCalenderSchedual():Observable<CalenderSchedual[]>{
    if(  this.auth.getRoleId() == '3'
   )
{
  if( !this.auth.getCurrentChildrenId())
  {
    this.router.navigate([`/main/parent/ParentStudents`]);
 return of([]);
  }

}
    return this.http.get<IGenericResponse<CalenderSchedual>>(`${env.Server_URL}StudentCalenderSchedule/GetMyCalenderSchedual?StudentId=${this.auth.getCurrentChildrenId()??""}`).pipe( map((res) => res.data ))

  }


  public GetMyCalenderSchedualPaged(data:any): Observable<ITPagination<CalenderListSchedualForStudents>> {
    if(  this.auth.getRoleId() == '3'
  )
{
 if( !this.auth.getCurrentChildrenId())
 {
   this.router.navigate([`/main/parent/ParentStudents`]);
// return of([]);
 }else
 data['StudentId']=this.auth.getCurrentChildrenId()

}
    return this.http.post<ITPagination<CalenderListSchedualForStudents>>(`${env.Server_URL}StudentCalenderSchedule/GetMyCalenderSchedualPaged`,data).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }
  CancelBookLessonSession(id:number):Observable<any>{
    if(  this.auth.getRoleId() == '3'
   )
{
  if( !this.auth.getCurrentChildrenId())
    {
      this.router.navigate([`/main/parent/ParentStudents`]);
   return of([]);
    }

}
    return this.http.get<any>(`${env.Server_URL}StudentCalenderSchedule/CancelBookLessonSession?TeacherLessonSessionSchedualSlotId=${id}&StudentId=${this.auth.getCurrentChildrenId()??""}`).pipe( map((res) => res.data ))

  }
  StudentAttendance(id:number):Observable<any>{
    return this.http.get<any>(`${env.Server_URL}StudentCalenderSchedule/StudentAttendance?BookteacherlessonsessiondetailId=${id}`).pipe( map((res) => res.data ))

  }
    // Fetch server time from an API
    getServerTime():Observable<any>  {
    const  url = 'http://worldtimeapi.org/api/timezone/Africa/Cairo'

      return this.http.get<any>(url).pipe( map((res) =>{
        return  res
      } ));
    }

}
