<div
  class="bg-white rounded-lg cardsSpace shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-3 p-6"
>
  <form class="max-md:px-1 mt-3" [formGroup]="SearchForm">
    <div class="d-flex flex-wrap align-items-end justify-between">
      <div class="grid gap-4 mb-6 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">
        <div class="col-span-2">
          <ng-select
            id="teacherSubjectAcademicSemesterYearId"
            #teacherSubjectAcademicSemesterYearSearch
            [selectOnTab]="true"
            formControlName="subjectId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [searchable]="true"
            placeholder="{{ 'StudentCompletedLesson.form.ChooseSubject' | translate }}"
            (change)="ChangStudentSubjectAcademicSemesterYearForList()"
          >
            <ng-option
              *ngFor="
                let type of SubjectAcademicSemesterYearForList;
                let i = index
              "
              [value]="type.subjectId"
            >
              {{ type.subjectName }}
            </ng-option>
          </ng-select>

        </div>
        <div>
          <ng-select
            id="teacherSubjectAcademicSemesterYearId"
            #teacherSubjectAcademicSemesterYearSearch
            [selectOnTab]="true"
            formControlName="lessonId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [searchable]="true"
            (change)="ChangeTeacherLessonForList()"
            placeholder="{{
              'StudentCompletedLesson.form.ChooseLesson' | translate
            }}"
          >
            <ng-option
              *ngFor="let lesson of StudentLessonForList; let i = index"
              [value]="lesson.lessonId"
            >
              {{ lesson.lessonName }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mx-2">
          <input
            type="text"
            formControlName="groupName"
            id="SearchInp"
            class=" border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter group Name"
          />
        </div>

        <div class="mx-2">
          <input
            type="date"
            (keypress)="$event.preventDefault()"
            formControlName="lessonDate"
            id="lessonDate"
            class=" border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter lesson Date"
          />
        </div>
        <!-- <div class="mx-2">
        <input
          type="date"
          formControlName="endDate"
          id="endDate"
          class="bg-gray-50 border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter End Date"
        />
      </div> -->
        <div class="text-right">
          <button
            type="submit"
            class="text-white  bg-gray-800 bg-[#FA0]  btnFilter dark:bg-[#1a202c]  hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            (click)="SearchFormPag()"
          >
            {{ "StudentCompletedLesson.form.Filter" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  class=" bg-[#ffffff] rounded-lg cardsSpace dark:bg-[#1a202c] shadow dark:border dark:bg-gray-800 dark:border-gray-700 mt-3"
>
  <div class="relative overflow-x-auto dark:bg-[#1a202c]">
    <div
      class="d-flex align-items-center  dark:bg-[#1a202c] border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <div class="mr-2">
        <img src="assets/images/MrS-Cool/setting.png" alt="" />
      </div>
      <div class="addressIconSpace">
        <h3 class="text-2xl text-left font-bold dark:text-white underTitle">
          {{ "StudentCompletedLesson.Tabble.header.Title" | translate }}
        </h3>
        <p class="text-sm/[11px] text-left rtl:text-right dark:text-white">
          {{ "StudentCompletedLesson.Tabble.header.subtitle" | translate }}
        </p>
      </div>
    </div>
    <div
      class="d-flex align-items-center startPagePadding  dark:bg-[#1a202c] rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <table
        class="w-full text-sm text-left rtl:text-right  dark:bg-[#1a202c] text-white-500 dark:text-white-400"
      >
        <thead
          class="text-xs text-white bg-[#FA0]  dark:bg-[#1a202c] dark:bg-blend-darken dark:text-white-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.Subject" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{
                "StudentCompletedLesson.Tabble.thead.TeacherName" | translate
              }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.Lesson" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.Group" | translate }}
            </th>

            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.SDate" | translate }}
            </th>

            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.STime" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.ETime" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "StudentCompletedLesson.Tabble.thead.Attendance" | translate }}
            </th>
            <th scope="col" class="px-6 py-3 text-center actionCW">
              {{ "StudentCompletedLesson.Tabble.thead.Action" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b-2 dark:bg-gray-800 dark:border-gray-700"
            *ngFor="
              let item of StudentCompletedLesson
                | paginate
                  : {
                      id: 'pagin',
                      itemsPerPage: pager.maxResultCount,
                      currentPage: pageNumber,
                      totalItems: totalRecords
                    };
              let y = index
            "
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
            >
              {{ item.subject }}
            </th>
            <td class="px-6 py-4">
              {{ item.teacherName }}
            </td>
            <td class="px-6 py-4">
              {{ item.lessonname }}
            </td>
            <td class="px-6 py-4">
              {{ item.groupName }}
            </td>
            <td class="px-6 py-4">
              {{ item.date | date }}
            </td>
            <td class="px-6 py-4">
              {{ item.startTime }}
            </td>
            <td class="px-6 py-4">
              {{ item.endTime }}
            </td>
            <td class="px-6 py-10 d-flex justify-content-center">
              <div *ngIf="!item.attendance" class="red-square"></div>
              <div *ngIf="item.attendance" class="green-square"></div>
            </td>
            <td class="px-6 py-4 text-center">
              <div class="md:flex justify-center">
                <a
                class="font-mediu hover:underline"
                [attr.disabled]="item.isRated "
                (click)="OpenToRate(item,template,y)"
              >
                <img *ngIf="!item.isRated"
                width="60"
                  src="assets/images/Icons/StudentRate.svg"
                  class="m-auto md:p-3"
                  alt=""
                />
                <img *ngIf="item.isRated"
                width="60"
                src="assets/images/Icons/StudentRateDone.svg"
                class="m-auto md:p-3"
                alt=""
              />
              </a>
                <a
                  class="font-mediu hover:underline"
                  (click)="navigateWithQueryParams(item)"
                >
                  <img
                  width="60"
                    src="assets/images/Icons/chat.svg"
                    class="m-auto md:p-3"
                    alt=""
                  />
                </a>
                <a
                  class="font-mediu hover:underline"
                  (click)="
                navigateToMatrial(item.teacherLessonId)
                  "
                >
                  <img
                  width="60"
                    src="assets/images/Icons/lesson.svg"
                    class="m-auto md:p-3"
                    alt=""
                  />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <pagination-controls
        id="pagin"
        previousLabel=""
        nextLabel=""
        style="display: flex; justify-content: center"
        (pageChange)="pageChanged((page = $event))"
      ></pagination-controls>
    </div>
  </div>
  <!-- </div> -->
</div>
<!-- (click)="TableDeleteGroupID = item.id; openModal(template)" -->
<ng-template #template>
  <div class="" style="position: relative; top: 50%">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <div>
        <div class="p-6 text-center RateModalW">
          <p class="text-[var(--theme-deafult-TeacherWithClick)] sora-bold ">{{ "StudentCompletedLesson.form.Ratings" | translate }}</p>
          <div class="text-center">
            <ngb-rating
            class="Rate p-0"
            [resettable]="true"
            [rate]="StudentRate"
            (rateChange)="onRateChange($event)"
            [max]="5"
          >
            <ng-template let-fill="fill">
              <span
                class="mr-2 text-[18px] lg:text-[30px] sora-SemiBold"
                *ngIf="fill === 100"
                >&#9733;</span
              >
              <span
                class="mr-2 text-[18px] lg:text-[30px] sora-SemiBold"
                *ngIf="fill !== 100"
                >&#9734;</span
              >
            </ng-template>
          </ngb-rating>
          </div>

          <button
          [ngClass]="{
            'bg-[var(--theme-deafult-studentWithClick)]':StudentRate!==0,
           'bg-[#FFF2D9] text-[#CAAF79]':  StudentRate===0,

           }"
          [disabled]="StudentRate==0"
            (click)="AddRate();modalRef?.hide()"
            type="button"
            class="mt-4 btnMargin text-white   focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-3 text-center mr-2"
          >
           {{'DocumentsInformation.form.Yes'|translate}}
          </button>
          <button
            (click)="StudentRate=0;modalRef?.hide()"
            type="button"
            class="text-gray-500 btnNoBorder bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-[var(--theme-deafult-studentWithClick)] text-sm sora-Regular px-5 py-3 hover:text-[var(--theme-deafult-studentWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
             {{'DocumentsInformation.form.No'|translate}}
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>
