import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { addMinutes } from 'date-fns';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { TeacherCalenderSchedualService } from 'src/app/shared/Apis/Teacher/Calender/teacher-calender-schedual.service';
import { AppointmentForListForStudents, CalenderListSchedual, CalenderListSchedualForStudents, CalenderSchedual } from 'src/app/shared/Model/teacher/calender-schedual';

import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { StudentCalenderScheduleService } from 'src/app/shared/Apis/Student/Calender/student-calender-schedule.service';
@Component({
  selector: 'app-student-calender-schedule-list',
  templateUrl: './student-calender-schedule-list.component.html',
  styleUrls: ['./student-calender-schedule-list.component.scss'],
  standalone:true, imports:[    CommonModule,

    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgxPaginationModule,]

})
export class StudentCalenderScheduleListComponent extends BaseComponent {
  events!: AppointmentForListForStudents[];
  appointmentsData!: AppointmentForListForStudents[];
  targetedAppointmentData!: number;
  currentDate: Date = new Date();
  // resourcesData: Resource[];
  modalRef?: BsModalRef;
  dateNow: Date = new Date();
  dateNow$: Date = new Date();
  serverTime: Date = new Date();

  protected SearchForm!: FormGroup;
  page!: number;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private _StudentCalenderScheduleService: StudentCalenderScheduleService,private translate: TranslateService,private cdr: ChangeDetectorRef,private ngZone: NgZone,    private seoService: SeoService,
    public layoutService: LayoutService
  ){
    super();

  }


  private GetMyCalenderSchedual() {
    this.removeNullfromPager(this.pager);
    this.appointmentsData=[]
    this._StudentCalenderScheduleService
      .GetMyCalenderSchedualPaged(this.pager)
      .subscribe((calendarItems) => {
this.totalRecords =calendarItems.totalCount;
        const transformedCalendar = calendarItems.items.map(
          (calendarItem: CalenderListSchedualForStudents) => {
            // Perform some transformation on each item
            // let Start = new Date(calendarItem.date); // تعيين تاريخ مخصص
            // let minute = this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0;

            let event = {
              text: calendarItem.groupName ?? 'individual',
              subjectName: calendarItem.subjectName ,
              sessionName: calendarItem.lessonName ,
              teacherName: calendarItem.teacherName ,

              startDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
              ),
              endDate: addMinutes(
                new Date(calendarItem.date),
                this.convertTimeToMinutes(calendarItem.timeTo) ?? 0
              ),
              timeFrom: calendarItem.timeFrom,
              timeTo: calendarItem.timeTo,
              isCancel: calendarItem.isCancel,
              teamMeetingLink: calendarItem.teamMeetingLink,
              id: calendarItem.teacherLessonSessionSchedualSlotId,
              bookTeacherlessonsessionDetailId:
                calendarItem.bookTeacherlessonsessionDetailId,
              roomId: calendarItem.isCancel ? 1 : 2,
              actions: this.isCancelAndCheckOnDate(
                calendarItem.isCancel,
                addMinutes(
                  new Date(calendarItem.date),
                  this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
                )
              ),
            };
            return event;
          }
        );
        this.events = [];
        this.events = [...transformedCalendar];
        this.appointmentsData = [...transformedCalendar];
      });
  }


  private CancelBookLessonSession(item: AppointmentForListForStudents) {
    this._StudentCalenderScheduleService
      .CancelBookLessonSession(item.id)
      .subscribe((calendarItems) => {
        item.isCancel=true
        // this.GetMyCalenderSchedual()
        // const transformedCalendar = calendarItems.map(
        //   (calendarItem: CalenderSchedual) => {
        //     // Perform some transformation on each item
        //     let Start = new Date(calendarItem.date); // تعيين تاريخ مخصص
        //     let minute = this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0;
        //     // إضافة 15 دقيقة إلى التاريخ المخصص
        //     Start.setMinutes(Start.getMinutes() + minute);

        //     let event = {
        //       text: calendarItem.groupName ?? 'individual',
        //       startDate: addMinutes(
        //         new Date(calendarItem.date),
        //         this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
        //       ),
        //       endDate: addMinutes(
        //         new Date(calendarItem.date),
        //         this.convertTimeToMinutes(calendarItem.timeTo) ?? 0
        //       ),
        //       timeFrom: calendarItem.timeFrom,
        //       timeTo: calendarItem.timeTo,
        //       isCancel: calendarItem.isCancel,
        //       id: calendarItem.teacherLessonSessionSchedualSlotId,
        //       roomId: calendarItem.isCancel ? 1 : 3,
        //       actions: this.isCancelAndCheckOnDate(
        //         calendarItem.isCancel,
        //         addMinutes(
        //           new Date(calendarItem.date),
        //           this.convertTimeToMinutes(calendarItem.timeFrom) ?? 0
        //         )
        //       ),
        //     };

        //     return event;
        //   }
        // );
        // this.events = [];
        // this.events = [...transformedCalendar];
        // this.appointmentsData = [...transformedCalendar];
      });
  }

  confirmCancelBookLessonSession(item: AppointmentForListForStudents) {
    Swal.fire({
      title: this.translate.instant('Warning'),
      text: this.translate.instant('Are You Sure To Cancel Book Lesson Session'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('CancelBookLessonSession'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this._StudentCalenderScheduleService.CancelBookLessonSession(item.id)
          .subscribe((response) => {
            item.actions = false; // Set the isCancel flag to true
            item.isCancel = true; // Set the isCancel flag to true
            this.cdr.detectChanges(); // Manually trigger change detection
            Swal.fire(
              this.translate.instant('Cancelled'),
              this.translate.instant('Your appointment has been cancelled.'),
              'success'
            );
          }, (error) => {
            Swal.fire(
              this.translate.instant('Error'),
              this.translate.instant('Something went wrong. Please try again later.'),
              'error'
            );
          });
      }
    });
  }

  private convertTimeToMinutes(timeString: string): number | null {
    // Regular expression to match the "HH:mm:ss" format
    const timeRegex = /^(\d{2}):(\d{2}):(\d{2})$/;

    // Check if the input time string matches the expected format
    const match = timeString.match(timeRegex);

    if (match) {
      // Extract the hour, minute, and second components
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const seconds = parseInt(match[3], 10);

      // Calculate the total minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      return totalMinutes;
    } else {
      // Return null if the input format is not valid
      console.error('Invalid time format. Expected "HH:mm:ss"');
      return null;
    }
  }

  isCancelAndCheckOnDate(isCancel: boolean, start: Date) {
    if (!isCancel && start > new Date()) {
      return true;
    } else {
      return false;
    }
  }

  protected StudentAttendance(BookteacherlessonsessiondetailId: number): void {
    this._StudentCalenderScheduleService
      .StudentAttendance(BookteacherlessonsessiondetailId)
      .subscribe((res) => {});
  }



  ngOnInit(): void {
    this.initiateSearch();
    this.onSubmit()
    this.seo()

  }

  private initiateSearch(): void {
    this.SearchForm = this._fb.group({
      dateFrom: [this.DateUTCPlusHours.slice(0, 10)],
      dateTo:[''],
    });
  }

  get fc() {
    return this.SearchForm.controls;
  }


  removeNullfromPager(pager: any): void {
    for (const prop in pager) {
      if (
        (pager.hasOwnProperty(prop) && pager[prop] === null) ||
        pager[prop] == ''
      ) {
        delete pager[prop];
      }
    }
  }

  navigate(id: number) {
    // this.router.navigate([`/main/CompletedLessonDetail/${id}`]);
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetMyCalenderSchedual();
    this.pageNumber;
  }
  SearchFormPag() {
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.pager = { ...this.pager, ...this.SearchForm.getRawValue() };

    this.GetMyCalenderSchedual()

    this.pageNumber;



  }

  protected onSubmit() {
    // this.submit = false;

    if (this.fc["dateFrom"].value && this.fc["dateTo"].value) {
      const fromStartDate = this.fc["dateFrom"].value;
      const toEndDate = this.fc["dateTo"].value;

      if (fromStartDate > toEndDate) {
        // this.notifier.showError('Start Date Should Be Before To End Date');
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Start Date Should Be Before To End Date",
        });
        // this.submit = true;
      } else {
        this.SearchFormPag();
      }
    } else {
      this.SearchFormPag();
    }
  }

  private seo() {
    // this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    // );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(
        translations.MENUITEMS.StudentsSessions.LIST.Calendar
      );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        translations.Home.header.meta_description
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(
          translations.Home.header.meta_description
        )
      );

      // this.seoService.setMetaTags(translations);
    });
  }

}
