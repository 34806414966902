<!-- <div class="w-full sm:w-1/1 md:w-1/1  xl:w-1/1 pt-5 px-3 md:ps-12  "> -->


<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="  bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white sora-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
        <mat-panel-description >
          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left sora-bold dark:text-white underTitle ">
                    {{ "ManageMyDocuments.header.Filter-ManageMyDocuments" |translate}}
            </h2>
           </div>
        </div>

        </mat-panel-description>
      </mat-expansion-panel-header>


   <div
>
  <form class="max-md:px-1 p-1">
    <div class="grid gap-6 mb-6 lg:grid-cols-4 grid-cols-1 px-6 mt-3">
      <div class="max-md:mb-1">
        <label
          for="DocumentTypeId"
          class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
          >{{ "ManageMyDocuments.form.Document-Type" | translate }}
        </label>
        <ng-select
          id="DocumentTypeId"
          #DocumentTypeId
          [selectOnTab]="true"
          [formControl]="SearchInp"
          class="form-control p-0 inputFilter"
          dropdownPosition="auto"
          labelForId="validation02"
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            SearchInp.valid && (SearchInp.touched || SearchInp.value)
          "
          [class.is-invalid]="SearchInp.invalid && SearchInp.touched"
          [ngClass]="{
          'class-1': SearchInp.invalid && (SearchInp.dirty || SearchInp.touched),
          'class-2': SearchInp.valid && (SearchInp.dirty || SearchInp.touched),
        }"
        >
          <ng-option value="">
            {{ "ManageMyDocuments.form.Choose-your-document-type" | translate }}
          </ng-option>
          <ng-option *ngFor="let item of DocumentType" [value]="item.id"
            >{{ item.name }}
          </ng-option>
        </ng-select>
      </div>
      <div class="text-right col-span-3 flex flex-wrap align-items-end">
        <button
          type="submit"
          (click)="ChangeSearchInp()"
          class="ml-auto text-white btnFilter bg-[var(--theme-deafult-TeacherWithClick)] focus:outline-none focus:ring-0 focus:ring-gray-300 rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
        >
          {{'ManageMyDocuments.form.Filter'|translate}}
        </button>
      </div>
    </div>
  </form>
</div>



    </mat-expansion-panel>
  </mat-accordion>



<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
    [expanded]="true"
     (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <mat-panel-description>
          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left sora-bold dark:text-white underTitle ">
              {{ "ManageMyDocuments.header.Title2" |translate}}
            </h2>
          </div>
        </div>


        </mat-panel-description>
      </mat-expansion-panel-header>


      <div
    >
      <div class="relative overflow-x-auto" *ngIf="MyDocuments">

        <div
          class="d-flex align-items-center startPagePadding mt-2 dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 mb-3"
        >
          <table
            class="w-full text-sm text-left dark:bg-[#1a202c] rtl:text-right text-white-500 dark:text-white-400"
          >
            <thead
              class="sora-Medium text-white dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMyDocuments.form.Title" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMyDocuments.form.Type" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMyDocuments.form.Order" | translate }}
                </th>
                <th scope="col" class="px-6 py-3">
                  {{ "ManageMyDocuments.form.file" | translate }}
                </th>
                <th scope="col" class="px-6 py-3 ">
                  {{ "ManageMyDocuments.form.Action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of MyDocuments; let y = index"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  {{ item.title }}
                </th>

                <td class="px-6 py-4">
                  {{ item.documentTypeName }}
                </td>
                <td class="px-6 py-4">
                  {{ item.order }}
                </td>
                <td class="px-6 py-4">
                  <a
                    [href]="Url + item.documentPath"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap"
                  >
                    <i class="fa-solid fa-paperclip"></i> {{'ManageMyDocuments.form.Click-for-Prev'|translate}}</a
                  >
                </td>
                <td
                  class="px-6 py-4"
                  (click)="TableDeletDocID = item.id; openModal(template)"
                >
                  <a
                    [tooltip]="'ManageMyDocuments.form.Click-Delete-Document' | translate"
                    class="font-medium text-red-600 red:text-blue-500 hover:underline inline-block"
                  >
                    <img
                      src="assets/images/MrS-Cool/delete-Icon.png"
                      class="w-4"
                      alt=""
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div> -->

      <ng-template #template>
        <div class="" style="position: relative; top: 50%">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div>
              <div class="pt-5 pb-2 px-12 text-center">
                <div class="text-center pb-6">
                  <img
                    src="assets/images/MrS-Cool/delete-Icon.png"
                    class="m-auto p-3"
                    alt=""
                  />
                </div>
                <h3
                  class="mb-3 text-[12px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400"
                >
                  {{'ManageMyDocuments.form.Are-you-sure-you-want-to-delete' |translate}}
                </h3>

                <button [disabled]="!submit"
                  (click)="DeleteTeacherDocument(TableDeletDocID); modalRef?.hide()"
                  type="button"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:-[var(--theme-deafult-TeacherWithClick)] focus:ring-0 focus:outline-none focus:ring-red-300 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] rounded-lg sora-Medium text-[12px] md:text-[14px] inline-flex items-center px-5 h-[3.8em] text-center mr-2"
                >
                  {{'ManageMyDocuments.form.Yes' |translate}}
                </button>
                <button
                  (click)="modalRef?.hide()"
                  type="button"
                  class="bg-white ml-2 hover:bg-gray-100 focus:ring-0 focus:outline-none focus:ring-transparent rounded-lg sora-Medium text-[12px] md:text-[14px] px-5 h-[3.8em] hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  {{'ManageMyDocuments.form.No' |translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>



    </mat-expansion-panel>
  </mat-accordion>


<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border  dark:border-gray-700"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white sora-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
        <mat-panel-description>
          <div
          class="d-flex align-items-center mainAddressPadding border-b-2 rounded-lg dark:border dark:bg-[#1a202c] bg-[#ffffff] dark:border-gray-700 px-6 pt-2 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/Icons/add-file-button_.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3 class="text-left sora-bold dark:text-white underTitle">
              {{ "ManageMyDocuments.header.Title" | translate }}
            </h3>
          </div>
        </div>
        </mat-panel-description>
      </mat-expansion-panel-header>


      <div

    >
      <form class="max-md:px-1" [formGroup]="insertForm" (ngSubmit)="onSubmit()">

        <div class="grid lg:grid-cols-4 grid-cols-1 gap-4 startPagePadding">
          <div class="col-span-3 pt-2 md:pr5">
            <div>
              <label
                for="first_name"
                class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
                >{{ "ManageMyDocuments.form.Document-Title" | translate }}
                <span
                  class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                  >*</span
                >
              </label>
              <input
                type="text"
                formControlName="Title"
                [tooltip]="
                  fc['Title'].invalid && (fc['Title'].dirty || fc['Title'].touched)
                    ? ('ManageMyDocuments.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-1': fc['Title'].invalid && (fc['Title'].dirty || fc['Title'].touched),
                  'class-2': fc['Title'].valid && (fc['Title'].dirty || fc['Title'].touched),
                }"
                id="first_name"
                class="bg-white startPagePadding border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter your document title"
                required
              />
            </div>

            <div class="grid gap-6 md:grid-cols-3 grid-cols-1 mt-4">
              <div class="max-md:w-full">
                <label
                  for="DocumentTypeId"
                  class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white pt-3"
                  >{{ "ManageMyDocuments.form.Document-Type" | translate }}
                  <span
                    class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                    >*</span
                  ></label
                >

                <ng-select
                  id="DocumentTypeId"
                  #DocumentTypeId
                  [selectOnTab]="true"
                  formControlName="DocumentTypeId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fc['DocumentTypeId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fc['DocumentTypeId'].errors && fc['DocumentTypeId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fc['DocumentTypeId'].valid &&
                    (fc['DocumentTypeId'].touched || fc['DocumentTypeId'].value)
                  "
                  [class.is-invalid]="
                    fc['DocumentTypeId'].invalid && fc['DocumentTypeId'].touched
                  "
                  [ngClass]="{
                    'class-1': fc['DocumentTypeId'].invalid && (fc['DocumentTypeId'].dirty || fc['DocumentTypeId'].touched),
                    'class-2': fc['DocumentTypeId'].valid && (fc['DocumentTypeId'].dirty || fc['DocumentTypeId'].touched),
                  }"
                >
                  <ng-option value="">
                    {{ "ManageMyDocuments.form.Choose-your-document-type" | translate }}
                  </ng-option>
                  <ng-option *ngFor="let item of DocumentType" [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="text-right mt-auto flex-wrap align-items-end">
                <button
                  type="submit"
                  type="submit"
                  [disabled]="!submit"
                  [ngClass]="{
                    'valid-form': insertForm.valid,
                    'invalid-form': insertForm.invalid
                  }"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] btnWithForm hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-4 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  {{ "ManageMyDocuments.form.Save" | translate }}
                </button>
                <button
                  type="button"
                  class="text-[var(--theme-deafult-TeacherWithClick)] bg-white btnWithForm border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c]  dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  (click)="Clear()"
                >
                  {{ "ManageMyDocuments.form.Clear" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center w-full relative">
              <div
                class="bottom-1 border-slate-950 absolute top-0 right-0"
                *ngIf="IsFile && FileSize"
              >
                <img
                  src="assets/images/MrS-Cool/delete-Icon.png"
                  class="m-auto p-3 w-14"
                  alt="delete-Icon"
                  (click)="ClearFile()"
                />
              </div>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full border-[#DDDDDD] border-[2px] rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div
                      *ngIf="!IsFile && FileSize"
                      class="flex flex-col items-center justify-center px-3"
                    >
                      <img
                        src="assets/images/MrS-Cool/currentDocument.png"
                        alt=""
                      />
                      <p
                        class="mb-3 mt-2 sora-Regular text-[10px] md:text-[12px] text-[var(--theme-deafult-TeacherWithClick)] text-center"
                      >
                        {{'ManageMyDocuments.form.Files-supported' |translate}}: PDF, JPG, PNG, TIFF, GIF,<br />
                        Pdf, docx, doc, WORD<br />
                        {{'ManageMyDocuments.form.Maximum-size-is' |translate}}: 2MB
                      </p>
                      <p
                        class="sora-Regular sora-Regular text-[11px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)]"
                      >
                      {{'ManageMyDocuments.form.Drag&Drop-Files' |translate}}
                      </p>

                      <p
                        class="py-3 px-5 mr-2 text-[7px] md:text-[11px] sora-Regular text-white focus:outline-none bg-[var(--theme-deafult-TeacherWithClick)] rounded-full border dark:hover:bg-gray-700"
                      >
                      {{'ManageMyDocuments.form.Choose-Files' |translate}}
                      </p>
                    </div>

                    <div
                      *ngIf="IsFile && FileSize"
                      class="flex flex-col items-center justify-center pt-5 pb-6"
                    >
                      <p class="bottom-1 border-slate-950 inFile">
                        <img src="assets/images/MrS-Cool/documents-1.png" alt="" />
                      </p>
                      <p
                        class="sora-Medium text-gray-500 dark:text-[#DDDDDD] text-center"
                      >
                        {{'ManageMyDocuments.form.Your-file-uploaded' |translate}} <br />
                        {{'ManageMyDocuments.form.successfully' |translate}}
                      </p>
                    </div>
                    <div
                      *ngIf="!FileSize"
                      class="flex flex-col items-center justify-center pt-5 pb-6"
                    >
                      <p class="bottom-1 border-slate-950 inFile-fill">
                        <img
                          src="assets/images/MrS-Cool/documents-folder.png"
                          alt="documents"
                        />
                      </p>
                      <p
                        class="sora-Medium text-[10px] md:text-[14px] text-red-500 dark:text-red-400 text-center"
                      >
                        {{'ManageMyDocuments.form.Your-file-cant-be-uploaded' |translate}},<br />
                        {{'ManageMyDocuments.form.Maximum-size-is' |translate}} : 2MB
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      class="hidden"
                      (change)="onSelectFile($event)"
                      formControlName="DocumentP"
                    />
                  </label>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  (change)="onSelectFile($event)"
                  formControlName="DocumentP"
                />
              </label>
            </div>
          </div>
          <div class="... d-none max-md:w-full">
            <div class="flex items-center justify-center w-ful relative">
              <div
                class="bottom-1 border-slate-950 absolute top-0 right-0"
                *ngIf="IsFile && FileSize"
              >
                <img
                  src="assets/images/MrS-Cool/delete-Icon.png"
                  class="m-auto p-3 w-14"
                  alt="delete-Icon"
                  (click)="ClearFile()"
                />
              </div>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div
                  *ngIf="!IsFile && FileSize"
                  class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                  <svg
                    class="w-8 h-8 mb-4text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>

                  <p
                    class="mb-2 sora-Regular text-[12px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400 text-center"
                  >
                  {{'ManageMyDocuments.form.Files-supported' |translate}}: PDF, JPG, PNG, TIFF, GIF,<br />
                  Pdf, docx, doc, WORD<br />
                  {{'ManageMyDocuments.form.Maximum-size-is' |translate}}: 2MB
                  </p>
                  <p
                    class="sora-Regular text-[12px]text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400"
                  >
                    <span class="">{{'ManageMyDocuments.form.Drag&Drop-Files' |translate}}
                    </span>
                  </p>
                  <p
                    class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] hover:text-[var(#fff)] hover:bg-[var(--theme-deafult-TeacherWithClick)] focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-[#1a202c] dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                  {{'ManageMyDocuments.form.Choose-Files' |translate}}
                </p>
                </div>

                <div
                  *ngIf="IsFile && FileSize"
                  class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                  <p class="bottom-1 border-slate-950 inFile">
                    <img src="assets/images/MrS-Cool/documents-1.png" alt="" />
                  </p>
                  <p
                    class="sora-Mediumtext-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400 text-center"
                  >
                  {{'ManageMyDocuments.form.Your-file-uploaded' |translate}} <br />
                  {{'ManageMyDocuments.form.successfully' |translate}}
                  </p>
                </div>
                <div
                  *ngIf="!FileSize"
                  class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                  <p class="bottom-1 border-slate-950 inFile-fill">
                    <img src="assets/images/MrS-Cool/documents-folder.png" alt="" />
                  </p>
                  <p class="sora-Medium text-red-500 dark:text-red-400 text-center">
                    {{'ManageMyDocuments.form.Your-file-cant-be-uploaded' |translate}},<br />
                    {{'ManageMyDocuments.form.Maximum-size-is' |translate}} : 2MB
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  (change)="onSelectFile($event)"
                  formControlName="DocumentP"
                />
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>



    </mat-expansion-panel>
  </mat-accordion>
