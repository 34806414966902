import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
} from '@angular/core';
import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

 import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import {
  SubjectInfo,
  SubjectInfoForList,
  TeacherSubjectAcademicSemeste,
} from 'src/app/shared/Model/teacher/subject-info';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsModalService, BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
interface row {
  EducationTypeId: number;
  EducationLevelId: number;
  SubjectSemesterYearIds: number;
  SubjectSemesterId: number;
}
@Component({
  selector: 'app-subject-info',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    NgSelectModule,
    ModalModule,
    NavTabsComponent,NgMultiSelectDropDownModule
  ],
  templateUrl: './subject-info.component.html',
  styleUrls: ['./subject-info.component.scss'],
})
export class SubjectInfoComponent implements OnInit {
  protected insertForm!: FormGroup;
  protected EducationTypes!: SubjectInfo[];
  protected EducationLevel!: SubjectInfo[];
  protected AcademicYear!: SubjectInfo[];
  protected SubjectsList!: SubjectInfoForList[];
  protected SubjectSemester!: SubjectInfo[];
  protected TeacherSubjectAcademic!: TeacherSubjectAcademicSemeste[];
  protected subscription = new Subscription();
  submit: boolean = true;
  @Output() ToDocuments: EventEmitter<boolean> = new EventEmitter<boolean>();
  modalRef?: BsModalRef;
  TableDeleteTeacherSubject!: number;
  dropdownSettings: IDropdownSettings = {};
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _SubjectInfo: SubjectInfoService,
    private _Lookup: LookupService,
    private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,
    private modalService: BsModalService, private translationSvc: TranslateService
  ) {}
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translationSvc.instant('SELECT_ALL'),
      unSelectAllText: this.translationSvc.instant('UNSELECT_ALL'),
      allowSearchFilter: true,

    };
    this.initiate();
    this.GetEducationType();
    this.GetTeacherSubjectAcademic();
    this.seo();

  }
  protected get fc() {
    return this.insertForm.controls;
  }

  private initiate(row?: row) {
    this.insertForm = this._fb.group({
      EducationTypeId: [row?.EducationTypeId || '', Validators.required],
      EducationLevelId: [row?.EducationLevelId || '', Validators.required],
      SubjectSemesterId: [row?.SubjectSemesterId || '', Validators.required],
      SubjectSemesterYearIds: [row?.SubjectSemesterYearIds || [], Validators.required],

    });
  }

  GetEducationType() {
    this._Lookup
      .GetEducationType()
      .subscribe((_EducationTypes) => (this.EducationTypes = _EducationTypes));
  }
  protected ChangEducationType() {
    this.fc['EducationLevelId'].setValue('');
    this.fc['SubjectSemesterId'].setValue('');
    this.fc['SubjectSemesterYearIds'].setValue('');
    this.resetArray();
    this.GetEducationLevel();
  }
  GetEducationLevel() {
    if (this.fc['EducationTypeId'].value)
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    else this.resetArray();
  }

  protected ChangEducationLevel() {
    this.fc['SubjectSemesterId'].setValue('');
    this.fc['SubjectSemesterYearIds'].setValue('');
    this.SubjectsList=[]
    this.SubjectSemester=[]

    this.SelectedEducationLevel();
  }
  // get from AcademicYear
  private SelectedEducationLevel() {

    if (this.fc['EducationLevelId'].value  ) {
      this.subscription.add(
        // this._Lookup
        //   .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
        //   .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
        this._Lookup
        .GetAllForListByEducationLevelId(this.fc['EducationLevelId'].value)
        .subscribe((_subject) => (this.SubjectsList = _subject))
      );
    } else {
      this.SubjectsList = [];
      this.SubjectSemester=[]

    }
  }

  protected ChangAcademicYear() {
    this.fc['SubjectSemesterYearIds'].setValue('');
       this.SubjectSemester=[]
    this.SelectedAcademicYear();
  }
  // get from AcademicYear
  private SelectedAcademicYear() {
    if (this.fc['SubjectSemesterId'].value) {
      this.subscription.add(
        this._Lookup
          .GetAllSubjectBySubjectIdAndEducationLevelId(
            {SubjectId: this.fc['SubjectSemesterId'].value ,EducationLevelId: this.fc['EducationLevelId'].value}
          )
          .subscribe(
            (_SubjectSemester) => (this.SubjectSemester = _SubjectSemester)
          )
      );
    } else this.SubjectSemester = [];
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      // Perform the submit logic here
   this.submit = false;
      this.insertData();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private insertData() {
    this.submit = false;
    const subjectSemesterYearIds = this.insertForm.get('SubjectSemesterYearIds')?.value.map((item:SubjectInfo) => item.id);

    this._SubjectInfo
      .AddTeacherSubjectAcademicForRegister({SubjectSemesterYearIds: subjectSemesterYearIds})
      .subscribe(
        (res) => {
          if (res.success == true) {
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Add Succsessfully',
            });
            this.GetTeacherSubjectAcademic();
            this.resetForm();

            this.resetArray()
          }
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }
  private GetTeacherSubjectAcademic() {
    this.subscription.add(
      this._SubjectInfo
        .GetTeacherSubjectAcademic({})
        .subscribe(
          (_TeacherSubjectAcademic) =>
            {
              this.submit
              this.TeacherSubjectAcademic = _TeacherSubjectAcademic
              if (isPlatformBrowser(this.platformId)) {

              if(this.TeacherSubjectAcademic &&this.TeacherSubjectAcademic.length !=0)

              localStorage.setItem(
                'profileStatusId',
                JSON.stringify('2')
              );
              else
              localStorage.setItem(
                'profileStatusId',
                JSON.stringify('1')
              );

            }
              this.submit = true;

          },
          (err) => {
            this.submit = true;
          }
        ));
    }
  navigate() {
    if (this.TeacherSubjectAcademic.length > 0) {
      this.ToDocuments.emit(true);
    } else
      Swal.fire({
        icon: 'warning',
        title: 'warning',
        text: ' Must be enter one item at least',
      });
  }

  protected DeleteTeacherSubjectAcademic(id: number) {
    this.submit = false;
    this.subscription.add(
      this._SubjectInfo
        .DeleteTeacherSubjectAcademic(id)
        .subscribe((_TeacherSubjectAcademic) => {
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Deleted successfully',
          });
          if (this.modalRef) {
            this.modalRef.hide();
          }
          this.GetTeacherSubjectAcademic();
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      ));
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

    // Add this method to handle selection changes
    onSelectionChange(selectedItems: any[]) {
      const ids = selectedItems.map(item => item.id);
      this.insertForm.get('SubjectSemesterYearIds')?.setValue(ids);
    }
    // Method to reset array if needed
    // this.form.get('SubjectSemesterYearIds').setValue([]);
  resetArray(){
    this.EducationLevel=[]
    this.SubjectsList=[]
    this.SubjectSemester=[]
  }
  resetForm() {
    this.insertForm.reset();
    this.insertForm.markAsUntouched();
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/left_teacher.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
       this.seoService.setTitle(translations.SubjectInfo.header.Title + ' - ' );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
