<div  class="flex _ flex-wrap justify-ce nter">
  <div class="flex justify-content-center sm:w-1/1 xl:w-2/6  max-xl:hidden">


    <img
      src="assets/images/MrS-Cool/left_parent.png"
      class="w-full imgPosition"
    />
  </div>

  <div
    class="flexFormDivAuth w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="TeacherSteps.signUp == true"
  >
    <app-nav-tabs class="w-full mb-4"></app-nav-tabs>
    <form
    class="w-full   max-md:p-[50px] bg-white md:w-10/12 ProfilePadding rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"

      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="rtl:text-end">
        <h2
        class="text-[16px] rtl:text-start col-span-2 lg:text-[30px] text-left Lama-bold dark:text-white"
        >
          {{ "ParentSignUp.header.PesrsonalInformation" | translate }}
        </h2>
        <p class=" rtl:text-start text-left text-[13px] Lama-Regular">
          {{ "ParentSignUp.header.Already-have-an-account?" | translate }}
        </p>
      </div>
      <div class="grid gap-6 mb-6 md:grid-cols-2 mt-3">
        <div>
          <label
            for="first_name"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.ParentName" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            >
          </label>
          <input
            type="text"
            formControlName="Name"
            [tooltip]="
              fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
                ? ('ParentSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
                  'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
                }"
            id="first_name"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="('ParentSignUp.body.Enter-your-name' | translate)"
            required
          />
        </div>
        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Gender" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            #GenderId
            [selectOnTab]="true"
            formControlName="GenderId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GenderId'].errors?.['required'] ?  ('ParentSignUp.body.required' | translate) : null"
            [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GenderId'].valid &&
              (fc['GenderId'].touched || fc['GenderId'].value)
            "
            [class.is-invalid]="
              fc['GenderId'].invalid && fc['GenderId'].touched
            "
          >
            <ng-option selected value=""
              >{{ "ParentSignUp.body.Male/Female" | translate }}
            </ng-option>

            <ng-option *ngFor="let gender of genders" [value]="gender.id"
              >{{ gender.title  | translate }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="phone"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.MobileNumber" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <input
            type="tel"
            id="phone"
            formControlName="Mobile"
            onlyNumbers
            maxlength="11"
            minlength="11"
            class="bg-white rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="('ParentSignUp.body.Enter-your-number' | translate)"
            [tooltip]="
              fc['Mobile'].invalid &&
              (fc['Mobile'].dirty || fc['Mobile'].touched)
                ? ('ParentSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
              'class-1': fc['Mobile'].invalid && (fc['Mobile'].dirty || fc['Mobile'].touched),
              'class-2': fc['Mobile'].valid && (fc['Mobile'].dirty || fc['Mobile'].touched),
            }"
          />
          <!-- <label for="success" class="block mb-2  text-green-700 dark:text-green-500">Your name</label>
        <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
        <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
        </div>
        <div>
          <label
            for="Email"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Email" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            >
          </label>
          <input
            type="email"
            appDisableWritingArabic
            formControlName="Email"

                 [tooltip]="
                 fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched)
                   ? fc['Email'].hasError('email')
                     ? ('The Email is invalid' | translate)
                     : ('StudentEditProfile.form.required' | translate)
                   : null
               "
               [ngClass]="{
       'class-1': fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched),
       'class-2': fc['Email'].valid && (fc['Email'].dirty || fc['Email'].touched),
     }"
            id="Email"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="('ParentSignUp.body.Enter-your-email' | translate)"
            required
          />
        </div>

        <div>
          <label
            for="Birthdate"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Birthdate" | translate }}</label
          >
          <input
            type="date"
            id="Birthdate"
            [max]="dateConverterPlus18()"
            (keypress)="$event.preventDefault()"
            formControlName="Birthdate"
            class="bg-white rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            required
            [placeholder]="('ParentSignUp.body.Enter-your-Birthdate' | translate)"
            [tooltip]="
              fc['Birthdate'].invalid &&
              (fc['Birthdate'].dirty || fc['Birthdate'].touched)
                ? ('ParentSignUp.body.required' | translate)
                : null
            "
            [ngClass]="{
  'class-1': fc['Birthdate'].invalid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
  'class-2': fc['Birthdate'].valid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
}"
          />
        </div>
        <div>
          <label
            for="password"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Password" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            id="password"
            formControlName="PasswordHash"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'Enter your password' | translate"
            required
            [tooltip]="
                     fc['PasswordHash'].errors?.['required']
                       ? ('ParentSignUp.body.required' | translate)
                       : fc['PasswordHash'].errors?.['minlength']
                         ? 'password should be more than 6 characters'
                         : null"
            [ngClass]="{
                  'class-1': fc['PasswordHash'].invalid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                  'class-2': fc['PasswordHash'].valid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                }"
          />
        </div>
        <div>
          <label
            for="confirm_password"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Confirmpassword" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            formControlName="confirmPassword"
            id="confirm_password"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'ParentSignUp.body.Enter-your-password' | translate"
            required
            [tooltip]="
                  fc['confirmPassword'].errors?.['required']
                    ? ('ParentSignUp.body.required' | translate)
                    :  fc['confirmPassword'].errors?.['mustMatch']
                        ? ('Passwords must match' | translate)
                        : null"
            [ngClass]="{
                  'class-1': fc['confirmPassword'].invalid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                  'class-2': fc['confirmPassword'].valid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                }"
          />
        </div>
        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Country" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Country_"
            #Country
            [selectOnTab]="true"
            formControlName="CountryId"
            class="form-control p-0"
            (change)="ChangCountry()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CountryId'].errors?.['required']? ('ParentSignUp.body.required' | translate) : null"
            [isDisabled]="!fc['CountryId'].errors && fc['CountryId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CountryId'].valid &&
              (fc['CountryId'].touched || fc['CountryId'].value)
            "
            [class.is-invalid]="
              fc['CountryId'].invalid && fc['CountryId'].touched
            "
            [ngClass]="{
      'class-1': fc['CountryId'].invalid && (fc['CountryId'].dirty || fc['CountryId'].touched),
      'class-2': fc['CountryId'].valid && (fc['CountryId'].dirty || fc['CountryId'].touched),
    }"
          >
            <ng-option value="">
              {{ "ParentSignUp.body.Choose-your-country" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of countries" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.Governorate" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="Governorate_"
            #Governorate
            [selectOnTab]="true"
            formControlName="GovernorateId"
            class="form-control p-0"
            (change)="ChangGovernorate()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GovernorateId'].errors?.['required'] ? ('ParentSignUp.body.required' | translate) : null"
            [isDisabled]="
              !fc['GovernorateId'].errors && fc['GovernorateId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GovernorateId'].valid &&
              (fc['GovernorateId'].touched || fc['GovernorateId'].value)
            "
            [class.is-invalid]="
              fc['GovernorateId'].invalid && fc['GovernorateId'].touched
            "
            [ngClass]="{
      'class-1': fc['GovernorateId'].invalid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
      'class-2': fc['GovernorateId'].valid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
    }"
          >
            <ng-option value="">
              {{ "ParentSignUp.body.Choose-your-governorate" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of Governorates" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ParentSignUp.body.City" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="City"
            #City_
            [selectOnTab]="true"
            formControlName="CityId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CityId'].errors?.['required'] ? ('ParentSignUp.body.required' | translate) : null"
            [isDisabled]="!fc['CityId'].errors && fc['CityId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CityId'].valid && (fc['CityId'].touched || fc['CityId'].value)
            "
            [class.is-invalid]="fc['CityId'].invalid && fc['CityId'].touched"
            [ngClass]="{
      'class-1': fc['CityId'].invalid && (fc['CityId'].dirty || fc['CityId'].touched),
      'class-2': fc['CityId'].valid && (fc['CityId'].dirty || fc['CityId'].touched),
    }"
          >
            <ng-option value="">
              {{ "ParentSignUp.body.Choose-your-city" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of cityList" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <!-- <div class="mb-6">
          <label for="Birthdate" class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{"Birthdate" |translate}}</label>
          <input type="date" id="Birthdate" [max]="dateConverterPlus18()" formControlName="Birthdate"class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="**********************" required> </div> -->

      <!-- <div class="mb-6">
            <label for="email" class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">Email address</label>
            <input type="email" id="email" formControlName="Email" class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required>
        </div> -->
      <div class="flex items-start mb-6">
        <div class="flex items-center h-5">
          <input
            id="remember"
            type="checkbox"
            formControlName="Accept"
            style="border: 3px solid var(--theme-deafult-ParentWithClick)"
            class="w-4 h-4 bg-white focus:ring-3 focus:ring-[var(--theme-deafult-ParentWithClick)] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:ring-offset-gray-800"
            [tooltip]="
              fc['Accept'].value == false &&
              (fc['Accept'].dirty || fc['Accept'].touched)
                ? ('TeacherSignUp.form.required' | translate)
                : null
            "
            [ngClass]="{
              'class-checkbox-err': fc['Accept'].value ==false && (fc['Accept'].dirty || fc['Accept'].touched),
              'class-radio': fc['Accept'].value ==true && (fc['Accept'].dirty || fc['Accept'].touched),
            }"
          />
        </div>
        <p
          for="remember"
          class="ml-2 rtl:mr-2 underline text-[12px] lg:text-[14px] Lama-Regular text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
        >
          {{
            "ParentSignUp.body.Accept-the-Terms-and-Privacy-Policy" | translate
          }}
        </p>
      </div>
      <div class="mt-3 flex justify-between">
      <button
        type="submit"
        [ngClass]="{
 'bg-[var(--theme-deafult-studentWithClick)]':insertForm.valid && fc['Accept'].value ==true,
'bg-[#FAE9EA] text-[#CB8B8E]':  insertForm.invalid,

}"
        class="text-[14px] btnH Lama-Regular w-full mb-5 hover:bg-[var(--theme-deafult-ParentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-studentWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        {{ "StudentSignUp.body.Submit" | translate }}
        <ng-container *ngIf="!submit">
          <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
        </ng-container>
      </button>
      </div>
    </form>
  </div>

  <div
    class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5"
    *ngIf="TeacherSteps.vefiryUser"
  >
    <app-vefiry-parent
      [DataVefiryUser]="DataVefiryUser"
      [phoneNumber]="fc['Mobile'].value"
      (Step3)="handleEvent($event, 'subject')"
    ></app-vefiry-parent>
  </div>
</div>
