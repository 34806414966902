import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { InstaSlider, LogoSlider } from '../../core/data/slider';
import { MostViewedLessonsService } from '../../Apis/most-viewed-lessons.service';
import { CustomPipeForImagesPipe } from '../../core/pipes/custom-pipe-for-images-pipe.pipe';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-most-popular-subjects',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    TranslateModule,
    CustomPipeForImagesPipe,
  ],
  templateUrl: './our-most-popular-subjects.component.html',
  styleUrls: ['./our-most-popular-subjects.component.scss'],
})
export class OurMostPopularSubjectsComponent {
  public InstaSliderConfig: any = LogoSlider;
  data: any[] = []; // Initialize data as an empty array
  @Input() type!: string;
  @Input()  showTeacherCount:boolean= true;
  @Input() academicleveid?: number;
protected loding:boolean =false;
  @Output() outputDat: EventEmitter<any> = new EventEmitter<boolean>();
  constructor(
    private MostViewedLessonsService: MostViewedLessonsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData(this.academicleveid);
  }

  getData(academicleveid?:number) {

    this.MostViewedLessonsService.GetTopBookedSubjectsOrTopViewedSubjects(
      this.type,
      academicleveid
    ).subscribe((res) => {
      this.data = res;

    });
  }

  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }
  }

  GoToSubjectOrLessonTeachers(id?: any) {
    const type = 'Subject';

    this.router.navigate([`/main/student/TeacherForSubjects/${type}/${id}`]);
    // else
    // this.router.navigate([`/main/student/SubjectDetails/${type}/${id}`]);
  }

  handleImageError(item: any): void {
    // Handle the image error, for example, by replacing it with a default image
    console.error(`Error loading image for item with subjectId ${item.subjectId}`);
    item.image = environment.defaultImage;
  }
}
