import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../../Model/Generic/generic-response';
import { IGetCheckOutBookTeacherSession } from 'src/app/shared/Model/teacher/get-check-out-book-teacher-session';
import { AuthService } from '../../../AuthService/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BookTeacherLessonSessionService {
  constructor(private http: HttpClient, protected auth: AuthService, private router:Router) {}

  GetCheckOutBookTeacherSession(obj:any ):Observable<IGetCheckOutBookTeacherSession>{
    if(  this.auth.getRoleId() == '3')
      {
          {
              if( !this.auth.getCurrentChildrenId())
                  this.router.navigate([`/main/parent/ParentStudents`]);
              obj.studentId=this.auth.getCurrentChildrenId();
          }

    }
    return this.http.post<IGenericResponseObject<IGetCheckOutBookTeacherSession>>(`${env.Server_URL}BookTeacherLessonSession/GetCheckOutBookTeacherSession`,obj).pipe( map((res) => res.data ))
  }
  CreateBookTeacherLessonSession(form:any ):Observable<any>{
    if(  this.auth.getRoleId() == '3')
      {
          {
              if( !this.auth.getCurrentChildrenId())
                  this.router.navigate([`/main/parent/ParentStudents`]);
              form.studentId=this.auth.getCurrentChildrenId();
          }

    };

    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}BookTeacherLessonSession/CreateBookTeacherLessonSession`,form).pipe( map((res) => res ))
  }

  RetrieveOrderInvoice(data: any): Observable<any> {
    return this.http.post<any>(`${env.Server_URL}Payment/UpdatePayment
    `, data);
  }
}
