import { Injectable } from '@angular/core';

export class Appointment {
  text!: string;
  timeFrom!: string;
  timeTo!: string;
  roomId!: number;
  id!: number;
  teacherlessonsessionId!: number;
  teachersubjectAcademicSemesterYearId!: number;
  teacherlessonId!: number;
  bookTeacherlessonsessionDetailId!: number;
  startDate!: Date;
  endDate!: Date;
 isCancel !:boolean
 actions !:boolean;
 teamMeetingLink!:string
}

export class Resource {
  text!: string;

  id!: number;

  color!: string;
}

const appointments: Appointment[] = []

const resources: Resource[] = [
  {
    text: 'Room 401',
    id: 1,
    color: '#337ab7',
  }, {
    text: 'Room 402',
    id: 2,
    color: '#000',
  }, {
    text: 'Room 403',
    id: 3,
    color: '#ae7fcc',
  }, {
    text: 'Room 407',
    id: 4,
    color: '#ff8817',
  }, {
    text: 'Room 409',
    id: 5,
    color: '#03bb92',
  },
];

@Injectable()
export class Service {
  getAppointments(): Appointment[] {
    return appointments;
  }

  getResources(): Resource[] {
    return resources;
  }
}
