import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { VefiryStudentComponent } from 'src/app/Authentication/Student/student-sign-up/vefiry-student/vefiry-student.component';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-Finance',
  templateUrl: './Finance.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BaseComponent,
    VefiryStudentComponent,
    TooltipModule,
    NavTabsComponent,
  ],
  styleUrls: ['./Finance.component.scss']
})
export class FinanceComponent  extends BaseComponent implements OnInit {
  MyTickets:any
  Controller: any;
  Finance: any;

  constructor(private LookupService:LookupService,private _SSRService: SSRService,
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    super();
    this.Controller = this._SSRService.Location(2);

   }

  ngOnInit() {
    this.seo();
    // this.GetFinance();
  }

GetFinance(){
  this.LookupService.GetFinance(this.Controller).subscribe(res=>{
    this.Finance=res.data
  })
}
 pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetFinance();
    this.pageNumber;
  }
  private seo(){
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.Finance.header.Finance);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
