<dx-scheduler
dir="ltr"
timeZone="Egypt/Los_Angeles"
  [dataSource]="appointmentsData"
  [views]="['day', 'week', 'month']"
  currentView="Auto Mode"
  [currentDate]="currentDate"
  [height]="650"
  appointmentTooltipTemplate="tooltip-template"
  [editing]="false"
  [showAllDayPanel]="false"
  [crossScrollingEnabled]="true"
  (onAppointmentFormOpening)="onAppointmentDblClick($event)"
>
  <!-- <dxi-view name="Auto Mode" type="month" maxAppointmentsPerCell="auto">
  </dxi-view>
  <dxi-view
    name="Unlimited Mode"
    type="month"
    maxAppointmentsPerCell="unlimited"
  >
  </dxi-view> -->
  <!-- <dxi-view name="Numeric Mode" type="month" [maxAppointmentsPerCell]="2">
  </dxi-view> -->
  <!-- <dxi-resource
    fieldExpr="roomId"
    label="room"
    [dataSource]="resourcesData"
  ></dxi-resource> -->

  <div *dxTemplate="let model of 'appointment-template'">
    <div class="showtime-preview">
      <div>{{ model.appointmentData.text }}</div>
      <div>
        Ticket Price:
        <strong>{{ "$" + model.targetedAppointmentData.text }}</strong>
      </div>
      <div class="dropdown-appointment-dates">
        {{
          (model.targetedAppointmentData.startDate | date : "shortTime") +
            " - " +
            (model.targetedAppointmentData.endDate | date : "shortTime")
        }}
      </div>
    </div>
  </div>
  <div *dxTemplate="let model of 'tooltip-template'">
    <div class="movie-tooltip">
      <div class="movie-info">
        <div
          class="flex items-center justify-content-end"
          *ngIf="model.targetedAppointmentData.actions"
        >
          <a
          (click)="targetedAppointmentData = model.targetedAppointmentData.id; openModal(template)"
            class="font-medium text-red-600 red:text-blue-500 hover:underline"
            ><i class="fa-solid fa-trash-can text-red-800"></i
          ></a>
        </div>
        <div class="movie-title">
          <div class="flex items-center">
            <div
            [ngClass]="{
              'bg-red-700':  model.appointmentData.actions ==false,
              'bg-green-600':  model.appointmentData.actions ==true
            }"
              class="inline-block w-4 h-4 mr-2  rounded-full"
            ></div>
            {{ model.appointmentData.text }}
          </div>
        </div>
        <div>{{ model.appointmentData.startDate | date : "medium" }}</div>
        <div class="mb-4">{{ model.appointmentData.endDate | date : "medium" }}</div>

        <div>
          <ng-container *ngIf="serverTime >=model.appointmentData.startDate && serverTime <=model.appointmentData.endDate &&!model.appointmentData.isCancel ; else notMeetingTime">
            <a *ngIf="serverTime >=model.appointmentData.startDate && serverTime <=model.appointmentData.endDate  &&!model.appointmentData.isCancel" (click)="StudentAttendance(model.appointmentData.bookTeacherlessonsessionDetailId)" [href]="model.appointmentData.teamMeetingLink"
          class=" text-[12px] mt-4 mb-4 m-lg:text-[14px]  sora-Medium w-100 text-white bg-[var(--theme-deafult-studentWithClick)] focus:bg-[var(--theme-deafult-studentWithClick)] my-3 hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-[var(--theme-deafult-studentWithClick)]"

          target="_blank" rel="noopener noreferrer">          {{ "StudentCalenderSchedule.body.teamsmicrosoft" | translate }}
           </a>
        </ng-container>
        <ng-template #notMeetingTime>
          {{ "StudentCalenderSchedule.body.Meetingisnotcurrentlyavailable" | translate }}
        </ng-template>
      </div>
    </div>
    </div>
  </div>

  <dxo-editing
    #editingOptions
    [allowAdding]="false"
    [allowDeleting]="false"
    [allowUpdating]="false"
    [allowResizing]="false"
    [allowDragging]="false"
    [allowAddShape]="false"
    [allowDependencyAdding]="false"
    [allowResourceUpdating]="false"
  ></dxo-editing>
</dx-scheduler>



<ng-template #template>
  <div class="" style="position: relative; top: 50%">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <div>
        <div class="p-6 text-center">
          <div class="text-center">
            <img
              src="assets/images/MrS-Cool/delete-Icon.png"
              class="m-auto p-3 w-16"
              alt="delete-Icon"
            />
          </div>
          <h3 class="mb-3    ">
            {{'StudentCalenderSchedule.body.Are-you-sure-you-want-to-Cancel-Book-Lesson-Session' | translate}}
          </h3>
          <button
          (click)="deleteEvent(targetedAppointmentData);modalRef?.hide()"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 sora-Regular rounded-lg text-sm inline-flex items-center px-5 py-3 text-center mr-2"
          >
            {{'StudentCalenderSchedule.body.Yes' |translate}}
          </button>
          <button
            (click)="modalRef?.hide()"
            type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm sora-Regular px-5 py-3 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            {{'StudentCalenderSchedule.body.No' |translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
