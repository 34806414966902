






<div class="container relative bg-white rounded-lg shadow dark:bg-gray-700 "style="width: 600px" >


  <div class=" w-full pb-0 " style="width: 600px">
    <div >
        <div >
          <form
          class="mb-5 max-md:px-1"
          [formGroup]="insertForm"
          (ngSubmit)="onSubmit()"
        >
            <div class="p-6 text-right">
              <div class="text-center">
                <label for="message" class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{"Lesson Brief English" |translate}}</label>
                <textarea id="message" formControlName="teacherBriefEn"           appDisableWritingArabic
                rows="4" class="block p-2.5 w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tell us about your Lesson Brief in English"></textarea>


              </div>
              <div class="text-center">
                <label for="message" class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{"Lesson Brief Arabic" |translate}}</label>
                <textarea id="message" formControlName="teacherBrief"           appDisableWritingEnglish
                rows="4" class="block p-2.5 w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tell us about your Lesson Brief in Arabic"></textarea>
              </div>
                 <button   type="submit" class="mt-2 text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    Save
                </button>
                <button (click)="bsModalRef.hide()"type="button" class=" mt-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
            </div>
          </form>
        </div>
</div>
  </div>
</div>


