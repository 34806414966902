<div class="trxt-center youtube ">

  <iframe width="560" height="315" src="https://www.youtube.com/embed/x1JJTAybn6Q?si=xpBv48htBoubhfNm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

  <p class="text-black text-center text-[16px] sora-Regular  my-2">

      {{ "ManageMySubjects.header.Explanation" | translate }}

  </p>
</div>


<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
    style="border-radius: 15px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >

      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left sora-bold dark:text-white underTitle">
              {{ "ManageMySubjects.header.filter-myschedual" | translate }}
            </h2>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div
      class="bg-[#ffffff] p-[40px] cardsSpace rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] mt-3 py-6"
    >
      <form class="max-md:px-1" [formGroup]="SearchForm">
        <div class="row">
          <div class="col-md-9 col-12">
            <div class="grid md:gap-x-5 lg:grid-cols-3 md:grid-cols-1">
              <div>
                <label
                  for="educationTypeIdSearch"
                  class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                  >{{ "ManageMySubjects.form.Education-Type" | translate }}
                </label>
                <ng-select
                  id="educationTypeIdSearch"
                  (change)="ChangEducationTypeSearch()"
                  #educationTypeS
                  [selectOnTab]="true"
                  formControlName="educationTypeId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fcSearch['educationTypeId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fcSearch['educationTypeId'].errors &&
                    fcSearch['educationTypeId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fcSearch['educationTypeId'].valid &&
                    (fcSearch['educationTypeId'].touched ||
                      fcSearch['educationTypeId'].value)
                  "
                  [class.is-invalid]="
                    fcSearch['educationTypeId'].invalid &&
                    fcSearch['educationTypeId'].touched
                  "
                  [ngClass]="{
            'class-1': fcSearch['educationTypeId'].invalid && (fcSearch['educationTypeId'].dirty || fcSearch['educationTypeId'].touched),
            'class-2': fcSearch['educationTypeId'].valid && (fcSearch['educationTypeId'].dirty || fcSearch['educationTypeId'].touched),
          }"
                >
                  <ng-option value="">
                    {{
                      "ManageMySubjects.form.Choose-your-EducationType"
                        | translate
                    }}
                  </ng-option>
                  <ng-option
                    *ngFor="let item of EducationTypes"
                    [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div>
                <label
                  for="educationLevelIdSearch"
                  class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                  >{{ "ManageMySubjects.form.Education-Level" | translate }}
                </label>
                <ng-select
                  id="educationLevelIdSearch"
                  (change)="ChangEducationLevelSearch()"
                  #educationLevelIdSearch
                  [selectOnTab]="true"
                  formControlName="educationLevelId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fcSearch['educationLevelId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fcSearch['educationLevelId'].errors &&
                    fcSearch['educationLevelId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fcSearch['educationLevelId'].valid &&
                    (fcSearch['educationLevelId'].touched ||
                      fcSearch['educationLevelId'].value)
                  "
                  [class.is-invalid]="
                    fcSearch['educationLevelId'].invalid &&
                    fcSearch['educationLevelId'].touched
                  "
                  [ngClass]="{
            'class-1': fcSearch['educationLevelId'].invalid && (fcSearch['educationLevelId'].dirty || fcSearch['educationLevelId'].touched),
            'class-2': fcSearch['educationLevelId'].valid && (fcSearch['educationLevelId'].dirty || fcSearch['educationLevelId'].touched),
          }"
                >
                  <ng-option value="">
                    {{
                      "ManageMySubjects.form.Choose-Education-level" | translate
                    }}
                  </ng-option>
                  <ng-option
                    *ngFor="let item of EducationLevelSearch"
                    [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>

              <div>
                <label
                  for="academicYearIdSearch"
                  class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                  >{{ "ManageMySubjects.form.Academic-Year" | translate }}
                </label>
                <ng-select
                  id="academicYearIdSearch"
                  (change)="ChangAcademicYearSearch()"
                  #academicYearIdS
                  [selectOnTab]="true"
                  formControlName="academicYearId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fcSearch['academicYearId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fcSearch['academicYearId'].errors &&
                    fcSearch['academicYearId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fcSearch['academicYearId'].valid &&
                    (fcSearch['academicYearId'].touched ||
                      fcSearch['academicYearId'].value)
                  "
                  [class.is-invalid]="
                    fcSearch['academicYearId'].invalid &&
                    fcSearch['academicYearId'].touched
                  "
                  [ngClass]="{
            'class-1': fcSearch['academicYearId'].invalid && (fcSearch['academicYearId'].dirty || fcSearch['academicYearId'].touched),
            'class-2': fcSearch['academicYearId'].valid && (fcSearch['academicYearId'].dirty || fcSearch['academicYearId'].touched),
          }"
                >
                  <ng-option value="">
                    {{
                      "ManageMySubjects.form.Choose-acdemic-year" | translate
                    }}
                  </ng-option>
                  <ng-option
                    *ngFor="let item of AcademicYearSearch"
                    [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="mt-4">
                <label
                  for="asubjectIdSearch"
                  class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                  >{{ "ManageMySubjects.form.Subject" | translate }}
                </label>
                <ng-select
                  id="asubjectIdSearch"
                  #asubjectIdS
                  [selectOnTab]="true"
                  formControlName="subjectId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fcSearch['subjectId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fcSearch['subjectId'].errors &&
                    fcSearch['subjectId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fcSearch['subjectId'].valid &&
                    (fcSearch['subjectId'].touched ||
                      fcSearch['subjectId'].value)
                  "
                  [class.is-invalid]="
                    fcSearch['subjectId'].invalid &&
                    fcSearch['subjectId'].touched
                  "
                  [ngClass]="{
          'class-1': fcSearch['subjectId'].invalid && (fcSearch['subjectId'].dirty || fcSearch['subjectId'].touched),
          'class-2': fcSearch['subjectId'].valid && (fcSearch['subjectId'].dirty || fcSearch['subjectId'].touched),
        }"
                >
                  <ng-option value="">
                    {{ "ManageMySubjects.form.Choose-Subject" | translate }}
                  </ng-option>
                  <ng-option
                    *ngFor="let item of SubjectSemesterSearch"
                    [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="mt-4">
                <label
                  for="statusIdSearch"
                  class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                  >{{ "ManageMySubjects.form.Status" | translate }}
                </label>
                <ng-select
                  id="statusIdSearch"
                  #Status
                  [selectOnTab]="true"
                  formControlName="statusId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="fcSearch['statusId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                  [isDisabled]="
                    !fcSearch['statusId'].errors && fcSearch['statusId'].touched
                  "
                  containerClass=""
                  [searchable]="true"
                  [class.is-valid]="
                    fcSearch['statusId'].valid &&
                    (fcSearch['statusId'].touched || fcSearch['statusId'].value)
                  "
                  [class.is-invalid]="
                    fcSearch['statusId'].invalid && fcSearch['statusId'].touched
                  "
                  [ngClass]="{
            'class-1': fcSearch['statusId'].invalid && (fcSearch['statusId'].dirty || fcSearch['statusId'].touched),
            'class-2': fcSearch['statusId'].valid && (fcSearch['statusId'].dirty || fcSearch['statusId'].touched),
          }"
                >
                  <ng-option value="">
                    {{ "ManageMySubjects.form.Choose-your-Status" | translate }}
                  </ng-option>
                  <ng-option *ngFor="let item of StatusSearch" [value]="item.id"
                    >{{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 d-flex items-end align-content-end">
            <button
              type="submit"
              [disabled]="!submitSearsh"
              (click)="ChangeSearchInp()"
              class="ml-auto text-white btnFilter bg-[var(--theme-deafult-TeacherWithClick)] focus:outline-none focus:ring-0 focus:ring-gray-300 rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
            >
              {{ "ManageMySubjects.form.Filter" | translate }}
              <ng-container *ngIf="!submitSearsh">
                <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
              </ng-container>
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] cardsSpace p-0 dark:border dark:border-[var(--theme-deafult-TeacherWithClick)] mt-3 p-6"
    style="border-radius: 15px"
    (opened)="panelOpenState = true"
    [expanded]="true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >
      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] px-6 pt-2 mb-3 w-full"
        >
          <div class="pb-3">
            <img src="assets/images/MrS-Cool/setting.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3 class="text-left sora-bold dark:text-white underTitle">
              {{ "ManageMySubjects.header.Title3" | translate }}
            </h3>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <div class="relative overflow-x-auto">
        <div
          class="dark:text-white md:flex max-md:block justify-end mt-3 px-6 underAddressLine"
        >
          <div
            class="flex items-center text-[10px] md:text-[12px] sora-Regular mx-2"
          >
            <div class="inline-block w-4 h-4 mr-3 rtl:ml-3 bg-[#39B002]"></div>
            {{ "ManageMySubjects.form.Approved" | translate }}
          </div>
          <div
            class="flex items-center text-[10px] md:text-[12px] sora-Regular mx-2"
          >
            <div class="inline-block w-4 h-4 mr-3 rtl:ml-3 bg-[#D9C93E]"></div>
            {{ "ManageMySubjects.form.In-Preview" | translate }}
          </div>
          <div
            class="flex items-center text-[10px] md:text-[12px] sora-Regular mx-2"
          >
            <div class="inline-block w-4 h-4 mr-3 rtl:ml-3 bg-[#FF0000]"></div>
            {{ "ManageMySubjects.form.Rejected" | translate }}
          </div>
        </div>
        <div
          class="d-flex align-items-center startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] px-6 pt-2 mb-3"
        >
          <table
            class="w-full text-sm dark:bg-[#1a202c] text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-scroll"
          >
            <thead
              class="sora-Medium text-white dark:bg-[#1a202c] rounded-lg bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
            >
              <tr>
                <th scope="col" class="px-6 py-2">
                  {{ "ManageMySubjects.form.Subjects" | translate }}
                </th>

                <th scope="col-2" colspan="1" class="px-6 py-2">
                  <div
                    class="grid gap-4 grid-cols-1 border-t-1 pt-2 border-white"
                  >
                    <p class="m-0">
                      {{ "ManageMySubjects.form.Group-Price" | translate }}
                    </p>
                  </div>
                </th>

                <th scope="col" class="px-6 py-2">
                  {{ "ManageMySubjects.form.Status" | translate }}
                </th>
                <th scope="col" class="px-6 py-2">
                  {{ "ManageMySubjects.form.Action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-[#1a202c] dark:border-gray-700"
                *ngFor="let item of TeacherSubjectAcademic; let y = index"
              >
                <th
                  scope="row"
                  class="px-6 py-4 text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  {{ item.subjectDisplayName }}
                </th>
                <!-- <td class="px-6 py-4">
              {{ item.individualCost }}
            </td> -->
                <td class="px-6 py-4">
                  {{ item.groupCost }}
                </td>
                <!-- <td class="px-6 py-4">
              {{ item.minGroup }}
            </td>
            <td class="px-6 py-4">
              {{ item.maxGroup }}
            </td> -->

                <td
                  class="px-4 py-2 text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                >
                  <div class="flex items-center">
                    <div
                      *ngIf="item.statusId == 1"
                      [tooltip]="'ManageMySubjects.form.Approved' | translate"
                      [ngClass]="{
                    'bg-[#39B002]': item.statusId == 1,
                  }"
                      class="inline-block w-4 h-4 mr-2 rounde d-full"
                    ></div>
                    <div
                      *ngIf="item.statusId == 3"
                      [tooltip]="'ManageMySubjects.form.In-Preview' | translate"
                      [ngClass]="{
                        'bg-[#D9C93E]': item.statusId == 3
                      }"
                      class="inline-block w-4 h-4 mr-2 rounde d-full"
                    ></div>
                    <div
                      *ngIf="item.statusId == 2"
                      [tooltip]="'ManageMySubjects.form.Rejected' | translate"
                      [ngClass]="{
                    'bg-[#FF0000]': item.statusId == 2,
                  }"
                      class="inline-block w-4 h-4 mr-2 rounde d-full"
                    ></div>

                    <!-- 95 -->
                  </div>
                </td>
                <td class="px-6 py-4 d-flex justify-between">
                  <a
                    [tooltip]="
                      'ManageMySubjects.form.Click-Edit-Subject' | translate
                    "
                    (click)="Edit(item); form.expanded = true"
                    class="cursor-pointer font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/edit.png"
                      class="w-4"
                      alt=""
                      srcset=""
                  /></a>
                  <a
                    [tooltip]="
                      'ManageMySubjects.form.Click-Manage-Lessons'
                        | translate
                    "
                    (click)="item.statusId != 2 ? navigate(item) : null"
                    routerLinkActive="router-link-active"
                    class="cursor-pointer font-medium mx-1 text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/write.png"
                      class="w-4"
                      alt=""
                      srcset=""
                  /></a>

                  <a
                    [tooltip]="
                      'ManageMySubjects.form.Click-Delete-Subject' | translate
                    "
                    (click)="TableDeletDocID = item.id; openModal(template)"
                    class="cursor-pointer font-medium mr-1 text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/delete.png"
                      class="w-4"
                      alt="Icons delete"
                      srcset=""
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div> -->

      <ng-template #template>
        <div class="" style="position: relative; top: 50%">
          <div class="relative pb-3 bg-white rounded-2xl shadow dark:bg-white">
            <div>
              <div class="pt-5 pb-2 px-12 text-center">
                <div class="text-center pb-6">
                  <img
                    src="assets/images/MrS-Cool/delete-Icon.png"
                    class="m-auto p-3"
                    alt=""
                  />
                </div>
                <h3
                  class="mb-3 text-[12px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-400"
                >
                  {{
                    "ManageMySubjects.form.Are-you-sure-you-want-to-delete"
                      | translate
                  }}
                </h3>
                <button
                  [disabled]="!submit"
                  (click)="
                    DeleteTeacherSubjectAcademic(TableDeletDocID);
                    modalRef?.hide()
                  "
                  type="button"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:-[var(--theme-deafult-TeacherWithClick)] focus:ring-0 focus:outline-none focus:ring-red-300 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] rounded-lg sora-Medium text-[12px] md:text-[14px] inline-flex items-center px-5 h-[3.8em] text-center mr-2"
                >
                  {{ "ManageMySubjects.form.Yes" | translate }}
                </button>
                <button
                  (click)="modalRef?.hide()"
                  type="button"
                  style="
                    border: 2px solid var(--theme-deafult-TeacherWithClick);
                  "
                  class="bg-white ml-2 hover:bg-gray-100 focus:ring-0 focus:outline-none focus:ring-transparent rounded-lg sora-Medium text-[12px] md:text-[14px] px-5 h-[3.8em] hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-[#1a202c] dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  {{ "ManageMySubjects.form.No" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
  <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border   dark:border-[var(--theme-deafult-TeacherWithClick)]"
    style="border-radius: 15px"
    #form
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
    >
      <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white sora-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
      <mat-panel-description>
        <div
          class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-[var(--theme-deafult-TeacherWithClick)] w-full"
        >
          <div class="pb-3">
            <img src="assets/images/Icons/add-file-button_.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h2 class="text-left sora-bold dark:text-white underTitle">
              {{
                fc["id"].value != null
                  ? ("ManageMySubjects.header.Title2" | translate)
                  : ("ManageMySubjects.header.Title" | translate)
              }}
            </h2>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div>
      <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
        <div class="grid gap-x-5 lg:grid-cols-3 grid-cols-1 startPagePadding">
          <div>
            <label
              for="countries"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{ "ManageMySubjects.form.Education-Type" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              ></label
            >

            <ng-select
              *ngIf="fc['id'].value == null"
              id="Education_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'EducationTypeId'"
              class="form-control p-0"
              (change)="ChangEducationType()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['EducationTypeId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['EducationTypeId'].valid &&
                (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
              "
              [class.is-invalid]="
                fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
              "
              [ngClass]="{
    'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
    'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
  }"
            >
              <ng-option value="">
                {{
                  "ManageMySubjects.form.Choose-your-EducationType" | translate
                }}
              </ng-option>
              <ng-option *ngFor="let item of EducationTypes" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>

            <ng-select
              *ngIf="fc['id'].value !== null"
              id="Education_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'EducationTypeIdUpdate'"
              class="form-control p-0"
              (change)="ChangEducationType()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['EducationTypeId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['EducationTypeId'].valid &&
                (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
              "
              [class.is-invalid]="
                fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
              "
              [ngClass]="{
    'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
    'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
  }"
            >
              <ng-option value="">
                {{
                  "ManageMySubjects.form.Choose-your-EducationType" | translate
                }}
              </ng-option>
              <ng-option *ngFor="let item of EducationTypes" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          <div>
            <label
              for="countries"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{ "ManageMySubjects.form.Education-Level" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              ></label
            >

            <ng-select
              *ngIf="fc['id'].value == null"
              id="EducationLevel_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'EducationLevelId'"
              class="form-control p-0"
              (change)="ChangEducationLevel()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['EducationLevelId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['EducationLevelId'].errors && fc['EducationLevelId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['EducationLevelId'].valid &&
                (fc['EducationLevelId'].touched || fc['EducationLevelId'].value)
              "
              [class.is-invalid]="
                fc['EducationLevelId'].invalid && fc['EducationLevelId'].touched
              "
              [ngClass]="{
    'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
    'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
  }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-Education-level" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of EducationLevel" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>

            <ng-select
              *ngIf="fc['id'].value !== null"
              id="EducationLevel_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'EducationLevelIdUpdate'"
              class="form-control p-0"
              (change)="ChangEducationLevel()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['EducationLevelId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['EducationLevelId'].errors && fc['EducationLevelId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['EducationLevelId'].valid &&
                (fc['EducationLevelId'].touched || fc['EducationLevelId'].value)
              "
              [class.is-invalid]="
                fc['EducationLevelId'].invalid && fc['EducationLevelId'].touched
              "
              [ngClass]="{
    'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
    'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
  }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-Education-level" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of EducationLevel" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>
          </div>

          <div>
            <label
              for="countries"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{ "ManageMySubjects.form.Academic-Year" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              ></label
            >

            <ng-select
              *ngIf="fc['id'].value == null"
              id="SubjectSemester_"
              #SubjectSemesterRev
              [selectOnTab]="true"
              [formControlName]="'SubjectSemesterId'"
              class="form-control p-0"
              (change)="ChangAcademicYear()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['SubjectSemesterId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['SubjectSemesterId'].errors &&
                fc['SubjectSemesterId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['SubjectSemesterId'].valid &&
                (fc['SubjectSemesterId'].touched ||
                  fc['SubjectSemesterId'].value)
              "
              [class.is-invalid]="
                fc['SubjectSemesterId'].invalid &&
                fc['SubjectSemesterId'].touched
              "
              [ngClass]="{
    'class-1': fc['SubjectSemesterId'].invalid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
    'class-2': fc['SubjectSemesterId'].valid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
  }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-acdemic-year" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of AcademicYear" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>

            <ng-select
              *ngIf="fc['id'].value !== null"
              id="SubjectSemester_"
              #SubjectSemesterRev
              [selectOnTab]="true"
              [formControlName]="'SubjectSemesterIdUpdate'"
              class="form-control p-0"
              (change)="ChangAcademicYear()"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['SubjectSemesterId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['SubjectSemesterId'].errors &&
                fc['SubjectSemesterId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['SubjectSemesterId'].valid &&
                (fc['SubjectSemesterId'].touched ||
                  fc['SubjectSemesterId'].value)
              "
              [class.is-invalid]="
                fc['SubjectSemesterId'].invalid &&
                fc['SubjectSemesterId'].touched
              "
              [ngClass]="{
    'class-1': fc['SubjectSemesterId'].invalid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
    'class-2': fc['SubjectSemesterId'].valid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
  }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-acdemic-year" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of AcademicYear" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div
          class="grid gap-x-6 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 py-0 startPagePadding"
        >
          <div>
            <label
              for="countries"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{ "ManageMySubjects.form.Subjects" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              ></label
            >

            <ng-select
              *ngIf="fc['id'].value == null"
              id="subjectSemesterYearId_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'subjectSemesterYearId'"
              class="form-control p-0"
              dropdownPosition="auto"
              (change)="GetSubjectSelected()"
              labelForId="validation02"
              [tooltip]="fc['subjectSemesterYearId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['subjectSemesterYearId'].errors &&
                fc['subjectSemesterYearId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['subjectSemesterYearId'].valid &&
                (fc['subjectSemesterYearId'].touched ||
                  fc['subjectSemesterYearId'].value)
              "
              [class.is-invalid]="
                fc['subjectSemesterYearId'].invalid &&
                fc['subjectSemesterYearId'].touched
              "
              [ngClass]="{
                  'class-1': fc['subjectSemesterYearId'].invalid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
                  'class-2': fc['subjectSemesterYearId'].valid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
                }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-Subject" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of SubjectSemester" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>

            <ng-select
              *ngIf="fc['id'].value !== null"
              (change)="GetSubjectSelected()"
              id="subjectSemesterYearId_"
              #EducationType
              [selectOnTab]="true"
              [formControlName]="'subjectSemesterYearIdUpdate'"
              class="form-control p-0"
              dropdownPosition="auto"
              labelForId="validation02"
              [tooltip]="fc['subjectSemesterYearId'].errors?.['required'] ?  ('this field is required' | translate) : null"
              [isDisabled]="
                !fc['subjectSemesterYearId'].errors &&
                fc['subjectSemesterYearId'].touched
              "
              containerClass=""
              [searchable]="true"
              [class.is-valid]="
                fc['subjectSemesterYearId'].valid &&
                (fc['subjectSemesterYearId'].touched ||
                  fc['subjectSemesterYearId'].value)
              "
              [class.is-invalid]="
                fc['subjectSemesterYearId'].invalid &&
                fc['subjectSemesterYearId'].touched
              "
              [ngClass]="{
                'class-1': fc['subjectSemesterYearId'].invalid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
                'class-2': fc['subjectSemesterYearId'].valid && (fc['subjectSemesterYearId'].dirty || fc['subjectSemesterYearId'].touched),
              }"
            >
              <ng-option value="">
                {{ "ManageMySubjects.form.Choose-acdemic-year" | translate }}
              </ng-option>
              <ng-option *ngFor="let item of SubjectSemester" [value]="item.id"
                >{{ item.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div
          class="grid gap-x-5 lg:grid-cols-3 md:grid-cols-1 grid-cols-1 startPagePadding"
        >
          <label
            class="col-span-2 block text-sm text-[var(--theme-deafult-TeacherWithClick)] m-0 dark:text-white"
            >{{ "ManageMySubjects.form.For-Group" | translate }}
          </label>

          <span class="border-b-2 col-span-4"></span>
          <div class="max-md:col-span-4">
            <label
              for="IndividuaGroupCostlCost_"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{ "ManageMySubjects.form.Price" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
                >*</span
              >
            </label>
            <input
              type="text"
              appOnlyNumbersWithDouble
              formControlName="GroupCost"
              [tooltip]="fc['GroupCost'].hasError('pattern') ? ('The Price should not start with 0' | translate) : (fc['GroupCost'].errors?.['required'] ? ('Group Price From Required' | translate) : null)"
              [ngClass]="{
                'class-1': fc['GroupCost'].invalid && (fc['GroupCost'].dirty || fc['GroupCost'].touched),
                'class-2': fc['GroupCost'].valid && (fc['GroupCost'].dirty || fc['GroupCost'].touched),
              }"
              id="GroupCost"
              class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#1a202c] dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your Price"
              [placeholder]="'Enter_your_Pricer' | translate"
              required
            />
            <span
              *ngIf="this.fc['subjectSemesterYearId'].value"
              class="text-[10px] sora-Regular"
              >{{ "Recommended " | translate }}
              <span class="text-[12px] sora-bold">{{
                Subject?.groupCostFrom
              }}</span>
              {{ "to" }}
              <span class="text-[12px] sora-bold">
                {{ Subject?.groupCostTo }} </span
              >{{ "EGP" }}</span
            >
          </div>

        </div>

        <div
          class="grid gap-x-5 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 startPagePadding"
        >
          <label
            class="col-span-4 block text-sm border-b-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ManageMySubjects.form.For-Teacher-Brief" | translate }}
          </label>
          <div class="max-md:col-span-4">
            <label
              for="message"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{
                "ManageMySubjects.form.Teacher-Brief-English" | translate
              }}</label
            >
            <textarea
              id="message"
              formControlName="TeacherBriefEn"
              appDisableWritingArabic
              rows="4"
              class="block w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-[#1a202c] dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              [placeholder]="
                'ManageMySubjects.form.Tell-us-about-your-subject' | translate
              "
            ></textarea>
          </div>
          <div class="max-md:col-span-4">
            <label
              for="message"
              class="block text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >{{
                "ManageMySubjects.form.Teacher-Brief-Arabic" | translate
              }}</label
            >
            <textarea
              id="message"
              appDisableWritingEnglish
              formControlName="teacherBrief"
              rows="4"
              class="block w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-[#1a202c] dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              [placeholder]="
                'ManageMySubjects.form.Tell-us-about-your-subject' | translate
              "
            ></textarea>
          </div>

          <div
            class="text-right col-span-3 pt-5 d-flex align-items-end justify-end"
          >
            <button
              type="submit"
              [disabled]="!submit"
              class="text-white bg-[var(--theme-deafult-TeacherWithClick)] btnWithForm focus:outline-none focus:ring-0 focus:ring-gray-300 rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
            >
              {{
                fc["id"].value !== null
                  ? ("ManageMySubjects.form.Update" | translate)
                  : ("ManageMySubjects.form.Save" | translate)
              }}
              <ng-container *ngIf="!submit">
                <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
              </ng-container>
            </button>
            <button
              type="button"
              class="text-[var(--theme-deafult-TeacherWithClick)] btnWithForm bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-0 focus:ring-transparent rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:border-gray-600 dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:hover:border-gray-600 dark:focus:ring-gray-700"
              (click)="Clear()"
            >
              {{ "ManageMySubjects.form.Clear" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
