import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { SubjectInfo, TeacherSubjectAcademicSemeste } from 'src/app/shared/Model/teacher/subject-info';
@Injectable({
  providedIn: 'root'
})
export class SubjectInfoService {
  constructor(private http: HttpClient) {}


  AddTeacherSubjectAcademic(data?:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/Create`,data)
  }
  AddTeacherSubjectAcademicForRegister(data?:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/CreateForRegister`,data)
  }
  UpdateTeacherSubjectAcademic(data:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/Update`,data??{})
  }
  GetTeacherSubjectAcademic(data:any ):Observable<TeacherSubjectAcademicSemeste[]>{
    return this.http.post<IGenericResponse<TeacherSubjectAcademicSemeste>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/GetTeacherSubjectAcademicSemesterYearList`,data).pipe( map((res) => res.data ))
  }
  SubjectOrLessonTeachers(data:any):Observable<any[]>{
    return this.http.post<IGenericResponse<any>>(`${env.Server_URL}SubjectOrLessonTeachers/GetAllTeacher`,data).pipe( map((res) => res.data ))
  }
  GetTeacherSubjectGroupDetail(id:number):Observable<any>{
    return this.http.get<IGenericResponse<any>>(`${env.Server_URL}SubjectOrLessonTeachers/GetTeacherSubjectGroupDetail?teacherSubjectId=${id}`).pipe( map((res) => res.data ))
  }
  GetTeachersMostBooked(data:any):Observable<any>{
    return this.http.post<IGenericResponse<any>>(`${env.Server_URL}SubjectOrLessonTeachers/GetTeachersMostBooked`,data).pipe( map((res) => res.data ))
  }
  DeleteTeacherSubjectAcademic(id:number):Observable<TeacherSubjectAcademicSemeste[]>{
    return this.http.get<IGenericResponse<TeacherSubjectAcademicSemeste>>(`${env.Server_URL}TeacherSubjectAcademicSemesterYear/Delete?id=${id}`).pipe( map((res) => res.data ))
  }



//////////////////////////////////////

GetTeacherLessonGroupDetail(id:any ):Observable<TeacherSubjectAcademicSemeste[]>{
  return this.http.get<IGenericResponse<TeacherSubjectAcademicSemeste>>(`${env.Server_URL}SubjectOrLessonTeachers/GetTeacherLessonGroupDetail?teacherLessonId=${id}`).pipe( map((res) => res.data ))
}

GetTeacherAvaliableSchedual(data:any ):Observable<TeacherSubjectAcademicSemeste[]>{
  return this.http.get<IGenericResponse<TeacherSubjectAcademicSemeste>>(`${env.Server_URL}SubjectOrLessonTeachers/GetTeacherAvaliableSchedual?teacherid=${data.teacherId}&lessonDuration=${data.lessonDuration}&startDate=${data.startDate}`).pipe( map((res) => res.data ))
}


}
