import { CommonModule, formatDate, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';
import { VefiryStudentComponent } from 'src/app/Authentication/Student/student-sign-up/vefiry-student/vefiry-student.component';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-StudentFinance',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BaseComponent,
    VefiryStudentComponent,
    TooltipModule,
    NavTabsComponent,
    NgxPaginationModule
  ],
  templateUrl: './StudentFinance.component.html',
  styleUrls: ['./StudentFinance.component.css']
})
export class StudentFinanceComponent extends BaseComponent implements OnInit {
  MyTickets:any
  Controller: any;
  Finance: any;
  page!: number;
  CurrentDate: string;
  FinanceSubjects: any;
  pageNumberPagedFinanceLessons= 1;
  pagerPagedFinanceLessons: any = {
    maxResultCount: 25,
   skipCount: 0
 };
  totalRecordsPagedFinanceLessons=0;
  FinanceLessons: any;
  constructor(private LookupService:LookupService,private _SSRService: SSRService,
    private seoService: SeoService,
    private layoutService: LayoutService) {
    super();
    this.Controller = this._SSRService.Location(2);
    const now = new Date(Date.now());

    // Define options for the desired date format
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
  };
    // Format the current date
    this.CurrentDate = now.toLocaleDateString('en-GB', options);
  }

  ngOnInit() {
    this.GetFinance();
    this.GetPagedFinanceSubjects();
    this.GetPagedFinanceLessons();
  }
  GetFinance(){
    this.LookupService.GetFinance(this.Controller).subscribe(res=>{
      this.Finance=res.data
    this.seo();

    })
  }
  GetPagedFinanceSubjects(){
    this.LookupService.PagedFinanceSubjects(this.Controller,this.pager).subscribe(res=>{
this.FinanceSubjects=res.data.items
this.totalRecords=res.data.totalCount
    })
  }
   pageChanged(page: any) {
      this.pageNumber = page; // -1 * pageSize;
      this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
      this.GetPagedFinanceSubjects();
      this.pageNumber;

    }


    GetPagedFinanceLessons(){
      this.LookupService.PagedFinanceLessons(this.Controller,this.pagerPagedFinanceLessons).subscribe(res=>{
  this.FinanceLessons=res.data.items
  this.totalRecordsPagedFinanceLessons=res.data.totalCount
      })
    }

    pageChangedPagedFinanceLessons(page: any) {
    this.pageNumberPagedFinanceLessons = page; // -1 * pageSize;
    this.pagerPagedFinanceLessons.skipCount= (this.pageNumberPagedFinanceLessons - 1) * this.pagerPagedFinanceLessons.maxResultCount;
    this.GetPagedFinanceLessons();
    this.pageNumberPagedFinanceLessons;

  }
  private seo(){
    // this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    // );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.Finance.header.Finance);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

  getFormattedDate(date: Date): string {

    const lang = this.layoutService.config.langu;
    const locale =lang === 'ar' ? 'ar-EG' : 'en-US';

    return formatDate(date, 'd MMM yyyy', locale);
  }
}
