import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { OurMostPopularLessonsComponent } from 'src/app/shared/components/our-most-popular-lessons/our-most-popular-lessons.component';
import { OurMostPopularSubjectsComponent } from 'src/app/shared/components/our-most-popular-subjects/our-most-popular-subjects.component';
import { MostBookedTeachersComponent } from '../../Teacher/MostBookedTeachers/most-booked-teachers/most-booked-teachers.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MostViewedLessonsService } from 'src/app/shared/Apis/most-viewed-lessons.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { LogoSlider } from 'src/app/shared/core/data/slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-showing-results-for-home',
  standalone: true,
  imports: [
    CommonModule,
    OurMostPopularLessonsComponent,
    OurMostPopularSubjectsComponent,
    MostBookedTeachersComponent,
    NgFor,
    NgIf,
    TranslateModule,
    CarouselModule,
    TooltipModule,
    TooltipModule,
    RouterLink,
    CustomPipeForImagesPipe,
  ],
  templateUrl: './showing-results-for-home.component.html',
  styleUrls: ['./showing-results-for-home.component.scss'],
})
export class ShowingResultsForHomeComponent
  extends BaseComponent
  implements OnInit
{
  protected AllSubjects!: any[];
  protected academicleveid!: number;
  protected academicLevelName!: string;
  public InstaSliderConfig: any = LogoSlider;

  constructor(
    private seoService: SeoService,
    private layoutService: LayoutService,
    private _MostViewedLessons: MostViewedLessonsService,
    private route: ActivatedRoute
  ) {
    super();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // Access query parameters using params object
      // Assuming params is an object with a property 'academicleveid'
      this.academicleveid = params['academicleveid'];
      // Assuming pager is an object with a property 'academicEducationLevelId'
      this.pager.academicEducationLevelId = this.academicleveid;
      const semesterId = params['semesterId'];
      if (semesterId) this.pager.semesterId = semesterId;

      this.GetAllSubjects(this.pager);
    });
  }

  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }
  }
  private GetAllSubjects(obj: any) {
    this._MostViewedLessons.GetAllSubjects(obj).subscribe((res) => {
      this.AllSubjects = res.getAllSubjects;
      this.academicLevelName = res.academicLevelName;
      this.seo()

    });
  }
  handleImageError(item: any): void {
    // Handle the image error, for example, by replacing it with a default image
    console.error(
      `Error loading image for item with subjectId ${item.subjectId}`
    );
    item.image = environment.defaultImage;
  }
  private seo(){
    // this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    // );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(this.academicLevelName);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Showing.results + this.academicLevelName)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Showing.results  + this.academicLevelName))
      // this.seoService.setMetaTags(translations);
    });

  }
}
