<!-- <p>student-edit-profile works!</p> -->
<div
  [dir]="'ltr'"
  class="flex _ flex-wrap justify-ce nter align-items-center"
  *ngIf="ConnectParentStudentSteps.Vefiry"
>
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6">
    <div class="d-flex flex-column align-items-center" for="dropzone-file">
      <div class="position-relative text-center 1" for="dropzone-file">
        <img
          onclick="document.getElementById('dropzone-file').click()"
          [src]="imageSrc ? imageSrc : 'assets/images/Logo/logo.svg'"
          class="m-auto rounded-circle fixed-size-img"
        />
        <span
          class="edit"
          onclick="document.getElementById('dropzone-file').click()"
        >
          <img
            src="assets/images/Profile/edit.png"
            class="w-50 rounded-circle"
          />
        </span>
      </div>

      <div class="text-center">
        <p class="pt-3 text-md sora-bold">{{ fc["Name"].value }}</p>
        <p class="text-md sora-bold">{{ fc["Code"].value }}</p>
        <!-- <div class="status-container">
            <span class="status-text active"><span></span> Active</span>
        </div> -->

        <!-- <div class="status-container">
            <span class="status-point not-active">&#8226;</span>
            <span class="status-text not-active">Not Active</span>
        </div> -->

        <!-- <ngb-rating [(rate)]="ProfileData.rate " (hover)="hovered=$event" (leave)="hovered=0" max="5"
          readonly="readonly"></ngb-rating> -->
      </div>
    </div>
  </div>

  <div class="w-full sm:px-5 sm:w-1/1 md:w-1/2 xl:w-2/3 md:px-3 md:ps-12">
    <form
      class="mb-5 max-md:px-1 startPagePadding bg-white rounded-lg shadow dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        *ngIf="!ShowProfile"
        class="border-b-2 dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700 px-6 pt-2 mb-3"
      >
        <h2 class="text-2xl text-left sora-bold dark:text-white">
          {{ "AddParentStudent.header.Title" | translate }}
        </h2>
        <p class="text-sm/[10px] text-left">
          {{ "AddParentStudent.header.Enter-subtitle-here" | translate }}
        </p>
      </div>
      <div
        *ngIf="ShowProfile"
        class="border-b-2 dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700 px-6 pt-2 mb-3"
      >
        <h2 class="text-2xl text-left sora-bold dark:text-white">
          {{ "AddParentStudent.header.StudentProfile" | translate }}
        </h2>
        <p class="text-sm/[10px] text-left">
          {{ "AddParentStudent.header.Enter-subtitle-here" | translate }}
        </p>
      </div>

      <div class="grid gap-6 mb-6 lg:grid-cols-2 md:grid-cols-1">
        <div>
          <label
            for="first_name"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Student-Name" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            >
          </label>
          <input
            type="text"
            formControlName="Name"
            [tooltip]="
              fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
                ? ('StudentEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
                  'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
                }"
            id="first_name"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your name"
            required
          />
        </div>

        <div>
          <label
            for="schoolName"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.School-Name" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            type="text"
            id="schoolName"
            formControlName="SchoolName"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your Number"
            [tooltip]="
              fc['SchoolName'].invalid &&
              (fc['SchoolName'].dirty || fc['SchoolName'].touched)
                ? ('StudentEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['SchoolName'].invalid && (fc['SchoolName'].dirty || fc['SchoolName'].touched),
                  'class-2': fc['SchoolName'].valid && (fc['SchoolName'].dirty || fc['SchoolName'].touched),
                }"
          />
          <!-- <label for="success" class="block mb-2  text-green-700 dark:text-green-500">Your name</label>
            <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
            <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
        </div>
      </div>
      <div class="grid gap-6 mb-6 lg:grid-cols-2 md:grid-cols-1">
        <div>
          <label
            for="mobile"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Mobile" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            >
          </label>
          <input
            *ngIf="!StudentId"
            type="tel"
            id="mobile"
            maxlength="11"
            minlength="11"
            onlyNumbers
            formControlName="Mobile"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            [tooltip]="
              fc['Mobile'].invalid &&
              (fc['Mobile'].dirty || fc['Mobile'].touched)
                ? ('StudentEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
                'class-1': fc['Mobile'].invalid && (fc['Mobile'].dirty || fc['Mobile'].touched),
                'class-2': fc['Mobile'].valid && (fc['Mobile'].dirty || fc['Mobile'].touched),
              }"
          />

          <input
            *ngIf="StudentId"
            type="tel"
            id="phone"
            formControlName="MobileX"
            maxlength="11"
            minlength="11"
            class="bg-slate-300 rtl-input border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your Number"
            [tooltip]="
              fc['MobileX'].invalid &&
              (fc['MobileX'].dirty || fc['MobileX'].touched)
                ? ('TeacherEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
            'class-1': fc['MobileX'].invalid && (fc['MobileX'].dirty || fc['MobileX'].touched),
            'class-2': fc['MobileX'].valid && (fc['MobileX'].dirty || fc['MobileX'].touched),
          }"
          />
        </div>

        <div>
          <label
            for="email"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Email-address" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <input
            type="email"
            id="email"
            formControlName="Email"
            appDisableWritingArabic
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="student.doe@gmail.com"
            required
            [tooltip]="
              fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched)
                ? fc['Email'].hasError('email')
                  ? ('The Email is invalid' | translate)
                  : ('StudentEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
    'class-1': fc['Email'].invalid && (fc['Email'].dirty || fc['Email'].touched),
    'class-2': fc['Email'].valid && (fc['Email'].dirty || fc['Email'].touched),
  }"
          />
        </div>
      </div>
      <div class="grid gap-6 mb-6 lg:grid-cols-3 md:grid-cols-1">
        <div>
          <label
            for="educationtype"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Education-Type" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="Education_"
            #EducationType
            [selectOnTab]="true"
            formControlName="EducationTypeId"
            class="form-control p-0"
            (change)="ChangEducationType()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['EducationTypeId'].errors?.['required'] ?  ('this field is required' | translate) : null"
            [isDisabled]="
              !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['EducationTypeId'].valid &&
              (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
            "
            [class.is-invalid]="
              fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
            "
            [ngClass]="{
        'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
        'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
      }"
          >
            <ng-option value="">
              {{ "StudentEditProfile.form.Choose-Education-Type" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of EducationTypes" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="educationlevel"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Education-Level" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="EducationLevel_"
            #EducationType
            [selectOnTab]="true"
            formControlName="EducationLevelId"
            class="form-control p-0"
            (change)="ChangEducationLevel()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['EducationLevelId'].errors?.['required'] ?  ('this field is required' | translate) : null"
            [isDisabled]="
              !fc['EducationLevelId'].errors && fc['EducationLevelId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['EducationLevelId'].valid &&
              (fc['EducationLevelId'].touched || fc['EducationLevelId'].value)
            "
            [class.is-invalid]="
              fc['EducationLevelId'].invalid && fc['EducationLevelId'].touched
            "
            [ngClass]="{
        'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
        'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
      }"
          >
            <ng-option value="">
              {{ "Choose Education level" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of EducationLevel" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="academicyear"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Choose-Academic-Year" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="AcademicYear_"
            #EducationType
            [selectOnTab]="true"
            formControlName="AcademicYearEducationLevelId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['AcademicYearEducationLevelId'].errors?.['required']     ? ('StudentEditProfile.form.required' | translate)
            : null"
            [isDisabled]="
              !fc['AcademicYearEducationLevelId'].errors &&
              fc['AcademicYearEducationLevelId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['AcademicYearEducationLevelId'].valid &&
              (fc['AcademicYearEducationLevelId'].touched ||
                fc['AcademicYearEducationLevelId'].value)
            "
            [class.is-invalid]="
              fc['AcademicYearEducationLevelId'].invalid &&
              fc['AcademicYearEducationLevelId'].touched
            "
            [ngClass]="{
        'class-1': fc['AcademicYearEducationLevelId'].invalid && (fc['AcademicYearEducationLevelId'].dirty || fc['AcademicYearEducationLevelId'].touched),
        'class-2': fc['AcademicYearEducationLevelId'].valid && (fc['AcademicYearEducationLevelId'].dirty || fc['AcademicYearEducationLevelId'].touched),
      }"
          >
            <ng-option value="">
              {{ "StudentEditProfile.form.Choose-Academic-Year" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of AcademicYear" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label
            for="countries"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Country" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Country_"
            #Country
            [selectOnTab]="true"
            formControlName="CountryId"
            class="form-control p-0"
            (change)="ChangCountry()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CountryId'].errors?.['required']                 ? ('StudentEditProfile.form.required' | translate)
            : null"
            [isDisabled]="!fc['CountryId'].errors && fc['CountryId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CountryId'].valid &&
              (fc['CountryId'].touched || fc['CountryId'].value)
            "
            [class.is-invalid]="
              fc['CountryId'].invalid && fc['CountryId'].touched
            "
            [ngClass]="{
        'class-1': fc['CountryId'].invalid && (fc['CountryId'].dirty || fc['CountryId'].touched),
        'class-2': fc['CountryId'].valid && (fc['CountryId'].dirty || fc['CountryId'].touched),
      }"
          >
            <ng-option value="">
              {{ "StudentEditProfile.form.Choose-your-Country" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of countries" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="GovernorateId"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Governorate" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Governorate_"
            #Governorate
            [selectOnTab]="true"
            formControlName="GovernorateId"
            class="form-control p-0"
            (change)="ChangGovernorate()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GovernorateId'].errors?.['required']                 ? ('StudentEditProfile.form.required' | translate)
            : null"
            [isDisabled]="
              !fc['GovernorateId'].errors && fc['GovernorateId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GovernorateId'].valid &&
              (fc['GovernorateId'].touched || fc['GovernorateId'].value)
            "
            [class.is-invalid]="
              fc['GovernorateId'].invalid && fc['GovernorateId'].touched
            "
            [ngClass]="{
        'class-1': fc['GovernorateId'].invalid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
        'class-2': fc['GovernorateId'].valid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
      }"
          >
            <ng-option value="">
              {{
                "StudentEditProfile.form.Choose-your-Governorate" | translate
              }}
            </ng-option>
            <ng-option *ngFor="let item of Governorates" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="CityId"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.City" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="City"
            #EducationType
            [selectOnTab]="true"
            formControlName="CityId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CityId'].errors?.['required']                ? ('StudentEditProfile.form.required' | translate)
            : null"
            [isDisabled]="!fc['CityId'].errors && fc['CityId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CityId'].valid && (fc['CityId'].touched || fc['CityId'].value)
            "
            [class.is-invalid]="fc['CityId'].invalid && fc['CityId'].touched"
            [ngClass]="{
        'class-1': fc['CityId'].invalid && (fc['CityId'].dirty || fc['CityId'].touched),
        'class-2': fc['CityId'].valid && (fc['CityId'].dirty || fc['CityId'].touched),
      }"
          >
            <ng-option value="">
              {{ "StudentEditProfile.form.Choose-your-City" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of cityList" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label
            for="GenderID"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Gender" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >

          <ng-select
            id="Gender_"
            #Gender
            [selectOnTab]="true"
            formControlName="GenderId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GenderId'].errors?.['required']                 ? ('StudentEditProfile.form.required' | translate)
            : null"
            [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GenderId'].valid &&
              (fc['GenderId'].touched || fc['GenderId'].value)
            "
            [class.is-invalid]="
              fc['GenderId'].invalid && fc['GenderId'].touched
            "
            [ngClass]="{
        'class-1': fc['GenderId'].invalid && (fc['GenderId'].dirty || fc['GenderId'].touched),
        'class-2': fc['GenderId'].valid && (fc['GenderId'].dirty || fc['GenderId'].touched),
      }"
          >
            <ng-option value="">
              {{ "StudentEditProfile.form.Male/Female" | translate }}
            </ng-option>
            <ng-option *ngFor="let item of genders" [value]="item.id"
              >{{ item.title  | translate }}
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label
            for="Birthdate"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "StudentEditProfile.form.Birthdate" | translate }}</label
          >
          <input
            type="date"
            id="Birthdate"
            [max]="expirationDateNow"
            (keypress)="$event.preventDefault()"
            formControlName="Birthdate"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="yyyy-MM-dd"
            required
            [tooltip]="
              fc['Birthdate'].invalid &&
              (fc['Birthdate'].dirty || fc['Birthdate'].touched)
                ? ('StudentEditProfile.form.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['Birthdate'].invalid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
                  'class-2': fc['Birthdate'].valid && (fc['Birthdate'].dirty || fc['Birthdate'].touched),
                }"
          />
        </div>

        <div *ngIf="!ShowProfile">
          <label
            for="Pass"
            class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >
            {{ "AddParentStudent.form.Password" | translate }}</label
          >
          <input
            type="password"
            id="password"
            formControlName="passwordHash"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            [tooltip]="
                 fc['passwordHash'].errors?.['required']
            ? ('AddParentStudent.form.required' | translate)
            : fc['passwordHash'].errors?.['minlength']
              ? 'password should be more than 6 characters'
              : null"



            [ngClass]="{
                'class-1': fc['passwordHash'].invalid && (fc['passwordHash'].dirty || fc['passwordHash'].touched),
                'class-2': fc['passwordHash'].valid && (fc['passwordHash'].dirty || fc['passwordHash'].touched),
              }"
          />
        </div>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept="image/*"
          (change)="preview($event)"
          formControlName="Image"
        />
      </div>

      <!-- <button type="submit"  class="text-white bg-blue-700 mb-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{{'Save & Next'|translate}}</button> -->
      <div class="mt-3 flex justify-center">
        <button
          type="submit"
          [disabled]="!submit"
          [ngClass]="{
            'bg-[var(--theme-deafult-studentWithClick)]': insertForm.valid,

            'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid,
           }"
          class="text-[14px] sora-Regular w-75 ProfileBtn text-white bg-[#DDDFE5] hover:bg-[var(--theme-deafult-studentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-studentWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[var(--theme-deafult-studentWithClick)] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          {{ "StudentEditProfile.form.Submit" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div
  [dir]="'ltr'"
  class="flex _ flex-wrap justify-ce nter pt-5 py-14"
  *ngIf="ConnectParentStudentSteps.vefiryOtp && DataVefiryOtp"
>
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6">
    <!-- <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
      <input type="file" formControlName="TeacherImage" accept="image/jpeg,image/png,image/gif" />
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('required')">Please select an image</div>
      <div *ngIf="insertForm?.get('imaTeacherImagege')?.hasError('invalidFileType')">Invalid file type. Allowed types: JPEG, PNG, GIF</div>
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('invalidFileSize')">File size exceeds the maximum limit (5MB)</div>
      <button type="submit">Submit</button>
    </form> -->
    <img src="assets/images/MrS-Cool/ConnectStudent.png" class="w-full mt-5" />
  </div>

  <div
    class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5"
    *ngIf="ConnectParentStudentSteps.vefiryOtp && DataVefiryOtp"
  >
    <app-verify-otpstudent-connect
      [DataVefiryOtp]="DataVefiryOtp"
      [endPoint]="true"
      [phoneNumber]="fc['Mobile'].value"
      (StepVerifyOTP)="handleEvent($event)"
    ></app-verify-otpstudent-connect>
  </div>
  <!-- <div class="w-full sm:w-1/1 md:w-1/2 justify-center text-center xl:w-2/3 pt-5" *ngIf="ForgetPasswordSteps.ResetPassword">
    <app-reset-password [from]="insertForm.value"></app-reset-password>
  </div> -->
</div>
