import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from 'src/app/shared/Model/Generic/generic-response';

@Injectable()
export class ProfileViewService {

    constructor(private http: HttpClient) {}
GetProfileTeacher(TeacherId:any):Observable<any>{
    return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}Teacher/TeacherProfileView?TeacherProfileId=${TeacherId}`).pipe( map((res) => res.data ))
  }
}
