import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
import { IStudentChat } from '../../../Model/Student/student-chat';
import { IChat, IGetAllLessonChatByTeacher } from 'src/app/shared/Model/teacher/ichat';
@Injectable({
  providedIn: 'root'
})
export class TeacherLessonSessionCommentService {
  constructor(private http: HttpClient) {}
  GetAllStudentsChatWithTeacher():Observable<IChat[]> {
    return this.http.get<IGenericResponse<IChat>>(`${env.Server_URL}TeacherLessonSessionComment/GetAllStudentsChatWithTeacher`).pipe(
      map(response => response.data) // Assuming IGenericResponse contains a property called 'data' that holds the array of IChat
    );}
  GetAllLessonChatByTeacher(id:number):Observable<IGetAllLessonChatByTeacher> {
    return this.http.get<IGenericResponseObject<IGetAllLessonChatByTeacher>>(`${env.Server_URL}TeacherLessonSessionComment/GetAllLessonChatByTeacher?bookTeacherLessonSessionDetailId=${id}`)
    .pipe(
      map(response => response.data) // Assuming IGenericResponse contains a property called 'data' that holds the array of IChat
    );}
  SendMessage(data:any):Observable<IGenericResponseObject<any>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherLessonSessionComment/CreateComment`,data)
  }
}
