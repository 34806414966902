<div class="relative overflow-x-auto shadow-md sm:rounded-lg card border-0">
    <div
      class="card-header bg-transparent d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <div class="mr-2">
        <img src="assets/images/Icons/infomartial.svg" alt="" />
      </div>
      <div class="addressIconSpace">
        <h3  class="text-2xl text-left font-bold dark:text-white underTitle">

          {{ "StudentMatrial.header.Title1" | translate }}

        </h3>
        <p class="text-sm/[11px] text-left rtl:text-right">
          {{ "StudentMatrial.header.Subtitle" | translate }}
        </p>
      </div>
    </div>
    <div class="card-body">
        <div
        class=" border-b-2 rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
        >
     <div class="row">
        <div class="col-6">
            <h4>{{'StudentMatrial.body.Subject' |translate}}</h4>
            <h5>{{StudentLessonMaterials?.subjectName}}</h5>
        </div>
        <div class="col-6">
            <h4>{{'StudentMatrial.body.Lesson' |translate}}</h4>
            <h5>{{StudentLessonMaterials?.lessonName}}</h5>
        </div>
     </div>
     <div class="row mt-5">
          <div class="col-6 ">
            <h4>{{'StudentMatrial.body.Subject-Brief' |translate}}</h4>
            <p>{{StudentLessonMaterials?.subjectBrief}}</p>
        </div>
        <div class="col-6">
            <h4>{{'StudentMatrial.body.Lesson-Brief' |translate}}</h4>
            <p>{{StudentLessonMaterials?.lessonBrief}}</p>
        </div>
     </div>


      </div>

    </div>
    <div
      class="card-header bg-transparent d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <div class="mr-2">
        <img src="assets/images/Icons/infomartial.svg" alt="" />
      </div>
      <div class="addressIconSpace">
        <h3  class="text-2xl text-left font-bold dark:text-white underTitle">

          {{ "StudentMatrial.header.Title2" | translate }}

        </h3>
        <p class="text-sm/[11px] text-left rtl:text-right">
          {{ "StudentMatrial.header.Subtitle" | translate }}
        </p>
      </div>
    </div>
    <div class="card-body">
        <div
        class="  grid gap-6  lg:grid-cols-5 md:grid-cols-2   rounded-lg dark:border dark:bg-gray-800 dark:border-gray-700 px-6 pt-2 mb-3"
        >
     <div class="card d-flex align-items-center pt-3 downloadCard"   *ngFor="let item of StudentLessonMaterials.teacherLessonMaterialDtos; let i = index">
             <img src="assets/images/Icons/download.svg" alt="" />

        <p class="mb-4 mt-2">{{item.materialTypeName}}</p>
        <p>{{item.name}}</p>
        <button class="btn btnDownload" (click)="downloadFiles(item.materialUrl)" >{{'StudentMatrial.body.Download-Files'|translate}}</button>

     </div>
      </div>

    </div>
  </div>
