<!-- Faq Question section Start -->
<section class="faq-contain"   dir="ltr">
  <div class="container">
    <div class="row">
      <div class="col-12" style="direction: ltr">
        <h2 class="bold text-center">Privacy Policy</h2>

        <h2>Introduction</h2>
        <p>
          At The App and Platform "M-r-Scool”, we care about the privacy of our
          users and are committed to protecting the personal information you
          provide when using the application. This Privacy Policy explains how
          we collect, use, and disclose personal information. Please read this
          Privacy Policy carefully to understand our practices and the measures
          we take to protect your privacy.
        </p>

        <h2>1. Information We Collect:</h2>
        <p>
          When using the The App and Platform "M-r-Scool”, we may collect some
          personal information voluntarily provided by you. We may collect the
          following information: Registration Information: When registering in
          the application, we may ask you to provide personal information such
          as your name, postal address, phone number, and email address.
        </p>

        <h3>1.1. Usage Information :</h3>
        <ul>
          <li>
            We may collect information about your use of the application,
            including the pages you visit and the actions you take. This
            information is used to improve your experience, customize content,
            and analyze the performance of the application.
          </li>
        </ul>

        <h2>2. Use of Information:</h2>
        <p>
          We use the personal information we collect for the following purposes:
          Service Provision: We use the information for educational publishing
          services without discovering your identity and collect information
          anonymously.
        </p>

        <h3>2.1.  App Improvement :</h3>
        <ul>
       <li> We use the information to improve and develop the application and
        enhance the user experience.</li>
        </ul>

        <h3>2.2. Communication:</h3>
        <ul>
          <li>
            We may use the contact information you provided to communicate with you
            and provide information and updates related to the services.
          </li>
        </ul>

        <h3>2.3.  Research and Analysis:</h3>
        <ul>
          <li>
            We may analyze the information to understand user’s needs and
            improve the services we provide.
          </li>
        </ul>

        <h2>3.  Information Sharing:</h2>
        <p>
          We respect the privacy of your information and commit not to share it with third parties
          without your explicit consent.
        </p>

        <h3>3.1. Service Providers</h3>
        <ul>
          <li>

            We may share the information with qualified technical and educational
            providers who assist us in providing appropriate services to you.
                      </li>
        </ul>

        <h3>3.2. Legal Requirements:</h3>
        <ul>
          <li>
            We may disclose your personal information if required by laws, regulations,
            and applicable legal procedures.          </li>
        </ul>


        <h2>4. Security and Protection:        </h2>
        <p>
          We take appropriate security measures to protect your personal information from unauthorized
          access, unlawful use, alteration, or disclosure. However, no security system is absolute, and full
          security of information over the internet cannot be guaranteed.

        </p>

        <h2>5. Your Rights:        </h2>
        <p>
          You have certain rights regarding your personal information. You can access, update, or delete
the personal information we have about you in accordance with applicable laws and
regulations. You can also request modifications or deletion of your required information by
contacting us through the provided information below
        </p>

        <h2>6.  Changes to the Privacy Policy:        </h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page,
          and the indicated effective date will be updated accordingly.
        </p>

        <h2>7. Contact Us:        </h2>
        <p>

          If you have any questions or inquiries regarding the Privacy Policy or our practices concerning
          personal information, please contact us info        </p>

        <ul>
          <li>Email: {{'info@saudia.services'}}</li>
          <br />
          <li>Phone: 01023036779</li>

        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Faq Question section End -->
