import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
   FormBuilder,
   FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Clipboard } from '@angular/cdk/clipboard';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { IStatus } from 'src/app/shared/Model/Country/country';
import {
  IGetTeacherLessonForList,
  IMyLessonSchedualGroup,
  ISubjectGroup,
  ITeacherSubjectAcademicSemesterYearForList,
} from 'src/app/shared/Model/teacher/subject-group';
 import { GroupScheduleComponent } from '../../group-schedule/group-schedule.component';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { validateNotZero } from 'src/app/shared/validators/validate-not-zero';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-lesson-group',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    GroupScheduleComponent,
    NgSelectModule,MatExpansionModule
  ],

  templateUrl: './group-for-lesson.component.html',
  styleUrls: ['./group-for-lesson.component.scss'],
})
export class GroupForLessonComponent extends BaseComponent implements OnInit {
  protected dynamicForm!: FormGroup;
  protected modalRef?: BsModalRef;
  protected submit: boolean = true;
  protected SearchForm!: FormGroup;
  protected TableDeleteGroupID!: number;
  protected daysOfWeek!: IStatus[]; // IStatus ==id , name => dayes of week;
  protected MyLessonSchedualGroup!: IMyLessonSchedualGroup[];
  protected SubjectGroupsIsReview!: ISubjectGroup;
  protected SubjectAcademicSemesterYearForList!: ITeacherSubjectAcademicSemesterYearForList[];
  protected TeacherLessonForList!: IGetTeacherLessonForList[];
  protected TeacherLessonForListSearch!: IGetTeacherLessonForList[];
  protected TeacherLessonObject!: IGetTeacherLessonForList |null;
  protected IsReview: boolean = false;
  public panelOpenState  = false;

  constructor(
     private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    private _Lookup: LookupService,
    protected _GroupForLessonService: GroupForLessonService
  ) {
    super();
  }

  ngOnInit(): void {
    this.seo();

    this.initiate();
    this.initiateSearch();
    this.GetDays();
    this.GetMySubjectSchedualGroup({});
    this.GetTeacherSubjectAcademicSemesterYearForList();
  }

  private initiate(row?: any) {
    this.dynamicForm = this._fb.group({
      teacherSubjectAcademicSemesterYearId: [
        '',
        [Validators.required, validateNotZero],
      ],
      groupName: ['', Validators.required],
      startDate: ['', Validators.required],
      teacherLessonId: ['', Validators.required],
      timeFrom: ['', Validators.required],
    });
  }
  private initiateSearch(row?: any) {
    this.SearchForm = this._fb.group({
      teacherSubjectAcademicSemesterYearId: [
        '',
        [Validators.required, validateNotZero],
      ],
      groupName: [''],
      startDate: '',
      endDate: '',
      teacherLessonId: '',
    });
  }

  get fc() {
    return this.dynamicForm.controls;
  }
  get Search() {
    return this.SearchForm.controls;
  }

  protected onSubmit() {
    this.submit = false;
    if (this.dynamicForm.valid) {
      // Perform the submit logic here
      this.CreateLessonTeacherScheduleGroup();
    } else {
      this.dynamicForm.markAllAsTouched();
      this.submit = true;
    }
  }

  Clear() {
    this.dynamicForm.reset();
    this.TeacherLessonObject=null
    this.TeacherLessonForList = [];

  }

  ChangeSearchInp() {
    this.GetMySubjectSchedualGroup(this.SearchForm.getRawValue());
  }

  protected DeleteLessonTeacherScheduleGroup(id: number) {
    this.submit = false;

    this._GroupForLessonService.DeleteLessonTeacherScheduleGroup(id).subscribe(
      (res) => {
        if (res.success) {
          this.modalRef?.hide();
          this.SearchForm.reset()
          // Display a success message
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Deleted successfully',
          });
          this.GetMySubjectSchedualGroup({});
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  /**
   * Copies the provided text into the user's clipboard.
   *
   * @param text The string to copy.
   * @returns Whether the operation was successful.
   */
  copyText(item?: any) {
    const textToCopy = 'Hello, world!'; // Set the text you want to copy here
    this.clipboard.copy(textToCopy);
  }

  private GetDays() {
    this._Lookup.GetDays().subscribe((days) => {
      this.daysOfWeek = days;
    });
  }

  private GetMySubjectSchedualGroup(_filter :any) {
    const filter =_filter;
    // Remove properties with value null
    Object.keys(filter).forEach(
      (key) =>
        (filter[key] === null && delete filter[key]) ||
        (filter[key] === '' && delete filter[key])
    );
    this._GroupForLessonService
      .GetMyLessonSchedualGroup(filter)
      .subscribe((res) => {
        this.MyLessonSchedualGroup = res;
      });
  }

  /**
   *
   *@GetTeacherSubjectAcademicSemesterYearForList
   * @private
   * @memberof GroupForLessonComponent
   */

  private GetTeacherSubjectAcademicSemesterYearForList() {
    this._GroupForLessonService
      .GetTeacherSubjectAcademicSemesterYearForList()
      .subscribe((res) => {
        this.SubjectAcademicSemesterYearForList = res;
      });
  }

  protected ChangeTeacherLessonForList() {

    const foundObject = this.TeacherLessonForList.find(
      (item) => item.id == this.fc['teacherLessonId'].value
    );
    if (foundObject != null) {
      this.TeacherLessonObject = foundObject;
      // this.fc['teacherSubjectAcademicSemesterYearName'].setValue(
      //   foundObject
      // );
    }
    else
    this.TeacherLessonObject=null
  }

  protected ChangTeacherSubjectAcademicSemesterYearForList() {

    this.fc['teacherLessonId'].setValue('');
    if (
      this.fc['teacherSubjectAcademicSemesterYearId'].value != null &&
      this.fc['teacherSubjectAcademicSemesterYearId'].value !== ''
    ) {
      this.GetTeacherLessonForList();
    } else {
      this.TeacherLessonForList = [];
      this.TeacherLessonObject=null
    }
  }

  protected ChangTeacherSubjectAcademicSemesterYearForListSearchForm() {
    this.Search['teacherLessonId'].setValue('');
    if (
      this.Search['teacherSubjectAcademicSemesterYearId'].value != null &&
      this.Search['teacherSubjectAcademicSemesterYearId'].value !== ''
    )
{
  this.GetTeacherLessonForListSearchForm();

}    else this.TeacherLessonForListSearch = [];
  }

  /**
   *
   * @GetTeacherLessonForList
   * @private
   * @memberof GroupForLessonComponent
   */
  private GetTeacherLessonForList() {
    this._GroupForLessonService
      .GetTeacherLessonForList(
        this.fc['teacherSubjectAcademicSemesterYearId'].value
      )
      .subscribe((res) => {
        this.TeacherLessonForList = res;
      });
  }
  private GetTeacherLessonForListSearchForm() {
    this._GroupForLessonService
      .GetTeacherLessonForList(
        this.Search['teacherSubjectAcademicSemesterYearId'].value
      )
      .subscribe((res) => {
        this.TeacherLessonForListSearch = res;
      });
  }

  CreateLessonTeacherScheduleGroup() {
    this.submit = false;

    const form = {
      teacherLessonId: this.fc['teacherLessonId'].value,
      teacherSubjectAcademicSemesterYearId:
        this.fc['teacherSubjectAcademicSemesterYearId'].value,
      groupName: this.fc['groupName'].value,
      teacherLessonSessionScheduleSlotsDto: [
        {
          date: this.fc['startDate'].value,
          // endDate: this.fc['teacherLessonId'].value,
          timeFrom: this.fc['timeFrom'].value,
          timeTo: this.calculateEndTime(
            this.TeacherLessonObject !=null ? this.TeacherLessonObject.groupDuration :0,
            this.fc['timeFrom'].value
          ),
        },
      ],
    };
    this._GroupForLessonService
      .CreateLessonTeacherScheduleGroup(form)
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Add Succsessfully',
          });
          this.SearchForm.reset();
          this.dynamicForm.reset();
          this.GetMySubjectSchedualGroup({});
          this.TeacherLessonObject=null
       this.TeacherLessonForList = [];
          // Initializing TeacherLessonObject
          // this.TeacherLessonObject = {} as IGetTeacherLessonForList;

          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }

  calculateEndTime(durationInMinutes: number, startTime: string): string {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    let totalMinutes = startHour * 60 + startMinute + durationInMinutes;

    // Calculate the end hour and minute
    const endHour = Math.floor(totalMinutes / 60) % 24; // Take the remainder when dividing by 24
    const endMinute = totalMinutes % 60;

    // Format the end time as 'HH:mm'
    const endTime = `${endHour.toString().padStart(2, '0')}:${endMinute
      .toString()
      .padStart(2, '0')}`;

    return endTime;
  }
  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.GroupForLesson.Tabble.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
