import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StudentEditProfileService } from 'src/app/shared/Apis/Student/Profile/student-edit-profile.service';
import { Router } from '@angular/router';
 import { NgbRatingConfig, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { environment as env, environment } from 'src/environments/environment';
import { IGetStudentProfile } from 'src/app/shared/Model/Student/student-edit-profile';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-student-edit-profile',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgSelectModule,
    TranslateModule,
    NgbRatingModule,
    DisableWeritingArabicDirective,
    NgSelectModule,
  ],
  templateUrl: './student-edit-profile.component.html',
  styleUrls: ['./student-edit-profile.component.scss'],
})
export class StudentEditProfileComponent
  extends BaseComponent
  implements OnInit
{
  private logoForm!: FormData;
  protected selectedFile!: File;
  protected ProfileData!: IGetStudentProfile;
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected submit: boolean = true;
  protected subscription = new Subscription();
  imageSrc!: string;
  max = 5;
  rate = 3;
  AcademicYearEducationLevel: any;
  EducationTypes: any;
  EducationLevel: any;
  AcademicYear: any;
  EducationtypeId: any;
  constructor(
    private StudentEditProfileService: StudentEditProfileService,
    private _fb: FormBuilder,
    private _transition: TranslateService,
    private _Lookup: LookupService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    config: NgbRatingConfig,
    private _AuthService: AuthService
  ) {
    super();

    config.max = 5;
    config.readonly = true;
  }
  ngOnInit(): void {
    this.initiate();
    this.GetCountry();
    this.GetProfile();
    this.GetEducationtype();
    this.seo();
  }

  private initiate(Profile?: IGetStudentProfile) {
    this.insertForm = this._fb.group({
      Name: [Profile?.name || '', Validators.required],
      Birthdate: [Profile?.birthdate?.slice(0, 10) || '', Validators.required],
      Email: [Profile?.email || '', [Validators.required, Validators.email]],
      CityId: [Profile?.cityId || '', Validators.required],
      MobileX: [{ value: Profile?.mobile || '', disabled: true }],
      GenderId: [Profile?.genderId || '', Validators.required],
      SchoolName: [Profile?.schoolName || '', Validators.required],
      AcademicYearEducationLevelId: [
        Profile?.academicYearEducationLevelId || '',
        Validators.required,
      ],
      EducationLevelId: [Profile?.educationLevelId || '', Validators.required],
      EducationTypeId: [Profile?.educationTypeId || '', Validators.required],
      CountryId: [Profile?.countryId || '', Validators.required],
      GovernorateId: [Profile?.governorateId || '', Validators.required],
      Code: [Profile?.code || '', Validators.required],
      Image: [Profile?.image],

      // remmove
    });
    // If a row is provided, fetch additional select options
    if (Profile) this.getAllSelect();
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  GetAcademicYearEducationLevel() {
    this._Lookup
      .GetAcademicYearEducationLevel()
      .subscribe(
        (AcademicYearEducationLevel) =>
          (this.AcademicYearEducationLevel = AcademicYearEducationLevel)
      );
  }
  GetProfile() {
    this.StudentEditProfileService.GetProfileStudent().subscribe(
      (_profileData: any) => {
        this.ProfileData = _profileData;
        if (this.ProfileData.image) {
          this.imageSrc =
            environment.serverFirstHalfOfImageUrl +
            '/' +
            this.ProfileData.image;
        } else {
          this.imageSrc = 'assets/images/Logo/logo.svg';
        }
        this.initiate(_profileData);
        // this.GetEducationLevel(this.ProfileData.educationTypeId)
        this.GetAcademicYear(this.ProfileData.educationLevelId);
        this.GetGovernorate();
        this.SelectedGovernorate();
      }
    );
  }
  GetCountry() {
    this._Lookup
      .GetCountry()
      .subscribe((country) => (this.countries = country));
  }
  protected ChangCountry() {
    this.fc['GovernorateId'].setValue('');
    this.fc['CityId'].setValue('');
    this.GetGovernorate();
  }
  GetGovernorate() {
    if (this.fc['CountryId'].value != null && this.fc['CountryId'].value != '')
      this._Lookup
        .GetGovernorate(this.fc['CountryId'].value)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    else {
      this.Governorates = [];
      this.cityList = [];
    }
  }

  protected ChangGovernorate() {
    this.fc['CityId'].setValue('');
    this.SelectedGovernorate();
  }
  // get from city
  private SelectedGovernorate() {
    // this.fc.fromCityId.setValue(null)
    if (
      this.fc['GovernorateId'].value !== null &&
      this.fc['GovernorateId'].value !== '' &&
      this.fc['GovernorateId'].value > 0
    ) {
      this.subscription.add(
        this._Lookup
          .GetCity(this.fc['GovernorateId'].value)
          .subscribe((cities) => (this.cityList = cities))
      );
    } else this.cityList = [];
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      // Perform the submit logic here
      this.UpdateData();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private UpdateData() {
    this.loopform();
    this.submit = false;
    this.StudentEditProfileService.UpdateProfileStudent(
      this.logoForm
    ).subscribe(
      (res: any) => {
        if (res.success == true) {
          Swal.fire({
            icon: 'success',
            title: this._transition.instant('Profile updated successfully'),
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.setItem('customer_name', res.data['name']);
          localStorage.setItem('logo', res.data['image']);
          localStorage.setItem(
            'academicYearId',
            JSON.stringify(res.data['academicYearEducationLevelId'])
          );
        }
        this._AuthService.ReloadHeader(true);

        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        //this.insertForm.removeControl(key);
      } else {
        if (typeof this.insertForm.value[key] !== 'object' && key !== 'image')
          this.logoForm.append(key, this.insertForm.value[key]);
        else if (typeof this.insertForm.value[key] == 'object')
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });
    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
      this.logoForm.append('StudentImage', this.selectedFile);
  }

  preview(files: any) {
    const file = files.target.files[0];
    if (file.length === 0) return;
    var mimeType = file.type;
    const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
    // this.selectedFile = file;
    if (before_ == 'image') {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > 2) {
        // File size exceeds 5MB
        // this.FileSize=false
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
        });
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
        this.imageSrc =
          environment.serverFirstHalfOfImageUrl +
          this._AuthService.GetByName('logo');
      } else {
        this.selectedFile = file;
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    } else {
      this.imageSrc =
        environment.serverFirstHalfOfImageUrl +
        this._AuthService.GetByName('logo');
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

      Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });
      return;
    }
  }
  GetAcademicYear(id: any) {
    if (id !== 'null')
      this._Lookup
        .GetAcademicYearByeducationLevelId(id)
        .subscribe((AcademicYear) => (this.AcademicYear = AcademicYear));
    else this.AcademicYear = [];
  }

  // ***************************
  GetEducationtype() {
    this._Lookup
      .GetEducationType()
      .subscribe((EducationType) => (this.EducationTypes = EducationType));
  }
  protected ChangEducationType() {
    this.fc['EducationLevelId'].setValue('');
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.EducationLevel = [];
    this.AcademicYear = [];
    this.GetEducationLevel();
  }

  GetEducationLevel() {
    if (this.fc['EducationTypeId'].value)
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    else this.EducationLevel = [];
  }

  protected ChangEducationLevel() {
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.SelectedEducationLevel();
  }
  // get from AcademicYear
  private SelectedEducationLevel() {
    if (this.fc['EducationLevelId'].value) {
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
          .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
      );
    } else this.AcademicYear = [];
  }
  private getAllSelect(): void {
    // Fetch and populate education level options
    this.GetEducationLevel();

    // Set the selected education level based on certain criteria
    this.SelectedEducationLevel();

    // // Set the selected academic year based on certain criteria
    // this.SelectedAcademicYear();
  }
  private seo(){
    this.seoService.setMetaImage(this.imageSrc ? this.imageSrc : 'assets/images/Profile/Pic.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.StudentEditProfile.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
