import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { InstaSlider, LogoSlider } from '../../core/data/slider';
import { MostViewedLessonsService } from '../../Apis/most-viewed-lessons.service';
import { Router } from '@angular/router';
import { TruncatePipe } from '../../core/pipes/truncate/truncate.pipe';
@Component({
  selector: 'app-our-most-popular-lessons',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    TranslateModule,TruncatePipe
  ],
  templateUrl: './our-most-popular-lessons.component.html',
  styleUrls: ['./our-most-popular-lessons.component.scss'],
})
export class OurMostPopularLessonsComponent implements OnInit {
  public InstaSliderConfig: any = LogoSlider;
  data: any[]=[];
  @Input() type!: string;
  @Input() academicleveid?: number;
  @Output() outputDat: EventEmitter<any> = new EventEmitter<boolean>();
  constructor(
    private MostViewedLessonsService: MostViewedLessonsService,
    private router: Router
  ) {}
  /**
   * Function is called when component is initialized
   * @memberof OurMostPopularLessonsComponent
   */

  ngOnInit(): void {

    this.GetMostViewedLessonsOrMostBookedLessons(this.academicleveid);
  }
  // /**
  //  * Function is called when @Input properties change
  //  * @param {SimpleChanges} changes
  //  * @memberof OurMostPopularLessonsComponent
  //  */
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(this.academicleveid ,"changes")

  //   // this.GetMostViewedLessonsOrMostBookedLessons(this.academicleveid);
  // }

 /**
   * Function calls service to get either most viewed lessons or most booked lessons
   * @memberof OurMostPopularLessonsComponent
   */
  GetMostViewedLessonsOrMostBookedLessons(academicleveid?:number) {
    this.MostViewedLessonsService.GetMostViewedLessonsOrMostBookedLessons(
      this.type,
      academicleveid
    ).subscribe((res) => {
      this.data = res;
    });
  }

   /**
   * Function formats input string to capitalize each word
   * @param {string} inputString - The input string to be formatted
   * @returns {string} - The formatted string
   * @memberof OurMostPopularLessonsComponent
   */

 protected  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }

    // return inputString.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});

  }

  GoToSubjectOrLessonTeachers(id?: any) {
    // const type = this.type  =="MostBookedLessons"? "Subject"  :"Lesson"
    // const type = "Lesson"
    this.router.navigate([`/main/student/TeacherForSubjects/Lesson/${id}`]);
    // else
    // this.router.navigate([`/main/student/SubjectDetails/${type}/${id}`]);
  }
}
