

import { TranslateService } from '@ngx-translate/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import Swal from 'sweetalert2';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LayoutService } from '../layout.service/layout.service';
import { AuthService } from '../../Apis/AuthService/auth.service';
// import { AuthService } from '@core';
LayoutService
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private _LoginService: AuthService , private _transition: TranslateService,private _Layout:LayoutService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // check to see if there's internet
    // const currentUser = this._LoginService.currentUserValue;
     
const lang :string = this._Layout.config.langu != null ? this._Layout.config.langu:"en";
if (this._LoginService.getToken() == null) {
  // "You must provide a username and password" ;

  return next.handle(request);
}
else {
  // "You have already logged in to this website and have authorized ";
  const tokenizedReq = request.clone({
    setHeaders: {
      Authorization: `Bearer ${this._LoginService.getToken()}`,

    }
  });
  return next.handle(tokenizedReq);

}
    // if ( isPlatformBrowser(this.platformId) &&typeof window !== 'undefined' && !window.navigator.onLine) {
    //   // if there is no internet, throw a HttpErrorResponse error
    //   // since an error is thrown, the function will terminate here
    //   // return Observable.throw(new HttpErrorResponse({ error: 'Internet is required.' }));
    //     // Swal.fire({
    //     //   icon: "warning",
    //     //   title: `! Network `,
    //     //   text: "You are not connected to the network",
    //     // });
    //   return throwError(() => new Error("Internet is required"));
    // } else {
    //     // else return the normal request
    //     if (this._LoginService.getToken() == null) {
    //       // "You must provide a username and password" ;

    //       return next.handle(request);
    //     }
    //     else {
    //       // "You have already logged in to this website and have authorized ";
    //       const tokenizedReq = request.clone({
    //         setHeaders: {
    //           Authorization: `Bearer ${this._LoginService.getToken()}`,

    //         }
    //       });
    //       return next.handle(tokenizedReq);

    //     }
    //   }
    // }
    }
  }
