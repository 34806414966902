<div class="ps-20 mb-5 max-md:px-1">
  <div
    class="m-auto pb-3 ProfilePadding px-20 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
    *ngIf="DoneVefiryOtp"
  >
<div>
  <img
  src="assets/images/MrS-Cool/VefiryUser.png"
  class="w-1/6 m-auto py-3"
/>
<h3 class="text-3xl Lama-bold dark:text-white">
  {{ "VerifyOtp.header.Text" | translate }}
</h3>
<p class="text-[var(--theme-deafult-TeacherWithClick)]">
  {{ "VerifyOtp.header.Subtitle" | translate }} <br />{{
    "VerifyOtp.header.for-verification" | translate
  }}
</p>
<ng-otp-input
dir="ltr"
class="text-[var(--theme-deafult-TeacherWithClick)]"
  #ngOtpInputRef
  (onInputChange)="onOtpChange($event)"
  (keyup.enter)="SendVerify()"
  [class.invalid-border]="isInvalid"
  [config]="config"
></ng-otp-input>
<div class="my-3">
  <button
    type="submit"
    (click)="SendVerify()"
    (keyup.enter)="SendVerify()"
    [ngClass]="{

    'bg-[var(--theme-deafult-TeacherWithClick)] text-white':ngOtpInputRef.otpForm.status== 'VALID' && ngOtpInputRef.currentVal !=null&& Controller=='teacher',
    'bg-[var(--theme-deafult-ParentWithClick)] text-white':ngOtpInputRef.otpForm.status== 'VALID' && ngOtpInputRef.currentVal !=null&& Controller=='parent',
    'bg-[var(--theme-deafult-studentWithClick)] text-white': ngOtpInputRef.otpForm.status== 'VALID' && ngOtpInputRef.currentVal !=null&&Controller=='student',

    'hover:bg-[var(--theme-deafult-TeacherWithClick)]':ngOtpInputRef.otpForm.status!= 'VALID' && ngOtpInputRef.currentVal ==null&& Controller=='teacher',
    'hover:bg-[var(--theme-deafult-ParentWithClick)]':ngOtpInputRef.otpForm.status!= 'VALID' && ngOtpInputRef.currentVal ==null&& Controller=='parent',
    'hover:bg-[var(--theme-deafult-studentWithClick)]': ngOtpInputRef.otpForm.status!= 'VALID' && ngOtpInputRef.currentVal ==null&&Controller=='student',
   }"
    class="w-full disBtnTextColor hover:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[#DDDFE5] bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black"
  >
    {{ "VerifyOtp.form.Verify" | translate }}
  </button>
</div>
</div>
    <div class="text-center mt-4 mb-4">
      <p
        class="text-sm font-light text-gray-500 dark:text-gray-950 "
        *ngIf="!showSendOtp"
      >
      <span class="text-[var(--theme-deafult-TeacherWithClick)]">
        {{ "VerifyOtp.form.OTP-Not-Received" | translate }}
      </span>
        <a class="font-medium text-red-600 hover:underline dark:text-red-500">
         <span class="text-red-600 dark:text-red-500 "> {{ DataVefiryOtp.secondsCount + " " }}{{ " " }}
          {{ "VerifyOtp.form.secondly" | translate }}</span></a
        >
      </p>
      <p
        class="text-sm font-light text-gray-500 dark:text-gray-950 text-[var(--theme-deafult-TeacherWithClick)]"
        *ngIf="showSendOtp"
      >
      <span class="text-[var(--theme-deafult-TeacherWithClick)]">
        {{ "VerifyOtp.form.OTP-Not-Received" | translate
        }}
        </span><a
          (click)="SendOTP()"
          class="font-medium text-red-600 hover:underline dark:text-red-600"
        >
        <span class="dark:text-red-600 text-red-600">
          {{ "VerifyOtp.form.Resend-Code" | translate }}
        </span></a
        >
      </p>
      <p
        class="text-sm font-light text-gray-500 dark:text-gray-950 text-[var(--theme-deafult-TeacherWithClick)]"
        (click)="navigate(false)"
      >
      <span class="text-[var(--theme-deafult-TeacherWithClick)]">
        {{ "VerifyOtp.form.Mobile-number-not-correct" | translate }}
        </span>
        <a
          (click)="navigate(false)"
          class="font-medium text-red-600 hover:underline dark:text-red-600"
          >
          <span class="dark:text-red-600 text-red-600">{{ "VerifyOtp.form.Modify-Now" | translate }}</span>
        </a>
      </p>
    </div>
  </div>

  <!-- <div class="m-auto pb-3   bg-white rounded-lg shadow dark:border  dark:bg-[#1a202c] dark:border-gray-700 " *ngIf="!DoneVefiryOtp">
    <p class="diagonal-fractions ... pt-2 text-right text-3xl   Lama-bold">
      2/4
    </p>
    <img src="assets/images/MrS-Cool/VefiryUserSucc.png"  class="w-1/6 m-auto py-3">
    <h3 class="text-3xl Lama-bold dark:text-white">{{"Verify Your Phone Number"}}</h3>

    <div class=" my-3 mx-5">
      <button type="submit" class="w-full   text-white bg-gray-200 focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
      (click)="navigate(true)"
      (keyup.enter)="navigate(true)">
        {{ "Next" | translate }}
      </button>
    </div>

  </div> -->
</div>
