import { Component, Inject, PLATFORM_ID, OnInit, Output, EventEmitter } from '@angular/core';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import {
  changeEnv,
  environment as env,
  environment,
} from 'src/environments/environment';
import { changeEnvProd } from 'src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  darkMode = false;
  show = false;
  // @Output() showFiller_: EventEmitter<boolean> = new EventEmitter<boolean>();
reload!:string

  protected languages = [
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'Arabic',
      code: 'ar',
    },
  ];

  protected languageNames: string = 'Arabic';
  protected Controller!: string;
  protected customerName!: string;
  protected imagePath!: string;
  public layoutType!: string; // default
  public elem: any;
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public layout: LayoutService,
    private _SSRService: SSRService,
    public _AuthService: AuthService,
    private _router: Router
  ) {
    // this.navigate(true)
    super();
    const language = localStorage.getItem('language');
    const darkModeStorage = localStorage.getItem('darkMode');
    if (darkModeStorage == 'true') {
      this.toggleDarkMode();
    } else {
      this.darkMode = true;
      this.toggleDarkMode();
    }
    if (language) {
      if (language == 'en') {
        this.layoutType = 'ltr';
        this.customizeLayoutType('ltr');

        this.languageNames = 'English';
      } else {
        this.layoutType = 'rtl';
        this.customizeLayoutType('rtl');

        this.languageNames = 'Arabic';
      }
    } else {
      this.layoutType = 'rtl';
      // this.layoutType = 'ltr';
      this.customizeLayoutType('ar');

    }
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    if (this.darkMode) {
      this.document.body.classList.add('dark');
    } else {
      this.document.body.classList.remove('dark');
    }
    localStorage.setItem('darkMode', String(this.darkMode));
  }

  ngOnInit(): void {
    // this.title = this._SSRService.getPathDecodeURIComponent();
    // if (this._SSRService.getPathDecodeURIComponent() != null) {
    //   this.GetCategoryLogo(this._SSRService.getPathDecodeURIComponent());
    // }
    this.elem = document.documentElement;
    this.init()
    this.checkRoleName();
  }
  toggleFullScreen() {
    this.layout.fullScreen = !this.layout.fullScreen;
    if (this.layout.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  private init() {
    if (typeof localStorage !== 'undefined') {
      const language = localStorage.getItem('language');
      const token = localStorage.getItem('customerAuthorization');
      //  token != null ? (this.isLogin = true) : (this.isLogin = false);
      language != null
        ? this.changeLanguage(language)
        : this.changeLanguage('ar');
    }
  }
  private checkRoleName() {
    if (
      this._AuthService.getToken() != null &&
      this._AuthService.getRoleName() != null
    ) {
      this.Controller = this._AuthService.getRoleName().toLowerCase();

      this.Controller =
        this.Controller == 'children' ? 'parent' : this.Controller;
      this._AuthService.header.subscribe((res) => {
        if (res) {
          // this.customerName = this._AuthService.getCustomerName().toLowerCase();
          // this.imagePath =
          //   environment.serverFirstHalfOfImageUrl +
          //   this._AuthService.GetByName('logo');
          this._AuthService.ReloadHeader(false);
        }
      });
    }
  }
  changeLanguage(code: string, reload?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
      if (typeof localStorage !== 'undefined') {
        const language = localStorage.getItem('language');
        if (language != code) {
          this.translate.use(code);
          localStorage.setItem('language', code);

          this.layout.config.langu = code;
          changeEnv(code);
          changeEnvProd(code);
          if (code == 'ar') {
            this.customizeLayoutType('rtl', reload);
            this.languageNames = 'English';
          } else {
            this.customizeLayoutType('ltr', reload);
            this.languageNames = 'Arabic';
          }
        } else {
          if (code == 'ar') {
            const logoSidebar = document.getElementById('logo-sidebar');
            if (logoSidebar) {
              logoSidebar?.classList.remove('-translate-x-full');
              logoSidebar?.classList.add('translate-x-full');
              logoSidebar?.classList.remove('left-0');
              logoSidebar.style.setProperty('left', 'unset', 'important');

            }
          } else {
            const logoSidebar = document.getElementById('logo-sidebar');
            if (logoSidebar) {
              logoSidebar?.classList.add('-translate-x-full');
              logoSidebar?.classList.remove('translate-x-full');
              // logoSidebar?.classList.add('left-0');
            }
          }
          console.log(code, '==', language);
          this.layout.config.langu = code;
          changeEnv(code);
          changeEnvProd(code);
        }
      }
    }
  }
  // Layout Type
  private customizeLayoutType(val: string, reload?: boolean) {
    this.layout.config.settings.layout_type = val;
    if (typeof document !== 'undefined')
      if (val == 'rtl') {
        document.getElementsByTagName('html')[0].setAttribute('dir', val);
        document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');

        if (typeof document.body.classList !== 'undefined') {
          // access the classList property
          document.body.classList.remove('ltr');
          document.body.classList.add('rtl');
          const logoSidebar = document.getElementById('logo-sidebar');
          if (logoSidebar) {
            logoSidebar?.classList.remove('-translate-x-full');
            logoSidebar?.classList.add('translate-x-full');
            logoSidebar?.classList.remove('left-0');
            logoSidebar.style.setProperty('left', 'unset', 'important');
          }
        }
        reload == true ? this.layout.reloadPage() : null;
      } else {
        document.getElementsByTagName('html')[0].removeAttribute('dir');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
        if (typeof document.body.classList !== 'undefined') {
          // access the classList property
          document.body.classList.remove('rtl');
          document.body.classList.add('ltr');
          const logoSidebar = document.getElementById('logo-sidebar');
          if (logoSidebar) {
            logoSidebar?.classList.add('-translate-x-full');
            logoSidebar?.classList.remove('translate-x-full');
            // logoSidebar?.classList.add('left-0');
          }
        }
        reload == true ? this.layout.reloadPage() : null;
      }
  }
  logOut(): void {
    const hoursPlusUtc = localStorage.getItem('hoursPlusUtc');

      // مسح جميع العناصر من localStorage
      localStorage.clear();

      // إعادة تعيين قيمة hoursPlusUtc
      if (hoursPlusUtc) {
        localStorage.setItem('hoursPlusUtc', hoursPlusUtc);
      }
    if (this.darkMode) {
      this.document.body.classList.remove('dark');
    }
    localStorage.setItem('role', 'Anonymous');
    this._router.navigate([`/authentication/${this.Controller}/login`]);
    this._AuthService.ReloadSidebar(true);
  }



  navigate(isInvalid: boolean) {
    this.show=!isInvalid
    // this.showFiller_.emit(this.show);console.log(this.showFiller_)
  }


  }


