<div class="  mt-10 py-3  md:pr-12 ">
  <div class="  shadow rounded-lg bg-white">
    <div class="mt-4 pt-5 px-4 ">
      <h2 class="Lama-SemiBold text-[30px] px-2">
        <span class=" Lama-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-TeacherWithClick)]"> {{"Showing.results"  |translate}}  </span>
        <span  class=" ml-2Lama-SemiBold  text-[18px] md:text-[30px] text-[var(--theme-deafult-ParentWithClick)]" *ngIf="academicLevelName">
          {{academicLevelName }}
          <!-- {{ formatString(AllSubjects[0].name)[1] }}
          {{ formatString(AllSubjects[0].name)[2] }} -->
      </span>
      </h2>

      <hr>
    </div>

    <div class="m-auto w-11/12 px-2 pb-2" >
      <div class="grid gap-0 mb-2 lg:grid-cols-3 md:grid-cols-2 mt-2" *ngIf="AllSubjects && AllSubjects.length >0" >
        <div
          class="logo-block rounded-lg ... flex flex-col justify-center py-9  px-10  bg-[#F1F3F6] hover:bg[var(--theme-deafult-ParentWithClick)] m-3"
          [routerLink]="['/main/student/SubjectDetails/' + item.id]"
          routerLinkActive="router-link-active"
          *ngFor="let item of AllSubjects"
        >
        <div
        class=" ... p-2"
      >
        <img
          [src]="item.image | customPipeForImages"
          alt="Boolk loessons"
          (error)="handleImageError(item)"
          class="m-auto w-full book-lesson"
        />

        <!-- <hr /> -->

        <div class="grid gap-2 contentCard md:grid-cols-2 mt-2">
          <div class="col-span-2 p-2">
            <p class="text-[12px] md:text-[16px] Lama-bold">
              {{ formatString(item.name)[0] }}
            </p>
            <p class="text-[12px] Lama-bold">
              {{ item.academicSemesterName }}
            </p>
          </div>
          <hr class="col-span-2" style="margin: 0;" />
          <div
            class="col-span-1 NumColor text-[10px] Lama-Regular flex justify-content-end items-center"
          >
            <img
              src="assets/images/OurMostPopularLessons/person_.svg"
              alt="Min Price"
              class="w-9 person inline-block mr-2"
            />
            <span class="inline-block">
              <span class="Lama-bold text-[12px]">
                {{ item.availableTeacherCount }}
              </span>
              {{"Showing.Available-Teachers"  |translate}}     </span
            >
            </div>
          <div
            class="col-span-1 NumColor text-[10px] Lama-Regular flex justify-content-end items-center"
          >
            <img
              src="assets/images/OurMostPopularLessons/document.svg"
              alt="Max Price"
              class="w-6 person_black inline-block mr-2"
            />
            <span class="inline-block">
              <span class="Lama-bold text-[12px]">
                {{ item.lessonsCount }} </span
              >    {{"Showing.lessons"  |translate}} </span
            >
          </div>
        </div>
      </div>
        </div>

      </div>
      <div class="grid gap-0 mb-2 md:grid-cols-1 mt-2"  *ngIf="!AllSubjects ">
        <div class="text-center"  >
          <img
            src="assets/images/OurMostPopularLessons/empty/TopViewedSubjects.svg"
            alt="calendar"
            class="m-auto"
          />
          <p class="text-[#868B9F] mt-3">
            {{"Showing.NoAvailable"  |translate}}    <br />       {{"Showing.SubjectsYet"  |translate}}

            <span class="vector-bg"
              >

            </span>
          </p>
        </div>
        </div>


    </div>
  </div>
</div>
<app-our-most-popular-subjects
[academicleveid]="academicleveid"
  [type]="'TopBookedSubjects'"
  [showTeacherCount]="false"
></app-our-most-popular-subjects>
<br />

<app-most-booked-teachers
  [academicleveid]="academicleveid"
  [type]="'GetTeachersMostBooked'"
></app-most-booked-teachers>
<br />



<!-- <app-our-most-popular-lessons
  [type]="'MostBookedLessons'"
  [academicleveid]="academicleveid"
></app-our-most-popular-lessons>
<br /> -->

<!-- <div class="row py-3">
  <img
    class="mb-4 ImgCover"
    src="../../../../../assets/images/Anonymous/app.jpg"
  />
</div> -->

<!-- <app-most-booked-teachers
[academicleveid]="academicleveid"
  [type]="'MostViewedTeacher'"
></app-most-booked-teachers>

<br /> -->

<!-- <app-our-most-popular-lessons
  [type]="'MostViewedLessons'"
  [academicleveid]="academicleveid"
></app-our-most-popular-lessons> -->

<!-- <app-our-most-popular-subjects
  [type]="'TopViewedSubjects'"
  [academicleveid]="academicleveid"
></app-our-most-popular-subjects>

<br />
<div class="row py-3">
  <img
    class="mb-4 ImgCover"
    src="../../../../../assets/images/Anonymous/app2.svg"
  />
</div>

 -->
