import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from 'src/app/shared/Model/Generic/generic-response';
import { IGetProfile } from 'src/app/shared/Model/teacher/edit-profile';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  constructor(private http: HttpClient) {}
  UpdateProfileTeacher(data:FormData):Observable<IGenericResponseObject<IGetProfile>>{
    return this.http.post<IGenericResponseObject<IGetProfile>>(`${env.Server_URL}Teacher/UpdateProfile`,data)
  }
  GetProfileTeacher():Observable<IGetProfile>{
    return this.http.get<IGenericResponseObject<IGetProfile>>(`${env.Server_URL}Teacher/Profile`).pipe( map((res) => res.data ))
  }
}
