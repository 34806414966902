
  <app-page-loader></app-page-loader>
  <public-api-loader></public-api-loader>
  <router-outlet
  class="overflow-hidden h-full dark:bg-[#1a202c]"></router-outlet>
<footer>

  <!-- <app-footer-tow></app-footer-tow> -->
  <bottom-navigation class="d-block d-xl-none"></bottom-navigation>
</footer>

