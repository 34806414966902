import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'bottom-navigation',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent {
  protected Controller!:string
constructor( @Inject(PLATFORM_ID) private platformId: Object){
  this.Controller =  this.getRoleName().toLowerCase();

  this.Controller =
    this.Controller == 'children' ? 'parent' : this.Controller;
}
  startTrip(): void { }
  explore(): void { }

  public getRoleName(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const roleName = localStorage.getItem('role');
      return roleName ??"Anonymous";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
}
