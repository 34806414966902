import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import { ISettings, PostLogin, ResponseLogin } from '../../Model/Auth/login';
import { environment } from 'src/environments/environment';
import { SidebarComponent } from 'src/app/layout/sidebar/sidebar.component';
import { isPlatformBrowser } from '@angular/common';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public sidebar = new BehaviorSubject<any>(null);
  public header = new BehaviorSubject<any>(true);
  // ChildrenId$: Observable<any> = this.sidebar.asObservable().pipe(
  //   distinctUntilChanged()
  // );

  constructor(private _http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ReloadSidebar(isRleliad:boolean)
  {
//  this.sidebar.next(null)
 this.sidebar.next(isRleliad)
  }

  ReloadHeader(isRleliad:boolean)
  {
   this.header.next(null)
  this.header.next(isRleliad)
  }
  // setting
  GetSetting():Observable<ISettings>{
    return this._http.get<IGenericResponseObject<ISettings>>(`${environment.Server_URL}Settings/GetSetting`).pipe( map((res) => res.data ))}


   _Login(controller:string ,form: PostLogin): Observable<ResponseLogin> {
    return this._http
      .post<ResponseLogin>(`${environment.Server_URL}${controller}/Login`, form)
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }

  public getToken(): string | null {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('customerAuthorization');
      return token;
    } else {
      return null; // Return null if localStorage is not defined
    }
  }
  public getProfileStatusId(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const profileStatusId = localStorage.getItem('profileStatusId');
      return profileStatusId ??"";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
  public getRoleName(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const roleName = localStorage.getItem('role');
      return roleName ??"Anonymous";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
  public getRoleId(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const roleName = localStorage.getItem('roleId');
      return roleName ??"";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
  public getCustomerName(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const customerName = localStorage.getItem('customer_name');
      return customerName ??"";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
  public GetByName(name:string): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const returned = localStorage.getItem(name);
      return returned ??"";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }
  public checkAddchildren(): boolean {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      let isAddchildren = localStorage.getItem('addchildren');

      if (isAddchildren === null) {
        // If 'addchildren' is not present in localStorage, set it to 'true'
        localStorage.setItem('addchildren', 'true');
        return true;
      } else {
        // If 'addchildren' is present, parse its value as a boolean
        return JSON.parse(isAddchildren);
      }
    } else {
      // Return true if localStorage is not defined
      return true;
    }
  }

  public  getCurrentChildrenId(): string  {
    if (typeof localStorage !== 'undefined'&& isPlatformBrowser(this.platformId)) {
      const roleName = localStorage.getItem('childrenId');
      return roleName ??"";
    } else {
      return ""; // Return null if localStorage is not defined
    }
  }


  }
