import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
   FormBuilder,
   FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';

import {
  IGetTeacherLessonForList,
  IMyLessonSchedualGroup,
  ITeacherSubjectAcademicSemesterYearForList,
} from 'src/app/shared/Model/teacher/subject-group';

import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { validateNotZero } from 'src/app/shared/validators/validate-not-zero';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-completedlessons',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
    NgxPaginationModule,
  ],
  templateUrl: './completedlessons.component.html',
  styleUrls: ['./completedlessons.component.scss'],
})
export class CompletedlessonsComponent extends BaseComponent implements OnInit {
  protected MyLessonSchedualGroup!: IMyLessonSchedualGroup[];
  protected SearchForm!: FormGroup;
  protected SubjectAcademicSemesterYearForList!: ITeacherSubjectAcademicSemesterYearForList[];
  protected TeacherLessonForList!: IGetTeacherLessonForList[];
  protected TeacherLessonObject!: IGetTeacherLessonForList;
  page!: number;

  constructor(
    private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,
    protected _GroupForLessonService: GroupForLessonService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    // if (typeof this._activatedRoute.snapshot.params['id'] !== 'undefined') {
    //   this.teacherLessonId = this._activatedRoute.snapshot.params['id'];
    //   this.GetMyLessonMaterials({ teacherLessonId: this.teacherLessonId });
    // }
    this.seo();

    this.initiateSearch();
    this.GetMyCompletedLesson();
    this.GetTeacherSubjectAcademicSemesterYearForList();
  }

  private initiateSearch(row?: any): void {
    this.SearchForm = this._fb.group({
      teacherSubjectId: ['', [Validators.required, validateNotZero]],
      groupName: [null],

      lessonDate: null,
      teacherLessonId: '',
    });
  }

  get fc() {
    return this.SearchForm.controls;
  }


  protected GetMyCompletedLesson() {
    this.removeNullfromPager(this.pager);
    this._GroupForLessonService
      .GetMyCompletedLesson(this.pager)
      .subscribe((res) => {
        this.MyLessonSchedualGroup = res.data.items;
        this.totalRecords = res.data.totalCount;
      });
  }

  removeNullfromPager(pager: any): void {
    for (const prop in pager) {
      if (
        (pager.hasOwnProperty(prop) && pager[prop] === null) ||
        pager[prop] == ''
      ) {
        delete pager[prop];
      }
    }
  }

  protected ChangeTeacherLessonForList() {
    const foundObject = this.TeacherLessonForList.find(
      (item) => item.id == this.fc['teacherLessonId'].value
    );
    if (foundObject != null) {
      this.TeacherLessonObject = foundObject;
      // this.fc['teacherSubjectAcademicSemesterYearName'].setValue(
      //   foundObject
      // );
    }
  }

  /**
   *
   *@GetTeacherSubjectAcademicSemesterYearForList
   * @private
   * @memberof CompletedlessonsComponent
   */

  private GetTeacherSubjectAcademicSemesterYearForList() {
    this._GroupForLessonService
      .GetTeacherSubjectAcademicSemesterYearForList()
      .subscribe((res) => {
        this.SubjectAcademicSemesterYearForList = res;
      });
  }

  protected ChangTeacherSubjectAcademicSemesterYearForList() {
    this.fc['teacherLessonId'].setValue(null);
    this.TeacherLessonForList=[];
    if (
      this.fc['teacherSubjectId'].value != '' &&
      this.fc['teacherSubjectId'].value != null
    )
      this.GetAllTeacherLessonForList();
  }

  /**
   *
   * @GetAllTeacherLessonForList
   * @private
   * @memberof GroupForLessonComponent
   */
  private GetAllTeacherLessonForList() {
    this._GroupForLessonService
      .GetAllTeacherLessonForList(this.fc['teacherSubjectId'].value)
      .subscribe((res) => {
        this.TeacherLessonForList = res;
      });
  }

  navigate(id: number) {
    this.router.navigate([`/main/CompletedLessonDetail/${id}`]);
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetMyCompletedLesson();
    this.pageNumber;
  }
  SearchFormPag() {
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.pager = { ...this.pager, ...  this.SearchForm.getRawValue() };

    this.GetMyCompletedLesson();
    this.pageNumber;
  }

  //  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.StudentCompletedLesson.Tabble.header.Title);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}
}
