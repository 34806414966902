import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
   FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  IBank,
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { EditProfileService } from 'src/app/shared/Apis/Teacher/Profile/edit-profile.service';
import { Router } from '@angular/router';
import { IGetProfile } from 'src/app/shared/Model/teacher/edit-profile';
import { NgbRatingConfig, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { environment as env, environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgSelectModule,
    TranslateModule,
    NgbRatingModule,
    DisableWeritingArabicDirective,
  ],
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent extends BaseComponent implements OnInit {
  private logoForm!: FormData;
  protected selectedFile!: File;
  protected ProfileData!: IGetProfile;
  protected countries!: ICountry[];
  protected Banks!: IBank[];
  protected Governorates!: IGovernorate[];
  
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected submit: boolean = true;
  protected subscription = new Subscription();
  imageSrc!: string;
  max = 5;
  rate = 3;

  constructor(
    private _EditProfile: EditProfileService,
    private _fb: FormBuilder,
    private _transition: TranslateService,
    private _Lookup: LookupService,
    private _router: Router,
    private _AuthService: AuthService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    config: NgbRatingConfig
  ) {
    super();

    config.max = 5;
    config.readonly = true;
  }
  ngOnInit(): void {
    this.initiate();
    this.GetCountry();
    this.GetBank();
    this.GetProfile();
    this.seo();

  }

  private initiate(Profile?: IGetProfile) {
    this.insertForm = this._fb.group({
      Name: [Profile?.name || '', Validators.required],
      Birthdate: [Profile?.birthdate?.slice(0, 10) || '', Validators.required],
      Email: [Profile?.email || '', [Validators.required, Validators.email]],
      CityId: [Profile?.cityId || '', Validators.required],
      GenderId: [Profile?.genderId || '', Validators.required],
      IsTeacher: [Profile?.isTeacher || Validators.required],
      MobileX: [{ value: Profile?.mobile || '', disabled: true }],
      Image: [Profile?.image],
      Code: [Profile?.code || ''],
      TeacherBIO: [Profile?.teacherBio || ''],
      // remmove
      GovernorateId: [Profile?.governorateId || '', Validators.required],
      CountryId: [Profile?.countryId || '', Validators.required],
      BankId: [Profile?.bankId || ''],
      Iban: [Profile?.iban || ''],

    });
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  GetProfile() {
    this._EditProfile.GetProfileTeacher().subscribe((_profileData) => {
      this.ProfileData = _profileData;
      this.imageSrc = env.serverFirstHalfOfImageUrl + _profileData.image;
      this.initiate(_profileData);
      if (_profileData) this.fc['IsTeacher'].setValue(_profileData?.isTeacher);
      this.getAllSelect();
    });
  }
  GetCountry() {
    this._Lookup
      .GetCountry()
      .subscribe((country) => (this.countries = country));
  }
  GetBank() {
    this._Lookup
      .GetBank()
      .subscribe((bank) => (this.Banks = bank));
  }
  protected ChangCountry() {
    this.fc['GovernorateId'].setValue(null);
    this.GetGovernorate();
  }
  GetGovernorate() {
    if (this.fc['CountryId'].value !== null)
      this._Lookup
        .GetGovernorate(this.fc['CountryId'].value)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    else this.Governorates = [];
    this.SelectedGovernorate();
  }

  protected ChangGovernorate() {
    this.fc['CityId'].setValue(null);
    this.SelectedGovernorate();
  }
  // get from city
  private SelectedGovernorate() {
    // this.fc.fromCityId.setValue(null)
    if (this.fc['GovernorateId'].value !== null) {
      this.subscription.add(
        this._Lookup
          .GetCity(this.fc['GovernorateId'].value)
          .subscribe((cities) => (this.cityList = cities))
      );
    } else {
      this.cityList = [];
      this.fc['CityId'].setValue(null);
    }
  }

  protected onSubmit() {
    if (this.insertForm.valid) {
      // Perform the submit logic here

      this.UpdateData();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private UpdateData() {
    this.loopform();
    this.submit = false;
    this._EditProfile.UpdateProfileTeacher(this.logoForm).subscribe(
      (res) => {
        if (res.success == true) {
          Swal.fire({
            icon: 'success',
            title: this._transition.instant('Profile updated successfully'),
            showConfirmButton: false,
            timer: 1500,
          });

          this.initiate(res.data);
          this.imageSrc = env.serverFirstHalfOfImageUrl + res.data.image;
          if (res.data) {
            this.fc['IsTeacher'].setValue(res.data?.isTeacher);
            this.submit = true;
            localStorage.setItem('customer_name', res.data['name']);
            localStorage.setItem('logo', res.data['image']);
            this._AuthService.ReloadHeader(true);
          }
        }
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        //this.insertForm.removeControl(key);
      } else {
        if (typeof this.insertForm.value[key] !== 'object' && key !== 'image')
          this.logoForm.append(key, this.insertForm.value[key]);
        else if (typeof this.insertForm.value[key] == 'object')
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });
    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
      this.logoForm.append('TeacherImage', this.selectedFile);
  }

  getAllSelect() {
    this.GetGovernorate();
    this.SelectedGovernorate();
    //this.SelectTruckType();
  }

  preview(files: any) {
    const file = files.target.files[0];
    if (file.length === 0) return;
    var mimeType = file.type;
    const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
    // this.selectedFile = file;
    if (before_ == 'image') {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > 2) {
        // File size exceeds 5MB
        // this.FileSize=false
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
        });
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
        this.imageSrc =
          environment.serverFirstHalfOfImageUrl +
          this._AuthService.GetByName('logo');
      } else {
        this.selectedFile = file;
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    } else {
      this.imageSrc =
        environment.serverFirstHalfOfImageUrl +
        this._AuthService.GetByName('logo');
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

      Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });
      return;
    }
  }
  private seo(){
    this.seoService.setMetaImage(this.imageSrc ? this.imageSrc : 'assets/images/Profile/Pic.png'   );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.TeacherEditProfile.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }

}
