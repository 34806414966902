<div class="trxt-center youtube  ">

  <iframe class="lg:w-[500px]" height="315" src="https://www.youtube.com/embed/aHEMFyz6HQ0?si=iEE0NVFjKd_Zkf_b" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  <p class="text-black text-center text-[16px] Lama-Regular  my-2">

      {{ "GroupForLesson.header.Explanation" | translate }}

  </p>
</div>

<div >
  <mat-accordion >
    <!-- #docregion basic-panel -->

    <!-- #enddocregion basic-panel -->
      <mat-expansion-panel
      class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border  dark:border-gray-700"
      style="border-radius:15px;  "
      (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
          <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
          <mat-panel-description>


            <div
            class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
          >
            <div class="pb-3">
              <img src="assets/images/MrS-Cool/setting.png" alt="" />
            </div>
            <div class="addressIconSpace">
              <h2 class="text-left Lama-bold dark:text-white underTitle ">
                    {{ "GroupForLesson.header.Filter-GroupForLesson" |translate}}
              </h2>
            </div>
          </div>


          </mat-panel-description>
        </mat-expansion-panel-header>





        <div
        class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg p-4 cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 "
      >
        <form class="max-md:px-1 " [formGroup]="SearchForm">
          <div class="grid gap-x-5   grid-cols-1  max-md:mb-3">
            <div class="lg:grid-cols-4 grid  gap-4  max-md:mb-3">


              <div class="mx-2">
                <label
                for="groupName"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.Group-Name" | translate }}
              </label>
                <input
                  type="text"
                  formControlName="groupName"
                  id="SearchInp"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter group Name"
                />
              </div>
              <div class="mx-2">
                <label
                for="startDate"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.SDate" | translate }}
              </label>
                <input
                  type="date" (keypress)="$event.preventDefault()"
                  formControlName="startDate"
                  id="startDate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter start Date"
                />
              </div>
              <div class="mx-2">
                <label
                for="endDate"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.EDate" | translate }}
              </label>
                <input
                  type="date" (keypress)="$event.preventDefault()"
                  formControlName="endDate"
                  id="endDate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter End Date"
                />
              </div>
              <div class=" lg:col-span-2 mx-2 max-md:mb-2">
                <label
                for="teacherSubjectAcademicSemesterYear"
                class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "GroupForLesson.form.Subject-Name" | translate }}
              </label>
                <ng-select id="teacherSubjectAcademicSemesterYearId"  #teacherSubjectAcademicSemesterYearSearch [selectOnTab]="true" formControlName="teacherSubjectAcademicSemesterYearId" class="form-control p-0"
                dropdownPosition="auto" labelForId="validation02"
                [tooltip]="fc['teacherSubjectAcademicSemesterYearId'].errors?.['required'] ?  ('GroupForLesson.form.required' | translate) : null"
                [isDisabled]="!fc['teacherSubjectAcademicSemesterYearId'].errors && fc['teacherSubjectAcademicSemesterYearId'].touched" containerClass=""
                [searchable]="true"
                (change)="ChangTeacherSubjectAcademicSemesterYearForListSearchForm()"

                >
                <ng-option value=""> {{'GroupForLesson.form.Choose-the-Subject' |translate}}
                </ng-option>
                <ng-option   *ngFor="let type of SubjectAcademicSemesterYearForList ; let i =index" [value]="type.id">
                  {{ type.subjectDisplayName }}
                </ng-option>
              </ng-select>
              </div>
              <div class=" lg:col-span-1 mx-2 max-md:mb-2">
                <label
                  for="countries"
                  class="block mb-2  text-gray-900 dark:text-white"
                  >{{ "GroupForLesson.form.Lesson" | translate }}
                  <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label >
                <ng-select id="teacherLessonId"  #teacherSubjectAcademicSemesterYearSearch [selectOnTab]="true" formControlName="teacherLessonId" class="form-control p-0"
                dropdownPosition="auto" labelForId="validation02"
                [tooltip]="fc['teacherLessonId'].errors?.['required'] ?  ('GroupForLesson.form.required' | translate) : null"
                [isDisabled]="!fc['teacherLessonId'].errors && fc['teacherLessonId'].touched" containerClass=""
                [searchable]="true"

                >
                <ng-option value="">           {{ "GroupForLesson.form.ChooseLesson" | translate }}</ng-option>
                <ng-option   *ngFor="let lesson of TeacherLessonForListSearch ; let i =index" [value]="lesson.id">
                  {{ lesson.lessonName }}
                </ng-option>
              </ng-select>

              </div>


              <div class="text-right align-items-end d-flex lg:col-span-1 justify-end mx-2 max-md:mb-2">
                <button
                  type="submit"
                  (click)="ChangeSearchInp()"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] btnFilter hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                {{ "GroupForLesson.form.Filter" | translate }}

                </button>
              </div>
            </div>


          </div>
        </form>
      </div>

      </mat-expansion-panel>
    </mat-accordion>


  <mat-accordion >
    <!-- #docregion basic-panel -->

    <!-- #enddocregion basic-panel -->
      <mat-expansion-panel
      class="bg-white rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3"
      style="border-radius:15px;  "
      (opened)="panelOpenState = true"
      [expanded]="true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
          <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
          <mat-panel-description>

            <div
            class="d-flex w-full align-items-center border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
          >
            <div class="pb-3">
              <img src="assets/images/MrS-Cool/setting.png" alt="" />
            </div>
            <div class="addressIconSpace">
              <h3  class="text-[30px] text-left Lama-bold dark:text-white underTitle">

                {{ "GroupForLesson.Tabble.header.Title" | translate }}

              </h3>

            </div>
          </div>

          </mat-panel-description>
        </mat-expansion-panel-header>


       <div
  >
    <div class="relative overflow-x-auto dark:bg-[#1a202c] ">

      <div
        class="d-flex align-items-center startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6  mb-3"
      >
        <table
          class="w-full  text-left rtl:text-right dark:bg-[#1a202c] text-white-500 dark:text-white-400"
        >
          <thead
            class="Lama-Medium text-white  dark:bg-[#1a202c] bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.Subject" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.Group" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.groupCost" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.SDate" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.Lesson" | translate }}
              </th>

              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.STime" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ "GroupForLesson.Tabble.thead.ETime" | translate }}
              </th>
              <th scope="col" class="px-6 py-3">

                {{ "GroupForLesson.Tabble.thead.Action" | translate }}

              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
              *ngFor="let item of MyLessonSchedualGroup; let y = index"
            >
              <th
                scope="row"
                class="px-6 text-[12px] py-4  text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ item.teacherSubjectAcademicSemesterYearName }}
              </th>
              <td class="px-6 py-4">
                {{ item.groupName }}
              </td>
              <td class="px-6 py-4">
                {{ item?.groupCost}}
              </td>
              <td class="px-6 py-4">
                {{ item.date|date}}
              </td>
              <td class="px-6 py-4">
                {{ item.lessonName}}
              </td>
              <td class="px-6 py-4">
                {{ item.timeFrom }}
              </td>
              <td class="px-6 py-4">
                {{ item.timeTo}}
              </td>
              <td class="px-6 py-4 text-center">

                <a
                  (click)="TableDeleteGroupID = item.id; openModal(template)"
                  class=" text-red-600 red:text-blue-500 hover:underline"
                  >   <img src="assets/images/Icons/delete.png" alt="Icons delete" srcset=""
              /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- </div> -->

    <ng-template #template>
      <div class="" style="position: relative; top: 50%">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div>
            <div class="p-6 text-center">
              <div class="text-center">
                <img
                  src="assets/images/MrS-Cool/delete-Icon.png"
                  class="m-auto p-3"
                  alt=""
                />
              </div>
              <h3
                class="mb-3 text-lg font-normal text-gray-500 dark:text-gray-400"
              >
                {{'GroupForLesson.form.Are-you-sure-you-want-to-delete' |translate}}
              </h3>
              <button
                (click)="modalRef?.hide(); submit? DeleteLessonTeacherScheduleGroup(TableDeleteGroupID):null;submit=false"
                type="button"
                [disabled]="!submit"
                class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                {{'GroupForLesson.form.Yes' |translate}}
              </button>
              <button
                (click)="modalRef?.hide()"
                type="button"
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                {{'GroupForLesson.form.No' |translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>



      </mat-expansion-panel>
    </mat-accordion>





<mat-accordion >
  <!-- #docregion basic-panel -->

  <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
    class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border  dark:border-gray-700"
    style="border-radius:15px;  "
    (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"  >
        <!-- <mat-panel-title>
          <div class="card-header CardHeader text-center border-0">
            <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
              {{ "TeacherForSubjects.form.Filter" | translate }}
            </h5>
          </div>
        </mat-panel-title> -->
        <mat-panel-description>
          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 w-full pt-2 "
        >
          <div class="pb-3">
            <img src="assets/images/Icons/add-file-button_.png" alt="" />
          </div>
          <div class="addressIconSpace">
            <h3  class="text-left Lama-bold dark:text-white underTitle">
              {{ "GroupForLesson.header.Add" | translate }}
            </h3>

          </div>
        </div>


        </mat-panel-description>
      </mat-expansion-panel-header>


     <div
  class="  bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 "
>
  <form
    class="mb-5 max-md:px-1 "
    [formGroup]="dynamicForm"
    (ngSubmit)="onSubmit()"
  >

    <div class="grid gap-2 mb-6 lg:grid-cols-4 md:grid-cols-1  px-6 startPagePadding">
      <div class="col-span-3">
        <label
          for="countries"
          class="block mb-2  text-gray-900 dark:text-white"
          >{{ "GroupForLesson.form.Subject" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >


        <ng-select id="teacherSubjectAcademicSemesterYearId"  #teacherSubjectAcademicSemesterYearId [selectOnTab]="true" formControlName="teacherSubjectAcademicSemesterYearId"
        class="form-control p-0"          (change)="ChangTeacherSubjectAcademicSemesterYearForList()"

        dropdownPosition="auto" labelForId="validation02"
        [tooltip]="
        fc['teacherSubjectAcademicSemesterYearId'].invalid &&
        (fc['teacherSubjectAcademicSemesterYearId'].dirty || fc['teacherSubjectAcademicSemesterYearId'].touched)
          ? ('GroupForLesson.form.required' | translate)
          : null
      "          [isDisabled]="!fc['teacherSubjectAcademicSemesterYearId'].errors && fc['teacherSubjectAcademicSemesterYearId'].touched" containerClass=""
        [searchable]="true"

            [class.is-valid]="fc['teacherSubjectAcademicSemesterYearId'].valid && (fc['teacherSubjectAcademicSemesterYearId'].touched  || fc['teacherSubjectAcademicSemesterYearId'].value)"
            [class.is-invalid]="fc['teacherSubjectAcademicSemesterYearId'].invalid && (fc['teacherSubjectAcademicSemesterYearId'].touched )"
            [ngClass]="{
              'class-1': fc['teacherSubjectAcademicSemesterYearId'].invalid && (fc['teacherSubjectAcademicSemesterYearId'].dirty || fc['teacherSubjectAcademicSemesterYearId'].touched),
              'class-2': fc['teacherSubjectAcademicSemesterYearId'].valid && (fc['teacherSubjectAcademicSemesterYearId'].dirty || fc['teacherSubjectAcademicSemesterYearId'].touched),
            }"
        >
        <ng-option value=""> {{'GroupForLesson.form.Choose-the-Subject' |translate}}
        </ng-option>
        <ng-option   *ngFor="let type of SubjectAcademicSemesterYearForList ; let i =index" [value]="type.id">
          {{ type.subjectDisplayName }}
        </ng-option>
      </ng-select>

      <p *ngIf="alertFlag" class="pt-2">{{'modify_group_price' | translate}} <a   routerLink="/main/ManageMySubjects" rel="noopener noreferrer"> {{'Click_hear' | translate}} </a></p>

      </div>
      <div class="  lg:col-span-1 col-span-3">
        <label
          for="countries"
          class="block mb-2  text-gray-900 dark:text-white"
          >{{ "GroupForLesson.form.Lesson" | translate }}  <span *ngIf="TeacherLessonObject" class="text-green-600 text-[13px] Lama-Regular">/{{'GroupForLesson.form.takes' |translate}}  {{TeacherLessonObject.groupDuration}}m</span>
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label >
        <ng-select id="teacherLessonId"  #teacherSubjectAcademicSemesterYearSearch [selectOnTab]="true" formControlName="teacherLessonId" class="form-control p-0"
        dropdownPosition="auto" labelForId="validation02"
        [tooltip]="fc['teacherLessonId'].errors?.['required'] ?  ('GroupForLesson.form.required' | translate) : null"
        [isDisabled]="!fc['teacherLessonId'].errors && fc['teacherLessonId'].touched" containerClass=""
        [searchable]="true"
        [class.is-valid]="fc['teacherLessonId'].valid && (fc['teacherLessonId'].touched  || fc['teacherLessonId'].value)"
        [class.is-invalid]="fc['teacherLessonId'].invalid && (fc['teacherLessonId'].touched )"
        [ngClass]="{
          'class-1': fc['teacherLessonId'].invalid && (fc['teacherLessonId'].dirty || fc['teacherLessonId'].touched),
          'class-2': fc['teacherLessonId'].valid && (fc['teacherLessonId'].dirty || fc['teacherLessonId'].touched),
        }"
        (change)="ChangeTeacherLessonForList()"
        >
        <ng-option value="">           {{ "GroupForLesson.form.ChooseLesson" | translate }}</ng-option>
        <ng-option   *ngFor="let lesson of TeacherLessonForList ; let i =index" [value]="lesson.id">
          {{ lesson.lessonName }}
        </ng-option>
      </ng-select>

      </div>
      <div class="  lg:col-span-2 col-span-3">
        <label
          for="countries"
          class="block mb-2  text-gray-900 dark:text-white"
          >
          {{ "GroupForLesson.form.Group" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >
        <input
          type="text"
          formControlName="groupName"
          [tooltip]="
            fc['groupName'].invalid &&
            (fc['groupName'].dirty || fc['groupName'].touched)
              ? ('GroupForLesson.form.required' | translate)
              : null
          "
          [ngClass]="{
              'class-1': fc['groupName'].invalid && (fc['groupName'].dirty || fc['groupName'].touched),
              'class-2': fc['groupName'].valid && (fc['groupName'].dirty || fc['groupName'].touched),
            }"
          id="groupName"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="{{ 'GroupForLesson.form.PGroup' | translate }}"

          required
        />
      </div>
      <div class="  lg:col-span-2 col-span-3">


            <label
              for="groupCost"
              class="block mb-2  text-gray-900 dark:text-white"

              >{{ "GroupForLesson.form.groupCost" | translate }}
              <span
                class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                >*</span
              >
            </label>
            <input
              type="number"
            [min]="0"
              formControlName="groupCost"
              [tooltip]="fc['groupCost'].hasError('pattern') ? ('PriceShouldNotStartWithZero' | translate) : (fc['groupCost'].errors?.['required'] ? ('GroupPriceFromRequired' | translate) : null)"
              [ngClass]="{
                'class-1': fc['groupCost'].invalid && (fc['groupCost'].dirty || fc['groupCost'].touched),
                'class-2': fc['groupCost'].valid && (fc['groupCost'].dirty || fc['groupCost'].touched),
              }"
              id="groupCost"
              class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#1a202c] dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your Price"
              [placeholder]="'Enter_your_Pricer' | translate"
              required
            />

          </div>
          <div class="  lg:col-span-2 col-span-3">
        <label
          for="countries"
          class="block mb-2  text-gray-900 dark:text-white"
          >       {{ "GroupForLesson.form.Date" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span></label
        >
        <input
          type="date" (keypress)="$event.preventDefault()"
          formControlName="startDate"
          [min]="expirationDateNow"
          [tooltip]="
            fc['startDate'].invalid &&
            (fc['startDate'].dirty || fc['startDate'].touched)
              ? ('GroupForLesson.form.required' | translate)
              : null
          "
          [ngClass]="{
              'class-1': fc['startDate'].invalid && (fc['startDate'].dirty || fc['startDate'].touched),
              'class-2': fc['startDate'].valid && (fc['startDate'].dirty || fc['startDate'].touched),
            }"
          id="startDate"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"

          required
        />
      </div>
      <div class="  lg:col-span-2 col-span-3">
        <label
          for="timeFrom"
          class="block mb-2  text-gray-900 pt-3 dark:text-white"
          > {{ "GroupForLesson.form.STime" | translate }}  <span *ngIf="TeacherLessonObject &&fc['timeFrom'].value" class="text-green-600 text-[13px] Lama-Regular">/{{'GroupForLesson.form.takes' |translate}}  {{ calculateEndTime(TeacherLessonObject.groupDuration,fc['timeFrom'].value) }} </span><span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold ">*</span>
        </label>
        <input
          type="time"
          formControlName="timeFrom"
          [tooltip]="
            fc['timeFrom'].invalid &&
            (fc['timeFrom'].dirty ||
              fc['timeFrom'].touched)
              ? ('GroupForLesson.form.required' | translate)
              : null
          "
          [ngClass]="{
          'class-1': fc['timeFrom'].invalid && (fc['timeFrom'].dirty || fc['timeFrom'].touched),
          'class-2': fc['timeFrom'].valid && (fc['timeFrom'].dirty || fc['timeFrom'].touched),
        }"
          id="timeFrom"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter your from Time"
          required
        />
      </div>

        <div class="text-right  col-span-4 d-flex align-items-end justify-end">
        <button
        type="submit" [disabled]="!submit"
          [disabled]="!submit"
          class="text-white btnWithForm bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
        {{ "GroupForLesson.form.Save" | translate }}

         </button>
        <button
          type="button"
          class="text-gray-900 btnWithForm bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c]  dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          (click)="Clear()"
        >
        {{ "GroupForLesson.form.clear" | translate }}


        </button>
      </div>
    </div>
      <!-- <div
        class="grid gap-8 mb-6 lg:grid-cols-4 md:grid-cols-4 px-6 startPagePadding"
      >
      </div> -->


  </form>
</div>



    </mat-expansion-panel>
  </mat-accordion>


  </div>



