<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 py-2"
>
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
    <div class="mr-2">
      <img src="assets/images/MrS-Cool/info_.png" alt="" />
    </div>
    <div>
      <h3  class="text-[30px] text-left sora-bold dark:text-white">
        {{ "ManageMySubject.header.Title" |translate }}
      </h3>
    </div>
  </div>
  <div class="grid gap-8 mb-6 lg:grid-cols-4 md:grid-cols-1 px-6 mt-3">
    <div>
      <label
        for="countries"
        class="block mb-2  sora-bold text-[16px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageMySubject.form.Education-Type" | translate }}
        <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
      >
      <p class="sora-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{TeacherSubjectAcademic?.educationTypeName}}</p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-bold text-[16px]  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageMySubject.form.Education-Level" | translate }}
        <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
      >
      <p class="sora-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{TeacherSubjectAcademic?.educationLevelName}}</p>

    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-bold text-[16px]  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageMySubject.form.Academic-Year" | translate }}
        <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
      >
      <p class="sora-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{TeacherSubjectAcademic?.academicYearName}}</p>

    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-bold text-[16px]  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "ManageMySubject.form.Subject" | translate }} <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
      >
      <p class="sora-Regular text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{TeacherSubjectAcademic?.subjectSemesterYearName}}</p>

    </div>
  </div>
</div>

<div
  class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 py-10 p-6"
>
  <form class="max-md:px-1">
    <div class="grid gap-6 mb-6 lg:grid-cols-3 px-6 mt-3">
      <div>
        <input type="SearchInp"  [formControl]="SearchInp"

        id="SearchInp" class="max-md:w-full dark:text-black  bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg
         focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Choose subject lesson" >
      </div>

      <div class="text-right lg:col-span-2 max-md:mt-3">
        <button
          type="submit"
          (click)="ChangeSearchInp()"
          class="ml-auto text-white bg-[var(--theme-deafult-TeacherWithClick)]  focus:outline-none focus:ring-0 focus:ring-gray-300   rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700"
        >
          {{'ManageMySubject.form.Filter' |translate}}
        </button>
      </div>
    </div>
  </form>
</div>

<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 p-6"
>
  <div class="relative overflow-x-auto ">
    <div
      class="d-flex align-items-center border-b-2  dark:border dark:bg-[#1a202c] dark:border-gray-700 mb-3"
    >
      <div class="mr-3">
        <img src="assets/images/MrS-Cool/setting.png" alt="" />
      </div>
      <div>
        <h3  class="text-[30px] text-left sora-bold dark:text-white">
          {{ "ManageMySubject.header.Title2" |translate}}
        </h3>
      </div>
    </div>

    <div
      class="relative overflow-x-auto border-b-2 dark:border dark:bg-[#1a202c] dark:border-gray-700   mb-3"


    >
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="sora-Regular text-white  rtl:text-right  bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
        >
          <tr>
            <th scope="col" class="px-6 py-2"></th>
            <th scope="col" class="px-6 py-2">
              {{ "ManageMySubject.form.Lessons" | translate }}
            </th>
            <th scope="col-2" colspan="2" class="px-6 py-2">
              {{ "ManageMySubject.form.For-Individual" | translate }}
              <div class="grid gap-4 grid-cols-2 border-t-2 pt-2 border-white">
                <p>{{ "Price" }}</p>
                <p>{{ "Time" }}</p>
              </div>
            </th>
            <th scope="col-2" colspan="2" class="px-6 py-2">
              {{ "ManageMySubject.form.For-Group" | translate }}
              <div class="grid gap-4 grid-cols-2 border-t-2 pt-2 border-white">
                <p class="col-span-1 m-0">{{ "ManageMySubject.form.Price"  | translate}}</p>
                <p class="col-span-1 m-0">{{ "ManageMySubject.form.Time"  | translate}}</p>
              </div>
            </th>

            <!-- <th scope="col" class="px-6 py-2">{{"ManageMySubject.form.Minimum"  | translate}}</th>
            <th scope="col" class="px-6 py-2">{{"ManageMySubject.form.Maximum"  | translate}}</th> -->
            <th scope="col" class="px-6 py-2">{{'ManageMySubject.form.Action'  | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let lesson of teacherUnitLessons; let i = index"
          >
            <tr>
              <th class="text-center border" [attr.rowspan]="1 + lesson.teacherUnitLessons.length">
                {{ lesson.unitName }}
              </th>
            </tr>
            <tr
              class="bg-white border-b dark:bg-[#1a202c] dark:border-gray-700"
              *ngFor="let item of lesson.teacherUnitLessons; let y = index"
            >
              <td
                class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
              >
                <div>
                  {{ item.lessonName }}
                <p
                [ngClass]="{
                  'txtRed': !item.teacherBrief && !item.teacherBriefEn,
                  'text-green-600': item.teacherBrief || item.teacherBriefEn,
                }"
                class="sora-Medium    dark:text-white"> {{  item.teacherBrief ||item.teacherBriefEn ? "Lesson brief added" : "Lesson brief NOT added" }} </p>

                </div>
              </td>

              <td class="px-6 py-4">
                <div *ngIf="item.isEdit" class="pt-4">
                  <input
                    [(ngModel)]="item.individualCost"
                    type="text" appOnlyNumbersWithDouble
                    id="individualCost"
                    *ngIf="item.isEdit" required
                    class="max-md:w-20 md:w-12 dark:text-black bg-white border form-control border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your individual Cost"
                  />
                  <!-- <div class="text-danger" *ngIf="validateField(item.individualDuration)">
                    <span > Required </span>
                </div> -->
                  <!-- <div class="text-[var(--theme-deafult-TeacherWithClick)]" >
                  <span > R. {{item.defaultIndividualCost}} to 70 EGP </span>
              </div> -->

              <div class="text-[var(--theme-deafult-TeacherWithClick)] pt-1 WMaxContent">
                <span class="sora-Medium  tex-[10px]  "> R. <span class="sora-bold">{{ item.defaultIndividualCost }}</span>  EGP </span>
              </div>
                </div>
                <div *ngIf="!item.isEdit">
                  {{ item.individualCost }}

                  <div class="text-[var(--theme-deafult-TeacherWithClick)] pt-1 WMaxContent">
                    <span class="sora-Medium  "> R. <span class="sora-bold">{{ item.defaultIndividualCost }}</span>  EGP </span>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4">
                <div *ngIf="item.isEdit">
                  <input
                    [(ngModel)]="item.individualDuration"
                    type="text" appOnlyNumbersNotToStarZero
                    id="individualDuration"
                    *ngIf="item.isEdit" required
                    class="max-md:w-20 md:w-12 dark:text-black bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your individual Cost"
                  />
                  <!-- <div class="text-danger" *ngIf="validateField(item.individualDuration)">
                    <span > Required </span>
                </div> -->
                  <!-- <div class="text-[var(--theme-deafult-TeacherWithClick)]" >
                  <span > R. {{item.defaultIndividualCost}} to 70 EGP </span>
              </div> -->
                </div>
                <div *ngIf="!item.isEdit">
                  {{ item.individualDuration }}

                  <!-- <div class="text-[var(--theme-deafult-TeacherWithClick)] pt-1" >
                  <span > R. {{item.defaultIndividualCost}} to 70 EGP </span>
              </div> -->
                </div>
              </td>
              <td class="px-6 py-4">
                <div *ngIf="item.isEdit" class="pt-4 m-2">
                  <input
                    *ngIf="item.isEdit"
                    [(ngModel)]="item.groupCost" appOnlyNumbersWithDouble
                    type="text"
                    id="GroupCost"required
                    class="max-md:w-20 md:w-12 dark:text-black bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your individual Cost"
                  />
                  <div class="text-[var(--theme-deafult-TeacherWithClick)] pt-1 WMaxContent">
                    <span class="sora-Medium tex-[9px]  "> R. <span class="sora-bold">{{ item.defaultGroupCost }}</span>  EGP </span>
                  </div>
                  <!-- <div class="text-danger" *ngIf="validateField(item.groupCost)">
                    <span > Required </span>
                </div> -->
                </div>
                <div *ngIf="!item.isEdit">
                  {{ item.groupCost }}
                  <div class="text-[var(--theme-deafult-TeacherWithClick)] pt-1">
                    <span class="sora-Medium  "> R. <span class="sora-bold">{{ item.defaultGroupCost }}</span>  EGP </span>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4">
                <div *ngIf="!item.isEdit">
                  {{ item.groupDuration }}
                </div>
                <div *ngIf="item.isEdit">

                <input
                  [(ngModel)]="item.groupDuration" appOnlyNumbersNotToStarZero
                  *ngIf="item.isEdit"
                  type="text"
                  required
                  id="groupDuration"
                  class=" max-md:w-20 md:w-12 dark:text-black bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your individual Cost"
                />
                </div>
              </td>

              <td class="px-6 py-4">
                <div class="d-flex justify-between" *ngIf="!item.isEdit">
                  <a
                  [tooltip]="   ('ManageMySubject.form.Click-Edit-Lesson' | translate)"
                    (click)="item.isEdit = true;setItem(item)"
                    class=" mr-1 font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img src="assets/images/Icons/edit.png" alt="" srcset=""
                  /></a>
                  <a
                  [tooltip]="   ('ManageMySubject.form.Click-Edit-Lesson-Brief' | translate)"
                    (click)="item.id >0 ? openModalForHeacherBriefComponent(item.id,i):null"
                    class="mr-1 font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img src="assets/images/Icons/write.png" alt="" srcset=""
                  /></a>
                  <a
                  (click)="item.id > 0 ? navigate(item.id):null"
                   routerLinkActive="router-link-active"
                    class=" mr-1font-medium text-red-600 red:text-blue-500 hover:underline"
                  >
                    <img
                      src="assets/images/Icons/book_.png"
                      class="mr-1"
                      alt=""
                      srcset=""
                  /></a>
                </div>

                <div class="d-flex justify-between" *ngIf="item.isEdit">
                  <button
                    class="btn btn-success btn-sm mx-1"
                    [disabled]="!submit"
                    (click)="validateForm(item)"
                  >
                  <img
                  src="assets/images/Icons/Done.png"

                  class="mr-1"
                  alt=""
                  srcset=""
              />
                    <!-- {{ item.id == 0 ? "Create" : "Update" }} -->
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    (click)="onCancel(item); "
                  >
                  <img
                  src="assets/images/Icons/Close_square.png"
                  class="mr-1"
                  alt=""
                  srcset=""
              />
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!-- </div> -->
</div>

<!-- (click)="Edit(item)" -->
