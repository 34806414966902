import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, ViewChild, Renderer2, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TeacherLessonSessionCommentService } from 'src/app/shared/Apis/Teacher/Chat/teacher-lesson-session-comment.service';
import {
  IChat,
  IGetAllLessonChatByTeacher,
  ITeacherLessonSessionCommentDetailsDtos,
} from 'src/app/shared/Model/teacher/ichat';
 import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
 import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-teacher-chat',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    CustomPipeForImagesPipe,
    TranslateModule,
    NgbRatingModule
  ],
  templateUrl: './teacher-chat.component.html',
  styleUrls: ['./teacher-chat.component.scss'],
})
export class TeacherChatComponent implements OnInit {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  isHovered!: boolean;
  isMobile: boolean=false;
  Contacts!: IChat[];
  protected submit: boolean = true;
  protected newMessage: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\s*\S.*$/),
  ]);
  ViewList: boolean = false;
  isCheck: boolean = false;
  activeIndex: number | null = null; // Initialize the active index as null
  activeIndexSubject: number | null = null;
  messageDate!: IGetAllLessonChatByTeacher;
  messages!: ITeacherLessonSessionCommentDetailsDtos[];
  bookTeacherLessonSessionDetailId: any;
  studentId: any;
  searchText: string = '';
  filteredContacts = this.Contacts; // Default to all contacts

  constructor(
    private renderer: Renderer2,
    private _TeacherLessonSessionCommentService: TeacherLessonSessionCommentService,
    private route: ActivatedRoute,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    this.GetContacts();
    this.route.queryParams.subscribe((params) => {
      this.studentId = params['studentId'];
      const bookTeacherLessonSessionDetailId =
        params['bookTeacherLessonSessionDetailId'];
      if (
        typeof bookTeacherLessonSessionDetailId != 'undefined' &&
        bookTeacherLessonSessionDetailId !== null
      ) {
        // Use the values as needed
        this.OpenMessage(bookTeacherLessonSessionDetailId);
        this.bookTeacherLessonSessionDetailId =
          bookTeacherLessonSessionDetailId;
      }
    });
  }
  ngOnInit(): void {
    this.seo()

  }
  GetContacts() {
    this._TeacherLessonSessionCommentService
      .GetAllStudentsChatWithTeacher()
      .subscribe((contacts) => {
        this.Contacts = contacts;
        this.filteredContacts = contacts;
        this.filterContacts()
      });
  }
  loadMessages(index: any, bookTeacherLessonSessionDetailId: any) {
    this.bookTeacherLessonSessionDetailId = bookTeacherLessonSessionDetailId;
    if (this.activeIndexSubject === index) {
      this.activeIndexSubject = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndexSubject = index; // Set the clicked item as the active one
    }
    this.OpenMessage(bookTeacherLessonSessionDetailId);
    this.isMobile=true;
  }
  OpenMessage(bookTeacherLessonSessionDetailId: any) {
    this._TeacherLessonSessionCommentService
      .GetAllLessonChatByTeacher(bookTeacherLessonSessionDetailId)
      .subscribe((res) => {
        this.messages = res.teacherLessonSessionCommentDetailsDtos;
        this.messageDate = res;

        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      });
  }
  toggleActive(index: number) {
    this.isCheck = true;
    if (this.activeIndex === index) {
      this.activeIndex = null; // If the clicked item is already active, deactivate it
    } else {
      this.activeIndex = index; // Set the clicked item as the active one
    }
  }
  // Inside your component class
  protected isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  protected isActiveSubject(index: number): boolean {
    return this.activeIndexSubject === index;
  }
  protected sendMessage(): void {
    this.submit = false;

    if (this.newMessage.valid) {
      const data = {
        bookTeacherLessonSessionDetailId: this.bookTeacherLessonSessionDetailId,
        comment: this.newMessage.value,
      };
      this._TeacherLessonSessionCommentService.SendMessage(data).subscribe(
        (res) => {
          this.messages = res.data.teacherLessonSessionCommentDetailsDtos;
          this.newMessage.reset();
          setTimeout(() => {
            this.scrollToBottom();
          }, 0);
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
    } else this.submit = true;
  }
  scrollToBottom() {
    try {
      this.renderer.setProperty(
        this.chatContainer.nativeElement,
        'scrollTop',
        this.chatContainer.nativeElement.scrollHeight
      );
    } catch (err) {}
  }
    //  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/message.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.TeacherChat.header.Message);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}

filterContacts(): void {
  const searchTextLower = this.searchText.toLowerCase();
  this.filteredContacts = [];

  for (let i = 0; i < this.Contacts.length; i++) {
    const contactName = this.Contacts[i].studentName.toLowerCase();
    if (contactName.includes(searchTextLower)) {
      this.filteredContacts.push(this.Contacts[i]);
    }
  }
}
}
