import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,

} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import {
  NgbPagination,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { ParentService } from 'src/app/shared/Apis/Parent/parent.service';
import { environment } from 'src/environments/environment';
import { ChildrenIdService } from './children-id.service';
import {
  ParentChildern,
  ParentChildern_,
} from 'src/app/shared/core/data/slider';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { IMyChildren } from 'src/app/shared/Model/Parent/Parent';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-choose-student',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    NgbProgressbarModule,
    TranslateModule,
    NgbRatingModule,
    NgbPagination,
    NgxPaginationModule,
  ],
  templateUrl: './choose-student.component.html',
  styleUrls: ['./choose-student.component.scss'],
})
export class ChooseStudentComponent implements OnInit, OnChanges {
  ImgUrlServer = environment.serverFirstHalfOfImageUrl;
  MyChildren: IMyChildren[] = [];
  selectedChild!: IMyChildren;
  @Output() selectStudent: EventEmitter<any> = new EventEmitter<any>();
  Childrenindex!: number;
  childrenIdFromService!: number;
  @Input() ChildrenId!: number;
  public ParentChildernliderConfig: any = ParentChildern;
  public InstaSliderConfig: any = ParentChildern_;

  constructor(
    protected ParentService: ParentService,
    private childrenIdService: ChildrenIdService,
    protected _AuthService: AuthService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,

  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.GetMyChildren().then(() => {
      this.childrenIdFromService = parseInt(
        this._AuthService.getCurrentChildrenId()
      );
      this.ChooseStudent();

      this.childrenIdService.backOnStudent.subscribe((res) => {
        if (res != null) {
          if (JSON.parse(this._AuthService.GetByName('isUpdate'))) {
            // this.GetMyChildren()
            this.getAll();
            if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('isUpdate', JSON.stringify(false));}
          }
          const id = parseInt(res);
          if (!isNaN(id)) {
            this.ChangeStudent(id);
            this.childrenIdFromService = id;
            // this.handleChildrenIdChange(); // Invoke the logic for handling childrenIdFromService change
          }
        }
      });
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['childrenIdFromService'] || changes['Childrenindex']) {
      this.childrenIdFromService =
        changes['childrenIdFromService'].currentValue;
      this.Childrenindex = changes['Childrenindex'].currentValue;
      // أدخل الإجراءات المطلوبة هنا لتحديث القالب بناءً على التغييرات في childrenIdFromService
    }
  }

  // GetMyChildren() {
  //   this.ParentService.GetMyChildren().subscribe(
  //     (res) => (this.MyChildren = res.data)
  //   );
  // }

  // async GetMyChildren() {
  //   try {
  //     const res = await this.ParentService.GetMyChildren().toPromise();
  //     this.MyChildren = res?.data??[];
  //   } catch (error) {
  //     console.error('Failed to get children:', error);
  //   }
  // }

  async GetMyChildren() {
    try {
      const res = await firstValueFrom(this.ParentService.GetMyChildren());
      this.MyChildren = res.data;
    } catch (error) {
      console.error('Failed to get children:', error);
    }
  }

  ChooseStudent() {
    const id = localStorage.getItem('childrenId');
    if (!this.Childrenindex && id)
      if (!this.childrenIdService.childrenIdSubject.value)
        this.childrenIdFromService = Number(id);
      else
        this.childrenIdFromService =
          this.childrenIdService.childrenIdSubject.value;

    if (!isNaN(this.childrenIdFromService)) {
      this.Childrenindex = this.MyChildren.findIndex(
        (x) => x.id == this.childrenIdFromService
      );
      this.childrenIdService.setChildrenName(
        this.MyChildren[this.Childrenindex]?.name
      );
      this.childrenIdService.setChildrenId(
        this.MyChildren[this.Childrenindex]?.id
      );
      this.reloadPage();
    }

    // this.ChangeStudent(this.childrenIdFromService);
  }

  ChangeStudent(selectedItem: any) {
    this.childrenIdFromService = selectedItem;
    // if(this.MyChildren.length>0){
    this.childrenIdService.setChildrenId(selectedItem);

    this.Childrenindex = this.MyChildren.findIndex((x) => x.id == selectedItem);

    this.childrenIdService.setChildrenName(
      this.MyChildren[this.Childrenindex]?.name
    );
    // this.selectedChild = this.MyChildren[this.Childrenindex]
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem(
      'academicYearId',
      JSON.stringify(
        this.MyChildren[this.Childrenindex].academicYearEducationLevelId
      )
    );}
    this.childrenIdService.setAcademicleveId(
      this.MyChildren[this.Childrenindex].academicYearEducationLevelId
    );
    this.reloadPage();


  }


  handleChildrenIdChange(): void {
    // Retrieve the updated children ID
    const updatedChildrenId = this.childrenIdFromService;

    // Perform any necessary actions based on the updated children ID
    // For example, you can update the selected child's information or trigger other related operations

    // Update the selected child's information based on the new ID
    const selectedChild = this.MyChildren.find(
      (child) => child.id === updatedChildrenId
    );
    if (selectedChild) {
      this.selectedChild = selectedChild;
      // Perform further actions with the selected child
    } else {
      console.log('Selected Child not found');
      // Handle the case when the selected child is not found
    }

    // Trigger other related operations or update the template as needed
    // ...

    // Example: Update the academic level ID in the childrenIdService
    const academicLevelId = selectedChild?.academicYearEducationLevelId;
    if (academicLevelId) {
      this.childrenIdService.setAcademicleveId(academicLevelId);
    } else {
      console.log('Academic level ID not found');
      // Handle the case when the academic level ID is not available
    }
  }

  protected reloadPage() {
    if (isPlatformBrowser(this.platformId)) {

    localStorage.setItem('role', 'children');
    localStorage.setItem('addchildren', 'false');
    this._AuthService.ReloadSidebar(true);}
  }
}
