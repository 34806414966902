<div
  class="global-card max-md:mb:2 m-auto w-fullbg-white rounded-lg shadow bg-white sha dow p-2 my-2"
>
  <div class="px-8 py-3">
    <h2
      class="text-[18px] lg:text-[30px] Lama-bold text-[var(--theme-deafult-TeacherWithClick)]"
    >
      {{
        type == "TopBookedSubjects"
          ? ("our-most-popular-Subjects.MostBookedSubjects" | translate)
          : ("our-most-popular-Subjects.MostViewedSubjects" | translate)
      }}
    </h2>

    <hr />
  </div>
  <div class="w-full" *ngIf="data && data[0] != null">
    <owl-carousel-o
      dir="ltr"
      [options]="InstaSliderConfig"
      class="no-arrow"

    >
      <ng-container *ngFor="let item of data">
        <ng-template carouselSlide>
          <div
            class="w-10/12 m-auto md:mb-5"
            [ngClass]="{
              content: data!.length <= 2,
              ' w-10/12': data!.length > 2
            }"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div
              class="logo-block rounded-lg ... p-2"
              (click)="GoToSubjectOrLessonTeachers(item.subjectId)"
            >
              <img
                [src]="item.image | customPipeForImages"
                alt="Boolk loessons"
                (error)="handleImageError(item)"
                class="m-auto w-full book-lesson"
              />

              <!-- <hr /> -->

              <div class="grid gap-2 contentCard md:grid-cols-2 mt-2">
                <div class="col-span-2 p-2">
                  <p
                    class="text-[12px] md:text-[16px] text-start Lama- bold rtl-p"
                  >
                    {{ formatString(item.subjectName)[0] }}
                  </p>
                  <p class="text-[12px] text-start Lama- bold rtl-p">
                    {{ formatString(item.subjectName)[1] }} ,
                    {{ formatString(item.subjectName)[2] }}
                  </p>
                </div>
                <hr class="col-span-2" style="margin: 0" />
                <div class="col-span-2 flex"
                [ngClass]="{
                  'justify-between':  showTeacherCount,
                  'justify-center':!showTeacherCount,
                }">
                  <div *ngIf="showTeacherCount"
                    class=" NumColor text-[10px] Lama-Regular flex items-center"
                  >
                    <img
                      src="assets/images/OurMostPopularLessons/person_.svg"
                      alt="Min Price"
                      class="w-9 person inline-block mr-2"
                    />
                    <span class="inline-block">
                      <span class="Lama-bold text-[12px]">
                        {{ item.teacherCount }}
                      </span>
                      {{'StudentSubjectDetails.header.available-teachers' |translate}}</span
                    >
                  </div>
                  <div
                    class=" NumColor text-[10px] Lama-Regular flex items-center"
                  >
                    <img
                      src="assets/images/OurMostPopularLessons/document.svg"
                      alt="Max Price"
                      class="w-6 person_black inline-block mr-2"
                    />
                    <span class="inline-block">
                      <span class="Lama-bold text-[12px]">
                        {{ item.lessonsCount }} </span
                      >{{'StudentSubjectDetails.header.Lessons' |translate}}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

  <div class="text-center" *ngIf="!data || !data.length">
    <div class="my-5 px-8 flex justify-center" [ngSwitch]="type">
      <div class="text-center" *ngSwitchCase="'TopViewedSubjects'">
        <img
          src="assets/images/OurMostPopularLessons/empty/TopViewedSubjects.svg"
          alt="calendar"
          class="m-auto"
        />
        <p class="text-[#868B9F] mt-3">
          {{ "Showing.NoAvailable" | translate }}     {{ "Showing.most" | translate }} <br />
         {{ "Showing.viewedSybjectsyet" | translate }}
          <span class="vector-bg"> </span>
        </p>
      </div>

      <div class="text-center" *ngSwitchDefault>
        <img
          src="assets/images/OurMostPopularLessons/empty/TopViewedSubjects.svg"
          alt="calendar"
          class="m-auto"
        />
        <p class="text-[#868B9F] mt-3">
          {{ "Showing.NoAvailable" | translate }} {{ "Showing.most" | translate }} <br />
          {{ "Showing.BookedSubjectsYet" | translate }}
          <span class="vector-bg"> </span>
        </p>
      </div>
    </div>
  </div>
</div>
