import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';
import {
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
 import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MustMatch } from 'src/app/shared/validators/MustMatch';
import { StudentRegisterSteps } from 'src/app/shared/Enums/teacher-register-steps';
import { IOtp } from 'src/app/shared/Model/teacher/teacher';
 ;
import { NavTabsComponent } from '../../tabs/nav-tabs/nav-tabs.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
 import { VefiryStudentComponent } from '../../Student/student-sign-up/vefiry-student/vefiry-student.component';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-student-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BaseComponent,
    VefiryStudentComponent,
    TooltipModule,
    NavTabsComponent,OnlyNumbersDirective,   NgSelectModule,
  ],
  templateUrl: './student-sign-up.component.html',
  styleUrls: ['./student-sign-up.component.scss']
})
export class StudentSignUpComponent extends BaseComponent implements OnInit {
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected StudentSteps = new StudentRegisterSteps;
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected DataVefiryUser!: IOtp;
  protected submit: boolean = true;
  protected changetype: boolean = true;
  protected confirmtype: boolean = true;

  private logoForm!: FormData;

  protected subscription = new Subscription();
  protected  EducationTypes: any;
  protected EducationLevel:any;
  protected EducationtypeId:any
  protected  AcademicYear: any
  constructor(
    private StudentService: StudentService,
    private _fb: FormBuilder,
    private _Lookup: LookupService,
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.initiate();
    this.GetEducationtype()
    this.seo();
  }



  private initiate() {
    this.insertForm = this._fb.group(
      {
        Name: ['', Validators.required],
         Birthdate: ['', Validators.required],
        // Email: ['', Validators.required],
        GenderId: ['', Validators.required],
        Mobile: ['', [Validators.required]],
        PasswordHash: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        // remmove
        Accept: [false, [Validators.required]],
        confirmPassword: ['', Validators.required],
        EducationTypeId: [ '', Validators.required],
        EducationLevelId: ['', Validators.required],
        AcademicYearEducationLevelId: ['', Validators.required],
      },
      {
        validators: [MustMatch('PasswordHash', 'confirmPassword')],
      }
    );
  }
  protected get fc() {
    return this.insertForm.controls;
  }



  // GetEducationLevel(id:any) {
  //   if (id !==  "null")
  //     this._Lookup
  //       .GetEducationLevelByEducationType(id)
  //       .subscribe((EducationLevel) => (this.EducationLevel = EducationLevel));
  //   else this.EducationLevel = [];
  // }



  protected onSubmit() {
    if (this.insertForm.valid && this.fc['Accept'].value == true) {
      // Perform the submit logic here
      this.insertData();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }

  private insertData() {
    this.loopform();
    this.submit = false;
    const data ={
      "name": this.insertForm.get('Name')?.value,
      "academicYearEducationLevelId": this.insertForm.get('AcademicYearEducationLevelId')?.value,
      "birthdate": this.insertForm.get('Birthdate')?.value,
      "genderId": this.insertForm.get('GenderId')?.value,
      "mobile":  this.insertForm.get('Mobile')?.value,
      "passwordHash": this.insertForm.get('PasswordHash')?.value,
    }
    this.StudentService.AddStudent(data).subscribe(
      (res) => {
        if (res.success == true) {
          this.StudentSteps = {
            signUp: false,
            vefiryUser: true,
          };
          this.DataVefiryUser = res.data;
          // Swal.fire({
          //   icon: "success",
          //   title: this.translate.instant("Inserted Success"),
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  public handleEvent(event: boolean) {
    if (!event) {
      this.StudentSteps = {
        signUp: true,
        vefiryUser: false,

      };
    } else if (  event == true) {
      this.StudentSteps = {
        signUp: false,
        vefiryUser: true,
      };
    }

    //this.serviceId = event
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        // delete   this.insertForm.value[key] ;
      } else {
        if (typeof this.insertForm.value[key] !== 'object')
          this.logoForm.append(key, this.insertForm.value[key]);
        else
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });

    // if (this.logo != null) {
    //   this.logoForm.append("logoUrl", this.logo);
    // }
  }

  GetEducationtype(){
    this._Lookup.GetEducationType()
    .subscribe((EducationType)=>(this.EducationTypes=EducationType))
  }
  protected ChangEducationType() {
    this.fc['EducationLevelId'].setValue('');
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.EducationLevel=[]
this.AcademicYear=[]
     this.GetEducationLevel();
  }

  GetEducationLevel() {
    if (this.fc['EducationTypeId'].value)
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    else this.EducationLevel = [];
  }

  protected ChangEducationLevel() {
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.SelectedEducationLevel();
  }
  // get from AcademicYear
  private SelectedEducationLevel() {

    if (this.fc['EducationLevelId'].value  ) {
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
          .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
      );
    } else this.AcademicYear = [];
  }

  private seo(){
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/left_student.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.auth.SignUp + ' - ' + translations.Tabs.body.Student);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });

  }

}
