import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { changeEnv, environment as env } from 'src/environments/environment';
import { changeEnvProd } from 'src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import {   isPlatformBrowser } from '@angular/common';
import {
  ActivatedRoute,
} from '@angular/router';
import { Location } from '@angular/common';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss'],
})
export class HeaderAuthComponent implements OnInit {
  protected languages = [
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'Arabic',
      code: 'ar',
    },
  ];
  protected languageNames: string = 'Arabic';
  protected path!: string;
  protected Controller!: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public layout: LayoutService,
    protected route: ActivatedRoute,
    private _SSRService: SSRService
  ) {
    this.path = this._SSRService.Location(3);
    this.CheckFromController(this._SSRService.Location(2));

    this._SSRService
      .events(2)
      .subscribe((eventValue: string | [string, string]) => {
        if (typeof eventValue === 'string') {
          // Handle the case when eventValue is a string
          // this.Controller = eventValue == 'home' ? 'teacher' : eventValue;
          this.CheckFromController(eventValue);
          // Your logic for handling a single string value
        }
      });

      if (isPlatformBrowser(this.platformId)) {
    //For use in other places you need role l example for use in path or src
    localStorage.setItem('Controller', this.Controller);
     }

  }
  ngOnInit(): void {
    this.init();
  }

  private CheckFromController(eventValue: string) {
    if (
      eventValue !== 'student' &&
      eventValue !== 'parent' &&
      eventValue !== 'teacher'
    ) {
      // Your code when the condition is false
      this.Controller = 'teacher';
    } else {
      // Your code when the condition is true
      this.Controller = eventValue;
    }
  }
  private init() {
    if (isPlatformBrowser(this.platformId)) {
    if (typeof localStorage !== 'undefined') {
      const language = localStorage.getItem('language');
      const token = localStorage.getItem('customerAuthorization');
      //  token != null ? (this.isLogin = true) : (this.isLogin = false);
      language != null
        ? this.changeLanguage(language)
        : this.changeLanguage('ar');
    }
  }
  }
  changeLanguage(code: string, reload?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
      if (typeof localStorage !== 'undefined') {
        const language = localStorage.getItem('language');
        if (language != code) {
          this.translate.use(code);
          localStorage.setItem('language', code);
          this.layout.language = code;
          this.layout.config.langu = code;
          changeEnv(code);
          changeEnvProd(code);
          if (code == 'ar') {
            this.customizeLayoutType('rtl', reload);
            this.languageNames = 'English';
          } else {
            this.customizeLayoutType('ltr', reload);
            this.languageNames = 'Arabic';
          }
        } else {
          if (code == 'ar') {
            const logoSidebar = document.getElementById('logo-sidebar');
            if (logoSidebar) {
              logoSidebar?.classList.remove('-translate-x-full');
              logoSidebar?.classList.add('translate-x-full');
              logoSidebar?.classList.remove('left-0');
              logoSidebar.style.setProperty('left', 'unset', 'important');
            }
          } else {
            const logoSidebar = document.getElementById('logo-sidebar');
            if (logoSidebar) {
              logoSidebar?.classList.add('-translate-x-full');
              logoSidebar?.classList.remove('translate-x-full');
              // logoSidebar?.classList.add('left-0');
            }
          }
          console.log(code, '==', language);
          this.layout.config.langu = code;
          changeEnv(code);
          changeEnvProd(code);
        }
      }
    }
  }
  public layoutType: string = 'rtl'; // default
  // Layout Type
  private customizeLayoutType(val: string, reload?: boolean) {
    this.layout.config.settings.layout_type = val;
    if (typeof document !== 'undefined')
      if (val == 'rtl') {
        document.getElementsByTagName('html')[0].setAttribute('dir', val);
        document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');

        if (typeof document.body.classList !== 'undefined') {
          // access the classList property
          document.body.classList.remove('ltr');
          document.body.classList.add('rtl');
          const logoSidebar = document.getElementById('logo-sidebar');
          if (logoSidebar) {
            logoSidebar?.classList.remove('-translate-x-full');
            logoSidebar?.classList.add('translate-x-full');
            logoSidebar?.classList.remove('left-0');
            logoSidebar.style.setProperty('left', 'unset', 'important');
          }
        }
        reload == true ? this.layout.reloadPage() : null;
      } else {
        document.getElementsByTagName('html')[0].removeAttribute('dir');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
        if (typeof document.body.classList !== 'undefined') {
          // access the classList property
          document.body.classList.remove('rtl');
          document.body.classList.add('ltr');
          const logoSidebar = document.getElementById('logo-sidebar');
          if (logoSidebar) {
            logoSidebar?.classList.add('-translate-x-full');
            logoSidebar?.classList.remove('translate-x-full');
            // logoSidebar?.classList.add('left-0');
          }
        }
        reload == true ? this.layout.reloadPage() : null;
      }
  }
}
