import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TeacherLessonService } from '../../Apis/Teacher/TeacherLesson/teacher-lesson.service';
import { ITeacherBrief } from '../../Model/teacher/lesson';
import { RouterLink } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { DisableWritingEnglishDirective } from '../../Directive/disable-weriting-english.directive';
import { DisableWeritingArabicDirective } from '../../Directive/disable-weriting-arabic.directive';

@Component({
  selector: 'app-model-brief',
  standalone: true,
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
    RouterLink,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    DisableWritingEnglishDirective,
    DisableWeritingArabicDirective,
  ],
  templateUrl: './model-brief.component.html',
  styleUrls: ['./model-brief.component.scss'],
})
export class ModelBriefComponent implements OnInit {
  public teacherBrief!: number;
  public TeacherLessonBerif!: ITeacherBrief;
  protected insertForm!: FormGroup;
  // modalRef?: BsModalRef;
  constructor(
    public bsModalRef: BsModalRef,
    private _TeacherLesson: TeacherLessonService,
    private _fb: FormBuilder,
    private _transition: TranslateService
  ) {}

  ngOnInit(): void {
    this.initiate();
    if (this.teacherBrief && this.teacherBrief > 0) {
      this.GetTeacherLesson(this.teacherBrief);
    }
    // else this.bsModalRef.hide();
  }
  GetTeacherLesson(id: number) {
    this._TeacherLesson.GetTeacherBerifById(id).subscribe((res) => {
      this.TeacherLessonBerif = res;
      this.initiate(res);
    });
  }
  private initiate(row?: ITeacherBrief) {
    this.insertForm = this._fb.group({
      id: [row?.id || null],
      teacherBrief: [row?.teacherBrief || null],
      teacherBriefEn: [row?.teacherBriefEn || null],
    });
  }
  get fc() {
    return this.insertForm.controls;
  }
  protected onSubmit() {
    if (this.insertForm.valid) {
      // Perform the submit logic here
      this.fc['id'].value != null
        ? this.UpdateTeacherBrief()
        : this.bsModalRef.hide();
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
  private UpdateTeacherBrief() {
    // this.submit = false;

    const filter = { ...this.insertForm.getRawValue() }; // Create a shallow copy

    Object.keys(filter).forEach((key) => {
      if (filter[key] === '') {
        filter[key] = null;
      }
    });
    this._TeacherLesson.UpdateTeacherLessonBerif(filter).subscribe(
      (res) => {
        if (res.success == true) {
          Swal.fire({
            icon: 'success',
            title: this._transition.instant(
              `Update Teacher Lesson Berif Success`
            ),
            showConfirmButton: false,
            timer: 1500,
          });
          this.bsModalRef.hide();
          this.insertForm.reset();
        }
        // this.submit = true;
        this._TeacherLesson.setFlagValue(true);
      },
      (err) => {
        // this.submit = true;
      }
    );
  }
}
