import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { RouterLink } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';

@Component({
  selector: 'app-nav-tabs',
  standalone: true,
  imports: [CommonModule,TranslateModule,RouterLink],
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent {
  protected actionPart!:string
  protected Controller!:string
  protected isLogin:boolean=false
protected loginService = inject(AuthService);

constructor(private _SSRService:SSRService) {
 this.Controller=this._SSRService.Location(2)
 this.actionPart=this._SSRService.Location(3)


    // this._SSRService.events(3, 2).subscribe((result: string|[string, string]) => {
    //   // Handle the result when both action and controller are provided
    //   const [actionPart, controllerPart] = result;
    //   this.actionPart=actionPart;
    //   this.Controller=controllerPart;
    // });

}
}
