  <div
  [dir]="'ltr'"
  class="flex _ flex-wrap justify-center   align-items-center"
>
  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/6 p-24">
    <img src="assets/images/MrS-Cool/changePass.png" class="w-full" />
  </div>

  <div class="w-full sm:w-1/1 md:w-1/2 xl:w-2/3   md:ps-12">
    <div>
      <form
        class=" mb-5 startPagePadding m-auto rtl:text-end pb-3 dark:bg-[#1a202c] bg-[#ffffff]  rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
        *ngIf="!doneReset"
        [formGroup]="insertForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="text-left rtl:text-end">
          <h3 class="text-[16px]  text-left rtl:text-end lg:text-[30px]  sora-bold dark:text-white">
            {{ "ChangePassword.header.Text" | translate }}
          </h3>

        </div>

        <div>
          <label
            for="currentPassword"
            class="block   mt-1 rtl:text-end font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ChangePassword.form.Current-Password" | translate }}
           </label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            id="currentPassword"
            formControlName="currentPassword"
            class="bg-white border  border-gray-300 text-[var(--theme-deafult-TeacherWithClick)]  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'ChangePassword.form.Enter-Your-Password' | translate"
            required
            [tooltip]="
              fc['currentPassword'].invalid &&
              (fc['currentPassword'].dirty || fc['currentPassword'].touched)
                ? ('this field is required' | translate)
                : null
            "
            [ngClass]="{
                    'class-1': fc['currentPassword'].invalid && (fc['currentPassword'].dirty || fc['currentPassword'].touched),
                    'class-2': fc['currentPassword'].valid && (fc['currentPassword'].dirty || fc['currentPassword'].touched),
                  }"
          />
        </div>
        <div>
          <label
            for="newPassword"
            class="block  mt-1 rtl:text-end font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ChangePassword.form.New-Password" | translate }}
            </label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            id="newPassword"
            formControlName="newPassword"
            class="bg-white  border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)]  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
             dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'ChangePassword.form.Enter-Your-Password' | translate"
            required
            [tooltip]="
            fc['newPassword'].errors?.['required']
              ? ('this field is required' | translate)
              : fc['newPassword'].errors?.['minlength']
                ? 'password should be more than 6 characters'
                : null"
            [ngClass]="{
                    'class-1': fc['newPassword'].invalid && (fc['newPassword'].dirty || fc['newPassword'].touched),
                    'class-2': fc['newPassword'].valid && (fc['newPassword'].dirty || fc['newPassword'].touched),
                  }"
          />
        </div>
        <div>
          <label
            for="confirm_password"
            class="block   rtl:text-end mt-1 font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "ChangePassword.form.Confirm-New-Password" | translate }}
            </label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            formControlName="confirmPassword"
            id="confirm_password"
            class="bg-white   border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)]  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'ChangePassword.form.Enter-Your-Password' | translate"
            required
            [tooltip]="
            fc['confirmPassword'].errors?.['required']
              ? ('Password is required' | translate)
              :  fc['confirmPassword'].errors?.['mustMatch']
                  ? ('Passwords must match' | translate)
                  : null"

            [ngClass]="{
                    'class-1': fc['confirmPassword'].invalid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                    'class-2': fc['confirmPassword'].valid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                  }"
          />
        </div>

        <!-- <div class="text-center sora-Medium pt-3">
                  <p>Your password changed <br> successfully </p>
                </div> -->
        <button
          type="submit" [disabled]="!submit"
          [ngClass]="{

            'bg-[var(--theme-deafult-TeacherWithClick)] text-white ':insertForm.valid&& Controller=='teacher',
            'bg-[var(--theme-deafult-ParentWithClick)]':insertForm.valid&& Controller=='parent',
            'bg-[var(--theme-deafult-studentWithClick)]': insertForm.valid&&Controller=='student',

            'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
            'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
            'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
           }"

          class="text-[14px] disBtnTextColor sora-Regular w-full mb-5  mt-3 text-[#868B9F] bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] font-medium rounded-lg  px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
        {{ "ChangePassword.form.Save-Password" | translate }}
        </button>
      </form>

      <div
        class="m-auto ProfilePadding pb-3 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
        *ngIf="doneReset"
      >
        <img
          src="assets/images/MrS-Cool/VefiryUserSucc.png"
          class="w-1/6 m-auto py-3"
        />
        <h3 class="text-[16px]  lg:text-[30px]  text-center sora-bold dark:text-white">
          {{ "ChangePassword.form.Your-Password-Changed-Successfully" | translate }}
        </h3>
        <p class="text-[14px] text-[#868B9F] sora-Regular my-2 text-center">
          {{ "ChangePassword.form.Your-password-had-beed-changed-successfully" | translate }}
          <br />
          {{ "ChangePassword.form.Please-back-to-your-account-and-enjoy" | translate }}
        </p>

        <div class="my-3 mx-5">
          <button
            type="submit"
            [ngClass]="{

              'bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.valid&& Controller=='teacher',
              'bg-[var(--theme-deafult-ParentWithClick)]':insertForm.valid&& Controller=='parent',
              'bg-[var(--theme-deafult-studentWithClick)]': insertForm.valid&&Controller=='student',

              'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
              'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
              'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
             }"

            class="w-full disBtnTextColor  bg-gray-200 focus:bg-black my-3 bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg  px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black"
            (click)="navigate()"
            (keyup.enter)="navigate()" >
            {{ "Go-To-Home" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
