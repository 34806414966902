


    <!-- Faq Question section Start -->
    <section class="faq-contain">
      <div class="container">
          <div class="row">
              <div class="col-12" style="direction: rtl;">
                  <h2 class="bold text-center">الشروط والأحكام</h2>

                  <h2>1. المقدمة</h2>
<p>

  >يرجى قراءة هذه الشروط واألحكام بعناية قبل استخدام تطبيقات ومنصة
  " " مستر أس كول على الهواتف المحمولة ) و أجهزة الكمبيوتر المشار إليه
   " "(. فيما يلي بـ التطبيق تحكم هذه الشروط واألحكام استخدامك للتطبيق،
   وبالوصول إلى التطبيق أو استخدامه، فإنك توافق على االلتزام بهذه
  .الشروط واألحكام
</p>
                  <h2>2.  الغرض من التطبيق </h2>

                 <p>صمم تطبيق ومنصة مستر أس كول لربط المدرسين بالطلبة يستطيع
                  المدرس التدريس في أي وقت ومن أي مكان وأن يحدد سعر الحصة تبعا
                  . للوقت والمادة العلمية المقدمة كذلك التطبيق والمنصة يتيحوا للطلبة
                  . اختيار المدرس المناسب من الناحية العلمية أو المادية وأيضا يمكن أولياء
                 . األمور من متابعة أوالدهم دراسيا بشكل مستمر مع دفع آمن للحصص</p>


                  <h2>3.  مسؤوليات المستخدم</h2>

                  <ul>
                     <li>تقر وتوافق على أن استخدامك للتطبيق ومنصة مستر أس كول يكون
                      على مسؤوليتك الشخصية وأنك مسؤول بشكل كامل عن أي نتائج تنشأ عن
                      . استخدامك للتطبيق وللمنصة</li>
                  </ul>





                  <h2>4.  كمعلم أوافق علي</h2>

                  <ul>
         <li>  ألتزم بأخالقيات المهنة والسلوك المهني ناحية الطلبة -
        </li>
         <li>  عدم استخدام ألفاظ غير الئقة -
        </li>
         <li> االلتزام بزي مالئم -
        </li>
         <li>  عدم التقاط الصور للطلبة -
        </li>
         <li>  عدم تسجيل المحادثات -
        </li>
         <li>  عدم طلب أي معلومات شخصية من الطلبة -
        </li>
         <li>  جميع المستندات المقدمة أصلية وصحيحة -
        </li>
         <li>  تم أنشاء الحساب بنفسي -
        </li>
         <li>  االلتزام بالسلوك التربوي تجاه الطلبة -
        </li>
         <li>  االلتزام بمواعيد الحصة المحددة -
        </li>
         <li> عدم ألغاء الحصص بدون اعتذار مسبق حرصا علي وقت الطلبة -
          المسجلين </li>
         <li>. االلتزام بتقديم الحصة تبعا للمراحل التربوية التي تقترحها المنصة -  </li>
         <li>   استخدام األدوات اإلليكترونية التي تقدمها المنصة -
        </li>
         <li> استخدام أدوات التقييم في نهاية كل حصة -
        </li>
         <li> عدم مشاركة أي روابط أو أكواد أو مواد تعليمية يتم أرسالها لي بصفة -
          .خاصة </li>
         <li> . تقييم الطلبة بشكل عادل وتربوي أثناء وبعد الحصة -
        </li>
         <li>   جميع المحادثات والتواصل تتم من خالل المنصة -
        </li>
         <li>. / عدم عقد أي اتفاقيات مواعيد خارج المنصة -
        </li>
         <li> وقف حسابي لمدة اسبوع في حال خالفت الشروط السابقة للمرة -
          . األولي، وأسبوعين للمرة الثانية </li>
         <li> في حال تكرار المخالفات سيتم أيقاف الحساب نهائيا -




                  </ul>

                  <h3>5.    كولي األمر أوافق علي                    :</h3>
                  <ul>

                    <li>  / االطالع على نتائج أبني بنتي بانتظام -
                    </li>
                     <li>  جميع المحادثات والتواصل تتم من خالل المنصة -
                    </li>
                     <li>   / عدم عقد أي اتفاقيات مواعيد خارج المنصة -
                    </li>
                     <li>  عدم مشاركة أي معلومات شخصية -
                    </li>
                     <li> . تقييم المعلم بشكل عادل -
                    </li>
                     <li>وقف حسابي لمدة اسبوع في حال خالفت الشروط السابقة للمرة -
                      . األولي، وأسبوعين للمرة الثانية  </li>
                     <li> . في حال تكرار المخالفات للمرة الثالثة سيتم أيقاف الحساب نهائيا -
                    </li>
                  </ul>

                  <h3>6. كطالب أوافق علي  :</h3>
                  <ul>
                      <li> . االلتزام بالسلوك القويم مع أساتذتي وزمالئي -
                      </li>
                      <li> . االلتزام بإرشادات المعلم -
                      </li>
                      <li>  عدم استخدام ألفاظ غير الئقة -
                      </li>
                      <li>  االلتزام بزي مالئم -
                      </li>
                      <li> / عدم التقاط الصور للطلبة للمعلم -
                      </li>
                      <li>   عدم تسجيل المحادثات -
                      </li>
                      <li>   جميع المحادثات والتواصل تتم من خالل المنصة -
                      </li>
                      <li> . / عدم عقد أي اتفاقيات مواعيد خارج المنصة -
                      </li>
                      <li>   عدم مشاركة أي معلومات شخصية -
                      </li>
                      <li>  تقييم المعلم بشكل عادل -
                      </li>
                      <li>  االلتزام بمواعيد الحصة وإرشادات وقواعد المنصة -
                      </li>
                      <li> ال يتم استرجاع المبلغ المدفوع ولكن يتم أعادته الي المحفظة المالية -
                        . / علي المنصة التطبيق الستخدامها الحقا </li>

                  </ul>

                  <h2>7.   الخدمات من جهات خارجية</h2>
                  <p>قد يتضمن التطبيق روابط، أو مراجع لمواقع الويب ،أو الخدمات أو الموارد
                    . المقدمة من جهات خارجية هذه الخدمات من جهات خارجية ليست تحت
                    . سيطرتنا، وال نكون مسؤولين عن توفرها أو محتواها أو دقتها يخضع
                    استخدامك ألي خدمات من جهات خارجية لشروطها وأحكامها وسياسات
                   . الخصوصية الخاصة بها</p>
                  <ul>
                      <li>جميع المحتويات، بما في ذلك النصوص والشعارات والبرمجيات، مملوكة للمنصة.</li>
                      <li>يُحظر إعادة استخدام أو تعديل أو توزيع المحتوى دون إذن خطي مسبق.</li>
                  </ul>

                  <h2>8.    الملكية الفكرية
                  </h2>
                  <p>جميع حقوق الملكية الفكرية في التطبيق ومحتواه، بما في ذلك النصوص
                    والرسومات والشعارات والصور والبرمجيات، مملوكة لنا أو للمرخصين
                   . لدينا ال يجوز لك استنساخ، أو تعديل، أو توزيع أو عرض أي جزء من التطبيق
                   . أو المنصة بدون موافقتنا الخطية المسبقة</p>


                  <h2>9.   تحمل المسؤولية
                  </h2>
                  <p>بأقصى مدى يسمح به القانون، نُ لقي باللوم عن أي ضرر مباشر أو غير
                    مباشر أو عرضي أو استداللي أو خاص ينشأ عن استخدامك للتطبيق، بما
                    في ذلك ولكن ال يقتصر على أي أخطاء أو إغفال في المحتوى، أو أي
                    خسارة أو ضرر من أي نوع يتكبده بسبب استخدام أي محتوى أو خدمات
                   . متاحة عبر التطبيق أو المنصة</p>

                  <h2>10. التعديلات    </h2>
               <p>نحتفظ بالحق في تعديل أو إنهاء التطبيق أو هذه الشروط واألحكام في أي
                . وقت دون إشعار مسبق من مسؤوليتك مراجعة الشروط واألحكام بشكل
                . دوري ألي تغييرات مواصلة استخدام التطبيق بعد أي تعديل يشير إلى
               . قبولك لشروط وأحكام التحديث</p>
               <h2>11. التعديلات    </h2>
               <p>نحتفظ بتعديل نسبة الخصم التي يتم استقطاعها لحساب التطبيق والمنصة
                . دون الرجوع إليك ودون ابالغك بشكل شخصي مواصلة استخدام التطبيق
               . بعد أي تعديل يشير إلى قبولك التعديل</p>
               <p>في حالة عدم تنفيذ الحصة يتم تحويل النقود الي المحفظة المالية الخاصة
                بك الستخدامها فيما بعد على التطبيق والمنصة، وال يحق لك المطالبة بها
               . نقدا أو تحويلها الي أي حساب آخر
               </p>

                  <h2>12.: القانون المعمول به
                  </h2>
                  <p> تخضع هذه الشروط واألحكام للقوانين ويتم تفسيرها وفقًا لقانون جمهورية
                    .مصر العربية
                    </p>
                  <h2>13.  اتصل بنا                  </h2>
                  <ul>
                    <li>البريد الإلكتروني: {{ "info@mrscool.app" }}</li>
                    <li>الهاتف: 01023036779</li>
                    </ul>

<p>إذا كان لديك أي أسئلة أو استفسارات بشأن هذه الشروط واألحكام أو
  .التطبيق، يُ رجى التواصل معنا على معلومات االتصال</p>
              </div>
          </div>
      </div>
  </section>
  <!-- Faq Question section End -->
