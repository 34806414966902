<div class="flex _ flex-wrap justify-ce nter">
  <div class="flex justify-content-center sm:w-1/1 xl:w-2/6  max-xl:hidden">
    <!-- <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
      <input type="file" formControlName="TeacherImage" accept="image/jpeg,image/png,image/gif" />
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('required')">Please select an image</div>
      <div *ngIf="insertForm?.get('imaTeacherImagege')?.hasError('invalidFileType')">Invalid file type. Allowed types: JPEG, PNG, GIF</div>
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('invalidFileSize')">File size exceeds the maximum limit (5MB)</div>
      <button type="submit">Submit</button>
    </form> -->

    <!-- Example => assets/images/MrS-Cool/left_Parent.sv -->
    <img
      [src]="'assets/images/MrS-Cool/left_teacher.png'"
      class="w-full imgPosition"
    />
  </div>

  <div
    class="flexFormDivAuth max-sm:d-block w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="TeacherSteps.signUp == true"
  >
    <app-nav-tabs class="w-full mb-4"></app-nav-tabs>

    <form
      class="sm:w-full p-[50px] bg-white md:w-10/12 ProfilePadding rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="text-center">
        <p class="text-black text-center text-[16px] sora-Regular">
          <a
            href="https://www.youtube.com/watch?v=op5L2L0ROvg"
            target="_blank"
            class="text-black"
          >
                  {{ "TeacherSignUp.header.Explanation" | translate }} *
          </a>
        </p>
      </div>
      <div class="rtl:text-end">
        <h2
          class="text-[16px] rtl:text-start col-span-2 lg:text-[30px] text-left sora-bold dark:text-white"
        >
          {{ "TeacherSignUp.header.PesrsonalInformation" | translate }}
        </h2>
        <p class="rtl:text-end text-right text-[14px] sora-Regular">(1/3)</p>
      </div>
      <div>
        <div class="position-relative text-center 1 w-full" for="dropzone-file">
          <img
            (click)="dropzone.click()"
            [src]="imageSrc ? imageSrc : 'assets/images/Profile/Pic.png'"
            class="m-auto rounded-circle Profile-Pic"
            [alt]="'TeacherSignUp.form.AddProfilePicture' | translate"
          />
          <span class="edit" (click)="dropzone.click()">
            <img src="assets/images/Profile/edit.png" class="rounded-circle" />
          </span>
          <label
            for="first_name"
            class="block text-[16px] text-center mt-2 sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.AddProfilePicture" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            >
          </label>
        </div>
      </div>
      <div class="grid gap-x-5 md:grid-cols-2">
        <div>
          <label
            for="first_name"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Teacher-Name" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            >
          </label>
          <input
            type="text"
            formControlName="Name"
            [tooltip]="
              fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched)
                ? ('TeacherSignUp.form.required' | translate)
                : null
            "
            [ngClass]="{
                  'class-1': fc['Name'].invalid && (fc['Name'].dirty || fc['Name'].touched),
                  'class-2': fc['Name'].valid && (fc['Name'].dirty || fc['Name'].touched),
                }"
            id="first_name"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [placeholder]="'TeacherSignUp.form.Enter-your-name' | translate"
            required
          />
        </div>
        <div>
          <label
            for="countries"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Gender" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <!-- <select id="countries"  formControlName="GenderId"   class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              [tooltip]="fc['GenderId'].invalid && (fc['GenderId'].dirty || fc['GenderId'].touched) ? ('this field is required' | translate) : null"
              [ngClass]="{
                'class-1': fc['GenderId'].invalid && (fc['GenderId'].dirty || fc['GenderId'].touched),
                'class-2': fc['GenderId'].valid && (fc['GenderId'].dirty || fc['GenderId'].touched),
              }">
                <option  selected [value]=null  >{{ "Male/Female" | translate}} </option>
                <option *ngFor="let gender of genders" [value]="gender.id">{{gender.title |translate}}</option>
              </select> -->

          <ng-select
            #GenderId
            [selectOnTab]="true"
            formControlName="GenderId"
            class="form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GenderId'].errors?.['required'] ?  ('TeacherSignUp.form.required' | translate) : null"
            [isDisabled]="!fc['GenderId'].errors && fc['GenderId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GenderId'].valid &&
              (fc['GenderId'].touched || fc['GenderId'].value)
            "
            [class.is-invalid]="
              fc['GenderId'].invalid && fc['GenderId'].touched
            "
          >
            <ng-option selected value=""
              >{{ "TeacherSignUp.form.Male/Female" | translate }}
            </ng-option>

            <ng-option *ngFor="let gender of genders" [value]="gender.id"
              >{{ gender.title  | translate }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="phone"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Mobile-Number" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            type="tel"
            id="phone"
            formControlName="Mobile"
            onlyNumbers
            maxlength="11"
            minlength="11"
            class="bg-white rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            [placeholder]="'TeacherSignUp.form.Enter-your-number' | translate"
            [tooltip]="
              fc['Mobile'].invalid &&
              (fc['Mobile'].dirty || fc['Mobile'].touched)
                ? ('TeacherSignUp.form.required' | translate)
                : null
            "
            [ngClass]="{
              'class-1': fc['Mobile'].invalid && (fc['Mobile'].dirty || fc['Mobile'].touched),
              'class-2': fc['Mobile'].valid && (fc['Mobile'].dirty || fc['Mobile'].touched),
            }"
          />
          <!-- <label for="success" class="block text-[16px] sora-SemiBold text-green-700 dark:text-green-500">Your name</label>
        <input type="text" id="success" class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="Success input">
        <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span cla-[16px] sora-SemiBold">Well done!</span> Some success message.</p> -->
        </div>
        <div>
          <label
            class="block k text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Are-you-teacher" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <div class="flex items-center pt-3">
            <div class="flex items-center mr-4">
              <input
                id="inline-radio"
                type="radio"
                formControlName="IsTeacher"
                [value]="true"
                name="IsTeacher"
                class="w-4 h-4 border-2 border-[var(--theme-deafult-TeacherWithClick)] bg-white focus:ring-3 focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:ring-offset-gray-800"
                [tooltip]="
                  fc['IsTeacher'].invalid &&
                  (!fc['IsTeacher'].dirty || !fc['IsTeacher'].touched)
                    ? ('TeacherSignUp.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-checkbox-err': fc['IsTeacher'].invalid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                  'class-radio': fc['IsTeacher'].valid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                }"
              />
              <label
                for="inline-radio"
                class="ml-2 rtl:mr-2 p-0 text-[16px] sora-Regular text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
              >
                {{ "TeacherSignUp.form.Yes" | translate }}
              </label>
            </div>
            <div class="flex items-center mr-5">
              <input
                id="inline-2-radio"
                type="radio"
                formControlName="IsTeacher"
                [value]="false"
                name="IsTeacher"
                class="w-4 h-4 border-2 border-[var(--theme-deafult-TeacherWithClick)] bg-white focus:ring-3 focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:ring-offset-gray-800"
                [tooltip]="
                  fc['IsTeacher'].invalid &&
                  (fc['IsTeacher'].dirty || fc['IsTeacher'].touched)
                    ? ('this field is required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-checkbox-err': fc['IsTeacher'].invalid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                  'class-radio': fc['IsTeacher'].valid && (fc['IsTeacher'].dirty || fc['IsTeacher'].touched),
                }"
              />
              <label
                for="inline-2-radio"
                class="ml-2 rtl:mr-2 p-0 text-[16px] sora-Regular text-[var(--theme-deafult-TeacherWithClick)] focus:ring-[var(--theme-deafult-studentWithClick)] dark:text-gray-300"
              >
                {{ "TeacherSignUp.form.No" | translate }}
              </label>
            </div>
          </div>
        </div>

        <div>
          <label
            for="password"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Password" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            id="password"
            formControlName="PasswordHash"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'TeacherSignUp.form.Enter-your-password' | translate"
            required
            [tooltip]="
          fc['PasswordHash'].errors?.['required']
            ? ('TeacherSignUp.form.required' | translate)
            : fc['PasswordHash'].errors?.['minlength']
              ? 'password should be more than 6 characters'
              : null"
            [ngClass]="{
                  'class-1': fc['PasswordHash'].invalid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                  'class-2': fc['PasswordHash'].valid && (fc['PasswordHash'].dirty || fc['PasswordHash'].touched),
                }"
          />
        </div>
        <div>
          <label
            for="confirm_password"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Confirmpassword" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <input
            [type]="changetype ? 'password' : 'text'"
            formControlName="confirmPassword"
            id="confirm_password"
            class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="**********************"
            [placeholder]="'TeacherSignUp.form.Enter-your-password' | translate"
            required
            [tooltip]="
            fc['confirmPassword'].errors?.['required']
              ? ('TeacherSignUp.form.required' | translate)
              :  fc['confirmPassword'].errors?.['mustMatch']
                  ? ('Passwords must match' | translate)
                  : null"
            [ngClass]="{
                  'class-1': fc['confirmPassword'].invalid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                  'class-2': fc['confirmPassword'].valid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                }"
          />
        </div>
        <div>
          <label
            for="countries"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Country" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="countries"
            #countriesId
            [selectOnTab]="true"
            formControlName="CountryId"
            class="form-control p-0"
            (change)="ChangCountry()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['CountryId'].errors?.['required'] ?  ('this field is required' | translate) : null"
            [isDisabled]="!fc['CountryId'].errors && fc['CountryId'].touched"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['CountryId'].valid &&
              (fc['CountryId'].touched || fc['CountryId'].value)
            "
            [class.is-invalid]="
              fc['CountryId'].invalid && fc['CountryId'].touched
            "
            [ngClass]="{
          'class-1': fc['CountryId'].invalid && (fc['CountryId'].dirty || fc['CountryId'].touched),
          'class-2': fc['CountryId'].valid && (fc['CountryId'].dirty || fc['CountryId'].touched),
        }"
          >
            <ng-option value="">
              {{ "TeacherSignUp.form.Choose-your-country" | translate }}
            </ng-option>
            <ng-option *ngFor="let country of countries" [value]="country.id"
              >{{ country.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <label
            for="GovernorateId"
            class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
            >{{ "TeacherSignUp.form.Governorate" | translate }}
            <span
              class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
              >*</span
            ></label
          >
          <ng-select
            id="GovernorateId"
            #GovernorateId
            [selectOnTab]="true"
            formControlName="GovernorateId"
            class="form-control p-0"
            (change)="ChangGovernorate()"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['GovernorateId'].errors?.['required'] ?  ('this field is required' | translate) : null"
            [isDisabled]="
              !fc['GovernorateId'].errors && fc['GovernorateId'].touched
            "
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['GovernorateId'].valid &&
              (fc['GovernorateId'].touched || fc['GovernorateId'].value)
            "
            [class.is-invalid]="
              fc['GovernorateId'].invalid && fc['GovernorateId'].touched
            "
            [ngClass]="{
              'class-1': fc['GovernorateId'].invalid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
              'class-2': fc['GovernorateId'].valid && (fc['GovernorateId'].dirty || fc['GovernorateId'].touched),
            }"
          >
            <ng-option value="">
              {{ "TeacherSignUp.form.Choose-your-governorate" | translate }}
            </ng-option>
            <ng-option
              *ngFor="let governorate of Governorates"
              [value]="governorate.id"
              >{{ governorate.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="">
        <label
          for="CityId"
          class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "TeacherSignUp.form.City" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
            >*</span
          ></label
        >
        <ng-select
          id="CityId"
          #CityId
          [selectOnTab]="true"
          formControlName="CityId"
          class="form-control p-0"
          dropdownPosition="auto"
          labelForId="validation02"
          [tooltip]="fc['CityId'].errors?.['required'] ?  ('this field is required' | translate) : null"
          [isDisabled]="!fc['CityId'].errors && fc['CityId'].touched"
          containerClass=""
          [searchable]="true"
          [class.is-valid]="
            fc['CityId'].valid && (fc['CityId'].touched || fc['CityId'].value)
          "
          [class.is-invalid]="fc['CityId'].invalid && fc['CityId'].touched"
          [ngClass]="{
            'class-1': fc['CityId'].invalid && (fc['CityId'].dirty || fc['CityId'].touched),
            'class-2': fc['CityId'].valid && (fc['CityId'].dirty || fc['CityId'].touched),
          }"
        >
          <ng-option value="">
            {{ "TeacherSignUp.form.Choose-your-city" | translate }}
          </ng-option>
          <ng-option *ngFor="let city of cityList" [value]="city.id"
            >{{ city.name }}
          </ng-option>
        </ng-select>
      </div>
      <!-- <div  >
          <label for="Birthdate" class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{"Birthdate" |translate}}</label>
          <input type="date" id="Birthdate" [max]="dateConverterPlus18()" formControlName="Birthdate"class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="**********************" required> </div> -->

      <!-- <div  >
            <label for="email" class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">Email address</label>
            <input type="email" id="email" formControlName="Email" class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required>
        </div> -->
      <div>
        <label
          for="message"
          class="block text-[16px] sora-SemiBold text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "TeacherSignUp.form.Teacher-BIO" | translate }}</label
        >
        <textarea
          id="message"
          formControlName="TeacherBIO"
          rows="4"
          class="block p-2.5 w-full text-sm text-[var(--theme-deafult-TeacherWithClick)] bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          [placeholder]="
            'TeacherSignUp.form.Tell-us-about-yourself' | translate
          "
        ></textarea>
        <input
          id="dropzone-file"
          #dropzone
          type="file"
          class="hidden rtl:rtl-input"
          (change)="preview($event)"
          formControlName="Image"
        />
      </div>
      <div class="flex items-center">
        <div class="flex items-end h-5">
          <input
            id="remember"
            type="checkbox"
            formControlName="Accept"
            style="border: 3px solid var(--theme-deafult-TeacherWithClick)"
            class="w-4 h-4 bg-white focus:ring-3 focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:ring-offset-gray-800"
            [tooltip]="
              fc['Accept'].value == false &&
              (fc['Accept'].dirty || fc['Accept'].touched)
                ? ('TeacherSignUp.form.required' | translate)
                : null
            "
            [ngClass]="{
              'class-checkbox-err': fc['Accept'].value ==false && (fc['Accept'].dirty || fc['Accept'].touched),
              'class-radio': fc['Accept'].value ==true && (fc['Accept'].dirty || fc['Accept'].touched),
            }"
          />
        </div>
        <label
          for="remember"
          class="ml-2 rtl:mr-2 Accept text-[14px] sora-Regular text-[var(--theme-deafult-TeacherWithClick)] focus:ring-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
          >{{
            "TeacherSignUp.form.Accept-the-Terms-and-Privacy-Policy" | translate
          }}
        </label>
      </div>
      <!-- <button
        type="submit"  [disabled]="!submit"
        [ngClass]="{'valid-form': insertForm.valid, 'invalid-form': insertForm.invalid}"
        class="text-[14px] sora-Regular w-full mb-5 text-[#868B9F] bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        {{ "Submit" | translate }}
      </button> -->

      <div class="flex justify-between align-center">
        <div class="pr-2">
          <button
            [disabled]="true"
            type="button"
            class="text-[#868B9F] bg-[#DDDFE5] text-[12px] lg:text-[14px] sora-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-black"
          >
            {{ "TeacherSignUp.form.Previous" | translate }}
          </button>
        </div>
        <div class="pl-2 mt-4">
          <i
            class="fa-solid fa-circle mx-2 text-[8px] text-[var(--theme-deafult-TeacherWithClick)]"
          ></i>
          <i class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5]"></i>
          <i class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5]"></i>
        </div>
        <div class="pl-2">
          <button
            type="submit"
            [disabled]="!submit"
            [ngClass]="{'valid-form': insertForm.valid, 'invalid-form': fc['Accept'].value !=true||insertForm.invalid,}"
            class="text-[#868B9F] bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white text-[12px] lg:text-[14px] sora-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)] dark:focus:ring-black"
          >
            {{ "TeacherSignUp.form.Save&Next" | translate }}
            <ng-container *ngIf="!submit">
              <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
            </ng-container>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    class="flexFormDivAuth max-sm:d-block w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="TeacherSteps.vefiryUser"
  >
    <app-vefiry-user
      [DataVefiryUser]="DataVefiryUser"
      [phoneNumber]="fc['Mobile'].value"
      (Step3)="handleEvent($event, 'subject')"
    ></app-vefiry-user>
  </div>

  <div
    class="flexFormDivAuth flexFormDivAuth2 max-sm:p-3 sm:p-3 w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="TeacherSteps.subjectInfo"
  >
    <app-subject-info
      (ToDocuments)="handleEvent($event, 'Documents')"
    ></app-subject-info>
  </div>
  <div
    class="flexFormDivAuth w-full mb-5 sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
    *ngIf="TeacherSteps.DocumentsInformation == true"
  >
    <app-documents-information
      (Previous)="handleEvent($event, 'Previous')"
    ></app-documents-information>
  </div>
</div>

<!--
<form>
  <div class="mb-6">
    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
    <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@flowbite.com" required>
  </div>
  <div class="mb-6">
    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
    <input data-popover-target="popover-password" data-popover-placement="bottom" type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
    <div data-popover id="popover-password" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
        <div class="p-3 space-y-2">
            <h3 class="font-semibold text-gray-900 dark:text-white">Must have at least 6 characters</h3>
            <div class="grid grid-cols-4 gap-2">
                <div class="h-1 bg-orange-300 dark:bg-orange-400"></div>
                <div class="h-1 bg-orange-300 dark:bg-orange-400"></div>
                <div class="h-1 bg-gray-200 dark:bg-gray-600"></div>
                <div class="h-1 bg-gray-200 dark:bg-gray-600"></div>
            </div>
            <p>It’s better to have:</p>
            <ul>
                <li class="flex items-center mb-1">
                    <svg class="w-3.5 h-3.5 me-2 text-green-400 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                    </svg>
                    Upper & lower case letters
                </li>
                <li class="flex items-center mb-1">
                    <svg class="w-3 h-3 me-2.5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    A symbol (#$&)
                </li>
                <li class="flex items-center">
                    <svg class="w-3 h-3 me-2.5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    A longer password (min. 12 chars.)
                </li>
            </ul>
    </div>
    <div data-popper-arrow></div>
</div>
  </div>
  <div class="flex items-start mb-6">
    <div class="flex items-center h-5">
      <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required>
    </div>
    <label for="remember" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
  </div>
  <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
</form> -->
