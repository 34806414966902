// export enum TeacherRegisterSteps {
//   signUp = 1,
//   vefiryUser = 2,
// }
export class TeacherRegisterSteps {
  signUp = true;
  vefiryUser = false
  subjectInfo = false
  DocumentsInformation = false
}

export class ForgetPasswordSteps {
  Forget = true;
  vefiryOtp = false
  ResetPassword = false
}


export class StudentRegisterSteps {
  signUp = true;
  vefiryUser = false

}
