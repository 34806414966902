<div class="align-center bg-white rounded-lg shadow" data-aos="flip-left"
data-aos-easing="ease-out-cubic"
data-aos-duration="2000">
  <div
    class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-[var(--theme-deafult-TeacherWithClick)]"
  >
    <div class="addressIconSpace">
      <h3 class="text-left sora-bold dark:text-white underTitle">
        {{ "Home.header.Title" | translate }}
      </h3>
      <p class="text-sm/[11px] text-left">
        {{ "Home.header.Subtitle" | translate }}
      </p>
    </div>
  </div>
  <div
    class="grid gap-1 mb-6 grid-cols-1 md:grid-cols-2 startPagePadding sm:p-0 main-home sora-Regular"
  >
    <!-- FCFBFF -->
    <div
      class="m-auto w-full max-sm:p-3 2xl:p-0 xl:p-0 lg:p-0 md:p-0 sm:w-11/12 md:w-4/5 bg-[#FCFBFF] border border-[#DDD] rounded-lg shadow"
    >
      <div>
        <form
          class="2xl:p-[30px] dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border dark:border-gray-700  lg:p-[30px] max-sm:p-3 md:p-[30px] ng-invalid ng-pristine ng-untouched py-1 sm:p-0 xl:p-[30px]"
          [formGroup]="insertForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="grid gap-1 mb-6 md:grid-cols-1 mt-3">
            <div class="col-span-1">
              <label
                for="first_name"
                class="block mb-2 flex text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >
                <img
                  src="assets/images/Anonymous/icon/icon.svg"
                  class="mr-2"
                  alt=""
                />
                <span class="pt-1 mr-1">{{
                  "Home.form.Education-Type" | translate
                }}</span>

                <span
                  class="text-[#FF0000] text-[12px] pt-2 md:text-[16px] sora-SemiBold"
                  >*</span
                >
              </label>
              <ng-select
                id="EducationTypeId"
                #educationType
                [selectOnTab]="true"
                formControlName="EducationTypeId"
                class="form-control p-0"
                (change)="ChangEducationType()"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="fc['EducationTypeId'].errors?.['required'] ?  ('Home.form.required' | translate) : null"
                [isDisabled]="
                  !fc['EducationTypeId'].errors && fc['EducationTypeId'].touched
                "
                containerClass=""
                [searchable]="true"
                [class.is-valid]="
                  fc['EducationTypeId'].valid &&
                  (fc['EducationTypeId'].touched || fc['EducationTypeId'].value)
                "
                [class.is-invalid]="
                  fc['EducationTypeId'].invalid && fc['EducationTypeId'].touched
                "
                [ngClass]="{
              'class-1': fc['EducationTypeId'].invalid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
              'class-2': fc['EducationTypeId'].valid && (fc['EducationTypeId'].dirty || fc['EducationTypeId'].touched),
            }"
              >
                <ng-option value="">
                  <span class="ngselectDOptionColor">{{
                    "Home.form.Education-Type" | translate
                  }}</span>
                </ng-option>
                <ng-option
                  *ngFor="let item of EducationTypes"
                  [value]="item.id"
                >
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>

            <div class="col-span-1">
              <label
                for="first_name"
                class="block mb-2 flex text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >
                <img
                  src="assets/images/Anonymous/icon/SubjectSemester.svg"
                  class="mr-2"
                  alt=""
                />
                <span class="pt-1 mr-1">{{
                  "Home.form.Education-Level" | translate
                }}</span>
                <span
                  class="text-[#FF0000] text-[12px] pt-2 md:text-[16px] sora-SemiBold"
                  >*</span
                >
              </label>
              <ng-select
                id="EducationLevelId"
                #educationType
                [selectOnTab]="true"
                formControlName="EducationLevelId"
                class="form-control p-0 mx-1 home sora-Regular"
                (change)="ChangEducationLevel()"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="fc['EducationLevelId'].errors?.['required'] ?  ('Home.form.required' | translate) : null"
                [isDisabled]="
                  !fc['EducationLevelId'].errors &&
                  fc['EducationLevelId'].touched
                "
                containerClass=""
                [searchable]="true"
                [class.is-valid]="
                  fc['EducationLevelId'].valid &&
                  (fc['EducationLevelId'].touched ||
                    fc['EducationLevelId'].value)
                "
                [class.is-invalid]="
                  fc['EducationLevelId'].invalid &&
                  fc['EducationLevelId'].touched
                "
                [ngClass]="{
              'class-1': fc['EducationLevelId'].invalid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
              'class-2': fc['EducationLevelId'].valid && (fc['EducationLevelId'].dirty || fc['EducationLevelId'].touched),
            }"
              >
                <ng-option value="">
                  <span class="ngselectDOptionColor">
                    {{ "Home.form.Education-Level" | translate }}</span
                  >
                </ng-option>
                <ng-option
                  *ngFor="let item of EducationLevel"
                  [value]="item.id"
                >
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-span-1">
              <label
                for="first_name"
                class="block mb-2 flex text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >
                <img
                  src="assets/images/Anonymous/icon/Academic.svg"
                  class="mr-2"
                  alt=""
                />
                <span class="pt-1 mr-1">{{
                  "Home.form.Academic-Year" | translate
                }}</span>
                <span
                  class="text-[#FF0000] text-[12px] pt-2 md:text-[16px] sora-SemiBold"
                  >*</span
                >
              </label>
              <ng-select
                id="SubjectSemesterId"
                #educationType
                [selectOnTab]="true"
                formControlName="SubjectSemesterId"
                class="form-control p-0 mx-1 col-span-6 home sora-Regular"
                (change)="ChangAcademicYear()"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="fc['SubjectSemesterId'].errors?.['required'] ?  ('Home.form.required' | translate) : null"
                [isDisabled]="
                  !fc['SubjectSemesterId'].errors &&
                  fc['SubjectSemesterId'].touched
                "
                containerClass=""
                [searchable]="true"
                [class.is-valid]="
                  fc['SubjectSemesterId'].valid &&
                  (fc['SubjectSemesterId'].touched ||
                    fc['SubjectSemesterId'].value)
                "
                [class.is-invalid]="
                  fc['SubjectSemesterId'].invalid &&
                  fc['SubjectSemesterId'].touched
                "
                [ngClass]="{
              'class-1': fc['SubjectSemesterId'].invalid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
              'class-2': fc['SubjectSemesterId'].valid && (fc['SubjectSemesterId'].dirty || fc['SubjectSemesterId'].touched),
            }"
              >
                <ng-option value="">
                  <span class="ngselectDOptionColor">
                    {{ "Home.form.Academic-Year" | translate }}</span
                  >
                </ng-option>
                <ng-option *ngFor="let item of AcademicYear" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-span-1 pr-2">
              <label
                for="first_name"
                class="block mb-2 flex text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >
                <img
                  src="assets/images/Anonymous/icon/term.svg"
                  class="mr-2"
                  alt=""
                />
                <span class="pt-1 mr-1">{{
                  "Home.form.Term" | translate
                }}</span>
                <span
                  class="text-[#FF0000] text-[12px] pt-2 md:text-[16px] sora-SemiBold"
                  >*</span
                >
              </label>
              <ng-select
                id="subjectAcademicYearId"
                #educationType
                [selectOnTab]="true"
                formControlName="subjectAcademicYearId"
                class="form-control p-0 mx-1 home sora-Regular"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="fc['subjectAcademicYearId'].errors?.['required'] ?  ('Home.form.required' | translate) : null"
                [isDisabled]="
                  !fc['subjectAcademicYearId'].errors &&
                  fc['subjectAcademicYearId'].touched
                "
                containerClass=""
                [searchable]="true"
                [class.is-valid]="
                  fc['subjectAcademicYearId'].valid &&
                  (fc['subjectAcademicYearId'].touched ||
                    fc['subjectAcademicYearId'].value)
                "
                [class.is-invalid]="
                  fc['subjectAcademicYearId'].invalid &&
                  fc['subjectAcademicYearId'].touched
                "
                [ngClass]="{
            'class-1': fc['subjectAcademicYearId'].invalid && (fc['subjectAcademicYearId'].dirty || fc['subjectAcademicYearId'].touched),
            'class-2': fc['subjectAcademicYearId'].valid && (fc['subjectAcademicYearId'].dirty || fc['subjectAcademicYearId'].touched),
          }"
              >
                <ng-option value="">
                  <span class="ngselectDOptionColor">
                    {{ "Home.form.Term" | translate }}</span
                  >
                </ng-option>
                <ng-option
                  *ngFor="let item of SubjectSemester"
                  [value]="item.id"
                >
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="m-auto p-3">
            <button
              type="submit"
              [disabled]="!submit"
              class="text-white btnH text-[12px] md:text-[14px] sora-Medium mb-3 w-full bg-[var(--theme-deafult-TeacherWithClick)] focus:bg-[var(--theme-deafult-TeacherWithClick)] bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
            >
              {{ "Home.form.Search" | translate }}
              <ng-container *ngIf="!submit">
                <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
              </ng-container>
            </button>
          </div>
        </form>
      </div>
    </div>
<div class="h-full">

  <img
  class="  ImgCover col-span-1     max-xl:hidden"
  src="../../../../../assets/images/Anonymous/Smart.jpg"
/>
</div>
  </div>
</div>
<br />

<app-our-most-popular-subjects
  [type]="'TopBookedSubjects'"
></app-our-most-popular-subjects>
<br />



<div class="row md:py-3  max-md:mb:2 ">

<div class="Anywhere row   justify-end align-items-center ">
  <!-- <img
  class="mb-4 ImgCover"
  [src]="language== 'en' ?'../../../../../assets/images/Anonymous/app.jpg':'../../../../../assets/images/Anonymous/app-ar.jpg'"
/> -->
  <div class="col-12 col-lg-3 col-xl-4  From max-md:px-8">
    <h3 class="title mb-2 text-white">Learn From</h3>
    <h3 class="title mb-2 text-[var(--theme-deafult-studentWithClick)]">Anywhere</h3>
    <p class="sora-Regular  text-white">Take classes on the go with Mr-Scool app. Stream or download to watch on the plane, the subway, or wherever you learn best.</p>

 <div class="row ">
  <div class="col-8 col-md-6">

    <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

      <i class="fa-brands fa-apple"></i> <div>
        <span class="sora-Regular Get">

          {{"home.Download" |translate}}

        </span>
        <br>
        <span class="sora-Medium">

          {{"home.Apple-Store" |translate}}
        </span>
      </div>
    </a>
  </div>
  <div class="col-8 col-md-6">

    <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

      <i class="fa-brands fa-google-play"></i> <div>
        <span class="sora-Regular Get">
          {{"home.Get-in-on" |translate}}

        </span>
        <br>
        <span class="sora-Medium">

          {{"home.Google-Play" |translate}}
        </span>
      </div>
    </a>
  </div>


 </div>
 <!-- <div class="ship">
  <img
    class="mb-4 ImgCover"
    src="../../../../../assets/images/Anonymous/ship.png"
  />
 </div> -->
  </div>

</div>
</div>

<app-most-booked-teachers
  [type]="'GetTeachersMostBooked'"
></app-most-booked-teachers>

<app-our-most-popular-lessons
  [type]="'MostBookedLessons'"
></app-our-most-popular-lessons>

<br />

<div class="row py-3 learnin g">
  <img
    class="md:mb-4  max-md:mb:2  ImgCover"
    [src]="language== 'en' ?'../../../../../assets/images/Anonymous/start-learning.jpg':'../../../../../assets/images/Anonymous/start-learning-ar.jpg'"
  />

  <!-- <p class="sora-bold">Start your Learning Journey Today!</p> -->
</div>
<!-- <app-most-booked-teachers
  [type]="'MostRatedTeacher'"
></app-most-booked-teachers>
<br /> -->

<app-our-most-popular-subjects
  [type]="'TopViewedSubjects'"
></app-our-most-popular-subjects>

<div class="row md:py-3">

  <img
  class="md:mb-4 max-md:mb:2 ImgCover"
  [src]="language== 'en' ?'../../../../../assets/images/Anonymous/app.jpg':'../../../../../assets/images/Anonymous/app-ar.jpg'"
/>
</div>
<br />
<app-our-most-popular-lessons
  [type]="'MostViewedLessons'"
></app-our-most-popular-lessons>
<br />

<app-most-booked-teachers
  [type]="'MostViewedTeacher'"
></app-most-booked-teachers>

<br />
