<div class="sm:px-5 sm:p-2 pt-10 md:px-3 md:ps-12 pt-12 text-center">
  <app-nav-tabs class="w-full mb-4"></app-nav-tabs>

  <div
  class="m-auto pb-3 ProfilePadding px-20 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
  *ngIf="DoneVefiryUser"
  >


    <img
      src="assets/images/MrS-Cool/VefiryUser.png"
      class="w-1/6 m-auto py-3"
    />
    <h3 class="text-[18px]  lg:text-[30px] sora-bold dark:text-white">
      {{ "VerifyStudent.header.Text" | translate }}
    </h3>
    <p class="text-[13px] sora-Regular">{{ "VerifyStudent.header.Subtitle" | translate }} <br />{{ "VerifyParent.header.for-verification" | translate }}</p>
    <ng-otp-input
    dir="ltr"
    class="text-[var(--theme-deafult-TeacherWithClick)]"
    (keyup.enter)="SendVerify()"
    #ngOtpInputRef
      (onInputChange)="onOtpChange($event)"
      [class.invalid-border]="isOtpInvalid"

      [config]="config"
    ></ng-otp-input>
    <!-- <pre>{{ DataVefiryUser.otp }}</pre> -->

    <div class="my-3 w">
      <button
      [ngClass]="{
        'bg-[var(--theme-deafult-TeacherWithClick)]':ngOtpInputRef.otpForm.status== 'VALID' && ngOtpInputRef.currentVal !=null,
        'bg-[#DDDFE5]': ngOtpInputRef.currentVal !=null,
        'text-[#868B9F]': ngOtpInputRef.currentVal !=null,
      }"
      type="submit" [disabled]="!submit"
        (click)="SendVerify()"
        (keyup.enter)="SendVerify()"
        class="w-full text-[#CAAF79]  bg-[#FFF2D9] hover:bg-[var(--theme-deafult-studentWithClick)] my-3    bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black"

        >
      {{ "VerifyStudent.form.Verify" | translate }}
      </button>
    </div>
    <div class="text-center  mt-4 mb-4">
      <p
        class="text-sm font-light text-gray-500 dark:text-gray-950"
        *ngIf="!showSendOtp"
      >
      {{"VerifyStudent.form.OTP-Not-Received" | translate }}
        <a
          style="color: red !important"
          class="font-medium hover:underline dark:text-primary-500"
        >
          {{ DataVefiryUser.secondsCount + " " }}{{ " " }}
          {{ "VerifyStudent.form.secondly" | translate }}</a
        >
      </p>

        <p class="  text-Regular text-[11px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-950" *ngIf="showSendOtp">
          {{"VerifyStudent.form.OTP-Not-Received" | translate }}  <span  (click)="SendOTP()" class="text-Regular text-[11px] md:text-[14px]text-primary-600 hover:underline dark:text-[#FF0000]" style="color: red !important">  {{ "VerifyStudent.form.Resend-Code" | translate }}</span>
      </p>
      <p
        class="text-sm font-light text-gray-500 dark:text-gray-950"
        (click)="navigate(false)"
      >
          {{"VerifyStudent.form.Mobile-number-not-correct" | translate }}
        <a
          (click)="navigate(false)"
          style="color: red"
          class="font-medium text-red-700 hover:underline dark:text-red-600"
        >
        {{ "VerifyStudent.form.Modify-Now" | translate }}</a
        >
      </p>
    </div>
  </div>

  <div
    class="m-auto pb-3 rtl:text-end px-20 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
    *ngIf="!DoneVefiryUser"
  >

    <img
      src="assets/images/MrS-Cool/VefiryUserSucc.png"
      class="w-1/6 m-auto py-3"
    />
    <h3
      class="text-3xl sora-bold text-[var(--theme-deafult-studentWithClick)] dark:text-white"
    >
      {{ "VerifyStudent.form.Account-Created" }}
    </h3>

    <p class="text-[14px] sora-Regular">
      {{ "VerifyStudent.form.Your-account-had-beed-created-successfully" }}
    </p>
    <p class="text-[14px] sora-Regular mb-5">
      {{ "VerifyStudent.form.Please-sign-in-to-use-your-account-and-enjoy" }}
    </p>

    <div class="my-3 mx-5">
      <button
        type="submit"
        class="w-full text-[#CAAF79] bg-[#FFF2D9] hover:bg-[var(--theme-deafult-studentWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-studentWithClick)] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        (click)="navigate(true)"
        (keyup.enter)="navigate(true)"
      >
        {{ "VerifyStudent.form.Go-To-Home" | translate }}
      </button>
    </div>
  </div>
</div>
