import {
  Component,
  Input,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  Output,
  EventEmitter,
} from '@angular/core';
// import { DownloadAppsComponent } from '../download-apps/download-apps.component';
// import { PublicService } from './../../../shared/services/public.service';
// import { AlertsService } from './../../../core/services/alerts.service';
// import { keys } from './../../../shared/configs/localstorage-key';
import {
  CommonModule,
  DatePipe,
  NgFor,
  NgIf,
  isPlatformBrowser,
} from '@angular/common';
// import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

import { extendMoment } from 'moment-range';
import * as Moment from 'moment';
import { InstaSlider } from 'src/app/shared/core/data/slider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import Swal from 'sweetalert2';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
const moment = extendMoment(Moment);

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    TranslateModule,
    RouterLink,
  ],
  selector: 'app-Booking-calendar',
  templateUrl: './Booking-calendar.component.html',
  styleUrls: ['./Booking-calendar.component.scss'],
  providers: [DatePipe],
})
export class BookingcalendarComponent {
  private unsubscribe: Subscription[] = [];
  currentLanguage: any = 'en';
  @Input() data: any;
  @Input() individualCost!: number;
  @Input() individualDuration!: number;
  @Input() teacherLessonId!: number;
  @Output() outputDat: EventEmitter<any> = new EventEmitter<boolean>();
  public InstaSliderConfig: any = InstaSlider;
  isloading: boolean = false;
  isloadingCalender: boolean = false;
  preDisable: boolean = true;

  date = moment();
  startDate: any;
  endDate: any;
  dates: any = [];
  selectedDay: any;
  appointmentDay: any = [];
  selectAppointmentItem: any = [];
  selectedTitleIndex: number = 0;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    // private dialogService: DialogService,
    // private alertsService: AlertsService,
    // private publicService: PublicService,
    private _transition: TranslateService,
    private router: Router,
    protected auth: AuthService,
    private cdr: ChangeDetectorRef,
    public datepipe: DatePipe,
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Only execute this code on the browser
      // this.currentLanguage = window?.localStorage?.getItem(keys?.language);
      // ... [rest of the browser-specific initialization code]
      this.dateChanged(this.date);
    }
  }

  dateChanged(date: any) {
    this.isloading = true;
    this.startDate = date;

    this.endDate = date.clone().add(4, 'days');
    this.dates = Array.from(
      moment.range(this.startDate, date.clone().add(4, 'days')).by('day')
    );
    this.selectedDay = this.dates[this.selectedTitleIndex];

    this.getAppointmentDoctor(
      this.startDate.format('YYYY-MM-DD'),
      this.endDate.format('YYYY-MM-DD')
    );
    this.outputDat.emit(this.selectedDay);
  }
  localStorage(BookTeacher: any) {
    BookTeacher.teacherLessonId = this.teacherLessonId;
    localStorage.setItem('BookTeacher', JSON.stringify(BookTeacher));
  }
  checkRoll(item: any): boolean {

    if (
      (this.auth.getToken() && this.auth.getRoleId() == '1') ||
      (this.auth.getToken() &&
        this.auth.getRoleId() == '3' &&
        !this.auth.getCurrentChildrenId()||this.auth.getCurrentChildrenId() =="undefined")

    ) {
      if (this.auth.getRoleId() == '3') {
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('StudentEmpty'),
        });
      } else
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('Serviceavailable'),
        });
      return false;
    } else {
      this.localStorage(item);
      this.router.navigate([`/main/Booking/` + this.teacherLessonId]);
      return true;
    }
  }
  getAppointmentDoctor(from: any, to: any): void {
    this.appointmentDay = [
      {
        available: 0,
        start_end: '10:00 AM - 12:00 PM',
        duration: '15',
        selected: true,
      },
      {
        available: 0,
        start_end: '10:00 AM - 12:00 PM',
        duration: '15',
      },
      {
        available: 7,
        start_end: '10:00 AM - 12:00 PM',
        duration: '15',
      },
      {
        available: 0,
        start_end: '10:00 AM - 12:00 PM',
        duration: '15',
      },
    ];
    if (this.selectAppointmentItem?.length > 0) {
      this.selectAppointmentItem?.forEach((sel: any) => {
        if (this.appointmentDay[sel?.date]) {
          this.appointmentDay[sel?.date]?.forEach((appo: any) => {
            if (sel?.id == appo?.id) {
              appo['selected'] = true;
            }
          });
        }
      });
    }
  }

  next(): void {
    this.selectedTitleIndex = 0;
    this.preDisable = false;
    let start = moment(this.endDate, 'YYYY-MM-DD').add(1, 'days');
    this.dateChanged(start);
    this.cdr?.detectChanges();
  }
  previous(): void {
    if (
      moment(Date.now()).isBefore(
        this.startDate
      )
    ) {
    this.selectedTitleIndex = 0;
    let start = this.startDate.clone().subtract(5, 'days');
    let end = this.startDate.clone().subtract(1, 'days');
    this.startDate = start;
    this.endDate = end;
    this.dateChanged(start);

    if (
      moment(this.startDate).isBefore(
        this.datepipe.transform(new Date(), 'YYYY-MM-dd')
      )
    ) {
      this.preDisable = true;
      this.cdr?.detectChanges();
    } else {
      this.preDisable = false;
      this.dates = Array.from(
        moment.range(this.startDate, this.endDate).by('day')
      );
      this.getAppointmentDoctor(
        this.startDate.format('YYYY-MM-DD'),
        this.endDate.format('YYYY-MM-DD')
      );
      this.cdr?.detectChanges();
    }
  }
  }

  bookNow(): void {
    // const ref = this?.dialogService?.open(DownloadAppsComponent, {
    //   width: '35%',
    //   styleClass: 'auth-dialog confirm-delete-trip',
    //   dismissableMask: true,
    //   data: {}
    // });
    // ref?.onClose?.subscribe((res: any) => {
    //   if (res?.isSave == true) {
    //     this.publicService?.toggleBodyScroll(true);
    //   }
    // });
  }

  protected changeBackground(
    event: Event,
    index: number,
    selectDay: any
  ): void {
    event.preventDefault();
    this.selectedDay = selectDay;
    this.selectedTitleIndex = index;
    this.outputDat.emit(selectDay);
  }

  ngOnDestroy(): void {
    this.unsubscribe?.forEach((sb) => sb?.unsubscribe());
  }

  private seo(){
    // this.seoService.setMetaImage( 'assets/images/Anonymous/Smart.jpg'
    // );
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.MENUITEMS.StudentsSessions.LIST.Calendar);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.MENUITEMS.StudentsSessions.LIST.Calendar)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.MENUITEMS.StudentsSessions.LIST.Calendar  ))
      // this.seoService.setMetaTags(translations);
    });

  }
}
