<nav
  class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-[#1a202c] dark:border-gray-700"
>
<div
class="px-3  py-2 lg:px-5 lg:pl-3 bg-[var(--theme-deafult-TeacherWithClick)] border-b dark:border-b-0 border-gray-200 text-white"
>
<div class="flex items-center justify-between">
  <div class="flex items-center justify-center d-none d-lg-block">
  <app-get-egypt-date-time [class]="'white'"></app-get-egypt-date-time>

    </div>
      <div class="flex items-center justify-between">
        <div class="flex sm:justify-center sm:mt-0 mr-4">
          <a
          href="https://www.facebook.com/mrscoolplatform" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)]"
          >
            <i class="fa-brands fa-facebook"></i>
          </a>

          <a
          href="https://www.instagram.com/mrscoolplatform/" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>

          <a
          href="https://www.youtube.com/@Mrscool-ly6sd" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a
          href="https://www.tiktok.com/@mrscoolplatform" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
          <i class="fab fa-tiktok "></i>
          </a>
        </div>
        <div class="flex  align-items-center">
          <button  class="flex text-sm bg-[var(--theme-deafult-TeacherWithClick)] rounded-full   text-white focus:ring-gray-300 dark:focus:ring-gray-600" >
            <span class="sr-only">Open language menu</span>
            <span  *ngIf="layout.config.langu=='en'" (click)="changeLanguage('ar',true)"> Arabic</span>
            <span  *ngIf="layout.config.langu=='ar'" (click)="changeLanguage('en',true)"> English</span>
       <i
          class="px-2 fa fa-globe" aria-hidden="true"></i>
              </button>

                       <!-- <div class="compare">
            <a *ngIf="layout.config.langu=='ar'" id="en"><span (click)="changeLanguage('en',true)">{{'EN'}} </span> </a>
            <a *ngIf="layout.config.langu=='en'" id="ar"> <span (click)="changeLanguage('ar',true)"> {{'AR'}} </span></a><i
            class="px-1 fa fa-globe" aria-hidden="true"></i>
          </div> -->
        </div>

      </div>
    </div>
  </div>
  <div class="px-2 py-2 lg:px-5 lg:pl-3 bg-white border-b border-gray-200">
    <div class="flex items-center justify-between main-home">
      <div class="flex  items-center justify-between max-md:w-full md:justify-start   ">

          <ng-content></ng-content>
        <a  class="flex ml-20 md:mr-24 max-md:hidden" routerLink="/main/home">
          <img
            src="assets/images/Logo/logo.svg"
            class=" mr-3 max-sm:w-8"
            alt="MrS-Cool Logo"
          />
        </a>
        <div class="text-right  items-center d-xl-none d-flex ">
          <button
            type="button"
            [routerLink]="'/authentication/' + Controller + '/login'"
            [ngClass]="{
          'button': path == 'login' || path == 'ForgetPassword',
        }"
            routerLinkActive="button"
            class="text-nowrap button-auth text-[#CD2028] text-[11px] md:text-[13px]   dark:text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] focus:text-white md:my-3  hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-0 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
          >
            {{'auth.Signin' |translate}}
          </button>
          <button
            type="button"
            [routerLink]="'/authentication/' + Controller + '/signup'"
            routerLinkActive="button"
            [ngClass]="{
                'button': path=='signup',
              }"
            class="text-nowrap  button-auth  text-[#CD2028] text-[11px] md:text-[13px]   dark:text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] focus:text-white md:my-3  hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-0 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
            >

            {{'auth.SignUp' |translate}}

          </button>


        </div>
      </div>
      <div class="flex items-center max-md:justify-between   d-xl-block d-none ">
        <div class="flex items-center ml-3">
          <div class="lg:px-5 lg:pl-3 bg-white d-flex justify-between">

            <div class="text-right flex items-center">
              <button
                type="button"
                [routerLink]="'/authentication/' + Controller + '/login'"
                [ngClass]="{
              'button': path == 'login' || path == 'ForgetPassword',
            }"
                routerLinkActive="button"
                class="text-nowrap button-auth text-[#CD2028] text-[11px] md:text-[13px]   dark:text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] focus:text-white md:my-3  hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-0 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
              >
              {{'auth.Signin' |translate}}
              </button>
              <button
                type="button"
                [routerLink]="'/authentication/' + Controller + '/signup'"
                routerLinkActive="button"
                [ngClass]="{
                    'button': path=='signup',
                  }"
                class="text-nowrap  button-auth  text-[#CD2028] text-[11px] md:text-[13px]   dark:text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] focus:text-white md:my-3  hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-0 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
                >
                {{'auth.SignUp' |translate}}
              </button>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
