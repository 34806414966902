import { CanActivateFn } from '@angular/router';

export const scrollGuard: CanActivateFn = (route, state) => {
  window.scroll(0, 0);
  if (typeof window !== 'undefined') {
    window.scroll(0, 0);
    return true;
  } else {
    return true;
  }
};
