import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  ICity,
  ICountry,
  IGenders,
  IGovernorate,
} from 'src/app/shared/Model/Country/country';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';

import { NgbRatingConfig, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { environment as env, environment } from 'src/environments/environment';
import { IGetStudentProfile } from 'src/app/shared/Model/Student/student-edit-profile';
import { StudentEditProfileService } from 'src/app/shared/Apis/Student/Profile/student-edit-profile.service';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { VerifyOTPStudentConnectComponent } from '../connect-parent-student/VerifyOTPStudentConnect/verify-otpstudent-connect/verify-otpstudent-connect.component';
import { ConnectParentStudentSteps } from 'src/app/shared/Enums/connect-parent-student-steps';
import { IOtp } from 'src/app/shared/Model/teacher/teacher';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { ChildrenIdService } from '../choose-student/children-id.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-add-parent-student',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TooltipModule,
    VerifyOTPStudentConnectComponent,
    OnlyNumbersDirective,
    OnlyNumbersDirective,
    TranslateModule,
    NgbRatingModule,
    DisableWeritingArabicDirective,
  ],
  templateUrl: './add-parent-student.component.html',
  styleUrls: ['./add-parent-student.component.scss'],
})
export class AddParentStudentComponent extends BaseComponent implements OnInit {
  private logoForm!: FormData;
  protected selectedFile!: File;
  protected ProfileData!: IGetStudentProfile;
  protected countries!: ICountry[];
  protected Governorates!: IGovernorate[];
  protected cityList: ICity[] = [];
  protected insertForm!: FormGroup;
  protected genders: IGenders[] = [
    { id: 1, title: 'Male' },
    { id: 2, title: 'Female' },
  ];
  protected submit: boolean = true;
  protected subscription = new Subscription();
  protected imageSrc!: string;
  protected max = 5;
  protected rate = 3;
  protected AcademicYearEducationLevel: any;
  protected EducationTypes: any;
  protected EducationLevel: any;
  protected AcademicYear: any;
  protected EducationtypeId: any;
  protected StudentId: any;
  protected ShowProfile!: boolean;
  protected DataVefiryOtp!: IOtp;
  protected ConnectParentStudentSteps = new ConnectParentStudentSteps();
  protected otp!: number;
  constructor(
    private StudentEditProfileService: StudentEditProfileService,
     private StudentService: StudentService,
    private _fb: FormBuilder,
    private _transition: TranslateService,
    private _Lookup: LookupService,
     config: NgbRatingConfig,
    public _appSettings: AuthService,
     private childrenIdService: ChildrenIdService,
    private _SSRService: SSRService,    private seoService: SeoService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();

    config.max = 5;
    config.readonly = true;
  }
  ngOnInit(): void {
    this.seo();
    this.initiate();

      if (
        this._SSRService.Location(3) == 'ProfileStudent' &&isPlatformBrowser(this.platformId)&&
        localStorage.getItem('childrenId') != null
      ) {
        this.StudentId = localStorage.getItem('childrenId');
        this.ShowProfile = true;
        this.GetCountry();
        this.GetEducationtype();
        this.GetProfile(this.StudentId);
        const lang = this.layoutService.config.langu;
        this.seoService.loadTranslations(lang).subscribe(translations => {
          this.seoService.setTitle(translations.AddParentStudent.header.StudentProfile);
          this.seoService.setHostUrlIndex();
          this.seoService.setMetaDescription(translations.Home.header.meta_description)
          this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))
        });
      }
 else {
      this.initiate();
      this.GetCountry();
      // this.GetProfile();
      this.GetEducationtype();
    }
  }

  private initiate(Profile?: IGetStudentProfile) {
    this.insertForm = this._fb.group({
      Name: [Profile?.name || '', Validators.required],
      Birthdate: [Profile?.birthdate?.slice(0, 10) || '', Validators.required],
      Email: [Profile?.email || '', [Validators.required, Validators.email]],
      CityId: [Profile?.cityId || '', Validators.required],
      GenderId: [Profile?.genderId || '', Validators.required],
      SchoolName: [Profile?.schoolName || '', Validators.required],
      AcademicYearEducationLevelId: [
        Profile?.academicYearEducationLevelId || '',
        Validators.required,
      ],
      EducationLevelId: [Profile?.educationLevelId || '', Validators.required],
      EducationTypeId: [Profile?.educationTypeId || '', Validators.required],
      GovernorateId: [Profile?.governorateId || '', Validators.required],
      CountryId: [Profile?.countryId || '', Validators.required],
      Code: [Profile?.code || ''],
      Mobile: [Profile?.mobile || '', Validators.required],
      MobileX: [{ value: Profile?.mobile || '', disabled: true }],
      passwordHash: [Profile?.passwordHash || '', Validators.minLength(6)],

      // remmove
    });
    if (Profile) this.getAllSelect();
  }
  protected get fc() {
    return this.insertForm.controls;
  }
  GetAcademicYearEducationLevel() {
    this._Lookup
      .GetAcademicYearEducationLevel()
      .subscribe(
        (AcademicYearEducationLevel) =>
          (this.AcademicYearEducationLevel = AcademicYearEducationLevel)
      );
  }
  GetProfile(id: any) {
    this.StudentService.GetStudentProfile(id).subscribe((_profileData: any) => {
      this.ProfileData = _profileData.data;
      this.imageSrc =
        environment.serverFirstHalfOfImageUrl + this.ProfileData.image;
      this.initiate(this.ProfileData);
      // this.GetEducationLevel(this.ProfileData.educationTypeId)
      // this.GetAcademicYear(this.ProfileData.educationLevelId);
      this.GetGovernorate(this.ProfileData.countryId);
      this.SelectedGovernorate(this.ProfileData.governorateId);
    });
  }
  GetCountry() {
    this._Lookup
      .GetCountry()
      .subscribe((country) => (this.countries = country));
  }
  protected ChangCountry() {
    this.fc['GovernorateId'].setValue('');
    this.fc['CityId'].setValue('');
    this.GetGovernorate();
  }
  GetGovernorate(id?: any) {
    if (
      this.fc['CountryId'].value != null &&
      this.fc['CountryId'].value != ''
    ) {
      this._Lookup
        .GetGovernorate(this.fc['CountryId'].value)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    } else if (id) {
      this._Lookup
        .GetGovernorate(id)
        .subscribe((_Governorates) => (this.Governorates = _Governorates));
    } else {
      this.cityList = [];
      this.Governorates = [];
    }
  }

  protected ChangGovernorate() {
    this.fc['CityId'].setValue(null);
    this.SelectedGovernorate();
  }
  // get from city
  private SelectedGovernorate(id?: any) {
    // this.fc.fromCityId.setValue(null)
    if (
      this.fc['GovernorateId'].value != null &&
      this.fc['GovernorateId'].value != ''
    ) {
      this.subscription.add(
        this._Lookup
          .GetCity(this.fc['GovernorateId'].value)
          .subscribe((cities) => (this.cityList = cities))
      );
    } else if (id) {
      this.subscription.add(
        this._Lookup.GetCity(id).subscribe((cities) => (this.cityList = cities))
      );
    } else this.cityList = [];
  }

  protected onSubmit() {
    if (this.ShowProfile) {
      const passwordHashControl = this.insertForm.get('passwordHash');
      passwordHashControl?.clearValidators();
      passwordHashControl?.removeValidators(Validators.required);
      passwordHashControl?.updateValueAndValidity();
    }
    if (this.insertForm.valid) {
      // Perform the submit logic here
      if (this.ShowProfile) {
        this.UpdateData();
      } else {
        this.InsertStudent();
      }
    } else {
      this.insertForm.markAllAsTouched();
    }
  }
  private UpdateData() {
    this.loopform();
    this.submit = false;
    this.StudentEditProfileService.UpdateProfileStudent(
      this.logoForm
    ).subscribe(
      (res: any) => {
        if (res.success == true) {
          if (isPlatformBrowser(this.platformId)) {

          localStorage.setItem(
            'academicYearId',
            JSON.stringify(res.data['academicYearEducationLevelId'])
          );
        }
          Swal.fire({
            icon: 'success',
            title: this._transition.instant('Profile updated successfully'),
            showConfirmButton: false,
            timer: 1500,
          });
          // this.childrenIdService.setAcademicleveId(null);
          this.childrenIdService.setAcademicleveId(
            res.data['academicYearEducationLevelId']
          );

          if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('isUpdate', JSON.stringify(true));}
          this._SSRService.eventPrams(res.data['id']);
          // this.router.navigate([`main/parent/StudentSubjects/${res.data['id']}`]);
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  private InsertStudent() {
    this.loopform();
    this.submit = false;
    this.StudentService.CreateByParent(this.logoForm).subscribe(
      (res: any) => {
        if (res.success == true) {
;
          if (res.data['otp']) {
            this.ConnectParentStudentSteps = {
              Vefiry: false,
              vefiryOtp: true,
            };
            this.DataVefiryOtp = res.data;
          }
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  private loopform() {
    this.logoForm = new FormData();
    Object.keys(this.insertForm.value).forEach((key) => {
      if (this.insertForm.value[key] == null) {
        //this.insertForm.removeControl(key);
      } else {
        if (
          typeof this.insertForm.value[key] !== 'object' &&
          key !== 'image' &&
          key !== 'truckImage' &&
          key !== 'GovernorateId' &&
          key !== 'CountryId' &&
          key !== 'EducationLevelId' &&
          key !== 'EducationTypeId'
        )
          this.logoForm.append(key, this.insertForm.value[key]);
        else if (typeof this.insertForm.value[key] == 'object')
          Object.keys(this.insertForm.value[key]).forEach((subkey) => {
            this.logoForm.append(key, this.insertForm.value[key][subkey]);
          });
      }
    });

    if (this.selectedFile && this.selectedFile.name != 'dummy.txt')
      this.logoForm.append('StudentImage', this.selectedFile);
  }

  protected onSelectFile(event: any) {
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      const reader = new FileReader();
      const fileSizeInMB = this.selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
      reader.readAsDataURL(this.selectedFile);

      if (fileSizeInMB > 2) {
        // File size exceeds 5MB

        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
        });
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file
      } else
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
    }
  }


  // ***************************
  GetEducationtype() {
    this._Lookup
      .GetEducationType()
      .subscribe((EducationType) => (this.EducationTypes = EducationType));
  }
  protected ChangEducationType() {
    this.fc['EducationLevelId'].setValue('');
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.EducationLevel = [];
    this.AcademicYear = [];
    this.GetEducationLevel();
  }

  GetEducationLevel() {
    if (this.fc['EducationTypeId'].value)
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    else this.EducationLevel = [];
  }

  protected ChangEducationLevel() {
    this.fc['AcademicYearEducationLevelId'].setValue('');
    this.SelectedEducationLevel();
  }
  // get from AcademicYear
  private SelectedEducationLevel() {
    if (this.fc['EducationLevelId'].value) {
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
          .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
      );
    } else this.AcademicYear = [];
  }
  private getAllSelect(): void {
    // Fetch and populate education level options
    this.GetEducationLevel();

    // Set the selected education level based on certain criteria
    this.SelectedEducationLevel();

    // // Set the selected academic year based on certain criteria
    // this.SelectedAcademicYear();
  }
  public handleEvent(event: [boolean, number]) {
    if (event[0] == true) {
      this.ConnectParentStudentSteps = {
        Vefiry: false,
        vefiryOtp: false,
      };
      this.fc['otp'].setValue(event[1]);
    } else
      this.ConnectParentStudentSteps = {
        Vefiry: true,
        vefiryOtp: false,
      };
    //this.serviceId = event
  }
  preview(files: any) {
    const file = files.target.files[0];
    if (file.length === 0) return;
    var mimeType = file.type;
    const before_ = mimeType.substring(-1, mimeType.indexOf('/'));
    // this.selectedFile = file;
    if (before_ == 'image') {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > 2) {
        // File size exceeds 5MB
        // this.FileSize=false
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: 'Selected file size is greater than 2MB. Please choose a smaller file.',
        });
        this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

        this.imageSrc = this.ProfileData
          ? environment.serverFirstHalfOfImageUrl + this.ProfileData.image
          : '';
      } else {
        this.selectedFile = file;
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    } else {
      this.imageSrc = this.ProfileData
        ? environment.serverFirstHalfOfImageUrl + this.ProfileData.image
        : '';
      this.selectedFile = new File([], 'dummy.txt', { type: 'text/plain' }); // Reset the selected file

      Swal.fire({ icon: 'error', title: `نوع صورة غير مقبول` });
      return;
    }
  }
  private seo(){
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.AddParentStudent.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))
    });

  }
}
