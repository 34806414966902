import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [

  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Anonymous'],
    submenu: [
      {
        path: '/main/home',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Homepage',
        iconType: '',
        icon: 'home.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Anonymous'],
        submenu: [],
      },]},
  //  for Teacher

  {
    path: '',
    title: 'MENUITEMS.Academic.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/main/ManageMySubjects',
        title: 'MENUITEMS.Academic.LIST.Subjects',
        iconType: '',
        icon: 'MySubjects.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      {
        path: '/main/SubjectGroup',
        title: 'MENUITEMS.Academic.LIST.FullSubject',
        iconType: '',
        icon: 'SubjectGroup8.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },  {
        path: '/main/GroupForLesson',
        title: 'MENUITEMS.Academic.LIST.ManageLesson',
        iconType: '',
        icon: 'SubjectGroup8.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
        {
        path: '/main/ManageMySchedule',
        title: 'MENUITEMS.Academic.LIST.Schedule',
        iconType: '',
        icon: 'calendar.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },


    ],
  },
  {
    path: '',
    title: 'MENUITEMS.Mr-S-Cool.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/main/Teacher/Homepage',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Homepage',
        iconType: '',
        icon: 'home.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      // {
      //   path: '/main/Dashboard',
      //   title: 'MENUITEMS.Mr-S-Cool.LIST.Dashboard',
      //   iconType: '',
      //   icon: 'Dashboard.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Teacher'],
      //   submenu: [],
      // },
      {
        path: '/main/Finance',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Finance',
        iconType: '',
        icon: 'Finance.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Information.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/main/teacher/EditProfile',
        title: 'MENUITEMS.Information.LIST.EditProfile',
        iconType: '',
        icon: 'EditProfile.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      {
        path: '/main/ManageMyDocuments',
        title: 'MENUITEMS.Information.LIST.Documents',
        iconType: '',
        icon: 'Rates.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },


  {
    path: '',
    title: 'MENUITEMS.StudentsSessions.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/main/teacher/Chat',
        title: 'MENUITEMS.StudentsSessions.LIST.Chat',
        iconType: '',
        icon: 'chat.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      // {
      //   path: '/main/Teacher/ReCalendar',
      //   title: 'MENUITEMS.StudentsSessions.LIST.Calendar',
      //   iconType: '',
      //   icon: 'calendar.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Teacher'],
      //   submenu: [],
      // },
      // {
      //   path: '/main/Teacher/ScheduleList',
      //   title: 'MENUITEMS.StudentsSessions.LIST.ScheduleList',
      //   iconType: '',
      //   icon: 'calendar.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Teacher'],
      //   submenu: [],
      // },
      {
        path: '/main/Completedlessons',
        title: 'MENUITEMS.StudentsSessions.LIST.Completed',
        iconType: '',
        icon: 'Completed.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },

      {
        path: '/main/Teacher/RatesReviews',
        title: 'MENUITEMS.StudentsSessions.LIST.RatesReviews',
        iconType: '',
        icon: 'Rates.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Settings.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/main/teacher/ChangePassword',
        title: 'MENUITEMS.Settings.LIST.ChangePassword',
        iconType: '',
        icon: 'ChangePassword.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },

      {
        path: 'main/SignOut',
        title: 'MENUITEMS.Settings.LIST.SignOut',
        iconType: '',
        icon: 'power.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: 'SignOut',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },

  // End  Teacher//
  // -----------------------

  // {
  //   path: '/main/Finance',
  //   title: 'MENUITEMS.Finance.LIST.Finance',
  //   iconType: '',
  //   icon: '',
  //   class: 'ml-menu',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Teacher'],
  //   submenu: [],
  // },
  // {
  //   path: '/main/StudentEditProfile',
  //   title: 'MENUITEMS.Profile.LIST.EditProfile',
  //   iconType: '',
  //   icon: '',
  //   class: 'ml-menu',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Student'],
  //   submenu: [],
  // },

  //  For Student
  {
    path: '',
    title: 'MENUITEMS.Mr-S-Cool.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [
      {
        path: '/main/student/StudentSubjects',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Homepage',
        iconType: '',
        icon: 'home.svg',

        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
      // {
      //   path: '/main/Dashboard',
      //   title: 'MENUITEMS.Mr-S-Cool.LIST.Dashboard',
      //   iconType: '',
      //   icon: 'Dashboard.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Student'],
      //   submenu: [],
      // },
      {
        path: '/main/student/Finance',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Finance',
        iconType: '',
        icon: 'Finance.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Information.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [
      {
        path: '/main/student/EditProfile',
        title: 'MENUITEMS.Information.LIST.EditProfile',
        iconType: '',
        icon: 'EditProfile.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.StudentsSessions.TEXT2',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [
      {
        path: '/main/student/Chat',
        title: 'MENUITEMS.StudentsSessions.LIST.Chat',
        iconType: '',
        icon: 'chat.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
      // {
      //   path: '/main/Student/ReCalendar',
      //   title: 'MENUITEMS.StudentsSessions.LIST.Calendar',
      //   iconType: '',
      //   icon: 'calendar.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Student'],
      //   submenu: [],
      // },
      {
        path: '/main/Student/ScheduleList',
        title: 'MENUITEMS.StudentsSessions.LIST.ScheduleList',
        iconType: '',
        icon: 'calendar.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
      {
        path: '/main/StudentCompletedlessons',
        title: 'MENUITEMS.StudentsSessions.LIST.Completed',
        iconType: '',
        icon: 'Completed.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },

    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Settings.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [
      {
        path: '/main/student/ChangePassword',
        title: 'MENUITEMS.Settings.LIST.ChangePassword',
        iconType: '',
        icon: 'ChangePassword.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },

      {
        path: 'main/SignOut',
        title: 'MENUITEMS.Settings.LIST.SignOut',
        iconType: '',
        icon: 'power.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: 'SignOut',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
    ],
  },

  // End  Student//

  // -----------------------
  // End  Parent//
  // -----------------------
  {
    path: '',
    title: 'MENUITEMS.Parent.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Parent', 'children'],
    submenu: [
      {
        path: '/main/parent/ParentStudents',
        title: 'MENUITEMS.Parent.LIST.Homepage',
        iconType: '',
        icon: 'home.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Parent', 'children'],
        submenu: [],
      },
      // {
      //   path: '/main/Dashboard',
      //   title: 'MENUITEMS.Parent.LIST.Dashboard',
      //   iconType: '',
      //   icon: 'Dashboard.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //  role: ['Parent', 'children'],
      //   submenu: [],
      // },
      // {
      //   path: '/main/parent/Finance',
      //   title: 'MENUITEMS.Parent.LIST.Finance',
      //   iconType: '',
      //   icon: 'Finance.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['Parent', 'children'],
      //   submenu: [],
      // },
      {
        path: '/main/parent/EditProfile',
        title: 'MENUITEMS.Information.LIST.EditProfile',
        iconType: '',
        icon: 'EditProfile.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Parent', 'children'],

        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Settings.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Parent', 'children'],

    submenu: [
      {
        path: '/main/parent/ChangePassword',
        title: 'MENUITEMS.Settings.LIST.ChangePassword',
        iconType: '',
        icon: 'ChangePassword.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Parent', 'children'],

        submenu: [],
      },

      {
        path: 'main/SignOut',
        title: 'MENUITEMS.Settings.LIST.SignOut',
        iconType: '',
        icon: 'power.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: 'SignOut',
        badgeClass: '',
        role: ['Student'],
        submenu: [],
      },
    ],
  },

  // for kod

  {
    path: '',
    title: '',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: 'children',
    badgeClass: '',
    role: ['children'],
    submenu: [
      {
        path: '/main/parent/StudentSubjects/',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Homepage',
        iconType: '',
        icon: 'home.svg',

        class: 'ml-menu',
        groupTitle: false,
        badge: 'ChildHomepage',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
      // {
      //   path: '/main/Dashboard',
      //   title: 'MENUITEMS.Mr-S-Cool.LIST.Dashboard',
      //   iconType: '',
      //   icon: 'Dashboard.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['children'],
      //   submenu: [],
      // },
      {
        path: '/main/student/Finance',
        title: 'MENUITEMS.Mr-S-Cool.LIST.Finance',
        iconType: '',
        icon: 'Finance.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.Information.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['children'],
    submenu: [
      {
        path: '/main/parent/ProfileStudent',
        title: 'MENUITEMS.Information.LIST.EditProfile',
        iconType: '',
        icon: 'EditProfile.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.StudentsSessions.TEXT2',
    iconType: 'material-icons-two-tone',
    icon: 'home.svg',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['children'],
    submenu: [
      {
        path: '/main/student/Chat',
        title: 'MENUITEMS.StudentsSessions.LIST.Chat',
        iconType: '',
        icon: 'chat.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
      // {
      //   path: '/main/Student/ReCalendar',
      //   title: 'MENUITEMS.StudentsSessions.LIST.Calendar',
      //   iconType: '',
      //   icon: 'calendar.svg',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['children'],
      //   submenu: [],
      // },
      {
        path: '/main/Student/ScheduleList',
        title: 'MENUITEMS.StudentsSessions.LIST.ScheduleList',
        iconType: '',
        icon: 'calendar.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
      {
        path: '/main/StudentCompletedlessons',
        title: 'MENUITEMS.StudentsSessions.LIST.Completed',
        iconType: '',
        icon: 'Completed.svg',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['children'],
        submenu: [],
      },
    ],
  },

  // {
  //   path: '',
  //   title: 'MENUITEMS.Settings.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'home.svg',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['children'],
  //   submenu: [
  //     // {
  //     //   path: '/main/student/ChangePassword',
  //     //   title: 'MENUITEMS.Settings.LIST.ChangePassword',
  //     //   iconType: '',
  //     //   icon: 'ChangePassword.svg',
  //     //   class: 'ml-menu',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: ['children'],
  //     //   submenu: [],
  //     // },

  //     // {
  //     //   path: 'main/SignOut',
  //     //   title: 'MENUITEMS.Settings.LIST.SignOut',
  //     //   iconType: '',
  //     //   icon: 'power.svg',
  //     //   class: 'ml-menu',
  //     //   groupTitle: false,
  //     //   badge: 'SignOut',
  //     //   badgeClass: '',
  //     //   role: ['children'],
  //     //   submenu: [],
  //     // },
  //   ],
  // },

  // -----------------------
  // End  parent//
  // -----------------------
];

// {
//   path: '/main/ManageMySubjects',
//   title: 'MENUITEMS.Subjects.TEXT',
//   iconType: 'material-icons-two-tone',
//   icon: 'medication',
//   class: 'menu-toggle',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   role: ['Teacher'],
// submenu: [
//     {
//       path: '/main/ManageMySubjects',
//       title: 'MENUITEMS.Subjects.LIST.MySubjects',
//       iconType: '',
//         icon: 'Tickets.svg',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: ['Teacher'],
//       submenu: [],
//     },

//   ],
// },
