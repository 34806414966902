<!-- <div class="w-full sm:w-1/1 md:w-1/1  xl:w-1/1 pt-5 px-3 md:ps-12  "> -->
  <div
      class="dark:bg-[#1a202c] bg-[#ffffff] mt20 rounded-lg shadow dark:border   dark:border-[var(--theme-deafult-TeacherWithClick)] px-3 md:px-14"
    >
    <div
    class="sm:p-5 pt-5 md:p-10"
    >
    <div class="text-center">
      <p class="text-black text-center text-[16px] sora-Regular">
        <a
          href="https://www.youtube.com/watch?v=op5L2L0ROvg"
          target="_blank"
          class="text-black"
        >
                {{ "TeacherSignUp.header.Explanation" | translate }} *
        </a>
      </p>
    </div>
      <form
        [formGroup]="insertForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="grid gap-0 grid-cols-3 ">
          <h2
            class="text-[16px] col-span-2 lg:text-[30px] text-left sora-bold dark:text-white"
          >
          {{ "DocumentsInformation.header.Title" |translate}}
        </h2>
        <p class="  text-right text-[14px] sora-Regular dark:text-white">
        (3/3)
      </p>

        <p class=" text-left text-[13px] sora-Regular">
          {{ "DocumentsInformation.header.Enter-subtitle-here" |translate}}
        </p>


      </div>

      <div class="grid gap-6 mb-6 md:grid-cols-2 ">

        <div>

          <div class="mb-3">
            <label
              for="documentType"
              class="block   text-[var(--theme-deafult-TeacherWithClick)] dark:text-white pt-3"
              >{{ "DocumentsInformation.form.Document-Type" | translate }}
              <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label
            >

            <ng-select
            id="documentType"
            #DocumentTypeId
            [selectOnTab]="true"
            formControlName="DocumentTypeId"
            class="sora-Ligh form-control p-0"
            dropdownPosition="auto"
            labelForId="validation02"
            [tooltip]="fc['DocumentTypeId'].errors?.['required'] ?  ('required' | translate) : null"
            containerClass=""
            [searchable]="true"
            [class.is-valid]="
              fc['DocumentTypeId'].valid &&
              (fc['DocumentTypeId'].touched || fc['DocumentTypeId'].value)
            "
            [class.is-invalid]="
              fc['DocumentTypeId'].invalid && fc['DocumentTypeId'].touched
            "
          >
            <ng-option value=""
              >            <span class="text-[#868B9F]" >{{ "DocumentsInformation.form.Choose-your-document-type" | translate }}</span>     </ng-option>

            <ng-option class="sora-Ligh  "*ngFor="let item of DocumentType" [value]="item.id"
              >{{ item.name }}
            </ng-option>
          </ng-select>
          </div>
          <div  class="mb-3">
            <label
              for="first_name"
              class="block  text-[var(--theme-deafult-TeacherWithClick)] pt-3 dark:text-white"
              >{{ "DocumentsInformation.form.Document-Title" | translate }}
              <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span>
            </label>
            <input
              type="text"
              formControlName="Title"
              [tooltip]="
                fc['Title'].invalid &&
                (fc['Title'].dirty || fc['Title'].touched)
                  ? ('DocumentsInformation.form.required' | translate)
                  : null
              "
              [ngClass]="{
            'class-1': fc['Title'].invalid && (fc['Title'].dirty || fc['Title'].touched),
            'class-2': fc['Title'].valid && (fc['Title'].dirty || fc['Title'].touched),
          }"
              id="first_name"
              class="sora-Ligh bg-white border border-[var(--theme-deafult-TeacherWithClick)] text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-[#DDDDDD] dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your name"
              required
            />
          </div>

        </div>

        <div class=" mt-4">
          <div class="flex items-center justify-center w-full relative">
            <div class="bottom-1 border-slate-950  absolute top-0 right-0"       *ngIf="IsFile && FileSize" >

              <img
              src="assets/images/MrS-Cool/delete-Icon.png"
              class="m-auto p-4 w-16 pt-5"
              alt="delete-Icon"
              (click)="ClearFile()"
            />
              </div>
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full    rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex items-center justify-center w-full ">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full  border-[#DDDDDD] border-[2px]   rounded-[20px] cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div
                    *ngIf="!IsFile && FileSize"
                    class="flex flex-col items-center justify-center py-7 px-3"
                  >

                    <img
                    src="assets/images/MrS-Cool/currentDocument.png"
                    alt=""
                  />
                    <p
                      class="mb-3 mt-2 sora-Regular text-[10px] md:text-[12px] text-[var(--theme-deafult-TeacherWithClick)]  text-center"
                    >
                      {{'DocumentsInformation.form.Files-supported' | translate}}: PDF, JPG, PNG, TIFF, GIF,<br />
                      Pdf, docx, doc, WORD<br />
                      {{'DocumentsInformation.form.Maximum-size-is' | translate}} : 2MB
                    </p>
                    <p class=" sora-Regular  sora-Regular text-[11px] md:text-[14px] text-[var(--theme-deafult-TeacherWithClick)] ">
                      {{'DocumentsInformation.form.Drag&Drop-Files' | translate}}
                    <p
                      class="py-3 px-5 mr-2 my-3  text-[7px] md:text-[11px] sora-Regular text-white focus:outline-none bg-[var(--theme-deafult-TeacherWithClick)] rounded-full border  dark:hover:bg-gray-700"
                    >
                      {{'DocumentsInformation.form.Choose-Files' | translate}}
                    </p>
                  </div>

                  <div
                    *ngIf="IsFile && FileSize"
                    class="flex flex-col items-center justify-center pt-5 pb-6 "
                  >


                    <p class="bottom-1 border-slate-950 inFile">
                      <img
                        src="assets/images/MrS-Cool/documents-1.png"
                        alt=""
                      />
                    </p>
                    <p
                      class="sora-Medium text-gray-500 dark:text-[#DDDDDD] text-center"
                    >
                       {{'DocumentsInformation.form.Your-file-uploaded' | translate}} <br />
                      {{'DocumentsInformation.form.successfully' | translate}}
                    </p>
                  </div>
                  <div
                    *ngIf="!FileSize"
                    class="flex flex-col items-center justify-center pt-5 pb-6"
                  >
                    <p class="bottom-1 border-slate-950 inFile-fill">
                      <img
                        src="assets/images/MrS-Cool/documents-folder.png"
                        alt="documents"
                      />
                    </p>
                    <p
                      class="sora-Medium text-[10px] md:text-[14px] text-red-500 dark:text-red-400 text-center"
                    >
                    {{'DocumentsInformation.form.Your-file-cant-be-uploaded' | translate}}, <br />
                      {{'DocumentsInformation.form.Maximum-size-is' | translate}} : 2MB
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                    (change)="onSelectFile($event)"
                    formControlName="DocumentP"
                  />
                </label>
              </div>

              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                (change)="onSelectFile($event)"
                formControlName="DocumentP"
              />
            </label>
          </div>
          <div class="flex justify-between mt-3">
            <button
            type="submit"  [disabled]="!submit"
            [ngClass]="{'valid-form': insertForm.valid, 'invalid-form': insertForm.invalid}"
            class="text-[10px] md:text-[14px] sora-Regular  text-[#868B9F] bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] sora-Regular rounded-lg text-sm px-5 h-12 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          >
                 {{'DocumentsInformation.form.Save' | translate}}
            </button>
            <button
              type="button"
              class="text-[var(--theme-deafult-TeacherWithClick)] bg-white border border-[var(--theme-deafult-TeacherWithClick)] focus:outline-none hover:bg-gray-100 focus:ring-4 focus:bg-[var(--theme-deafult-TeacherWithClick)]sora-Regular rounded-lg text-sm px-5 h-12 mr-2 mb-2 dark:bg-[#1a202c] dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              (click)="Clear()"
            >
               {{'DocumentsInformation.form.Clear' | translate}}
            </button>
          </div>
        </div>


      </div>


    </form>
    <!-- <p class="text-left"  >
      <span class=" text-[#FF0000]  text-[12px] md:text-[16px] sora-SemiBold ">*</span> <span class="text-[var(--theme-deafult-TeacherWithClick)] ">{{ "SubjectInfo.form.Note-Must-be-enter-one-item-at-least" | translate }}</span>
    </p> -->
    <p class="text-left"  *ngIf="!MyDocuments||(MyDocuments.length ==0)  ||(MyDocuments &&this.MyDocuments[0]?.profileStatus !=3)" >
      <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span>
      <!-- {{'DocumentsInformation.form.your-academic-certificate'|translate}} -->
       {{messageRequired}}
    </p>
    <div
      class="relative overflow-x-auto shadow-md sm:rounded-lg"
      *ngIf="MyDocuments && MyDocuments.length >0"
    >
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead
          class="sora-Medium text-white    bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">
              {{ "DocumentsInformation.form.Document-Type" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "DocumentsInformation.form.Document-Title" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "DocumentsInformation.form.Order" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">{{ "DocumentsInformation.form.File" | translate }}</th>
            <th scope="col" class="px-6 py-3">{{ "DocumentsInformation.form.Action" | translate }} </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b border-[#DDDDDD] dark:bg-[#1a202c] dark:border-gray-700"
            *ngFor="let item of MyDocuments; let y = index"
          >
            <th
              scope="row"
              class="px-6 py-4 sora-Regular text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
            >
              {{ item.documentTypeName }}
            </th>
            <td class="px-6 py-4">
              {{ item.title }}
            </td>
            <td class="px-6 py-4">
              {{ item.order }}
            </td>
            <td class="px-6 py-4">
              <a
                [href]="Url + item.documentPath"
                target="_blank"
                rel="noopener noreferrer"
                class="text-[var(--theme-deafult-TeacherWithClick)]"
              >
                <i class="fa-solid fa-paperclip"></i> {{'DocumentsInformation.form.Click-for-Prev'|translate}}</a
              >
            </td>
            <td class="px-6 py-4">
              <a
                class="sora-Regular text-red-600 red:text-blue-500 hover:underline"
                (click)="TableDeletDocID = item.id; openModal(template)"
                ><img src="assets/images/Icons/delete.png" class=" " alt=""></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-3 flex justify-between align-center">
      <div class="pr-2">
        <button
          [ngClass]="{
    'bg-[var(--theme-deafult-TeacherWithClick)]': MyDocuments&&(MyDocuments.length >0),
    'bg-[#DDDFE5]':  !MyDocuments||(MyDocuments.length ==0),
    'text-[#868B9F]':  !MyDocuments||(MyDocuments.length ==0),
  }"
          (click)="Previous.emit(true)"
          type="submit"
          class="text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3 bg-[var(--theme-deafult-TeacherWithClick)]-600 hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 text-[12px] lg:text-[14px] sora-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c]-600 dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
          >
        {{ "DocumentsInformation.form.Previous" | translate }}
        </button>
      </div>
      <div class="pl-2 mt-4">
        <i
          class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5]"
        ></i>
        <i
          class="fa-solid fa-circle mx-2 text-[8px] text-[#DDDFE5]"
          (click)="Previous.emit(true)"
        ></i>
        <i
          class="fa-solid fa-circle mx-2 text-[8px] text-[var(--theme-deafult-TeacherWithClick)]"
          (click)="MyDocuments ? navigate() : null"
        ></i>
      </div>
      <div class="pl-2">
        <button
          [ngClass]="{
    'bg-[var(--theme-deafult-TeacherWithClick)]': MyDocuments&&(MyDocuments.length >0) &&this.MyDocuments[0].profileStatus ==3 ,
    'bg-[#DDDFE5]':  !MyDocuments||(MyDocuments.length ==0)  ||(MyDocuments &&this.MyDocuments[0]?.profileStatus !=3),
    'text-[#868B9F]':  !MyDocuments||(MyDocuments.length ==0)   ||(MyDocuments &&this.MyDocuments[0]?.profileStatus !=3),
  }"
          type="submit"
          class=" text-white focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3 bg-[var(--theme-deafult-TeacherWithClick)]-600 hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 text-[12px] lg:text-[14px] sora-Medium rounded-lg text-sm w-32 h-16 text-center dark:bg-[#1a202c]-600 dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black"
          (click)="navigate()"
          [disabled]="!MyDocuments   ||(MyDocuments &&this.MyDocuments[0]?.profileStatus !=3)"
        >
          {{ "DocumentsInformation.form.Next" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="" style="position: relative; top: 50%">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <div>
        <div class="p-6 text-center deleteModalW">
          <div class="text-center">
            <img
              src="assets/images/MrS-Cool/delete-Icon.png"
              class="m-auto mt-3 p-3 w-14"
              alt="delete-Icon"
            />
          </div>
          <h6 class="mb-3 mt-3 text-lg text-[var(--theme-deafult-TeacherWithClick)]  font-normal  dark:text-gray-400 ">
            {{'DocumentsInformation.form.Are-you-sure-you-want-to-delete'|translate}}
          </h6>
          <button
          [disabled]="!submit"
            (click)="DeleteTeacherDocument(TableDeletDocID);modalRef?.hide()"
            type="button"
            class="mt-4 btnMargin text-white bg-[var(--theme-deafult-TeacherWithClick)]  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-3 text-center mr-2"
          >
           {{'DocumentsInformation.form.Yes'|translate}}
          </button>
          <button
            (click)="modalRef?.hide()"
            type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm sora-Regular px-5 py-3 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
             {{'DocumentsInformation.form.No'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
