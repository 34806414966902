

<!-- Faq Question section Start -->
<section class="faq-contain"   dir="rtl">
  <div class="container">
    <div class="row">
      <div class="col-12" style="direction: rtl">
        <h2 class="bold text-center">سياسة الخصوصية</h2>

        <h2>مقدمة</h2>
        <p>
          نحن في تطبيق ومنصة مستر أس كول نهتم بخصوصية المستخدمين لدينا، ونلتزم
          بحماية المعلومات الشخصية التي تقدمها عند استخدام . التطبيق تشرح سياسة
          الخصوصية هذه كيفية جمعنا واستخدامنا وكشفنا للمعلومات الشخصية يُ . رجى
          قراءة سياسة الخصوصية هذه بعناية لفهم . ممارساتنا والتدابير التي نتخذها
          لحماية خصوصيتك
        </p>

        <h2>1. : المعلومات التي نجمعها</h2>
        <p>
          أثناء استخدامك تطبيق ومنصة مستر أس كول ، قد نقوم بجمع بعض . المعلومات
          الشخصية التي تقدمها بشكل طوعي قد نجمع المعلومات :التالية
        </p>

        <h3>1.1. معلومات التسجيل :</h3>
        <ul>
          <li>
            عند التسجيل في التطبيق، قد نطلب منك تقديم معلومات شخصية مثل االسم
            والعنوان البريدي ورقم الهاتف وعنوان البريد .اإللكتروني
          </li>
        </ul>

        <h3>1.2. المعلومات الدراسية واألكاديمية :</h3>
        <ul>
          <li>
            : المعلومات الدراسية واألكاديمية قد تقدم لنا معلومات بشأن السنوات .
            الدراسية والشهادات والكليات والمعاهد التي التحقت بها يتم استخدام .
            هذه المعلومات لتوفير الخدمات األكاديمية والمشورة المناسبة
          </li>
        </ul>

        <h3>1.3. معلومات االستخدام :</h3>
        <ul>
          <li>
            قد نجمع معلومات حول استخدامك للتطبيق، بما في . ذلك الصفحات التي
            تزورها واإلجراءات التي تقوم بها يتم استخدام هذه . المعلومات لتحسين
            تجربتك وتخصيص المحتوى وتحليل أداء التطبيق
          </li>
        </ul>

        <h2>2. كيفية استخدام البيانات</h2>
        <p>
          نستخدم المعلومات الشخصية التي نجمعها لألغراض ألغراض أكاديمية . للبحث
          والنشر دون الكشف عن الهوية
        </p>

        <h3>2.1. تحسين التطبيق:</h3>
        <ul>
          <li>نستخدم المعلومات لتحسين وتطوير التطبيق وتحسين .تجربة المستخدم</li>
        </ul>

        <h3>2.2. التواصل والتواصل:</h3>
        <ul>
          <li>
            قد نستخدم معلومات االتصال التي قدمتها للتواصل . معك وتقديم المعلومات
            والتحديثات المتعلقة بالخدمات.
          </li>
        </ul>

        <h3>2.3. البحث والتحليل :</h3>
        <ul>
          <li>
            قد نقوم بتحليل المعلومات لفهم احتياجات المستخدمين . وتحسين الخدمات
            التي نقدمه
          </li>
        </ul>

        <h2>3. مشاركة المعلومات:</h2>
        <p>
          نحن نحترم خصوصية معلوماتك ونلتزم بعدم مشاركتها مع أطراف ثالثة دون
          موافقتك الصريحة، إال في حالة . الموافقة على مشاركة معلوماتك الشخصية
          بشكل صريح ألغراض محددة
        </p>
        <h2>3. مشاركة البيانات</h2>

        <h3>3.1. تقديم الخدمة :</h3>
        <ul>
          <li>
            قد نقوم بمشاركة المعلومات مع مقدمي الخدمات التربوية . والتقنية
            المؤهلين الذين يساعدوننا في تقديم الخدمات المناسبة لك
          </li>
        </ul>

        <h3>3.2. المتطلبات القانونية:</h3>
        <ul>
          <li>
            قد نكشف عن معلوماتك الشخصية إذا كانت مطلوبة . من قبل القوانين
            واللوائح واإلجراءات القانونية المعمول بها
          </li>
        </ul>
        <h3>3.3. األمان والحماية:</h3>
        <ul>
          <li>
            قد نشارك المعلومات الشخصية لحماية حقوقنا وسالمتك وسالمة المستخدمين
            اآلخرين، وللتحقيق في االشتباه في االحتيال أو . النشاط غير القانوني
          </li>
        </ul>

        <h2>4. األمان</h2>
        <p>
          نحن نتخذ إجراءات أمنية مناسبة لحماية معلوماتك الشخصية من الوصول . غير
          المصرح به واالستخدام غير القانوني أو التغيير أو الكشف ومع ذلك، فإنه ال
          يوجد نظام أمان مطلق، وال يمكن ضمان أمان المعلومات عبر . اإلنترنت بشكل
          كامل
        </p>

        <h2>5. حقوقك</h2>

        <h2>6. تغييرات في سياسة الخصوصية</h2>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه من حين آلخر سنقوم بنشر أي . تغييرات
          على هذه الصفحة وسنحدث تاريخ السريان المشار إليه أعاله
        </p>

        <h2>7. االتصال بنا</h2>
        <p>
          ذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية أو ممارساتنا فيما
          يتعلق بالمعلومات الشخصية، يُ رجى االتصال بنا
        </p>
        <h2>8. تواصل معنا</h2>
        <ul>
          <li>البريد الإلكتروني: {{ "info@mrscool.app" }}</li>
          <br />
          <li>الهاتف: 01023036779</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Faq Question section End -->

