import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { api, environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  sanitizedPdfUrl: SafeResourceUrl | null = null;
  public lang:string='en'

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   // this.lang = params['lang'];

    //   const apiUrl = this.lang
    //     ? `${api}/api/${this.lang}/Settings/PrivacyAndPolicy`
    //     : `${environment.Server_URL}Settings/PrivacyAndPolicy`;

    //   this.openPrivacyAndPolicy(apiUrl);
    // });
  }

  openPrivacyAndPolicy(apiKey:string) {
    const apiUrl = `${environment.Server_URL}Settings/PrivacyAndPolicy`;
    this.http.get(apiKey, { responseType: 'text' }).subscribe({
      next: (data: string) => {
        let pdfUrl = data.replace(/\\/g, '/');
        // this.router.navigate(['/pdf-viewer'], { queryParams: { url } });
        if (pdfUrl)
        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);

      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

}
