import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  withHashLocation,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ParentService } from 'src/app/shared/Apis/Parent/parent.service';
import { StudentService } from 'src/app/shared/Apis/Student/student.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ChooseStudentComponent } from '../../Parent/choose-student/choose-student.component';
import { OurMostPopularLessonsComponent } from 'src/app/shared/components/our-most-popular-lessons/our-most-popular-lessons.component';
import { ChildrenIdService } from '../../Parent/choose-student/children-id.service';
import { OurMostPopularSubjectsComponent } from 'src/app/shared/components/our-most-popular-subjects/our-most-popular-subjects.component';
import { MostBookedTeachersComponent } from '../../Teacher/MostBookedTeachers/most-booked-teachers/most-booked-teachers.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-student-subjects',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    ChooseStudentComponent,
    OurMostPopularLessonsComponent,
    OurMostPopularSubjectsComponent,
    MostBookedTeachersComponent,
  ],
  templateUrl: './student-subjects.component.html',
  styleUrls: ['./student-subjects.component.scss'],
})
export class StudentSubjectsComponent implements OnInit {
  //ViewChild
  @ViewChild('MostViewedTeacher')
  MostViewedTeacher!: MostBookedTeachersComponent;
  @ViewChild('MostRatedTeacher') MostRatedTeacher!: MostBookedTeachersComponent;
  @ViewChild('TopBookedSubjects')
  TopBookedSubjects!: OurMostPopularSubjectsComponent;
  @ViewChild('TopViewedSubjects')
  TopViewedSubjects!: OurMostPopularSubjectsComponent;
  @ViewChild('MostViewedLessons')
  MostViewedLessons!: OurMostPopularLessonsComponent;
  @ViewChild('MostBookedLessons')
  MostBookedLessons!: OurMostPopularLessonsComponent;
  //end ViewChild
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    nav: false,
    // navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
  };
  protected academicLevelName!: string;
  protected academicleveid!: number;

  envImageUrl = environment.serverFirstHalfOfImageUrl + '/';
  StudentId: any;
  StudentSubjects: any[] = [];
  selectedStudent: any;

  constructor(
    private childrenIdService: ChildrenIdService,
    private StudentService: StudentService,
    private ssr: SSRService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private layoutService: LayoutService,
    public modalService: NgbModal
  ) {
    // const returnEvent:any=  this.ssr.event(4);
    //   if(returnEvent !=true){
    //     this.StudentId=returnEvent
    //   }

    this.academicleveid = JSON.parse(
      this.auth.GetByName('academicYearId') ?? 0
    );
  }
  ngOnInit(): void {

    if (this.route.snapshot.paramMap.get('id')) {
      this.StudentId = this.route.snapshot.paramMap.get('id');
      this.StudentId = parseInt(this.StudentId);
      if (this.StudentId != JSON.parse(this.auth.GetByName('childrenId')))
        // this.childrenIdService.setChildrenId(this.StudentId);
        this.ssr.eventPrams(this.StudentId);
      this.GetStudentSubjects(this.StudentId);


      // this.ssr.eventPrams(parseInt(this.ssr.Location(this.StudentId)))
    } else {
      this.GetStudentSubjects();
    }

    this.auth.getRoleId() == '2';
    if (this.auth.getRoleId() == '3' && this.auth.getCurrentChildrenId()) {
      this.childrenIdService.childrenIdSubject.subscribe((res) => {
        if (res != null && this.StudentId != res) {
          this.onStudentSelect(res);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.getQueryParam();
    this.childrenIdService.academicleveid.subscribe((res) => {
      if (res != null && this.ssr.Location(3) == 'StudentSubjects') {
        this.updateComponentsAcademicLevel(res);
      }
    });
  }
  GoToDetails(id: any) {
    this.router.navigate([`/main/student/SubjectDetails`, id]);
  }

  GetStudentSubjects(id?: any) {
    const lang = this.layoutService.config.langu;
    if (id) {
      this.StudentService.GetStudentSubjects(id).subscribe((res) => {
        this.StudentSubjects = res.data.subjects;
        this.academicLevelName = res.data.academicLevelName;
        this.seoService.loadTranslations(lang).subscribe(translations => {
          this.seoService.setTitle(translations.StudentSubject.header.Title +  ','+ this.academicLevelName );
        });
      });
    } else {
      this.StudentService.GetStudentSubjects().subscribe((res) => {
        this.StudentSubjects = res.data.subjects;
        this.academicLevelName = res.data.academicLevelName;
        this.seoService.loadTranslations(lang).subscribe(translations => {
          this.seoService.setTitle(translations.StudentSubject.header.Title +  ','+ this.academicLevelName );
        });
      });


    }
    this.seoService.setMetaImage( 'assets/images/MrS-Cool/parentstudent.png'
    );
     this.seoService.loadTranslations(lang).subscribe(translations => {
      this.seoService.setTitle(translations.StudentSubject.header.Title +  ','+ this.academicLevelName );
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description))
      // this.seoService.setMetaTags(translations);
    });
  }

  formatString(inputString: string) {
    const parts = inputString.split(',');
    return parts;
  }

  GoToStudentProfile(id: any) {
    this.router.navigate([`/main/parent/ProfileStudent`, id]);
  }
  GoToConnectStudent() {
    this.modalService.dismissAll();
    this.router.navigate([`/main/parent/ConnectStudent`]);
  }
  onStudentSelect(selectedStudent: any) {
    if (this.selectedStudent != selectedStudent) {
      this.GetStudentSubjects(selectedStudent);
      this.selectedStudent = selectedStudent;
      this.academicleveid = JSON.parse(
        this.auth.GetByName('academicYearId') ?? 0
      );
    }
  }

  private updateComponentsAcademicLevel(id: number) {

    const academicLevel = id;
    if (this.academicleveid !== academicLevel) {
      this.academicleveid = academicLevel;
      this.MostViewedLessons?.GetMostViewedLessonsOrMostBookedLessons(
        academicLevel
      );
      this.MostBookedLessons?.GetMostViewedLessonsOrMostBookedLessons(
        academicLevel
      );
      this.MostRatedTeacher?.GetatedTeacherrTopViewedSubjects(academicLevel);
      this.MostViewedTeacher?.GetatedTeacherrTopViewedSubjects(academicLevel);
      this.TopViewedSubjects?.getData(academicLevel);
      this.TopBookedSubjects?.getData(academicLevel);
    }

  }

  private updateComponentAcademicLevel(component: any, academicLevel: any) {
    if (!component) {
      return; // Exit early if component is undefined
    }
    component.academicleveid = academicLevel;
    if (component.ngOnInit) {
      component.ngOnInit();
    }
  }
  private getQueryParam() {
    this.route.params.subscribe((params) => {
      if (params['id'] != null) {
        this.ssr.eventPrams(params['id']);
        this.StudentId = params['id'];
    // this.auth.ReloadHeader(true);

      }
    });
  }


}
