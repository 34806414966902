import {  ChangeDetectorRef, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent {
  direction!: Direction;
  @ViewChild('drawer') drawer: any;

  constructor(public route:ActivatedRoute,protected AuthService:AuthService,private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object){
  }

  ngAfterViewInit(): void {
    // تحقق مما إذا كنت على المتصفح
    if (isPlatformBrowser(this.platformId)) {
      // منطق حجم الشاشة فقط يعمل على المتصفح
      if (this.AuthService.getToken()) {
        if (window.innerWidth > 768) {
          this.drawer.open();
        }
        this.cdr.detectChanges();
      }

      // إضافة Event listener لتغيير الحجم في حالة تغييره بعد فتح الصفحة
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 768) {
          this.drawer.close();
        } else if (this.AuthService.getToken()) {
          this.drawer.open();
        }
        this.cdr.detectChanges();
      });
    }
  }
}
