import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgbCollapseModule,
  NgbModalModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { httpInterceptorProvider } from './shared/core/interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderAuthComponent } from './layout/header-auth/header-auth.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseToggleDirective } from './shared/Directive/collapse-toggle.directive';
import { DatePlaceholderDirective } from './shared/Directive/DatePlaceholder.directive';
// Import DevExtreme Scheduler module
import { DxButtonModule, DxSchedulerModule } from 'devextreme-angular';
import { TeacherCalenderSchedualComponent } from './Component/Teacher/teacher-calender-schedual/teacher-calender-schedual.component';
import { TestCalenderComponent } from './Component/Teacher/teacher-calender-schedual/test-calender/test-calender.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ChooseStudentComponent } from './Component/Parent/choose-student/choose-student.component';
import { FooterComponent } from './layout/footer/footer.component';
import { StudentCalenderScheduleComponent } from './Component/Student/student-calender-schedule/student-calender-schedule.component';
import { DatePipe } from '@angular/common';
import { SidenavAutosizeExample } from './layout/sidenav-autosize-example/sidenav-autosize-example.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { BottomNavigationComponent } from './shared/components/bottom-navigation/bottom-navigation.component';
import { FooterTowComponent } from './layout/footer/footer-tow/footer-tow.component';
import { environment } from 'src/environments/environment.prod';

//  export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
// Factory function for TranslateHttpLoader

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.langJson+'/', '');
}
@NgModule({
  declarations: [
    AppComponent,
    TeacherCalenderSchedualComponent,
    HeaderComponent,
    HeaderAuthComponent,
    PageLoaderComponent,
    SidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    CollapseToggleDirective,
    DatePlaceholderDirective,TestCalenderComponent, StudentCalenderScheduleComponent,LoaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    ChooseStudentComponent,
    FooterComponent,FooterTowComponent,SidenavAutosizeExample,
    MatSlideToggleModule,
    MatSidenavModule,  MatButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FlatpickrModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    NgbModule,
    BsDropdownModule.forRoot(),
    NgbCollapseModule,
    DxSchedulerModule,DxButtonModule, BottomNavigationComponent
  ],
  providers: [httpInterceptorProvider,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
