<nav
  class="fixed top-0 z-50 w-full bg-white dark:bg-[#1a202c] border-b border-gray-200  dark:border-gray-700"
>
  <div
    class="px-3 py-1 lg:px-5 lg:pl-3 bg-[var(--theme-deafult-TeacherWithClick)] border-b dark:border-b-0 border-gray-200 text-white"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start">
        <!-- <a class="flex ml-2 md:mr-24 text-decoration-none">
          <span class="border-gray-200 text-white text-decoration-none phone"
            ><i class="fa-solid PhoneIconColor fa-phone pr-4"></i> 242 844 39
            88</span
          >
        </a> -->
      </div>
      <div class="flex items-center justify-between">
        <div class="flex sm:justify-center sm:mt-0 mr-4">
          <a
          href="https://www.facebook.com/profile.php?id=61559643763340" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)]"
          >
            <i class="fa-brands fa-facebook"></i>
          </a>

          <a
          href="https://www.instagram.com/mrscool.eg/" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>

          <a
          href="https://www.youtube.com/@Mrscool-ly6sd" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a
          href="https://www.tiktok.com/@mrscool.eg" target="_blank"
            class="text-white hover:text-gray-900 dark:hover:text-[var(--theme-deafult-studentWithClick)] ms-4"
          >
          <i class="fab fa-tiktok "></i>
          </a>
        </div>
        <div class="flex items-center">
          <button
            class="flex text-sm bg-[var(--theme-deafult-TeacherWithClick)] rounded-full text-white focus:ring-gray-300 dark:focus:ring-gray-600"
          >
            <span class="sr-only">Open language menu</span>
            <span
              *ngIf="layout.config.langu == 'en'"
              (click)="changeLanguage('ar',true)"
            >
              Arabic</span
            >
            <span
              *ngIf="layout.config.langu == 'ar'"
              (click)="changeLanguage('en',true)"
            >
              English</span
            >
          </button>
        </div>
        <div class="flex items-center ml-3 d-none">
          <!-- <div class="compare">
              <a *ngIf="layout.config.langu=='ar'" id="en"><span (click)="changeLanguage('en',true)">{{'EN'}} </span> </a>
              <a *ngIf="layout.config.langu=='en'" id="ar"> <span (click)="changeLanguage('ar',true)"> {{'AR'}} </span></a><i
              class="px-1 fa fa-globe" aria-hidden="true"></i>
            </div> -->
          <div>
            <button
              type="button"
              class="flex text-sm bg-[var(--theme-deafult-TeacherWithClick)] rounded-full focus:ring-0 focus:ring-transparent text-white focus:ring-gray-300 dark:focus:ring-gray-600"
              aria-expanded="false"
              data-dropdown-toggle="dropdown-language"
            >
              <span class="sr-only">Open language menu</span>
              <span
                *ngIf="layout.config.langu == 'en'"
                (click)="changeLanguage('ar',true)"
              >
                Arabic</span
              >
              <span
                *ngIf="layout.config.langu == 'ar'"
                (click)="changeLanguage('en',true)"
              >
                English</span
              >
            </button>
          </div>
          <div
            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
            id="dropdown-language"
          >
            <ul class="py-1" role="none">
              <li>
                <a
                  (click)="changeLanguage('en',true)"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Englishs</a
                >
              </li>
              <li>
                <a
                  (click)="changeLanguage('ar',true)"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >{{ "اللغة العربية" }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="px-3 py-0 lg:px-5 lg:pl-3 border-b dark:border-b-0 dark:bg-[#1a202c] border-gray-200"
  >
    <div class="grid gap-6 lg:grid-cols-2 grid-cols-2">
      <div class="flex items-center justify-start">

        <ng-content></ng-content>

        <a class="flex ml-20 md:mr-24 d-xl-block d-none" routerLink="/main/home">
          <img
            src="assets/images/Logo/logo.svg"
            class="pb-2 pt-2 Logo"
            alt="MrS-Cool Logo"
          />
        </a>
      </div>

      <div class="flex items-center justify-end">
        <div class="text-right flex flex-row-reverse">

          <span
            class="edit cursor-pointer"
            [routerLink]="'/main/' + Controller + '/Chat'"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.75 8.125C18.5842 8.125 18.4253 8.19085 18.3081 8.30806C18.1908 8.42527 18.125 8.58424 18.125 8.75V13.75C18.125 14.2473 17.9275 14.7242 17.5758 15.0758C17.2242 15.4275 16.7473 15.625 16.25 15.625H3.75C3.25272 15.625 2.77581 15.4275 2.42417 15.0758C2.07254 14.7242 1.875 14.2473 1.875 13.75V8.75C1.875 8.58424 1.80915 8.42527 1.69194 8.30806C1.57473 8.19085 1.41576 8.125 1.25 8.125C1.08424 8.125 0.925268 8.19085 0.808058 8.30806C0.690848 8.42527 0.625 8.58424 0.625 8.75V13.75C0.625 14.5788 0.95424 15.3737 1.54029 15.9597C1.83047 16.2499 2.17497 16.4801 2.55411 16.6371C2.93326 16.7942 3.33962 16.875 3.75 16.875H16.25C17.0788 16.875 17.8737 16.5458 18.4597 15.9597C19.0458 15.3737 19.375 14.5788 19.375 13.75V8.75C19.375 8.58424 19.3092 8.42527 19.1919 8.30806C19.0747 8.19085 18.9158 8.125 18.75 8.125Z"
                fill="#CD2028"
              />
              <path
                d="M0.625 6.25C0.6251 6.37268 0.661304 6.49262 0.729099 6.59487C0.796894 6.69712 0.893282 6.77715 1.00625 6.825L7.7875 9.73125C8.48763 10.0313 9.24141 10.186 10.0031 10.186C10.7648 10.186 11.5186 10.0313 12.2188 9.73125L19 6.825C19.1118 6.77621 19.2069 6.69577 19.2735 6.59359C19.3401 6.49142 19.3754 6.37198 19.375 6.25C19.375 5.4212 19.0458 4.62634 18.4597 4.04029C17.8737 3.45424 17.0788 3.125 16.25 3.125H3.75C3.33962 3.125 2.93326 3.20583 2.55411 3.36288C2.17497 3.51992 1.83047 3.75011 1.54029 4.04029C0.95424 4.62634 0.625 5.4212 0.625 6.25ZM3.75 4.375H16.25C16.6786 4.37544 17.094 4.52268 17.4272 4.7922C17.7604 5.06172 17.9913 5.43725 18.0812 5.85625L11.725 8.58125C11.18 8.81509 10.5931 8.93568 10 8.93568C9.40692 8.93568 8.82003 8.81509 8.275 8.58125L1.91875 5.85625C2.00875 5.43725 2.23957 5.06172 2.57277 4.7922C2.90597 4.52268 3.32144 4.37544 3.75 4.375Z"
                fill="#CD2028"
              />
            </svg>


          </span>




          <!-- <span class="edit d-md-block d-none">
            <img
              src="assets/images/header/Rectangle.png"
              class="w-100 rounded-circle"
            />
          </span> -->
          <span
            class="edit cursor-pointer d-md-block d-none"
            (click)="toggleFullScreen()"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 7.5C17.1548 7.5 16.875 7.22016 16.875 6.875V3.75C16.875 3.40547 16.5945 3.125 16.25 3.125H13.125C12.7798 3.125 12.5 2.84516 12.5 2.5C12.5 2.15484 12.7798 1.875 13.125 1.875H16.25C17.2839 1.875 18.125 2.71609 18.125 3.75V6.875C18.125 7.22016 17.8452 7.5 17.5 7.5ZM3.125 6.875V3.75C3.125 3.40547 3.40547 3.125 3.75 3.125H6.875C7.22016 3.125 7.5 2.84516 7.5 2.5C7.5 2.15484 7.22016 1.875 6.875 1.875H3.75C2.71609 1.875 1.875 2.71609 1.875 3.75V6.875C1.875 7.22016 2.15484 7.5 2.5 7.5C2.84516 7.5 3.125 7.22016 3.125 6.875ZM18.125 16.25V13.125C18.125 12.7798 17.8452 12.5 17.5 12.5C17.1548 12.5 16.875 12.7798 16.875 13.125V16.25C16.875 16.5945 16.5945 16.875 16.25 16.875H13.125C12.7798 16.875 12.5 17.1548 12.5 17.5C12.5 17.8452 12.7798 18.125 13.125 18.125H16.25C17.2839 18.125 18.125 17.2839 18.125 16.25ZM7.5 17.5C7.5 17.1548 7.22016 16.875 6.875 16.875H3.75C3.40547 16.875 3.125 16.5945 3.125 16.25V13.125C3.125 12.7798 2.84516 12.5 2.5 12.5C2.15484 12.5 1.875 12.7798 1.875 13.125V16.25C1.875 17.2839 2.71609 18.125 3.75 18.125H6.875C7.22016 18.125 7.5 17.8452 7.5 17.5Z"
                fill="#FFAA00"
              />
            </svg>

            <!-- <img
              src="assets/images/header/maximize.png"
              class="w-100 rounded-circle"
            /> -->
          </span>
          <!-- <span class="edit cursor-pointer" (click)="toggleDarkMode()">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.79297 9.99861C1.79297 14.8245 5.71791 18.75 10.543 18.75C13.6712 18.7469 16.5627 17.0712 18.1211 14.3584C18.4353 13.8092 17.8283 13.1942 17.2752 13.5014C16.3513 14.0148 15.3132 14.2849 14.2563 14.2876C10.7976 14.2876 8.00703 11.497 8.00634 8.03786C8.00634 6.03926 9.03322 3.74065 11.4341 2.46079C11.9952 2.17699 11.8333 1.33555 11.207 1.28018C6.29509 0.864983 1.79297 4.78344 1.79297 9.99861ZM9.03903 2.64761C7.53647 4.1504 6.75678 6.04742 6.75634 8.03664C6.75634 12.172 10.1216 15.5378 14.2563 15.5378C14.7761 15.5366 15.2862 15.4472 15.7907 15.34C14.4088 16.7008 12.5344 17.4973 10.5417 17.4998C6.39216 17.4998 3.04172 14.1488 3.04172 9.99861C3.04172 6.08913 5.96103 3.24467 9.03903 2.64761Z"
                fill="#FFAA00"
              />
            </svg>


          </span> -->
        </div>



        <div class="items-center">
          <div class="lg:px-5 lg:pl-3 bg-white"></div>
          <div class="btn-group" dropdown>
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn text-sm bg-[var(--theme-deafult-TeacherWithClick)] rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              aria-controls="dropdown-basic"
            >
              <span class="sr-only">Open user menu</span>
              <img
                class="w-8 h-8 rounded-full"
                [src]="
                  imagePath
                    ? imagePath
                    : 'assets/images/Logo/logo.svg'
                "
                alt="user photo"
              />
            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu rtl:rtldropdown-menushow"
              role="menu"
              aria-labelledby="button-basic"
            >
              <li role="none">
                <a class="dropdown-item">
                  <p
                    style="text-wrap: wrap"
                    class="text-sm text-[var(--theme-deafult-TeacherWithClick)]"
                    role="none"
                  >
                    {{ customerName }}
                  </p></a
                >
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item"
                  [routerLink]="'/main/' + Controller + '/EditProfile'"
                  routerLinkActive="active"
                  >EditProfile
                </a>
              </li>
              <li role="menuitem">
                <a
                  class="dropdown-item"
                  [routerLink]="'/main/' + Controller + '/ChangePassword'"
                  routerLinkActive="button"
                  >Change Password</a
                >
              </li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem">
                <a class="dropdown-item" (click)="logOut()">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>





