<div  class="flex _ flex-wrap justify-ce nter  rtl:text-start dark:bg-[#1a202c]">
  <div
    class="m-auto flex justify-content-center sm:w-1/1 max-lg:hidden dark:bg-[#1a202c] xl:w-2/6"
  >

    <img [src]="'assets/images/Auth/SiginIn.png'" class="imgPosition w-full" />
  </div>
  <div
    class="flexFormDivAuth w-full mb-5 dark:bg-[#1a202c] sm:px-5 sm:w-1/1 justify-content-end xl:w-2/3 md:px-3 md:ps-12"
  >
    <app-nav-tabs class="w-full mb-4"></app-nav-tabs>
    <form
      class="w-full rtl:text-start md:p-[50px] dark:bg-[#1a202c] bg-[#ffffff] md:w-10/12 ProfilePadding rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
      [formGroup]="insertForm"
      (ngSubmit)="onSubmit()"
    >
      <h3 class="text-[18px] rtl:text-start lg:text-[30px] sora-bold dark:text-white">
        {{ "Login.header.Text" | translate }}
      </h3>
      <p class="mb-3 mt-1 rtl:text-start text-[13px] sora-Regular dark:text-white">
        {{ "Login.header.Subtitle" | translate
        }}<a routerLink="/authentication/{{ Controller }}/signup">
          <span class="text-red-600 ml-1 sora-Medium">{{
            "Login.header.Sign-up-for-free" | translate
          }}</span></a
        >
      </p>

      <div>
        <label
          data-aos="fade-up"
          for="phone"
          class="block  rtl:text-start text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{ "Login.form.Mobile-Number" | translate }}
          <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold"
            >*</span
          ></label
        >
        <input
          type="tel"
          id="phone"
          formControlName="mobile"
          onlyNumbers
          maxlength="11"
          minlength="11"
          class="bg-white  rtl:text-end border text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          [placeholder]="('Login.form.Enter-your-number' | translate)"
          [tooltip]="
            fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched)
              ? ('Login.form.required' | translate)
              : null
          "
          [ngClass]="{
            'class-1': fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched),
            'class-2': fc['mobile'].valid && (fc['mobile'].dirty || fc['mobile'].touched),
          }"
        />
      </div>
      <div>
        <label
          for="password"
          class="block  rtl:text-start text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
          >{{'Login.form.Password'| translate}}</label
        >
        <input
          [type]="changetype ? 'password' : 'text'"
          id="password"
          autocomplete="current-password"
          formControlName="password"
          class="bg-white  rtl:text-end border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="**********************"
          required
          [tooltip]="
            fc['password'].invalid &&
            (fc['password'].dirty || fc['password'].touched)
              ? ('Login.form.required' | translate)
              : null
          "
          [ngClass]="{
                  'class-1': fc['password'].invalid && (fc['password'].dirty || fc['password'].touched),
                  'class-2': fc['password'].valid && (fc['password'].dirty || fc['password'].touched),}"
        />
      </div>
      <div class="flex items-center justify-between my-2">
        <div class="flex items-center">
          <input
            id="remember"
            aria-describedby="remember"
            type="checkbox"
            class="w-4 h-4 border-2 border-[var(--theme-deafult-TeacherWithClick)] bg-white focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
            required=""
          />
          <span
            for="remember"
            class="ml-3 rtl:mr-3 mb-2 mt-2 text-sm forgetColor text-[var(--theme-deafult-TeacherWithClick)] dark:text-gray-300"
            >{{ "Login.form.Remember-me" | translate }}</span
          >
        </div>
        <a
          class="text-sm font-medium forgetColor hover:underline dark:text-primary-500 text-[var(--theme-deafult-TeacherWithClick)]"
          [routerLink]="'/authentication/' + Controller + '/ForgetPassword'"
          routerLinkActive="active"
          >{{ "Login.form.Forgot-password" | translate }}</a
        >
      </div>

      <button
        type="submit"
        [disabled]="!submit"
        [ngClass]="{

      'bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.valid&& Controller=='teacher',
      'bg-[var(--theme-deafult-ParentWithClick)]':insertForm.valid&& Controller=='parent',
      'bg-[var(--theme-deafult-studentWithClick)]': insertForm.valid&&Controller=='student',

      'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
      'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
      'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
     }"
        class="text-[10px]  disBtnTextColor lg:text-[14px] sora-Regular w-full  bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)]  focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] rounded-lg px-5 py-2.5 mr-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        {{ "Login.form.Sign-in" | translate }}
        <ng-container *ngIf="!submit">
          <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
        </ng-container>
      </button>

    </form>
  </div>
</div>
