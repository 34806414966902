import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
import { IOtp, IVefiryUserPost, IVefiryUserResponse } from '../../Model/teacher/teacher';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) {}
AddTeacher(data:FormData):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Teacher/Register`,data)
  }
  VefiryUser(data:IVefiryUserPost):Observable<IGenericResponseObject<IVefiryUserResponse>>{
    return this.http.post<IGenericResponseObject<IVefiryUserResponse>>(`${env.Server_URL}Teacher/VefiryUser`,data)
  }
  SendOTP(mobile:any):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Teacher/SendOTP`,{mobile:mobile})
  }


}
