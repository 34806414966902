<div>
  <app-header *ngIf="AuthService.getToken() != null">
        <button   type="button"(click)="drawer.toggle()"
         class=" d-block items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
            <span class="sr-only">Open sidebar</span>
           <svg class="w-6 h-6"   fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
               <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
         </button>
  </app-header>
  <app-header-auth *ngIf="AuthService.getToken() == null">
    <button   type="button"(click)="drawer.toggle()"
    class=" d-block items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
       <span class="sr-only">Open sidebar</span>
      <svg class="w-6 h-6"   fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
       </svg>
    </button></app-header-auth>
</div>

<mat-drawer-container class="example-container" autosize>

  <mat-drawer #drawer class="example-sidenav" mode="side">
    <!-- <p>Auto-resizing sidenav</p>
    <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p>
    <button (click)="showFiller = !showFiller" mat-raised-button>
      Toggle extra text
    </button> -->
    <app-sidebar ></app-sidebar>

  </mat-drawer>

  <div class="example-sidenav-content   0">

    <div class="  sm:px-0 2xl:px-14 xl:px-14 lg:px-14 md:px-14 ">


  <div
  [ngClass]="{'mt-40':!AuthService.getToken() }"
  class="  ">

  <router-outlet></router-outlet>
  </div>
  </div>
   </div>
</mat-drawer-container>

