<aside
  class="top-0 bg-[#ffffff] dark:bg-[#1a202c] h-screen border-r border-gray-200 dark:border-gray-700"
>
  <div class="h-full dark:bg-[#1a202c] pb-4">
    <ul class="space-y-2 pt-4 font-medium dark:bg-[#1a202c]">
      <li
        id="Sidebar-li "
        class="border-b-side"
        *ngFor="let sidebarItem of sidebarItems; let i = index"
        [routerLinkActive]="
          sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'
        "
        [routerLink]="sidebarItem.path ? [sidebarItem.path] : null"
      >
        <!-- --------------------------------------------------------------- -->
        <h5 class="text-[14px] dark:text-white md:text-[18px] Lama-SemiBold">
          {{ sidebarItem.title | translate }}
          {{
            sidebarItem.badge == "children"
              ? _appSettings.GetByName("ChildName")
              : null
          }}
        </h5>

        <p
          *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
          class="subtitle m-0 dark:text-white Lama-Regular text-[12px] md:text-[16px]"
        >
          <a
            style="text-decoration: none"
            (click)="sidebarSubItem1.badge == 'SignOut' ? logOut() : null"
            [routerLink]="
              sidebarSubItem1.badge == 'ChildHomepage'
                ? sidebarSubItem1.path + _appSettings.getCurrentChildrenId()
                : sidebarSubItem1.path
            "
            routerLinkActive="active"
            class="flex items-center w-full pt-1 transition duration-75 rounded-lg pl-1 group hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-white"
          >
            <!-- <i    [classList]="sidebarSubItem1.icon" class="mr-2 " aria-hidden="true"></i> -->
            <img
              src="assets/images/Icons/slider/{{ sidebarSubItem1.icon }}"
              class="mr-3 rtl:ml-2"
              alt=""
              srcset=""
            />
            <span class="dark:text-white">
              {{ sidebarSubItem1.title | translate }}
            </span>
          </a>
        </p>
      </li>
    </ul>
  </div>
</aside>

<div class="pt-32 d-none">
  <ul class="space-y-2 pt-4 overflow-y-auto font-medium dark:bg-[#1a202c]">
    <li
      id="Sidebar-li "
      class="border-b-side"
      *ngFor="let sidebarItem of sidebarItems; let i = index"
      [routerLinkActive]="
        sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'
      "
      [routerLink]="sidebarItem.path ? [sidebarItem.path] : null"
    >
      <!-- --------------------------------------------------------------- -->
      <h5 class="text-[14px] dark:text-white md:text-[18px] Lama-SemiBold">
        {{ sidebarItem.title | translate }}
        {{
          sidebarItem.badge == "children"
            ? _appSettings.GetByName("ChildName")
            : null
        }}
      </h5>

      <p
        *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
        class="subtitle m-0 dark:text-white Lama-Regular text-[12px] md:text-[16px]"
      >
        <a
          style="text-decoration: none"
          (click)="sidebarSubItem1.badge == 'SignOut' ? logOut() : null"
          [routerLink]="
            sidebarSubItem1.badge == 'ChildHomepage'
              ? sidebarSubItem1.path + _appSettings.getCurrentChildrenId()
              : sidebarSubItem1.path
          "
          routerLinkActive="active"
          class="flex items-center w-full pt-1 transition duration-75 rounded-lg pl-1 group hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-white"
        >
          <!-- <i    [classList]="sidebarSubItem1.icon" class="mr-2 " aria-hidden="true"></i> -->
          <img
            src="assets/images/Icons/slider/{{ sidebarSubItem1.icon }}"
            class="mr-3 rtl:ml-2"
            alt=""
            srcset=""
          />
          <span class="dark:text-white">
            {{ sidebarSubItem1.title | translate }}
          </span>
        </a>
      </p>
    </li>
  </ul>
</div>
