import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from 'src/app/shared/Model/Generic/generic-response';
import { IGetParentProfile } from 'src/app/shared/Model/Parent/parent-edit-profile';

@Injectable({
  providedIn: 'root'
})
export class ParentEditProfileService {
  constructor(private http: HttpClient) {}
  UpdateProfileParent(data:FormData):Observable<IGenericResponseObject<IGetParentProfile>>{
    return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}Parent/UpdateProfile`,data)
  }
  GetProfileParent():Observable<IGetParentProfile>{
    return this.http.get<IGenericResponseObject<IGetParentProfile>>(`${env.Server_URL}Parent/Profile`).pipe( map((res) => res.data ))
  }
}
