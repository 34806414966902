import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map,BehaviorSubject } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
import { IMyChildren, IOtp, IVefiryUserPost, IVefiryUserResponse } from '../../Model/Parent/Parent';

@Injectable({
  providedIn: 'root'
})
export class ParentService {
    myChildrenSubject: BehaviorSubject<IMyChildren[]> = new BehaviorSubject<IMyChildren[]>([]);
  MyChildren$: Observable<IMyChildren[]> = this.myChildrenSubject.asObservable();

  constructor(private http: HttpClient) {}
AddParent(data:any):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Parent/Register`,data)
  }

  VefiryUser(data:IVefiryUserPost):Observable<IGenericResponseObject<IVefiryUserResponse>>{
    return this.http.post<IGenericResponseObject<IVefiryUserResponse>>(`${env.Server_URL}Parent/VefiryUser`,data)
  }
  SendOTP(mobile:number):Observable<IGenericResponseObject<IOtp>>{
    return this.http.post<IGenericResponseObject<IOtp>>(`${env.Server_URL}Parent/SendOTP`,{mobile:mobile})
  }


  GetMyChildren():Observable<IGenericResponse<IMyChildren>>{
    return this.http.get<IGenericResponse<IMyChildren>>(`${env.Server_URL}Parent/GetMyChildren`).pipe(map((res) => {
      this.updateMyChildren(res.data)
      return  res
    }));
  }

   // Function to update MyChildren
   updateMyChildren(newData: IMyChildren[]): void {
    localStorage.setItem('addchildren', 'false');
    this.myChildrenSubject.next(newData);
  }
}
