<div>
  <form
    class="p-12 mb-5 m-auto pb-3 dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
    *ngIf="!doneReset"
    [formGroup]="insertForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="text-left">
      <h3 class="text-3xl Lama-bold dark:text-white text-[var(--theme-deafult-TeacherWithClick)]">
        {{ "ResetPassword.header.Text" | translate }}
      </h3>
      <p class="mb-3 mt-1 Lama-Medium dark:text-white text-[var(--theme-deafult-TeacherWithClick)]">
        {{ "ResetPassword.header.Subtitle" | translate }}
        <span class="text-red-600 ml-1 Lama-Medium">{{
          "ResetPassword.header.Sign-up-for-free" | translate
        }}</span>
      </p>
    </div>

    <div>
      <label
        for="password"
        class="block mb-3 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >

        <span class="text-[var(--theme-deafult-TeacherWithClick)]">{{ "ResetPassword.form.New-Password" | translate }}</span>
        <!-- <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
          >*</span
        > -->
        </label
      >
      <input
        [type]="changetype ? 'password' : 'text'"
        id="password"
        formControlName="newPassword"
        class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="**********************"
        [placeholder]="'ResetPassword.form.Enter-your-password' | translate"
        required
        [tooltip]="
          fc['newPassword'].invalid &&
          (fc['newPassword'].dirty || fc['newPassword'].touched)
            ? ('ResetPassword.form.required' | translate)
            : null
        "
        [ngClass]="{
                'class-1': fc['newPassword'].invalid && (fc['newPassword'].dirty || fc['newPassword'].touched),
                'class-2': fc['newPassword'].valid && (fc['newPassword'].dirty || fc['newPassword'].touched),
              }"
      />
    </div>
    <div>
      <label
        for="confirm_password"
        class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >
        <span class="text-[var(--theme-deafult-TeacherWithClick)]">{{ "ResetPassword.form.Confirm-New-Password" | translate }}</span>
        <!-- <span class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
          >*</span
        > -->
        </label
      >
      <input
        [type]="changetype ? 'password' : 'text'"
        formControlName="confirmPassword"
        id="confirm_password"
        class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="**********************"
        [placeholder]="'ResetPassword.form.Enter-your-password' | translate"
        required
        [tooltip]="
                   fc['confirmPassword'].errors?.['required']
                     ? ('TeacherSignUp.form.required' | translate)
                     :  fc['confirmPassword'].errors?.['mustMatch']
                         ? ('Passwords must match' | translate)
                         : null"
        [ngClass]="{
                'class-1': fc['confirmPassword'].invalid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
                'class-2': fc['confirmPassword'].valid && (fc['confirmPassword'].dirty || fc['confirmPassword'].touched),
              }"
      />
    </div>

    <!-- <div class="text-center Lama-Medium pt-3">
      <p>
        {{
          "ResetPassword.form.We-will-send-you-otp-to-your-mobile-number"
            | translate
        }}
        <br />{{ "ResetPassword.form.to-reset-a-new-password" | translate }}
      </p>
    </div> -->
    <button
      type="submit"
      [ngClass]="{

        'bg-[var(--theme-deafult-TeacherWithClick)] text-white':insertForm.valid&& Controller=='teacher',
        'bg-[var(--theme-deafult-ParentWithClick)] text-white':insertForm.valid&& Controller=='parent',
        'bg-[var(--theme-deafult-studentWithClick)] text-white': insertForm.valid&&Controller=='student',

        'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
        'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
        'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
       }"
      class="w-full disBtnTextColor my-3  bg-[#DDDFE5] bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black"
    >
      Verify
    </button>
  </form>

  <div
    class="m-auto pb-3 bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700"
    *ngIf="doneReset"
  >
    <img
      src="assets/images/MrS-Cool/VefiryUserSucc.png"
      class="w-1/6 m-auto py-3"
    />
    <h3 class="text-3xl Lama-bold dark:text-white">
      {{ "ResetPassword.form.Reset-Your-New-Password" | translate }}
    </h3>

    <div class="my-3 mx-5">
      <button
        type="submit"
        [ngClass]="{

          'bg-[var(--theme-deafult-TeacherWithClick)] text-white':insertForm.valid&& Controller=='teacher',
          'bg-[var(--theme-deafult-ParentWithClick)] text-white':insertForm.valid&& Controller=='parent',
          'bg-[var(--theme-deafult-studentWithClick)] text-white': insertForm.valid&&Controller=='student',

          'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
          'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
          'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
         }"
        class="w-full disBtnTextColor my-3  bg-[#DDDFE5] bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black"
        (click)="navigate()"
        (keyup.enter)="navigate()"
      >
        {{ "ResetPassword.form.Go-To-Sign-In" | translate }}
      </button>
    </div>
  </div>
</div>
