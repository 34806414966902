<div class="footer">
  <div class="container">
      <div class="row">
          <div class="col-lg-4 col-sm-4 col-xs-12">
              <div class="single_footer">
                <img
                src="assets/images/Logo/Logo_footer.svg"
                class="h-14 me-3"
                alt="Mr-Scool Logo"
              />
              <p class="mt-5">مستر إس كول هي منصة تعليمية إلكترونية رائدة في مصر. </p>
              <p>تتميز المنصة بتوفير مجموعة واسعة من المدرسين المؤهلين والمتميزين، وتقدم خيارات مرنة للدروس والحصص التعليمية التي يمكن تخصيصها لتناسب الاحتياجات والجداول الزمنية لكل طالب.</p>
                  <!-- <ul>
                      <li><a href="#">Lorem Ipsum</a></li>
                      <li><a href="#">Simply dummy text</a></li>
                      <li><a href="#">The printing and typesetting </a></li>
                      <li><a href="#">Standard dummy text</a></li>
                      <li><a href="#">Type specimen book</a></li>
                  </ul> -->
              </div>
          </div><!--- END COL -->
          <div class="col-md-4 col-sm-4 col-xs-12 d-xl-block d-none">
              <div class="single_footer single_footer_address  d-none">
                  <h4>Take your learning with you  </h4>
                  <div class="row ">
                    <div class="col-8 col-md-6">

                      <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

                        <i class="fa-brands fa-apple"></i> <div>
                          <span class="Lama-Regular Get">

                            {{"home.Download" |translate}}

                          </span>
                          <br>
                          <span class="Lama-Medium">

                            {{"home.Apple-Store" |translate}}
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-8 col-md-6">

                      <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

                        <i class="fa-brands fa-google-play"></i> <div>
                          <span class="Lama-Regular Get">
                            {{"home.Get-in-on" |translate}}

                          </span>
                          <br>
                          <span class="Lama-Medium">

                            {{"home.Google-Play" |translate}}
                          </span>
                        </div>
                      </a>
                    </div>


                   </div>>
              </div>
          </div><!--- END COL -->
          <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="single_footer single_footer_address">
                  <h4>اشترك في النشرة الإخبارية لدينا</h4>
                  <p>اشترك الان في النشرة البريدية الخاصه ب مستر اس كول لمتابعة كل الاخبار والنصائح الطبية

                  </p>
                  <div class="signup_form">
                      <form action="#" class="subscribe">
                          <input type="text" class="subscribe__input" placeholder="Enter Email Address">
                          <button type="button" class="subscribe__btn"><i class="fas fa-paper-plane"></i></button>
                      </form>
                  </div>
              </div>
              <div class="social_profile">
                  <ul>
                      <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                  </ul>
              </div>
          </div><!--- END COL -->

          <div class="col-md-12 col-sm-4 col-xs-12 row Take">
            <div class="single_footer single_footer_address col-md-5 col-sm-4 col-xs-12 ">
                <!-- <h4>Take your learning with you  </h4> -->
                <div class="row ">
                  <div class="col-8 col-md-6">

                    <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

                      <i class="fa-brands fa-apple"></i> <div>
                        <span class="Lama-Regular Get">

                          {{"home.Download" |translate}}

                        </span>
                        <br>
                        <span class="Lama-Medium">

                          {{"home.Apple-Store" |translate}}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div class="col-8 col-md-6">

                    <a href="http://" target="_blank" rel="noopener noreferrer" class="btn-apk ">

                      <i class="fa-brands fa-google-play"></i> <div>
                        <span class="Lama-Regular Get">
                          {{"home.Get-in-on" |translate}}

                        </span>
                        <br>
                        <span class="Lama-Medium">

                          {{"home.Google-Play" |translate}}
                        </span>
                      </div>
                    </a>
                  </div>


                 </div>>
            </div>
        </div><!--- END COL -->
      </div><!--- END ROW -->
      <div class="row END">
          <div class="col-lg-12 col-sm-12 col-xs-12">
              <p class="copyright"> ©  {{ date | date : "yyy" }} <a href="#">جميع الحقوق محفوظة مستر إس كول
                <a href="http://wecancity.cpm" target="_blank" rel="noopener noreferrer">wecancity</a> تم التطوير بواسطة     </a>.</p>
          </div><!--- END COL -->
      </div><!--- END ROW -->
  </div><!--- END CONTAINER -->
</div>
