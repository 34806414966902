<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 py-2"
>
  <div
    class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
  >
    <div class="mr-2">
      <img src="assets/images/MrS-Cool/info_.png" alt="" />
    </div>
    <div>
      <h3  class="text-[30px] text-left sora-bold dark:text-white">
        {{ "GroupSchedule.header.Title" |translate}}
      </h3>
    </div>
  </div>
  <div class="px-6">
    <p class="text-lg text-left sora-bold dark:text-white">
      {{ "GroupSchedule.form.Subject"  |translate}}
    </p>
    <p class="sora-Medium text-[12px]">{{TeacherSubjectGroup.teacherSubjectAcademicSemesterYearName}}</p>
  </div>
  <div class="grid gap-8 mb-6 lg:grid-cols-4 md:grid-cols-1 px-6 mt-3">

    <div>
      <label
        for="countries"
        class="block mb-2 sora-SemiBold  text[16px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "GroupSchedule.form.Group-name" | translate }}
       </label
      >
      <p class="sora-Medium text-[12px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">
        {{ TeacherSubjectGroup.groupName }}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-SemiBold  text[16px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "GroupSchedule.form.Start-Date" | translate }}
       </label
      >
      <p class="sora-Medium text-[12px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">
        {{ TeacherSubjectGroup.startDate |date}}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-SemiBold  text[16px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "GroupSchedule.form.End-Date" | translate }}
       </label
      >
      <p class="sora-Medium text-[12px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">
        {{ TeacherSubjectGroup.endDate  |date}}
      </p>
    </div>
    <div>
      <label
        for="countries"
        class="block mb-2 sora-SemiBold  text[16px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
        >{{ "GroupSchedule.form.Num-of-Lessons" | translate }}</label
      >
      <p class="sora-Medium text-[12px] text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">
        {{ TeacherSubjectGroup.numLessons }}
      </p>
    </div>
  </div>
</div>


<div
  class="bg-white rounded-lg shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 p-6"
>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <div
      class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <div class="mr-3">
        <img src="assets/images/MrS-Cool/setting.png" alt="" />
      </div>
      <div>
        <h3  class="text-[30px] text-left sora-bold dark:text-white">

          {{ "GroupSchedule.form.Manage-My-Subject-Groups" | translate }}

        </h3>
        <p class="my-3 text-sm/[11px] text-left rtl:text-right">
          {{ "GroupSchedule.form.Lessons-Schedule" | translate }}
        </p>
      </div>
    </div>
    <div
      class="d-flex align-items-center border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
    >
      <table
        class="w-full text-sm text-left rtl:text-right text-white-500 dark:text-white-400"
      >
        <thead
          class="sora-Medium text-white   bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-blend-darken dark:text-white-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">
              {{ "GroupSchedule.form.Serial" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "GroupSchedule.form.Lesson" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "GroupSchedule.form.Day" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "GroupSchedule.form.Start-Date" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">
              {{ "GroupSchedule.form.Start-Time" | translate }}
            </th>
            <th scope="col" class="px-6 py-3">

              {{ "GroupSchedule.form.End-Time" | translate }}

            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
            *ngFor="let item of TeacherSubjectGroup.scheduleSlots; let y = index"
          >

          <td class="px-6 py-4">
            {{ y+1 }}
          </td>
            <th
              scope="row"
              class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
            >
              {{ item.lessonName }}
            </th>
            <td class="px-6 py-4">
              {{ item.dayName }}
            </td>
            <td class="px-6 py-4">
              {{ item.date |date }}
            </td>
            <td class="px-6 py-4">
              {{ item.timeFrom }}
            </td>
            <td class="px-6 py-4">
              {{ item.timeTo }}
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <div class=" mt-3 flex justify-end ">
      <button *ngIf="!TeacherSubjectGroup.id  " type="submit" [disabled]="!submit" class=" text-white bg-[var(--theme-deafult-TeacherWithClick)] focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  mx-2 bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black" (click)="submit == true?CreateSubjectTeacherScheduleGroup() :null" >        {{ "GroupSchedule.form.Next" | translate }}  </button>


     <button type="submit" class="m-3 text-white bg-gray-400 focus:bg-[var(--theme-deafult-TeacherWithClick)] my-3  bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#1a202c] dark:hover:bg-[var(--theme-deafult-TeacherWithClick)]-700 dark:focus:ring-black" (click)="navigate(false,false)">{{"GroupSchedule.form.Back" |translate}}</button>
    </div>
  </div>
  <!-- </div> -->

