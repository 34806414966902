import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BookTeacherLessonSessionService } from 'src/app/shared/Apis/Teacher/SubjectInfo/BookTeacherLesson/book-teacher-lesson-session.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
 import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-confirm-payment',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnInit {
  protected status!: string;
  private _sessionId!: string | null;
  private orderId!: string | null;
  public responseCode!: string | null;
  public SectorType!: string;
  public rsponse: any;
  // Inject ActivatedRoute in the constructor
  constructor(
    private route: ActivatedRoute,
    private BookTeacherLessonSessionService: BookTeacherLessonSessionService,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}
  ngOnInit(): void {
    this.retrieveQueryParam();
  }

  // Inside a method or lifecycle hook where you want to retrieve the parameter
  private retrieveQueryParam() {
    // Using snapshot to get the current state of the route
    // const params = this.route.snapshot.queryParams;
    // Assuming params is an object with a property 'status'
    // this.status = params['status'];

    //mrscoolweb.azurewebsites.net/main/Confirm?
    this.route.queryParamMap.subscribe((params) => {
      this._sessionId = params.get('sessionId');
      this.status = params.get('responseMessage') ?? '';
      this.orderId = params.get('orderId');
      this.responseCode = params.get('responseCode');


      // console.log('Session ID:', this._sessionId);
      // console.log('Response Message:', this.status);
      // console.log('Order ID:', this.orderId);
      // console.log('responseCode :', this.responseCode);


    this.seo();



    });
  }

  private int() {
    // Use bookTeacherLessonSessionId further in your code
    let myObject: any = {
      orderId: this.orderId,
      sessionId: this._sessionId,
    };
    if (myObject)
      this.BookTeacherLessonSessionService.RetrieveOrderInvoice(
        myObject
      ).subscribe((res) => {
        if (res.success === true) {

          this.rsponse = res.data;
        }
      });
  }

  private seo(){
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe(translations => {
      if(this.responseCode=='000')
      this.seoService.setTitle(translations.ConfirmBooking.body.YourPaymentApproveSuccessfully);
      else
      this.seoService.setTitle(translations.ConfirmBooking.body.YourPaymentFailed);

      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(translations.Home.header.meta_description)
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


    });

  }
}
