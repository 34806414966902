import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from 'src/app/shared/Model/Generic/generic-response';
import { ISchedule, IScheduleSearch } from 'src/app/shared/Model/teacher/schedule';


@Injectable({
  providedIn: 'root'
})
export class ManageMyScheduleService {

  constructor(private http: HttpClient) {}
  AddTeacherSchedule(data:any):Observable<IGenericResponseObject<any>>{
      return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}TeacherSchedule/Create`,data)
    }
    GetManageMySchedule( IScheduleSearch?: IScheduleSearch):Observable<ISchedule[]>{

      return this.http.post<IGenericResponse<ISchedule>>(`${env.Server_URL}TeacherSchedule/GetMySchedules`,IScheduleSearch).pipe( map((res) => res.data ))
    }

    DeleteTeacherSchedule(id:number):Observable<ISchedule[]>{
      return this.http.get<IGenericResponse<ISchedule>>(`${env.Server_URL}TeacherSchedule/Delete?id=${id}`).pipe( map((res) => res.data ))
    }
}
