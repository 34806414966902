import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../Apis/AuthService/auth.service';
import { isPlatformBrowser } from '@angular/common';

export const isNotLoginGuard: CanActivateFn = (route, state) => {
  const loginService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
    const router = inject(Router);
  if (loginService.getToken() != null) {
    if (
      typeof loginService.getProfileStatusId() !== 'undefined' &&
      JSON.parse(loginService.getProfileStatusId()) != 3
    )
      return true;
    else router.navigate([`/main/home`]);
    return false;
  } else {
    if (isPlatformBrowser(platformId)) {

    localStorage.setItem('role', 'Anonymous');
    }
    return true;
  }
};
