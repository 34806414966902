import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { api, environment } from 'src/environments/environment';
@Component({
  selector: 'app-open-terms-and-conditions',
  templateUrl: './open-terms-and-conditions.component.html',
  styleUrls: ['./open-terms-and-conditions.component.scss']
})
export class OpenTermsAndConditionsComponent implements OnInit {
  sanitizedPdfUrl: SafeResourceUrl | null = null;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   const lang = params['lang'];

    //   const apiUrl = lang
    //     ? `${api}/api/${lang}/Settings/TermsAndConditions`
    //     : `${environment.Server_URL}Settings/TermsAndConditions`;

    //   this.openTermsAndConditions(apiUrl);
    // });
  }

  openTermsAndConditions(apiKey:string) {
    const apiUrl = `${environment.Server_URL}Settings/TermsAndConditions`;
    this.http.get(apiKey, { responseType: 'text' }).subscribe({
      next: (data: string) => {
        let pdfUrl = data.replace(/\\/g, '/');
        // this.router.navigate(['/pdf-viewer'], { queryParams: { url } });
        if (pdfUrl)
        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);

      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

}
