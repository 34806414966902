import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../../Model/Generic/generic-response';
@Injectable({
  providedIn: 'root'
})
export class RatesReviewsService {

  constructor(private http: HttpClient) { }
  TeacherLessonRate(pager:any):Observable<any>{

      return this.http.post<IGenericResponseObject<any>>(`${env.Server_URL}Teacher/TeacherLessonRate`,pager)

  }
}
