import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import {
  NgbProgressbarModule,
  NgbRatingModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { ProfileViewService } from 'src/app/shared/Apis/Teacher/Profile/ProfileView.service';
import { LogoSlider } from 'src/app/shared/core/data/slider';
import { environment } from 'src/environments/environment';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
@Component({
  selector: 'app-teacher-profile',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    NgFor,
    NgIf,
    TooltipModule,
    CarouselModule,
    TranslateModule,
    NgbRatingModule,
    NgbProgressbarModule,
    RouterLink,
    RouterLinkActive,
    // Components
  ],
  providers: [ProfileViewService],
  templateUrl: './teacher-profile.component.html',
  styleUrls: ['./teacher-profile.component.scss'],
})
export class TeacherProfileComponent implements OnInit {
  ImgUrlServer = environment.serverFirstHalfOfImageUrl;
  TeacherData: any;
  TeacherId: any;
  public InstaSliderConfig: any = LogoSlider;

  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    nav: true,
    navText: [
      '<i class="fa-solid fa-arrow-left-long"></i>',
      '<i class="fa-solid fa-arrow-right-long"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
  };
  constructor(
    private route: ActivatedRoute,
    private ProfileViewService: ProfileViewService,
    private seoService: SeoService,
    private layoutService: LayoutService
  ) {}
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.TeacherId = this.route.snapshot.paramMap.get('id');
      this.GetTeacherData(this.TeacherId);
    }
  }
  GetTeacherData(id: any) {
    this.ProfileViewService.GetProfileTeacher(id).subscribe((res) => {
      this.TeacherData = res;

      if(this.TeacherData?.teacherRatePercents.length==0){

      }
      let length = this.TeacherData.subjects.length;
      this.customOptionResponsive(length);
      this.seoService.setTitle(  this.TeacherData?.teacherName  ??"");
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(this.TeacherData?.teacherBIO??"")
      this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.TeacherData?.teacherBIO??""))
      this.seoService.setMetaImage(this.TeacherData?.teacherImage??"assets/images/MrS-Cool/subjectDetails.png");

    });
  }
  customOptionResponsive(length: any) {
    if (length > 6) {
      length = length - 1;
    }
    for (const breakpoint in this.customOptions.responsive) {
      this.customOptions.responsive[breakpoint as any].items = Math.min(
        this.customOptions.responsive[breakpoint as any].items as any,
        length
      );
    }
  }
  formatString(inputString: string) {
    const parts = inputString.split(',');
    return parts;
  }

}


