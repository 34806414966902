import { CommonModule, DatePipe, formatDate, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  NgbPagination,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { environment } from 'src/environments/environment';
import { MostBookedTeachersComponent } from '../../MostBookedTeachers/most-booked-teachers/most-booked-teachers.component';
import { BookingcalendarComponent } from '../../Booking-calendar/Booking-calendar.component';
import { CustomPipeForImagesPipe } from 'src/app/shared/core/pipes/custom-pipe-for-images-pipe.pipe';
import { InstaSlider } from 'src/app/shared/core/data/slider';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import Swal from 'sweetalert2';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { TextAlignDirective } from 'src/app/shared/Directive/text-align.directive';

import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';

registerLocaleData(localeAr);
@Component({
  selector: 'app-teacher-lesson-group-detail',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgbTooltipModule,
    CarouselModule,
    NgbProgressbarModule,
    MostBookedTeachersComponent,
    TranslateModule,
    NgbRatingModule,
    NgbPagination,
    RouterLink,
    NgxPaginationModule,
    BookingcalendarComponent,
    CustomPipeForImagesPipe,TextAlignDirective
  ],
  providers: [DatePipe,    { provide: LOCALE_ID, useValue: 'ar' }],

  templateUrl: './teacher-lesson-group-detail.component.html',
  styleUrls: ['./teacher-lesson-group-detail.component.scss'],
})
export class TeacherLessonGroupDetailComponent
  extends BaseComponent
  implements OnInit
{
  SubjectDetails: any;

  TeacherSubjectId: any;
  TeacherSubjectGroupDetail: any;
  getTeacherAvaliableSchedualDtos: any;
  rating = 0;
  page!: number;
  individualCost!: number;
  individualDuration!: number;
  teacherLessonId!: number;

  public InstaSliderConfig: any = InstaSlider;
  @ViewChildren('embeddedImage') embeddedImages!: QueryList<ElementRef>;
  DetailsForMostBooked: any;
  type: any;
  teacherLessonSessionId: any;

  constructor(
    private datePipe: DatePipe,
    private SubjectInfoService: SubjectInfoService,
    private _transition: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    protected auth: AuthService,
    private seoService: SeoService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.subscribe((params) => {
        this.TeacherSubjectId = params.get('id');
        this.GetTeacherLessonGroupDetail();
      });
      if (isPlatformBrowser(this.platformId))
      this.type = history.state.data;
    }
  }
  localStorage(BookTeacher: any) {
    const BookTeacher$ = {
      teacherLessonSessionId: BookTeacher.teacherLessonSessionId,
    };
    localStorage.setItem('BookTeacher', JSON.stringify(BookTeacher$));
  }
  checkRoll(item: any): boolean {

    if (
      (this.auth.getToken() && this.auth.getRoleId() == '1') ||
      (this.auth.getToken() &&
        this.auth.getRoleId() == '3' &&
        !this.auth.getCurrentChildrenId()||this.auth.getCurrentChildrenId() =="undefined")
    ) {
      if (this.auth.getRoleId() == '3') {
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('StudentEmpty'),
        });
      } else
        Swal.fire({
          icon: 'warning',
          title: this._transition.instant('warning'),
          text: this._transition.instant('Serviceavailable'),
        });
      return false;
    } else {
      this.localStorage(item);
      this.router.navigate([`/main/Booking/` + item.teacherLessonSessionId]);
      return true;
    }
  }
  GetTeacherLessonGroupDetail() {
    this.SubjectInfoService.GetTeacherLessonGroupDetail(
      this.TeacherSubjectId
    ).subscribe((res: any) => {
      this.TeacherSubjectGroupDetail = res;
      if(res.teacherRateDto.ratePercents.length==0){
        res.teacherRateDto.ratePercents.push({ratePercent:0,rateNumber:0},
          {ratePercent:0,rateNumber:0},
          {ratePercent:0,rateNumber:0},
          {ratePercent:0,rateNumber:0},
          {ratePercent:0,rateNumber:0})
      }
      this.SubjectDetails = res.getSubjectOrLessonDto;
      this.getTeacherAvaliableSchedualDtos =
        res.getTeacherAvaliableSchedualDtos;

        this.seoService.setTitle( this.TeacherSubjectGroupDetail?.teacherName   +'-' +  this.SubjectDetails?.headerName ??"");
        this.seoService.setHostUrlIndex();
        this.seoService.setMetaDescription(this.TeacherSubjectGroupDetail?.teacherBIO??"")
        this.seoService.setMetaKeywords(this.seoService.generateKeywords(this.TeacherSubjectGroupDetail?.teacherBIO??""))
        this.seoService.setMetaImage(this.TeacherSubjectGroupDetail?.teacherImage);
    });

  }
  GetTeacherAvaliableSchedual(obj: any) {
    this.SubjectInfoService.GetTeacherAvaliableSchedual(obj).subscribe(
      (res: any) => {
        // this.TeacherSubjectGroupDetail = res;
        // this.SubjectDetails = res.getSubjectOrLessonDto;
        this.getTeacherAvaliableSchedualDtos = res;
        // this.DetailsForMostBooked = {
        //   exceptTeacherId: this.TeacherSubjectGroupDetail.teacherId,
        // };
      }
    );
  }
  convertTime(inputTime: string): string | null {
    const lang = this.layoutService.config.langu; // en | ar

    const time = new Date(`2000-01-01T${inputTime}`);
    return this.datePipe.transform(time, 'h:mm a') || null;
  }


  formatString(inputString: string) {
    if (inputString) {
      const parts = inputString.split(',');
      return parts;
    } else {
      return '';
    }
  }


  convertDurationToHours(duration: number): string {
    const lang = this.layoutService.config.langu; // en | ar
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    if (lang == 'ar') {
      // تنسيق الوقت للعربية
      return `${formattedMinutes} : ${formattedHours} ساعات`;
    } else {
      // تنسيق الوقت للإنجليزية أو اللغات الأخرى
      return `${formattedHours} : ${formattedMinutes} hrs`;
    }
  }

  onLeave(rate: number) {
    if (this.pager.rate === 1) {
      // If the current rate is 1 and the user leaves the rating component, reset it to 0
      this.pager.rate = 0;
    }
  }

  protected handleEvent(day: any) {
    const dateObject = new Date(day);
    // Use the DatePipe to format the date
    const startDate = this.datePipe.transform(dateObject, 'yyyy-MM-dd');

    this.GetTeacherAvaliableSchedual({
      teacherId: this.TeacherSubjectGroupDetail.teacherId,
      lessonDuration: this.TeacherSubjectGroupDetail.individualDuration,
      startDate,
    });
  }
  getFormattedDate(date: Date): string {

    const lang = this.layoutService.config.langu;
    const locale =lang === 'ar' ? 'ar-EG' : 'en-US';

    return formatDate(date, 'd MMM yyyy', locale);
  }
  bookNow(teacherLessonSessionId: number): void {
    // const ref = this?.dialogService?.open(DownloadAppsComponent, {
    //   width: '35%',
    //   styleClass: 'auth-dialog confirm-delete-trip',
    //   dismissableMask: true,
    //   data: {}
    // });
    // ref?.onClose?.subscribe((res: any) => {
    //   if (res?.isSave == true) {
    //     this.publicService?.toggleBodyScroll(true);
    //   }
    // });
  }
}
