

<div class="container h-100">
  <div class="card chat-card h-100 cardsSpace bg-white rounded-lg cardsSpace shadow dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700 border-0">
      <div class= "bg-white ">
          <div
          class="d-flex align-items-center border-b-2 mainAddressPadding  rounded-lg dark:border dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:border-gray-700 "
        >
          <div class="pb-3">
              <img
              src="assets/images/MrS-Cool/message.png"
              class=""

            />        </div>
          <div class="addressIconSpace">
            <h3 class=" text-left Lama-bold dark:text-white underTitle">
              {{ "TeacherChat.header.Message" | translate }}
            </h3>
            <p class=" text-sm/[11px] text-left">
              {{ "TeacherChat.header.ChatSubtitle" | translate }}
            </p>
          </div>
        </div>
            <!-- <div class="row">
                <div class="col-1 MessageImg" >
                    <img
                    src="assets/images/MrS-Cool/message.png"
                    class=""

                  />
                </div>
                <div class="col text-start p-0 addressIconSpace"><h3 class="Lama-bold text-[30px] underTitle text-[var(--theme-deafult-TeacherWithClick)] ">{{ "TeacherChat.header.Message" | translate }}</h3>
                    <span class="m-0 "> {{ "TeacherChat.header.ChatSubtitle" | translate }}</span></div>
            </div> -->
        </div>
      <div class="card-body chat-container h-100">
        <div class="row h-100">
          <div class="col-md-4  col-12   h-100 contactCard">

            <div class="h-100 card border-0">

                  <label for="table-search" class="sr-only">{{ "TeacherChat.chat.Search" | translate }}</label>
                  <div class="relative mb-3 mt-4">
                      <svg xmlns="http://www.w3.org/2000/svg" class="searchIcon relative" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M9.58268 17.5013C13.9549 17.5013 17.4993 13.9569 17.4993 9.58464C17.4993 5.21238 13.9549 1.66797 9.58268 1.66797C5.21043 1.66797 1.66602 5.21238 1.66602 9.58464C1.66602 13.9569 5.21043 17.5013 9.58268 17.5013Z" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M18.3327 18.3346L16.666 16.668" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                                                <input type="text" id="table-search-users"
                                                [(ngModel)]="searchText"
                                                (keyup)="filterContacts()"
                                                class="searchBG bg-[#FFFAEF]  form-control block p-2 pl-10 text-[12px] text-[var(--theme-deafult-TeacherWithClick)] border border-gray-300 rounded-lg w-80   focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search">
                  </div>
                  <div class="scrollable-content p-2"
                  [ngClass]="{
                    'isMobile':isCheck&&isMobile
                  }">
                      <ng-container *ngFor="let contact of filteredContacts let i=index" >
                        <button
                  class="student-list"
                  type="button"  [ngClass]="{ 'active-button':(contact?.teacherSubjectAcademicSemesterYearId==messageDate?.teacherSubjectAcademicSemesterYearId &&contact?.studentId ==studentId&&!isCheck  &&toggleActive(i) )|| isActive(i) }" (click)="toggleActive(i) ; activeIndexSubject=null;studentId=null" class="flex mt-3  items-center w-full p-2 text-base text-[var(--theme-deafult-TeacherWithClick)] transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                          <img
                              [src]="contact.teacherImage? (contact.teacherImage |customPipeForImages):'assets/images/MrS-Cool/ChatSender.png'"
                              class="border-radius50"
                              width="40"
                              height="40"
                            />
                              <span class="flex-1 ml-3 text-left  min-md-whitespace-nowrap">{{ contact.teacherName }}</span>
                             <div class="row">
                              <!-- <div class="col-12 text-end">
                                  <span >5 mins</span>

                              </div> -->
                              <div class="col-12 text-end">
                                  <span class="inline-block mr-2 lessonNum text-[12px]" >{{contact.lessonNum}}</span>
                                  <svg class="w-3 h-3 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                   </svg>
                              </div>
                             </div>
                        </button>
                        <ng-container  *ngFor="let item of contact.teacherLessonSessionsDtos let i2=index" >
                          <ul *ngIf="isActive(i)" (click)="loadMessages(i2,item.bookTeacherLessonSessionDetailId); newMessage.reset()"
                          class="cursor-pointer notactive-list mt-3 "[ngClass]="{ 'active-list': (item.bookTeacherLessonSessionDetailId==messageDate?.bookTeacherLessonSessionDetailId )||isActiveSubject(i2) }" >

                          <li  class="Lama-Regular lessonName">{{item.lessonName}}</li>
                        </ul>
                        </ng-container>

                      </ng-container>
                  </div>


                  </div>
              </div>

              <div class="col-md-8  col-12 h-100"   [ngClass]="{
                'hidden-mobile':!isMobile
              }">
                  <div class="chat h-100 ">
                      <div class="chat-messages card chatCard "  *ngIf="messageDate">
                          <div class="card-header bg-white headerfooterBorder " >
                              <div  >
                                  <div class="mr-2 timeText">
                                  <div class="row">
                                    <div class="col-sm-1 col-2">
                                      <img
                                          [src]="messageDate.teacherImage? (messageDate.teacherImage |customPipeForImages):'assets/images/MrS-Cool/ChatSender.png'"
                                          width="40"
                                          height="40"
                                          class="border-radius50"
                                        />
                                      </div>
                                      <div *ngIf="messageDate" class="col  text-start p-0 mt-1 "><span class="Lama-bold text-[var(--theme-deafult-TeacherWithClick)] ">{{messageDate!.teacherName}}</span>
                                          <br>
                                          <span class="Lama-Regular">{{messageDate!.subjectName}}</span></div>
                                  </div>
                                  <button class=" text-end mr-4 d-md-none d-block"
                                  (click)="isMobile=false"
                                  [ngClass]="{
                                    'hidden':!isMobile
                                  }">
                                   Back  <i class="fa-solid  fa-arrow-right"></i>
                                  </button>
                              </div>
                          </div>

                      </div>
                      <div class="card-body overflow-auto scrollable-content " #chatContainer>
                          <ng-container >
                          <div *ngFor="let message of messages" class="mt-5"
                              [ngClass]="{'my-message': message.fromName !=null, 'contact-message': message.toName!=null}">
                              <div class=" mt-3 ml-auto"
                                  [ngClass]="{'my-message': message.fromName !=null, 'contact-message': message.toName!=null}">
                                  <div  class="Lama-Regular"  [ngClass]="{'mymessageName': message.fromName !=null, 'contantmessageName': message.toName!=null}">
                                      <div class="mr-2 timeText">
                                        <span class="Lama-Regular">{{message.creationDate |date:'d MMMM , y '}}</span>     <span class="Lama-Regular ml-2">{{message.creationDate |date:' h:mm'}}</span>
                                        </div>
                                        <div class="mr-2 senderText">
                                            <span class="Lama-Regular" *ngIf="message.fromName !=null">{{message.fromName}}</span>
                                            <span  class="Lama-Regular"  *ngIf="message.toName !=null">{{message.toName}}</span>
                                        </div>
                                      <div class="mr-2">
                                        <img  *ngIf="message.fromName !=null"
                                        [src]="messageDate.studentImage? (messageDate.studentImage |customPipeForImages):'assets/images/MrS-Cool/ChatSender.png'"
                                        width="40"
                                        height="40"
                                        class="border-radius50 "
                                      />
                                        <img *ngIf="message.toName !=null"
                                        [src]="messageDate.teacherImage? (messageDate.teacherImage |customPipeForImages):'assets/images/MrS-Cool/ChatSender.png'"
                                        width="40"
                                        height="40"
                                        class="border-radius50 "
                                      />

                                      </div>

                                  </div>

                              </div>
                              <div class="mt-2  text-[14px]"
                              [ngClass]="{'myMessageDiv': message.toName!=null, 'contantMessageDiv':  message.fromName!=null}"
                              class="Lama-Regular"   [class.ml-auto]="message.fromName!=null" [class.mr-auto]=" message.toName!=null">
                                  {{ message.comment }}
                              </div>
                          </div>

                      </ng-container>

                      </div>
                      <div class="card-footer bg-white headerfooterBorder min-md-h115">
                        <div class="chat-input min-md-h100 row">
                          <div class="col-md-8  col-12">
                              <textarea [formControl]="newMessage"
                                        class="form-control Lama-Regular bg-white h100 border-0 text-[14px] overflow-auto"
                                        [placeholder]="'Type your message...'"
                                        (keydown.enter)="!newMessage.errors && submit ? sendMessage() : null;$event.preventDefault();"></textarea>
                              <div *ngIf="newMessage.invalid && (newMessage.dirty || newMessage.touched)">
                                  <div *ngIf="newMessage.errors?.['required']">
                                      Message is required.
                                  </div>
                                  <div *ngIf="newMessage.errors?.['pattern'] && (newMessage.dirty || newMessage.touched)" class="pt-2">
                                      Input cannot consist solely of whitespace characters.
                                  </div>
                              </div>
                          </div>

                          <div class="col-md-4 d-flex flex-column justify-content-center">
                            <button
                              class=" SendMessage bg-[var(--theme-deafult-TeacherWithClick)] text-[14px] min-md-h-[65px] Lama-Regular w-full text-white bg-[#DDDFE5] hover:bg-[var(--theme-deafult-TeacherWithClick)] hover:text-white focus:outline-none focus:ring-4 focus:ring-[var(--theme-deafult-TeacherWithClick)] font-medium rounded-lg text-sm px-5 py-3 mr-2 dark:bg-[var(--theme-deafult-TeacherWithClick)] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                              (click)="submit ? sendMessage() : null"
                            >
                              {{ "TeacherChat.chat.SendMessage" | translate }}
                              <ng-container *ngIf="!submit">
                                <i
                                  class="pi pi-spin pi-spinner"
                                  style="font-size: 1rem"
                                ></i>
                              </ng-container>
                            </button>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div class="noChatCard d-flex justify-center" *ngIf="!messageDate">
                      <div >
                          <img
                          src="assets/images/MrS-Cool/Vector.png"
                          width="200"
                          height="200"
                        />

                      </div>
                      <div class="d-flex justify-center noMessageTxt mt-3" >

                        <label class="Lama-Regular">{{ "StudendChat.chat.ShowMessage" | translate }}</label>
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</div>
