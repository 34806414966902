import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
@Injectable({
  providedIn: 'root'
})
export class SubjectSemesterYearService {
  constructor(private http: HttpClient) {}
  GetSubjectLessons(id?:any){
    if(id){
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}SubjectSemesterYear/GetSubjectLessons?id=${id}`)
    }
    else{
      return this.http.get<IGenericResponseObject<any>>(`${env.Server_URL}SubjectSemesterYear/GetSubjectLessons`)
    }
  }
  
  }
