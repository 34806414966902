
<div [dir]="'ltr'" class="flex _ flex-wrap justify-center pt-5 py-14 mt-20">
  <div class="flex justify-content-center md:w-1/2 sm:w-1/1 dark:bg-[#1a202c] xl:w-2/6">

    <!-- <form [formGroup]="insertForm" (ngSubmit)="onSubmit()">
      <input type="file" formControlName="TeacherImage" accept="image/jpeg,image/png,image/gif" />
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('required')">Please select an image</div>
      <div *ngIf="insertForm?.get('imaTeacherImagege')?.hasError('invalidFileType')">Invalid file type. Allowed types: JPEG, PNG, GIF</div>
      <div *ngIf="insertForm?.get('TeacherImage')?.hasError('invalidFileSize')">File size exceeds the maximum limit (5MB)</div>
      <button type="submit">Submit</button>
    </form> -->
<img src="assets/images/MrS-Cool/left.svg"  class="w-full">
  </div>


  <div class="md:ps-12 md:w-1/2 ng-star-inserted pt-5 sm:w-1/1 w-full" *ngIf="ForgetPasswordSteps.Forget">

      <form class=" p-12 mb-5 m-auto pb-3 ProfilePadding text-[var(--theme-deafult-TeacherWithClick)] dark:bg-[#1a202c] bg-[#ffffff] rounded-lg shadow dark:border  dark:bg-[#1a202c] dark:border-gray-700 "  [formGroup]="insertForm" (ngSubmit)="onSubmit()">
        <h3 class="text-3xl  sora-bold text-[var(--theme-deafult-TeacherWithClick)]  dark:text-white">{{"ForgetPassword.header.Text" | translate}} </h3>
         <p class="mb-3 mt-1 text-[13px] text-[var(--theme-deafult-TeacherWithClick)] sora-Regular dark:text-white ">{{"ForgetPassword.header.Subtitle" | translate}}<a routerLink="/authentication/{{Controller}}/signup"> <span class="text-red-600 ml-1 sora-Medium ">{{"ForgetPassword.header.Sign-up-for-free" |translate}}</span></a></p>

        <div>
          <label for="phone" class="block mb-2  text-[var(--theme-deafult-TeacherWithClick)] dark:text-white">{{"ForgetPassword.form.Mobile-Number" |translate}} <span class="text-[#FF0000] text-[12px] md:text-[16px] sora-SemiBold ">*</span></label>
          <input type="tel" id="phone" formControlName="mobile"  maxlength="11" minlength="11" class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your Number"
          [tooltip]="fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched) ? ('this field is required' | translate) : null"
          onlyNumbers
          [ngClass]="{
            'class-1': fc['mobile'].invalid && (fc['mobile'].dirty || fc['mobile'].touched),
            'class-2': fc['mobile'].valid && (fc['mobile'].dirty || fc['mobile'].touched),
          }"
          >
              </div>

              <div class="text-center sora-Medium pt-3 text-[var(--theme-deafult-TeacherWithClick)]">
                <p>{{"ForgetPassword.form.We-will-send-you-otp-to-your-mobile-number" | translate}} <br>{{"ForgetPassword.form.to-reset-a-new-password" | translate}} </p>
              </div>
      <button type="submit"
      [ngClass]="{

        'bg-[var(--theme-deafult-TeacherWithClick)] text-white':insertForm.valid&& Controller=='teacher',
        'bg-[var(--theme-deafult-ParentWithClick)] text-white':insertForm.valid&& Controller=='parent',
        'bg-[var(--theme-deafult-studentWithClick)] text-white': insertForm.valid&&Controller=='student',
        'hover:bg-[var(--theme-deafult-TeacherWithClick)]':insertForm.invalid&& Controller=='teacher',
        'hover:bg-[var(--theme-deafult-ParentWithClick)]':insertForm.invalid&& Controller=='parent',
        'hover:bg-[var(--theme-deafult-studentWithClick)]': insertForm.invalid&&Controller=='student',
       }"

      class="w-full disBtnTextColor    my-3  bg-[#DDDFE5]   bg-black-600 hover:bg-black-700 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black">{{"ForgetPassword.form.Verify" |translate}}</button>
      </form>

  </div>

  <div class="w-full sm:w-1/1 md:w-1/2 justify-center text-center  pt-5" *ngIf="ForgetPasswordSteps.vefiryOtp && DataVefiryOtp">
    <app-verify-otp [DataVefiryOtp]="DataVefiryOtp" [phoneNumber]=" fc['mobile'].value " (StepVerifyOTP)="handleEvent($event)"></app-verify-otp>
  </div>
  <div class="w-full sm:w-1/1 md:w-1/2 justify-center text-center pt-5" *ngIf="ForgetPasswordSteps.ResetPassword">
    <app-reset-password [from]="insertForm.value"></app-reset-password>
  </div>


</div>


