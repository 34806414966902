import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTextAlign]',
  standalone: true
})
export class TextAlignDirective implements OnChanges {
  @Input() appTextAlign: string | undefined;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appTextAlign'] && changes['appTextAlign'].currentValue) {
      this.setTextAlignment();
    }
  }

  private setTextAlignment() {
    console.log('Received text:', this.appTextAlign);
    if (this.appTextAlign) {
      const isArabic = /[\u0600-\u06FF]/.test(this.appTextAlign);
      this.el.nativeElement.style.textAlign = isArabic ? 'right' : 'left';
    }
  }
}
